import { QuestionOrderSteps as QuestionOrderStep } from '../../../Section/types';
import {
  drivingLicenseIdentificationNumber,
  otherIdentificationNumber,
  passportIdentificationNumber,
  ssn,
  tin,
} from '../../questions/identifiers';
import { QuestionProperties } from '../../questions/types';

export const drivingLicenseIdentificationNumberFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return drivingLicenseIdentificationNumber({
    ...properties,
    additionalValidations: {
      maxLength: 30,
      ...properties.additionalValidations,
    },
  });
};

export const otherIdentificationNumberFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return otherIdentificationNumber({
    ...properties,
    additionalValidations: {
      maxLength: 30,
      ...properties.additionalValidations,
    },
  });
};

export const passportIdentificationNumberFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return passportIdentificationNumber({
    ...properties,
    additionalValidations: {
      maxLength: 30,
      ...properties.additionalValidations,
    },
  });
};

export const ssnFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return ssn({
    ...properties,
    disabledIfPrefilled: true,
  });
};

export const tinFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return tin({
    ...properties,
    disabledIfPrefilled: true,
  });
};
