import { Image, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { Style } from '../../../styles';

import { formatBalance, formatDisplayDate } from '~/components/AccountPerformance/utils';
import { OpeningClosingBalance } from '~/hooks/qpr/useGetDataForQPR';
import { ContentOptions } from '~/utils/contentstack';

export interface AccountBalancePdfContent {
  accountBalance: string;
  balanceChange: string;
  startingBalance: string;
}

interface Props {
  content: AccountBalancePdfContent;
  contentOptions: ContentOptions;
  endDate: string;
  imageUrl: string;
  openingClosingBalance: OpeningClosingBalance;
  pdfStyles: Style;
  startDate: string;
}

export const AccountBalance: React.FC<Props> = ({
  endDate,
  imageUrl,
  content,
  contentOptions,
  openingClosingBalance,
  pdfStyles,
  startDate,
}) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '12px',
        justifyContent: 'space-between',
        border: pdfStyles.border.border,
        margin: '12px 40px',
      }}
    >
      <View style={{ marginRight: '32px' }}>
        <Text
          style={{
            color: pdfStyles.textPrimary.color,
            fontSize: 14,
            marginBottom: '4px',
          }}
        >
          {content.accountBalance}
        </Text>
        <Text
          style={{
            color: pdfStyles.textSecondary.color,
            fontSize: 8,
            marginBottom: '24px',
          }}
        >
          {`${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`}
        </Text>
        <Text
          style={{
            color: pdfStyles.textSecondary.color,
            fontSize: 8,
            marginBottom: '4px',
          }}
        >
          {content.accountBalance}
        </Text>
        <Text
          style={{
            color: pdfStyles.textPrimary.color,
            fontSize: 16,
          }}
        >
          {formatBalance(openingClosingBalance.closingBalance ?? '', contentOptions)}
        </Text>
        <View
          style={{
            display: 'flex',
            padding: '2px 0',
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginTop: '24px',
          }}
        >
          <View
            style={{
              marginRight: '24px',
            }}
          >
            <Text
              style={{
                color: pdfStyles.textSecondary.color,
                fontSize: 8,
                marginBottom: '4px',
              }}
            >
              {content.startingBalance}
            </Text>
            <Text
              style={{
                color: pdfStyles.textPrimary.color,
                fontSize: 12,
              }}
            >
              {formatBalance(openingClosingBalance.openingBalance ?? '', contentOptions)}
            </Text>
          </View>
          <View>
            <Text
              style={{
                color: pdfStyles.textSecondary.color,
                fontSize: 8,
                marginBottom: '4px',
              }}
            >
              {content.balanceChange}
            </Text>
            <Text
              style={{
                color: pdfStyles.textPrimary.color,
                fontSize: 12,
              }}
            >
              {formatBalance(openingClosingBalance.difference ?? '', contentOptions)}
            </Text>
          </View>
        </View>
      </View>
      <View style={{ paddingTop: '50px' }}>
        {imageUrl && <Image src={imageUrl} style={{ ...pdfStyles?.accountBalanceChart }} />}
      </View>
    </View>
  );
};
