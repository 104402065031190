import { GetRetakeRTQFADocusignContent, GetRetakeRTQFADocusignContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetRetakeRTQFADocusignContent = (
  options?: QueryHookOptions<GetRetakeRTQFADocusignContent, GetRetakeRTQFADocusignContentVariables>,
): QueryResult<GetRetakeRTQFADocusignContent, GetRetakeRTQFADocusignContentVariables> => {
  return useContentstackQuery(queries.GetRetakeRTQFADocusignContent, options);
};
