import { format, parseISO } from 'date-fns';

import { TableField } from '../DownloadablePerformance/Views/AccountActivity';
import { replaceVariable } from '../DownloadableProposal/utils';

import { Content } from '.';

import { formatPartyInfo } from '~/components/pdfs/DownloadablePerformance/utils';
import { GetClientInfo } from '~/hooks/client/symphony/__generated__/query.v2';
import { GetDownloadableQPRContent_all_downloadable_qpr_items } from '~/hooks/qpr/contentstack/__generated__/query.v2';
import { formatCurrencyPrecise } from '~/utils/format';

export interface AccountActivityFormattedData {
  field: string;
  label: string;
  thisQuarter: string;
  ytd: string;
}

export const getDownloadableQPRContent = (
  accountNumber: string,
  clientInfo: GetClientInfo,
  faInfo: GetClientInfo,
  startDate: string,
  endDate: string,
  data?: GetDownloadableQPRContent_all_downloadable_qpr_items | null,
): Content => {
  const advisor = formatPartyInfo(faInfo, data?.cover?.email ?? '');
  const client = formatPartyInfo(clientInfo, data?.cover?.email ?? '');
  const commonText = {
    logo: data?.logoConnection?.edges?.[0]?.node?.url ?? '',
    footerText: replaceVariable(data?.footer ?? '', [
      {
        value: `${format(parseISO(new Date().toISOString()), 'MMM dd, yyyy')} at ${format(
          parseISO(new Date().toISOString()),
          'HH:mm',
        )}`,
        variable: 'date',
      },
    ]),
  };

  return {
    cover: {
      ...commonText,
      emailImage: data?.cover?.email_imageConnection?.edges?.[0]?.node?.url ?? '',
      phoneImage: data?.cover?.phone_imageConnection?.edges?.[0]?.node?.url ?? '',
      contactImage: data?.cover?.contact_name_imageConnection?.edges?.[0]?.node?.url ?? '',
      primaryClientInfo: client,
      advisorInfo: advisor,
      preparedBy: data?.cover?.prepared_by ?? '',
      preparedFor: data?.cover?.prepared_for ?? '',
      primaryAccountOwner:
        data?.cover?.additional_client_headers?.find(header => header?.key === 'PRIMARY_ACCOUNT_OWNER')?.value ?? '',
      subtitle: replaceVariable(data?.cover?.subtitle ?? '', [
        { value: `${startDate} - ${endDate}`, variable: 'period' },
      ]),
      title: data?.cover?.title ?? '',
    },
    tableOfContents: {
      pageName: data?.table_of_contents?.pagename ?? '',
      title: data?.table_of_contents?.title ?? '',
      pages:
        data?.table_of_contents?.pages?.map(item => ({
          page: replaceVariable(item?.page ?? '', [{ value: accountNumber, variable: 'accountNumber' }]),
          index: item?.index ?? '',
        })) ?? [],
      ...commonText,
    },
    accountActivity: {
      pageName: data?.account_activity?.pagename ?? '',
      account: {
        title: data?.account_activity?.account?.title ?? '',
        info: data?.account_activity?.account?.info ?? '',
        asOf: data?.performance_section?.as_of ?? '',
        increasedBy: data?.account_activity?.account?.increased_by ?? '',
        decreasedBy: data?.account_activity?.account?.decreased_by ?? '',
        disclosureContent: {
          disclosure: data?.account_activity?.disclosure?.disclosure_text ?? '',
          title: data?.account_activity?.disclosure?.title ?? '',
        },
        quarter: data?.account_activity?.account?.quarter ?? '',
        tableHeaders: {
          accountActivity: data?.account_activity?.account?.table_headers?.account_activity ?? '',
          thisQuarter: data?.account_activity?.account?.table_headers?.this_quarter ?? '',
          ytd: data?.account_activity?.account?.table_headers?.ytd ?? '',
        },
        tableFields:
          data?.account_activity?.account?.table_fields?.map((item: any) => ({
            label: item?.label ?? '',
            field: item?.field ?? '',
          })) ?? [],
      },
      ...commonText,
    },
    disclosureContent: {
      continuedTitle: data?.disclosure_section?.continued_title ?? '',
      disclosureList:
        data?.disclosure_section?.disclosure_list?.map(item => ({
          heading: item?.heading ?? null,
          text: item?.text ?? '',
          pageNumber: item?.disclosure_page_number ?? 1,
          subPointList:
            item?.sub_point_list?.map(point => ({
              subPointHeading: point?.sub_point_heading ?? '',
              subPointText: point?.sub_point_text ?? '',
            })) ?? [],
        })) ?? [],
      title: data?.disclosure_section?.title ?? '',
      pageName: data?.performance_section?.pagename ?? '',
      ...commonText,
    },
    performanceSection: {
      pageName: data?.performance_section?.pagename ?? '',
      account: data?.performance_section?.account ?? '',
      performanceReportFor: data?.performance_section?.performance_report_for ?? '',
      asOf: data?.performance_section?.as_of ?? '',
      yourPortfolioReturned: data?.performance_section?.your_portfolio_returned ?? '',
      from: data?.performance_section?.from ?? '',
      to: data?.performance_section?.to ?? '',
      disclosure: data?.performance_section?.performance_chart_disclosure ?? '',
      yourPortfolio: data?.performance_section?.your_portfolio ?? '',
      currentBalance: data?.performance_section?.current_balance ?? '',
      ytd: data?.performance_section?.ytd ?? '',
      qtd: data?.performance_section?.qtd ?? '',
      oneYear: data?.performance_section?.one_year ?? '',
      threeYear: data?.performance_section?.three_year ?? '',
      fiveYear: data?.performance_section?.five_year ?? '',
      tenYear: data?.performance_section?.ten_year ?? '',
      sinceInception: data?.performance_section?.since_inception ?? '',
      ...commonText,
    },
    assetBreakdown: {
      allocationDetails: {
        assetAllocationColumns: {
          allocation: data?.asset_allocation?.asset_allocation_columns?.allocation ?? '',
          asset: data?.asset_allocation?.asset_allocation_columns?.asset ?? '',
          assetClass: data?.asset_allocation?.asset_allocation_columns?.asset_class ?? '',
          targetAllocation: data?.asset_allocation?.asset_allocation_columns?.target_allocation ?? '',
          value: data?.asset_allocation?.asset_allocation_columns?.value ?? '',
        },
        assetAllocationTitle: data?.asset_allocation?.asset_allocation_title ?? '',
        donutActualAllocationLabel: data?.asset_allocation?.donut_actual_allocation_label ?? '',
        donutTargetAllocationLabel: data?.asset_allocation?.donut_target_allocation_label ?? '',
        title: data?.asset_allocation?.title ?? '',
      },
      multiSleeveAllocationDetailsTitle: data?.asset_allocation?.multi_sleeve_title ?? '',
      pageName: data?.asset_allocation?.pagename ?? '',
      ...commonText,
    },
  };
};

// TODO: fix types
// This is being used to get QPR data based on fields (columns) defined in ContentStack.
// Need a better way of defining/transforming the data required here.
export const getAccountActivityFormattedData = (
  accountActivityQuarterData: Record<string, unknown>,
  accountActivityYearData: Record<string, unknown>,
  tableFields: TableField[],
): AccountActivityFormattedData[] => {
  return tableFields.map(({ label, field }) => {
    const quarter = accountActivityQuarterData[field];
    const year = accountActivityYearData[field];
    return {
      label,
      field,
      thisQuarter: quarter && typeof quarter === 'string' ? formatCurrencyPrecise(parseFloat(quarter)) : '-',
      ytd: year && typeof year === 'string' ? formatCurrencyPrecise(parseFloat(year)) : '-',
    };
  });
};
