import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { getNormalizedDiscountUnit } from './utils';

import { AddIcon, Grid, IconButton, InputLabel, RemoveIcon } from '~/components/ui/mui';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';

interface PricingSummaryEditProps {
  discount: number;
  helperText?: string | React.ReactNode;
  setDiscount: React.Dispatch<React.SetStateAction<number>>;
}

export interface PricingSummaryEditBasisPointProps extends PricingSummaryEditProps {
  discountTiers?: number[];
  discountUnit?: string;
}
export const PricingSummaryEditBasisPoint: React.FC<PricingSummaryEditBasisPointProps> = ({
  discount,
  discountTiers = [0],
  discountUnit,
  helperText,
  setDiscount,
}) => {
  const [discountIndex, setDiscountIndex] = useState(discountTiers.findIndex(t => t === discount) || 0);

  useEffect(() => {
    setDiscountIndex(discountTiers.findIndex(t => t === discount));
  }, [discount, discountTiers]);

  return (
    <Grid sx={{ textAlign: 'center' }}>
      <Grid alignItems="center" display="flex" sx={{ justifyContent: 'center' }}>
        <IconButton
          disabled={discountIndex === 0}
          onClick={() => {
            setDiscount(prev => (discountIndex > 0 ? discountTiers[discountIndex - 1] : prev));
          }}
          sx={{ border: '2px solid', borderColor: 'other.stroke' }}
        >
          <RemoveIcon />
        </IconButton>
        <Typography sx={{ px: 5, py: 2 }} variant="h3">
          {`${discount} ${discountUnit}`}
        </Typography>
        <IconButton
          disabled={discountIndex === discountTiers.length - 1}
          onClick={() => {
            setDiscount(prev => (discountIndex < discountTiers.length - 1 ? discountTiers[discountIndex + 1] : prev));
          }}
          sx={{ border: '2px solid', borderColor: 'other.stroke' }}
        >
          <AddIcon />
        </IconButton>
      </Grid>
      <InputLabel sx={{ p: 2 }}>{helperText}</InputLabel>
    </Grid>
  );
};

export interface PricingSummaryEditVariableInputProps extends PricingSummaryEditProps {
  decimalScale?: number;
  discountUnit?: string;
  isInvalidInput: boolean;
}
export const PricingSummaryEditVariableInput: React.FC<PricingSummaryEditVariableInputProps> = ({
  decimalScale,
  discount,
  discountUnit,
  helperText,
  isInvalidInput,
  setDiscount,
}) => {
  return (
    <Grid alignItems="center" display="block" sx={{ textAlign: 'center' }}>
      <NumberFormat
        customInput={TextField}
        decimalScale={decimalScale}
        fixedDecimalScale
        inputProps={{ sx: { textAlign: 'center' } }}
        onValueChange={values => values.floatValue !== undefined && setDiscount(values.floatValue)}
        suffix={discountUnit}
        value={discount}
      />
      <InputLabel error={isInvalidInput}>{helperText}</InputLabel>
    </Grid>
  );
};

export interface PricingSummaryEditComponentProps
  extends PricingSummaryEditBasisPointProps,
    PricingSummaryEditVariableInputProps {
  isPercentageAdvancedPricing?: boolean;
  isTieredBasisPointAdvancedPricing?: boolean;
}
export const PricingSummaryEdit: React.FC<PricingSummaryEditComponentProps> = ({
  decimalScale,
  discount,
  discountTiers,
  discountUnit,
  helperText,
  isInvalidInput,
  isPercentageAdvancedPricing,
  isTieredBasisPointAdvancedPricing,
  setDiscount,
}) => {
  if (isTieredBasisPointAdvancedPricing) {
    return (
      <PricingSummaryEditBasisPoint
        discount={discount}
        discountTiers={discountTiers}
        discountUnit={discountUnit}
        helperText={helperText}
        setDiscount={setDiscount}
      />
    );
  }

  const decimalScaleNormalized = decimalScale ?? isPercentageAdvancedPricing ? 2 : 0;
  return (
    <PricingSummaryEditVariableInput
      decimalScale={decimalScaleNormalized}
      discount={discount}
      discountUnit={getNormalizedDiscountUnit(isPercentageAdvancedPricing, discountUnit)}
      helperText={helperText}
      isInvalidInput={isInvalidInput}
      setDiscount={setDiscount}
    />
  );
};
