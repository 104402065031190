import { DataPointKeys, QuestionKeys } from '../../../configs/questions/types';
import {
  ManagedProductPaperworkWithFreeFormId,
  PaperworkInputWithFreeFormId,
} from '../../../hooks/useGetPaperworkData';

export const defaultAssetsHeldAwayConfig = {
  questions: [
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_STOCKS,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_STOCKS,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.stocks.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.stocks = {
            ...paperworkInput.investment.experienceDetail.stocks,
            qualified: value,
            percentage,
          };
        }
      },
    },
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_BONDS,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_BONDS,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.bonds.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.bonds = {
            ...paperworkInput.investment.experienceDetail.bonds,
            qualified: value,
            percentage,
          };
        }
      },
    },
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_SHORT_TERMS,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_SHORT_TERMS,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.shortTerms.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.shortTerms = {
            ...paperworkInput.investment.experienceDetail.shortTerms,
            qualified: value,
            percentage,
          };
        }
      },
    },
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_MUTUAL_FUNDS,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_MUTUAL_FUNDS,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.mutualFunds.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.mutualFunds = {
            ...paperworkInput.investment.experienceDetail.mutualFunds,
            qualified: value,
            percentage,
          };
        }
      },
    },
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_OPTIONS,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_OPTIONS,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.options.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.options = {
            ...paperworkInput.investment.experienceDetail.options,
            qualified: value,
            percentage,
          };
        }
      },
    },
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_LIMITED_PARTNERSHIPS,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_LIMITED_PARTNERSHIPS,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.limitedPartnerships.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.limitedPartnerships = {
            ...paperworkInput.investment.experienceDetail.limitedPartnerships,
            qualified: value,
            percentage,
          };
        }
      },
    },
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_LIFE_INSURANCE,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_LIFE_INSURANCE,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.lifeInsurance.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.lifeInsurance = {
            ...paperworkInput.investment.experienceDetail.lifeInsurance,
            qualified: value,
            percentage,
          };
        }
      },
    },
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_VARIABLE_CONTRACTS,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_VARIABLE_CONTRACTS,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.variableContracts.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.variableContracts = {
            ...paperworkInput.investment.experienceDetail.variableContracts,
            qualified: value,
            percentage,
          };
        }
      },
    },
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_SECURITY_FUTURES,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_SECURITY_FUTURES,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.securityFutures.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.securityFutures = {
            ...paperworkInput.investment.experienceDetail.securityFutures,
            qualified: value,
            percentage,
          };
        }
      },
    },
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_ANNUITIES_AND_LIFE_INSURANCE,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_ANNUITIES_AND_LIFE_INSURANCE,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.annuitiesAndLifeInsurance.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.annuitiesAndLifeInsurance = {
            ...paperworkInput.investment.experienceDetail.annuitiesAndLifeInsurance,
            qualified: value,
            percentage,
          };
        }
      },
    },
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_ALTERNATIVE_INVESTMENTS,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_ALTERNATIVE_INVESTMENTS,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.alternativeInvestments.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.alternativeInvestments = {
            ...paperworkInput.investment.experienceDetail.alternativeInvestments,
            qualified: value,
            percentage,
          };
        }
      },
    },
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_FOREIGN_CURRENCY,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_FOREIGN_CURRENCY,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.foreignCurrency.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.foreignCurrency = {
            ...paperworkInput.investment.experienceDetail.foreignCurrency,
            qualified: value,
            percentage,
          };
        }
      },
    },
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_FOREIGN_SECURITY,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_FOREIGN_SECURITY,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.foreignSecurity.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.foreignSecurity = {
            ...paperworkInput.investment.experienceDetail.foreignSecurity,
            qualified: value,
            percentage,
          };
        }
      },
    },
    {
      questionKey: QuestionKeys.ASSETS_HELD_AWAY_OTHER,
      dataPointKey: DataPointKeys.ASSETS_HELD_AWAY_OTHER,
      prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => {
        return paperworkData?.investment?.experienceDetail?.other.qualified ?? '';
      },
      paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string, percentage: number) => {
        if (paperworkInput.investment?.experienceDetail) {
          paperworkInput.investment.experienceDetail.other = {
            ...paperworkInput.investment.experienceDetail.other,
            qualified: value,
            percentage,
          };
        }
      },
    },
  ],
};
