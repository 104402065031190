import React from 'react';

import { SleeveTemplateFilterState } from '..';
import { SleeveTemplateActionCard } from '../SleeveTemplateActionCard';
import { SleeveTemplateCard } from '../SleeveTemplateCard';
import { SleeveTemplateCardContent, SleeveTemplateData } from '../utils';

import { Grid } from '~/components/ui/mui';
import { AssetAllocationModalState } from '~/containers/PortfolioSelection/types';
import { RiskPreferenceVolatilityMappings } from '~/hooks/volatility/symphony';
import { checkVolatilityMatch, VolatilityMatchStatus } from '~/utils/volatility';

export interface SleeveTemplateGridProps {
  content: SleeveTemplateCardContent;
  dataQa?: string;
  deleteSleeveTemplate?: (sleeveTemplateId: number) => Promise<void>;
  filterState?: SleeveTemplateFilterState;
  initialRiskPreference: number;
  isDeleteAllowed?: boolean;
  onQuickApplySleeveTemplate: (sleeveTemplate: SleeveTemplateData) => void;
  openAssetAllocationModal: (assetAllocationProps: AssetAllocationModalState) => void;
  riskPreferenceVolatilityList: RiskPreferenceVolatilityMappings[];
  setCurrentSleeveTemplateFilter?: (value: SleeveTemplateFilterState) => void;
  sleeveTemplates: SleeveTemplateData[];
}

export const SleeveTemplateGrid: React.FC<SleeveTemplateGridProps> = ({
  content,
  dataQa = 'sleeve-template-grid',
  deleteSleeveTemplate,
  filterState,
  initialRiskPreference,
  onQuickApplySleeveTemplate,
  openAssetAllocationModal,
  riskPreferenceVolatilityList,
  setCurrentSleeveTemplateFilter,
  sleeveTemplates,
  isDeleteAllowed,
}) => {
  const volatilityMatchStatus = (sleeveVolatility: string): VolatilityMatchStatus => {
    return checkVolatilityMatch(riskPreferenceVolatilityList, sleeveVolatility, initialRiskPreference);
  };

  const sleeveTemplatesToBeDisplayed = (): SleeveTemplateData[] => {
    return filterState === SleeveTemplateFilterState.Suitable ? riskScoreMatchingTemplates() : sleeveTemplates;
  };

  const riskScoreMatchingTemplates = (): SleeveTemplateData[] => {
    return sleeveTemplates.filter(
      sleeveTemplate => volatilityMatchStatus(sleeveTemplate.volatility) === VolatilityMatchStatus.MATCHED,
    );
  };

  const getSleeveTemplateActionCardsToDisplay = () => {
    if (!filterState || !setCurrentSleeveTemplateFilter) {
      return null;
    }

    if (sleeveTemplates.length === 0) {
      return (
        <Grid item md={4} xs={12}>
          <SleeveTemplateActionCard
            content={content}
            isEmptyCard
            setCurrentSleeveTemplateFilter={setCurrentSleeveTemplateFilter}
          />
        </Grid>
      );
    } else if (sleeveTemplates.length === riskScoreMatchingTemplates().length) {
      return null;
    } else if (riskScoreMatchingTemplates().length === 0) {
      if (filterState === SleeveTemplateFilterState.Suitable) {
        return (
          <>
            <Grid item md={4} xs={12}>
              <SleeveTemplateActionCard
                content={content}
                isSuitableNotAvailableCard
                setCurrentSleeveTemplateFilter={setCurrentSleeveTemplateFilter}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SleeveTemplateActionCard
                content={content}
                isSuitableBlendCard
                setCurrentSleeveTemplateFilter={setCurrentSleeveTemplateFilter}
              />
            </Grid>
          </>
        );
      } else {
        return (
          <Grid item md={4} xs={12}>
            <SleeveTemplateActionCard
              content={content}
              isAllBlendCard
              setCurrentSleeveTemplateFilter={setCurrentSleeveTemplateFilter}
            />
          </Grid>
        );
      }
    } else {
      if (filterState === SleeveTemplateFilterState.Suitable) {
        return (
          <Grid item md={4} xs={12}>
            <SleeveTemplateActionCard
              content={content}
              isSuitableBlendCard
              setCurrentSleeveTemplateFilter={setCurrentSleeveTemplateFilter}
            />
          </Grid>
        );
      } else {
        return (
          <Grid item md={4} xs={12}>
            <SleeveTemplateActionCard
              content={content}
              isAllBlendCard
              setCurrentSleeveTemplateFilter={setCurrentSleeveTemplateFilter}
            />
          </Grid>
        );
      }
    }
  };

  return (
    <Grid container data-qa={dataQa} spacing={3}>
      {sleeveTemplatesToBeDisplayed().map((sleeve, idx) => (
        <Grid item key={idx} md={4} xs={12}>
          <SleeveTemplateCard
            content={content}
            deleteSleeveTemplate={deleteSleeveTemplate}
            donutAllocations={sleeve.donutAllocations}
            isDeleteAllowed={isDeleteAllowed}
            minimumBalanceForAccountOpening={sleeve.minimumBalanceForAccountOpening}
            onQuickApplySleeveTemplate={() => onQuickApplySleeveTemplate(sleeve)}
            openAssetAllocationModal={openAssetAllocationModal}
            sleeveAllocationData={sleeve.allocations}
            sleeveTemplateId={sleeve.id}
            sleeveTemplateName={sleeve.name}
            stockBondSplit={sleeve.stockBondSplit}
            volatilityMatchStatus={volatilityMatchStatus(sleeve.volatility)}
          />
        </Grid>
      ))}
      {getSleeveTemplateActionCardsToDisplay()}
    </Grid>
  );
};
