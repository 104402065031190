import { Image, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { SecurityReturns } from '~/components/AccountPerformance/types';
import { formatDisplayDate, formatSecurityName } from '~/components/AccountPerformance/utils';
import { Dot } from '~/components/pdfs/components/Dot';
import { Style } from '~/components/pdfs/styles';

export interface ReturnsPdfContent {
  returns: string;
}

export interface ReturnsSecurityColors {
  [key: string]: string;
}

interface Props {
  accountNumberDisplay: string;
  accountReturnsPercentage?: string;
  content: ReturnsPdfContent;
  endDate: string;
  imageUrl: string;
  pdfStyles: Style;
  returnsSecurityColors: ReturnsSecurityColors;
  securitiesReturns: SecurityReturns[];
  showSecurities: boolean;
  startDate: string;
}

export const Returns: React.FC<Props> = ({
  accountNumberDisplay,
  accountReturnsPercentage,
  endDate,
  imageUrl,
  content,
  pdfStyles,
  returnsSecurityColors,
  securitiesReturns,
  showSecurities,
  startDate,
}) => {
  const commonTextStyle = {
    fontSize: 8,
    margin: '2px 0',
    width: '50%',
    fontWeight: 400,
    ...pdfStyles.returns,
  };

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '8px',
        justifyContent: 'space-between',
        border: pdfStyles.border.border,
        margin: '12px 40px',
      }}
    >
      <View style={{ marginRight: '8px', width: '35%' }}>
        <Text
          style={{
            color: pdfStyles.textPrimary.color,
            fontSize: 14,
            marginBottom: '4px',
          }}
        >
          {content.returns}
        </Text>
        <Text
          style={{
            color: pdfStyles.textSecondary.color,
            fontSize: 8,
            marginBottom: '24px',
          }}
        >
          {`${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`}
        </Text>
        <View
          key="account-returns"
          style={{
            alignItems: 'center',
            display: 'flex',
            borderBottom:
              showSecurities &&
              securitiesReturns.filter(securityReturn => securityReturn.dailyChartData.length > 0).length > 0
                ? pdfStyles.border.border
                : undefined,
            padding: '4px 0',
            justifyContent: 'flex-start',
            flexDirection: 'row',
          }}
        >
          <Dot color={returnsSecurityColors.default || 'primary.main'} size={5} />
          <Text
            style={{
              ...commonTextStyle,
              width: '50%',
            }}
          >
            {accountNumberDisplay}
          </Text>
          <View
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
            }}
          >
            <Text style={{ ...commonTextStyle }}>{accountReturnsPercentage}</Text>
          </View>
        </View>
        {showSecurities &&
          securitiesReturns
            .filter(securityReturn => securityReturn.dailyChartData.length > 0)
            .map(({ returnValue, securityName, securityId }, index, array) => (
              <View
                key={securityId}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  borderBottom: index === array.length - 1 ? undefined : pdfStyles.border.border,
                  padding: '4px 0',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                }}
              >
                <Dot color={returnsSecurityColors[securityId] || 'primary.main'} size={5} />
                <Text
                  style={{
                    ...commonTextStyle,
                    width: '55%',
                  }}
                >
                  {formatSecurityName(securityName)}
                </Text>
                <View
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                  }}
                >
                  <Text style={{ ...commonTextStyle }}>{returnValue}</Text>
                </View>
              </View>
            ))}
      </View>
      <View style={{ paddingTop: '50px' }}>{imageUrl && <Image src={imageUrl} />}</View>
    </View>
  );
};
