import { getPaperworkStringFieldValue } from '../../../configs/questions/utils';
import {
  ManagedProductPaperworkWithFreeFormId,
  PaperworkInputWithFreeFormId,
} from '../../../hooks/useGetPaperworkData';
import { FormData } from '../../../types';
import { getFieldName } from '../../utils';

import { defaultAssetsHeldAwayConfig } from './config';

export const getUpdatedTotalAndPercentageArray = (
  fieldValues: number[],
): { percentageArray: number[]; totalValue: number } => {
  const totalAssetValues = roundToTwoDecimal(
    fieldValues.reduce<number>((total, currValue) => {
      total += currValue;
      return total;
    }, 0),
  );

  let sumOfAllPercentage = 0;
  const newPercentageArray = fieldValues.map(value => {
    const percentageValue = totalAssetValues > 0 ? Math.round((value / totalAssetValues) * 100) : 0;
    sumOfAllPercentage += percentageValue;
    return percentageValue;
  });

  const largestValueIndicesArray = [...newPercentageArray]
    .map((value, index) => ({ value, index }))
    .sort((a, b) => b.value - a.value)
    .map(v => v.index);

  // updating the largest asset value percentage to make sum equals to 100
  while (100 - sumOfAllPercentage !== 0 && sumOfAllPercentage !== 0) {
    const nextLargestAssetValueIndex = largestValueIndicesArray.shift() ?? 0;
    if (100 - sumOfAllPercentage < 0) {
      newPercentageArray[nextLargestAssetValueIndex] -= 1;
      sumOfAllPercentage -= 1;
    } else {
      newPercentageArray[nextLargestAssetValueIndex] += 1;
      sumOfAllPercentage += 1;
    }
  }

  return { totalValue: totalAssetValues, percentageArray: newPercentageArray };
};

export const roundToTwoDecimal = (value: number): number => {
  return Math.round(value * 100) / 100;
};

export const populateAssetsHeldAway = (
  paperworkInput: PaperworkInputWithFreeFormId,
  data: {
    allSavedPaperworkData: ManagedProductPaperworkWithFreeFormId[];
    dependentFormFieldsInvisibility: Record<string, boolean>;
    formValues: FormData;
    savedPaperworkData?: ManagedProductPaperworkWithFreeFormId;
    unmaskedValues: Record<string, string>;
  },
  paperworkFreeFormId: string | undefined,
) => {
  if (!paperworkInput.investment?.experienceDetail) {
    paperworkInput.investment = {
      ...paperworkInput.investment,
      experienceDetail: {
        alternativeInvestments: {},
        annuitiesAndLifeInsurance: {},
        bonds: {},
        foreignCurrency: {},
        foreignSecurity: {},
        lifeInsurance: {},
        limitedPartnerships: {},
        mutualFunds: {},
        options: {},
        other: {},
        shortTerms: {},
        securityFutures: {},
        stocks: {},
        total: {},
        variableContracts: {},
      },
    };
  }

  const { allProvidedValues, allProvidedFields } = defaultAssetsHeldAwayConfig.questions.reduce<{
    allProvidedFields: string[];
    allProvidedValues: number[];
  }>(
    (acc, next) => {
      const fieldName = getFieldName(next.dataPointKey, paperworkFreeFormId);
      const value = getPaperworkStringFieldValue(data.formValues[fieldName]);
      if (value) {
        acc.allProvidedValues.push(Number(value));
        acc.allProvidedFields.push(fieldName);
      }
      return acc;
    },
    { allProvidedFields: [], allProvidedValues: [] },
  );

  const { totalValue, percentageArray } = getUpdatedTotalAndPercentageArray(allProvidedValues);

  defaultAssetsHeldAwayConfig.questions.forEach(assetConfig => {
    const fieldName = getFieldName(assetConfig.dataPointKey, paperworkFreeFormId);
    const indexFromProvidedFields = allProvidedFields.indexOf(fieldName);
    if (indexFromProvidedFields !== -1) {
      assetConfig.paperworkInputSetter(
        paperworkInput,
        allProvidedValues[indexFromProvidedFields].toString(),
        percentageArray[indexFromProvidedFields],
      );
    }
  });

  if (paperworkInput.investment.experienceDetail) {
    paperworkInput.investment.experienceDetail.total = {
      ...paperworkInput.investment.experienceDetail.total,
      qualified: totalValue.toString(),
    };
  }
};
