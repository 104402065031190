import { DataPointKeys } from '../../../configs/questions/types';
import {
  defaultRegulatoryExchangeAffiliationTypeValue,
  getPaperworkBooleanFieldValue,
  getPaperworkEnumFieldValue,
  getPaperworkStringFieldValue,
  regulatorySameAsEmployerNoValue,
  regulatorySameAsEmployerYesValue,
} from '../../../configs/questions/utils';
import {
  ManagedProductPaperworkWithFreeFormId,
  PaperworkInputWithFreeFormId,
} from '../../../hooks/useGetPaperworkData';
import { FormData } from '../../../types';
import { getFieldName } from '../../utils';

import { AddressType, ControlType, RegulatoryRelationship } from '~/__generated__';
import { CMSQuestions } from '~/containers/Paperwork/contentstack';

export const isInputLengthValid = (input: string, maxLength?: number) => !maxLength || input.length <= maxLength;

export const isCompanyNameValid = (input: string, maxLength?: number) => {
  if (input.length > 0) {
    // The regex checks if the input value contains only alphanumeric characters and some special characters except |.
    return isInputLengthValid(input, maxLength) && /^[:;"'<>,./?0-9a-zA-Z `~!@#$%^&*()-_=+[\]{}\\]*$/.test(input);
  }
  return true;
};

export const spfCheckboxFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_SPF_CHECKBOX, paperworkFreeFormId);

export const spfNameFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_SPF_NAME, paperworkFreeFormId);

export const spfRelationshipFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_SPF_RELATIONSHIP, paperworkFreeFormId);

export const spfTitleFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_SPF_TITLE, paperworkFreeFormId);

export const spfCountryFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_SPF_COUNTRY, paperworkFreeFormId);

export const phcCheckboxFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_PHC_CHECKBOX, paperworkFreeFormId);

export const phcNameFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_PHC_NAME, paperworkFreeFormId);

export const phcTickerFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_PHC_TICKER, paperworkFreeFormId);

export const phcRelationshipFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_PHC_RELATIONSHIP, paperworkFreeFormId);

export const phcControlTypeFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_PHC_CONTROL_TYPE, paperworkFreeFormId);

export const exchangeEmployedCheckboxFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_EXCHANGE_EMPLOYED_CHECKBOX, paperworkFreeFormId);

export const sameAsEmployerFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_SAME_AS_EMPLOYER, paperworkFreeFormId);

export const exchangeCompanyNameFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_EXCHANGE_COMPANY_NAME, paperworkFreeFormId);

export const exchangeAffiliationTypeFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_EXCHANGE_AFFILIATION_TYPE, paperworkFreeFormId);

export const exchangeRelationshipFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_EXCHANGE_RELATIONSHIP, paperworkFreeFormId);

export const exchangeCompanyStreetAddressFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_EXCHANGE_COMPANY_STREET_ADDRESS, paperworkFreeFormId);

export const exchangeCompanyStreetLineFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_EXCHANGE_COMPANY_STREET_LINE, paperworkFreeFormId);

export const exchangeCompanyStateFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_EXCHANGE_COMPANY_STATE, paperworkFreeFormId);

export const exchangeCompanyCityFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_EXCHANGE_COMPANY_CITY, paperworkFreeFormId);

export const exchangeCompanyZipCodeFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_EXCHANGE_COMPANY_ZIPCODE, paperworkFreeFormId);

export const exchangeCompanyAffiliationLetterFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_EXCHANGE_COMPANY_AFFILIATION_LETTER, paperworkFreeFormId);

export const accreditedInvestorCheckboxFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_ACCREDITED_INVESTOR_CHECKBOX, paperworkFreeFormId);

export const associatedWithNonAccountHoldingBrokerDealerCheckboxFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(
    DataPointKeys.REGULATORY_ASSOCIATED_WITH_NON_ACCOUNT_HOLDING_BROKER_DEALER_CHECKBOX,
    paperworkFreeFormId,
  );

export const associatedWithAccountHoldingBrokerDealerCheckboxFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_ASSOCIATED_WITH_ACCOUNT_HOLDING_BROKER_DEALER_CHECKBOX, paperworkFreeFormId);

export const associatedWithRegisteredInvestmentAdvisorCheckboxFieldNameGetter = (paperworkFreeFormId?: string) =>
  getFieldName(DataPointKeys.REGULATORY_ASSOCIATED_WITH_REGISTERED_INVESTMENT_ADVISOR_CHECKBOX, paperworkFreeFormId);

export const populateRegulatory = (
  paperworkInput: PaperworkInputWithFreeFormId,
  data: {
    allSavedPaperworkData: ManagedProductPaperworkWithFreeFormId[];
    dependentFormFieldsInvisibility: Record<string, boolean>;
    formValues: FormData;
    savedPaperworkData?: ManagedProductPaperworkWithFreeFormId;
    unmaskedValues: Record<string, string>;
  },
  paperworkFreeFormId: string | undefined,
) => {
  paperworkInput.regulatoryInformation = {};

  const spfFieldName = spfCheckboxFieldNameGetter(paperworkFreeFormId);
  const spfNameFieldName = spfNameFieldNameGetter(paperworkFreeFormId);
  const spfRelationshipFieldName = spfRelationshipFieldNameGetter(paperworkFreeFormId);
  const spfTitleFieldName = spfTitleFieldNameGetter(paperworkFreeFormId);
  const spfCountryFieldName = spfCountryFieldNameGetter(paperworkFreeFormId);
  const phcCheckboxFieldName = phcCheckboxFieldNameGetter(paperworkFreeFormId);
  const phcNameFieldName = phcNameFieldNameGetter(paperworkFreeFormId);
  const phcTickerFieldName = phcTickerFieldNameGetter(paperworkFreeFormId);
  const phcRelationshipFieldName = phcRelationshipFieldNameGetter(paperworkFreeFormId);
  const phcControlTypeFieldName = phcControlTypeFieldNameGetter(paperworkFreeFormId);
  const exchangeCheckboxFieldName = exchangeEmployedCheckboxFieldNameGetter(paperworkFreeFormId);
  const sameAsEmployerFieldName = sameAsEmployerFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyNameFieldName = exchangeCompanyNameFieldNameGetter(paperworkFreeFormId);
  const exchangeAffiliationTypeFieldName = exchangeAffiliationTypeFieldNameGetter(paperworkFreeFormId);
  const exchangeRelationshipFieldName = exchangeRelationshipFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyStreetAddressFieldName = exchangeCompanyStreetAddressFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyStreetLineFieldName = exchangeCompanyStreetLineFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyStateFieldName = exchangeCompanyStateFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyCityFieldName = exchangeCompanyCityFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyZipCodeFieldName = exchangeCompanyZipCodeFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyAffiliationLetterFieldName = exchangeCompanyAffiliationLetterFieldNameGetter(
    paperworkFreeFormId,
  );
  const accreditedInvestorCheckboxFieldName = accreditedInvestorCheckboxFieldNameGetter(paperworkFreeFormId);
  const associatedWithNonAccountHoldingBrokerDealerCheckboxFieldName = associatedWithNonAccountHoldingBrokerDealerCheckboxFieldNameGetter(
    paperworkFreeFormId,
  );
  const associatedWithAccountHoldingBrokerDealerCheckboxFieldName = associatedWithAccountHoldingBrokerDealerCheckboxFieldNameGetter(
    paperworkFreeFormId,
  );
  const associatedWithRegisteredInvestmentAdvisorCheckboxFieldName = associatedWithRegisteredInvestmentAdvisorCheckboxFieldNameGetter(
    paperworkFreeFormId,
  );

  paperworkInput.regulatoryInformation.hasSpfConnection = getPaperworkBooleanFieldValue(data.formValues[spfFieldName]);
  paperworkInput.regulatoryInformation.isPhcSeniorOfficer = getPaperworkBooleanFieldValue(
    data.formValues[phcCheckboxFieldName],
  );
  paperworkInput.regulatoryInformation.isExchangeEmployed = getPaperworkBooleanFieldValue(
    data.formValues[exchangeCheckboxFieldName],
  );
  paperworkInput.regulatoryInformation.hasAffiliationLetter = getPaperworkBooleanFieldValue(
    data.formValues[exchangeCompanyAffiliationLetterFieldName],
  );
  paperworkInput.regulatoryInformation.isAccreditedInvestor = getPaperworkBooleanFieldValue(
    data.formValues[accreditedInvestorCheckboxFieldName],
  );
  paperworkInput.regulatoryInformation.isEmployedByAccountBroker = getPaperworkBooleanFieldValue(
    data.formValues[associatedWithAccountHoldingBrokerDealerCheckboxFieldName],
  );
  paperworkInput.regulatoryInformation.isEmployedByAnotherBroker = getPaperworkBooleanFieldValue(
    data.formValues[associatedWithNonAccountHoldingBrokerDealerCheckboxFieldName],
  );
  paperworkInput.regulatoryInformation.isAssociatedWithUsRegisteredInvestmentAdvisor = getPaperworkBooleanFieldValue(
    data.formValues[associatedWithRegisteredInvestmentAdvisorCheckboxFieldName],
  );

  if (paperworkInput.regulatoryInformation.hasSpfConnection) {
    paperworkInput.regulatoryInformation.nameOfSeniorPoliticalFigure = getPaperworkStringFieldValue(
      data.formValues[spfNameFieldName],
    );
    paperworkInput.regulatoryInformation.relationship = getPaperworkStringFieldValue(
      data.formValues[spfRelationshipFieldName],
    );
    paperworkInput.regulatoryInformation.politicalTitle = getPaperworkStringFieldValue(
      data.formValues[spfTitleFieldName],
    );
    paperworkInput.regulatoryInformation.countryOfOffice = getPaperworkStringFieldValue(
      data.formValues[spfCountryFieldName],
    );
  }

  if (paperworkInput.regulatoryInformation.isPhcSeniorOfficer) {
    paperworkInput.regulatoryInformation.publiclyHeldCompanyName = getPaperworkStringFieldValue(
      data.formValues[phcNameFieldName],
    );
    paperworkInput.regulatoryInformation.ticker = getPaperworkStringFieldValue(data.formValues[phcTickerFieldName]);
    paperworkInput.regulatoryInformation.phcRelationship = getPaperworkEnumFieldValue(
      RegulatoryRelationship,
      data.formValues[phcRelationshipFieldName],
    );
    paperworkInput.regulatoryInformation.controlType = getPaperworkEnumFieldValue(
      ControlType,
      data.formValues[phcControlTypeFieldName],
    );
  }

  if (paperworkInput.regulatoryInformation.isExchangeEmployed) {
    // Setting a default value because we don't always ask for the exchange affiliation type
    paperworkInput.regulatoryInformation.finraRelationship = getPaperworkEnumFieldValue(
      RegulatoryRelationship,
      data.formValues[exchangeRelationshipFieldName],
    );
    paperworkInput.regulatoryInformation.exchangeAffiliationType = getPaperworkStringFieldValue(
      data.formValues[exchangeAffiliationTypeFieldName],
      defaultRegulatoryExchangeAffiliationTypeValue,
    );

    const exchangeFieldsAddress = {
      addressLine1: getPaperworkStringFieldValue(data.formValues[exchangeCompanyStreetAddressFieldName]) ?? '',
      addressLine2: getPaperworkStringFieldValue(data.formValues[exchangeCompanyStreetLineFieldName]) ?? '',
      countryPrimarySubdivision: getPaperworkStringFieldValue(data.formValues[exchangeCompanyStateFieldName]) ?? '',
      countrySecondarySubdivision: getPaperworkStringFieldValue(data.formValues[exchangeCompanyCityFieldName]) ?? '',
      postalCode: getPaperworkStringFieldValue(data.formValues[exchangeCompanyZipCodeFieldName]) ?? '',
      type: AddressType.OFFICE,
    };

    if (
      // Check for null/undefined as a fallback where the value isn't selected
      !data.formValues[sameAsEmployerFieldName] ||
      data.formValues[sameAsEmployerFieldName] === regulatorySameAsEmployerNoValue
    ) {
      paperworkInput.regulatoryInformation.exchangeEmployerName = getPaperworkStringFieldValue(
        data.formValues[exchangeCompanyNameFieldName],
      );
      paperworkInput.regulatoryInformation.exchangeAddress = exchangeFieldsAddress;
    } else if (data.formValues[sameAsEmployerFieldName] === regulatorySameAsEmployerYesValue) {
      const employerName = paperworkInput.party.partyCompany?.organizationName;
      const employmentAddress = paperworkInput.party.addresses?.find(address => address.type === AddressType.OFFICE);
      paperworkInput.regulatoryInformation.exchangeEmployerName =
        employerName ?? data.formValues[exchangeCompanyNameFieldName];
      paperworkInput.regulatoryInformation.exchangeAddress =
        // Check that employmentAddress has valid information, otherwise use exchangeFieldsAddress
        employmentAddress?.addressLine1 ? employmentAddress : exchangeFieldsAddress;
    }
  }
};

export const getRegulatoryTickerSearchContent = (questionsContent: CMSQuestions) => {
  return {
    tickerSearchAddTickerManuallyCta:
      questionsContent.fields?.text?.find(item => item?.key === 'tickerSearchAddTickerManuallyCta')?.value ?? '',
    tickerSearchNoOptionsText:
      questionsContent.fields?.text?.find(item => item?.key === 'tickerSearchNoOptionsText')?.value ?? '',
    tickerSearchPlaceholder:
      questionsContent.fields?.text?.find(item => item?.key === 'tickerSearchPlaceholder')?.value ?? '',
  };
};
