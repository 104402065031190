import React, { useContext } from 'react';

interface InvestmentAmount {
  investmentAmount?: number;
  setInvestmentAmount?: (value?: number) => void;
}

const InvestmentAmountContext = React.createContext<InvestmentAmount | undefined>(undefined);

export const useInvestmentAmountContext = <T extends InvestmentAmount>(): T => {
  const investmentAmountData = useContext(InvestmentAmountContext);

  return investmentAmountData as T;
};

export const InvestmentAmountProvider = InvestmentAmountContext.Provider;
