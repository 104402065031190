import React, { FC, ReactNode } from 'react';

import { Alert } from '~/components/ui/Alert';
import { Box, Button, Grid, Skeleton, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { useIsMediumScreen } from '~/utils/responsiveness';
import { SfTheme } from '~/utils/theme';

export interface CompletionMessageContent {
  heading?: string;
  helperTexts?: {
    heading?: string;
    subHeading?: string;
  };
  imageUrl?: string;
  moreInfo?: string;
  primaryCTA?: string;
  secondaryCTA?: string;
  subHeading?: { config?: Record<string, ReactNode>; data: string };
  subHeadingText?: string;
}

export interface Props {
  content: CompletionMessageContent;
  contentError: any;
  contentLoading: boolean;
  contentOptions: ContentOptions;
  dataQa?: string;
  isUserLoggedIn?: boolean;
  onPrimaryCtaCallback?: () => void;
  onSecondaryCtaCallback?: () => void;
}

export const CompletionMessage: FC<Props> = ({
  dataQa = 'on-success',
  contentOptions,
  onPrimaryCtaCallback,
  onSecondaryCtaCallback,
  isUserLoggedIn = false,
  content,
  contentError,
  contentLoading,
}) => {
  const {
    sfOnboardingCompleted: { styles: style, typographyVariants },
  } = useTheme<SfTheme>();

  const isMediumScreen = useIsMediumScreen();

  return (
    <Grid
      alignItems="center"
      container
      data-qa={dataQa}
      direction="column"
      justifyContent="center"
      sx={{ minHeight: isMediumScreen ? '100vh' : '80vh', display: 'flex' }}
      textAlign="center"
    >
      {contentLoading ? (
        <Grid item>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Grid>
      ) : contentError ? (
        <Grid item>
          <Alert contentOptions={contentOptions} error={contentError} severity="error" />
        </Grid>
      ) : (
        <>
          <Grid item>
            {content?.imageUrl && (
              <Box data-qa={`${dataQa}-image`} sx={{ height: '100px', pb: 2 }}>
                <img alt="" src={content.imageUrl} />
              </Box>
            )}
            {content?.heading && (
              <Typography
                data-qa={`${dataQa}-heading`}
                sx={{ color: `${style.headingColor}`, pb: 2 }}
                variant={typographyVariants?.heading}
              >
                {content.heading}
              </Typography>
            )}
            {content?.subHeading && (
              <RteContent
                config={content.subHeading.config}
                data={content.subHeading.data ?? ''}
                sx={{ color: `${style.subHeadingColor}` }}
              />
            )}
            {content?.subHeadingText && (
              <Typography data-qa={`${dataQa}-sub-heading`} sx={{ pb: 2 }} variant={typographyVariants?.subHeading}>
                {content.subHeadingText}
              </Typography>
            )}
            {content?.moreInfo && (
              <Typography data-qa={`${dataQa}-more-info`} variant={typographyVariants?.subtitle2}>
                {content.moreInfo}
              </Typography>
            )}
          </Grid>
          {isUserLoggedIn && (
            <Grid container direction={isMediumScreen ? 'column-reverse' : 'row'} justifyContent="center" spacing={2}>
              {content?.secondaryCTA && (
                <Grid item>
                  <Button
                    data-qa={`${dataQa}-cta-secondary`}
                    fullWidth={isMediumScreen}
                    onClick={onSecondaryCtaCallback}
                    variant="outlined"
                  >
                    {content.secondaryCTA}
                  </Button>
                </Grid>
              )}
              {content?.primaryCTA && (
                <Grid item>
                  <Button
                    data-qa={`${dataQa}-cta-primary`}
                    fullWidth={isMediumScreen}
                    onClick={onPrimaryCtaCallback}
                    variant="contained"
                  >
                    {content.primaryCTA}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
