// Epic: https://sigfig.atlassian.net/browse/DA2-882
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useModelPortfolioDetails } from './hooks/useModelPortfolioDetails';
import { useGetAccountBalances } from './symphony';
import {
  AssetAllocationModalState,
  ModelPortfolioSleeve,
  Portfolio,
  PortfolioSelectionProps,
  StrategyMode,
} from './types';
import { getFilteredPortfolios, getPortfolioSelectionHeaderContent } from './utils';

import { BalanceType, PlanUpdateWorkflowStatus } from '~/__generated__';
import { AssetAllocationModal } from '~/components/modals/AssetAllocation';
import { IncompatiblePortfolioModal } from '~/components/modals/IncompatiblePortfolio';
import { InvestmentRestrictions } from '~/components/modals/InvestmentRestrictions';
import { FilteredPortfolioSelection } from '~/components/PortfolioSelection/FilteredPortfolioSelection';
import { InvestmentAmountWarningModal } from '~/components/PortfolioSelection/InvestmentAmountWarningModal';
import { KeepCurrentPortfolioModal } from '~/components/PortfolioSelection/KeepCurrentPortfolioModal';
import { PortfolioSelectionHeader } from '~/components/PortfolioSelection/PortfolioSelectionHeader';
import { ShoppingCart } from '~/components/PortfolioSelection/ShoppingCart';
import { useValidateRestriction } from '~/components/PortfolioSelection/symphony';
import { SleeveTemplates, SleeveTemplatesTabs } from '~/components/SleeveTemplates';
import { SleeveAllocationData } from '~/components/SleeveTemplates/SleeveTemplateCard';
import { SleeveTemplateData } from '~/components/SleeveTemplates/utils';
import { Alert } from '~/components/ui/Alert';
import { Divider } from '~/components/ui/Divider';
import { Link } from '~/components/ui/Link';
import { useModalState } from '~/components/ui/Modal/hooks';
import { EditIcon, Grid, IconButton, InfoOutlinedIcon, Skeleton, Stack, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ComparisonContentConfirmationModalType, usePortfolioComparison } from '~/hooks/comparison';
import { getComparisonModalContent } from '~/hooks/comparison/utils';
import { useUpdatePlanUpdateWorkflow } from '~/hooks/plan/symphony';
import { useInvestmentAmountContext } from '~/hooks/portfolio/investmentAmountContext';
import { useRecommendedProduct } from '~/hooks/questionnaire/useRecommendedProduct';
import { useCoreConfig } from '~/utils/config';
import { SfTheme } from '~/utils/theme';

const PortfolioSelection: FC<PortfolioSelectionProps> = ({
  actorPartyId,
  dataQa = 'portfolio-selection',
  contentOptions,
  managedProductId,
  onBack,
  partyId,
  portfolioSelectionData,
  productType,
  isRce,
  assetClassTier,
  onKeepCurrentPortfolio,
}) => {
  const riskScore =
    portfolioSelectionData?.data?.riskScoreUser ?? portfolioSelectionData?.data?.calculatedRecommendations?.riskScore;
  const initialFundingAmountFromQuestionnaire = portfolioSelectionData?.data?.initialFundingAmountFromQuestionnaire;

  const [selectedStrategyMode, setSelectedStrategyMode] = useState<StrategyMode>(StrategyMode.SINGLE);
  const [currentTab, setCurrentTab] = useState(SleeveTemplatesTabs.System);
  const [quickApplySleeveTemplate, setQuickApplySleeveTemplate] = useState<SleeveTemplateData | undefined>(undefined);
  const [sleeveData, setSleeveData] = useState<ModelPortfolioSleeve[]>([]);
  const [allocationValue, setAllocationValue] = useState<SleeveAllocationData[] | undefined>(undefined);
  const [allocationModalPropsToUse, setAllocationModalPropsToUse] = useState<AssetAllocationModalState | undefined>(
    undefined,
  );
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [isBusy, setIsBusy] = useState(false);
  const [onValidateError, setOnValidateError] = useState<boolean>(false);
  const {
    investmentAmount: investmentAmountValue,
    setInvestmentAmount: setInvestmentAmountValue,
  } = useInvestmentAmountContext();
  const [updatePlanUpdateWorkflow] = useUpdatePlanUpdateWorkflow();
  const [validateRestriction] = useValidateRestriction();

  const {
    featureFlags: { msiEnabled },
    components: {
      sfPortfolioSelection: { assetAllocationTableColumns, showInvestmentAmount },
    },
  } = useCoreConfig();

  const {
    open: isOpenConfirmationModal,
    openModal: openConfirmationModal,
    onClose: closeConfirmationModal,
  } = useModalState();

  const {
    open: openAssetAllocation,
    openModal: openAssetAllocationModal,
    onClose: onCloseAssetAllocationModal,
  } = useModalState();

  const {
    open: openEditRestriction,
    openModal: openEditRestrictionModal,
    onClose: onCloseEditRestrictionModal,
  } = useModalState();

  const {
    open: openIncompatible,
    openModal: openIncompatibleModal,
    onClose: onCloseIncompatibleModal,
  } = useModalState();

  const { open: openWarning, openModal: openWarningModal, onClose: closeWarningModal } = useModalState();

  const {
    data: currentPortfolioData,
    loading: currentPortfolioDataLoading,
    error: currentPortfolioDataError,
  } = usePortfolioComparison({
    assetClassTier,
    contentOptions,
    managedProductId,
    partyId,
    skip: !isRce,
  });

  const {
    data: recommendedProductData,
    loading: recommendedProductLoading,
    error: recommendedProductError,
  } = useRecommendedProduct({
    contentOptions,
    recommendedProduct: portfolioSelectionData?.data?.calculatedRecommendations ?? undefined,
    riskScoreUser: riskScore ?? undefined,
    skip: !portfolioSelectionData?.data,
  });

  const {
    data: modelPortfolioDetails,
    loading: modelPortfolioDetailsLoading,
    error: modelPortfolioDetailsError,
  } = useModelPortfolioDetails({
    partyId,
    managedProductId,
  });

  const [getAccountBalances, { data: accountBalancesData }] = useGetAccountBalances({
    variables: {
      partyId,
      managedProductId,
    },
  });

  const content = portfolioSelectionData?.data?.content;
  const headerContent = getPortfolioSelectionHeaderContent(content, recommendedProductData);
  const cardHeader = content?.cardHeader ?? {};
  const cardLabels = content?.cardLabels ?? {};
  const portfolios = portfolioSelectionData?.data?.modelPortfolios ?? [];
  const portfolioFilters = portfolioSelectionData?.data?.portfolioFiltersProps.portfolioFilters ?? [];
  const portfolioFiltersHeader = portfolioSelectionData?.data?.portfolioFiltersProps.portfolioFiltersHeader ?? '';
  const recommendedProduct = recommendedProductData;

  const {
    sfPortfolioSelection: { typographyVariants },
  } = useTheme<SfTheme>();

  const onStrategyModeChange = (strategyModeValue: StrategyMode) => {
    setSelectedStrategyMode(strategyModeValue);
    setQuickApplySleeveTemplate({} as SleeveTemplateData);
    setAllocationValue(undefined);
  };

  useEffect(() => {
    if (modelPortfolioDetails?.stagedCompositeModelPortfolioData && msiEnabled) {
      onStrategyModeChange(StrategyMode.MULTI_SLEEVE);
      setAllocationValue(modelPortfolioDetails.stagedCompositeModelPortfolioData.allocationValue);
    }
  }, [msiEnabled, modelPortfolioDetails]);

  const onQuickApplySleeveTemplate = (sleeveTemplate: SleeveTemplateData): void => {
    setQuickApplySleeveTemplate(Object.assign({}, sleeveTemplate));
  };

  const handleOpenAssetAllocationModal = (assetAllocationProps: AssetAllocationModalState) => {
    setAllocationModalPropsToUse(assetAllocationProps);
    openAssetAllocationModal();
  };

  const handleValidateRestriction = async () => {
    if (isRce && sleeveData.length) {
      try {
        setOnValidateError(false);
        const result = await validateRestriction({
          variables: {
            managedId: managedProductId,
            modelPortfolioAllocations: sleeveData.map(modelPortfolioSleeve => ({
              modelPortfolioSeriesId: modelPortfolioSleeve.modelPortfolioSeriesId,
              modelPortfolioInternalId: modelPortfolioSleeve.modelPortfolioInternalId,
              percentage: modelPortfolioSleeve.percentage,
            })),
          },
        });

        if (!result.data?.validateSecurityRestriction.isValid) {
          onCloseEditRestrictionModal();
          openIncompatibleModal();
        } else {
          handleSaveModelPortfolioSleeves(sleeveData, false);
        }
      } catch (e) {
        console.error(e);
        setOnValidateError(true);
      }
    }
  };

  const handleCloseConfirmationModal = () => {
    setErrorSaving(undefined);
    closeConfirmationModal();
  };

  const handleSaveModelPortfolioSleeves = async (
    modelPortfolioSleeves: ModelPortfolioSleeve[],
    shouldValidateRestriction = true,
  ) => {
    if (isRce && shouldValidateRestriction) {
      try {
        setOnValidateError(false);
        const result = await validateRestriction({
          variables: {
            managedId: managedProductId,
            modelPortfolioAllocations: modelPortfolioSleeves.map(modelPortfolioSleeve => ({
              modelPortfolioSeriesId: modelPortfolioSleeve.modelPortfolioSeriesId,
              modelPortfolioInternalId: modelPortfolioSleeve.modelPortfolioInternalId,
              percentage: modelPortfolioSleeve.percentage,
            })),
          },
        });

        if (!result.data?.validateSecurityRestriction.isValid) {
          openIncompatibleModal();
        } else if (portfolioSelectionData?.data?.selectModelPortfolioSleeves) {
          portfolioSelectionData.data.selectModelPortfolioSleeves(modelPortfolioSleeves);
        }
      } catch (err) {
        console.error(err);
        setOnValidateError(true);
      }
    } else if (portfolioSelectionData?.data?.selectModelPortfolioSleeves) {
      portfolioSelectionData.data.selectModelPortfolioSleeves(modelPortfolioSleeves);
    }
  };

  const accountBalance = useMemo(() => {
    const accountBalanceValue =
      accountBalancesData?.client?.financialAccounts?.[0]?.balances?.find(
        balance => balance.type === BalanceType.TOTAL_ACCOUNT,
      )?.balance.value ?? undefined;

    return accountBalanceValue ? parseFloat(accountBalanceValue) : undefined;
  }, [accountBalancesData]);

  const onSubmitPortfolioSelection = useCallback(
    (modelPortfolioSleeves: ModelPortfolioSleeve[]) => {
      const isUndefinedInvestmentAmount = investmentAmountValue === undefined;
      if (isUndefinedInvestmentAmount && !isRce && showInvestmentAmount) {
        setSleeveData(modelPortfolioSleeves);
        openWarningModal();
      } else {
        handleSaveModelPortfolioSleeves(modelPortfolioSleeves);
      }
    },
    [handleSaveModelPortfolioSleeves, investmentAmountValue, isRce, openWarningModal, showInvestmentAmount],
  );

  const handleSelectSinglePortfolio = useCallback(
    (portfolio: Portfolio) => {
      const modelPortfolioSleeves = [
        {
          modelPortfolioSeriesId: portfolio.seriesId,
          modelPortfolioInternalId: portfolio.internalId,
          percentage: null,
        },
      ];
      onSubmitPortfolioSelection(modelPortfolioSleeves);
    },
    [onSubmitPortfolioSelection],
  );

  const onConfirmWarningModal = useCallback(() => {
    if (sleeveData) {
      handleSaveModelPortfolioSleeves(sleeveData);
    }
  }, [handleSaveModelPortfolioSleeves, sleeveData]);

  const onCancelWarningModal = useCallback(() => {
    setSleeveData([]);
    closeWarningModal();
  }, [closeWarningModal]);

  const handleKeepCurrentPortfolio = async () => {
    if (currentPortfolioData) {
      const { planId, planUpdateWorkflowId } = currentPortfolioData;
      if (!planId || !planUpdateWorkflowId) {
        throw new Error(
          `Invalid PlanId: ${currentPortfolioData.planId} or PlanUpdateWorkflowId: ${currentPortfolioData.planUpdateWorkflowId}`,
        );
      }
      try {
        setIsBusy(true);
        await updatePlanUpdateWorkflow({
          variables: {
            planId,
            planUpdateWorkflowId,
            status: PlanUpdateWorkflowStatus.CANCELLED,
          },
        });
        onKeepCurrentPortfolio?.(false);
      } catch (err: any) {
        setErrorSaving(err);
        console.error(err);
      } finally {
        setIsBusy(false);
      }
    } else {
      throw new Error(`Invalid CurrentPortfolioData`);
    }
  };

  useEffect(() => {
    // Lazy fetching account balance, as it is needed only for Retake RTQ flow with enabled investment amount filter
    if (isRce && showInvestmentAmount) {
      getAccountBalances();
    }
  }, []);

  useEffect(() => {
    setInvestmentAmountValue?.(investmentAmountValue ?? accountBalance);
  }, [accountBalance, investmentAmountValue, setInvestmentAmountValue]);

  const handleChangeInvestmentAmount = useCallback((value?: number) => setInvestmentAmountValue?.(value), [
    setInvestmentAmountValue,
  ]);

  useEffect(() => {
    if (initialFundingAmountFromQuestionnaire) {
      handleChangeInvestmentAmount(initialFundingAmountFromQuestionnaire);
    }
  }, [initialFundingAmountFromQuestionnaire, handleChangeInvestmentAmount]);

  const keepModalContent = getComparisonModalContent(
    currentPortfolioData?.comparisonContent?.confirmation_modal?.find(modal => {
      const modalType: ComparisonContentConfirmationModalType = 'Keep';
      return modal?.confirmation_modal_type === modalType;
    }),
  );
  return (
    <Grid container data-qa={dataQa} item xs={12}>
      <Grid item xs={12}>
        {recommendedProductLoading ||
        portfolioSelectionData?.loading ||
        modelPortfolioDetailsLoading ||
        currentPortfolioDataLoading ? (
          <Stack spacing={2}>
            <Skeleton height={100} sx={{ margin: 'auto' }} variant="circular" width={100} />
            <Skeleton height={200} variant="rectangular" />
            <Skeleton height={500} variant="rectangular" />
          </Stack>
        ) : recommendedProductError ||
          portfolioSelectionData?.error ||
          modelPortfolioDetailsError ||
          currentPortfolioDataError ? (
          <Alert
            contentOptions={contentOptions}
            error={
              recommendedProductError ||
              portfolioSelectionData?.error ||
              modelPortfolioDetailsError ||
              currentPortfolioDataError
            }
            severity="error"
          />
        ) : (
          <>
            <PortfolioSelectionHeader
              accountBalance={accountBalance}
              assetClassTier={assetClassTier}
              content={headerContent}
              contentOptions={contentOptions}
              currentPortfolioData={currentPortfolioData}
              initialInvestmentAmountValue={initialFundingAmountFromQuestionnaire ?? accountBalance}
              investmentAmountValue={investmentAmountValue}
              isRce={isRce}
              onBack={onBack}
              onInvestmentAmountChange={handleChangeInvestmentAmount}
              onStrategyModeChange={onStrategyModeChange}
              openAssetAllocationModal={handleOpenAssetAllocationModal}
              openConfirmationModal={openConfirmationModal}
              recommendedProduct={recommendedProduct}
              selectedStrategyMode={selectedStrategyMode}
            />

            <Divider flexItem sx={{ mb: 4 }} />

            {onValidateError && (
              <Grid item sx={{ py: 2 }}>
                <Alert
                  contentOptions={contentOptions}
                  error="Validation Failed"
                  onClose={() => setOnValidateError(false)}
                  severity="error"
                />
              </Grid>
            )}
            {selectedStrategyMode === StrategyMode.SINGLE ? (
              <>
                <FilteredPortfolioSelection
                  filterProps={{ portfolioFilters, portfolioFiltersHeader }}
                  higherMinimumWarningMessage={content?.higherMinimumWarningMessage}
                  investmentAmount={investmentAmountValue}
                  isTaxShelteredAccountFromQuestionnaire={
                    portfolioSelectionData?.data?.isTaxShelteredAccountFromQuestionnaire
                  }
                  nullStateMessage={content?.nullStateMessage ?? ''}
                  portfolioCardProps={{
                    assetClassTier,
                    content: {
                      header: cardHeader,
                      labels: cardLabels,
                    },
                    contentOptions,
                    filters: portfolioFilters,
                    openAssetAllocationModal: handleOpenAssetAllocationModal,
                    portfolios: getFilteredPortfolios(
                      portfolios,
                      riskScore ?? undefined,
                      modelPortfolioDetails,
                      showInvestmentAmount ? investmentAmountValue : undefined,
                      isRce,
                    ),
                    selectSinglePortfolio: handleSelectSinglePortfolio,
                  }}
                />
              </>
            ) : (
              <>
                <ShoppingCart
                  allocationValue={allocationValue}
                  assetClassTier={assetClassTier}
                  contentOptions={contentOptions}
                  investmentAmountValue={investmentAmountValue}
                  isRce={isRce}
                  modelPortfolios={portfolios}
                  openAssetAllocationModal={handleOpenAssetAllocationModal}
                  partyId={actorPartyId}
                  productType={productType}
                  quickApplySleeveTemplate={quickApplySleeveTemplate}
                  riskScore={riskScore ?? 0}
                  selectModelPortfolioSleeves={onSubmitPortfolioSelection}
                  setCurrentTab={setCurrentTab}
                />
                <SleeveTemplates
                  assetClassTier={assetClassTier}
                  contentOptions={contentOptions}
                  currentTab={currentTab}
                  initialRiskPreference={riskScore ?? 0}
                  modelPortfolios={portfolios}
                  onQuickApplySleeveTemplate={onQuickApplySleeveTemplate}
                  openAssetAllocationModal={handleOpenAssetAllocationModal}
                  partyId={actorPartyId}
                  productType={productType}
                  setCurrentTab={setCurrentTab}
                />
              </>
            )}

            {!isRce && showInvestmentAmount && (
              <InvestmentAmountWarningModal
                content={content?.investmentAmountWarningModal}
                onClose={onCancelWarningModal}
                onConfirm={onConfirmWarningModal}
                open={openWarning}
              />
            )}

            {isRce && (
              <IncompatiblePortfolioModal
                contentOptions={contentOptions}
                onClose={onCloseIncompatibleModal}
                open={openIncompatible}
                openEditRestrictionModal={() => {
                  openEditRestrictionModal();
                  onCloseIncompatibleModal();
                }}
              />
            )}

            {isRce && (
              <InvestmentRestrictions
                contentOptions={contentOptions}
                managedProductId={managedProductId}
                onClose={onCloseEditRestrictionModal}
                onUpdateValidateRestriction={handleValidateRestriction}
                open={openEditRestriction}
                partyId={partyId}
              />
            )}

            {allocationModalPropsToUse && (
              <AssetAllocationModal
                columns={assetAllocationTableColumns}
                contentOptions={contentOptions}
                onClose={onCloseAssetAllocationModal}
                open={openAssetAllocation}
                {...allocationModalPropsToUse}
              />
            )}
            {isRce && currentPortfolioData && (
              <KeepCurrentPortfolioModal
                content={{
                  ...keepModalContent,
                  modalTitle: currentPortfolioData.comparisonContent?.keep_button_text ?? '',
                }}
                contentOptions={contentOptions}
                errorSaving={errorSaving}
                handleKeepCurrentPortfolio={handleKeepCurrentPortfolio}
                isBusy={isBusy}
                isOpen={isOpenConfirmationModal}
                onClose={handleCloseConfirmationModal}
              />
            )}
          </>
        )}
      </Grid>
      <Grid item sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }} xs={12}>
        <Grid data-qa={`${dataQa}-edit-risk-tolerance`} sx={{ display: 'flex', justifyContent: 'flex-start', my: 1 }}>
          <Grid sx={{ padding: 0, mr: 0.5, mt: -0.2, transform: 'scale(0.8)' }}>
            <InfoOutlinedIcon />
          </Grid>
          <Typography sx={{ mr: 1 }} variant="body2">
            {content?.strategyUnavailableText ?? ''}
          </Typography>
          <Link
            data-qa={`${dataQa}-edit-risk-tolerance-link`}
            onClick={() => onBack()}
            variant={typographyVariants?.editRiskToleranceLink}
          >
            {content?.header.riskToleranceEditLinkText}
          </Link>
          <IconButton
            disableRipple
            onClick={() => onBack()}
            sx={{ padding: 0, ml: 0.5, mt: -0.2, transform: 'scale(0.7)' }}
          >
            <EditIcon color="primary" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PortfolioSelection;
