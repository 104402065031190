import { QuestionOrderSteps as QuestionOrderStep } from '../../../Section/types';
import {
  drivingLicenseIdentificationNumber,
  identificationType,
  passportIdentificationNumber,
  ssn,
} from '../../questions/identifiers';
import { QuestionProperties } from '../../questions/types';

export const drivingLicenseIdentificationNumberNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return drivingLicenseIdentificationNumber({
    ...properties,
    additionalValidations: {
      maxLength: 30,
      ...properties.additionalValidations,
    },
  });
};

export const passportIdentificationNumberNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return passportIdentificationNumber({
    ...properties,
    additionalValidations: {
      maxLength: 9,
      ...properties.additionalValidations,
    },
  });
};

export const ssnNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return ssn({
    ...properties,
    disabledIfPrefilled: true,
  });
};

export const identificationTypeNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return identificationType({
    ...properties,
    allowedOptions: ['PASSPORT', 'DRIVING_LICENSE'],
  });
};
