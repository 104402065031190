import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { HelperTextContent } from '~/components/ui/HelperTextContent';
import { Modal } from '~/components/ui/Modal';
import { Button, Grid } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { CurrencyTextField } from '~/components/ui/TextField/CurrencyTextField';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { formatCurrency } from '~/utils/format';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface Content {
  accountBalanceLabel?: string;
  cancelButtonText?: string;
  existingCustomerInfoText?: string;
  infoText?: string;
  inputCurrency?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  lessThanAccountMinimum?: string;
  noAmountEnteredText?: string;
  resetValueButtonText?: string;
  saveButtonText?: string;
  title?: string;
}

export interface Props {
  accountBalance?: number;
  accountMinimumBalanceForAccountOpening?: number;
  content?: Content;
  contentOptions: ContentOptions;
  dataQa?: string;
  initialInvestmentAmountValue?: number;
  investmentAmountValue?: number;
  isRce?: boolean;
  onClose: () => void;
  onInvestmentAmountChange: (value?: number) => void;
  open: boolean;
}

export const InvestmentAmountModal: FC<Props> = ({
  accountBalance,
  accountMinimumBalanceForAccountOpening,
  content,
  contentOptions,
  dataQa = 'investment-amount-modal',
  initialInvestmentAmountValue,
  investmentAmountValue,
  isRce,
  onClose,
  onInvestmentAmountChange,
  open,
}) => {
  const [formValue, setFormValue] = useState<number | undefined>(initialInvestmentAmountValue ?? investmentAmountValue);
  const [hardBlock, setHardBlock] = useState<boolean>(false);
  const isMediumScreen = useIsMediumScreen();

  // If accountMinimumBalanceForAccountOpening is undefined or zero we do not want to run Validation on initial Investment amount.
  const isValidFormValue = useMemo(
    () => !accountMinimumBalanceForAccountOpening || (formValue && accountMinimumBalanceForAccountOpening <= formValue),
    [formValue],
  );

  /**
   * if accountMinimumBalanceForAccountOpening is not present or is 0, we do not want to hide the buttons.
   * if it is present, and initialInvestmentAmountValue is undefined then we don't want to show
   * the CTA, since getting this value is mandatory.
   */
  const showResetCancelCta = !!initialInvestmentAmountValue || !accountMinimumBalanceForAccountOpening;

  useEffect(() => {
    if (hardBlock && isValidFormValue) {
      setHardBlock(false);
    }
  }, [hardBlock, isValidFormValue]);

  const submitInvestmentAmount = useCallback(() => {
    // Reset to new investment amount value on submit button click
    if (isValidFormValue) {
      onInvestmentAmountChange(formValue);
      onClose();
    } else {
      setHardBlock(true);
    }
  }, [formValue, onClose, onInvestmentAmountChange, isValidFormValue]);

  const resetInvestmentAmount = useCallback(() => {
    setHardBlock(false);
    onInvestmentAmountChange(initialInvestmentAmountValue);
    setFormValue(initialInvestmentAmountValue);
  }, [initialInvestmentAmountValue, onClose]);

  const onCloseInvestmentAmountModal = useCallback(() => {
    if (showResetCancelCta || investmentAmountValue !== undefined) {
      setHardBlock(false);
      setFormValue(investmentAmountValue ?? initialInvestmentAmountValue);
      onClose();
    } else {
      setHardBlock(true);
    }
  }, [initialInvestmentAmountValue, investmentAmountValue, onClose, showResetCancelCta]);

  return (
    <Modal
      actions={
        <Grid container md={12}>
          <Grid item md={4}>
            {showResetCancelCta && (
              <Button
                disabled={formValue === initialInvestmentAmountValue}
                onClick={resetInvestmentAmount}
                variant="text"
              >
                {content?.resetValueButtonText}
              </Button>
            )}
          </Grid>
          <Grid item md={8} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
            {showResetCancelCta && (
              <Button onClick={onCloseInvestmentAmountModal} sx={{ mr: 2 }} variant="outlined">
                {content?.cancelButtonText}
              </Button>
            )}
            <Button disabled={!formValue} onClick={submitInvestmentAmount} variant="contained">
              {content?.saveButtonText}
            </Button>
          </Grid>
        </Grid>
      }
      content={
        <>
          <Typography sx={{ mb: 2, color: 'text.primary' }} variant="body2">
            {content?.infoText}
          </Typography>
          {isRce && (
            <Typography sx={{ mb: 2, color: 'text.primary' }} variant="body2">
              {content?.existingCustomerInfoText}
            </Typography>
          )}
          <Grid container>
            <Grid item xs={6}>
              <CurrencyTextField
                autoFocus
                contentOptions={contentOptions}
                currencySymbol={content?.inputCurrency}
                fullWidth={isMediumScreen}
                inputProps={{
                  sx: { pr: 1.5, py: 1.5, width: '228px' },
                }}
                label={content?.inputLabel}
                name="investmentAmount"
                onValueChange={({ value: newValue }) => setFormValue(parseFloat(newValue))}
                placeholder={content?.inputPlaceholder}
                value={formValue ?? ''}
              />
            </Grid>
            {isRce && (
              <Grid item sx={{ pl: 1.5 }} xs={6}>
                <Typography variant="caption">{content?.accountBalanceLabel}</Typography>
                <Typography sx={{ mt: 1 }} variant="body1">
                  {formatCurrency(accountBalance ?? 0, { locale: contentOptions.locale })}
                </Typography>
              </Grid>
            )}
          </Grid>
          {hardBlock && (
            <HelperTextContent error id="investmentAmount-helper-text" severity="error">
              {!formValue ? (
                content?.noAmountEnteredText
              ) : (
                <RteContent
                  config={{ minimumBalanceForAccountOpening: accountMinimumBalanceForAccountOpening }}
                  data={content?.lessThanAccountMinimum ?? ''}
                />
              )}
            </HelperTextContent>
          )}
        </>
      }
      dataQa={`${dataQa}-content`}
      onClose={showResetCancelCta ? onCloseInvestmentAmountModal : undefined}
      open={open}
      title={content?.title}
    />
  );
};
