import { GetPortfolioDetailsContent, GetPortfolioDetailsContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetPortfolioDetailsContent = (
  options?: QueryHookOptions<GetPortfolioDetailsContent, GetPortfolioDetailsContentVariables>,
) => {
  return useContentstackQuery(queries.GetPortfolioDetailsContent, options);
};
