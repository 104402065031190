import { SavePortfolioSelection, SavePortfolioSelectionVariables } from './__generated__/mutation.v2';
import {
  GetPortfolioSelectionData,
  GetPortfolioSelectionData_managedProduct_calculatedRecommendations,
  GetPortfolioSelectionDataVariables,
  GetPortfolioSelectionModelPortfolios,
  GetPortfolioSelectionModelPortfolios_modelPortfoliosV2_modelPortfolios_tags,
  GetPortfolioSelectionModelPortfolios_modelPortfoliosV2_modelPortfolios_tags_BooleanAttribute,
  GetPortfolioSelectionModelPortfoliosVariables,
} from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type CalculatedRecommendations = GetPortfolioSelectionData_managedProduct_calculatedRecommendations;
export type Tag = GetPortfolioSelectionModelPortfolios_modelPortfoliosV2_modelPortfolios_tags;
export type BooleanAttributeTag = GetPortfolioSelectionModelPortfolios_modelPortfoliosV2_modelPortfolios_tags_BooleanAttribute;

export const isBooleanAttribute = (tag: Tag): tag is BooleanAttributeTag => tag.__typename === 'BooleanAttribute';

export const useSavePortfolioSelection = (
  options?: MutationHookOptions<SavePortfolioSelection, SavePortfolioSelectionVariables>,
): MutationTuple<SavePortfolioSelection, SavePortfolioSelectionVariables> =>
  useSymphonyMutation(mutations.SavePortfolioSelection, options);

export const useGetPortfolioSelectionData = (
  options?: QueryHookOptions<GetPortfolioSelectionData, GetPortfolioSelectionDataVariables>,
): QueryResult<GetPortfolioSelectionData, GetPortfolioSelectionDataVariables> =>
  useSymphonyQuery(queries.GetPortfolioSelectionData, options);

export const useGetPortfolioSelectionModelPortfolios = (
  options?: QueryHookOptions<GetPortfolioSelectionModelPortfolios, GetPortfolioSelectionModelPortfoliosVariables>,
): QueryResult<GetPortfolioSelectionModelPortfolios, GetPortfolioSelectionModelPortfoliosVariables> =>
  useSymphonyQuery(queries.GetPortfolioSelectionModelPortfolios, options);
