import { ModelPortfolioDetails } from './hooks/useModelPortfolioDetails';
import { Portfolio, PortfolioSelectionContent } from './types';

import { Content as PortfolioSelectionHeaderContent } from '~/components/PortfolioSelection/PortfolioSelectionHeader';
import { RecommendedProductData } from '~/hooks/questionnaire/useRecommendedProduct';

export const isRiskScoreInModelPortfolioRiskRange = (modelPortfolio: Portfolio, riskScore: number) => {
  const range = modelPortfolio.riskRange;
  return !range || (riskScore >= range.min && riskScore <= range.max);
};

export const getFilteredPortfolios = (
  portfolios: Portfolio[],
  riskScore?: number,
  modelPortfolioDetails?: ModelPortfolioDetails,
  investmentAmountValue?: number,
  isRce?: boolean,
) => {
  const targetSingleModelPortfolioDetails = modelPortfolioDetails?.targetSingleModelPortfolioData;
  return portfolios.filter(modelPortfolio => {
    const isSameAsCurrentTargetSingleModelPortfolio =
      targetSingleModelPortfolioDetails &&
      modelPortfolio.internalId === targetSingleModelPortfolioDetails.internalId &&
      modelPortfolio.seriesId === targetSingleModelPortfolioDetails.seriesId;
    const isRiskScoreInRange =
      riskScore === undefined || isRiskScoreInModelPortfolioRiskRange(modelPortfolio, riskScore);
    const isMinimumLesserThanInvestmentAmount =
      investmentAmountValue === undefined ||
      (isRce
        ? !modelPortfolio.accountMinimumBalanceForRebalance ||
          parseFloat(modelPortfolio.accountMinimumBalanceForRebalance) <= investmentAmountValue
        : !modelPortfolio.accountMinimumBalanceForAccountOpening ||
          parseFloat(modelPortfolio.accountMinimumBalanceForAccountOpening) <= investmentAmountValue);
    return !isSameAsCurrentTargetSingleModelPortfolio && isRiskScoreInRange && isMinimumLesserThanInvestmentAmount;
  });
};

export const getPortfolioSelectionHeaderContent = (
  content?: PortfolioSelectionContent,
  recommendedProduct?: RecommendedProductData,
): PortfolioSelectionHeaderContent => ({
  investmentAmount: content?.investmentAmount,
  investmentAmountModal: content?.investmentAmountModal,
  headerIcon: content?.header.headerIcon ?? '',
  keepCurrentPortfolio: {
    assetAllocationLabel: content?.keepCurrentPortfolio.assetAllocationLabel ?? '',
    currentPortfolioLabel: content?.keepCurrentPortfolio.currentPortfolioLabel ?? '',
    keepCurrentPortfolioCta: content?.keepCurrentPortfolio.keepCurrentPortfolioCta ?? '',
    viewAssetAllocation: content?.keepCurrentPortfolio.viewAssetAllocation ?? '',
    message: content?.keepCurrentPortfolio.message ?? '',
  },
  investmentStrategyLabel: content?.header.investmentStrategyLabel ?? '',
  minimumInvestmentAmountInfoText: content?.minimumInvestmentAmountInfoText ?? '',
  riskLabel:
    recommendedProduct?.riskBands[recommendedProduct.riskBandUser ?? recommendedProduct.riskBandNew]?.label ?? '',
  riskToleranceEditLinkText: content?.header.riskToleranceEditLinkText ?? '',
  riskToleranceLabel: content?.header.riskToleranceLabel ?? '',
  strategyModeOptions: content?.strategyModeOptions,
  title: content?.header.title ?? '',
});
