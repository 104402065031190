import { useEffect, useState } from 'react';

import { useAccessibilityContent } from '~/hooks/accessibility/useAccessibilityContent';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface UseGetAccessibilityContent {
  beneficiaryAddButton?: string;
  beneficiaryAdded?: string;
  beneficiaryRemoveButton?: string;
  beneficiaryRemoved?: string;
  primaryBeneficiaryAddButton?: string;
  primaryBeneficiaryRemoveButton?: string;
}

export const useGetPaperworkAccessibilityContent = (
  contentOptions: ContentOptions,
): AsyncResult<UseGetAccessibilityContent> => {
  const [state, setState] = useState<AsyncResult<UseGetAccessibilityContent>>({ loading: true });

  const {
    data: accessibilityContent,
    loading: accessibilityContentLoading,
    error: accessibilityContentError,
  } = useAccessibilityContent(contentOptions);

  useEffect(() => {
    if (accessibilityContentLoading) {
      return;
    }

    if (accessibilityContentError) {
      setState({
        loading: false,
        error: accessibilityContentError,
      });
      return;
    }
    const accessibilityContentAriaLabels = accessibilityContent?.aria;

    setState({
      data: {
        beneficiaryAdded: accessibilityContentAriaLabels?.['aria-label:beneficiary-added'],
        beneficiaryRemoved: accessibilityContentAriaLabels?.['aria-label:beneficiary-removed'],
        beneficiaryAddButton: accessibilityContentAriaLabels?.['aria-label:beneficiary-add-button'],
        beneficiaryRemoveButton: accessibilityContentAriaLabels?.['aria-label:beneficiary-remove-button'],
        primaryBeneficiaryAddButton: accessibilityContentAriaLabels?.['aria-label:primary-beneficiary-add-button'],
        primaryBeneficiaryRemoveButton:
          accessibilityContentAriaLabels?.['aria-label:primary-beneficiary-remove-button'],
      },
      loading: accessibilityContentLoading,
      error: accessibilityContentError,
    });
  }, [accessibilityContent, accessibilityContentLoading, accessibilityContentError]);

  return state;
};
