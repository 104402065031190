import { QuestionOrderSteps as QuestionOrderStep } from '../../../Section/types';
import { contingentBeneficiary, primaryBeneficiary, trustedContact } from '../../questions/beneficiary';
import { QuestionProperties } from '../../questions/types';

export const contingentBeneficiaryNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return contingentBeneficiary(
    {
      ...properties,
      additionalValidations: {
        max: 6,
        min: 0,
        ...properties.additionalValidations,
      },
    },
    {
      beneficiaryConfig: {
        checkMaritalStatus: true,
        hideSsn: false,
        includeLastName: false,
        isPerStirpesEnabled: true,
        isTrustTypeFieldsEnabled: true,
      },
    },
  );
};

export const primaryBeneficiaryNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return primaryBeneficiary(
    {
      ...properties,
      additionalValidations: {
        max: 4,
        min: 1,
        ...properties.additionalValidations,
      },
    },
    {
      beneficiaryConfig: {
        checkMaritalStatus: true,
        hideSsn: false,
        includeLastName: false,
        isPerStirpesEnabled: true,
        isTrustTypeFieldsEnabled: true,
      },
    },
  );
};

export const trustedContactNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return trustedContact(
    {
      ...properties,
      additionalValidations: {
        max: 2,
        min: 1,
        ...properties.additionalValidations,
      },
    },
    {
      trustedContactConfig: {
        allowSpecialCharacters: true,
        checkMaritalStatus: true,
        eitherEmailOrPhoneRequired: false,
        optionalPhoneNumber: false,
        optionalCity: true,
        optionalEmailAddress: true,
        optionalState: true,
        optionalStreetAddress: true,
        optionalZipCode: true,
      },
    },
  );
};
