import { QuestionOrderSteps as QuestionOrderStep } from '../../../Section/types';
import { QuestionProperties } from '../../questions/types';
import {
  annualHouseholdIncome,
  annualRecurringExpenses,
  estimatedNetWorth,
  liquidNetWorth,
} from '../../questions/wealthExpenses';

export const annualHouseholdIncomeMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return annualHouseholdIncome({
    ...properties,
    additionalValidations: {
      maxLength: 15,
      ...properties.additionalValidations,
    },
  });
};
export const annualRecurringExpensesMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return annualRecurringExpenses({
    ...properties,
    additionalValidations: {
      maxLength: 15,
      ...properties.additionalValidations,
    },
  });
};

export const liquidNetWorthMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return liquidNetWorth({
    ...properties,
    additionalValidations: {
      maxLength: 15,
      ...properties.additionalValidations,
    },
  });
};

export const estimatedNetWorthMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return estimatedNetWorth({
    ...properties,
    additionalValidations: {
      maxLength: 15,
      ...properties.additionalValidations,
    },
  });
};
