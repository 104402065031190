import React, { FC } from 'react';

import { CmsKeys, ComponentTypes, ValidationNames } from '../types';

import { AssetsHeldAwayV2 } from './AssetsHeldAwayV2';
import { BeneficiariesV2 } from './BeneficiariesV2';
import { CheckboxQuestion } from './CheckboxQuestion';
import { ContactsV2 } from './ContactsV2';
import { CurrencyQuestion } from './CurrencyQuestion';
import { DateQuestion } from './DateQuestion';
import { DropdownQuestion } from './DropdownQuestion';
import { usePaperworkQuestionManager } from './hooks/usePaperworkQuestionManager';
import { InputQuestion } from './InputQuestion';
import { RadioQuestion } from './RadioQuestion';
import { RegulatoryV2 } from './RegulatoryV2';
import { TLHQuestion } from './TlhQuestion';
import { Props } from './types';
import { getErrorMessage, getQuestionsContentForCustomComponent } from './utils';

import { Alert } from '~/components/ui/Alert';
import { FileUploader } from '~/components/ui/FileUploader';
import { Box } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { InvestmentRestrictions } from '~/containers/Paperwork/InvestmentRestrictions';

const customComponents = [
  ComponentTypes.AssetsHeldAway,
  ComponentTypes.Beneficiary,
  ComponentTypes.Contacts,
  ComponentTypes.Tlh,
  ComponentTypes.Regulatory,
  ComponentTypes.FileUploader,
];

export const Question: FC<Props> = props => {
  const {
    accountProfileContent,
    accountType,
    allPaperworkDataAvailableForPrefill,
    allSavedPaperworkData,
    contentOptions,
    countriesList,
    disablePageEditing,
    faPartyAttributes,
    faRepCodes,
    formHooks,
    harvestLosses,
    hidden,
    isAccountProfileEdit,
    isPaperworkSaving,
    isFormSubmitted,
    managedProductId,
    minorAgesByState,
    numberOfQuestionsInRow,
    onCheckboxAnswerChange,
    onClose,
    onDateAnswerChange,
    onDropdownAnswerChange,
    onQuestionShow,
    onRadioAnswerChange,
    open,
    openModal,
    partyId,
    partyRelationships,
    questionInRow,
    questionInRowIndex,
    questionnaireInvestWealthData,
    questionsContent,
    setUnmaskedValues,
    showTlhButtonIcon,
    statesList,
    stateZipCodeMap,
    savedPaperworkDataForCurrentQuestion,
    unmaskedValues,
    validationMessages,
  } = props;

  const {
    DropdownItemsWatcher,
    PrefillValuesWatcher,
    ValidationWatcher,
    componentType,
    dropdownItems,
    errorMessage,
    fieldName,
    inputErrors,
    isFieldDisabled,
    prefillValue,
    questionContent,
    validationRules,
  } = usePaperworkQuestionManager({
    accountType,
    allPaperworkDataAvailableForPrefill,
    allSavedPaperworkData,
    countriesList,
    disablePageEditing,
    faPartyAttributes,
    faRepCodes,
    formHooks,
    hidden,
    isFormSubmitted,
    isPaperworkSaving,
    managedProductId,
    minorAgesByState,
    onQuestionShow,
    partyId,
    partyRelationships,
    questionInRow,
    questionnaireInvestWealthData,
    questionsContent,
    savedPaperworkDataForCurrentQuestion,
    stateZipCodeMap,
    statesList,
    validationMessages,
  });

  // Since custom components are responsible for fetching their own content, we don't need to check for content here
  if (!customComponents.includes(questionInRow.componentType) && questionContent.error) {
    return (
      <Alert
        contentOptions={contentOptions}
        data-qa={`cms-error-${questionInRow.questionKey}`}
        key={fieldName}
        severity="error"
      >
        Content not found for question: "{questionInRow.questionKey}" in CMS.
      </Alert>
    );
  }

  return (
    <>
      {ValidationWatcher}
      {DropdownItemsWatcher}
      {PrefillValuesWatcher}
      <Box
        data-qa={`question-${questionInRow.questionKey}`}
        key={fieldName}
        sx={{
          display: 'flex',
          flex: '1 0',
          flexWrap: 'wrap',
          alignContent: 'flex-end',
          mt:
            componentType === ComponentTypes.Dropdown && questionInRow.cmsKey === CmsKeys.States ? '-2.5px' : undefined,
          mr: numberOfQuestionsInRow > 1 && questionInRowIndex === 0 ? 1 : isAccountProfileEdit ? 2 : 0,
        }}
      >
        <Box sx={{ flex: 1, alignItems: 'start' }}>
          {componentType === ComponentTypes.Input ? (
            <InputQuestion
              defaultValue={prefillValue}
              fieldName={fieldName}
              formHooks={formHooks}
              inputErrors={inputErrors}
              isFieldDisabled={isFieldDisabled}
              questionContent={questionContent}
              questionInRow={questionInRow}
              setUnmaskedValues={setUnmaskedValues}
              unmaskedValues={unmaskedValues}
              validationRules={validationRules}
              {...questionInRow.inputComponentProps}
            />
          ) : componentType === ComponentTypes.Date ? (
            <DateQuestion
              defaultValue={prefillValue}
              fieldName={fieldName}
              formHooks={formHooks}
              inputErrors={inputErrors}
              isFieldDisabled={isFieldDisabled}
              onDateAnswerChange={onDateAnswerChange}
              questionContent={questionContent}
              questionInRow={questionInRow}
              validationRules={validationRules}
              {...questionInRow.dateComponentProps}
            />
          ) : componentType === ComponentTypes.Dropdown ? (
            <DropdownQuestion
              defaultValue={prefillValue}
              dropdownItems={dropdownItems}
              fieldName={fieldName}
              formHooks={formHooks}
              inputErrors={inputErrors}
              isFieldDisabled={isFieldDisabled}
              onDropdownAnswerChange={onDropdownAnswerChange}
              questionContent={questionContent}
              questionInRow={questionInRow}
              validationRules={validationRules}
            />
          ) : componentType === ComponentTypes.Checkbox ? (
            <CheckboxQuestion
              defaultValue={prefillValue}
              fieldName={fieldName}
              formHooks={formHooks}
              inputErrors={inputErrors}
              isFieldDisabled={isFieldDisabled}
              onCheckboxAnswerChange={onCheckboxAnswerChange}
              questionContent={questionContent}
              questionInRow={questionInRow}
              validationRules={validationRules}
            />
          ) : componentType === ComponentTypes.Radio ? (
            <RadioQuestion
              defaultValue={prefillValue}
              fieldName={fieldName}
              formHooks={formHooks}
              inputErrors={inputErrors}
              isFieldDisabled={isFieldDisabled}
              onRadioAnswerChange={onRadioAnswerChange}
              questionContent={questionContent}
              questionInRow={questionInRow}
              validationRules={validationRules}
            />
          ) : componentType === ComponentTypes.Label ? (
            <Typography component="h3" data-qa={`component-label-${questionInRow.questionKey}`} variant="subtitle1">
              {questionContent.question}
            </Typography>
          ) : componentType === ComponentTypes.Info ? (
            <Alert
              contentOptions={contentOptions}
              data-qa={`component-info-${questionInRow.questionKey}`}
              severity="info"
            >
              {questionContent.question}
            </Alert>
          ) : componentType === ComponentTypes.Text ? (
            <RteContent
              data={questionContent.question}
              data-qa={`component-text-${questionInRow.questionKey}`}
              defaultBodyVariant="body2"
            />
          ) : componentType === ComponentTypes.Subtext ? (
            <Typography
              color="text.secondary"
              data-qa={`component-subtext-${questionInRow.questionKey}`}
              variant="body2"
            >
              {questionContent.question}
            </Typography>
          ) : componentType === ComponentTypes.AssetsHeldAway ? (
            <AssetsHeldAwayV2
              accountType={accountType}
              contentOptions={contentOptions}
              disablePageEditing={disablePageEditing}
              formHooks={formHooks}
              isAccountProfileEdit={isAccountProfileEdit}
              isFormSubmitted={isFormSubmitted}
              paperworkFreeFormId={questionInRow.paperworkFreeFormId}
              questionsContent={questionsContent}
              savedPaperworkData={savedPaperworkDataForCurrentQuestion ?? undefined}
              {...questionInRow.assetsHeldAwayComponentProps}
            />
          ) : componentType === ComponentTypes.Beneficiary ? (
            <BeneficiariesV2
              contentOptions={contentOptions}
              countriesList={countriesList}
              ctas={accountProfileContent?.ctas}
              disablePageEditing={disablePageEditing}
              formHooks={formHooks}
              hidden={hidden}
              isAccountProfileEdit={isAccountProfileEdit}
              isFormSubmitted={isFormSubmitted}
              maximumBeneficiaries={(validationRules.max as number | undefined) ?? 4}
              minimumBeneficiaries={(validationRules.min as number | undefined) ?? 0}
              paperworkFreeFormId={questionInRow.paperworkFreeFormId}
              questionKey={questionInRow.questionKey}
              questionsContent={questionsContent}
              savedPaperworkData={savedPaperworkDataForCurrentQuestion ?? undefined}
              validationMessages={validationMessages}
              {...questionInRow.beneficiariesComponentProps}
            />
          ) : componentType === ComponentTypes.Contacts ? (
            <ContactsV2
              ctas={accountProfileContent?.ctas}
              disablePageEditing={disablePageEditing}
              formHooks={formHooks}
              hidden={hidden}
              isAccountProfileEdit={isAccountProfileEdit}
              maximumContacts={(validationRules.max as number | undefined) ?? 2}
              minimumContacts={(validationRules.min as number | undefined) ?? 1}
              paperworkFreeFormId={questionInRow.paperworkFreeFormId}
              questionKey={questionInRow.questionKey}
              questionsContent={questionsContent}
              savedPaperworkData={savedPaperworkDataForCurrentQuestion ?? undefined}
              stateZipCodeMap={stateZipCodeMap}
              statesList={statesList}
              validationMessages={validationMessages}
              {...questionInRow.contactsComponentProps}
            />
          ) : componentType === ComponentTypes.Regulatory ? (
            <RegulatoryV2
              countriesList={countriesList}
              employmentData={savedPaperworkDataForCurrentQuestion?.party?.partyCompany}
              formHooks={formHooks}
              paperworkFreeFormId={questionInRow.paperworkFreeFormId}
              questionKey={questionInRow.questionKey}
              questionsContent={questionsContent}
              regulatoryData={savedPaperworkDataForCurrentQuestion?.regulatoryInformation}
              stateZipCodeMap={stateZipCodeMap}
              statesList={statesList}
              validationMessages={validationMessages}
              {...questionInRow.regulatoryComponentProps}
            />
          ) : componentType === ComponentTypes.InvestmentRestrictions ? (
            <InvestmentRestrictions
              content={{
                questions: getQuestionsContentForCustomComponent(questionsContent, questionInRow.questionKey),
              }}
              contentOptions={contentOptions}
              managedProductId={managedProductId}
              partyId={partyId}
              questionKey={questionInRow.questionKey}
            />
          ) : componentType === ComponentTypes.Tlh ? (
            <TLHQuestion
              contentOptions={contentOptions}
              harvestLosses={harvestLosses}
              onClose={onClose}
              open={open}
              openModal={openModal}
              partyId={partyId}
              questionKey={questionInRow.questionKey}
              questionsContent={questionsContent}
              showTlhButtonIcon={showTlhButtonIcon}
            />
          ) : componentType === ComponentTypes.Currency ? (
            <CurrencyQuestion
              contentOptions={contentOptions}
              defaultValue={prefillValue}
              fieldName={fieldName}
              formHooks={formHooks}
              inputErrors={inputErrors}
              questionContent={questionContent}
              validationRules={validationRules}
            />
          ) : (
            componentType === ComponentTypes.FileUploader && (
              <FileUploader
                ctaText={questionContent.question}
                maxFileSizeMessage={getErrorMessage(
                  fieldName,
                  questionInRow.questionKey,
                  ValidationNames.MaxFileSize,
                  validationMessages,
                )}
                maxNumberOfFilesMessage={getErrorMessage(
                  fieldName,
                  questionInRow.questionKey,
                  ValidationNames.MaxNumberOfFiles,
                  validationMessages,
                )}
                {...questionInRow.fileUploaderComponentProps}
              />
            )
          )}
        </Box>
        {/* Adding minHeight: '2.5vh' to maintain all the fields in the same line, even if there are form errors for few fields */}
        <Box sx={{ width: 1, ...(isAccountProfileEdit && { minHeight: '2.5vh' }) }}>
          {!!inputErrors && errorMessage && (
            <Typography key={`error-${fieldName}`} role="alert" sx={{ color: 'error.main' }} variant="caption">
              {errorMessage}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};
