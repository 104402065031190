import { Canvas, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { Style } from '~/components/pdfs/styles';
import { DonutSlice } from '~/components/ui/Donut';
import { sortBySpecifiedOrder } from '~/utils/asset-allocation';

export interface Props {
  centerSubText?: string;
  centerText?: string;
  data: DonutSlice[];
  fontSize: number;
  gap?: number;
  label?: string;
  lineSize?: number;
  pdfStyles: Style;
  size?: number;
}

const getStyles = (size: number) =>
  StyleSheet.create({
    root: {
      width: size,
      height: size,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    canvas: {
      width: size,
      height: size,
      position: 'absolute',
    },
    splitText: {
      width: size,
      fontSize: 9,
      textAlign: 'center',
    },
  });

export const getSegmentPath = (start: number, end: number, outerRadius: number, innerRadius: number) => {
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0);
  const y0 = Math.sin(a0);
  const x1 = Math.cos(a1);
  const y1 = Math.sin(a1);

  const largeArc = end - start > 0.5 ? 1 : 0;

  const move = `M ${outerRadius + innerRadius * x0} ${outerRadius + innerRadius * y0}`;
  const line1 = `L ${outerRadius + outerRadius * x0} ${outerRadius + outerRadius * y0}`;
  const arc1 = ` A ${outerRadius} ${outerRadius} 0 ${largeArc} 1 ${outerRadius + outerRadius * x1} ${
    outerRadius + outerRadius * y1
  }`;
  const line2 = `L ${outerRadius + innerRadius * x1} ${outerRadius + innerRadius * y1}`;
  const arc2 = `A ${innerRadius} ${innerRadius} 0 ${largeArc} 0 ${outerRadius + innerRadius * x0} ${
    outerRadius + innerRadius * y0
  }`;

  return `${move} ${line1} ${arc1} ${line2} ${arc2}`;
};

export const Donut: React.FC<Props> = ({
  centerText,
  centerSubText,
  data,
  size = 65,
  gap = 0.003,
  fontSize = 9,
  label,
  lineSize = 10,
  pdfStyles,
}) => {
  const commonTextStyle = {
    color: pdfStyles.textPrimary.color,
    fontSize,
  };
  const outerRadius = size / 2;
  const innerRadius = outerRadius - lineSize;
  const styles = getStyles(size);
  const sortedData = sortBySpecifiedOrder(data);
  const paint = (painter: any) => {
    let start = 90;
    sortedData.map(slice => {
      painter
        .path(getSegmentPath(start, start + slice.value / 100 - gap, outerRadius, innerRadius))
        .fillColor(slice.color)
        .fillOpacity(parseInt(slice.opacity ?? '100', 10) / 100)
        .fill();

      start += slice.value / 100;
    });
    return painter;
  };

  return (
    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <View style={{ ...styles.root }}>
        <Canvas paint={paint} style={styles.canvas} />
        <View style={styles.splitText}>
          {centerText && <Text style={commonTextStyle}>{centerText}</Text>}
          {centerSubText && <Text style={commonTextStyle}>{centerSubText}</Text>}
        </View>
      </View>
      {label && (
        <View>
          <Text
            style={{
              color: pdfStyles.textSecondary.color,
              fontSize: 8,
              marginTop: '16px',
            }}
          >
            {label}
          </Text>
        </View>
      )}
    </View>
  );
};
