import React from 'react';

import { QuestionnaireCard } from '~/components/Questionnaire/QuestionnaireCard';
import { Divider } from '~/components/ui/Divider';
import { Grid, InsertDriveFileIcon } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

export interface Document {
  name: string;
  status: React.ReactNode;
}

export interface Packet {
  documents: Document[];
  id: string;
}

export interface DocumentListProps {
  packets: Packet[];
}

export const DocumentList: React.FC<DocumentListProps> = ({ packets }) => {
  return (
    <QuestionnaireCard loading={false} showBorderEffect>
      <Grid container spacing={4} sx={{ textAlign: 'center', mt: 2 }}>
        {packets.map((packet, idx) => (
          <Grid item key={packet.id} xs={12}>
            {packet.documents.map(document => (
              <Grid container key={document.name} mb={4}>
                <Grid item xs={2}>
                  <InsertDriveFileIcon />
                </Grid>
                <Grid item sx={{ textAlign: 'left' }} xs={8}>
                  <Typography>{document.name}</Typography>
                </Grid>
                <Grid item xs={2}>
                  {document.status}
                </Grid>
              </Grid>
            ))}
            {idx !== packets.length - 1 && <Divider />}
          </Grid>
        ))}
      </Grid>
    </QuestionnaireCard>
  );
};
