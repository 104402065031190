import { ComponentTypes, QuestionOrderSteps } from '../../Section/types';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';

export const grantorPageSoftWarning = ({ rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.GRANTOR_PAGE_SOFT_WARNING,
    dataPointKey: DataPointKeys.GRANTOR_PAGE_SOFT_WARNING,
    componentType: ComponentTypes.Info,
    rules,
  };
};
export const sectionJustification = ({ rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.SECTION_JUSTIFICATION,
    dataPointKey: DataPointKeys.SECTION_JUSTIFICATION,
    componentType: ComponentTypes.Info,
    rules,
  };
};
