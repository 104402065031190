import { GetRetakeRTQDocusignDocument, GetRetakeRTQDocusignDocumentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export const useGetRetakeRTQDocusignDocument = (
  options?: QueryHookOptions<GetRetakeRTQDocusignDocument, GetRetakeRTQDocusignDocumentVariables>,
): QueryResult<GetRetakeRTQDocusignDocument, GetRetakeRTQDocusignDocumentVariables> => {
  return useSymphonyQuery(queries.GetRetakeRTQDocusignDocument, options);
};
