import React, { FC, useCallback, useMemo, useState } from 'react';
import { Controller, FieldError, UseFormMethods, useWatch } from 'react-hook-form';

import { DataPointKeys, QuestionKeys } from '../../../configs/questions/types';
import { regulatorySameAsEmployerNoValue, regulatorySameAsEmployerYesValue } from '../../../configs/questions/utils';
import { ZipCodeMapItem } from '../../../hooks/useGetPaperworkData';
import { FormData } from '../../../types';
import { CmsKeys, QuestionContent, ValidationMessage } from '../../types';
import { getFieldName } from '../../utils';
import { isValidLength, isValidTicker, isValidZipCode } from '../../validators';
import { getDropdownItemsFromCms, getErrorMessage, getQuestionsContentForCustomComponent } from '../utils';

import {
  accreditedInvestorCheckboxFieldNameGetter,
  associatedWithAccountHoldingBrokerDealerCheckboxFieldNameGetter,
  associatedWithNonAccountHoldingBrokerDealerCheckboxFieldNameGetter,
  associatedWithRegisteredInvestmentAdvisorCheckboxFieldNameGetter,
  exchangeAffiliationTypeFieldNameGetter,
  exchangeCompanyAffiliationLetterFieldNameGetter,
  exchangeCompanyCityFieldNameGetter,
  exchangeCompanyNameFieldNameGetter,
  exchangeCompanyStateFieldNameGetter,
  exchangeCompanyStreetAddressFieldNameGetter,
  exchangeCompanyStreetLineFieldNameGetter,
  exchangeCompanyZipCodeFieldNameGetter,
  exchangeEmployedCheckboxFieldNameGetter,
  exchangeRelationshipFieldNameGetter,
  getRegulatoryTickerSearchContent,
  isCompanyNameValid,
  isInputLengthValid,
  phcCheckboxFieldNameGetter,
  phcControlTypeFieldNameGetter,
  phcNameFieldNameGetter,
  phcRelationshipFieldNameGetter,
  phcTickerFieldNameGetter,
  sameAsEmployerFieldNameGetter,
  spfCheckboxFieldNameGetter,
  spfCountryFieldNameGetter,
  spfNameFieldNameGetter,
  spfRelationshipFieldNameGetter,
  spfTitleFieldNameGetter,
} from './utils';

import { EmploymentStatus, RegulatoryRelationship } from '~/__generated__';
import { SecuritySearchOption, TickerSearch } from '~/components/TickerSearch';
import { Alert } from '~/components/ui/Alert';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Box, Checkbox, FormControl, FormControlLabel, useTheme } from '~/components/ui/mui';
import { RadioGroup } from '~/components/ui/RadioGroup';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { CMSQuestions } from '~/containers/Paperwork/contentstack';
import { PaperworkPartyCompanyInformation, PaperworkRegulatoryInformation } from '~/containers/Paperwork/symphony';
import { SfTheme } from '~/utils/theme';

export interface RegulatoryConfig {
  autoFillCompanyNameFromTicker: boolean;
  isAffiliationLetterFieldEnabled: boolean;
}

export interface Props {
  countriesList: DropdownItem<string>[];
  dataQa?: string;
  employmentData?: PaperworkPartyCompanyInformation | null;
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  paperworkFreeFormId?: string;
  questionKey: string;
  questionsContent: CMSQuestions;
  regulatoryConfig?: RegulatoryConfig;
  regulatoryData?: PaperworkRegulatoryInformation | null;
  stateZipCodeMap?: ZipCodeMapItem[];
  statesList: DropdownItem<string>[];
  validationMessages?: ValidationMessage[];
}

const defaultRegulatoryConfig = {
  autoFillCompanyNameFromTicker: false,
  isAffiliationLetterFieldEnabled: false,
};

export const RegulatoryV2: FC<Props> = ({
  dataQa = 'regulatory-v2',
  questionsContent,
  validationMessages,
  countriesList,
  statesList,
  stateZipCodeMap,
  formHooks,
  paperworkFreeFormId,
  questionKey,
  regulatoryConfig = defaultRegulatoryConfig,
  regulatoryData,
  employmentData,
}) => {
  const {
    sfPaperwork: { styles: style },
  } = useTheme<SfTheme>();

  const { isAffiliationLetterFieldEnabled, autoFillCompanyNameFromTicker } = regulatoryConfig;

  const [hasSpfConnection, setHasSpfConnection] = useState<boolean>(regulatoryData?.hasSpfConnection ?? false);
  const [isPhcSeniorOffice, setIsPhcSeniorOfficer] = useState<boolean>(regulatoryData?.isPhcSeniorOfficer ?? false);
  const [isExchangeEmployed, setIsExchangeEmployed] = useState<boolean>(regulatoryData?.isExchangeEmployed ?? false);
  const [exchangeRelationship, setExchangeRelationship] = useState<RegulatoryRelationship | null>(
    regulatoryData?.finraRelationship ?? null,
  );
  const [isSameEmployer, setIsSameEmployer] = useState<boolean>(
    !!(regulatoryData && employmentData && regulatoryData.exchangeEmployerName === employmentData.organizationName),
  );
  const [tickerOption, setTickerOption] = useState<SecuritySearchOption | null>({
    label: regulatoryData?.ticker ?? '',
    name: regulatoryData?.ticker ?? '',
    ticker: regulatoryData?.ticker ?? '',
  });

  const [showTickerSearch, setShowTickerSearch] = useState<boolean>(true);
  const spfCheckboxFieldName = spfCheckboxFieldNameGetter(paperworkFreeFormId);
  const spfNameFieldName = spfNameFieldNameGetter(paperworkFreeFormId);
  const spfRelationshipFieldName = spfRelationshipFieldNameGetter(paperworkFreeFormId);
  const spfTitleFieldName = spfTitleFieldNameGetter(paperworkFreeFormId);
  const spfCountryFieldName = spfCountryFieldNameGetter(paperworkFreeFormId);
  const phcCheckboxFieldName = phcCheckboxFieldNameGetter(paperworkFreeFormId);
  const phcNameFieldName = phcNameFieldNameGetter(paperworkFreeFormId);
  const phcTickerFieldName = phcTickerFieldNameGetter(paperworkFreeFormId);
  const phcControlTypeFieldName = phcControlTypeFieldNameGetter(paperworkFreeFormId);
  const phcRelationshipFieldName = phcRelationshipFieldNameGetter(paperworkFreeFormId);
  const exchangeEmployedCheckboxFieldName = exchangeEmployedCheckboxFieldNameGetter(paperworkFreeFormId);
  const sameAsEmployerFieldName = sameAsEmployerFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyNameFieldName = exchangeCompanyNameFieldNameGetter(paperworkFreeFormId);
  const exchangeAffiliationTypeFieldName = exchangeAffiliationTypeFieldNameGetter(paperworkFreeFormId);
  const exchangeRelationshipFieldName = exchangeRelationshipFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyStreetAddressFieldName = exchangeCompanyStreetAddressFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyStreetLineFieldName = exchangeCompanyStreetLineFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyStateFieldName = exchangeCompanyStateFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyCityFieldName = exchangeCompanyCityFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyZipCodeFieldName = exchangeCompanyZipCodeFieldNameGetter(paperworkFreeFormId);
  const exchangeCompanyAffiliationLetterFieldName = exchangeCompanyAffiliationLetterFieldNameGetter(
    paperworkFreeFormId,
  );
  const accreditedInvestorCheckboxFieldName = accreditedInvestorCheckboxFieldNameGetter(paperworkFreeFormId);
  const associatedWithNonAccountHoldingBrokerDealerCheckboxFieldName = associatedWithNonAccountHoldingBrokerDealerCheckboxFieldNameGetter(
    paperworkFreeFormId,
  );
  const associatedWithAccountHoldingBrokerDealerCheckboxFieldName = associatedWithAccountHoldingBrokerDealerCheckboxFieldNameGetter(
    paperworkFreeFormId,
  );
  const associatedWithRegisteredInvestmentAdvisorCheckboxFieldName = associatedWithRegisteredInvestmentAdvisorCheckboxFieldNameGetter(
    paperworkFreeFormId,
  );

  const { setValue, getValues, control, errors: fieldErrors } = formHooks;
  const employmentStatus = useWatch<string>({
    control,
    name: getFieldName(DataPointKeys.EMPLOYMENT_STATUS, paperworkFreeFormId),
  });

  const questions = useMemo(() => {
    return getQuestionsContentForCustomComponent(questionsContent, questionKey);
  }, [questionsContent, questionKey]);

  const tickerSearchContent = useMemo(() => {
    return getRegulatoryTickerSearchContent(questionsContent);
  }, [questionsContent]);

  const regulatoryLabelContent = questions.find(q => q.key === QuestionKeys.REGULATORY_LABEL);
  const spfCheckboxContent = questions.find(q => q.key === QuestionKeys.REGULATORY_SPF_CHECKBOX);
  const spfNameContent = questions.find(q => q.key === QuestionKeys.REGULATORY_SPF_NAME);
  const spfRelationshipContent = questions.find(q => q.key === QuestionKeys.REGULATORY_SPF_RELATIONSHIP);
  const spfTitleContent = questions.find(q => q.key === QuestionKeys.REGULATORY_SPF_TITLE);
  const spfCountryContent = questions.find(q => q.key === QuestionKeys.REGULATORY_SPF_COUNTRY);
  const phcCheckboxContent = questions.find(q => q.key === QuestionKeys.REGULATORY_PHC_CHECKBOX);
  const phcNameContent = questions.find(q => q.key === QuestionKeys.REGULATORY_PHC_NAME);
  const phcTickerContent = questions.find(q => q.key === QuestionKeys.REGULATORY_PHC_TICKER);
  const phcRelationshipContent = questions.find(q => q.key === QuestionKeys.REGULATORY_PHC_RELATIONSHIP);
  const phcControlTypeContent = questions.find(q => q.key === QuestionKeys.REGULATORY_PHC_CONTROL_TYPE);
  const exchangeEmployedCheckboxContent = questions.find(
    q => q.key === QuestionKeys.REGULATORY_EXCHANGE_EMPLOYED_CHECKBOX,
  );
  const sameAsEmployerContent = questions.find(q => q.key === QuestionKeys.REGULATORY_SAME_AS_EMPLOYER);
  const exchangeCompanyContent = questions.find(q => q.key === QuestionKeys.REGULATORY_EXCHANGE_COMPANY);
  const exchangeAffiliationTypeContent = questions.find(
    q => q.key === QuestionKeys.REGULATORY_EXCHANGE_AFFILIATION_TYPE,
  );
  const exchangeStreetAddressContent = questions.find(q => q.key === QuestionKeys.REGULATORY_EXCHANGE_STREET_ADDRESS);
  const exchangeStreetLineContent = questions.find(q => q.key === QuestionKeys.REGULATORY_EXCHANGE_STREET_LINE);
  const exchangeCityContent = questions.find(q => q.key === QuestionKeys.REGULATORY_EXCHANGE_CITY);
  const exchangeStateContent = questions.find(q => q.key === QuestionKeys.REGULATORY_EXCHANGE_STATE);
  const exchangeZipCodeContent = questions.find(q => q.key === QuestionKeys.REGULATORY_EXCHANGE_ZIP_CODE);
  const exchangeCompanyAffiliationLetterContent = questions.find(
    q => q.key === QuestionKeys.REGULATORY_EXCHANGE_COMPANY_AFFILIATION_LETTER,
  );
  const exchangeCompanyAffiliationLetterInfoContent = questions.find(
    q => q.key === QuestionKeys.REGULATORY_EXCHANGE_COMPANY_AFFILIATION_LETTER_INFO,
  );
  const exchangeRelationshipContent = questions.find(q => q.key === QuestionKeys.REGULATORY_EXCHANGE_RELATIONSHIP);
  const accreditedInvestorCheckboxContent = questions.find(
    q => q.key === QuestionKeys.REGULATORY_ACCREDITED_INVESTOR_CHECKBOX,
  );
  const nonAccountHoldingBrokerDealerCheckboxContent = questions.find(
    q => q.key === QuestionKeys.REGULATORY_NON_ACCOUNT_HOLDING_BROKER_DEALER_CHECKBOX,
  );
  const accountHoldingBrokerDealerCheckboxContent = questions.find(
    q => q.key === QuestionKeys.REGULATORY_ACCOUNT_HOLDING_BROKER_DEALER_CHECKBOX,
  );
  const registeredInvestmentAdvisorCheckboxContent = questions.find(
    q => q.key === QuestionKeys.REGULATORY_REGISTERED_INVESTMENT_ADVISOR_CHECKBOX,
  );

  const hideSpfFields = !hasSpfConnection;
  const hidePhcFields = !isPhcSeniorOffice;

  // Custom logic to determine the display of different questions to checkbox states
  const isEmployed = useMemo(() => {
    if (employmentStatus) {
      return [EmploymentStatus.EMPLOYED.toString(), EmploymentStatus.SELF_EMPLOYED.toString()].includes(
        employmentStatus,
      );
    } else {
      return employmentData?.organizationName ?? false;
    }
  }, [employmentData?.organizationName, employmentStatus]);

  const showSameAsEmployerField =
    isExchangeEmployed && exchangeRelationship === RegulatoryRelationship.SELF_OR_ACCOUNT_OWNER && isEmployed;
  const showExchangeEmployerInformation = isExchangeEmployed && !(showSameAsEmployerField && isSameEmployer);

  const resetCompanyAddressFields = useCallback(() => {
    setValue(exchangeCompanyNameFieldName, '');
    setValue(exchangeAffiliationTypeFieldName, '');
    setValue(exchangeCompanyStreetAddressFieldName, '');
    setValue(exchangeCompanyStreetLineFieldName, '');
    setValue(exchangeCompanyCityFieldName, '');
    setValue(exchangeCompanyStateFieldName, '');
    setValue(exchangeCompanyZipCodeFieldName, '');
  }, [
    exchangeAffiliationTypeFieldName,
    exchangeCompanyCityFieldName,
    exchangeCompanyNameFieldName,
    exchangeCompanyStateFieldName,
    exchangeCompanyStreetAddressFieldName,
    exchangeCompanyStreetLineFieldName,
    exchangeCompanyZipCodeFieldName,
    setValue,
  ]);

  if (
    [
      regulatoryLabelContent,
      spfCheckboxContent,
      spfNameContent,
      spfRelationshipContent,
      spfTitleContent,
      spfCountryContent,
      phcCheckboxContent,
      phcNameContent,
      phcTickerContent,
      exchangeEmployedCheckboxContent,
      sameAsEmployerContent,
      exchangeCompanyContent,
      exchangeAffiliationTypeContent,
      exchangeStreetAddressContent,
      exchangeStreetLineContent,
      exchangeCityContent,
      exchangeStateContent,
      exchangeZipCodeContent,
      accreditedInvestorCheckboxContent,
      exchangeRelationshipContent,
      phcRelationshipContent,
    ].includes(undefined)
  ) {
    return <Alert severity="error">Content not found for some of the question(s) in: "{questionKey}" in CMS.</Alert>;
  }

  const getInputError = (fieldNameForError: string, questionKeyForError: string, error?: FieldError) => {
    return (
      !!error && (
        <Box data-qa={`error-${error.ref?.name}`} sx={{ width: 1 }}>
          <Typography role="alert" sx={{ color: 'error.main' }} variant="caption">
            {getErrorMessage(fieldNameForError, questionKeyForError, error.type, validationMessages)}
          </Typography>
        </Box>
      )
    );
  };

  const onCheckboxAnswerChange = (dataPointKey: string, answer: boolean) => {
    switch (dataPointKey) {
      case spfCheckboxFieldName:
        setHasSpfConnection(answer);
        break;
      case phcCheckboxFieldName:
        setIsPhcSeniorOfficer(answer);
        break;
      case exchangeEmployedCheckboxFieldName:
        setIsExchangeEmployed(answer);
        break;
      default:
        break;
    }
  };

  const onRadioAnswerChange = (answer: string) => {
    switch (answer) {
      case regulatorySameAsEmployerNoValue:
        setIsSameEmployer(false);
        break;
      case regulatorySameAsEmployerYesValue:
        setIsSameEmployer(true);
        break;
      default:
        break;
    }
  };

  const getTickerController = () => (
    <Box sx={{ display: hidePhcFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
      <Controller
        control={control}
        defaultValue={tickerOption?.ticker}
        name={phcTickerFieldName}
        render={({ name, onChange, ref, value, ...params }) =>
          showTickerSearch ? (
            <TickerSearch
              {...params}
              ariaLabelledBy="Ticker Search"
              content={{
                label: phcTickerContent?.question,
                noOptionsText: tickerSearchContent.tickerSearchNoOptionsText,
                placeholder: tickerSearchContent.tickerSearchPlaceholder,
              }}
              customOptions={[
                {
                  isDivider: true,
                  label: tickerSearchContent.tickerSearchAddTickerManuallyCta,
                  name: tickerSearchContent.tickerSearchAddTickerManuallyCta,
                  ticker: '',
                },
              ]}
              error={!!fieldErrors[phcTickerFieldName]}
              onValueChange={(_e, newValue, reason) => {
                if (reason === 'clear') {
                  setTickerOption(null);
                  onChange(null);
                } else if (newValue?.name === tickerSearchContent.tickerSearchAddTickerManuallyCta) {
                  setShowTickerSearch(false);
                } else {
                  if (autoFillCompanyNameFromTicker) {
                    setValue(phcNameFieldName, newValue?.name);
                  }
                  setTickerOption(newValue);
                  onChange(newValue?.ticker);
                }
              }}
              sx={{ width: '100%' }}
              tickerSearchValue={tickerOption}
            />
          ) : (
            <TextField
              error={!!fieldErrors[phcTickerFieldName]}
              fullWidth
              inputRef={ref}
              label={phcTickerContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (phcTickerContent?.character_limit && inputValue.length > phcTickerContent.character_limit) {
                  e.preventDefault();
                  return;
                }
                e.target.value = inputValue.toUpperCase(); // NFS has validation, and it considers only [A-Z0-9] pattern as valid
                onChange(e);
              }}
              value={value}
            />
          )
        }
        rules={
          hidePhcFields
            ? {}
            : {
                required: true,
                validate: {
                  isValidTicker: value => (value ? isValidTicker(value) : true),
                },
              }
        }
      />
      {getInputError(phcTickerFieldName, QuestionKeys.REGULATORY_PHC_TICKER, fieldErrors[phcTickerFieldName])}
    </Box>
  );

  const getCompanyController = () => (
    <Box sx={{ display: hidePhcFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
      <Controller
        control={control}
        defaultValue={regulatoryData?.publiclyHeldCompanyName ?? ''}
        name={phcNameFieldName}
        render={({ onChange, value, name, ref }) => (
          <TextField
            disabled={autoFillCompanyNameFromTicker}
            error={!!fieldErrors[phcNameFieldName]}
            fullWidth
            inputRef={ref}
            label={phcNameContent?.question}
            name={name}
            onChange={e => {
              const { value: inputValue } = e.target;
              if (!isInputLengthValid(inputValue, phcNameContent?.character_limit)) {
                e.preventDefault();
                return;
              }
              onChange(e);
            }}
            type="text"
            value={value}
          />
        )}
        rules={hidePhcFields ? {} : { required: !autoFillCompanyNameFromTicker }}
      />
      {getInputError(phcNameFieldName, QuestionKeys.REGULATORY_PHC_NAME, fieldErrors[phcNameFieldName])}
    </Box>
  );

  const getCheckBoxController = (
    defaultValue: boolean,
    checkboxFieldName: string,
    checkboxQuestionKey: string,
    questionContent?: string,
  ) => (
    <Box sx={{ mt: 1 }}>
      <Controller
        control={control}
        defaultValue={defaultValue}
        key={`question-${checkboxFieldName}`}
        name={checkboxFieldName}
        render={({ onChange, value }) => (
          <FormControl data-qa={`question-checkbox-${questionKey}`} error={!!fieldErrors[checkboxFieldName]}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  id={checkboxFieldName}
                  onChange={e => {
                    onChange(e.target.checked);
                  }}
                />
              }
              label={<RteContent component="span" data={questionContent ?? ''} />}
              value={value}
            />
          </FormControl>
        )}
      />
      {getInputError(checkboxFieldName, checkboxQuestionKey, fieldErrors[checkboxFieldName])}
    </Box>
  );

  return (
    <Box data-qa={dataQa}>
      <Box sx={{ display: 'flex' }}>
        <Typography component="h3" sx={{ mr: 1 }} variant="subtitle1">
          {regulatoryLabelContent?.question}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', my: 2, pt: 1, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.hasSpfConnection ?? false}
          key={`question-${spfCheckboxFieldName}`}
          name={spfCheckboxFieldName}
          render={({ onChange, value }) => (
            <FormControl
              data-qa={`question-checkbox-${QuestionKeys.REGULATORY_SPF_CHECKBOX}`}
              error={!!fieldErrors[spfCheckboxFieldName]}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    id={spfCheckboxFieldName}
                    onChange={e => {
                      onCheckboxAnswerChange(spfCheckboxFieldName, e.target.checked);
                      onChange(e.target.checked);
                    }}
                  />
                }
                label={<RteContent component="span" data={spfCheckboxContent?.question ?? ''} />}
                value={value}
              />
            </FormControl>
          )}
        />
        {getInputError(spfCheckboxFieldName, QuestionKeys.REGULATORY_SPF_CHECKBOX, fieldErrors[spfCheckboxFieldName])}
      </Box>
      <Box sx={{ display: hideSpfFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.nameOfSeniorPoliticalFigure ?? ''}
          name={spfNameFieldName}
          render={({ onChange, value, name, ref }) => (
            <TextField
              error={!!fieldErrors[spfNameFieldName]}
              fullWidth
              inputRef={ref}
              label={spfNameContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (spfNameContent?.character_limit && inputValue.length > spfNameContent.character_limit) {
                  e.preventDefault();
                  return;
                }
                onChange(e);
              }}
              type="text"
              value={value}
            />
          )}
          rules={hideSpfFields ? {} : { required: true }}
        />
        {getInputError(spfNameFieldName, QuestionKeys.REGULATORY_SPF_NAME, fieldErrors[spfNameFieldName])}
      </Box>
      <Box sx={{ display: hideSpfFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.relationship ?? ''}
          name={spfRelationshipFieldName}
          render={({ onChange, value, ref }) => (
            <Dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: style.maxDropdownHeight,
                  },
                },
              }}
              dataQa={`dropdown-${spfRelationshipFieldName}`}
              error={!!fieldErrors[spfRelationshipFieldName]}
              inputRef={ref}
              items={spfRelationshipContent?.options ?? []}
              label={spfRelationshipContent?.question}
              onChange={e => onChange(e.target.value)}
              value={value}
              width="100%"
            />
          )}
          rules={hideSpfFields ? {} : { required: true }}
        />
        {getInputError(
          spfRelationshipFieldName,
          QuestionKeys.REGULATORY_SPF_RELATIONSHIP,
          fieldErrors[spfRelationshipFieldName],
        )}
      </Box>
      <Box sx={{ display: hideSpfFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.politicalTitle ?? ''}
          name={spfTitleFieldName}
          render={({ onChange, value, name, ref }) => (
            <TextField
              error={!!fieldErrors[spfTitleFieldName]}
              fullWidth
              inputRef={ref}
              label={spfTitleContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (spfTitleContent?.character_limit && inputValue.length > spfTitleContent.character_limit) {
                  e.preventDefault();
                  return;
                }
                onChange(e);
              }}
              type="text"
              value={value}
            />
          )}
          rules={hideSpfFields ? {} : { required: true }}
        />
        {getInputError(spfTitleFieldName, QuestionKeys.REGULATORY_SPF_TITLE, fieldErrors[spfTitleFieldName])}
      </Box>
      <Box sx={{ display: hideSpfFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.countryOfOffice ?? ''}
          name={spfCountryFieldName}
          render={({ onChange, value, ref }) => (
            <Dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: style.maxDropdownHeight,
                  },
                },
              }}
              dataQa={`dropdown-${spfCountryFieldName}`}
              error={!!fieldErrors[spfCountryFieldName]}
              inputRef={ref}
              items={getDropdownItemsFromCms(
                spfCountryContent as QuestionContent,
                CmsKeys.Countries,
                countriesList,
                statesList,
              )}
              label={spfCountryContent?.question}
              onChange={e => onChange(e.target.value)}
              value={value}
              width="100%"
            />
          )}
          rules={hideSpfFields ? {} : { required: true }}
        />
        {getInputError(spfCountryFieldName, QuestionKeys.REGULATORY_SPF_COUNTRY, fieldErrors[spfCountryFieldName])}
      </Box>
      <Box sx={{ display: 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.isPhcSeniorOfficer ?? false}
          key={`question-${phcCheckboxFieldName}`}
          name={phcCheckboxFieldName}
          render={({ onChange, value }) => (
            <FormControl
              data-qa={`question-checkbox-${QuestionKeys.REGULATORY_PHC_CHECKBOX}`}
              error={!!fieldErrors[phcCheckboxFieldName]}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    id={phcCheckboxFieldName}
                    onChange={e => {
                      onCheckboxAnswerChange(phcCheckboxFieldName, e.target.checked);
                      onChange(e.target.checked);
                    }}
                  />
                }
                label={<RteContent component="span" data={phcCheckboxContent?.question ?? ''} />}
                value={value}
              />
            </FormControl>
          )}
        />
        {getInputError(phcCheckboxFieldName, QuestionKeys.REGULATORY_PHC_CHECKBOX, fieldErrors[phcCheckboxFieldName])}
      </Box>
      <Box
        sx={{
          display: hidePhcFields ? 'none' : 'flex',
          my: 2,
          flex: '1 0',
          flexWrap: 'wrap',
        }}
      >
        <Controller
          control={control}
          defaultValue={regulatoryData?.phcRelationship ?? ''}
          name={phcRelationshipFieldName}
          render={({ onChange, value, ref }) => (
            <Dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: style.maxDropdownHeight,
                  },
                },
              }}
              dataQa={`dropdown-${phcRelationshipFieldName}`}
              error={!!fieldErrors[phcRelationshipFieldName]}
              inputRef={ref}
              items={phcRelationshipContent?.options ?? []}
              label={phcRelationshipContent?.question}
              onChange={e => onChange(e.target.value)}
              value={value}
              width="100%"
            />
          )}
          rules={hidePhcFields ? {} : { required: true }}
        />
        {getInputError(
          phcRelationshipFieldName,
          QuestionKeys.REGULATORY_PHC_RELATIONSHIP,
          fieldErrors[phcRelationshipFieldName],
        )}
      </Box>
      <Box sx={{ display: hidePhcFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.controlType ?? ''}
          name={phcControlTypeFieldName}
          render={({ onChange, value, ref }) => (
            <Dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: style.maxDropdownHeight,
                  },
                },
              }}
              dataQa={`dropdown-${phcControlTypeFieldName}`}
              error={!!fieldErrors[phcControlTypeFieldName]}
              inputRef={ref}
              items={phcControlTypeContent?.options ?? []}
              label={phcControlTypeContent?.question}
              onChange={e => onChange(e.target.value)}
              value={value}
              width="100%"
            />
          )}
          rules={hidePhcFields ? {} : { required: true }}
        />
        {getInputError(
          phcControlTypeFieldName,
          QuestionKeys.REGULATORY_PHC_CONTROL_TYPE,
          fieldErrors[phcControlTypeFieldName],
        )}
      </Box>
      {autoFillCompanyNameFromTicker ? (
        <>
          {getTickerController()} {getCompanyController()}
        </>
      ) : (
        <>
          {getCompanyController()} {getTickerController()}
        </>
      )}
      <Box sx={{ display: 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.isExchangeEmployed ?? false}
          key={`question-${exchangeEmployedCheckboxFieldName}`}
          name={exchangeEmployedCheckboxFieldName}
          render={({ onChange, value }) => (
            <FormControl
              data-qa={`question-checkbox-${QuestionKeys.REGULATORY_EXCHANGE_EMPLOYED_CHECKBOX}`}
              error={!!fieldErrors[exchangeEmployedCheckboxFieldName]}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    id={exchangeEmployedCheckboxFieldName}
                    onChange={e => {
                      onCheckboxAnswerChange(exchangeEmployedCheckboxFieldName, e.target.checked);
                      onChange(e.target.checked);
                    }}
                  />
                }
                label={<RteContent component="span" data={exchangeEmployedCheckboxContent?.question ?? ''} />}
                value={value}
              />
            </FormControl>
          )}
        />
        {getInputError(
          exchangeEmployedCheckboxFieldName,
          QuestionKeys.REGULATORY_EXCHANGE_EMPLOYED_CHECKBOX,
          fieldErrors[exchangeEmployedCheckboxFieldName],
        )}
      </Box>
      <Box
        sx={{
          display: isExchangeEmployed ? 'flex' : 'none',
          my: 2,
          flex: '1 0',
          flexWrap: 'wrap',
        }}
      >
        <Controller
          control={control}
          defaultValue={exchangeRelationship ?? ''}
          name={exchangeRelationshipFieldName}
          render={({ onChange, value, ref }) => (
            <Dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: style.maxDropdownHeight,
                  },
                },
              }}
              dataQa={`dropdown-${exchangeRelationshipFieldName}`}
              error={!!fieldErrors[exchangeRelationshipFieldName]}
              inputRef={ref}
              items={exchangeRelationshipContent?.options ?? []}
              label={exchangeRelationshipContent?.question}
              onChange={e => {
                const regulatoryRelationshipValue =
                  typeof e.target.value === 'string'
                    ? Object.values(RegulatoryRelationship).find(rr => rr.toString() === e.target.value) ?? null
                    : null;
                setExchangeRelationship(regulatoryRelationshipValue);
                onChange(e);
                resetCompanyAddressFields();
              }}
              value={value}
              width="100%"
            />
          )}
          rules={isExchangeEmployed ? { required: true } : {}}
        />
        {getInputError(
          exchangeRelationshipFieldName,
          QuestionKeys.REGULATORY_EXCHANGE_RELATIONSHIP,
          fieldErrors[exchangeRelationshipFieldName],
        )}
      </Box>
      {showSameAsEmployerField && (
        <Box sx={{ display: 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
          <Controller
            control={control}
            defaultValue={isSameEmployer ? regulatorySameAsEmployerYesValue : regulatorySameAsEmployerNoValue}
            key={`question-${sameAsEmployerFieldName}`}
            name={sameAsEmployerFieldName}
            render={({ onChange, value }) => (
              <RadioGroup
                dataQa={`${dataQa}-same-as-employer`}
                error={!!fieldErrors[sameAsEmployerFieldName]}
                items={sameAsEmployerContent?.options ?? []}
                legend={sameAsEmployerContent?.question}
                name={sameAsEmployerFieldName}
                onChange={e => {
                  onRadioAnswerChange(e);
                  onChange(e);
                  resetCompanyAddressFields();
                }}
                value={value}
              />
            )}
            rules={{ required: true }}
          />
        </Box>
      )}
      <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.exchangeEmployerName ?? ''}
          name={exchangeCompanyNameFieldName}
          render={({ onChange, value, name, ref }) => (
            <TextField
              error={!!fieldErrors[exchangeCompanyNameFieldName]}
              fullWidth
              inputRef={ref}
              label={exchangeCompanyContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (!isCompanyNameValid(inputValue, exchangeCompanyContent?.character_limit)) {
                  e.preventDefault();
                  return;
                }
                onChange(e);
              }}
              value={value}
            />
          )}
          rules={!showExchangeEmployerInformation ? {} : { required: true }}
        />
        {getInputError(
          exchangeCompanyNameFieldName,
          QuestionKeys.REGULATORY_EXCHANGE_COMPANY,
          fieldErrors[exchangeCompanyNameFieldName],
        )}
      </Box>
      <Box sx={{ display: !isExchangeEmployed ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.exchangeAffiliationType ?? ''}
          name={exchangeAffiliationTypeFieldName}
          render={({ onChange, value, ref }) => (
            <Dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: style.maxDropdownHeight,
                  },
                },
              }}
              dataQa={`dropdown-${exchangeAffiliationTypeFieldName}`}
              error={!!fieldErrors[exchangeAffiliationTypeFieldName]}
              inputRef={ref}
              items={exchangeAffiliationTypeContent?.options ?? []}
              label={exchangeAffiliationTypeContent?.question}
              onChange={e => onChange(e.target.value)}
              value={value}
              width="100%"
            />
          )}
          rules={isExchangeEmployed ? { required: true } : {}}
        />
        {getInputError(
          exchangeAffiliationTypeFieldName,
          QuestionKeys.REGULATORY_EXCHANGE_AFFILIATION_TYPE,
          fieldErrors[exchangeAffiliationTypeFieldName],
        )}
      </Box>
      <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.exchangeAddress?.addressLine1 ?? ''}
          name={exchangeCompanyStreetAddressFieldName}
          render={({ onChange, value, name, ref }) => (
            <TextField
              error={!!fieldErrors[exchangeCompanyStreetAddressFieldName]}
              fullWidth
              inputRef={ref}
              label={exchangeStreetAddressContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (
                  exchangeStreetAddressContent?.character_limit &&
                  inputValue.length > exchangeStreetAddressContent.character_limit
                ) {
                  e.preventDefault();
                  return;
                }
                onChange(e);
              }}
              value={value}
            />
          )}
          rules={!showExchangeEmployerInformation ? {} : { required: true }}
        />
        {getInputError(
          exchangeCompanyStreetAddressFieldName,
          QuestionKeys.REGULATORY_EXCHANGE_STREET_ADDRESS,
          fieldErrors[exchangeCompanyStreetAddressFieldName],
        )}
      </Box>
      <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.exchangeAddress?.addressLine2 ?? ''}
          name={exchangeCompanyStreetLineFieldName}
          render={({ onChange, value, name, ref }) => (
            <TextField
              error={!!fieldErrors[exchangeCompanyStreetLineFieldName]}
              fullWidth
              inputRef={ref}
              label={exchangeStreetLineContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (
                  exchangeStreetLineContent?.character_limit &&
                  inputValue.length > exchangeStreetLineContent.character_limit
                ) {
                  e.preventDefault();
                  return;
                }
                onChange(e);
              }}
              value={value}
            />
          )}
        />
        {getInputError(
          exchangeCompanyStreetLineFieldName,
          QuestionKeys.REGULATORY_EXCHANGE_STREET_LINE,
          fieldErrors[exchangeCompanyStreetLineFieldName],
        )}
      </Box>
      <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.exchangeAddress?.countrySecondarySubdivision ?? ''}
          name={exchangeCompanyCityFieldName}
          render={({ onChange, value, name, ref }) => (
            <TextField
              error={!!fieldErrors[exchangeCompanyCityFieldName]}
              fullWidth
              inputRef={ref}
              label={exchangeCityContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (exchangeCityContent?.character_limit && inputValue.length > exchangeCityContent.character_limit) {
                  e.preventDefault();
                  return;
                }
                onChange(e);
              }}
              value={value}
            />
          )}
          rules={!showExchangeEmployerInformation ? {} : { required: true }}
        />
        {getInputError(
          exchangeCompanyCityFieldName,
          QuestionKeys.REGULATORY_EXCHANGE_CITY,
          fieldErrors[exchangeCompanyCityFieldName],
        )}
      </Box>
      <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', my: 2 }}>
        <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', flex: '1 0', flexWrap: 'wrap', mr: 1 }}>
          <Controller
            control={control}
            defaultValue={regulatoryData?.exchangeAddress?.countryPrimarySubdivision ?? ''}
            name={exchangeCompanyStateFieldName}
            render={({ onChange, value, ref }) => (
              <Dropdown
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: style.maxDropdownHeight,
                    },
                  },
                }}
                dataQa={`dropdown-${exchangeCompanyStateFieldName}`}
                error={!!fieldErrors[exchangeCompanyStateFieldName]}
                inputRef={ref}
                items={getDropdownItemsFromCms(
                  exchangeStateContent as QuestionContent,
                  CmsKeys.States,
                  countriesList,
                  statesList,
                )}
                label={exchangeStateContent?.question}
                onChange={e => onChange(e.target.value)}
                value={value}
                width="100%"
              />
            )}
            rules={!showExchangeEmployerInformation ? {} : { required: true }}
          />
          {getInputError(
            exchangeCompanyStateFieldName,
            QuestionKeys.REGULATORY_EXCHANGE_STATE,
            fieldErrors[exchangeCompanyStateFieldName],
          )}
        </Box>
        <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', flex: '1 0', flexWrap: 'wrap' }}>
          <Controller
            control={control}
            defaultValue={regulatoryData?.exchangeAddress?.postalCode ?? ''}
            name={exchangeCompanyZipCodeFieldName}
            render={({ onChange, value, name, ref }) => (
              <TextField
                error={!!fieldErrors[exchangeCompanyZipCodeFieldName]}
                fullWidth
                inputRef={ref}
                label={exchangeZipCodeContent?.question}
                name={name}
                onChange={e => {
                  const { value: inputValue } = e.target;
                  if (
                    exchangeZipCodeContent?.character_limit &&
                    inputValue.length > exchangeZipCodeContent.character_limit
                  ) {
                    e.preventDefault();
                    return;
                  }
                  if (inputValue) {
                    e.target.value = inputValue.replaceAll(/\D/g, '');
                  }
                  onChange(e);
                }}
                type="text"
                value={value}
              />
            )}
            rules={
              !showExchangeEmployerInformation
                ? {}
                : {
                    required: true,
                    validate: {
                      isLength: value => (value ? isValidLength(value, 5) : true),
                      isValidZipCode: value =>
                        isValidZipCode({
                          linkedState: getValues(exchangeCompanyStateFieldName),
                          zipcode: value,
                          stateZipCodeMap: stateZipCodeMap ?? [],
                        }),
                    },
                  }
            }
          />
          {getInputError(
            exchangeCompanyZipCodeFieldName,
            QuestionKeys.REGULATORY_EXCHANGE_ZIP_CODE,
            fieldErrors[exchangeCompanyZipCodeFieldName],
          )}
        </Box>
      </Box>
      {isAffiliationLetterFieldEnabled && isExchangeEmployed && (
        <>
          <Box sx={{ display: 'flex', mt: 2, pt: 1, pb: 0, flex: '1 0', flexWrap: 'wrap' }}>
            <Controller
              control={control}
              defaultValue={regulatoryData?.hasAffiliationLetter ?? false}
              key={`question-${exchangeCompanyAffiliationLetterFieldName}`}
              name={exchangeCompanyAffiliationLetterFieldName}
              render={({ onChange, value }) => (
                <FormControl
                  data-qa={`question-checkbox-${QuestionKeys.REGULATORY_EXCHANGE_COMPANY_AFFILIATION_LETTER}`}
                  error={!!fieldErrors[exchangeCompanyAffiliationLetterFieldName]}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        id={exchangeCompanyAffiliationLetterFieldName}
                        onChange={e => {
                          onChange(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <RteContent component="span" data={exchangeCompanyAffiliationLetterContent?.question ?? ''} />
                    }
                    value={value}
                  />
                </FormControl>
              )}
              rules={{
                validate: {
                  mandatoryAffiliationLetter: value => !!value,
                },
              }}
            />
            {getInputError(
              exchangeCompanyAffiliationLetterFieldName,
              QuestionKeys.REGULATORY_EXCHANGE_COMPANY_AFFILIATION_LETTER,
              fieldErrors[exchangeCompanyAffiliationLetterFieldName],
            )}
          </Box>
          <Alert data-qa="affiliation-letter-info" severity="info">
            <RteContent data={exchangeCompanyAffiliationLetterInfoContent?.question ?? ''} defaultBodyVariant="body2" />
          </Alert>
        </>
      )}
      {getCheckBoxController(
        regulatoryData?.isAccreditedInvestor ?? false,
        accreditedInvestorCheckboxFieldName,
        QuestionKeys.REGULATORY_ACCREDITED_INVESTOR_CHECKBOX,
        accreditedInvestorCheckboxContent?.question,
      )}
      {getCheckBoxController(
        regulatoryData?.isEmployedByAnotherBroker ?? false,
        associatedWithNonAccountHoldingBrokerDealerCheckboxFieldName,
        QuestionKeys.REGULATORY_NON_ACCOUNT_HOLDING_BROKER_DEALER_CHECKBOX,
        nonAccountHoldingBrokerDealerCheckboxContent?.question,
      )}
      {getCheckBoxController(
        regulatoryData?.isEmployedByAccountBroker ?? false,
        associatedWithAccountHoldingBrokerDealerCheckboxFieldName,
        QuestionKeys.REGULATORY_ACCOUNT_HOLDING_BROKER_DEALER_CHECKBOX,
        accountHoldingBrokerDealerCheckboxContent?.question,
      )}
      {getCheckBoxController(
        regulatoryData?.isAssociatedWithUsRegisteredInvestmentAdvisor ?? false,
        associatedWithRegisteredInvestmentAdvisorCheckboxFieldName,
        QuestionKeys.REGULATORY_REGISTERED_INVESTMENT_ADVISOR_CHECKBOX,
        registeredInvestmentAdvisorCheckboxContent?.question,
      )}
    </Box>
  );
};
