import React, { useState } from 'react';

import { SleeveTemplateCardContent } from '../utils';

import { Allocation } from '~/components/AssetAllocationTable';
import { Divider } from '~/components/ui/Divider';
import { Donut, DonutSlice } from '~/components/ui/Donut';
import { ModalLink } from '~/components/ui/ModalLink';
import { Box, Button, Grid, LoadingButton, Paper, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { Diversification } from '~/containers/Plan/types';
import { getStockBondRatio } from '~/containers/Plan/utils';
import { AssetAllocationModalState } from '~/containers/PortfolioSelection/types';
import { formatPercentage } from '~/utils/format';
import { SfTheme } from '~/utils/theme';
import { VolatilityMatchStatus } from '~/utils/volatility';

export interface SleeveAllocationData {
  assetAllocations?: Allocation[];
  modelPortfolioDescription?: string;
  modelPortfolioInternalId: number;
  modelPortfolioName: string;
  modelPortfolioSeriesId?: number;
  percentage: string;
  portfolioAssetsBreakdown?: DonutSlice[];
  stockBondDiversification?: Diversification;
  strategyBrochureUrl?: string;
}

export interface SleeveTemplateCardProps {
  content: SleeveTemplateCardContent;
  dataQa?: string;
  deleteSleeveTemplate?: (sleeveTemplateId: number) => Promise<void>;
  donutAllocations: DonutSlice[];
  isDeleteAllowed?: boolean;
  minimumBalanceForAccountOpening: string;
  onQuickApplySleeveTemplate: () => void;
  openAssetAllocationModal: (assetAllocationProps: AssetAllocationModalState) => void;
  sleeveAllocationData: SleeveAllocationData[];
  sleeveTemplateId: number;
  sleeveTemplateName: string;
  stockBondSplit: string;
  volatilityMatchStatus: VolatilityMatchStatus;
}

export const SleeveTemplateCard: React.FC<SleeveTemplateCardProps> = ({
  content: {
    aggressiveLabel,
    cancelIconUrl,
    checkIconUrl,
    conservativeLabel,
    minimumInvestmentLabel,
    quickApplyLabel,
    notSuitableText,
    removeCard,
    removeLabel,
    stockBondLabel,
    suitableText,
  },
  dataQa = 'sleeve-template-card',
  deleteSleeveTemplate,
  donutAllocations,
  isDeleteAllowed,
  minimumBalanceForAccountOpening,
  onQuickApplySleeveTemplate,
  openAssetAllocationModal,
  sleeveAllocationData,
  sleeveTemplateName,
  stockBondSplit,
  sleeveTemplateId,
  volatilityMatchStatus,
}) => {
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    sfPortfolioDetails: { typographyVariants },
  } = useTheme<SfTheme>();

  const handleDelete = async () => {
    try {
      if (deleteSleeveTemplate) {
        setIsDeleting(true);
        await deleteSleeveTemplate(sleeveTemplateId);
        setIsDeleteInProgress(false);
      }
    } catch {
      setIsDeleteInProgress(false);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleOpenAssetAllocationModal = (sleeveIndex: number): void => {
    const sleeve = sleeveAllocationData[sleeveIndex];
    const stockBondDiversification = sleeve.stockBondDiversification;
    const stockBondRatio = (stockBondDiversification && getStockBondRatio(stockBondDiversification)) || '';
    const sleeveAssetAllocations = sleeve.assetAllocations ?? [];
    const sleeveDonutAllocations = sleeve.portfolioAssetsBreakdown;
    const donutPropsData = {
      centerSubtext: stockBondLabel,
      centerTextVariant: typographyVariants?.stockBondSplitLabel,
      centerText: stockBondRatio,
      data: sleeveDonutAllocations ?? [],
      innerRadius: 75,
      justifyContentGraph: 'center',
      showTooltip: true,
      size: 275,
    };
    // TODO - Replace the values from contentstack
    const modalContent = {
      assetAllocation: 'Asset Allocation',
      close: 'Close',
    };

    openAssetAllocationModal({
      content: modalContent,
      donutProps: donutPropsData,
      portfolio: {
        assetAllocationData: sleeveAssetAllocations,
        description: sleeve.modelPortfolioDescription || '',
        name: sleeve.modelPortfolioName || '',
        portfolioAssetsBreakdown: sleeveDonutAllocations ?? [],
        stockBondRatio,
      },
    });
  };

  return (
    <Paper
      data-qa={dataQa}
      sx={{
        height: '271px',
      }}
    >
      {isDeleteInProgress ? (
        <Grid container sx={{ alignItems: 'center', px: 4, py: 3 }}>
          <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
            <img alt="" src={removeCard.removeIconUrl} />
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
            <Typography sx={{ mt: 1, fontWeight: 600, textAlign: 'center' }} variant="subtitle2">
              {removeCard.header}
            </Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
            <Typography sx={{ display: 'flex', textAlign: 'center', mt: 1 }} variant="caption">
              {removeCard.message}
            </Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'center', mt: 1.5 }} xs={12}>
            <LoadingButton loading={isDeleting} onClick={() => handleDelete()} variant="outlined">
              {removeCard.confirmCta}
            </LoadingButton>
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'center', mt: 2 }} xs={12}>
            <Button onClick={() => setIsDeleteInProgress(false)} variant="outlined">
              {removeCard.backCta}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container>
            <Typography sx={{ mx: 1.5, mt: 2 }} variant="subtitle2">
              {sleeveTemplateName}
            </Typography>
          </Grid>
          <Grid alignItems="flex-start" container sx={{ px: 0.5, my: -1 }}>
            <Grid item>
              <Donut data={donutAllocations} dataQa={`${dataQa}-donut`} innerRadius={12} size={65} />
            </Grid>
            <Grid item sx={{ pt: 1.5, pl: 1 }}>
              <Grid alignItems="start" container direction="column" item>
                <Typography variant="caption">{stockBondLabel}</Typography>
                <Typography sx={{ py: 0.5 }} variant="caption">
                  {stockBondSplit}
                </Typography>
              </Grid>
            </Grid>
            <Grid item sx={{ pt: 1.5, pr: 2, ml: 'auto' }}>
              <Grid alignItems="end" container direction="column" item>
                {volatilityMatchStatus === VolatilityMatchStatus.MATCHED ? (
                  <Box sx={{ display: 'flex' }}>
                    <img alt="check icon" src={checkIconUrl} />
                    <Typography sx={{ ml: 0.75 }} variant="caption">
                      {suitableText}
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Box sx={{ display: 'flex' }}>
                      <img alt="cancel icon" src={cancelIconUrl} />
                      <Typography sx={{ ml: 0.75 }} variant="caption">
                        {notSuitableText}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', py: 0.5 }}>
                      <Typography variant="caption">
                        {volatilityMatchStatus === VolatilityMatchStatus.TOO_AGGRESSIVE
                          ? aggressiveLabel
                          : conservativeLabel}
                      </Typography>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '128px', px: 2 }}>
            <Divider sx={{ mb: 0.5, mt: 1 }} />
            {sleeveAllocationData.map((sleeve, idx) => (
              <Grid container key={idx} sx={{ py: 0.5 }}>
                <Grid item>
                  <ModalLink
                    data-qa={`${dataQa}-view-asset-allocation`}
                    onClick={() => handleOpenAssetAllocationModal(idx)}
                    sx={{
                      textDecoration: 'underline',
                      color: 'text.primary',
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        textOverflow: 'ellipsis',
                        maxWidth: '200px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                      variant="caption"
                    >
                      {sleeve.modelPortfolioName}
                    </Typography>
                  </ModalLink>
                </Grid>
                <Grid item sx={{ ml: 'auto' }}>
                  <Typography variant="caption">
                    {formatPercentage(parseFloat(sleeve.percentage) / 100, { decimals: 0 })}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            <Grid container sx={{ mt: 'auto', pt: 0.5 }}>
              <Grid item>
                <Typography variant="caption">{minimumInvestmentLabel}</Typography>
              </Grid>
              <Grid item sx={{ ml: 'auto' }}>
                <Typography variant="caption">${minimumBalanceForAccountOpening ?? ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mt: 1 }} />
              </Grid>
            </Grid>
          </Box>
          <Grid container sx={{ px: 2, mt: 1.5 }}>
            <Grid item md={6} xs={6}>
              {isDeleteAllowed && (
                <Button onClick={() => setIsDeleteInProgress(true)} size="small" variant="text">
                  {removeLabel}
                </Button>
              )}
            </Grid>
            <Grid item sx={{ ml: 'auto' }}>
              <Button onClick={() => onQuickApplySleeveTemplate()} size="small" variant="text">
                {quickApplyLabel}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};
