import { ComponentTypes, QuestionOrderSteps } from '../../Section/types';
import { getFieldName } from '../../Section/utils';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';
import {
  filterValidationRules,
  getPaperworkDataForPrefill,
  getPaperworkStringFieldValue,
  requiredValidation,
} from './utils';

export const taxBracket = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.TAX_BRACKET,
    dataPointKey: DataPointKeys.TAX_BRACKET,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.wealthInformation?.taxBracket;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.wealthInformation = {
        ...paperworkInput.wealthInformation,
        taxBracket: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.TAX_BRACKET, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const annualHouseholdIncome = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.ANNUAL_HOUSEHOLD_INCOME,
    dataPointKey: DataPointKeys.ANNUAL_HOUSEHOLD_INCOME,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.wealthInformation?.annualIncome;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.wealthInformation = {
        ...paperworkInput.wealthInformation,
        annualIncome: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.ANNUAL_HOUSEHOLD_INCOME, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const annualRecurringExpenses = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.ANNUAL_RECURRING_EXPENSES,
    dataPointKey: DataPointKeys.ANNUAL_RECURRING_EXPENSES,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.wealthInformation?.annualRecurringExpenses;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.wealthInformation = {
        ...paperworkInput.wealthInformation,
        annualRecurringExpenses: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.ANNUAL_RECURRING_EXPENSES, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const liquidNetWorth = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.LIQUID_NET_WORTH,
    dataPointKey: DataPointKeys.LIQUID_NET_WORTH,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.wealthInformation?.liquidAssets;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.wealthInformation = {
        ...paperworkInput.wealthInformation,
        liquidAssets: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.LIQUID_NET_WORTH, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const estimatedNetWorth = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.ESTIMATED_NET_WORTH,
    dataPointKey: DataPointKeys.ESTIMATED_NET_WORTH,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.wealthInformation?.netWorth;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.wealthInformation = {
        ...paperworkInput.wealthInformation,
        netWorth: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.ESTIMATED_NET_WORTH, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const wealthExpensesText = ({ adjacent, rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.WEALTH_EXPENSES_TEXT,
    dataPointKey: DataPointKeys.WEALTH_EXPENSES_TEXT,
    componentType: ComponentTypes.Text,
    adjacent,
    rules,
  };
};
