import omit from 'lodash.omit';

import { completeAssetsHeldAwayMapping } from '../AssetsHeldAway/config';
import { AssetsHeldAwayConfig } from '../Section/Questions/types';
import { PaperworkInvestment } from '../symphony';

import { getPaperworkBooleanFieldValue } from './helper';

import { InvestmentAmountsInput, InvestmentExperienceDetailInput, InvestmentInput } from '~/__generated__';

const getUpdatedInvestmentExperienceDetailInvestmentsAmount = (
  symphonyMappingSplit: string[],
  key: string,
  investmentsAmount: InvestmentAmountsInput,
  result: Record<string, any>,
): InvestmentAmountsInput => {
  const investmentsAmountKey = symphonyMappingSplit[3] as keyof InvestmentAmountsInput;
  investmentsAmount[investmentsAmountKey] = result[key] ? result[key] : null;

  return investmentsAmount;
};

const getUpdatedInvestmentExperienceDetail = (
  symphonyMappingSplit: string[],
  key: string,
  result: Record<string, any>,
  experienceDetail?: InvestmentExperienceDetailInput | null,
): InvestmentExperienceDetailInput => {
  const experienceDetailKey = symphonyMappingSplit[2] as keyof InvestmentExperienceDetailInput;
  experienceDetail = experienceDetail ?? {
    alternativeInvestments: { nonQualified: null, qualified: null, percentage: null },
    annuitiesAndLifeInsurance: { nonQualified: null, qualified: null, percentage: null },
    bonds: { nonQualified: null, qualified: null, percentage: null },
    foreignCurrency: { nonQualified: null, qualified: null, percentage: null },
    foreignSecurity: { nonQualified: null, qualified: null, percentage: null },
    lifeInsurance: { nonQualified: null, qualified: null, percentage: null },
    limitedPartnerships: { nonQualified: null, qualified: null, percentage: null },
    mutualFunds: { nonQualified: null, qualified: null, percentage: null },
    options: { nonQualified: null, qualified: null, percentage: null },
    other: { nonQualified: null, qualified: null, percentage: null },
    otherAssetClass: null,
    shortTerms: { nonQualified: null, qualified: null, percentage: null },
    securityFutures: { nonQualified: null, qualified: null, percentage: null },
    stocks: { nonQualified: null, qualified: null, percentage: null },
    total: { nonQualified: null, qualified: null, percentage: null },
    variableContracts: { nonQualified: null, qualified: null, percentage: null },
  };

  switch (experienceDetailKey) {
    case 'otherAssetClass':
      experienceDetail.otherAssetClass = result[key] as string;
      break;
    default:
      experienceDetail[experienceDetailKey] = getUpdatedInvestmentExperienceDetailInvestmentsAmount(
        symphonyMappingSplit,
        key,
        experienceDetail[experienceDetailKey],
        result,
      );
      break;
  }

  return experienceDetail;
};

export const getUpdatedInvestment = (
  symphonyMappingSplit: string[],
  key: string,
  investment: InvestmentInput,
  result: Record<string, any>,
): InvestmentInput => {
  const investmentInputKey = symphonyMappingSplit[1] as keyof InvestmentInput;
  switch (investmentInputKey) {
    case 'experienceDetail':
      investment.experienceDetail = getUpdatedInvestmentExperienceDetail(
        symphonyMappingSplit,
        key,
        result,
        investment.experienceDetail,
      );
      break;
    case 'hasExperienceBuyingOrSellingInstruments':
      investment.hasExperienceBuyingOrSellingInstruments = getPaperworkBooleanFieldValue(result, key);
      break;
    default:
      investment[investmentInputKey] =
        typeof result[key] === 'object'
          ? result[key][key]
            ? result[key][key].toString()
            : null
          : result[key]
          ? result[key]
          : null;
      break;
  }

  return investment;
};

export const initialiseInvestmentObject = (investment: PaperworkInvestment): InvestmentInput => {
  return {
    ...omit(investment, ['__typename', 'experienceDetail']),
    experienceDetail: investment.experienceDetail
      ? {
          alternativeInvestments: omit(investment.experienceDetail.alternativeInvestments, '__typename'),
          annuitiesAndLifeInsurance: omit(investment.experienceDetail.annuitiesAndLifeInsurance, '__typename'),
          bonds: omit(investment.experienceDetail.bonds, '__typename'),
          foreignCurrency: omit(investment.experienceDetail.foreignCurrency, '__typename'),
          foreignSecurity: omit(investment.experienceDetail.foreignSecurity, '__typename'),
          lifeInsurance: omit(investment.experienceDetail.lifeInsurance, '__typename'),
          limitedPartnerships: omit(investment.experienceDetail.limitedPartnerships, '__typename'),
          mutualFunds: omit(investment.experienceDetail.mutualFunds, '__typename'),
          options: omit(investment.experienceDetail.options, '__typename'),
          other: omit(investment.experienceDetail.other, '__typename'),
          otherAssetClass: investment.experienceDetail.otherAssetClass,
          shortTerms: omit(investment.experienceDetail.shortTerms, '__typename'),
          securityFutures: omit(investment.experienceDetail.securityFutures, '__typename'),
          stocks: omit(investment.experienceDetail.stocks, '__typename'),
          total: omit(investment.experienceDetail.total, '__typename'),
          variableContracts: omit(investment.experienceDetail.variableContracts, '__typename'),
        }
      : null,
  };
};

export const getDefaultValueForInvestmentKey = (investment: InvestmentInput, investmentMapping: string) => {
  const investmentMappingSplit = investmentMapping.split('.');
  const investmentKey = investmentMappingSplit[0] as keyof InvestmentInput;
  switch (investmentKey) {
    case 'experienceDetail':
      const experienceDetailKey = investmentMappingSplit[1] as keyof InvestmentExperienceDetailInput;
      switch (experienceDetailKey) {
        case 'otherAssetClass':
          return investment?.experienceDetail?.otherAssetClass ?? '';
        default:
          const investmentsAmountKey = investmentMappingSplit[2] as keyof InvestmentAmountsInput;
          return investment?.experienceDetail?.[experienceDetailKey]?.[investmentsAmountKey] ?? '';
      }
    default:
      return investment?.[investmentKey] ?? '';
  }
};

export const getAssetsHeldAway = (
  result: Record<string, any>,
  assetsHeldAwayConfig?: AssetsHeldAwayConfig,
): InvestmentExperienceDetailInput => {
  const investmentExperience = new Map();
  completeAssetsHeldAwayMapping.forEach(question => {
    const asset = question.symphonyMapping.split('.')[2];
    if (assetsHeldAwayConfig?.excludeQuestions?.includes(question.questionKey)) {
      investmentExperience.set(asset, {
        nonQualified: null,
        percentage: null,
        qualified: null,
      });
    } else {
      const percentage = result[`data_point:${asset}Percentage:number`];
      const amount = result[question.dataPointKey];
      investmentExperience.set(asset, {
        nonQualified: null,
        percentage: percentage ?? null,
        qualified: amount ?? null,
      });
    }
  });

  investmentExperience.set('total', {
    nonQualified: null,
    percentage: result[`data_point:totalValuePercentage:number`] ?? null,
    qualified: result[`data_point:totalValue:string`] ?? null,
  });

  return Object.fromEntries(investmentExperience);
};
