import { DataPointKeys, QuestionKeys, QuestionSetKeys } from '../../questions/types';
import { PaperworkDataQuestionConfig } from '../../types';

export const aboutTheTrusteeSectionKey = 'about_the_trustee';
export const aboutTheTrusteeQuestions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.FIRST_NAME },
  { properties: { optional: true }, questionOrSetKey: QuestionKeys.MIDDLE_NAME },
  { questionOrSetKey: QuestionKeys.LAST_NAME },
  { questionOrSetKey: QuestionKeys.EMAIL },
  { questionOrSetKey: QuestionKeys.CONFIRM_EMAIL },
  { questionOrSetKey: QuestionKeys.PHONE_NUMBER },
  { properties: { adjacent: QuestionKeys.SSN }, questionOrSetKey: QuestionKeys.BIRTH_DATE },
  { questionOrSetKey: QuestionKeys.SSN },
  { questionOrSetKey: QuestionSetKeys.HOME_ADDRESS_FIELDS },
  {
    properties: {
      optional: true,
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.DIFFERENT_MAILING_ADDRESS,
              value: true,
            },
          ],
          next: QuestionKeys.MAILING_STREET_ADDRESS,
        },
        {
          next: QuestionKeys.RESIDENT_TYPE,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.DIFFERENT_MAILING_ADDRESS,
  },
  { questionOrSetKey: QuestionSetKeys.MAILING_ADDRESS_FIELDS },
  { questionOrSetKey: QuestionSetKeys.CITIZENSHIP_FIELDS },
];
