import { getPaperworkStringFieldValue } from '../configs/questions/utils';
import { ZipCodeMapItem } from '../hooks/useGetPaperworkData';

export const isValidEmail = (email: string): boolean => {
  const indexOfAt = email.indexOf('@');
  const indexOfDot = email.lastIndexOf('.');
  if (indexOfAt < 1 || indexOfDot < indexOfAt + 2 || indexOfDot + 2 >= email.length) {
    return false;
  }

  const prefix = email.substring(0, indexOfAt);
  const domain = email.substring(indexOfAt + 1, indexOfDot);
  const suffix = email.substring(indexOfDot + 1);
  return /^[a-zA-Z0-9._+-]*$/.test(prefix) && /^[a-zA-Z0-9.-]*$/.test(domain) && /^[a-zA-Z0-9.]*$/.test(suffix);
};

export const isValidLength = (value: string, expectedLength: number): boolean => {
  return value.length === expectedLength;
};

export const isValidIdentificationNumber = (value: string): boolean => {
  return /^\*{5}\d{4}$/.test(value) || (/^\d{9}$/.test(value) && !(/123456789/.test(value) || /^(\d)\1+$/.test(value)));
};

export const isValidNonNegativeInteger = (number: string): boolean => {
  return /^\d+$/.test(number);
};

export const isConfirmEmailMatching = (
  confirmEmail: string,
  emailFieldName: string,
  data: {
    getFormValue: (fieldName?: string) => string;
  },
): boolean => {
  return getPaperworkStringFieldValue(data.getFormValue(emailFieldName)) === confirmEmail;
};

/**
 * isAddressWithoutPostBox to determine if the address contains any variation of PO Box or not
 * Length of address should not be very significant (for the case where it is introduced, max length is 50)
 * @param { address } address - Address of client
 * @returns { boolean } Returns true if address have no instance of different variation of PO Box in Address
 */
export const isAddressWithoutPostBox = (address?: string | null): boolean => {
  if (!address) {
    return true;
  }

  const regexCategory1 = new RegExp(/(^|\s)p(\.)?\s*o(\.)?\s*box(\s|$)/i); // Combinations of abbreviated PO Box
  const regexCategory2 = new RegExp(/(^|\s)post((al|\soffice)\s)?(\s)*box(\s|$)/i); // Combinations of Post(al) (Office) Box
  const regexCategory3 = new RegExp(/(^|\s)(box|bin)\s+number(\s|$)/i); // Combinations of (Box|Bin) Number
  return !regexCategory1.test(address) && !regexCategory2.test(address) && !regexCategory3.test(address);
};

export const isValidTicker = (ticker: string): boolean => {
  return /^[A-Za-z0-9]+$/.test(ticker);
};

interface IsValidZipCodeVariables {
  isZipCodeOptional?: boolean;
  linkedState: string | undefined | null;
  stateZipCodeMap?: ZipCodeMapItem[];
  zipcode: string | undefined | null;
}
/**
 * @param { IsValidZipCodeVariables } isValidZipCodeVariables it's an object with
 * * linkedState - A string param that is the state against which the zip code needs to be validated
 * * zipcode - A string param. This param is the user entered zipcode
 * * stateZipCodeMap - A list that maps states to valid it's zipcodes
 * * isZipCodeOptional - A boolean value coming from partner's paperwork config
 * This function checks if the entered zip code lies in the range of zip codes for the corresponding entered state.
 * and also if zipcode is optional field and isZipCodeOptional flag is true, it will ignore the validations and pass true.
 * Returns true only if the #digits in zipcode is greater than or equal to 3 and lies in the range of zip codes for the corresponding state linked to it or the state isn't present in the zipcode map, else returns false.
 * PDF LINK FOR STATE TO ZIP CODES MAP (stateZipCodeMap)- http://www.directmailmanagerri.com/pdfs/zipcode_list_for_US_states.pdf
 */
export const isValidZipCode = ({
  zipcode,
  linkedState,
  stateZipCodeMap,
  isZipCodeOptional = false,
}: IsValidZipCodeVariables): boolean => {
  if (!zipcode?.length && isZipCodeOptional) {
    return true;
  }
  if (!zipcode || !linkedState || !stateZipCodeMap) {
    return false;
  }
  const statesArray = stateZipCodeMap.map(item => item.state);
  const stateIndex = statesArray.indexOf(linkedState);
  if (stateIndex === -1) {
    return true;
  }

  const firstThreeCharacters = zipcode.length >= 3 ? parseInt(zipcode.slice(0, 3), 10) : '';
  if (firstThreeCharacters && stateZipCodeMap[stateIndex].ranges.length !== 0) {
    return stateZipCodeMap[stateIndex].ranges.some(
      range => firstThreeCharacters >= parseInt(range.low, 10) && firstThreeCharacters <= parseInt(range.high, 10),
    );
  }
  return false;
};
