import * as queries from './query.gql';

import {
  GetRelatedParties,
  GetRelatedPartiesVariables,
} from '~/containers/AccountPerformance/symphony/__generated__/query.v2';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export const useGetRelatedParties = (
  options?: QueryHookOptions<GetRelatedParties, GetRelatedPartiesVariables>,
): QueryResult<GetRelatedParties, GetRelatedPartiesVariables> => {
  return useSymphonyQuery(queries.GetRelatedParties, options);
};
