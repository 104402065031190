import React, { ComponentProps } from 'react';

import { Link } from '~/components/ui/Link';
import { Button, Grid, LoadingButton, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { useIsMediumScreen } from '~/utils/responsiveness';
import { SfTheme } from '~/utils/theme';

export interface OnboardingPageCtasProps extends React.ComponentProps<typeof Grid> {
  backButtonProps?: ComponentProps<typeof Button>;
  content: {
    backCta: string;
    nextCta: string;
    saveCta?: string;
    saveExitData?: string;
    saveExitLink?: string;
  };
  dataQa?: string;
  nextButtonProps?: ComponentProps<typeof LoadingButton>;
  saveButtonProps?: ComponentProps<typeof LoadingButton>;
  saveLinkProps?: ComponentProps<typeof Link>;
  showSaveButton?: boolean;
  showSaveLink?: boolean;
}

export const OnboardingPageCtas: React.FC<OnboardingPageCtasProps> = ({
  backButtonProps,
  content,
  dataQa,
  nextButtonProps,
  saveButtonProps,
  saveLinkProps,
  showSaveButton,
  showSaveLink,
  ...rest
}) => {
  const isMediumScreen = useIsMediumScreen();
  const {
    sfOnboardingPageCtas: { styles },
  } = useTheme<SfTheme>();

  return (
    <Grid
      container
      data-qa={dataQa}
      flexDirection="column"
      item
      sx={styles.container}
      textAlign="center"
      xs={12}
      {...rest}
    >
      <Grid
        container
        justifyContent="center"
        spacing={1}
        sx={{ ...styles.ctaContainer, flexDirection: { md: 'row', xs: 'column-reverse' } }}
      >
        {backButtonProps?.onClick && (
          <Grid item>
            <Button data-qa={`${dataQa}-cta-back`} fullWidth={isMediumScreen} variant="outlined" {...backButtonProps}>
              {content?.backCta}
            </Button>
          </Grid>
        )}
        {showSaveButton && (
          <Grid item>
            <LoadingButton
              data-qa={`${dataQa}-cta-save`}
              fullWidth={isMediumScreen}
              variant="outlined"
              {...saveButtonProps}
            >
              {content?.saveCta}
            </LoadingButton>
          </Grid>
        )}
        {nextButtonProps?.onClick && (
          <Grid item>
            <LoadingButton
              data-qa={`${dataQa}-cta-next`}
              fullWidth={isMediumScreen}
              variant="contained"
              {...nextButtonProps}
            >
              {content?.nextCta}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
      {showSaveLink && (
        <RteContent
          config={{
            link: <Link {...saveLinkProps}>{content?.saveExitLink}</Link>,
          }}
          data={content?.saveExitData ?? ''}
          sx={{ ...styles.link }}
        />
      )}
    </Grid>
  );
};
