import React from 'react';

import { GetAccountActivity_client_financialAccounts_financialAccountSummary } from '~/components/AccountActivity/symphony/__generated__/query.v2';
import {
  AccountActivityTableBodyContent,
  AccountActivityTableHeadingContent,
  getTableCellValue,
  TableRowContent,
} from '~/components/AccountActivity/utils';
import { formatDisplayDate } from '~/components/AccountPerformance/utils';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '~/components/ui/mui';
import { getOpeningClosingBalance } from '~/hooks/qpr/useGetDataForQPR';

export interface Props {
  accountActivityData?: GetAccountActivity_client_financialAccounts_financialAccountSummary | null;
  content: {
    tableBody: AccountActivityTableBodyContent;
    tableHeading: AccountActivityTableHeadingContent;
  };
  dataQa?: string;
  endDate: string;
  startDate: string;
}

export const AccountActivityTable: React.FC<Props> = ({
  accountActivityData,
  content,
  dataQa = 'account-activity-table',
  endDate,
  startDate,
}) => {
  const openingClosingBalance = getOpeningClosingBalance(accountActivityData);
  return (
    <TableContainer>
      <Table role="table">
        <TableHead>
          <TableRow role="row">
            <TableCell role="columnheader">{content.tableHeading.activityColumnLabel}</TableCell>
            <TableCell role="columnheader">
              {`${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {content.tableBody.tableRow.map((row: TableRowContent) => (
            <TableRow key={row.key}>
              <TableCell data-qa={`${dataQa}-${row.key}-label`} sx={{ fontWeight: row.isBold ? 'bold' : 'normal' }}>
                {row.label}
              </TableCell>
              <TableCell data-qa={`${dataQa}-${row.key}-value`} sx={{ fontWeight: row.isBold ? 'bold' : 'normal' }}>
                {getTableCellValue(row, {
                  openingBalance: openingClosingBalance?.openingBalance ?? '',
                  contributions: accountActivityData?.contributions ?? '',
                  dividends: accountActivityData?.dividends ?? '',
                  fees: accountActivityData?.fees ?? '',
                  interest: accountActivityData?.interest ?? '',
                  marketMovement: accountActivityData?.marketMovement ?? '',
                  withdrawals: accountActivityData?.withdrawals ?? '',
                  closingBalance: openingClosingBalance?.closingBalance ?? '',
                })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
