import {
  GetIncompatiblePortfoilioModalContent,
  GetIncompatiblePortfoilioModalContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetIncompatiblePortfolioModalContent = (
  options?: QueryHookOptions<GetIncompatiblePortfoilioModalContent, GetIncompatiblePortfoilioModalContentVariables>,
): QueryResult<GetIncompatiblePortfoilioModalContent, GetIncompatiblePortfoilioModalContentVariables> => {
  return useContentstackQuery(queries.GetIncompatiblePortfoilioModalContent, options);
};
