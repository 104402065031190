import uniqueId from 'lodash.uniqueid';
import React, { useCallback, useEffect } from 'react';

import { CheckedRadioIcon } from './CheckedIcon';
import { UncheckedRadioIcon } from './UncheckedIcon';

import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormHelperText,
  FormLabel,
  MuiRadioGroup,
  Radio,
  RadioButtonCheckedIcon,
  RadioButtonUncheckedIcon,
  useTheme,
} from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { getSxPropsArray } from '~/utils/theme';

export type RadioGroupChangeEvent = (value: string, event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;

export interface Item {
  disabled?: boolean;
  label: string;
  value: any;
}

export interface Props extends Omit<FormControlProps, 'onChange'> {
  dataQa?: string;
  description?: string;
  includeHeader?: boolean;
  items: Item[];
  labelId?: string;
  legend?: React.ReactElement | string | null;
  name: string;
  onChange?: RadioGroupChangeEvent;
  row?: boolean;
  showBorder?: boolean;
  value: any;
}

export const RadioGroup: React.FC<Props> = ({
  dataQa = 'radio-group',
  description,
  disabled,
  error,
  items,
  labelId = 'radio-group-label',
  legend,
  onChange,
  required,
  row,
  showBorder,
  value: baseValue,
  includeHeader = true,
}) => {
  const [value, setValue] = React.useState<string | null>(baseValue);
  const {
    sfRadioGroup: {
      styles: { radioBorder, base, radioIcons: radioIconStyles },
    },
  } = useTheme();
  useEffect(() => {
    if (baseValue !== value) {
      setValue(baseValue);
    }
  }, [baseValue]);
  const radioBaseStyles = base ?? [];
  const radioBorderStyles = radioBorder?.base ?? [];

  const getRadioBorderColor = useCallback(
    (item: Item): string | undefined => {
      if (item.disabled) {
        return 'action.disabled';
      }
      if (item.value === value) {
        return radioBorder?.selectedColor;
      }
      return undefined;
    },
    [radioBorder?.selectedColor, value],
  );

  /**
   * @param event 'click' type will fire upon Arrow key navigation just as an actual mouse click would.
   * https://github.com/facebook/react/issues/7407
   */
  const handleChange = (event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    const newValue = (event.target as HTMLButtonElement).value;
    setValue(newValue);
    onChange?.(newValue, event);
  };

  // Generating uniqueId for all occurances of radio group
  labelId = uniqueId(dataQa + '-id');

  return (
    <FormControl data-qa={dataQa} disabled={disabled} error={error} required={required} sx={{ maxWidth: '100%' }}>
      {!!legend && !!includeHeader && (
        <FormLabel color="primary" component="legend" id={labelId}>
          {typeof legend === 'string' ? <RteContent data={legend} /> : legend}
        </FormLabel>
      )}
      <MuiRadioGroup aria-labelledby={labelId} row={row} value={value}>
        {items.map((item, index) => (
          <FormControlLabel
            control={
              <Radio
                checkedIcon={radioIconStyles?.checkedIcon ? <CheckedRadioIcon /> : <RadioButtonCheckedIcon />}
                icon={radioIconStyles?.icon ? <UncheckedRadioIcon /> : <RadioButtonUncheckedIcon />}
                onClick={handleChange}
              />
            }
            disabled={item.disabled}
            key={index}
            label={<RteContent data={item.label} sx={{ my: 1 }} variantMapping="p" />}
            sx={[
              ...getSxPropsArray(radioBaseStyles),
              ...(showBorder
                ? [...getSxPropsArray(radioBorderStyles), { borderColor: getRadioBorderColor(item) }]
                : []),
            ]}
            value={item.value}
          />
        ))}
      </MuiRadioGroup>
      <FormHelperText>{description}</FormHelperText>
    </FormControl>
  );
};
