import { isMasked } from './helper';

import { TrustInformationInput } from '~/__generated__';
import { isoDateFormat } from '~/utils/format';

export const getUpdatedTrustInformation = (
  objKey: string,
  key: string,
  trustInformation: TrustInformationInput,
  result: Record<string, any>,
): TrustInformationInput => {
  if (result[key] && result[key] !== '') {
    switch (objKey) {
      case 'trustIdentificationNumber':
        trustInformation.trustIdentificationNumber = isMasked(result[key]?.toString()) ? null : result[key];
        break;
      case 'trustIdentificationType':
        if (result[key] === 'TIN') {
          trustInformation.isTrusteeGrantor = null;
        }
        trustInformation.trustIdentificationType = result[key];
        break;
      case 'numberOfTrustees':
        trustInformation.numberOfTrustees = parseInt(result[key], 10);
        break;
      case 'trustAgreementDate':
        trustInformation.trustAgreementDate = isoDateFormat(result[key]);
        break;
      case 'isTrusteeGrantor':
        // https://sigfig.atlassian.net/browse/ANR-9924  If the SSN is true, then the trustee will automatically become the grantor, so 'isTrusteeGrantor' will be true and vice versa.
        trustInformation.isTrusteeGrantor = result[key] === 'true';
        break;
      default:
        trustInformation[objKey as keyof TrustInformationInput] = result[key] as never;
        break;
    }
  }
  return trustInformation;
};

export const getDefaultValueForTruthInformationKey = (
  trustInformation: TrustInformationInput,
  trustInformationMapping: string,
) => {
  const trustInformationKey = trustInformationMapping as keyof TrustInformationInput;
  if (trustInformationKey === 'isTrusteeGrantor') {
    return !!trustInformation?.isTrusteeGrantor;
  }
  return trustInformationKey === 'numberOfTrustees'
    ? trustInformation?.[trustInformationKey]?.toString() ?? ''
    : trustInformation?.[trustInformationKey] ?? '';
};
