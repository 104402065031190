import debounce from 'lodash/debounce';
import React from 'react';

import { Autocomplete, Box, InputAdornment, SearchIcon } from '~/components/ui/mui';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { SearchOption } from '~/containers/HomeOfficeDashboard/types';

interface Content {
  emptyResults: string;
  placeholder: string;
  repPrefix: string;
}

export interface Props {
  content: Content;
  dataQa?: string;
  disabled?: boolean;
  onSearchTermChange: (searchTerm: string) => void;
  onSelect: (selectedSearchOption: SearchOption) => void;
  searchError: boolean;
  searchOptions: SearchOption[];
  searchOptionsLoading: boolean;
}

const DEBOUNCE_WAIT_TIME = 500;

export const Search: React.FC<Props> = ({
  content,
  dataQa = 'home-office-advisor-search',
  disabled,
  onSearchTermChange,
  onSelect,
  searchError,
  searchOptions,
  searchOptionsLoading,
}: Props) => {
  const debounceSearchInput = debounce(onSearchTermChange, DEBOUNCE_WAIT_TIME);
  const [open, setOpen] = React.useState(false);

  return (
    <Autocomplete
      ListboxProps={{
        style: {
          maxHeight: '100px',
        },
      }}
      disablePortal
      disabled={disabled}
      filterOptions={options => options}
      loading={searchOptionsLoading}
      noOptionsText={content.emptyResults}
      onChange={(_e, value) => {
        if (value && typeof value === 'object') {
          onSelect(value);
          setOpen(false);
        }
      }}
      onClose={() => setOpen(false)}
      onInputChange={(_event, value, reason) => {
        if (!value || value.length === 0) {
          setOpen(false);
        } else {
          setOpen(true);
        }
        if (reason !== 'reset') {
          debounceSearchInput(value);
        }
      }}
      open={open}
      options={searchOptions.map(option => ({
        id: option.advisorPartyId,
        label: option.advisorName,
        ...option,
      }))}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: null,
          }}
          ariaLabel={content.placeholder}
          dataQa={`${dataQa}-search-field`}
          error={searchError}
          id={`${dataQa}-search-field`}
          placeholder={content.placeholder}
          size="small"
          variant="outlined"
        />
      )}
      renderOption={(props, option: SearchOption) => (
        <Box component="li" {...props} data-qa={`${dataQa}-option-${option.id}`} key={option.id}>
          <Typography variant="body1">
            {option.advisorName} {option.advisorRepCode ? `- ${content.repPrefix} ${option.advisorRepCode}` : ''}
          </Typography>
        </Box>
      )}
      sx={{ width: '300px' }}
    />
  );
};
