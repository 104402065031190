import { DataPointKeys, QuestionKeys } from '../../questions/types';
import { PaperworkDataQuestionConfig } from '../../types';

export const eDeliverySectionKey = 'e_delivery';
export const eDeliveryQuestions: PaperworkDataQuestionConfig[] = [
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.EDELIVERY,
              value: 'Yes',
            },
          ],
          next: QuestionKeys.EDELIVERY_EMAIL,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.EDELIVERY,
  },
  { questionOrSetKey: QuestionKeys.EDELIVERY_EMAIL },
];
