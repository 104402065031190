import { populateAssetsHeldAway } from '../../Section/Question/AssetsHeldAwayV2/utils';
import { ComponentTypes, QuestionOrderSteps } from '../../Section/types';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';

export const assetsHeldAway = ({ paperworkFreeFormId, rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY,
    dataPointKey: DataPointKeys.ASSETS_HELD_AWAY,
    prefillValueGetter: _data => undefined, // Not needed, as handled by the custom component
    paperworkInputSetter: (paperworkInput, data) => {
      populateAssetsHeldAway(paperworkInput, data, paperworkFreeFormId);
    },
    componentType: ComponentTypes.AssetsHeldAway,
    rules,
  };
};
