import React from 'react';

import { Link } from '~/components/ui/Link';
import { SettingsIcon, TableCell, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { SfTheme } from '~/utils/theme';

export interface AdvancedPricingLinkProps extends React.ComponentProps<typeof TableCell> {
  advancedPricingModalLabel?: string;
  dataQa?: string;
  showAdvancedPricing: () => void;
}

export const AdvancedPricingLink = ({
  dataQa,
  showAdvancedPricing,
  advancedPricingModalLabel,
  ...rest
}: AdvancedPricingLinkProps) => {
  const {
    sfPricingSummary: { typographyVariants },
  } = useTheme<SfTheme>();

  return (
    <TableCell colSpan={2} sx={{ textAlign: 'right' }} {...rest}>
      <Link
        data-qa={`${dataQa}-advanced-pricing-modal-link`}
        onClick={showAdvancedPricing}
        sx={{ display: 'inline-flex' }}
        underline="hover"
      >
        <SettingsIcon fontSize="small" />
        <Typography ml={0.3} variant={typographyVariants?.advancedPricingLink}>
          {advancedPricingModalLabel}
        </Typography>
      </Link>
    </TableCell>
  );
};
