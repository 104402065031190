import React, { FC } from 'react';

import { usePaperworkSectionManager } from './hooks/usePaperworkSectionManager';
import { Question } from './Question';
import { SectionHeading } from './SectionHeading';
import { ComponentTypes, Props, QuestionOrderSteps } from './types';
import { getFieldName } from './utils';

import { Box, Grid, Skeleton } from '~/components/ui/mui';

export const Section: FC<Props> = ({
  dataQa = 'paperworkV2-section',
  accountProfileContent,
  accountType,
  allPaperworkDataAvailableForPrefill,
  allSavedPaperworkData,
  content,
  contentOptions,
  dependentFormFieldsInvisibility,
  disablePageEditing,
  faPartyAttributes,
  faRepCodes,
  formHooks,
  harvestLosses,
  isFormSubmitted,
  isPaperworkSaving,
  isPaperworkSubmitting,
  isAccountProfileEdit,
  managedProductId,
  onFormFieldsVisibilityChange,
  order,
  partyId,
  questionnaireInvestWealthData,
  registerCustomDeferredPromise,
  sectionHeading,
  sectionKey,
  sectionNumber,
  setUnmaskedValues,
  showTlhButtonIcon = false,
  unmaskedValues,
  unregisterCustomDeferredPromise,
}) => {
  const {
    closeTlhModal,
    fetchingQuestions,
    isTlhModalOpen,
    onCheckboxAnswerChange,
    onDateAnswerChange,
    onDropdownAnswerChange,
    onQuestionShow,
    onRadioAnswerChange,
    openTlhModal,
    questions,
    validationMessages,
  } = usePaperworkSectionManager({
    content,
    formHooks,
    harvestLosses,
    onFormFieldsVisibilityChange,
    order,
  });

  const questionsRendered = new Set();

  const renderQuestionComponent = (
    questionsInRow: QuestionOrderSteps[],
    _question: QuestionOrderSteps,
    hidden: boolean,
  ) => {
    return questionsInRow.map((questionInRow, questionInRowIndex) => {
      questionsRendered.add(questionInRow.questionKey);
      const savedPaperworkDataForCurrentQuestion = allSavedPaperworkData?.find(
        paperwork => paperwork.paperworkFreeFormId === questionInRow.paperworkFreeFormId,
      );

      const QuestionComponent = questionInRow.customComponent ?? Question;
      return (
        <QuestionComponent
          accountProfileContent={accountProfileContent}
          accountType={accountType}
          allPaperworkDataAvailableForPrefill={allPaperworkDataAvailableForPrefill}
          allSavedPaperworkData={allSavedPaperworkData}
          contentOptions={contentOptions}
          countriesList={content.countriesList ?? []}
          disablePageEditing={disablePageEditing}
          faPartyAttributes={faPartyAttributes}
          faRepCodes={faRepCodes}
          formHooks={formHooks}
          harvestLosses={!!harvestLosses}
          hidden={hidden}
          isAccountProfileEdit={isAccountProfileEdit}
          isFormSubmitted={isFormSubmitted}
          isPaperworkSaving={isPaperworkSaving}
          isPaperworkSubmitting={isPaperworkSubmitting}
          key={questionInRow.questionKey}
          managedProductId={managedProductId}
          minorAgesByState={content.minorAgesByState}
          numberOfQuestionsInRow={questionsInRow.length}
          onCheckboxAnswerChange={onCheckboxAnswerChange}
          onClose={closeTlhModal}
          onDateAnswerChange={onDateAnswerChange}
          onDropdownAnswerChange={onDropdownAnswerChange}
          onFormFieldsVisibilityChange={onFormFieldsVisibilityChange}
          onQuestionShow={onQuestionShow}
          onRadioAnswerChange={onRadioAnswerChange}
          open={isTlhModalOpen}
          openModal={openTlhModal}
          partyId={partyId}
          questionInRow={questionInRow}
          questionInRowIndex={questionInRowIndex}
          questionnaireInvestWealthData={questionnaireInvestWealthData}
          questionsContent={content.questions}
          registerCustomDeferredPromise={registerCustomDeferredPromise}
          savedPaperworkDataForCurrentQuestion={savedPaperworkDataForCurrentQuestion}
          setUnmaskedValues={setUnmaskedValues}
          showTlhButtonIcon={showTlhButtonIcon}
          stateZipCodeMap={content.stateZipCodeMap}
          statesList={content.statesList ?? []}
          unmaskedValues={unmaskedValues}
          unregisterCustomDeferredPromise={unregisterCustomDeferredPromise}
          validationMessages={validationMessages}
        />
      );
    });
  };

  return (
    <Grid data-qa={`${dataQa}-section-${sectionKey}`} item key={`${dataQa}-section-${sectionKey}`} md={12}>
      <SectionHeading
        dataQa={`${dataQa}-section-heading-${sectionKey}`}
        heading={sectionHeading ?? sectionKey}
        key={`${dataQa}-section-heading-${sectionKey}`}
        number={sectionNumber}
      />
      <Box sx={{ pt: 1 }}>
        {fetchingQuestions ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : isAccountProfileEdit ? (
          <Box data-qa={dataQa} sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {questions.map((question, index) => {
              if (questionsRendered.has(question.questionKey)) {
                return null;
              }

              const hasAdjacent = question.adjacent !== undefined && question.adjacent !== null;
              const adjacentQuestion = hasAdjacent
                ? questions.find(q => q.questionKey === question.adjacent)
                : undefined;
              const questionsInRow = adjacentQuestion ? [question, adjacentQuestion] : [question];
              const hidden =
                dependentFormFieldsInvisibility[getFieldName(question.dataPointKey, question.paperworkFreeFormId)];
              const newLine = questionsInRow[questionsInRow.length - 1]?.rules[0]?.newLine ?? false;
              const fullWidthComponent =
                question.componentType === ComponentTypes.Label ||
                question.componentType === ComponentTypes.Info ||
                question.componentType === ComponentTypes.Beneficiary ||
                question.componentType === ComponentTypes.Contacts;

              return (
                <Box
                  key={index}
                  sx={{
                    width: newLine || fullWidthComponent ? 1 : 0.33,
                    display: 'flex',
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'flex-start',
                      my: 2,
                      width: newLine && !fullWidthComponent ? 0.33 : 1,
                      display: hidden ? 'none' : 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    {renderQuestionComponent(questionsInRow, question, hidden)}
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box data-qa={dataQa}>
            {questions.map((question, index) => {
              if (question.componentType === ComponentTypes.Hidden) {
                questionsRendered.add(question.questionKey);
                return null;
              }
              if (questionsRendered.has(question.questionKey)) {
                return null;
              }

              const hasAdjacent = question.adjacent !== undefined && question.adjacent !== null;
              const adjacentQuestion = hasAdjacent
                ? questions.find(q => q.questionKey === question.adjacent)
                : undefined;
              const questionsInRow = adjacentQuestion ? [question, adjacentQuestion] : [question];
              const hidden =
                dependentFormFieldsInvisibility[getFieldName(question.dataPointKey, question.paperworkFreeFormId)];

              return (
                <Box
                  key={index}
                  sx={{
                    alignItems: 'flex-start',
                    display: hidden ? 'none' : 'flex',
                    my: 2,
                    width: 1,
                  }}
                >
                  {renderQuestionComponent(questionsInRow, question, hidden)}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Grid>
  );
};
