import React from 'react';

import { formatDisplayDate } from '../utils';

import { AccountActivityTable } from '~/components/AccountActivity/AccountActivityTable';
import { GetAccountActivity_client_financialAccounts_financialAccountSummary } from '~/components/AccountActivity/symphony/__generated__/query.v2';
import { AccountActivityContent, getOpeningClosingBalanceDifference } from '~/components/AccountActivity/utils';
import { Box, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { getOpeningClosingBalance } from '~/hooks/qpr/useGetDataForQPR';

export interface Props {
  accountActivityData?: GetAccountActivity_client_financialAccounts_financialAccountSummary | null;
  content: AccountActivityContent;
  dataQa?: string;
  endDate: string;
  startDate: string;
}

export const AccountPerformanceActivity: React.FC<Props> = ({
  accountActivityData,
  content,
  dataQa = 'account-performance-activity',
  endDate,
  startDate,
}) => {
  const {
    sfAccountPerformance: { styles, typographyVariants },
  } = useTheme();
  const openingClosingBalance = getOpeningClosingBalance(accountActivityData);
  return (
    <Box data-qa={dataQa} id="accountActivity" sx={styles.container}>
      <Typography
        color="text.primary"
        component="h2"
        data-qa={`${dataQa}-header-large-text`}
        variant={typographyVariants?.sectionHeading || 'h3'}
      >
        {content.header.title}
      </Typography>
      <Typography color="text.secondary" sx={{ mt: 1.5 }} variant={typographyVariants?.selectedPeriod}>
        {`${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`}
      </Typography>

      <RteContent
        config={{
          balanceDifference: (
            <Typography color="primary.main" sx={{ display: 'inline' }}>
              {getOpeningClosingBalanceDifference(openingClosingBalance).balanceDifference}
            </Typography>
          ),
        }}
        data={
          getOpeningClosingBalanceDifference(openingClosingBalance).increased
            ? content.header.balanceIncreasedBy
            : content.header.balanceDecreasedBy
        }
        data-qa={`${dataQa}-balance-increased-by`}
        sx={{ my: 3 }}
      />
      <AccountActivityTable
        accountActivityData={accountActivityData}
        content={{
          tableBody: content.tableBody,
          tableHeading: content.tableHeading,
        }}
        dataQa={`${dataQa}-table`}
        endDate={endDate}
        startDate={startDate}
      />
    </Box>
  );
};
