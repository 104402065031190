import {
  GetPricingModalContent,
  GetPricingModalContent_all_pricing_modal_items_fees,
  GetPricingModalContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type PricingModalFees = GetPricingModalContent_all_pricing_modal_items_fees;

export const useGetPricingModalContent = (
  options?: QueryHookOptions<GetPricingModalContent, GetPricingModalContentVariables>,
): QueryResult<GetPricingModalContent, GetPricingModalContentVariables> =>
  useContentstackQuery(queries.GetPricingModalContent, options);
