import { ComponentTypes, QuestionOrderSteps } from '../../Section/types';
import { getFieldName } from '../../Section/utils';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';
import {
  filterValidationRules,
  getPaperworkDataForPrefill,
  getPaperworkEnumFieldValue,
  getPaperworkStringFieldValue,
  requiredValidation,
} from './utils';

import { EmploymentStatus } from '~/__generated__';

export const companyName = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.COMPANY_NAME,
    dataPointKey: DataPointKeys.COMPANY_NAME,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyCompany?.organizationName;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.party.partyCompany = {
        ...paperworkInput.party.partyCompany,
        organizationName: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.COMPANY_NAME, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const employmentStatus = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.EMPLOYMENT_STATUS,
    dataPointKey: DataPointKeys.EMPLOYMENT_STATUS,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyPerson?.employmentStatus;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.party.partyPerson = {
        ...paperworkInput.party.partyPerson,
        employmentStatus: getPaperworkEnumFieldValue(
          EmploymentStatus,
          data.formValues[getFieldName(DataPointKeys.EMPLOYMENT_STATUS, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const incomeSource = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.INCOME_SOURCE,
    dataPointKey: DataPointKeys.INCOME_SOURCE,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.wealthInformation?.sourceOfInitialFunds;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.wealthInformation = {
        ...paperworkInput.wealthInformation,
        sourceOfInitialFunds: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.INCOME_SOURCE, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const jobTitle = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.JOB_TITLE,
    dataPointKey: DataPointKeys.JOB_TITLE,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyCompany?.designation;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.party.partyCompany = {
        ...paperworkInput.party.partyCompany,
        designation: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.JOB_TITLE, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const jobTitleSelfEmployed = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.JOB_TITLE_SELF_EMPLOYED,
    dataPointKey: DataPointKeys.JOB_TITLE_SELF_EMPLOYED,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyCompany?.designation;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.party.partyCompany = {
        ...paperworkInput.party.partyCompany,
        designation: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.JOB_TITLE_SELF_EMPLOYED, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const otherIncomeSource = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.OTHER_INCOME_SOURCE,
    dataPointKey: DataPointKeys.OTHER_INCOME_SOURCE,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.wealthInformation?.otherSourceOfInitialFunds;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.wealthInformation = {
        ...paperworkInput.wealthInformation,
        otherSourceOfInitialFunds: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.OTHER_INCOME_SOURCE, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};
