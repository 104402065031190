import { PaperworkDataPageConfig } from '../types';

import {
  coApplicantHeading as coApplicantAboutYouSectionKey,
  coApplicantQuestions as coApplicantAboutYouQuestions,
  questions as aboutYouQuestions,
  sectionKey as aboutYouSectionKey,
} from './sections/aboutYou';
import {
  sectionKey as beneficiarySectionKey,
  taxableQuestions as beneficiaryTaxableQuestions,
  trustedContacts as trustedContactsQuestions,
  trustedContactsOnlyHeading as trustedContactsSectionKey,
} from './sections/beneficiariesAndTrustedContacts';
import {
  questions as identificationAndEmploymentQuestions,
  sectionKey as identificationAndEmploymentSectionKey,
} from './sections/identificationAndEmployment';
import { questions as regulatoryQuestions, sectionKey as regulatorySectionKey } from './sections/regulatory';
import {
  questions as wealthExpenseQuestions,
  sectionKey as wealthExpenseSectionKey,
} from './sections/wealthAndExpenses';

import { FinancialAccountType, PaperworkType } from '~/__generated__';

export const jointOwnerPaperworkPagesForOnboarding = (
  _accountType: FinancialAccountType,
): PaperworkDataPageConfig[] => {
  return [
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: aboutYouSectionKey,
          questions: aboutYouQuestions,
        },
        {
          sectionKey: identificationAndEmploymentSectionKey,
          questions: identificationAndEmploymentQuestions,
        },
        {
          sectionKey: beneficiarySectionKey,
          questions: beneficiaryTaxableQuestions,
        },
      ],
    },
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: wealthExpenseSectionKey,
          questions: wealthExpenseQuestions,
        },
        {
          sectionKey: regulatorySectionKey,
          questions: regulatoryQuestions,
        },
      ],
    },
    {
      heading: 'heading_co_applicant',
      paperworkFreeFormIdForPage: 'joint',
      sections: [
        {
          sectionKey: coApplicantAboutYouSectionKey,
          questions: coApplicantAboutYouQuestions,
        },
        {
          sectionKey: identificationAndEmploymentSectionKey,
          questions: identificationAndEmploymentQuestions,
        },
        {
          sectionKey: trustedContactsSectionKey,
          questions: trustedContactsQuestions,
        },
      ],
    },
  ];
};

export const jointPaperworkFreeFormIdToProfileType: Record<string, PaperworkType> = {
  individual: PaperworkType.PRIMARY,
  joint: PaperworkType.SECONDARY,
};
