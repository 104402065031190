import { QuestionRule, Validation } from '~/containers/Paperwork/Section/Questions/types';

export enum QuestionKeys {
  ADDITIONAL_STAKEHOLDER = 'question:additional_stakeholder',
  ANNUAL_HOUSEHOLD_INCOME = 'question:annual_household_income',
  ANNUAL_RECURRING_EXPENSES = 'question:annual_recurring_expenses',
  ASSETS_HELD_AWAY = 'question:assets_held_away',
  ASSETS_HELD_AWAY_ALTERNATIVE_INVESTMENTS = 'question:assets_held_away_alternative_investments',
  ASSETS_HELD_AWAY_ANNUITIES_AND_LIFE_INSURANCE = 'question:assets_held_away_annuities_and_life_insurance',
  ASSETS_HELD_AWAY_BONDS = 'question:assets_held_away_bonds',
  ASSETS_HELD_AWAY_FOREIGN_CURRENCY = 'question:assets_held_away_foreign_currency',
  ASSETS_HELD_AWAY_FOREIGN_SECURITY = 'question:assets_held_away_foreign_security',
  ASSETS_HELD_AWAY_LIFE_INSURANCE = 'question:assets_held_away_life_insurance',
  ASSETS_HELD_AWAY_LIMITED_PARTNERSHIPS = 'question:assets_held_away_limited_partnerships',
  ASSETS_HELD_AWAY_MUTUAL_FUNDS = 'question:assets_held_away_mutual_funds',
  ASSETS_HELD_AWAY_OPTIONS = 'question:assets_held_away_options',
  ASSETS_HELD_AWAY_OTHER = 'question:assets_held_away_other',
  ASSETS_HELD_AWAY_SECURITY_FUTURES = 'question:assets_held_away_security_futures',
  ASSETS_HELD_AWAY_SHORT_TERMS = 'question:assets_held_away_short_terms',
  ASSETS_HELD_AWAY_STOCKS = 'question:assets_held_away_stocks',
  ASSETS_HELD_AWAY_TOTAL_ASSET_VALUE = 'question:assets_held_away_total_asset_value',
  ASSETS_HELD_AWAY_VARIABLE_CONTRACTS = 'question:assets_held_away_variable_contracts',
  BIRTH_DATE = 'question:birth_date',
  CITIZENSHIP = 'question:citizenship',
  COMPANY_CITY = 'question:company_city',
  COMPANY_COUNTRY = 'question:company_country',
  COMPANY_NAME = 'question:company_name',
  COMPANY_STATE = 'question:company_state',
  COMPANY_STREET_ADDRESS = 'question:company_street_address',
  COMPANY_STREET_ADDRESS_LINE_2 = 'question:company_street_line_2',
  COMPANY_ZIP_CODE = 'question:company_zip_code',
  CONFIRM_EMAIL = 'question:confirm_email',
  CONTINGENT_BENEFICIARY = 'question:beneficiary',
  DESIGNATE_PRIMARY_BENEFICIARY = 'question:designate_primary_beneficiary',
  DESIGNATE_PRIMARY_BENEFICIARY_LABEL = 'question:designate_primary_label_text',
  DESIGNATE_TRUSTED_CONTACT = 'question:designate_trusted_contact',
  DIFFERENT_MAILING_ADDRESS = 'question:different_mailing_address',
  DRIVING_LICENSE_EXPIRY_DATE = 'question:driving_license_expiry_date',
  DRIVING_LICENSE_IDENTIFICATION_NUMBER = 'question:driving_license_identification_number',
  DRIVING_LICENSE_ISSUE_DATE = 'question:driving_license_issue_date',
  DRIVING_LICENSE_STATE = 'question:driving_license_state',
  EDELIVERY = 'question:enroll_in_edelivery',
  EDELIVERY_EMAIL = 'question:edelivery_email',
  EMAIL = 'question:email',
  EMPLOYMENT = 'question:employment',
  EMPLOYMENT_STATUS = 'question:employment_status',
  ESTIMATED_NET_WORTH = 'question:estimated_net_worth',
  FIRST_NAME = 'question:first_name',
  GRANTOR_PAGE_SOFT_WARNING = 'question:grantor_page_soft_warning',
  GRANTOR_SSN = 'question:grantor_ssn',
  HOME_CITY = 'question:city',
  HOME_COUNTRY = 'question:country',
  HOME_STATE = 'question:state',
  HOME_STREET_ADDRESS = 'question:street_address',
  HOME_STREET_ADDRESS_LINE_2 = 'question:street_line_2',
  HOME_ZIP_CODE = 'question:zip_code',
  IDENTIFICATION = 'question:identification',
  IDENTIFICATION_TYPE = 'question:identification_type',
  INCOME_SOURCE = 'question:income_source',
  IS_SSN_SAME_AS_TRUSTEE = 'question:is_ssn_same_as_trustee',
  JOB_TITLE = 'question:job_title',
  JOB_TITLE_SELF_EMPLOYED = 'question:job_title_self_employed',
  LAST_NAME = 'question:last_name',
  LIQUID_NET_WORTH = 'question:liquid_net_worth',
  MAILING_CITY = 'question:mailing_city',
  MAILING_COUNTRY = 'question:mailing_country',
  MAILING_STATE = 'question:mailing_state',
  MAILING_STREET_ADDRESS = 'question:mailing_street_address',
  MAILING_STREET_ADDRESS_LINE_2 = 'question:mailing_street_line_2',
  MAILING_ZIP_CODE = 'question:mailing_zip_code',
  MARITAL_STATUS = 'question:marital_status',
  MIDDLE_NAME = 'question:middle_name',
  NUMBER_OF_DEPENDENTS = 'question:number_of_dependents',
  NUMBER_OF_TRUSTEES = 'question:number_of_trustees',
  ORIGINAL_DEPOSITOR_BIRTH_DATE = 'question:original_depositor_birth_date',
  ORIGINAL_DEPOSITOR_DEATH_DATE = 'question:original_depositor_death_date',
  ORIGINAL_DEPOSITOR_FIRST_NAME = 'question:original_depositor_first_name',
  ORIGINAL_DEPOSITOR_LAST_NAME = 'question:original_depositor_last_name',
  ORIGINAL_DEPOSITOR_MIDDLE_NAME = 'question:original_depositor_middle_name',
  ORIGINAL_DEPOSITOR_RELATIONSHIP_TYPE = 'question:original_depositor_relationship',
  ORIGINAL_DEPOSITOR_SSN = 'question:original_depositor_ssn',
  ORIGINAL_DEPOSITOR_TEXT = 'question:original_depositor_text',
  OTHER_IDENTIFICATION_EXPIRY_DATE = 'question:other_identification_expiry_date',
  OTHER_IDENTIFICATION_ISSUE_DATE = 'question:other_identification_issue_date',
  OTHER_IDENTIFICATION_NUMBER = 'question:other_identification_number',
  OTHER_INCOME_SOURCE = 'question:other_income_source',
  PASSPORT_COUNTRY = 'question:passport_country',
  PASSPORT_EXPIRY_DATE = 'question:passport_expiry_date',
  PASSPORT_IDENTIFICATION_NUMBER = 'question:passport_identification_number',
  PASSPORT_ISSUE_DATE = 'question:passport_issue_date',
  PHONE_NUMBER = 'question:phone_number',
  PRIMARY_BENEFICIARY = 'question:beneficiary_primary',
  REGULATORY = 'question:regulatory',
  RESIDENT_ALIEN_TAX_STATUS = 'question:resident_alien_tax_status',
  RESIDENT_TYPE = 'question:resident_type',
  REVOCABLE_PRIMARY_TRUSTEE = 'question:revocable_primary_trustee',
  REVOKER_FIRST_NAME = 'question:revoker_first_name',
  REVOKER_LAST_NAME = 'question:revoker_last_name',
  REVOKER_MIDDLE_NAME = 'question:revoker_middle_name',
  SAME_HOME_MAILING_ADDRESS = 'question:same_home_mailing_address',
  SECTION_JUSTIFICATION = 'question:section_justification',
  SSN = 'question:ssn',
  STATE_LAW_GOVERNING_TRUST = 'question:state_law_governing_trust',
  TAX_BRACKET = 'question:tax_bracket',
  TAX_REPORTING_NUMBER = 'question:tax_reporting_number',
  TAX_REPORTING_TYPE = 'question:tax_reporting_type',
  TENANTS_IN_COMMON_PERCENTAGE = 'question:tenants_in_common_percentage',
  TENANTS_IN_COMMON_PERCENTAGE_CO_APPLICANT = 'question:tenants_in_common_percentage_co_applicant',
  TIN = 'question:tin',
  TLH = 'question:tlh',
  TLH_LABEL = 'question:tlh_label',
  TLH_TEXT = 'question:tlh_text',
  TRUSTED_CONTACT = 'question:trusted_contact',
  TRUST_DATE = 'question:trust_date',
  TRUST_NAME = 'question:trust_name',
  TRUST_SSN = 'question:trust_ssn',
  TRUST_TYPE = 'question:type_of_trust',
  US_TAX_STATUS = 'question:us_tax_status',
  WEALTH_EXPENSES_TEXT = 'question:wealth_expenses_text',
}

export enum DataPointKeys {
  ADDITIONAL_STAKEHOLDER = 'label:additional_stakeholder:string',
  ANNUAL_HOUSEHOLD_INCOME = 'data_point:annual_household_income:float',
  ANNUAL_RECURRING_EXPENSES = 'data_point:annual_recurring_expenses:float',
  ASSETS_HELD_AWAY = 'custom:assets_held_away:assets_held_away',
  BIRTH_DATE = 'data_point:birth_date:date',
  CITIZENSHIP = 'data_point:citizenship:single_option',
  COMPANY_NAME = 'data_point:company_name:string',
  CONFIRM_EMAIL = 'data_point:confirm_email:email',
  CONTINGENT_BENEFICIARY = 'custom:contingent_beneficiaries:beneficiaries',
  DESIGNATE_PRIMARY_BENEFICIARY = 'data_point:designate_primary_beneficiary:single_option',
  DESIGNATE_PRIMARY_BENEFICIARY_LABEL = 'text:designate_primary_label_text:string',
  DESIGNATE_TRUSTED_CONTACT = 'data_point:designate_trusted_contact:single_option',
  DIFFERENT_MAILING_ADDRESS = 'data_point:different_mailing_address:boolean',
  DRIVING_LICENSE_EXPIRY_DATE = 'data_point:driving_license_expiry_date:date',
  DRIVING_LICENSE_IDENTIFICATION_NUMBER = 'data_point:driving_license_identification_number:string',
  DRIVING_LICENSE_ISSUE_DATE = 'data_point:driving_license_issue_date:date',
  DRIVING_LICENSE_STATE = 'data_point:driving_license_state:single_option',
  EDELIVERY = 'data_point:enroll_in_edelivery:single_option',
  EDELIVERY_EMAIL = 'data_point:edelivery_email:email',
  EMAIL = 'data_point:email:email',
  EMPLOYMENT = 'label:employment:string',
  EMPLOYMENT_STATUS = 'data_point:employment_status:single_option',
  ESTIMATED_NET_WORTH = 'data_point:estimated_net_worth:float',
  FIRST_NAME = 'data_point:first_name:string',
  GRANTOR_PAGE_SOFT_WARNING = 'info:grantor_page_soft_warning:string',
  IDENTIFICATION = 'label:identification:string',
  IDENTIFICATION_TYPE = 'data_point:identification_type:single_option',
  INCOME_SOURCE = 'data_point:income_source:single_option',
  IS_SSN_SAME_AS_TRUSTEE = 'data_point:is_ssn_same_as_trustee:single_option',
  JOB_TITLE = 'data_point:job_title:string',
  JOB_TITLE_SELF_EMPLOYED = 'data_point:job_title_self_employed:string',
  LAST_NAME = 'data_point:last_name:string',
  LIQUID_NET_WORTH = 'data_point:liquid_net_worth:float',
  MARITAL_STATUS = 'data_point:marital_status:single_option',
  MIDDLE_NAME = 'data_point:middle_name:string',
  NUMBER_OF_DEPENDENTS = 'data_point:number_of_dependents:number',
  NUMBER_OF_TRUSTEES = 'data_point:number_of_trustees:single_option',
  ORIGINAL_DEPOSITOR_BIRTH_DATE = 'data_point:original_depositor_birth_date:date',
  ORIGINAL_DEPOSITOR_DEATH_DATE = 'data_point:original_depositor_death_date:date',
  ORIGINAL_DEPOSITOR_FIRST_NAME = 'data_point:original_depositor_first_name:string',
  ORIGINAL_DEPOSITOR_LAST_NAME = 'data_point:original_depositor_last_name:string',
  ORIGINAL_DEPOSITOR_MIDDLE_NAME = 'data_point:original_depositor_middle_name:string',
  ORIGINAL_DEPOSITOR_RELATIONSHIP_TYPE = 'data_point:original_depositor_relationship:single_option',
  ORIGINAL_DEPOSITOR_SSN = 'data_point:original_depositor_ssn:ssn',
  ORIGINAL_DEPOSITOR_TEXT = 'text:original_depositor_text:string',
  OTHER_IDENTIFICATION_EXPIRY_DATE = 'data_point:other_identification_expiry_date:date',
  OTHER_IDENTIFICATION_ISSUE_DATE = 'data_point:other_identification_issue_date:date',
  OTHER_IDENTIFICATION_NUMBER = 'data_point:other_identification_number:string',
  OTHER_INCOME_SOURCE = 'data_point:other_income_source:string',
  PASSPORT_COUNTRY = 'data_point:passport_country:single_option',
  PASSPORT_EXPIRY_DATE = 'data_point:passport_expiry_date:date',
  PASSPORT_IDENTIFICATION_NUMBER = 'data_point:passport_identification_number:string',
  PASSPORT_ISSUE_DATE = 'data_point:passport_issue_date:date',
  PHONE_NUMBER = 'data_point:phone_number:number',
  PRIMARY_BENEFICIARY = 'custom:primary_beneficiaries:beneficiaries',
  REGULATORY = 'custom:regulatory',
  RESIDENT_ALIEN_TAX_STATUS = 'data_point:resident_alien_tax_status:single_option',
  RESIDENT_TYPE = 'data_point:resident_type:single_option',
  REVOCABLE_PRIMARY_TRUSTEE = 'label:revocable_primary_trustee:string',
  REVOKER_FIRST_NAME = 'data_point:revoker_first_name:string',
  REVOKER_LAST_NAME = 'data_point:revoker_last_name:string',
  REVOKER_MIDDLE_NAME = 'data_point:revoker_middle_name:string',
  SAME_HOME_MAILING_ADDRESS = 'data_point:same_home_mailing_address:boolean',
  SECTION_JUSTIFICATION = 'info:section_justification:string',
  SSN = 'data_point:ssn:ssn',
  STATE_LAW_GOVERNING_TRUST = 'data_point:state_of_trust:single_option',
  TAX_BRACKET = 'data_point:tax_bracket:single_option',
  TAX_REPORTING_NUMBER = 'data_point:tax_reporting_number:tin',
  TAX_REPORTING_TYPE = 'data_point:tax_reporting_type:single_option',
  TENANTS_IN_COMMON_PERCENTAGE = 'data_point:tenants_in_common_percentage:percentage',
  TENANTS_IN_COMMON_PERCENTAGE_CO_APPLICANT = 'data_point:tenants_in_common_percentage:percentage',
  TIN = 'data_point:tin:tin',
  TLH = 'custom:tlh:tlh',
  TLH_LABEL = 'label:tlh_label:string',
  TLH_TEXT = 'label:tlh_text:string',
  TRUSTED_CONTACT = 'custom:trusted_contact:contacts',
  TRUST_DATE = 'data_point:trust_date:date',
  TRUST_NAME = 'data_point:trust_name:string',
  TRUST_SSN = 'data_point:trust_ssn:ssn',
  TRUST_TYPE = 'data_point:type_of_trust:single_option',
  US_TAX_STATUS = 'data_point:us_tax_status:single_option',
  WEALTH_EXPENSES_TEXT = 'label:wealth_expenses_text:string',
}

export enum QuestionSetKeys {
  CITIZENSHIP_FIELDS = 'question_set:citizenship',
  COMPANY_ADDRESS_FIELDS = 'question_set:company_address_fields',
  HOME_ADDRESS_FIELDS = 'question_set:home_address_fields',
  MAILING_ADDRESS_FIELDS = 'question_set:mailing_address_fields',
}

export interface QuestionProperties {
  // NOTE: currently not handled in getPaperworkData()
  //  only used in predefined question mappers
  additionalValidations: Validation[];
  adjacent?: string;
  allowedOptions?: string[];
  defaultValue?: string;
  disabled?: boolean;
  required: boolean;
  rules: QuestionRule[];
}
