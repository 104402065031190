import { Text, View } from '@react-pdf/renderer';
import React from 'react';

import { ActivityData } from '../../../DownloadableQPR';
import { FontSizes } from '../../../styles';
import { Style } from '../../../styles';

import { OpeningClosingBalance } from '~/components/AccountActivity/utils';
import { getOpeningClosingBalanceDifference } from '~/components/AccountActivity/utils';
import { formatDisplayDate } from '~/components/AccountPerformance/utils';

export interface TableField {
  field: string;
  label: string;
}

export interface DisclosureContent {
  disclosure: string;
  title: string;
}

export interface AccountContent {
  asOf: string;
  decreasedBy: string;
  disclosureContent: DisclosureContent;
  increasedBy: string;
  info: string;
  quarter: string;
  tableFields: TableField[];
  tableHeaders: {
    [key: string]: string;
  };
  title: string;
}

interface Props {
  accountActivityFormattedData: ActivityData[];
  accountContent: AccountContent;
  displayQuarter?: boolean;
  endDate: string;
  openingClosingBalance: OpeningClosingBalance;
  pdfStyles: Style;
  selectedQuarter?: string;
  startDate?: string | null;
}

export const AccountActivityView: React.FC<Props> = ({
  accountContent,
  displayQuarter = true,
  endDate,
  pdfStyles,
  openingClosingBalance,
  accountActivityFormattedData,
  selectedQuarter,
  startDate,
}) => {
  const commonTextStyle = {
    fontSize: FontSizes.Small,
    fontFamily: pdfStyles.accountActivity.fontFamily,
    margin: '2px 0',
    width: '50%',
    fontWeight: 400,
  };

  const { title, info, tableHeaders, increasedBy, decreasedBy, quarter, asOf } = accountContent;
  const { balanceDifference, increased } = getOpeningClosingBalanceDifference(openingClosingBalance);

  const getCompleteInfo = () => {
    return (
      <Text>
        {info} {increased ? increasedBy : decreasedBy}
        <Text style={{ color: pdfStyles.primaryMainColor.color }}> {balanceDifference}</Text>{' '}
        {displayQuarter ? quarter : ''}
      </Text>
    );
  };

  const getFontWeight = (index: number, length: number) => {
    return index === 0 || index === length - 1 ? pdfStyles.accountActivityBold.fontWeight : 'normal';
  };

  return (
    <View style={{ ...pdfStyles.border, padding: '20px' }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
        }}
      >
        <Text style={{ fontSize: FontSizes.Large, marginBottom: '4px', ...pdfStyles.accountActivity }}>{title}</Text>
        {displayQuarter && (
          <Text style={{ fontSize: FontSizes.Small, marginBottom: '4px' }}>{` ${asOf} ${selectedQuarter}`}</Text>
        )}
      </View>
      <Text
        style={{
          color: pdfStyles.textSecondary.color,
          fontSize: 8,
        }}
      >
        {`${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`}
      </Text>
      <Text style={{ fontSize: FontSizes.Medium, marginBottom: '20px', marginTop: '15px' }}>{getCompleteInfo()}</Text>
      <View
        style={{
          display: 'flex',
          borderBottom: pdfStyles.border.border,
          padding: '2px 0',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <Text style={{ ...commonTextStyle, width: '55%' }}>{tableHeaders.accountActivity}</Text>
        <View style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', width: '45%' }}>
          <Text style={commonTextStyle}>{`${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`}</Text>
          {displayQuarter && <Text style={commonTextStyle}>{tableHeaders.ytd}</Text>}
        </View>
      </View>
      {accountActivityFormattedData.map(({ label, thisQuarter, ytd }, index) => (
        <View
          key={label}
          style={{
            display: 'flex',
            borderBottom: pdfStyles.border.border,
            padding: '4px 0',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Text
            style={{
              ...commonTextStyle,
              width: '55%',
              fontWeight: getFontWeight(index, accountActivityFormattedData.length),
            }}
          >
            {label}
          </Text>
          <View
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              width: '45%',
              fontWeight: getFontWeight(index, accountActivityFormattedData.length),
            }}
          >
            <Text style={{ ...commonTextStyle, fontWeight: getFontWeight(index, accountActivityFormattedData.length) }}>
              {thisQuarter}
            </Text>
            {displayQuarter && (
              <Text
                style={{ ...commonTextStyle, fontWeight: getFontWeight(index, accountActivityFormattedData.length) }}
              >
                {ytd}
              </Text>
            )}
          </View>
        </View>
      ))}
    </View>
  );
};
