import { DataPointKeys, QuestionKeys, QuestionSetKeys } from '../../questions/types';
import { PaperworkDataQuestionConfig } from '../../types';

export const aboutTheTrustSectionKey = 'about_the_trust';
export const aboutTheTrustQuestions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.TRUST_NAME },
  { questionOrSetKey: QuestionKeys.TRUST_DATE },
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.TAX_REPORTING_TYPE,
              value: 'TIN',
            },
          ],
          next: QuestionKeys.TAX_REPORTING_NUMBER,
        },
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.TAX_REPORTING_TYPE,
              value: 'SSN',
            },
          ],
          next: QuestionKeys.TRUST_SSN,
        },
        {
          next: QuestionKeys.STATE_LAW_GOVERNING_TRUST,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.TAX_REPORTING_TYPE,
  },
  { properties: { rules: [{ next: null }] }, questionOrSetKey: QuestionKeys.TAX_REPORTING_NUMBER },
  { questionOrSetKey: QuestionKeys.TRUST_SSN },
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.TAX_REPORTING_TYPE,
              value: 'SSN',
            },
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.IS_SSN_SAME_AS_TRUSTEE,
              value: 'false',
            },
          ],
          next: QuestionKeys.GRANTOR_PAGE_SOFT_WARNING,
        },
        {
          next: null,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.IS_SSN_SAME_AS_TRUSTEE,
  },
  { properties: { rules: [{ next: null }] }, questionOrSetKey: QuestionKeys.GRANTOR_PAGE_SOFT_WARNING },
  { questionOrSetKey: QuestionKeys.STATE_LAW_GOVERNING_TRUST },
  { questionOrSetKey: QuestionSetKeys.HOME_ADDRESS_FIELDS },
  {
    properties: {
      optional: true,
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.DIFFERENT_MAILING_ADDRESS,
              value: true,
            },
          ],
          next: QuestionKeys.MAILING_STREET_ADDRESS,
        },
        {
          next: QuestionKeys.NUMBER_OF_TRUSTEES,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.DIFFERENT_MAILING_ADDRESS,
  },
  { questionOrSetKey: QuestionSetKeys.MAILING_ADDRESS_FIELDS },
  { questionOrSetKey: QuestionKeys.NUMBER_OF_TRUSTEES },
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.TRUST_TYPE,
              value: 'REVOCABLE',
            },
          ],
          next: QuestionKeys.REVOCABLE_PRIMARY_TRUSTEE,
        },
        {
          next: null,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.TRUST_TYPE,
  },
  { questionOrSetKey: QuestionKeys.REVOCABLE_PRIMARY_TRUSTEE },
  { questionOrSetKey: QuestionKeys.REVOKER_FIRST_NAME },
  { properties: { optional: true }, questionOrSetKey: QuestionKeys.REVOKER_MIDDLE_NAME },
  { properties: { rules: [{ next: null }] }, questionOrSetKey: QuestionKeys.REVOKER_LAST_NAME },
];
