import { ValidateSecurityRestriction, ValidateSecurityRestrictionVariables } from './__generated__/mutation.v2';
import * as mutations from './mutation.gql';

import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyMutation } from '~/utils/symphony';

export const useValidateRestriction = (
  options?: MutationHookOptions<ValidateSecurityRestriction, ValidateSecurityRestrictionVariables>,
): MutationTuple<ValidateSecurityRestriction, ValidateSecurityRestrictionVariables> => {
  return useSymphonyMutation(mutations.ValidateSecurityRestriction, options);
};
