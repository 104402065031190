import { DeleteUserSleeveTemplate, DeleteUserSleeveTemplateVariables } from './__generated__/mutation.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import {
  GetSystemSleeveTemplates,
  GetSystemSleeveTemplates_systemSleeveTemplates,
  GetSystemSleeveTemplatesVariables,
  GetUserSleeveTemplates,
  GetUserSleeveTemplates_userSleeveTemplates,
  GetUserSleeveTemplatesVariables,
} from '~/components/SleeveTemplates/symphony/__generated__/query.v2';
import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type SleeveTemplate =
  | GetSystemSleeveTemplates_systemSleeveTemplates
  | GetUserSleeveTemplates_userSleeveTemplates;

export const useGetSystemSleeveTemplates = (
  options?: QueryHookOptions<GetSystemSleeveTemplates, GetSystemSleeveTemplatesVariables>,
): QueryResult<GetSystemSleeveTemplates, GetSystemSleeveTemplatesVariables> => {
  return useSymphonyQuery(queries.GetSystemSleeveTemplates, options);
};

export const useGetUserSleeveTemplates = (
  options?: QueryHookOptions<GetUserSleeveTemplates, GetUserSleeveTemplatesVariables>,
): QueryResult<GetUserSleeveTemplates, GetUserSleeveTemplatesVariables> => {
  return useSymphonyQuery(queries.GetUserSleeveTemplates, options);
};

export const useDeleteUserSleeveTemplate = (
  options?: MutationHookOptions<DeleteUserSleeveTemplate, DeleteUserSleeveTemplateVariables>,
): MutationTuple<DeleteUserSleeveTemplate, DeleteUserSleeveTemplateVariables> => {
  return useSymphonyMutation(mutations.DeleteUserSleeveTemplate, options);
};
