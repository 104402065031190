import { Font, StyleSheet } from '@react-pdf/renderer';

import { SimplePaletteColorOptions, TypographyOptions } from '~/components/ui/mui';
import { defaultTheme } from '~/utils/theme';

export type FontWeight =
  | number
  | 'thin'
  | 'hairline'
  | 'ultralight'
  | 'extralight'
  | 'light'
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'demibold'
  | 'bold'
  | 'ultrabold'
  | 'extrabold'
  | 'heavy'
  | 'black';

const textPrimaryColor = defaultTheme.palette?.text?.primary ?? '#434343';
const textSecondaryColor = defaultTheme.palette?.text?.secondary ?? '#8C8C8C';
const borderColor = defaultTheme.palette?.grey?.[200] ?? '#E2E8EE';
const fontWeightBold = ((defaultTheme.typography as TypographyOptions | undefined)?.body1?.fontWeightBold ??
  700) as FontWeight;
const primaryMainColor = (defaultTheme.palette?.primary as SimplePaletteColorOptions | undefined)?.main ?? '#2365F6';
const primaryDarkColor = (defaultTheme.palette?.primary as SimplePaletteColorOptions | undefined)?.dark ?? '#2365F6';

interface CommonFontStyle {
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: number;
}

export interface Style {
  accountActivity: {
    fontFamily: string;
    fontSize?: number;
    fontWeight?: number;
  };
  accountActivityBold: {
    fontWeight: FontWeight;
  };
  accountAllocation?: CommonFontStyle;
  accountBalanceChart?: {
    height?: string;
    margin?: string;
  };
  accountReport?: CommonFontStyle;
  allocationPercentageColumn: {
    width: string;
  };
  assetClassLabelColumn: {
    width: string;
  };
  background: {
    backgroundColor: string;
  };
  border: {
    border: string;
    borderColor: string;
    borderRadius: number;
  };
  disclosure: {
    fontFamily: string;
    fontFamilyBold: string;
  };
  disclosureImportantLink: {
    color: string;
  };
  dividerColor?: { color: string };
  factSheetLink?: {
    color?: string;
    fontSize?: number;
    padding?: string;
  };
  footer: {
    bottom: number;
    color?: string;
  };
  header: {
    color: string;
    fontSize: number;
    height?: number;
    logoHeight?: number;
    logoWidth?: number;
  };
  investmentRecommendationModelPortfolio: {
    color: string;
  };
  performanceSection: {
    fontFamily: string;
  };
  portfolioCompareTitles: {
    fontFamily: string;
    fontWeight: FontWeight;
  };
  primaryDarkColor: {
    color: string;
  };
  primaryMainColor: {
    color: string;
  };
  reportHeading?: CommonFontStyle;
  reportSubTitle?: CommonFontStyle & { margin: string };
  reportTitle?: CommonFontStyle;
  returns: {
    fontFamily: string;
  };
  riskPlaybackRiskBandText: {
    fontFamily: string;
    fontWeight: FontWeight;
    textTransform: 'capitalize' | 'uppercase' | 'lowercase';
  };
  riskToleranceTitle: {
    fontFamily: string;
    fontWeight: FontWeight;
  };
  riskType: {
    fontFamily: string;
    fontWeight: FontWeight;
  };
  securityNameColumn: {
    width: string;
  };
  textPrimary: {
    color: string;
  };
  textSecondary: {
    color: string;
  };
  verticalDivider: {
    borderRight: string;
  };
  whyDigitalAdviceProBenefits: {
    height: string;
  };
  whyDigitalAdviceProBold: {
    fontFamily: string;
    fontWeight: FontWeight;
  };
}

// These don't come from the theme because they are used for PDF rendering, where font sizes don't match their screen equivalents
export enum FontSizes {
  verySmall = 5,
  Small = 9,
  Medium = 10,
  Large = 14,
}
export enum FontSizesUpdated {
  VerySmall = 6,
  Small = 7,
  Normal = 8,
  Regular = 9,
  Medium = 11,
  Large = 13,
}
Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 },
  ],
});

export const defaultStyles = StyleSheet.create({
  verticalDivider: {
    borderRight: `1px solid ${borderColor}`,
  },
  allocationPercentageColumn: {
    width: '20%',
  },
  assetClassLabelColumn: {
    width: '30%',
  },
  securityNameColumn: {
    width: '15%',
  },
  border: {
    border: `1 solid ${borderColor}`,
    borderColor,
    borderRadius: 4,
  },
  background: {
    backgroundColor: '#FAFAFA',
  },
  accountActivity: {
    fontFamily: 'Open Sans',
  },
  accountActivityBold: {
    fontWeight: fontWeightBold,
  },
  disclosure: {
    fontFamily: 'Open Sans',
    fontFamilyBold: 'Open Sans',
  },
  disclosureImportantLink: {
    color: '#2365F6',
  },
  footer: {
    bottom: 10,
  },
  header: {
    fontSize: 12,
    color: textSecondaryColor,
  },
  investmentRecommendationModelPortfolio: {
    color: primaryMainColor,
  },
  performanceSection: {
    fontFamily: 'Open Sans',
  },
  primaryDarkColor: {
    color: primaryDarkColor,
  },
  primaryMainColor: {
    color: primaryMainColor,
  },
  returns: {
    fontFamily: 'Open Sans',
  },
  riskPlaybackRiskBandText: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  riskToleranceTitle: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
  },
  riskType: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
  },
  textPrimary: {
    color: textPrimaryColor,
  },
  textSecondary: {
    color: textSecondaryColor,
  },
  whyDigitalAdviceProBenefits: {
    height: '150px',
  },
  whyDigitalAdviceProBold: {
    /**
     * React Pdf does not support fontWeight directly
     * font family has to be registered only then does it pick up weight.
     * this info should be pass down via partner repos.
     */
    fontFamily: 'Open Sans',
    fontWeight: fontWeightBold,
  },
  portfolioCompareTitles: {
    fontFamily: 'Open Sans',
    fontWeight: fontWeightBold,
  },
});
