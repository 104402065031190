import {
  GetAnnualReviewClientEmailActionContent,
  GetAnnualReviewClientEmailActionContentVariables,
} from './__generated__/query.v2';
import * as query from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetAnnualReviewClientEmailActionContent = (
  options?: QueryHookOptions<GetAnnualReviewClientEmailActionContent, GetAnnualReviewClientEmailActionContentVariables>,
): QueryResult<GetAnnualReviewClientEmailActionContent, GetAnnualReviewClientEmailActionContentVariables> => {
  return useContentstackQuery(query.GetAnnualReviewClientEmailActionContent, options);
};
