import { Canvas, Image, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { Style } from '~/components/pdfs/styles';
import { RteContent } from '~/components/ui/redactor/RteContent';

interface Props {
  accountNumber?: string | null;
  logoUrl?: string;
  pageName?: string;
  pdfStyles: Style;
}

export const Header: React.FC<Props> = ({ accountNumber, logoUrl, pageName, pdfStyles }) => {
  return (
    <View
      fixed
      style={{
        width: '100%',
        height: pdfStyles.header.height ?? 30,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}
    >
      {logoUrl && (
        <Image
          src={logoUrl}
          style={{ width: pdfStyles.header.logoWidth ?? 70, height: pdfStyles.header.logoHeight ?? 15, left: 20 }}
        />
      )}
      <Canvas
        paint={(painter: any, availableWidth: number) =>
          painter
            .moveTo(0, 0)
            .lineTo(availableWidth, 0)
            .strokeColor(pdfStyles.dividerColor?.color ?? pdfStyles.primaryMainColor.color)
            .stroke()
        }
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: 1,
        }}
      />
      {pageName && (
        <Text
          style={{
            textAlign: 'center',
            color: pdfStyles.header.color,
            fontSize: pdfStyles.header.fontSize,
            position: 'absolute',
            right: 20,
          }}
        >
          <RteContent config={{ accountNumber }} data={pageName} />
        </Text>
      )}
    </View>
  );
};
