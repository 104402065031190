import { QuestionKeys } from '../../questions/types';
import { PaperworkDataQuestionConfig } from '../../types';

export const wealthAndExpensesSectionKey = 'wealth_expenses';
export const wealthAndExpensesQuestions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.TAX_BRACKET },
  { questionOrSetKey: QuestionKeys.ANNUAL_HOUSEHOLD_INCOME },
  { questionOrSetKey: QuestionKeys.ANNUAL_RECURRING_EXPENSES },
  { questionOrSetKey: QuestionKeys.LIQUID_NET_WORTH },
  { questionOrSetKey: QuestionKeys.ESTIMATED_NET_WORTH },
];

export const individualJointWealthAndExpensesQuestions: PaperworkDataQuestionConfig[] = [
  ...wealthAndExpensesQuestions,
  { questionOrSetKey: QuestionKeys.TLH_LABEL },
  { questionOrSetKey: QuestionKeys.TLH_TEXT },
  { questionOrSetKey: QuestionKeys.TLH },
];

export const trustWealthAndExpensesQuestions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.WEALTH_EXPENSES_TEXT },
  ...wealthAndExpensesQuestions,
];
