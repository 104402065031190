import { pdf } from '@react-pdf/renderer';
import { format, parseISO } from 'date-fns';
import * as htmlToImage from 'html-to-image';
import React, { useCallback, useMemo, useState } from 'react';

import { SecurityReturns } from '~/components/AccountPerformance/types';
import { Content as AssetAllocationContent } from '~/components/AssetAllocation';
import { DownloadablePerformance, DownloadablePerformanceContent } from '~/components/pdfs/DownloadablePerformance';
import { getDownloadablePerformanceContent } from '~/components/pdfs/DownloadablePerformance/utils';
import { getAccountActivityFormattedData } from '~/components/pdfs/DownloadableQPR/utils';
import { Style } from '~/components/pdfs/styles';
import { Alert } from '~/components/ui/Alert';
import { CircularProgress } from '~/components/ui/CircularProgress';
import { Button, useTheme } from '~/components/ui/mui';
import { Snackbar } from '~/components/ui/Snackbar';
import { PerformancePdfData } from '~/containers/AccountPerformance/hooks/useGetPerformancePdfData';
import { getStockBondRatio } from '~/containers/Plan/utils';
import { DetailedFinancialAccount } from '~/hooks/account-details/symphony';
import { getAllocations, getSleeves } from '~/hooks/account-details/utils';
import { QuarterlyAssets } from '~/hooks/qpr/symphony';
import {
  GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_portfolioSummary,
  GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_sleevesAllocation,
} from '~/hooks/qpr/symphony/__generated__/query.v2';
import { OpeningClosingBalance } from '~/hooks/qpr/useGetDataForQPR';
import { AssetClassContent } from '~/utils/asset-allocation';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { AllocationDetailsColumns, AssetAllocationTableColumns } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface Props {
  account?: DetailedFinancialAccount;
  accountActivityData: GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_portfolioSummary;
  accountNumberToDisplay: string;
  assetAllocationColumns?: AllocationDetailsColumns[] | AssetAllocationTableColumns[];
  assetAllocationContent?: AssetAllocationContent | null;
  assetClassContent?: AssetClassContent | null;
  assetClassTier: AssetClassTier;
  assets?: QuarterlyAssets;
  buttonText: string;
  contentOptions: ContentOptions;
  dataQa?: string;
  disclosureAtBottom: boolean;
  displayAllocationBar: boolean;
  endDate: string;
  factSheetUrl?: string;
  formatPhoneNumbers: boolean;
  fullWidth: boolean;
  inceptionDate: string;
  maskedAccountNumberDisplay: string;
  modelPortfolioName?: string;
  openingClosingBalance: OpeningClosingBalance;
  partnerStyles: Style;
  performancePdfResult: AsyncResult<PerformancePdfData>;
  returnPercentage?: string;
  securitiesReturns: SecurityReturns[];
  showActualAllocationDonut: boolean;
  showAdditionalClientsInfo?: boolean;
  showFactSheetLinkInAssetAllocation?: boolean;
  showPortfolioNameInAssetAllocation?: boolean;
  showSecurities: boolean;
  sleeves?: GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_sleevesAllocation[] | null;
  startDate: string;
}

const DownloadablePerformanceComponent: React.FC<Props> = ({
  account,
  accountActivityData,
  accountNumberToDisplay,
  assetAllocationColumns,
  assetAllocationContent,
  assetClassContent,
  assetClassTier,
  assets,
  buttonText,
  contentOptions,
  disclosureAtBottom,
  displayAllocationBar,
  endDate,
  factSheetUrl,
  formatPhoneNumbers,
  fullWidth,
  inceptionDate,
  maskedAccountNumberDisplay,
  modelPortfolioName,
  openingClosingBalance,
  partnerStyles,
  performancePdfResult,
  returnPercentage,
  securitiesReturns,
  showActualAllocationDonut,
  showAdditionalClientsInfo = false,
  showFactSheetLinkInAssetAllocation,
  showPortfolioNameInAssetAllocation,
  showSecurities,
  startDate,
  sleeves,
}) => {
  const [downloadingQPR, setDownloadingQPR] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const {
    sfReturnsChart: { styles: returnsChartStyles },
  } = useTheme();

  const {
    data: performancePdfData,
    loading: performancePdfDataLoading,
    error: performancePdfDataError,
  } = performancePdfResult;

  const qprContent: DownloadablePerformanceContent | undefined = useMemo(() => {
    if (
      performancePdfData &&
      performancePdfData.pdfContentData &&
      performancePdfData.clientInfo &&
      performancePdfData.advisorInfo &&
      account?.maskedAccountNumber
    ) {
      return getDownloadablePerformanceContent({
        advisorInfo: performancePdfData.advisorInfo,
        clientInfo: performancePdfData.clientInfo,
        startDate: format(parseISO(startDate), 'MM/dd/yyyy'),
        endDate: format(parseISO(endDate), 'MM/dd/yyyy'),
        data: performancePdfData.pdfContentData,
        additionalClientsInfo: performancePdfData.additionalClientsInfo,
      });
    }
    return;
  }, [account?.maskedAccountNumber, endDate, performancePdfData, startDate]);

  const downloadQPR = useCallback(async () => {
    setDownloadingQPR(true);

    try {
      if (
        assetAllocationContent?.donut &&
        assets &&
        assets.stockBondDiversification &&
        performancePdfData &&
        performancePdfData.modelPortfoliosContentData &&
        qprContent
      ) {
        const tableFields = qprContent.accountContent.tableFields;
        const accountActivityFormattedData = getAccountActivityFormattedData(
          {
            ...accountActivityData,
            ...openingClosingBalance,
          },
          {},
          tableFields,
        );
        const accountBalanceChart = document.getElementById('performance-chart');
        const accountBalanceChartResponse = await htmlToImage.toPng(accountBalanceChart as HTMLElement);
        const returnsChart = document.getElementById('returns-chart');
        const returnsChartResponse = returnsChart ? await htmlToImage.toPng(returnsChart as HTMLElement) : '';
        const qpr = (
          <DownloadablePerformance
            account={account}
            accountActivityFormattedData={accountActivityFormattedData}
            accountBalanceChartUrl={accountBalanceChartResponse}
            accountNumberToDisplay={accountNumberToDisplay}
            allocation={getAllocations(
              assetClassTier,
              assets,
              assetClassContent,
              assetAllocationContent.otherAssetClass,
            )}
            assetAllocationColumns={assetAllocationColumns}
            assetClassTier={assetClassTier}
            content={qprContent}
            contentOptions={contentOptions}
            disclosureAtBottom={disclosureAtBottom}
            displayAllocationBar={displayAllocationBar}
            endDate={endDate}
            factSheetLinkText={assetAllocationContent.factSheetLinkText}
            factSheetUrl={factSheetUrl}
            formatPhoneNumbers={formatPhoneNumbers}
            height={400}
            inceptionDate={inceptionDate}
            maskedAccountNumberDisplay={maskedAccountNumberDisplay}
            modelPortfolioName={modelPortfolioName}
            openingClosingBalance={openingClosingBalance}
            partnerStyles={partnerStyles}
            returnPercentage={returnPercentage}
            returnsChartUrl={returnsChartResponse}
            returnsSecurityColors={{ ...returnsChartStyles.colors }}
            securitiesReturns={securitiesReturns}
            showActualAllocationDonut={showActualAllocationDonut}
            showAdditionalClientsInfo={showAdditionalClientsInfo}
            showFactSheetLinkInAssetAllocation={showFactSheetLinkInAssetAllocation}
            showPortfolioNameInAssetAllocation={showPortfolioNameInAssetAllocation}
            showSecurities={showSecurities}
            sleeveContent={performancePdfData.sleevesContent}
            sleeves={getSleeves(
              assetClassTier,
              sleeves ?? [],
              performancePdfData.modelPortfoliosContentData,
              assetClassContent,
              assetAllocationContent.otherAssetClass,
            )}
            startDate={startDate}
            stockBondLabel={assetAllocationContent.donut.stockBondSplit.label}
            stockBondRatio={getStockBondRatio(assets.stockBondDiversification)}
            width="100%"
          />
        );
        const pdfBlob = await pdf(qpr).toBlob();
        const filename = `${qprContent.cover.primaryClientInfo.name}-${startDate}-${endDate}`;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.setAttribute('download', filename);
        link.click();
      }
    } catch (e: any) {
      console.error(e);
      setShowSnackbar(true);
    } finally {
      setDownloadingQPR(false);
    }
  }, [
    accountNumberToDisplay,
    assetAllocationColumns,
    assetAllocationContent,
    assets,
    modelPortfolioName,
    performancePdfData,
    qprContent,
    accountActivityData,
    openingClosingBalance,
    account,
    assetClassTier,
    assetClassContent,
    contentOptions,
    disclosureAtBottom,
    displayAllocationBar,
    endDate,
    factSheetUrl,
    formatPhoneNumbers,
    inceptionDate,
    maskedAccountNumberDisplay,
    partnerStyles,
    returnPercentage,
    returnsChartStyles,
    securitiesReturns,
    showActualAllocationDonut,
    showAdditionalClientsInfo,
    showFactSheetLinkInAssetAllocation,
    showPortfolioNameInAssetAllocation,
    showSecurities,
    sleeves,
    startDate,
  ]);

  const handleDownloadQPRClick = useCallback(() => {
    downloadQPR();
  }, [downloadQPR]);

  return (
    <>
      {downloadingQPR && performancePdfDataLoading && <CircularProgress />}
      {performancePdfDataError && <Alert contentOptions={contentOptions} error={performancePdfDataError} />}
      <Button
        disabled={performancePdfDataLoading || downloadingQPR || !!performancePdfDataError}
        fullWidth={fullWidth}
        onClick={handleDownloadQPRClick}
        variant="outlined"
      >
        {buttonText}
      </Button>
      {/* TODO: replace feedbackmessage with contentstack */}
      {showSnackbar && (
        <Snackbar feedbackMessage="There is some error with the pdf download. Please refresh and try again later." />
      )}
    </>
  );
};

export default DownloadablePerformanceComponent;
