import { FilterGroup, Portfolio, PortfolioSelectionFilters } from '~/containers/PortfolioSelection/types';

/**
 * Checks all relevant props of the portfolio for matching with specified filters
 * @param filters
 * @param portfolio
 * @returns true if filters is empty or if every filter passes on the portfolio
 * Only one tag and provider needs to match for the respective condition to be satisfied
 * A non-specified filter will return false and lead to no portfolios being displayed
 */
export const matchesFilters = (filters: PortfolioSelectionFilters, portfolio: Portfolio) =>
  (Object.keys(filters) as (keyof Portfolio)[])
    .map(filterKey => {
      const activeFilters = filters[filterKey];

      switch (filterKey) {
        case 'isTaxSheltered': {
          if (!activeFilters || portfolio.isTaxSheltered === undefined) {
            return true;
          }

          if (activeFilters.includes('RETIREMENT') && activeFilters.includes('NON_RETIREMENT')) {
            return true;
          }

          // Portfolio.isTaxSheltered = null is suitable for both retirement & non-retirement
          return activeFilters.includes('RETIREMENT')
            ? portfolio.isTaxSheltered !== false
            : portfolio.isTaxSheltered !== true;
        }
        case 'tags': {
          return portfolio[filterKey]?.some(
            portfolioTag => portfolioTag.value && activeFilters?.includes(portfolioTag.name),
          );
        }
        case 'provider': {
          return activeFilters?.includes(portfolio[filterKey]?.name ?? '');
        }
        default:
          return false;
      }
    })
    .every(condition => condition);

/**
 * Bulk filtering of a list of portfolios with prescribed list of portfolio property filters
 * @param { activeFilters, portfolios}
 * @returns An array of portfolios that satisfy the filters, will return identity if filters are empty
 */
export const getFilteredPortfolios = ({
  activeFilters,
  portfolios,
}: {
  activeFilters: PortfolioSelectionFilters;
  portfolios: Portfolio[];
}): Portfolio[] =>
  Object.keys(activeFilters).length === 0
    ? portfolios
    : portfolios.filter(portfolio => matchesFilters(activeFilters, portfolio));

/**
 * Helper function for a map groupedPortfolios that either creates a new [currPortfolio] for key name or extends the array at key name with currPortfolio
 * @param groupedPortfolios - existing map of portfolios grouped by seriesBaseName or Provider
 * @param name - a SeriesBaseName or Provider to serve as a key
 * @param currPortfolio - Portfolio to add to the grouped map
 */
const updateGroup = (groupedPortfolios: Map<string, Portfolio[]>, name: string, currPortfolio: Portfolio) => {
  const items = groupedPortfolios.get(name);
  groupedPortfolios.set(name, items ? [...items, currPortfolio] : [currPortfolio]);
};

/**
 * Converts an of portfolios to a Map of portfolio arrays
 * @param portfolios
 * @returns Map of portfolios grouped by seriesBaseName or provider (as fallback)
 */
export const getGroupedPortfolios = (portfolios: Portfolio[]): Map<string, Portfolio[]> => {
  const allGroupedPortfolios = portfolios.reduce((groupedPortfolios, currPortfolio) => {
    if (currPortfolio.seriesBaseName) {
      updateGroup(groupedPortfolios, currPortfolio.seriesBaseName, currPortfolio);
      return groupedPortfolios;
    }

    if (currPortfolio.provider) {
      updateGroup(groupedPortfolios, currPortfolio.provider.name, currPortfolio);
      return groupedPortfolios;
    }

    return groupedPortfolios;
  }, new Map<string, Portfolio[]>());

  // sorting each group's portfolios by name
  allGroupedPortfolios.forEach(group => {
    group.sort((a, b) => a.productName.localeCompare(b.productName, undefined, { numeric: true, sensitivity: 'base' }));
  });
  // return portfolio groups sorted by seriesBaseName or provider
  return new Map(Array.from(allGroupedPortfolios.entries()).sort((a, b) => a[0].localeCompare(b[0])));
};

/**
 * Updates a map at an attribute with a value. The value is added/instantiated as a list if addToFilters is true, removed from list if addToFilters is false
 * Using Copy-on-write paradigm for avoiding pass by reference
 * @param selectedFilters {[keyof Portfolio]: string[]}, main selected filters object, indexable by attribute
 * @param attribute
 * @param value
 * @param addToFilters
 * @returns a copy of the updated selectedFilters object, with value either added or removed from the list indexed by attribute
 */
export const updateSelectedFilters = (
  selectedFilters: PortfolioSelectionFilters,
  attribute: keyof Portfolio,
  value: string,
  addToFilters: boolean,
): PortfolioSelectionFilters => {
  const newSelectedFilters = { ...selectedFilters };

  if (addToFilters) {
    if (attribute in newSelectedFilters) {
      newSelectedFilters[attribute]?.push(value);
    } else {
      newSelectedFilters[attribute] = [value];
    }
  } else {
    newSelectedFilters[attribute] = newSelectedFilters[attribute]?.filter(f => f !== value);
    if (!newSelectedFilters[attribute]?.length) {
      delete newSelectedFilters[attribute];
    }
  }
  return newSelectedFilters;
};

/**
 * Initializes the filters based on the account type from the questionnaire.
 *
 * @param portfolioFilters - An array of filter groups.
 * @param isTaxShelteredAccountFromQuestionnaire - Indicates if the account is tax-sheltered based on the questionnaire.
 * @returns {PortfolioSelectionFilters} The initialized filter state.
 */
export const initializeSelectedFilters = (
  portfolioFilters: FilterGroup[],
  isTaxShelteredAccountFromQuestionnaire: boolean | undefined,
): PortfolioSelectionFilters => {
  const taxShelteredAttribute = portfolioFilters.find(attr => attr.attribute === 'isTaxSheltered');

  if (taxShelteredAttribute && isTaxShelteredAccountFromQuestionnaire !== undefined) {
    const accountType = isTaxShelteredAccountFromQuestionnaire ? 'RETIREMENT' : 'NON_RETIREMENT';
    return updateSelectedFilters({}, taxShelteredAttribute.attribute, accountType, true);
  }
  return {};
};
