import React, { FC } from 'react';

import { useRelevantDocuments } from './hooks/useRelevantDocuments';

import { RelevantDocuments as RelevantDocumentsModal } from '~/components/modals/RelevantDocuments';
import { Link } from '~/components/ui/Link';
import { useModalState } from '~/components/ui/Modal/hooks';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  contentOptions: ContentOptions;
  data?: string;
  dataQa?: string;
  linkText: string;
}

export const RelevantDocuments: FC<Props> = ({ contentOptions, data, dataQa, linkText }) => {
  const { open, openModal, onClose } = useModalState();

  const { data: relevantDocumentData } = useRelevantDocuments({
    contentOptions,
  });

  const getLinkText = (text: string) => (
    <Link onClick={openModal} variant="body1">
      {text}
    </Link>
  );

  return (
    <>
      <RteContent
        config={{ requiredDocumentsCTA: getLinkText(linkText) }}
        data={data || ''}
        data-qa={`${dataQa}-link`}
      />
      {relevantDocumentData && <RelevantDocumentsModal content={relevantDocumentData} onClose={onClose} open={open} />}
    </>
  );
};
