import React, { createRef, useMemo } from 'react';

import { ArrowDropUpIcon, Box, Grid, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { useRefDimensions } from '~/hooks/layout';
import { RiskPreferenceVolatilityMappings } from '~/hooks/volatility/symphony';

enum VolatilityBarConfigurations {
  MIN_VALUE = 0.02,
  MAX_VALUE = 0.16,
  VOLATILITY_DIVIDEND = 0.0001,
}

export interface VolatilityBarContent {
  currentLabel: string;
  targetLabel: string;
}

export interface VolatilityBarProps {
  calculatedVolatility: number;
  content: VolatilityBarContent;
  dataQa?: string;
  defaultWidth?: number;
  riskPreferenceVolatilityList: RiskPreferenceVolatilityMappings[];
  riskScore?: number;
}

export const VolatilityBar: React.FC<VolatilityBarProps> = ({
  dataQa = 'volatility-bar',
  calculatedVolatility,
  content,
  riskPreferenceVolatilityList,
  defaultWidth = 0,
  riskScore,
}) => {
  const {
    sfVolatilityBar: { styles: sfVolatilityBarStyles },
  } = useTheme();

  const ref = createRef<HTMLDivElement>();
  const { width } = useRefDimensions(ref);

  const getVolatilityBandWidth = () => {
    const viewBoxWidth = width && width > 0 ? width : defaultWidth;
    return (
      viewBoxWidth /
      ((VolatilityBarConfigurations.MAX_VALUE - VolatilityBarConfigurations.MIN_VALUE) /
        VolatilityBarConfigurations.VOLATILITY_DIVIDEND)
    );
  };

  const getTargetRiskPreferenceVolatility = () => {
    return riskPreferenceVolatilityList?.find(
      riskPreferenceVolatility => riskPreferenceVolatility.riskPreference === riskScore,
    );
  };

  const getTargetVolatiltyPercentageWidth = useMemo(() => {
    const volatilityBandWidth = getVolatilityBandWidth();
    const targetRiskPreferenceVolatility = getTargetRiskPreferenceVolatility();
    if (targetRiskPreferenceVolatility) {
      const upperBoundary =
        parseFloat(targetRiskPreferenceVolatility.upperBoundary) <= VolatilityBarConfigurations.MAX_VALUE
          ? parseFloat(targetRiskPreferenceVolatility.upperBoundary)
          : VolatilityBarConfigurations.MAX_VALUE;
      const lowerBoundary =
        parseFloat(targetRiskPreferenceVolatility.lowerBoundary) <= VolatilityBarConfigurations.MIN_VALUE
          ? VolatilityBarConfigurations.MIN_VALUE
          : parseFloat(targetRiskPreferenceVolatility.lowerBoundary);
      if (upperBoundary < lowerBoundary) {
        return 0;
      } else {
        const boundariesDifference = upperBoundary - lowerBoundary;
        return volatilityBandWidth * (boundariesDifference / VolatilityBarConfigurations.VOLATILITY_DIVIDEND);
      }
    }
    return 0;
  }, [getVolatilityBandWidth, getTargetRiskPreferenceVolatility]);

  const getTargetPosition = useMemo(() => {
    const volatilityBandWidth = getVolatilityBandWidth();
    const targetRiskPreferenceVolatility = getTargetRiskPreferenceVolatility();
    if (targetRiskPreferenceVolatility) {
      if (parseFloat(targetRiskPreferenceVolatility.lowerBoundary) < VolatilityBarConfigurations.MIN_VALUE) {
        return 0;
      } else {
        return (
          volatilityBandWidth *
          ((parseFloat(targetRiskPreferenceVolatility.lowerBoundary) - VolatilityBarConfigurations.MIN_VALUE) /
            VolatilityBarConfigurations.VOLATILITY_DIVIDEND)
        );
      }
    }
    return 0;
  }, [getTargetRiskPreferenceVolatility, getVolatilityBandWidth]);

  const getCurrentPosition = useMemo(() => {
    const volatilityBandWidth = getVolatilityBandWidth();
    if (!calculatedVolatility) {
      return 0;
    } else if (calculatedVolatility >= VolatilityBarConfigurations.MAX_VALUE) {
      return (
        volatilityBandWidth *
        ((VolatilityBarConfigurations.MAX_VALUE - VolatilityBarConfigurations.MIN_VALUE) /
          VolatilityBarConfigurations.VOLATILITY_DIVIDEND)
      );
    } else if (calculatedVolatility <= VolatilityBarConfigurations.MIN_VALUE) {
      return 0;
    } else {
      return (
        volatilityBandWidth *
        ((calculatedVolatility - VolatilityBarConfigurations.MIN_VALUE) /
          VolatilityBarConfigurations.VOLATILITY_DIVIDEND)
      );
    }
  }, [calculatedVolatility, getVolatilityBandWidth]);

  return (
    <Grid data-qa={dataQa} sx={{ ...sfVolatilityBarStyles.volatilityBarContainer }}>
      <Grid ref={ref} sx={{ mt: 2, ...sfVolatilityBarStyles.volatilityBar }}>
        <Box
          sx={{
            width: `${getTargetVolatiltyPercentageWidth}px`,
            left: `${getTargetPosition}px`,
            ...sfVolatilityBarStyles.volatilityBand,
          }}
        >
          <Box sx={{ mt: 1, ...sfVolatilityBarStyles.guideLabel }}>
            <Box sx={{ ...sfVolatilityBarStyles.imageContainer }}>
              <ArrowDropUpIcon style={sfVolatilityBarStyles.upArrow} />
            </Box>
            <Typography variant="subtitle2">{content.targetLabel}</Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 1, ...sfVolatilityBarStyles.currentGuideLabel, left: `${getCurrentPosition - 20}px` }}>
          <Box sx={{ ...sfVolatilityBarStyles.imageContainer }}>
            <ArrowDropUpIcon style={sfVolatilityBarStyles.upArrow} />
          </Box>
          <Typography variant="subtitle2">{content.currentLabel}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
