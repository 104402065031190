import React, { useState } from 'react';

import { Alert } from '~/components/ui/Alert';
import { Box, Button, CloseIcon, CloudUploadIcon, InsertDriveFileIcon, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

export interface Props {
  ctaText: string;
  dataQa?: string;
  existingFiles?: FileDetails[];
  fileType?: string;
  maxFileSizeInMB?: number;
  maxFileSizeMessage?: string;
  maxNumberOfFiles?: number;
  maxNumberOfFilesMessage?: string;
  onFileSelect?: (file: string | ArrayBuffer) => void;
  supportMultipleFileSelection?: boolean;
}
interface FileDetails {
  file?: string | ArrayBuffer | null;
  fileName: string;
}

export const FileUploader: React.FC<Props> = ({
  ctaText,
  dataQa = 'file-uploader',
  fileType,
  existingFiles,
  onFileSelect,
  maxNumberOfFiles,
  maxFileSizeInMB,
  maxNumberOfFilesMessage,
  maxFileSizeMessage,
  supportMultipleFileSelection,
}) => {
  const [fileDetailsArray, setFileDetailsArray] = useState<FileDetails[]>(existingFiles ?? []);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    sfFileUploader: { styles: sfFileUploaderStyles },
  } = useTheme();

  const fileToDataURL = (file: File) => {
    const reader = new FileReader();
    const newFile: FileDetails = { fileName: file.name };
    if (!maxFileSizeInMB || file.size < maxFileSizeInMB * 1048576) {
      return new Promise<FileDetails>(resolve => {
        reader.onload = function (event) {
          newFile.file = event.target?.result;
          resolve(newFile);
        };
        reader.readAsDataURL(file);
      });
    }
    setErrorMessage(maxFileSizeMessage ?? '');
    return null;
  };

  const handleCapture = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    if (target.files && target.files.length) {
      Promise.all(Array.from(target.files).map(file => fileToDataURL(file))).then(files => {
        if (!files.includes(null)) {
          if (maxNumberOfFiles && fileDetailsArray.length + files.length > maxNumberOfFiles) {
            setErrorMessage(maxNumberOfFilesMessage ?? '');
            files.splice(maxNumberOfFiles - fileDetailsArray.length, files.length);
          }
          files.forEach(file => onFileSelect?.(file?.file ?? ''));
          setFileDetailsArray([...fileDetailsArray, ...(files as FileDetails[])]);
        }
      });
    }
  };

  const deleteSelectedFile = (index: number) => {
    setErrorMessage('');
    fileDetailsArray.splice(index, 1);
    setFileDetailsArray([...fileDetailsArray]);
  };

  return (
    <>
      <Button
        color="primary"
        component="label"
        disabled={errorMessage === maxNumberOfFilesMessage}
        startIcon={<CloudUploadIcon />}
        sx={{ mb: 2 }}
        variant="outlined"
      >
        {ctaText}
        <input
          accept={fileType}
          data-qa={`${dataQa}-input`}
          hidden
          multiple={supportMultipleFileSelection}
          onChange={handleCapture}
          type="file"
        />
      </Button>
      <Box sx={{ width: 1 }}>
        {fileDetailsArray.map((file, index) => (
          <Box key={index} sx={sfFileUploaderStyles.card}>
            <InsertDriveFileIcon sx={{ mr: 1 }} />
            <Typography data-qa={`${dataQa}-file-name-${index}`} sx={sfFileUploaderStyles.fileName} variant="subtitle2">
              {file.fileName}
            </Typography>
            <CloseIcon onClick={() => deleteSelectedFile(index)} sx={{ marginLeft: 'auto' }} />
          </Box>
        ))}
      </Box>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    </>
  );
};
