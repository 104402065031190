import { DataPointKeys, QuestionKeys } from '../../questions/types';
import { designatePrimaryBeneficiaryYesValue, designateTrustedContactYesValue } from '../../questions/utils';
import { PaperworkDataQuestionConfig } from '../../types';

export const sectionKey = 'beneficiaries_contacts';
export const trustedContactsOnlyHeading = 'trusted_contacts';

export const iraQuestions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.PRIMARY_BENEFICIARY },
  { questionOrSetKey: QuestionKeys.CONTINGENT_BENEFICIARY },
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.DESIGNATE_TRUSTED_CONTACT,
              value: designateTrustedContactYesValue,
            },
          ],
          next: QuestionKeys.TRUSTED_CONTACT,
        },
        {
          next: null,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.DESIGNATE_TRUSTED_CONTACT,
  },
  { questionOrSetKey: QuestionKeys.TRUSTED_CONTACT },
];

export const taxableQuestions: PaperworkDataQuestionConfig[] = [
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.DESIGNATE_PRIMARY_BENEFICIARY,
              value: designatePrimaryBeneficiaryYesValue,
            },
          ],
          next: QuestionKeys.PRIMARY_BENEFICIARY,
        },
        {
          next: QuestionKeys.DESIGNATE_TRUSTED_CONTACT,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.DESIGNATE_PRIMARY_BENEFICIARY,
  },
  { questionOrSetKey: QuestionKeys.PRIMARY_BENEFICIARY },
  { properties: { rules: [{ next: null }] }, questionOrSetKey: QuestionKeys.CONTINGENT_BENEFICIARY },
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.DESIGNATE_TRUSTED_CONTACT,
              value: designateTrustedContactYesValue,
            },
          ],
          next: QuestionKeys.TRUSTED_CONTACT,
        },
        {
          next: null,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.DESIGNATE_TRUSTED_CONTACT,
  },
  { questionOrSetKey: QuestionKeys.TRUSTED_CONTACT },
];

export const trustedContacts: PaperworkDataQuestionConfig[] = [
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.DESIGNATE_TRUSTED_CONTACT,
              value: designateTrustedContactYesValue,
            },
          ],
          next: QuestionKeys.TRUSTED_CONTACT,
        },
        {
          next: null,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.DESIGNATE_TRUSTED_CONTACT,
  },
  { questionOrSetKey: QuestionKeys.TRUSTED_CONTACT },
];
