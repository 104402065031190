import { Canvas, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { Style } from '../styles';

import { RiskBand } from '~/components/RiskSpectrum';

export interface Props {
  pdfStyles: Style;
  riskBandColors: string[];
  riskBandNew: number;
  riskBands: RiskBand[];
}

const TOTAL_SPECTRUM_WIDTH = 427;
const LABEL_WIDTH = 57;

export const RiskIndicator: React.FC<Props> = ({ pdfStyles, riskBands, riskBandNew, riskBandColors }) => {
  const riskScore = riskBands.length > riskBandNew ? riskBands[riskBandNew].riskScoreFloor : 0;
  const riskType = riskBands.length > riskBandNew ? riskBands[riskBandNew].label : '';
  const sortedRiskBand = riskBands.slice().sort((a, b) => a.riskScoreFloor - b.riskScoreFloor);

  const getHeight = (score: number) => (score === riskScore ? 10 : 4);
  const offset = TOTAL_SPECTRUM_WIDTH / riskBands.length;
  const sectionWidth = (TOTAL_SPECTRUM_WIDTH - riskBands.length * 2) / riskBands.length;

  return (
    <View>
      <Text
        style={{
          width: LABEL_WIDTH,
          fontSize: 9,
          textAlign: 'center',
          marginLeft: riskBandNew * offset - (LABEL_WIDTH / 2 - sectionWidth / 2),
          ...pdfStyles.riskType,
        }}
      >
        {riskType}
      </Text>
      <Canvas
        paint={(painter: any) => {
          sortedRiskBand.forEach(({ riskScoreFloor }, index) =>
            painter
              .rect(offset * index, 0, sectionWidth, getHeight(riskScoreFloor))
              .fillColor(riskBandColors[index])
              .fill(),
          );

          return painter;
        }}
        style={{
          marginTop: 8,
          height: 8,
        }}
      />
      <Canvas
        paint={(painter: any) => painter.polygon([4, 0], [0, 4], [8, 4]).fillColor('dimgray').fill()}
        style={{
          height: 8,
          marginTop: 10,
          marginLeft: riskBandNew * offset + sectionWidth / 2 - 4,
        }}
      />
    </View>
  );
};
