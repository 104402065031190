/**
 * Helper functions to generate Redactor output in tests and storybook
 */

const makeBodyString = (variant: 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'overline') => (
  body: string,
) => `<p class="${variant}">${body}</p>`;
export const subtitle1String = makeBodyString('subtitle1');
export const subtitle2String = makeBodyString('subtitle2');
export const body1String = makeBodyString('body1');
export const body2String = makeBodyString('body2');
export const captionString = makeBodyString('caption');
export const overlineString = makeBodyString('overline');

const makeHeadingString = (level: 1 | 2 | 3 | 4 | 5 | 6) => (body: string) => `<h${level}>${body}</h${level}>`;
export const h1String = makeHeadingString(1);
export const h2String = makeHeadingString(2);
export const h3String = makeHeadingString(3);
export const h4String = makeHeadingString(4);
export const h5String = makeHeadingString(5);
export const h6String = makeHeadingString(6);

export const variableString = (key: string): string => `<span data-redactor-type="variable">${key}</span>`;

export const tooltipString = (tooltipContent: string, useInfoIcon: string, anchorElement: string): string =>
  `<span data-redactor-type="tooltip" data-tooltip-content="${tooltipContent}" data-use-info-icon="${useInfoIcon}">${anchorElement}</span>`;

export const strongString = (body: string): string => `<strong>${body}</strong>`;
