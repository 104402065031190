import { PaperworkDataPageConfig } from '../types';

import { questions as aboutYouQuestions, sectionKey as aboutYouSectionKey } from './sections/aboutYou';
import {
  iraQuestions as beneficiaryIRAQuestions,
  sectionKey as beneficiarySectionKey,
  taxableQuestions as beneficiaryTaxableQuestions,
} from './sections/beneficiariesAndTrustedContacts';
import {
  questions as identificationAndEmploymentQuestions,
  sectionKey as identificationAndEmploymentSectionKey,
} from './sections/identificationAndEmployment';
import { questions as regulatoryQuestions, sectionKey as regulatorySectionKey } from './sections/regulatory';
import {
  questions as wealthExpenseQuestions,
  sectionKey as wealthExpenseSectionKey,
} from './sections/wealthAndExpenses';

import { FinancialAccountType, PaperworkType } from '~/__generated__';
import { isIRAAccountType } from '~/utils/account';

export const individualOwnerPaperworkPagesForOnboarding = (
  accountType: FinancialAccountType,
): PaperworkDataPageConfig[] => {
  return [
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: aboutYouSectionKey,
          questions: aboutYouQuestions,
        },
        {
          sectionKey: identificationAndEmploymentSectionKey,
          questions: identificationAndEmploymentQuestions,
        },
        {
          sectionKey: beneficiarySectionKey,
          questions: isIRAAccountType(accountType) ? beneficiaryIRAQuestions : beneficiaryTaxableQuestions,
        },
      ],
    },
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: wealthExpenseSectionKey,
          questions: wealthExpenseQuestions,
        },
        {
          sectionKey: regulatorySectionKey,
          questions: regulatoryQuestions,
        },
      ],
    },
  ];
};

export const individualPaperworkFreeFormIdToProfileType: Record<string, PaperworkType> = {
  individual: PaperworkType.PRIMARY,
};
