import { Scalars as SymphonyScalars } from '~/__generated__';
import { RiskPreferenceVolatilityMappings } from '~/hooks/volatility/symphony';

export enum VolatilityMatchStatus {
  MATCHED = 'MATCHED',
  TOO_AGGRESSIVE = 'TOO_AGGRESSIVE',
  TOO_CONSERVATIVE = 'TOO_CONSERVATIVE',
}

export const checkVolatilityMatch = (
  riskPreferenceVolatilityList: RiskPreferenceVolatilityMappings[],
  volatility: SymphonyScalars['Decimal'],
  riskScore: number,
): VolatilityMatchStatus => {
  let compositeModelPortfolioVolatility = VolatilityMatchStatus.MATCHED;
  if (
    !riskPreferenceVolatilityList.find(
      riskPreferenceVolatility =>
        riskPreferenceVolatility.lowerBoundary <= volatility &&
        riskPreferenceVolatility.upperBoundary >= volatility &&
        riskPreferenceVolatility.riskPreference === riskScore,
    )
  ) {
    compositeModelPortfolioVolatility = VolatilityMatchStatus.TOO_CONSERVATIVE;
    const compositeModelPortfolioRiskMapping = riskPreferenceVolatilityList.find(
      riskPreferenceVolatility =>
        riskPreferenceVolatility.lowerBoundary <= volatility && riskPreferenceVolatility.upperBoundary >= volatility,
    );
    if (compositeModelPortfolioRiskMapping && compositeModelPortfolioRiskMapping.riskPreference > riskScore) {
      compositeModelPortfolioVolatility = VolatilityMatchStatus.TOO_AGGRESSIVE;
    }
  }
  return compositeModelPortfolioVolatility;
};
