import { ApolloCache } from '@apollo/client';

import { GetGoals, GetGoalsVariables } from './__generated__/getGoals.v2';
import { UpdateCliffGoal, UpdateCliffGoalVariables } from './__generated__/updateCliffGoal.v2';
import { UpdateRetirementGoal, UpdateRetirementGoalVariables } from './__generated__/updateRetirementGoal.v2';
import * as getGoalsQueries from './getGoals.gql';

import { AccountType, AmplifyScalars, GoalStatus } from '~/__generated__';
import { isGoalCashFlowSourceItem } from '~/containers/Goals/amplify';
import { GetGoal, GetGoalVariables } from '~/containers/Goals/amplify/__generated__/getGoal.v2';
import { GetGoal as GetGoalQuery } from '~/containers/Goals/amplify/getGoal.gql';
import produce from '~/utils/immer';

/**
 * Update the GetGoals query cache for the Goals Summary
 */
export const updateGetGoalsCache = (
  cache: Pick<ApolloCache<UpdateRetirementGoal | UpdateCliffGoal>, 'readQuery' | 'writeQuery'>,
  goalId: string,
  goalStatus: GoalStatus,
  partyId: string,
  targetEndDate?: string | null,
): void => {
  const getGoalsQueryOptions = { query: getGoalsQueries.GetGoals, variables: { partyId } };
  const goalsCache = cache.readQuery<GetGoals, GetGoalsVariables>(getGoalsQueryOptions);
  if (goalsCache) {
    cache.writeQuery<GetGoals>({
      ...getGoalsQueryOptions,
      data: produce(goalsCache, draft => {
        const targetGoal = draft.getUserByPartyId?.goals?.items.find(g => g?.id === goalId);
        if (!targetGoal) {
          return;
        }
        targetGoal.goalStatus = goalStatus;
        targetGoal.targetEndDate = targetEndDate ?? null;
        targetGoal.goalProjection = null;
      }),
    });
  }
};

type CliffData = Pick<UpdateCliffGoalVariables, 'targetBalance'> & { targetEndDate: AmplifyScalars['AWSDate'] | null };
type RetirementData = Pick<UpdateRetirementGoalVariables, 'cashFlowSources' | 'targetSpending' | 'user'>;
type FormData = { cliff: CliffData; retirement?: never } | { cliff?: never; retirement: RetirementData };
type Data = FormData & {
  goalId: string;
  goalStatus: GoalStatus;
  partyId: string;
  title?: string;
};

/**
 * Update GetGoal query cache.
 */
export const updateGoalsCache = (
  cache: Pick<ApolloCache<UpdateRetirementGoal | UpdateCliffGoal>, 'readQuery' | 'writeQuery'>,
  { cliff, goalId, goalStatus, partyId, retirement, title }: Data,
) => {
  updateGetGoalsCache(cache, goalId, goalStatus, partyId, cliff?.targetEndDate);

  const queryOptions = { query: GetGoalQuery, variables: { goalId } };
  const goalCache = cache.readQuery<GetGoal, GetGoalVariables>(queryOptions);
  if (!goalCache) {
    return;
  }
  cache.writeQuery<GetGoal>({
    ...queryOptions,
    data: produce(goalCache, draft => {
      if (!draft.getGoal) {
        return;
      }
      draft.getGoal.goalStatus = goalStatus;
      draft.getGoal.goalProjection = null;

      if (title) {
        draft.getGoal.title = title;
      }

      if (cliff) {
        draft.getGoal.targetBalance = cliff.targetBalance;
        draft.getGoal.targetEndDate = cliff.targetEndDate;
      }

      if (retirement) {
        const { cashFlowSources, targetSpending, user } = retirement;
        if (user) {
          draft.getGoal.user.annualIncome = user.annualIncome;
          draft.getGoal.user.retirementAge = user.retirementAge;
        }
        if (typeof targetSpending === 'number') {
          draft.getGoal.targetSpending = targetSpending;
        }
        if (cashFlowSources) {
          const houseAccount = draft.getGoal.goalAccounts?.items.find(
            a => a?.account.accountType === AccountType.HOUSE,
          );
          if (houseAccount?.cashFlowSources) {
            houseAccount.cashFlowSources.items.filter(isGoalCashFlowSourceItem).forEach(source => {
              const targetSource = cashFlowSources.find(cfs => cfs.cashFlowType === source.cashFlowType);
              if (targetSource) {
                source.amount = targetSource.amount;
              }
            });
          }
        }
      }
    }),
  });
};
