import React from 'react';

import { Modal } from '~/components/ui/Modal';
import { Box, Button, Grid, WarningIcon } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

export interface Content {
  cancelButtonText: string;
  infoText: string;
  submitButtonText: string;
  title: string;
}

export interface Props {
  content?: Content;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}

export const InvestmentAmountWarningModal: React.FC<Props> = ({ content, onClose, onConfirm, open }) => {
  return (
    <Modal
      actions={
        <Grid container>
          <Grid item md={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button onClick={onClose} sx={{ mr: 2 }} variant="outlined">
              {content?.cancelButtonText}
            </Button>
            <Button onClick={onConfirm} variant="contained">
              {content?.submitButtonText}
            </Button>
          </Grid>
        </Grid>
      }
      content={
        <Box sx={{ my: 2 }}>
          <WarningIcon sx={{ color: 'warning.main' }} />
          <Typography sx={{ mt: 3.5 }} variant="body2">
            {content?.infoText}
          </Typography>
        </Box>
      }
      dataQa="investment-amount-warning-modal"
      onClose={onClose}
      open={open}
      title={content?.title}
    />
  );
};
