import {
  GetGoal,
  GetGoal_getGoal,
  GetGoal_getGoal_goalAccounts_items,
  GetGoal_getGoal_goalAccounts_items_cashFlowSources_items,
  GetGoalVariables,
} from './__generated__/getGoal.v2';
import * as getGoalQueries from './getGoal.gql';

import { useGoalsQuery } from '~/utils/amplify';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';

export type Goal = GetGoal_getGoal;
export type GoalAccountItem = GetGoal_getGoal_goalAccounts_items;
export type GoalCashFlowSourceItem = GetGoal_getGoal_goalAccounts_items_cashFlowSources_items;

export const isGoalCashFlowSourceItem = (item: GoalCashFlowSourceItem | null): item is GoalCashFlowSourceItem => !!item;
export const isGoalAccountItem = (item: GoalAccountItem | null): item is GoalAccountItem => !!item;

export const useGetGoal = (
  options: QueryHookOptions<GetGoal, GetGoalVariables>,
): QueryResult<GetGoal, GetGoalVariables> => useGoalsQuery(getGoalQueries.GetGoal, options);
