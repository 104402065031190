import { PaperworkDataPageConfig } from '../types';

import {
  aboutCoApplicantQuestions,
  aboutCoApplicantSectionKey,
  aboutJointTenantsInCommonCoApplicantQuestions,
  aboutJointTenantsInCommonQuestions,
  aboutYouQuestions,
  aboutYouSectionKey,
} from './sections/aboutYou';
import { assetsHeldAwayQuestions, assetsHeldAwaySectionKey } from './sections/assetsHeldAway';
import {
  beneficiariesAndContactsSectionKey,
  beneficiariesAndContactsTaxableQuestions,
  trustedContactsQuestions,
  trustedContactsSectionKey,
} from './sections/beneficiariesAndTrustedContacts';
import { eDeliveryQuestions, eDeliverySectionKey } from './sections/eDelivery';
import { idAndEmploymentQuestions, idAndEmploymentSectionKey } from './sections/identificationAndEmployment';
import { regulatoryQuestions, regulatorySectionKey } from './sections/regulatory';
import { individualJointWealthAndExpensesQuestions, wealthAndExpensesSectionKey } from './sections/wealthAndExpenses';

import { FinancialAccountType, PaperworkType } from '~/__generated__';

export const jointOwnerPaperworkPagesForClientLedOnboarding = (
  _accountType: FinancialAccountType,
): PaperworkDataPageConfig[] => {
  return [
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: aboutYouSectionKey,
          questions: aboutYouQuestions,
        },
        {
          sectionKey: idAndEmploymentSectionKey,
          questions: idAndEmploymentQuestions,
        },
        {
          sectionKey: beneficiariesAndContactsSectionKey,
          questions: beneficiariesAndContactsTaxableQuestions,
        },
      ],
    },
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: wealthAndExpensesSectionKey,
          questions: individualJointWealthAndExpensesQuestions,
        },
        {
          sectionKey: regulatorySectionKey,
          questions: regulatoryQuestions,
        },
      ],
    },
    {
      heading: 'heading_co_applicant',
      paperworkFreeFormIdForPage: 'joint',
      consent: {
        consentRequired: true,
        defaultChecked: false,
      },
      sections: [
        {
          sectionKey: aboutCoApplicantSectionKey,
          questions: aboutCoApplicantQuestions,
        },
        {
          adjacent: trustedContactsSectionKey,
          sectionKey: idAndEmploymentSectionKey,
          questions: idAndEmploymentQuestions,
        },
        {
          sectionKey: trustedContactsSectionKey,
          questions: trustedContactsQuestions,
        },
        {
          sectionKey: regulatorySectionKey,
          questions: regulatoryQuestions,
        },
      ],
    },
  ];
};

export const jointOwnerPaperworkPagesForAdvisorLedOnboarding = (
  accountType: FinancialAccountType,
): PaperworkDataPageConfig[] => {
  const isJointTenantsInCommon = accountType === FinancialAccountType.JOINT_TENANTS_IN_COMMON;

  return [
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: aboutYouSectionKey,
          questions: isJointTenantsInCommon ? aboutJointTenantsInCommonQuestions : aboutYouQuestions,
        },
        {
          sectionKey: idAndEmploymentSectionKey,
          questions: idAndEmploymentQuestions,
        },
        {
          sectionKey: beneficiariesAndContactsSectionKey,
          questions: beneficiariesAndContactsTaxableQuestions,
        },
      ],
    },
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: wealthAndExpensesSectionKey,
          questions: individualJointWealthAndExpensesQuestions,
        },
        {
          sectionKey: regulatorySectionKey,
          questions: regulatoryQuestions,
        },
        {
          sectionKey: assetsHeldAwaySectionKey,
          questions: assetsHeldAwayQuestions,
        },
      ],
    },
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: eDeliverySectionKey,
          questions: eDeliveryQuestions,
        },
      ],
    },
    {
      heading: 'heading_co_applicant',
      paperworkFreeFormIdForPage: 'joint',
      sections: [
        {
          sectionKey: aboutCoApplicantSectionKey,
          questions: isJointTenantsInCommon ? aboutJointTenantsInCommonCoApplicantQuestions : aboutCoApplicantQuestions,
        },
        {
          adjacent: trustedContactsSectionKey,
          sectionKey: idAndEmploymentSectionKey,
          questions: idAndEmploymentQuestions,
        },
        {
          sectionKey: trustedContactsSectionKey,
          questions: trustedContactsQuestions,
        },
        {
          sectionKey: regulatorySectionKey,
          questions: regulatoryQuestions,
        },
      ],
    },
  ];
};

export const jointPaperworkFreeFormIdToProfileType: Record<string, PaperworkType> = {
  individual: PaperworkType.PRIMARY,
  joint: PaperworkType.SECONDARY,
};
