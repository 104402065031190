import { parseISO } from 'date-fns';
import React from 'react';
import { FieldError } from 'react-hook-form';

import { ValidationMessage } from '../../../types';
import { getErrorMessage } from '../../utils';

import { RelationshipSubtype } from '~/__generated__';
import { Box } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { PaperworkRelationships } from '~/containers/Paperwork/symphony';

const getISODate = (date?: string | null) => (date ? parseISO(date) : null);

export const getDefaultValueForDOBField = (prefillData?: PaperworkRelationships): Date | null => {
  if (prefillData?.relationshipType === RelationshipSubtype.TRUST) {
    return getISODate(prefillData.party.partyBusinessEntity?.attributes?.trustDate);
  }
  return getISODate(prefillData?.party.partyPerson?.birthDate);
};

export const getDefaultValueForNameField = (prefillData?: PaperworkRelationships): string => {
  if (
    prefillData?.relationshipType &&
    [RelationshipSubtype.TRUST, RelationshipSubtype.CHARITY].includes(prefillData.relationshipType)
  ) {
    return prefillData.party.partyBusinessEntity?.name ?? '';
  }
  return prefillData?.party.partyPerson?.givenName ?? '';
};

export const getInputError = (
  fieldNameForError: string,
  questionKeyForError: string,
  error?: FieldError,
  validationMessages?: ValidationMessage[],
) => {
  return (
    !!error && (
      <Box data-qa={`error-${error.ref?.name}`} sx={{ width: 1 }}>
        <Typography role="alert" sx={{ color: 'error.main' }} variant="caption">
          {getErrorMessage(fieldNameForError, questionKeyForError, error.type, validationMessages)}
        </Typography>
      </Box>
    )
  );
};
