import React, { FC, useState } from 'react';

import { ComponentTypes } from '../types';

import { getQuestionContent } from './utils';

import { TaxLossHarvestingModal } from '~/components/modals/TaxLossHarvesting';
import { Button, createSvgIcon } from '~/components/ui/mui';
import { CMSQuestions } from '~/containers/Paperwork/contentstack';
import { ContentOptions } from '~/utils/contentstack';

interface Props {
  contentOptions: ContentOptions;
  harvestLosses: boolean;
  onClose: () => void;
  open: boolean;
  openModal: () => void;
  partyId: string;
  questionKey: string;
  questionsContent: CMSQuestions;
  showTlhButtonIcon: boolean;
}

const LeafIcon = createSvgIcon(
  <React.Fragment>
    <path d="M6.05001 8.05C3.32001 10.78 3.32001 15.2 6.03001 17.93C7.50001 14.53 10.12 11.69 13.39 10C10.62 12.34 8.68001 15.61 8.00001 19.32C10.6 20.55 13.8 20.1 15.95 17.95C19.43 14.47 20 4 20 4C20 4 9.53001 4.57 6.05001 8.05Z" />
  </React.Fragment>,
  'Leaf',
);

export const TLHQuestion: FC<Props> = ({
  contentOptions,
  harvestLosses,
  onClose,
  open,
  openModal,
  partyId,
  questionKey,
  questionsContent,
  showTlhButtonIcon,
}) => {
  const [tlhValue, setTlhValue] = useState(harvestLosses);
  return (
    <>
      <Button
        aria-haspopup="true"
        onClick={openModal}
        startIcon={showTlhButtonIcon ? <LeafIcon color="primary" /> : ''}
        variant={showTlhButtonIcon ? 'outlined' : 'contained'}
      >
        {
          getQuestionContent({
            componentType: ComponentTypes.Tlh,
            questionKey: questionKey + (tlhValue ? '_off' : '_on'),
            singleOptionInputContents: questionsContent.single_option_input_questions,
            textInputContents: questionsContent.text_input_questions,
          }).question
        }
      </Button>
      <TaxLossHarvestingModal
        contentOptions={contentOptions}
        onClose={onClose}
        onFinish={() => setTlhValue(curr => !curr)}
        open={open}
        partyId={partyId}
      />
    </>
  );
};
