import React, { ComponentProps, Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Controller, FieldError, RegisterOptions, UseFormMethods } from 'react-hook-form';

import { isMasked } from '../../configs/questions/utils';
import { FormData } from '../../types';
import { Mask, QuestionContent, QuestionOrderSteps } from '../types';

import { formatInputQuestionDefaultValue } from './utils';

import { InputAdornment } from '~/components/ui/mui';
import { TextField } from '~/components/ui/TextField';

interface Props {
  defaultValue: any;
  fieldName: string;
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  formattingRules?: {
    capitalize?: boolean;
    masked?: Mask;
  };
  inputErrors?: FieldError;
  invalidCharacterRegex?: RegExp;
  isFieldDisabled: boolean;
  questionContent: QuestionContent;
  questionInRow: QuestionOrderSteps;
  setUnmaskedValues: Dispatch<SetStateAction<Record<string, string>>>;
  textFieldProps?: Partial<ComponentProps<typeof TextField>>;
  unmaskedValues: Record<string, string>;
  validationRules: RegisterOptions;
}

export const InputQuestion: FC<Props> = ({
  formattingRules,
  formHooks,
  defaultValue,
  validationRules,
  fieldName,
  questionContent,
  questionInRow,
  invalidCharacterRegex,
  isFieldDisabled,
  inputErrors,
  setUnmaskedValues,
  textFieldProps,
  unmaskedValues,
}) => {
  const { control } = formHooks;

  /**
   * This is to handle cases where FE receives unmasked data, which is unchanged on user submission,
   * should then be sent as paperwork data.
   */
  useEffect(() => {
    if (
      typeof defaultValue === 'string' &&
      formattingRules?.masked &&
      !isMasked(defaultValue) &&
      !Object.keys(unmaskedValues).includes(fieldName)
    ) {
      setUnmaskedValues({ ...unmaskedValues, [fieldName]: defaultValue });
    }
  }, [fieldName, formattingRules?.masked, defaultValue, setUnmaskedValues, unmaskedValues]);

  return (
    <Controller
      control={control}
      defaultValue={formatInputQuestionDefaultValue({
        capitalize: formattingRules?.capitalize,
        defaultValue,
        invalidCharacterRegex,
        masked: formattingRules?.masked,
        validationRules,
      })}
      key={`question-${fieldName}`}
      name={fieldName}
      render={({ onChange, value, name, ref }) => (
        <TextField
          InputProps={{
            endAdornment: questionContent.suffix ? (
              <InputAdornment position="end">{questionContent.suffix}</InputAdornment>
            ) : undefined,
            startAdornment: questionContent.prefix ? (
              <InputAdornment position="start">{questionContent.prefix}</InputAdornment>
            ) : undefined,
          }}
          data-qa={`question-textField-${questionInRow.questionKey}`}
          disabled={isFieldDisabled}
          error={!!inputErrors}
          fullWidth
          inputRef={ref}
          label={questionContent.question}
          name={name}
          onBlur={e => {
            const trimmedValue = e.target.value.trim();
            e.target.value = trimmedValue.replaceAll(/\s+/g, ' ');
            onChange(e);
          }}
          onChange={e => {
            const { value: inputValue } = e.target;
            if (validationRules.maxLength && inputValue.length > +validationRules.maxLength) {
              e.preventDefault();
              return;
            }
            if (validationRules.max && parseFloat(inputValue) > +validationRules.max) {
              e.preventDefault();
              return;
            }
            if (invalidCharacterRegex && e.target.value) {
              e.target.value = inputValue.replaceAll(invalidCharacterRegex, '');
            }
            if (formattingRules?.capitalize && e.target.value) {
              e.target.value = e.target.value.toUpperCase();
            }
            onChange(e);
          }}
          value={value}
          {...textFieldProps}
        />
      )}
      rules={validationRules}
    />
  );
};
