import { QuestionOrderSteps as QuestionOrderStep } from '../../../Section/types';
import { firstName, lastName, middleName, numberOfDependents } from '../../questions/personal';
import { QuestionProperties } from '../../questions/types';

export const firstNameNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return firstName(
    {
      ...properties,
      additionalValidations: {
        pattern: new RegExp(/^[a-zA-Z -]*$/),
        maxLength: 30,
        ...properties.additionalValidations,
      },
    },
    {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z -]/g),
    },
  );
};

export const lastNameNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return lastName(
    {
      ...properties,
      additionalValidations: {
        pattern: new RegExp(/^[a-zA-Z -]*$/),
        maxLength: 25,
        ...properties.additionalValidations,
      },
    },
    {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z -]/g),
    },
  );
};

export const middleNameNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return middleName(
    {
      ...properties,
      additionalValidations: {
        pattern: new RegExp(/^[a-zA-Z ]*$/),
        maxLength: 10,
        ...properties.additionalValidations,
      },
    },
    {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z ]/g),
    },
  );
};

export const numberOfDependentsNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return numberOfDependents(
    {
      ...properties,
      additionalValidations: {
        pattern: new RegExp(/^\d*$/),
        maxLength: 2,
        ...properties.additionalValidations,
      },
    },
    {
      invalidCharacterRegex: new RegExp(/\D/g),
    },
  );
};
