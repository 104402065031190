import { isDate, parseISO } from 'date-fns';
import { DeepMap, ErrorOption, FieldError } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

import { CMSQuestions, SingleOptionInputQuestion, TextInputQuestion } from '../../contentstack';
import { GetPaperworkQuestionContent_all_paperwork_questions_items } from '../../contentstack/exports';
import { PaperworkContextVariables } from '../../context';
import { ZipCodeMapItem } from '../../hooks/useGetPaperworkData';
import { getDefaultValueForInvestmentKey } from '../../utils/investment';
import { getDefaultValueForPartyKey } from '../../utils/party';
import {
  getDefaultValueForRegulatoryInformationKey,
  getDefaultValueForRelationshipsKey,
} from '../../utils/relationships';
import { getDefaultValueForTruthInformationKey } from '../../utils/trustInformation';
import { getDefaultValueForWealthKey } from '../../utils/wealth';

import {
  CustomErrorMap,
  DataMappingsByDataPointKey,
  DropdownsContent,
  Formatting,
  HiddenOption,
  Mask,
  QuestionContent,
  QuestionOrderSteps,
  Validation,
  ValidationMessage,
} from './types';

import {
  AddressType,
  InvestmentAmountsInput,
  InvestmentInput,
  PaperworkAdditionalAttributesInput,
  PaperworkInput,
  RegulatoryInformationInput,
  RelationshipInput,
  TrustInformationInput,
  WealthInput,
} from '~/__generated__';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { ManagedProductPaperwork, PaperworkParty, QuestionnaireData } from '~/containers/Paperwork/symphony';

export enum ComponentTypes {
  AssetsHeldAway = 'AssetsHeldAway',
  Beneficiary = 'Beneficiary',
  Checkbox = 'Checkbox',
  CheckboxGroup = 'CheckboxGroup',
  Contacts = 'Contacts',
  Currency = 'Currency',
  Date = 'Date',
  Dropdown = 'Dropdown',
  FileUploader = 'FileUploader',
  /*
   * Hidden ComponentType is a page modifier that is not rendered on DOM,
   * but its value maybe used to conditionally render items
   * they are NOT meant to get information on the page. Their values will
   * not be used to save information.
   */
  Hidden = 'Hidden',
  Info = 'Info',
  Input = 'Input',
  InvestmentRestrictions = 'InvestmentRestrictions',
  Label = 'Label',
  Radio = 'Radio',
  Regulatory = 'Regulatory',
  Subtext = 'Subtext',
  Text = 'Text',
  Tlh = 'Tlh',
}

export enum InputTypes {
  Email = 'email',
  Float = 'float',
  Number = 'number',
  Percentage = 'percentage',
  SingleOption = 'single_option',
  Ssn = 'ssn',
  Text = 'text',
  Tin = 'tin',
}

export enum ValidationNames {
  AllowCustodialMinorsOnly = 'allowCustodialMinorsOnly',
  AlphaNumericOnly = 'alphaNumericOnly',
  AlphaOnly = 'alphaOnly',
  AtLeastOne = 'atLeastOne',
  Disabled = 'disabled',
  ForceDisabled = 'forceDisabled',
  FutureDisabled = 'future_disabled',
  InvalidCharactersRegex = 'invalidCharactersRegex',
  IsValidTenantsInCommonPercentage = 'isValidTenantsInCommonPercentage',
  Length = 'length',
  Max = 'max',
  MaxAge = 'max_age',
  MaxDecimalPlaces = 'maxDecimalPlaces',
  MaxFileSize = 'maxFileSize',
  MaxLength = 'maxLength',
  MaxNumberOfFiles = 'maxNumberOfFiles',
  Min = 'min',
  MinDateFromDataPointKey = 'minDateFromDataPointKey',
  MinLength = 'minLength',
  MinorAge = 'minor_age',
  NumericOnly = 'numericOnly',
  PastDisabled = 'past_disabled',
  PostBoxNotAllowed = 'postBoxNotAllowed',
  Regex = 'regex',
  Required = 'required',
  RestrictedStates = 'restrictedStates',
  RestrictedStatesForJointAccount = 'restrictedStatesForJointAccount',
  SufficientFunds = 'sufficientFunds',
  TenantsInCommonPercentage = 'tenantsInCommonPercentage',
  TodayDisabled = 'today_disabled',
  ValidNetWorth = 'validNetWorth',
  ValidZipCode = 'validZipCode',
  ValueNotLessThan = 'valueNotLessThan',
}

export enum FormattingNames {
  Capitalize = 'capitalize',
  DisableTextInput = 'disable_text_input',
  Masked = 'masked',
}

export enum CmsKeys {
  Countries = 'countries',
  Email = 'email',
  RepCode = 'repCode',
  SelectContact = 'selectContact',
  States = 'states',
}

export const getInputType = (type: string): InputTypes => {
  switch (type) {
    case 'email':
      return InputTypes.Email;
    case 'number':
      return InputTypes.Number;
    case 'percentage':
      return InputTypes.Percentage;
    case 'single_option':
      return InputTypes.SingleOption;
    case 'ssn':
      return InputTypes.Ssn;
    case 'tin':
      return InputTypes.Tin;
    case 'float':
      return InputTypes.Float;
    default:
      return InputTypes.Text;
  }
};

export const matchesRegex = (pattern: RegExp, str?: string): boolean => {
  if (!str) {
    return true;
  } else {
    return pattern.test(str);
  }
};

export const isValidEmail = (email: string): boolean => {
  return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.]+$/.test(email);
};

export const isValidIdentificationNumber = (value: string): boolean => {
  return /^\*{5}\d{4}$/.test(value) || (/^\d{9}$/.test(value) && !(/123456789/.test(value) || /^(\d)\1+$/.test(value)));
};

export const isValidNumber = (number: string): boolean => {
  return /^[0-9.]+$/.test(number);
};

/**
 * isAddressWithoutPostBox to determine if the address contains any variation of PO Box or not
 * Length of address should not be very signficant (for the case where it is introduced, max length is 50)
 * @param { address } address - Address of client
 * @returns { boolean } Returns true if address have no instance of different variation of PO Box in Address
 */
export const isAddressWithoutPostBox = (address?: string | null): boolean => {
  if (!address) {
    return true;
  }

  const regexCategory1 = new RegExp(/(^|\s)p(\.)?\s*o(\.)?\s*box(\s|$)/i); // Combinations of abbreviated PO Box
  const regexCategory2 = new RegExp(/(^|\s)post((al|\soffice)\s)?(\s)*box(\s|$)/i); // Combinations of Post(al) (Office) Box
  const regexCategory3 = new RegExp(/(^|\s)(box|bin)[\s]+number(\s|$)/i); // Combinations of (Box|Bin) Number
  return !regexCategory1.test(address) && !regexCategory2.test(address) && !regexCategory3.test(address);
};

export interface FormData {
  [dataPointKey: string]: any;
}

export const atLeastOneIsFilled = ({
  value,
  linkedFields,
  isSubmitted,
  fieldsErrors,
  fieldName,
  getValues,
  setError,
  trigger,
}: {
  fieldName: string;
  fieldsErrors: DeepMap<FormData, FieldError>;
  getValues: any;
  isSubmitted: boolean;
  linkedFields: string[];
  setError: (name: string, error: ErrorOption) => void;
  trigger: (name?: string | string[] | undefined) => Promise<boolean>;
  value: string;
}): boolean => {
  let atLeastOneFieldIsFilled = !!value.length;
  let fieldErrorsExist = !!fieldsErrors[fieldName];
  linkedFields.forEach(field => {
    if (getValues(field) && !atLeastOneFieldIsFilled) {
      atLeastOneFieldIsFilled = true;
    }
    if (!fieldErrorsExist && fieldsErrors[field]) {
      fieldErrorsExist = true;
    }
  });
  // remove error of linked fields when user typed a value
  if (fieldErrorsExist && value.length === 1) {
    linkedFields.forEach(field => {
      trigger(field);
    });
  }
  // add error if user delete the value again
  if (!isSubmitted && !atLeastOneFieldIsFilled) {
    linkedFields.forEach(field => {
      setError(field, { type: 'atLeastOne' });
    });
  }
  return atLeastOneFieldIsFilled;
};

export const isValidLength = (value: string, expectedLength: number): boolean => {
  return value.length === expectedLength;
};

export const isValidTicker = (ticker: string): boolean => {
  return /^[A-Za-z0-9]+$/.test(ticker);
};

export const maskData = (value: string, mask: Mask): string => {
  return value
    .split('')
    .map((char, index) => {
      if (index < value.length - mask.charsToShow) {
        return mask.maskChar;
      }
      return char;
    })
    .join('');
};
export interface IsValidZipCodeParam {
  isZipCodeOptional?: boolean;
  linkedState: string | undefined | null;
  stateZipCodeMap: ZipCodeMapItem[];
  zipcode: string | undefined | null;
}
/**
 * @param { IsValidZipCodeParam } isValidZipCodeParam it's an object with
 * @param {string} isValidZipCodeParam.linkedState - A string param that is the state against which the zip code needs to be validated
 * @param {string} isValidZipCodeParam.zipcode - A string param. This param is the user entered zipcode
 * @param {list} isValidZipCodeParam.stateZipCodeMap - A list that maps states to valid it's zipcodes
 * @param {boolean} isValidZipCodeParam.isZipCodeOptional - A boolean value coming from partner's paperwork config
 * This function checks if the entered zip code lies in the range of zip codes for the corresponding entered state.
 * and also if zipcode is optional field and isZipCodeOptional flag is true, it will ignore the validations and pass true.
 * Returns true only if the #digits in zipcode is greater than or equal to 3 and lies in the range of zip codes for the corresponding state linked to it or the state isnt present in the zipcode map, else returns false.
 * PDF LINK FOR STATE TO ZIP CODES MAP (stateZipCodeMap)- http://www.directmailmanagerri.com/pdfs/zipcode_list_for_US_states.pdf
 */
export const isValidZipCode = ({
  linkedState,
  zipcode,
  stateZipCodeMap,
  isZipCodeOptional = false,
}: IsValidZipCodeParam): boolean => {
  if (!zipcode?.length && isZipCodeOptional) {
    return true;
  }
  if (!zipcode || !linkedState || !stateZipCodeMap) {
    return false;
  }
  const statesArray = stateZipCodeMap.map(item => item.state);
  const firstThreeCharacters = zipcode.length >= 3 ? parseInt(zipcode.slice(0, 3), 10) : '';
  const stateIndex = statesArray.indexOf(linkedState);
  if (stateIndex === -1) {
    return true;
  }
  if (firstThreeCharacters && stateZipCodeMap[stateIndex].ranges.length !== 0) {
    const isInRange = stateZipCodeMap[stateIndex].ranges.some(
      range =>
        firstThreeCharacters >= parseInt(range.low ?? '', 10) && firstThreeCharacters <= parseInt(range.high ?? '', 10),
    );
    return isInRange;
  }
  return false;
};

export const getFormattingRules = (
  formattings?: Formatting[],
): { capitalize?: boolean; disableTextInput?: boolean; masked?: Mask } => {
  if (!formattings) {
    return {};
  }

  return formattings.reduce(
    (acc: Partial<{ capitalize: boolean; disableTextInput: boolean; masked: Mask }>, formatting) => {
      switch (formatting.name) {
        case FormattingNames.Capitalize:
          acc.capitalize = formatting.value as boolean;
          break;
        case FormattingNames.DisableTextInput:
          acc.disableTextInput = formatting.value as boolean;
          break;
        case FormattingNames.Masked:
          acc.masked = formatting.value as Mask;
          break;
        default:
          break;
      }
      return acc;
    },
    {},
  );
};

export type ValidationRules = Partial<RegisterOptions> &
  Partial<{
    allowCustodialMinorsOnly: boolean;
    alphaNumericOnly: boolean;
    alphaOnly: boolean;
    atLeastOne: string[];
    capitalize: boolean;
    disabled: boolean;
    forceDisabled: boolean;
    futureDisabled: boolean;
    invalidCharactersRegex: RegExp;
    isValidTenantsInCommonPercentage: boolean;
    length: number;
    max: number;
    maxAge: number;
    maxDecimalPlaces: number;
    maxFileSize: number;
    maxNumberOfFiles: number;
    min: number;
    minDate: Date;
    minDateFromDataPointKey: string;
    minorAge: boolean;
    numericOnly: boolean;
    pastDisabled: boolean;
    postBoxNotAllowed: boolean;
    regex: RegExp;
    restrictedStates: boolean;
    restrictedStatesForJointAccount: string[];
    sufficientFunds: boolean;
    tenantsInCommonPercentageSum: boolean;
    todayDisabled: boolean;
    validNetWorth: boolean;
    validZipCode: string;
    valueNotLessThan: string;
  }>;

export const getValidationRules = (
  validations?: Validation[],
): { customErrorMap: CustomErrorMap[]; validationRules: ValidationRules } => {
  const customErrorMap: CustomErrorMap[] = [];
  if (!validations) {
    return { customErrorMap, validationRules: {} };
  }

  const validationRules = validations.reduce((acc: ValidationRules, validation) => {
    if (validation.errorMap) {
      customErrorMap.push(validation.errorMap);
    }
    switch (validation.name) {
      case ValidationNames.Required:
        acc.required = validation.value as boolean;
        break;
      case ValidationNames.Disabled:
        acc.disabled = validation.value as boolean;
        break;
      case ValidationNames.ForceDisabled:
        acc.forceDisabled = validation.value as boolean;
        break;
      case ValidationNames.FutureDisabled:
        acc.futureDisabled = validation.value as boolean;
        break;
      case ValidationNames.PastDisabled:
        acc.pastDisabled = validation.value as boolean;
        break;
      case ValidationNames.Max:
        acc.max = validation.value as number;
        break;
      case ValidationNames.Min:
        acc.min = validation.value as number;
        break;
      case ValidationNames.MinLength:
        acc.minLength = validation.value as number;
        break;
      case ValidationNames.Length:
        acc.length = validation.value as number;
        break;
      case ValidationNames.MaxDecimalPlaces:
        acc.maxDecimalPlaces = validation.value as number;
        break;
      case ValidationNames.MaxLength:
        acc.maxLength = validation.value as number;
        break;
      case ValidationNames.MaxNumberOfFiles:
        acc.maxNumberOfFiles = validation.value as number;
        break;
      case ValidationNames.MaxFileSize:
        acc.maxFileSize = validation.value as number;
        break;
      case ValidationNames.MinorAge:
        acc.minorAge = validation.value as boolean;
        break;
      case ValidationNames.AllowCustodialMinorsOnly:
        acc.allowCustodialMinorsOnly = validation.value as boolean;
        break;
      case ValidationNames.MaxAge:
        acc.maxAge = validation.value as number;
        break;
      case ValidationNames.AtLeastOne:
        acc.atLeastOne = validation.value as string[];
        break;
      case ValidationNames.RestrictedStates:
        acc.restrictedStates = validation.value as boolean;
        break;
      case ValidationNames.ValidNetWorth:
        acc.validNetWorth = validation.value as boolean;
        break;
      case ValidationNames.AlphaNumericOnly:
        acc.alphaNumericOnly = validation.value as boolean;
        break;
      case ValidationNames.AlphaOnly:
        acc.alphaOnly = validation.value as boolean;
        break;
      case ValidationNames.IsValidTenantsInCommonPercentage:
        acc.isValidTenantsInCommonPercentage = validation.value as boolean;
        break;
      case ValidationNames.NumericOnly:
        acc.numericOnly = validation.value as boolean;
        break;
      case ValidationNames.SufficientFunds:
        acc.sufficientFunds = validation.value as boolean;
        break;
      case ValidationNames.ValidZipCode:
        acc.validZipCode = validation.value as string;
        break;
      case ValidationNames.TodayDisabled:
        acc.todayDisabled = validation.value as boolean;
        break;
      case ValidationNames.TenantsInCommonPercentage:
        acc.tenantsInCommonPercentageSum = validation.value as boolean;
        break;
      case ValidationNames.PostBoxNotAllowed:
        acc.postBoxNotAllowed = validation.value as boolean;
        break;
      case ValidationNames.RestrictedStatesForJointAccount:
        acc.restrictedStatesForJointAccount = validation.value as string[];
        break;
      case ValidationNames.ValueNotLessThan:
        acc.valueNotLessThan = validation.value as string;
        break;
      case ValidationNames.MinDateFromDataPointKey:
        acc.minDateFromDataPointKey = validation.value as string;
        break;
      case ValidationNames.Regex:
        acc.regex = validation.value as RegExp;
        break;
      case ValidationNames.InvalidCharactersRegex:
        acc.invalidCharactersRegex = validation.value as RegExp;
        break;
      default:
        break;
    }
    return acc;
  }, {});

  return { customErrorMap, validationRules };
};

const getDefaultAdditionalAttribute = (
  paperwork: ManagedProductPaperwork,
  key: string,
  defaultValue: string | boolean | Record<string, boolean> | undefined | string[],
): string | boolean | Record<string, boolean> | undefined | string[] => {
  const value = paperwork.additionalAttributes?.[key as keyof PaperworkAdditionalAttributesInput];
  return typeof value === 'boolean'
    ? value
    : value
    ? Array.isArray(value)
      ? (value.filter(v => !!v) as string[])
      : value
    : defaultValue;
};
/**
 * This function is used to set the defaultValue correctly.
 * @param paperwork - Party Paperwork information.
 * @param data - DataMappingsByDataPointKey
 * @returns The default setter.
 */
export const getDefaultValue = (paperwork: ManagedProductPaperwork, data: DataMappingsByDataPointKey) => {
  const symphonyMappingSplit = data.symphonyMapping.split('.');
  const inputFieldKey = symphonyMappingSplit[0] as keyof ManagedProductPaperwork;
  switch (inputFieldKey) {
    case 'additionalAttributes':
      if (data.componentType === ComponentTypes.CheckboxGroup && data.checkboxItems) {
        const defaultValue: Record<string, string | boolean | Record<string, boolean> | undefined | string[]> = {};
        data.checkboxItems.forEach(
          item =>
            (defaultValue[item.dataPointKey] = getDefaultAdditionalAttribute(
              paperwork,
              item.symphonyMapping.split('.')[1],
              item.defaultValue,
            )),
        );
        return defaultValue;
      } else {
        return getDefaultAdditionalAttribute(paperwork, symphonyMappingSplit[1], data.defaultValue);
      }
    case 'party':
      return getDefaultValueForPartyKey(
        paperwork.party as PaperworkParty,
        symphonyMappingSplit.slice(1).join('.'),
        data,
      );
    case 'investment':
      return getDefaultValueForInvestmentKey(
        paperwork.investment as InvestmentInput,
        symphonyMappingSplit.slice(1).join('.'),
      );
    case 'regulatoryInformation':
      return getDefaultValueForRegulatoryInformationKey(
        paperwork.regulatoryInformation as RegulatoryInformationInput,
        symphonyMappingSplit.slice(1).join('.'),
      );
    case 'trustInformation':
      return getDefaultValueForTruthInformationKey(
        paperwork.trustInformation as TrustInformationInput,
        symphonyMappingSplit.slice(1).join('.'),
      );
    case 'wealthInformation':
      return getDefaultValueForWealthKey(
        paperwork.wealthInformation as WealthInput,
        symphonyMappingSplit.slice(1).join('.'),
      );
    case 'isHomeAddressDerivedFromPrimary':
      return paperwork[inputFieldKey] ?? data.defaultValue;
    case 'isMailingAddressDerivedFromPrimary':
    case 'isMailingAddressSameAsHomeAddress':
      return paperwork[inputFieldKey] === false;
    case 'tradeConfirmationFrequency':
      return paperwork[inputFieldKey];
    case 'isProxyVotingEnabled':
      return typeof paperwork[inputFieldKey] === 'boolean' ? `${paperwork[inputFieldKey]}` : null;
  }

  const symphonyMappingRelationshipsSplit = data.symphonyMapping.split(':');
  switch (symphonyMappingRelationshipsSplit[0]) {
    case 'relationships':
      return getDefaultValueForRelationshipsKey(paperwork.relationships as RelationshipInput[], data.symphonyMapping);
  }
  // TODO: Default pre-fill for custom components and non-stored values
  return '';
};

export const getValueFromSelectedContact = (
  selectedContact: ManagedProductPaperwork,
  data: DataMappingsByDataPointKey,
  dpk: string,
  dropdownsContent: DropdownsContent,
  singleOptionInputContents: (SingleOptionInputQuestion | null)[] | null,
) => {
  let value = getDefaultValue(selectedContact, data);
  if (dpk === 'data_point:resident_type:single_option') {
    /**
     * Only US Citizen and Resident Alien are available, from contact data
     * if the citizenship is USA we set it as 1 mapped in contentstack or we map to 2 for
     * resident alien
     */
    value =
      !selectedContact.party?.partyPerson?.citizenship || selectedContact.party.partyPerson.citizenship === 'USA'
        ? '1'
        : '2';
  } else {
    switch (data.componentType) {
      case ComponentTypes.Dropdown:
        const question = getQuestionContent({
          componentType: ComponentTypes.Dropdown,
          customComponents: [],
          questionKey: data.questionKey ?? '',
          singleOptionInputContents,
          textInputContents: null,
          type: InputTypes.SingleOption,
        });
        const dropdownItems = getDropdownItems(data.cmsKeys, dropdownsContent, question, []);
        value = getDefaultDropdownValue(value, dropdownItems);
        value = value ? value : data.defaultValue ?? '';
        break;
      case ComponentTypes.Date:
        value = value && typeof value === 'string' && isDate(parseISO(value)) ? parseISO(value) : value;
        break;
      case ComponentTypes.Radio:
        if (!value) {
          value = data.defaultValue ?? '';
        }
        break;
      case ComponentTypes.Checkbox:
        if (typeof value !== 'boolean') {
          value = data.defaultValue ?? false;
        }
        // Check if the address is present in the paperwork. If it is, then uncheck the checkbox "Your co-applicant's home and mailing address are the same as yours." Otherwise, check the checkbox.
        value = {
          [dpk]:
            dpk === 'data_point:same_home_mailing_address:boolean'
              ? !selectedContact.party?.addresses?.filter(address => address.type === AddressType.MAILING)?.length
              : value,
        };
        break;
      case ComponentTypes.CheckboxGroup:
        value = value ? value : data.defaultValue;
    }
  }
  return value;
};

export const getDropdownItems = (
  cmsKey: string | undefined,
  dropdownsContent: DropdownsContent,
  questionContent: QuestionContent,
  hiddenOptions: string[] = [],
  allowedOptions?: string[],
): DropdownItem[] => {
  const sortFunc = (a: DropdownItem, b: DropdownItem) =>
    a.label && b.label ? (a.label > b.label ? 1 : a.label < b.label ? -1 : 0) : 0;
  const dropdownItems =
    cmsKey === CmsKeys.States
      ? dropdownsContent.statesList.sort(sortFunc) ?? []
      : cmsKey === CmsKeys.Countries
      ? [
          dropdownsContent.countriesList.find(country => country.value === '999') as DropdownItem,
          ...dropdownsContent.countriesList.filter(country => country.value !== '999').sort(sortFunc),
        ] ?? []
      : questionContent.options ?? [];
  return (dropdownItems as DropdownItem[])
    .filter(item => !hiddenOptions.includes(item.value.toString()))
    .filter(item => !allowedOptions?.length || allowedOptions.includes(item.value.toString()));
};

export const getErrorMessage = (
  errorType?: string,
  validationMessages?: ValidationMessage[],
  customErrorMap?: CustomErrorMap[],
  questionKey?: string,
): string | undefined => {
  const customErrorName =
    customErrorMap?.find(item => item.originalErrorName === errorType)?.customErrorName ?? errorType;
  return (
    validationMessages?.find(m => m.key === `validation:${questionKey ?? ''}:${errorType}`)?.label ??
    validationMessages?.find(m => m.key === `validation:${errorType}`)?.label ??
    validationMessages?.find(m => m.key === customErrorName)?.label ??
    errorType
  );
};

export const filterAllMatchingDropdownItems = (value: string, dropdownItems: DropdownItem[]): string[] => {
  return value
    ? value.split(',').filter(v => {
        return (
          dropdownItems.map(o => o.value).includes(v) ||
          dropdownItems.flatMap(o => o.otherValues).includes(v) ||
          dropdownItems.map(o => typeof o.label === 'string' && o.label.toLowerCase()).includes(v.toLowerCase())
        );
      })
    : [];
};
/**
 * This function checks both the label and value of the dropdownItems to get the defaultValue correctly.
 * It is mainly required for state field prefill because Identity stores state as California, but Contentstack has CA as value
 * @param value - The default value for the dropdown.
 * @param dropdownItems - An array containing the dropdown items.
 * @returns The default dropdown value.
 */
export const getDefaultDropdownValue = (value: string, dropdownItems: DropdownItem[], firstDefaultValue = false) => {
  if (!value && firstDefaultValue) {
    return dropdownItems[0].value ?? '';
  }
  const defaultValue = filterAllMatchingDropdownItems(value, dropdownItems)[0];

  if (!defaultValue) {
    return '';
  }

  return (
    dropdownItems.find(i => i.value === defaultValue)?.value ??
    dropdownItems.find(i => i.label === defaultValue)?.value ??
    dropdownItems.find(i => i.otherValues?.includes(defaultValue))?.value ??
    ''
  );
};

export const getDefaultCheckBoxGroupValue = (defaultValues: Record<string, any>, questionInRow: QuestionOrderSteps) => {
  const defaultValue: Record<string, boolean> = {};
  questionInRow.checkBoxes?.forEach(checkBox => {
    defaultValue[checkBox.dataPointKey] = defaultValues[checkBox.dataPointKey] ?? checkBox.defaultValue ?? false;
  });

  return defaultValue;
};

export const getQuestionnaireValues = (questions: QuestionnaireData[], questionnaireDataPointKeyMapping: string) => {
  const foundObject = questions.find(q => {
    if (q.__typename === 'QuestionnaireCollectedCurrency') {
      return q.dataPointKey === questionnaireDataPointKeyMapping;
    } else if (q.__typename === 'QuestionnaireCollectedSingleOption') {
      return q.dataPointKey === questionnaireDataPointKeyMapping;
    }
    return undefined;
  });
  if (foundObject?.__typename === 'QuestionnaireCollectedCurrency') {
    return foundObject.value.currencyValue;
  }
  if (foundObject?.__typename === 'QuestionnaireCollectedSingleOption') {
    return foundObject.singleOptionValue;
  }
  return undefined;
};

export const calculateNetWorth = (paperwork: PaperworkInput): number => {
  const cash = parseFloat(paperwork.wealthInformation?.cash ?? '0');
  const nonLiquidAssets = parseFloat(paperwork.wealthInformation?.nonLiquidAssets ?? '0');
  const liabilities = parseFloat(paperwork.wealthInformation?.liabilities ?? '0');
  const investmentExperienceDetail = paperwork.investment?.experienceDetail ?? {};
  const totalInvestmentExperienceValue = getTotalInvestmentExperienceValue(investmentExperienceDetail);

  return cash + nonLiquidAssets + totalInvestmentExperienceValue - liabilities;
};

export const getTotalInvestmentExperienceValue = (investmentExperience: {
  [key: string]: InvestmentAmountsInput;
}): number => {
  return Object.values(investmentExperience).reduce(
    (total: number, exp: InvestmentAmountsInput): number =>
      total + parseFloat(exp?.qualified ?? '0') + parseFloat(exp?.nonQualified ?? '0'),
    0,
  );
};

export const getQuestionContent = (params: {
  componentType: ComponentTypes;
  customComponents: ComponentTypes[];
  questionKey: string;
  singleOptionInputContents: (SingleOptionInputQuestion | null)[] | null;
  textInputContents: (TextInputQuestion | null)[] | null;
  type: InputTypes;
}): QuestionContent => {
  const { componentType, customComponents, questionKey, singleOptionInputContents, textInputContents, type } = params;
  if (type === InputTypes.SingleOption) {
    const singleInputQuestionContent = singleOptionInputContents?.find(c => c?.key === questionKey);
    if (singleInputQuestionContent) {
      return {
        key: singleInputQuestionContent.key ?? '',
        question: singleInputQuestionContent.question ?? '',
        options:
          singleInputQuestionContent.input_options?.map(o => ({
            value: o?.value ?? '',
            label: o?.label ?? '',
          })) ?? [],
      };
    }
  } else {
    const textInputQuestionContent = textInputContents?.find(c => c?.key === questionKey);
    if (textInputQuestionContent) {
      return {
        key: textInputQuestionContent.key ?? '',
        question: textInputQuestionContent.question ?? '',
        character_limit: textInputQuestionContent.character_limit ?? 50,
        prefix: textInputQuestionContent.prefix ?? undefined,
        suffix: textInputQuestionContent.suffix ?? undefined,
      };
    }
  }

  return { key: '', question: '', error: !customComponents.includes(componentType) };
};

export const getComponentFields = (params: {
  contextVariables: PaperworkContextVariables;
  currentDefaultValue?: string | boolean;
  customComponents: ComponentTypes[];
  defaultValues: Record<string, any>;
  dropdownsContent: any;
  fieldsErrors: DeepMap<FormData, FieldError>;
  formIsSubmitted: boolean;
  hiddenOptions?: HiddenOption[];
  listOfEmails?: DropdownItem<string | number>[];
  questionInRow: QuestionOrderSteps;
  repCodes: DropdownItem<string>[];
  selectContactDropdownItems?: DropdownItem[];
  singleOptionInputContents: (SingleOptionInputQuestion | null)[] | null;
  textInputContents: (TextInputQuestion | null)[] | null;
  validationMessages?: ValidationMessage[];
}): {
  componentType: ComponentTypes;
  defaultValue: any;
  dependentHiddenOptions?: {
    comparisonType: string;
    contextVariable: keyof PaperworkContextVariables;
    fieldName: string;
    value: string;
  };
  dropdownItems: DropdownItem<string | number>[];
  errorMessage?: string | null;
  fieldName: string;
  formattingRules: {
    capitalize?: boolean;
    disableTextInput?: boolean;
    masked?: Mask;
  };
  inputErrors: any;
  inputType: InputTypes;
  questionContents: QuestionContent[];
  validationRules: ValidationRules;
} => {
  const {
    currentDefaultValue,
    contextVariables,
    defaultValues,
    dropdownsContent,
    fieldsErrors,
    formIsSubmitted,
    hiddenOptions,
    listOfEmails,
    questionInRow,
    repCodes,
    validationMessages,
    customComponents,
    selectContactDropdownItems,
    singleOptionInputContents,
    textInputContents,
  } = params;
  const fieldName = questionInRow.dataPointKey;
  const dataPointKeySplit = fieldName.split(':');
  const type = dataPointKeySplit[2];
  const inputErrors = formIsSubmitted ? fieldsErrors[fieldName] : null;
  const { customErrorMap, validationRules } = getValidationRules(questionInRow.validations);
  const errorMessage = formIsSubmitted
    ? getErrorMessage(inputErrors?.type, validationMessages, customErrorMap, questionInRow.questionKey)
    : null;
  const formattingRules = getFormattingRules(questionInRow.formats);
  const inputType = getInputType(type);
  const componentType = questionInRow.componentType;
  const questionContents: QuestionContent[] = [];
  if (componentType === ComponentTypes.CheckboxGroup) {
    questionInRow.checkBoxes?.forEach(checkBox => {
      questionContents.push(
        getQuestionContent({
          componentType,
          customComponents,
          questionKey: checkBox.questionKey,
          singleOptionInputContents,
          textInputContents,
          type: inputType,
        }),
      );
    });
  } else {
    questionContents.push(
      getQuestionContent({
        componentType,
        customComponents,
        questionKey: questionInRow.questionKey,
        singleOptionInputContents,
        textInputContents,
        type: inputType,
      }),
    );
  }

  const hiddenOption = hiddenOptions?.find(option => option.fieldName === fieldName);
  const dependentHiddenOptions = hiddenOptions?.find(option => option.dependentOn?.fieldName === fieldName)
    ?.dependentOn;
  const dropdownItems =
    componentType === ComponentTypes.Dropdown
      ? questionInRow.cmsKey === CmsKeys.Email && listOfEmails
        ? listOfEmails
        : questionInRow.cmsKey === CmsKeys.RepCode
        ? repCodes
        : questionInRow.cmsKey === CmsKeys.SelectContact && selectContactDropdownItems
        ? selectContactDropdownItems
        : getDropdownItems(
            questionInRow.cmsKey,
            dropdownsContent,
            questionContents[0],
            getHiddenOptions(contextVariables, hiddenOption),
            questionInRow.allowedOptions,
          )
      : [];
  const symphonyDefaultValue = currentDefaultValue ?? defaultValues[questionInRow.dataPointKey];

  let defaultValue;
  if (questionInRow.componentType === ComponentTypes.Dropdown) {
    const matchingItems = filterAllMatchingDropdownItems(symphonyDefaultValue, dropdownItems);
    if (symphonyDefaultValue && symphonyDefaultValue.split(',').length > 1 && matchingItems.length > 1) {
      // Check if two or more dropdown items values exist in default values, then choose the config defaultValue
      defaultValue = questionInRow.defaultValue ?? '';
    } else if (matchingItems.length) {
      // Check if symphonyDefaultValue is present in the dropdown options
      defaultValue = symphonyDefaultValue;
    } else {
      // Check if defaultValue should be applied or not
      defaultValue = questionInRow.doNotApplyDefaultValue ? '' : questionInRow.defaultValue ?? '';
    }
  } else if (questionInRow.componentType === ComponentTypes.Radio) {
    defaultValue = symphonyOrQuestionDefaultValue(symphonyDefaultValue, questionInRow.defaultValue)?.toString();
  } else {
    defaultValue = symphonyOrQuestionDefaultValue(symphonyDefaultValue, questionInRow.defaultValue);
  }

  return {
    defaultValue,
    dropdownItems,
    dependentHiddenOptions,
    errorMessage,
    fieldName,
    formattingRules,
    inputType,
    inputErrors,
    componentType,
    questionContents,
    validationRules,
  };
};

/**
 * This function checks if the concatenated current and target values match. For example:
 * ('one,two', 'two' -> true),  ('one', 'one,two' -> true), ('one,two', 'one,three' -> true)
 * @param {string} targetValue - The string that we want to match against.
 * @param {string} currentValue - The current value that we want to match.
 * @param {string | undefined} splitIdentifier - Defaults to ,
 * @returns {boolean} Whether or not the current and target values match.
 */
export const matchConcatenatedStringValues = (
  targetValue: string,
  currentValue: string,
  splitIdentifier: string | undefined = ',',
): boolean => {
  const currentValueSplit = currentValue.split(splitIdentifier);
  const targetValueSplit = targetValue.split(splitIdentifier);
  return (
    currentValueSplit.includes(targetValue) ||
    targetValueSplit.includes(currentValue) ||
    currentValueSplit.some(v => targetValueSplit.includes(v)) ||
    targetValueSplit.some(v => currentValueSplit.includes(v))
  );
};

export const getDataMappingsByDataPointKey = (
  questions: QuestionOrderSteps[],
): Record<string, DataMappingsByDataPointKey> =>
  questions.reduce((acc: Record<string, DataMappingsByDataPointKey>, question) => {
    if (question.componentType === ComponentTypes.CheckboxGroup) {
      const defaultValue: Record<string, boolean> = {};
      question.checkBoxes?.forEach(checkBox => {
        defaultValue[checkBox.dataPointKey] = checkBox.defaultValue;
      });
      acc[question.dataPointKey] = {
        checkboxItems: question.checkBoxes,
        questionKey: question.questionKey,
        symphonyMapping: question.symphonyMapping,
        componentType: question.componentType,
        contextKey: question.contextKey,
        defaultValue,
      };
    } else {
      acc[question.dataPointKey] = {
        alternateSymphonyMappingForPrefill: question.alternateSymphonyMappingForPrefill,
        cmsKeys: question.cmsKey,
        componentType: question.componentType,
        contextKey: question.contextKey,
        shouldFetchFromSymphonyData: question.shouldFetchFromSymphonyData,
        symphonyMapping: question.symphonyMapping,
        questionnaireDataPointKeyMapping: question.questionnaireDataPointKey,
        questionKey: question.questionKey,
        defaultValue: question.defaultValue,
      };
    }
    return acc;
  }, {});

/**
 * This function gets the hidden dropdown options, checking on contextVariable values as needed:
 * @param {PaperworkContextVariables} contextVariables - Paperwork Context variables
 * @param {HiddenOption} hiddenOption - Hidden option config found for question
 * @returns {string[]} List of hidden drop down options
 */
export const getHiddenOptions = (
  contextVariables: PaperworkContextVariables,
  hiddenOption?: HiddenOption,
): string[] => {
  if (hiddenOption?.dependentOn) {
    const contextVariableKey = hiddenOption.dependentOn.contextVariable;
    const contextVariableValue = contextVariables[contextVariableKey];
    switch (contextVariableKey) {
      case 'isNonUsCitizen':
      case 'isResidentAlien':
      case 'isValidNetWorth':
        return contextVariableValue ? hiddenOption.values : [];
      case 'maritalStatus':
        return areOptionsForStringContextVariableHidden(contextVariableValue as string, hiddenOption)
          ? hiddenOption.values
          : [];
      default:
        console.warn(`Context Variable '${contextVariableKey}' not supported for validation`);
        return [];
    }
  } else {
    return hiddenOption?.values ?? [];
  }
};

const areOptionsForStringContextVariableHidden = (stringValue: string, hiddenOption: HiddenOption): boolean => {
  switch (hiddenOption.dependentOn?.comparisonType) {
    case 'EQUAL':
      return stringValue === hiddenOption.dependentOn.value;
    case 'NOT_EQUAL':
      return stringValue !== hiddenOption.dependentOn.value;
    default:
      return false;
  }
};

const symphonyOrQuestionDefaultValue = (symphonyDefaultValue: any, questionDefaultValue?: string | boolean): any => {
  // Check for empty string, if true assign defaultValue in Paperwork config
  return typeof symphonyDefaultValue === 'string' && !symphonyDefaultValue
    ? questionDefaultValue ?? ''
    : symphonyDefaultValue;
};

export const formatDefaultValueForQuestionInput = (
  defaultValue = '',
  formattingRules?: { capitalize?: boolean; masked?: Mask },
  validationRules?: ValidationRules,
): string => {
  const cleanedString = validationRules?.invalidCharactersRegex
    ? defaultValue.replaceAll(validationRules.invalidCharactersRegex, '')
    : defaultValue;
  const trimmedString = validationRules?.maxLength
    ? defaultValue.slice(0, validationRules.maxLength as number)
    : cleanedString;
  const capitalizedString = formattingRules?.capitalize ? trimmedString.toUpperCase() : trimmedString;
  return formattingRules?.masked ? maskData(capitalizedString, formattingRules.masked) : capitalizedString;
};

export const getRegulatoryTickerSearchContent = (
  questionsContent: GetPaperworkQuestionContent_all_paperwork_questions_items,
) => {
  return {
    tickerSearchAddTickerManuallyCta:
      questionsContent.fields?.text?.find(item => item?.key === 'tickerSearchAddTickerManuallyCta')?.value ?? '',
    tickerSearchNoOptionsText:
      questionsContent.fields?.text?.find(item => item?.key === 'tickerSearchNoOptionsText')?.value ?? '',
    tickerSearchPlaceholder:
      questionsContent.fields?.text?.find(item => item?.key === 'tickerSearchPlaceholder')?.value ?? '',
  };
};

export const getQuestionsValidationErrorMessages = (questionContent: CMSQuestions): ValidationMessage[] => {
  const textFields = questionContent.fields?.text;
  return (
    textFields
      ?.filter(field => field?.key?.startsWith('validation:'))
      .map(field => ({ key: field?.key ?? '', label: field?.value ?? '' })) ?? []
  );
};
