import { Image, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';

import { Style } from '~/components/pdfs/styles';
import { formatPhoneNumberString } from '~/utils/format/string';

interface PartyInfo {
  email: string;
  name: string;
  phoneNumber: string;
  title?: string;
}

export interface CoverContent {
  additionalOwnersInfo?: PartyInfo[];
  advisorInfo: PartyInfo;
  contactImage: string;
  emailImage: string;
  footerText: string;
  logo: string;
  phoneImage: string;
  preparedBy: string;
  preparedFor: string;
  primaryAccountOwner: string;
  primaryClientInfo: PartyInfo;
  subtitle: string;
  title: string;
}

interface Props {
  content: CoverContent;
  formatPhoneNumbers: boolean;
  pdfStyles: Style;
  showAdditionalClientsInfo?: boolean;
}

export const CoverPage: React.FC<Props> = ({ content, formatPhoneNumbers, pdfStyles, showAdditionalClientsInfo }) => {
  const {
    footerText,
    logo,
    title,
    subtitle,
    preparedFor,
    preparedBy,
    emailImage,
    phoneImage,
    contactImage,
    advisorInfo,
    primaryAccountOwner,
    primaryClientInfo,
    additionalOwnersInfo,
  } = content;
  const commonTextStyle = {
    color: pdfStyles.textPrimary.color,
    fontSize: 10,
    marginTop: '10px',
  };
  const commonHeadingStyle = {
    color: pdfStyles.primaryMainColor.color,
    fontSize: 10,
    marginTop: '40px',
    ...pdfStyles.reportHeading,
  };

  const jointAccountHeaderStyle = {
    color: pdfStyles.textSecondary.color,
    fontSize: 10,
    marginTop: '16px',
  };

  const displayPartyInfo = (clientInfos: PartyInfo) => (
    <View style={{ marginRight: '64px' }}>
      {clientInfos.title && <Text style={jointAccountHeaderStyle}>{clientInfos.title}</Text>}
      <View style={{ display: 'flex', flexDirection: 'row', marginTop: '4px' }}>
        <Image
          src={contactImage}
          style={{ maxHeight: '16px', maxWidth: '16px', marginRight: '4px', marginTop: '8px' }}
        />
        <Text style={commonTextStyle}>{clientInfos.name}</Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', marginTop: '4px' }}>
        <Image src={emailImage} style={{ maxHeight: '16px', maxWidth: '16px', marginRight: '4px', marginTop: '8px' }} />
        <Text style={commonTextStyle}>{clientInfos.email}</Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', marginTop: '4px' }}>
        <Image src={phoneImage} style={{ maxHeight: '16px', maxWidth: '16px', marginRight: '4px', marginTop: '8px' }} />
        <Text style={commonTextStyle}>
          {formatPhoneNumbers ? formatPhoneNumberString(clientInfos.phoneNumber) : clientInfos.phoneNumber}
        </Text>
      </View>
    </View>
  );

  return (
    <>
      <Page size="LETTER" wrap={false}>
        <Header logoUrl={logo} pdfStyles={pdfStyles} />
        <View
          style={{
            width: '100%',
            textAlign: 'left',
            marginTop: '200px',
            marginLeft: '80px',
          }}
        >
          <Text
            style={{
              color: pdfStyles.primaryMainColor.color,
              fontSize: 20,
              ...pdfStyles.reportTitle,
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              color: pdfStyles.textPrimary.color,
              fontSize: 14,
              marginTop: '40px',
              ...pdfStyles.reportSubTitle,
            }}
          >
            {subtitle}
          </Text>
          <Text style={commonHeadingStyle}>{preparedFor}</Text>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            {displayPartyInfo({
              ...primaryClientInfo,
              title: showAdditionalClientsInfo ? primaryAccountOwner : undefined,
            })}
            {showAdditionalClientsInfo && additionalOwnersInfo?.map(party => displayPartyInfo(party))}
          </View>
          <Text style={commonHeadingStyle}>{preparedBy}</Text>
          {displayPartyInfo(advisorInfo)}
        </View>
        <Footer pdfStyles={pdfStyles} text={footerText} />
      </Page>
    </>
  );
};
