import { OnboardingWrapperOnNextArgs } from './types';

import { ManagedProductAdditionalAttributesInput, OnboardingStates } from '~/__generated__/symphonyTypes.v2';

export const DEFAULT_ONBOARDING_STATES_ORDER = [
  OnboardingStates.MADLIBS,
  OnboardingStates.RTQ,
  OnboardingStates.RTQ_RESULT,
  OnboardingStates.PORTFOLIO_SELECTION,
  OnboardingStates.PLAN,
  OnboardingStates.FUNDING,
  OnboardingStates.PAPERWORK,
  OnboardingStates.PLAYBACK,
];

export class OnboardingWrapperHelper {
  stateToPathParam: Record<OnboardingStates, string>;
  pathParamToState: Record<string, OnboardingStates>;
  onOnboardingStageChange: (onboardingStage?: string, state?: any, replace?: boolean) => void;

  constructor(
    onOnboardingStageChange: (onboardingStage?: string, state?: any, replace?: boolean) => void,
    overrides?: Record<OnboardingStates, string>,
    isGoalsEnabled?: boolean,
  ) {
    this.onOnboardingStageChange = onOnboardingStageChange;
    this.stateToPathParam = {
      [OnboardingStates.ASSET_CONSOLIDATION]: 'asset-consolidation',
      [OnboardingStates.FUNDING]: 'funding',
      [OnboardingStates.GOALS]: 'goals',
      [OnboardingStates.INVESTMENT_SWITCH]: 'investment-switch',
      [OnboardingStates.MADLIBS]: 'madlibs',
      [OnboardingStates.PAPERWORK]: 'paperwork',
      [OnboardingStates.PLAN]: 'plan',
      [OnboardingStates.PLAYBACK]: 'playback',
      [OnboardingStates.PORTFOLIO_SELECTION]: 'portfolio-selection',
      [OnboardingStates.RTQ]: 'rtq',
      [OnboardingStates.RTQ_RESULT]: 'rtq-result',
      [OnboardingStates.SUITABILITY]: 'suitability',
      [OnboardingStates.SUITABILITY_RESULT]: 'suitability-result',
      [OnboardingStates.UNKNOWN]: isGoalsEnabled ? 'goals' : 'madlibs',
    };

    this.pathParamToState = {
      'asset-consolidation': OnboardingStates.ASSET_CONSOLIDATION,
      funding: OnboardingStates.FUNDING,
      goals: OnboardingStates.GOALS,
      'investment-switch': OnboardingStates.INVESTMENT_SWITCH,
      madlibs: OnboardingStates.MADLIBS,
      suitability: OnboardingStates.SUITABILITY,
      'suitability-result': OnboardingStates.SUITABILITY_RESULT,
      paperwork: OnboardingStates.PAPERWORK,
      plan: OnboardingStates.PLAN,
      playback: OnboardingStates.PLAYBACK,
      'portfolio-selection': OnboardingStates.PORTFOLIO_SELECTION,
      rtq: OnboardingStates.RTQ,
      'rtq-result': OnboardingStates.RTQ_RESULT,
    };

    if (overrides) {
      Object.entries(overrides).forEach(override => {
        const overrideState = override[0] as OnboardingStates;
        const overridePath = override[1];

        if (overrideState in this.stateToPathParam) {
          this.stateToPathParam[overrideState] = overridePath;
        }
      });
    }
  }

  getStateFromPathParam(pathParam: string): OnboardingStates {
    if (pathParam in this.pathParamToState) {
      return this.pathParamToState[pathParam];
    }
    return OnboardingStates.UNKNOWN;
  }
}

/**
 * @param {OnboardingWrapperOnNextArgs} updateDigitalWealthInputs - digital wealth inputs to fetch the attributes
 * @returns {Partial<OnboardingWrapperOnNextArgs> | undefined} It returns the additionAttrbute object considering the unset values too.
 */
export const getAdditionalAttributesValue = (
  updateDigitalWealthInputs: OnboardingWrapperOnNextArgs | null,
): ManagedProductAdditionalAttributesInput | undefined => {
  /**
   * this record is a map of fields in OnboardingWrapperOnNextArgs that unset a value to the field that's value is being unset.
   * PS: Both key,value should be fields of OnboardingWrapperOnNextArg*/

  const unsetAdditionAttributesFieldMap: Record<string, keyof OnboardingWrapperOnNextArgs> = {
    unsetAgeOfTermination: 'ageOfTermination',
    unsetAssetConsolidationAccountIdentifier: 'assetConsolidationAccountIdentifier',
  };
  if (updateDigitalWealthInputs) {
    let additionalAttributes = {};
    Object.keys(unsetAdditionAttributesFieldMap).forEach(unsetKey => {
      const relatedAttribute = unsetAdditionAttributesFieldMap[unsetKey];
      const unsetKeyValue = !!updateDigitalWealthInputs[unsetKey as keyof OnboardingWrapperOnNextArgs];
      additionalAttributes = {
        ...additionalAttributes,
        [relatedAttribute]: unsetKeyValue ? undefined : updateDigitalWealthInputs[relatedAttribute],
      };
    });
    if (Object.values(additionalAttributes).every(attribute => !attribute)) {
      return undefined;
    }
    return additionalAttributes;
  } else {
    return undefined;
  }
};
