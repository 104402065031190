import { GetPaperworkContent_all_paperwork_items } from '../contentstack/__generated__/query.v2';
import { CorporateAccountHolderNumbers } from '../context';
import { ComponentTypes } from '../Section/Questions/utils';
import { ManagedProductPaperwork, TrustInformation } from '../symphony';
import { PaperworkData, PaperworkPage, Props } from '../types';

import {
  AccountHolderType,
  EntityProfileType,
  FinancialAccountType,
  PaperworkType,
  TrustProfileType,
} from '~/__generated__';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { isCorporateAccountType, isJointAccountType, isTrustAccountType } from '~/utils/account';

export const isValidNumberInput = (value: string) => {
  return isNaN((value as unknown) as number) || value.includes('.');
};

export const isMasked = (value?: string): boolean => {
  return !!value && value.includes('*');
};

export const getPaperworkBooleanFieldValue = (record: Record<string, any>, key: string): boolean => {
  return record[key] !== undefined && record[key] !== null && record[key] !== ''
    ? typeof record[key] === 'object'
      ? record[key][key] !== ''
        ? (record[key][key] as boolean)
        : false
      : (record[key] as boolean)
    : false;
};

export const mandatoryCheckboxValidation = (value: any): boolean =>
  typeof value === 'object' ? Object.values(value)[0] === true : value === true;

export const SELECT_DROPDOWN_ADD_NEW_VALUE = 'SELECT_DROPDOWN_ADD_NEW_VALUE';

export const getSelectContactDropdownItems = ({
  accountType,
  allPaperworkData,
  entityRelationships,
  relatedContactsData,
  relatedEntitiesData,
  page,
  paperworkSymphonyData,
  content,
}: {
  accountType: FinancialAccountType;
  allPaperworkData?: ManagedProductPaperwork[] | null;
  content?: GetPaperworkContent_all_paperwork_items | null;
  entityRelationships: Record<string, string[]>;
  page: PaperworkPage | null;
  paperworkSymphonyData?: ManagedProductPaperwork;
  relatedContactsData?: ManagedProductPaperwork[];
  relatedEntitiesData?: ManagedProductPaperwork[];
}): { isEntityPage: boolean; selectContactDropdownItems: DropdownItem<string>[] } => {
  const items: DropdownItem<string>[] = [];
  let isEntityPage = false;
  const getPartyPersonItem = (c: ManagedProductPaperwork) => ({
    value: c.party?.id ?? '',
    label: `${c.party?.partyPerson?.givenName ?? ''} ${c.party?.partyPerson?.familyName ?? ''}`,
  });
  if (page && (relatedContactsData?.length || relatedEntitiesData?.length)) {
    const isTrustAccount = isTrustAccountType(accountType);
    isEntityPage =
      (isTrustAccount && page.trustProfileType === TrustProfileType.TRUST) || isCorporateAccountType(accountType);
    const trust = allPaperworkData?.find(p => p.trustInformation?.trustProfileType === TrustProfileType.TRUST);
    (isEntityPage ? relatedEntitiesData : relatedContactsData)?.forEach(c => {
      if (c.party) {
        const isValidOption =
          !items.find(el => el.value === c.party?.id) &&
          !allPaperworkData?.find(
            p =>
              c.party &&
              p.additionalAttributes?.selectedContactPartyId &&
              p.additionalAttributes.selectedContactPartyId === c.party.id &&
              c.party.id !== paperworkSymphonyData?.additionalAttributes?.selectedContactPartyId,
          );
        if (isValidOption) {
          if (isEntityPage) {
            items.push({ value: c.party.id ?? '', label: c.party.partyBusinessEntity?.name ?? '' });
          } else {
            /**
             * TODO: since we are handling account holders in partner repository
             * for corporate this logic stands for now, may need update when we bring account
             * holder section in core.
             */
            if (
              trust?.additionalAttributes?.selectedContactPartyId &&
              entityRelationships[trust.additionalAttributes.selectedContactPartyId].includes(c.party.id ?? '')
            ) {
              items.push(getPartyPersonItem(c));
            } else if (!trust) {
              items.push(getPartyPersonItem(c));
            }
          }
        }
      }
    });
  }
  items.push({ label: content?.select_contact_add_new_label ?? '', value: SELECT_DROPDOWN_ADD_NEW_VALUE });
  return { isEntityPage, selectContactDropdownItems: items };
};

export const getCurrentPaperworkSymphonyData = ({
  allPaperworkData,
  isCorporateAccount,
  isSecondaryPaperwork,
  isTrustAccount,
  activePage,
  data,
}: {
  activePage: number;
  allPaperworkData?: ManagedProductPaperwork[] | null;
  data: PaperworkData;
  isCorporateAccount: boolean;
  isSecondaryPaperwork: boolean;
  isTrustAccount: boolean;
}) => {
  if (allPaperworkData) {
    if (isTrustAccount && isSecondaryPaperwork) {
      return allPaperworkData.filter(p => p.paperworkType === PaperworkType.SECONDARY)[
        activePage === 0
          ? activePage
          : Math.ceil((activePage + (data.numberOfPagesPerTrustee ?? 1) - 1) / (data.numberOfPagesPerTrustee ?? 1)) - 1
      ];
    }
    if (isCorporateAccount && isSecondaryPaperwork && allPaperworkData.length > 1) {
      /**
       *  For corporate account in allPaperworkdata array, we sort the available information
       *  such that index 0, is always the entity/corporation itself, index 1 is alway primary Authorized
       *  individual, followed by all other secondary Authorized individuals and lastly beneficial owners/control person.
       *  Currently, for all account types we only have one page per additional stakeholder, except primary.
       *  So configs for secondary accounts are mapped out to be in the same order as allPaperworkdata.
       */
      return allPaperworkData.filter(item => item.paperworkType === PaperworkType.SECONDARY)[
        activePage ? activePage - 1 : 0
      ];
    }
    return allPaperworkData.find(
      p => p.paperworkType === (isSecondaryPaperwork ? PaperworkType.SECONDARY : PaperworkType.PRIMARY),
    );
  }
  return;
};

/**
 * The function is used to sort paperwork in the order Entity
 * Primary and rest of the account holders
 * @param paperwork all Paperwork information saved so fat
 * @returns sorted paperwork
 */
export const getCorporateAllPaperworkData = (
  paperwork?: ManagedProductPaperwork[] | null,
): ManagedProductPaperwork[] | null | undefined => {
  const entityPaperwork = paperwork?.find(
    p => p.id && p.additionalAttributes?.entityProfileType === EntityProfileType.ENTITY,
  );
  const primaryPaperwork = paperwork?.find(p => p.id && p.paperworkType === PaperworkType.PRIMARY);
  const accountHolders = [
    ...(paperwork?.filter(
      p =>
        p.paperworkType === PaperworkType.SECONDARY &&
        p.party?.partyBusinessEntity?.attributes?.accountHolderType?.includes(AccountHolderType.AUTHORIZED_INDIVIDUAL),
    ) ?? []),
    ...(paperwork?.filter(
      p =>
        p.paperworkType === PaperworkType.SECONDARY &&
        p.additionalAttributes?.entityProfileType !== EntityProfileType.ENTITY &&
        !p.party?.partyBusinessEntity?.attributes?.accountHolderType?.includes(AccountHolderType.AUTHORIZED_INDIVIDUAL),
    ) ?? []),
  ];
  // This ensures for 1st paperwork is entity,second is primary and thereafter other account holders
  return entityPaperwork && primaryPaperwork ? [entityPaperwork, primaryPaperwork, ...accountHolders] : paperwork;
};

export const getAllPaperworkData = (
  hasTrustSection: boolean,
  isCorporateAccount: boolean,
  paperwork?: ManagedProductPaperwork[] | null,
): ManagedProductPaperwork[] | null | undefined => {
  const tempPaperworkData = paperwork;
  if (isCorporateAccount) {
    return getCorporateAllPaperworkData(tempPaperworkData);
  }
  if (hasTrustSection) {
    // If the paperwork has trust section then add the trust information data to primary account profile.
    // Usually we have a whole page for trust information, if there is a trust section that means in UI we are
    // showing the trust information along with the primary profile.
    const primaryPaperworkIndex = tempPaperworkData?.findIndex(p => p.paperworkType === PaperworkType.PRIMARY);
    if (
      primaryPaperworkIndex !== undefined &&
      primaryPaperworkIndex !== -1 &&
      tempPaperworkData?.[primaryPaperworkIndex]
    ) {
      tempPaperworkData[primaryPaperworkIndex].trustInformation = tempPaperworkData.find(
        p =>
          p.paperworkType === PaperworkType.SECONDARY &&
          p.trustInformation?.trustProfileType === TrustProfileType.TRUST,
      )?.trustInformation as TrustInformation;
    }
  }
  return tempPaperworkData;
};

export const getNumberOfCorporateAccountHolders = (
  allPaperworkData?: ManagedProductPaperwork[] | null,
): CorporateAccountHolderNumbers => {
  const AI: { isAuthorizedIndividualEntity: boolean; isOnlyAuthorizedIndividual: boolean }[] = [];
  const NON_AI: { isStakeHolderEntity: boolean }[] = [];
  allPaperworkData?.forEach(item => {
    if (item.additionalAttributes?.entityProfileType !== EntityProfileType.ENTITY) {
      if (
        item.party?.partyBusinessEntity?.attributes?.accountHolderType?.includes(
          AccountHolderType.AUTHORIZED_INDIVIDUAL,
        )
      ) {
        AI.push({
          isAuthorizedIndividualEntity:
            item.additionalAttributes?.entityProfileType === EntityProfileType.STAKEHOLDER_ENTITY,
          isOnlyAuthorizedIndividual: item.party.partyBusinessEntity.attributes.accountHolderType.length === 1,
        });
      } else {
        NON_AI.push({
          isStakeHolderEntity: item.additionalAttributes?.entityProfileType === EntityProfileType.STAKEHOLDER_ENTITY,
        });
      }
    }
  });
  return {
    AI,
    NON_AI,
  };
};

export const fetchPages = async (
  accountType: FinancialAccountType,
  allPaperworkData: ManagedProductPaperwork[] | null | undefined,
  getPaperworkData: Props['getPaperworkData'],
): Promise<PaperworkData | undefined> => {
  if (getPaperworkData) {
    const trustInformationFromPaperwork = allPaperworkData?.find(
      a => a.trustInformation?.trustProfileType === TrustProfileType.TRUST,
    )?.trustInformation;
    const isCorporateAccount = isCorporateAccountType(accountType);
    const numberOfCorporateAccountHolders = isCorporateAccount
      ? getNumberOfCorporateAccountHolders(allPaperworkData)
      : undefined;
    const corporateTaxation = allPaperworkData?.find(
      item => item.additionalAttributes?.entityProfileType === EntityProfileType.ENTITY,
    )?.party?.partyBusinessEntity?.attributes?.taxation;
    const totalTrustees = trustInformationFromPaperwork?.numberOfTrustees ?? 1;
    const isTrusteeGrantor = trustInformationFromPaperwork?.isTrusteeGrantor ?? false;
    const result = await getPaperworkData(
      accountType,
      totalTrustees,
      undefined,
      numberOfCorporateAccountHolders,
      isTrusteeGrantor,
      corporateTaxation,
    );
    return { ...result, numberOfCorporateAccountHolders, numberOfSecondaryTrustees: totalTrustees - 1 };
  }

  return undefined;
};

export const getPagesFromDataProp = (
  data: PaperworkData,
  accountType: FinancialAccountType,
  allPaperworkData: ManagedProductPaperwork[] | null | undefined,
): PaperworkData => {
  let totalTrustees = 1;
  /**
   * This will need clean up later, the logic of deciding paperwork config should reside in Partner Repos instead of core.
   * So that core is only responsible for rendering the page and does not require complex logic to filter
   * out what all information to show.
   */
  let sortedPages = data.pages.slice().sort((a, b) => (a.index > b.index ? 1 : -1));

  if (isTrustAccountType(accountType)) {
    totalTrustees =
      allPaperworkData?.find(a => a.trustInformation?.trustProfileType === TrustProfileType.TRUST)?.trustInformation
        ?.numberOfTrustees ?? 1;
    sortedPages = sortedPages.filter(
      a =>
        !!a.customComponent ||
        (a.index < 2 + (totalTrustees - 1) * (data.numberOfPagesPerTrustee ?? 1) && isTrustAccountType(a.accountType)),
    );
  } else {
    sortedPages = sortedPages.filter(a => !isTrustAccountType(a.accountType));
    if (!isJointAccountType(accountType)) {
      sortedPages = sortedPages.filter(a => !isJointAccountType(a.accountType));
    }
  }
  // If some pages are filtered out this will fix the index.
  return { pages: sortedPages.map((a, index) => ({ ...a, index })), numberOfSecondaryTrustees: totalTrustees - 1 };
};

export const getDataPointSymphonyMap = (
  accountType: FinancialAccountType,
  pages: PaperworkPage[],
  page: PaperworkPage | null,
  formData: Record<string, any>,
  activePage: number,
): {
  dataPointSymphonyMap: Record<string, string>;
  dataPointsInCurrentPage: string[];
  result: Record<string, any>;
} => {
  let dataPointSymphonyMap: Record<string, string> = {};
  let result: Record<string, any> = formData;
  let dataPointsInCurrentPage: string[] = [];
  pages.forEach((p, index) => {
    p.sections?.forEach(section => {
      if (section.customSectionComponent && section.dataPointSymphonyMap) {
        dataPointSymphonyMap = { ...dataPointSymphonyMap, ...section.dataPointSymphonyMap };
        if (index === activePage) {
          dataPointsInCurrentPage = dataPointsInCurrentPage.concat(Object.keys(section.dataPointSymphonyMap));
        }
      } else {
        section.questions?.order.orderSteps.forEach(step => {
          if (index === activePage) {
            dataPointsInCurrentPage.push(step.dataPointKey);
          }
          // create datapoint symphony mapping for each check boxes in check box group.
          if (step.componentType === ComponentTypes.CheckboxGroup && result[step.dataPointKey]) {
            step.checkBoxes?.map(checkBox => {
              dataPointSymphonyMap[checkBox.dataPointKey] = checkBox.symphonyMapping;
              result[checkBox.dataPointKey] = result[step.dataPointKey][checkBox.dataPointKey];
            });
            delete result[step.dataPointKey];
          }
          if (step.componentType === ComponentTypes.Hidden) {
            delete result[step.dataPointKey];
          } else if (!step.accountTypes || step.accountTypes.includes(accountType)) {
            dataPointSymphonyMap[step.dataPointKey] = step.symphonyMapping;
          }
        });
      }
    });
  });
  if (page?.defaultValues) {
    dataPointSymphonyMap = { ...dataPointSymphonyMap, ...page.defaultValues.dataPointKeySymphonyMapping };
    result = { ...result, ...page.defaultValues.values };
    dataPointsInCurrentPage = [
      ...dataPointsInCurrentPage,
      ...Object.keys(page.defaultValues.dataPointKeySymphonyMapping),
    ];
  }
  return { dataPointSymphonyMap, result, dataPointsInCurrentPage };
};
