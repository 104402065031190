import { useEffect, useState } from 'react';

import { useGetRelevantDocuments } from '../contentstack';

import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface RelevantDocumentVariables {
  contentOptions: ContentOptions;
}

export interface TableContent {
  accountType: string;
  requiredDocuments: string;
}

export interface RelevantDocumentData {
  accountTypeTableLabel: string;
  close: string;
  linkText: string;
  modalTitle: string;
  requiredDocumentsLabel: string;
  tableContent: TableContent[];
}

export const useRelevantDocuments = ({
  contentOptions,
}: RelevantDocumentVariables): AsyncResult<RelevantDocumentData> => {
  const [state, setState] = useState<AsyncResult<RelevantDocumentData>>({
    loading: true,
  });

  const {
    data: relevantDocumentData,
    loading: relevantDocumentLoading,
    error: relevantDocumentError,
  } = useGetRelevantDocuments({
    variables: contentOptions,
  });

  useEffect(() => {
    if (relevantDocumentLoading) {
      return;
    }
    if (relevantDocumentError) {
      setState({ loading: false, error: relevantDocumentError });
      return;
    }

    const content = relevantDocumentData?.all_relevant_document_modal?.items?.[0];

    setState({
      loading: false,
      data: {
        accountTypeTableLabel: content?.accounttype_table_label ?? '',
        requiredDocumentsLabel: content?.required_documents_label ?? '',
        modalTitle: content?.modal_title ?? '',
        linkText: content?.documents_by_accounttype ?? '',
        close: content?.close ?? '',
        tableContent:
          content?.table_content?.map(value => {
            return {
              accountType: value?.accounttype ?? '',
              requiredDocuments: value?.required_documents ?? '',
            };
          }) ?? [],
      },
    });
  }, [relevantDocumentData, relevantDocumentData, relevantDocumentError]);

  return state;
};
