import React, { useEffect, useState } from 'react';

import { useGetRetakeRTQClientDocusignContent } from './contentstack';
import { useGetRetakeRTQDocusignClientStatus } from './symphony';
import { GetRetakeRTQDocusignClientStatus_managedProduct_legalDocuments_signees } from './symphony/__generated__/query.v2';

import { LegalDocumentStatus } from '~/__generated__';
import { Alert } from '~/components/ui/Alert';
import { Box, Button, Skeleton } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { useInterval } from '~/utils/interval';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  handleButtonClick?: () => void;
  managedProductId: string;
  onClientDocusignCompleted: () => void;
  onClientDocusignDeclined: () => void;
  partyId: string;
  partyIdFA?: string;
}
const pollFrequency = 20 * 1000; // 20 seconds
export const ClientDocusign: React.FC<Props> = ({
  contentOptions,
  dataQa = 'client-docusign',
  handleButtonClick,
  managedProductId,
  onClientDocusignCompleted,
  onClientDocusignDeclined,
  partyId,
  partyIdFA,
}) => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetRetakeRTQClientDocusignContent({
    variables: contentOptions,
  });

  const [isStatusPending, setIsStatusPending] = useState(true);
  const [docusignClientsList, setDocusignClientsList] = useState<
    GetRetakeRTQDocusignClientStatus_managedProduct_legalDocuments_signees[]
  >();
  // This state is to keep track of planUpdateWorkflowId for the case where client declines as the PUW will be cancelled and the next poll to get the status will return undefined for planUpdateWorkflowId
  const [planUpdateWorkflowId, setPlanUpdateWorkflowId] = useState('');

  const {
    data: clientStatusData,
    loading: clientStatusLoading,
    error: clientStatusError,
    refetch,
  } = useGetRetakeRTQDocusignClientStatus({
    variables: { managedProductId, partyId },
  });

  // long polling
  useInterval(
    async () => {
      await refetch();
      setIsStatusPending(true);
    },
    isStatusPending ? pollFrequency : null,
  );

  useEffect(() => {
    const puwId = clientStatusData?.managedProduct?.planUpdateWorkflows[0]?.id;
    if (puwId) {
      setPlanUpdateWorkflowId(puwId);
    }
  }, [clientStatusData?.managedProduct?.planUpdateWorkflows, clientStatusLoading]);

  useEffect(() => {
    if (planUpdateWorkflowId && !clientStatusLoading) {
      const legalDocuments = clientStatusData?.managedProduct?.legalDocuments?.find(item =>
        item.associatedEntities?.planUpdateWorkflowIds.includes(planUpdateWorkflowId),
      );
      if (legalDocuments) {
        const clientList = legalDocuments.signees.filter(item => item.partyId !== partyIdFA);
        setDocusignClientsList(clientList);
        if (clientList.every(client => client.status === LegalDocumentStatus.SUCCEEDED)) {
          setIsStatusPending(false);
          onClientDocusignCompleted();
        } else if (clientList.some(client => client.status === LegalDocumentStatus.DECLINED)) {
          setIsStatusPending(false);
          onClientDocusignDeclined();
        } else {
          setIsStatusPending(true);
        }
      }
    }
  }, [
    clientStatusData,
    clientStatusLoading,
    planUpdateWorkflowId,
    onClientDocusignCompleted,
    onClientDocusignDeclined,
    partyIdFA,
  ]);

  useEffect(() => {
    if (clientStatusError) {
      console.error(clientStatusError);
    }
  }, [clientStatusError]);

  const content = contentData?.all_retake_rtq_docusign?.items?.[0]?.client_docusign;
  return (
    <Box data-qa={dataQa} sx={{ textAlign: 'center', my: 6 }}>
      {contentLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : contentError || clientStatusError ? (
        <Alert contentOptions={contentOptions} error={contentError || clientStatusError} severity="error" />
      ) : (
        <>
          <img alt="" src={content?.imageConnection?.edges?.[0]?.node?.url ?? ''} />
          <Typography color="primary.main" data-qa={`${dataQa}-heading`} sx={{ mb: 1, mt: 4 }} variant="h3">
            {content?.heading}
          </Typography>
          <RteContent
            config={{
              clientEmail: docusignClientsList
                ? docusignClientsList.map((signee, idx) => (
                    <>
                      {!!idx && (
                        <Typography component="span" key={`${signee.partyId}-conjunction`}>
                          {idx === docusignClientsList.length - 1 ? content?.conjunction ?? ' and ' : ', '}
                        </Typography>
                      )}
                      <Typography color="primary.main" component="span" key={`${signee.partyId}-email`}>
                        <a href={`mailto:${signee.email}`}>{signee.email}</a>
                      </Typography>
                    </>
                  ))
                : null,
            }}
            data={content?.body ?? ''}
            data-qa={`${dataQa}-body`}
            sx={{ mb: 9 }}
          />

          <Button color="primary" data-qa={`${dataQa}-cta`} onClick={() => handleButtonClick?.()} variant="contained">
            {content?.primary_cta}
          </Button>
        </>
      )}
    </Box>
  );
};
