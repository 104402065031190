import { useMemo } from 'react';

import { useGetAdvisorMetricValues, useGetPartnerMetricValues } from '../symphony';
import { GetPartnerMetricValues } from '../symphony/__generated__/query.v2';

import {
  formatMetricValueAsInteger,
  getAdvisorMetricValuesVariables,
  getAumData,
  getHomeOfficeTableData,
  getMetricValueByMetricId,
  getTotalPages,
} from './utils';

import { SortConfig } from '~/components/ui/BasicTable';
import { HomeOfficeView } from '~/containers/HomeOfficeDashboard/config';
import {
  HomeOfficeFilterValues,
  HomeOfficeTableData,
  HomeOfficeTimeFrameValues,
  MetricsIds,
} from '~/containers/HomeOfficeDashboard/types';
import { ContentOptions } from '~/utils/contentstack';
import { toSymphonyDate } from '~/utils/symphony';
import { AsyncResult } from '~/utils/types';

interface Props {
  contentOptions: ContentOptions;
  currentPage: number;
  filterValues: HomeOfficeFilterValues;
  recordsPerPage: number;
  selectedParty?: string | null;
  sortConfig: SortConfig;
  timeFrameValues: HomeOfficeTimeFrameValues;
  view: HomeOfficeView;
}

interface PartnerMetricsData {
  totalAccountsOpened: string;
  totalAum: {
    change?: string;
    isPositive?: boolean;
    value: string;
  };
}
interface HomeOfficeMetricsData {
  homeOfficeTableData?: HomeOfficeTableData[];
  homeOfficeTableDataLoading: boolean;
  paginationContext: {
    limit: number;
    totalPages: number;
    totalRecords: number;
  };
  partnerData: PartnerMetricsData;
  partnerMetricsLoading: boolean;
}

export const useGetHomeOfficeMetricsData = ({
  contentOptions,
  currentPage,
  recordsPerPage,
  selectedParty,
  sortConfig,
  timeFrameValues,
  filterValues,
  view,
}: Props): AsyncResult<HomeOfficeMetricsData> => {
  const interval = useMemo(() => {
    return {
      startDate: toSymphonyDate(timeFrameValues.startDate, contentOptions),
      endDate: toSymphonyDate(timeFrameValues.endDate, contentOptions),
    };
  }, [timeFrameValues, contentOptions]);

  const advisorMetricVariables = useMemo(
    () =>
      getAdvisorMetricValuesVariables({
        selectedParty,
        filterValues,
        view,
        currentPage,
        recordsPerPage,
        sortConfig,
      }),
    [currentPage, filterValues, recordsPerPage, selectedParty, sortConfig, view],
  );

  const {
    data: partnerMetricsData,
    loading: partnerMetricsLoading,
    error: partnerMetricsError,
  } = useGetPartnerMetricValues({
    variables: interval,
    fetchPolicy: 'no-cache',
  });

  const {
    data: advisorMetricValuesData,
    loading: advisorMetricValuesLoading,
    error: advisorMetricValuesError,
  } = useGetAdvisorMetricValues({
    variables: {
      ...interval,
      ...advisorMetricVariables,
    },
    fetchPolicy: 'no-cache',
  });

  const error = useMemo(() => partnerMetricsError || advisorMetricValuesError, [
    partnerMetricsError,
    advisorMetricValuesError,
  ]);

  const data = useMemo(() => {
    const totalRecords = advisorMetricValuesData?.advisorMetricValues.paginationContext.total ?? 0;
    return {
      partnerMetricsLoading,
      homeOfficeTableDataLoading: advisorMetricValuesLoading,
      partnerData: getPartnerData(partnerMetricsData),
      homeOfficeTableData: getHomeOfficeTableData(advisorMetricValuesData?.advisorMetricValues.advisorMetricValues),
      paginationContext: {
        totalRecords,
        limit: recordsPerPage,
        totalPages: getTotalPages({
          limit: recordsPerPage,
          total: totalRecords,
        }),
      },
    };
  }, [advisorMetricValuesData, advisorMetricValuesLoading, partnerMetricsData, partnerMetricsLoading, recordsPerPage]);

  return { loading: false, data, error };
};

const getPartnerData = (partnerMetricsData?: GetPartnerMetricValues): PartnerMetricsData => {
  const partnerTotalAccountsOpenedMetricsValue = getMetricValueByMetricId(
    partnerMetricsData?.partnerMetricValues,
    MetricsIds.PartnerActiveAccountsCount,
  );

  const totalAum = getAumData(
    partnerMetricsData?.partnerMetricValues,
    MetricsIds.PartnerTotalAUM,
    MetricsIds.ChangeInPartnerAUM,
  );

  return {
    totalAccountsOpened: formatMetricValueAsInteger(partnerTotalAccountsOpenedMetricsValue),
    totalAum,
  };
};
