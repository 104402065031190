import { Canvas } from '@react-pdf/renderer';
import React from 'react';

import { Style } from '~/components/pdfs/styles';

interface Props {
  color?: string;
  pdfStyles: Style;
  style?: any;
}

export const Divider: React.FC<Props> = ({ pdfStyles, style, color }) => {
  return (
    <Canvas
      paint={(painter: any, availableWidth: number) =>
        painter
          .moveTo(0, 0)
          .lineTo(availableWidth, 0)
          .strokeColor(color ? color : pdfStyles.dividerColor?.color ?? pdfStyles.border.borderColor)
          .stroke()
      }
      style={{
        width: '100%',
        height: 1,
        ...style,
      }}
    />
  );
};
