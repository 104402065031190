import { Text, View } from '@react-pdf/renderer';
import React from 'react';

import { Divider } from '../../../components/Divider';
import { RiskIndicator } from '../../../components/RiskIndicator';
import { Style } from '../../../styles';

import { RiskBand } from '~/components/RiskSpectrum';

export interface RiskPlaybackContent {
  descriptionLabel: string;
  riskToleranceTitle: string;
  subtitle: string;
  title: string;
}

interface Props {
  content: RiskPlaybackContent;
  pdfStyles: Style;
  riskBandColors: string[];
  riskBandNew: number;
  riskBands: RiskBand[];
}

export const RiskPlayback: React.FC<Props> = ({ content, riskBandNew, riskBands, riskBandColors, pdfStyles }) => {
  const commonTextStyle = {
    fontSize: 10,
    marginTop: '8px',
    marginBottom: '8px',
  };

  const descriptionHtml = riskBands.length > riskBandNew ? riskBands[riskBandNew].description : '';
  const descriptionDiv = document.createElement('div');
  descriptionDiv.innerHTML = descriptionHtml;
  const description = descriptionDiv.textContent || descriptionDiv.innerText || '';

  return (
    <>
      <Text
        style={{
          color: pdfStyles.primaryMainColor.color,
          fontSize: 16,
          margin: '12px 0',
        }}
      >
        {content.title}
      </Text>
      {content.subtitle.length > 0 && (
        <Text style={commonTextStyle}>
          {content.subtitle}{' '}
          <Text style={pdfStyles.riskPlaybackRiskBandText}>
            {riskBands.length > riskBandNew ? riskBands[riskBandNew].label : ''}
          </Text>
          .
        </Text>
      )}

      <View
        style={{
          ...pdfStyles.border,
          padding: '12px',
          marginTop: '12px',
          marginBottom: '30px',
          textAlign: 'justify',
        }}
      >
        <Text style={{ ...commonTextStyle, margin: '16px 0', ...pdfStyles.riskToleranceTitle }}>
          {content.riskToleranceTitle}
        </Text>
        <View style={{ marginBottom: '12px' }}>
          <RiskIndicator
            pdfStyles={pdfStyles}
            riskBandColors={riskBandColors}
            riskBandNew={riskBandNew}
            riskBands={riskBands}
          />
        </View>
        <Divider pdfStyles={pdfStyles} />
        <Text style={{ marginTop: '12px', marginBottom: '8px', fontSize: 9 }}>{content.descriptionLabel}</Text>
        <Text style={{ fontSize: 8 }}>{description}</Text>
      </View>
    </>
  );
};
