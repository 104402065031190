import React, { FC } from 'react';

import { PerformanceFilterContent } from '../types';

import { PerformanceFilterDropdowns } from '~/components/AccountPerformance/types';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownChangeEvent, ListItem } from '~/components/ui/Dropdown/types';
import { Box, Grid, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { MeasurementName } from '~/hooks/performance/types';

export interface Props {
  accountOptions: ListItem[];
  content: PerformanceFilterContent;
  dataQa?: string;
  handleAccountChange: (event: DropdownChangeEvent) => void;
  handlePerformanceFilterChange: (event: DropdownChangeEvent) => void;
  handleQuarterChange: (event: DropdownChangeEvent) => void;
  performanceFilterOptions: PerformanceFilterDropdowns;
  selectedAccountId: string;
  selectedQuarter: string;
  selectedTimePeriod: MeasurementName;
}

export const AccountPerformanceFilters: FC<Props> = ({
  content,
  dataQa = 'account-performance-filters',
  performanceFilterOptions,
  handlePerformanceFilterChange,
  handleQuarterChange,
  handleAccountChange,
  selectedTimePeriod,
  selectedQuarter,
  selectedAccountId,
  accountOptions,
}) => {
  const {
    palette,
    sfAccountPerformance: { styles, typographyVariants },
  } = useTheme();

  // Default / Initial value for Quarters Dropdown Options.
  const pastQuartersDropdownValue =
    selectedQuarter || (performanceFilterOptions.pastQuarterDropdownOptions[0]?.value ?? '');
  return (
    <Box sx={{ overflowX: 'auto', width: '100%' }}>
      <Grid
        container
        data-qa={`${dataQa}-filters`}
        spacing={2}
        sx={{
          alignItems: 'center',
          flexWrap: 'nowrap',
          minWidth: 'max-content',
        }}
      >
        <Grid item>
          <Typography variant={typographyVariants?.viewPerformance}>{content.labels.viewPerformance}</Typography>
        </Grid>
        <Grid item sx={{ minWidth: '200px' }}>
          <Dropdown
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: styles.maxDropdownHeight,
                  width: '300px',
                },
              },
            }}
            SelectDisplayProps={{
              style: {
                color: styles.dropdownLabelColor || palette.primary.main,
                padding: '14px 32px 14px 16px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            data-qa={`${dataQa}-account-name`}
            fullWidth
            id="account-select"
            items={accountOptions}
            onChange={handleAccountChange}
            value={selectedAccountId}
            variant="accountDetails"
          />
        </Grid>
        <Grid item>
          <Typography variant={typographyVariants?.viewPerformance}>{content.labels.asOf}</Typography>
        </Grid>
        <Grid item>
          <Dropdown
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: styles.maxDropdownHeight,
                },
              },
            }}
            SelectDisplayProps={{
              style: { color: styles.dropdownLabelColor || palette.primary.main, padding: '14px 32px 14px 16px' },
            }}
            items={performanceFilterOptions.timePeriodDropdownOptions}
            onChange={handlePerformanceFilterChange}
            value={selectedTimePeriod}
          />
        </Grid>
        {selectedTimePeriod === MeasurementName.QuarterlyPerformance && (
          <Grid item>
            <Dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: styles.maxDropdownHeight,
                  },
                },
              }}
              SelectDisplayProps={{
                style: { color: styles.dropdownLabelColor || palette.primary.main, padding: '14px 32px 14px 16px' },
              }}
              items={performanceFilterOptions.pastQuarterDropdownOptions}
              onChange={handleQuarterChange}
              value={pastQuartersDropdownValue}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
