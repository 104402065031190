import React, { ComponentProps } from 'react';

import { useGetIncompatiblePortfolioModalContent } from './contentstack';

import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { Button, Skeleton, WarningIcon } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export interface IncompatiblePortfolioContent {
  body: {
    content: string;
    title: string;
  };
  ctas: {
    primary: string;
    secondary: string;
  };
  heading: string;
}

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  onClose: () => void;
  open: boolean;
  openEditRestrictionModal: () => void;
};

export const IncompatiblePortfolioModal: React.FC<Props> = ({
  onClose,
  open,
  openEditRestrictionModal,
  contentOptions,
}) => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetIncompatiblePortfolioModalContent({
    variables: contentOptions,
    skip: !open,
  });
  const content = contentData?.all_incompatible_portfolio_modal?.items?.[0];
  return (
    <>
      {contentLoading ? (
        <Skeleton />
      ) : contentError ? (
        <Alert contentOptions={contentOptions} error={contentError} severity="error" />
      ) : (
        <Modal
          actions={
            <>
              <Button onClick={() => onClose()} variant="outlined">
                {content?.ctas?.secondary}
              </Button>

              <Button onClick={() => openEditRestrictionModal()} variant="contained">
                {content?.ctas?.primary}
              </Button>
            </>
          }
          content={
            <>
              <WarningIcon color="warning" />
              <Typography variant="h3">{content?.body?.title}</Typography>
              <RteContent data={content?.body?.content ?? ''} py={4} />
            </>
          }
          onClose={onClose}
          open={open}
          title={content?.heading}
        />
      )}
    </>
  );
};
