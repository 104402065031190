import React from 'react';

import { Divider } from '~/components/ui/Divider';
import { TableCell, TableRow } from '~/components/ui/mui';

const lineStyle = { backgroundColor: 'rgba(0, 0, 0, 0.12)' };

export const HorizontalLine = () => <Divider sx={{ ...lineStyle, mt: 0.5 }} />;

export const TableHorizontalLine = () => (
  <TableRow>
    <TableCell size="small" sx={{ borderBottom: 'none', px: 0, py: 0.25 }}>
      <Divider sx={{ ...lineStyle }} />
    </TableCell>
    <TableCell size="small" sx={{ borderBottom: 'none', px: 0, py: 0.25 }}>
      <Divider sx={{ ...lineStyle }} />
    </TableCell>
  </TableRow>
);
