import { isAfter, isValid, toDate } from 'date-fns';
import { ComponentProps } from 'react';
import { RegisterOptions, Validate } from 'react-hook-form';

import { ManagedProductPaperworkWithFreeFormId } from '../../hooks/useGetPaperworkData';
import { DateQuestion } from '../../Section/Question/DateQuestion';

import { ManagedProductPaperwork } from '~/containers/Paperwork/symphony';
import { isoDateFormat } from '~/utils/format';

export const defaultRegulatoryExchangeAffiliationTypeValue = 'FINRA';
export const regulatorySameAsEmployerNoValue = 'data_point:regulatory_same_as_employer:single_option_no';
export const regulatorySameAsEmployerYesValue = 'data_point:regulatory_same_as_employer:single_option_yes';
export const designateTrustedContactNoValue = 'data_point:designate_trusted_contact:single_option_no';
export const designateTrustedContactYesValue = 'data_point:designate_trusted_contact:single_option_yes';
export const designatePrimaryBeneficiaryNoValue = 'data_point:designate_primary_beneficiary:single_option_no';
export const designatePrimaryBeneficiaryYesValue = 'data_point:designate_primary_beneficiary:single_option_yes';

export const commonDefaultDateQuestionProps: Partial<ComponentProps<typeof DateQuestion>> = {
  datePickerProps: {
    minDate: new Date('1899-12-31'),
  },
};
export const commonDefaultDateValidations: Record<string, Validate> = {
  isDate: value => (value ? isValid(toDate(value)) : true),
  minDate: value => (value ? isAfter(value, new Date('1899-12-31')) : true),
};

export const requiredValidation = (require: boolean): Partial<RegisterOptions> => {
  return require ? { required: true } : {};
};

export const isMasked = (value: string | null | undefined): boolean => {
  return !!value && value.includes('*');
};

export const getPaperworkBooleanFieldValue = (value: any, defaultValue: boolean | null = null): boolean | null => {
  if (value === undefined || value === null || typeof value !== 'boolean') {
    return defaultValue;
  }

  return value;
};

export const getPaperworkStringFieldValue = (value: any, defaultValue: string | null = null): string | null => {
  if (value === undefined || value === null || typeof value !== 'string') {
    return defaultValue;
  }

  return value;
};

export const getPaperworkEnumFieldValue = <T extends string, TEnumValue extends string>(
  enumeration: { [key in T]: TEnumValue },
  value: any,
  defaultValue: TEnumValue | null = null,
): TEnumValue | null => {
  if (value === undefined || value === null || typeof value !== 'string') {
    return defaultValue;
  }

  return Object.values<TEnumValue>(enumeration).find(en => en.toString() === value) ?? defaultValue;
};

export const getPaperworkDateFieldValueAsIsoString = (
  value: any,
  defaultValue: string | null = null,
): string | null => {
  if (value === undefined || value === null) {
    return defaultValue;
  }

  if (typeof value === 'string' || value instanceof Date) {
    return isoDateFormat(value);
  }

  return defaultValue;
};

export const getPaperworkNumberFieldValue = (
  value: any,
  asInteger = true,
  defaultValue: number | null = null,
): number | null => {
  if (value === undefined || value === null) {
    return defaultValue;
  }

  if (typeof value === 'string') {
    return asInteger ? parseInt(value, 10) : parseFloat(value);
  }

  if (typeof value === 'number') {
    return value;
  }

  return defaultValue;
};

export const filterValidationRules = (
  validationRules: RegisterOptions,
  hidden: boolean,
  isPaperworkSaving: boolean,
): RegisterOptions => {
  if (hidden) {
    return {};
  }

  if (isPaperworkSaving) {
    const { required, ...rest } = validationRules;
    return rest;
  }

  return validationRules;
};

// TODO - should this function be made configurable i.e. passed in via the config creator, instead of being a static util, and based on account type?
export const getPaperworkDataForPrefill = (
  data: {
    allPaperworkDataAvailableForPrefill?: ManagedProductPaperwork[];
    partyId: string;
    savedPaperworkData?: ManagedProductPaperworkWithFreeFormId;
  },
  paperworkFreeFormId?: string,
  selectedPartyIdForPrefill?: string,
): ManagedProductPaperwork | undefined => {
  // If we have additional paperwork data for prefill,
  // and we have an indicator for which party's data we want to use for prefill,
  // we use it for prefill either wholly or by merging with the saved data
  // Otherwise we have the case where we return the current party's information for prefill by default,
  // which can be prefilled if we are prefilling a question for the 'individual' themselves
  if (data.savedPaperworkData?.additionalAttributes?.selectedContactPartyId || selectedPartyIdForPrefill) {
    const availablePaperworkData =
      data.allPaperworkDataAvailableForPrefill?.find(
        pd => pd.party?.id === data.savedPaperworkData?.additionalAttributes?.selectedContactPartyId,
      ) ?? data.allPaperworkDataAvailableForPrefill?.find(pd => pd.party?.id === selectedPartyIdForPrefill);

    if (availablePaperworkData) {
      if (data.savedPaperworkData) {
        // TODO - merge the available data with the current savedPaperworkData - this is not needed until we start supporting prefill from additional sources in Core
        return {
          ...availablePaperworkData,
        };
      } else {
        return availablePaperworkData;
      }
    } else {
      return data.savedPaperworkData;
    }
  } else if (data.savedPaperworkData) {
    return data.savedPaperworkData;
  } else if (
    // TODO - check if this is the right way to determine if we are prefilling for the individual?
    paperworkFreeFormId === 'individual' &&
    data.allPaperworkDataAvailableForPrefill?.find(pd => pd.party?.id === data.partyId)
  ) {
    return data.allPaperworkDataAvailableForPrefill.find(pd => pd.party?.id === data.partyId);
  } else {
    return;
  }
};
