import { useEffect, useState } from 'react';

import { PortfolioDetailsContent } from '~/components/modals/AssetAllocation';
import { useGetPortfolioDetailsContent } from '~/components/PortfolioDetails/contentstack';
import { useGetAssetClassContent } from '~/hooks/asset-classes/contentstack';
import { GetAssetClassContent_all_asset_class_data_items as AssetClassContentItem } from '~/hooks/asset-classes/contentstack/__generated__/query.v2';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface PortfolioSelectionCardAssetDataVariables {
  contentOptions: ContentOptions;
}

export interface PortfolioSelectionCardAssetData {
  assetClass: (AssetClassContentItem | null)[];
  content: PortfolioDetailsContent;
}

export const useGetPortfolioSelectionCardAssetData = ({
  contentOptions,
}: PortfolioSelectionCardAssetDataVariables): AsyncResult<PortfolioSelectionCardAssetData> => {
  const [state, setState] = useState<AsyncResult<PortfolioSelectionCardAssetData>>({
    loading: true,
    error: undefined,
    data: undefined,
  });

  const { data: contentData, loading: contentDataLoading, error: contentDataError } = useGetPortfolioDetailsContent({
    variables: contentOptions,
  });

  const {
    data: assetClassContentData,
    loading: assetClassContentLoading,
    error: assetClassContentError,
  } = useGetAssetClassContent({
    variables: contentOptions,
  });

  useEffect(() => {
    if (contentDataError || assetClassContentError) {
      setState({ loading: false, error: contentDataError ?? assetClassContentError });
      return;
    }

    if (contentDataLoading || assetClassContentLoading) {
      return;
    }

    const assetData = assetClassContentData?.all_asset_class_data?.items || [];
    const contentItem = contentData?.all_portfolio_details?.items?.[0];
    const content = {
      assetAllocation: contentItem?.asset_allocation ?? '',
      bondLabel: contentItem?.bond_label ?? '',
      close: contentItem?.close ?? '',
      stockLabel: contentItem?.stock_label ?? '',
    };

    setState({
      data: {
        assetClass: assetData,
        content,
      },
      loading: false,
    });
  }, [
    contentData,
    contentDataLoading,
    contentDataError,
    assetClassContentData,
    assetClassContentError,
    assetClassContentLoading,
  ]);

  return state;
};
