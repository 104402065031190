import { Page, Text, View } from '@react-pdf/renderer';
import { Style as ReactStyle } from '@react-pdf/types';
import { format, parseISO, startOfYear } from 'date-fns';
import React from 'react';

import { Footer } from '../../../components/Footer';
import { Header } from '../../../components/Header';
import { FontSizes, Style } from '../../../styles';

import { RteContent } from '~/components/ui/redactor/RteContent';

interface SubPointList {
  subPointHeading: string;
  subPointText: string;
}

interface DisclosureList {
  heading: string | null;
  pageNumber: number;
  subPointList: SubPointList[];
  text: string;
}
export interface DisclosureContent {
  continuedTitle: string;
  disclosureList: DisclosureList[];
  footerText: string;
  logo: string;
  pageName: string;
  title: string;
}

interface Props {
  accountNumber?: string;
  disclosureAtBottom: boolean;
  disclosureContent: DisclosureContent;
  endDate: string;
  inceptionDate: string;
  pdfStyles: Style;
  startDate: string;
}

export const Disclosure: React.FC<Props> = ({
  disclosureAtBottom,
  disclosureContent,
  pdfStyles,
  startDate,
  endDate,
  inceptionDate,
  accountNumber,
}) => {
  const { footerText, logo, pageName } = disclosureContent;
  const formatDate = 'MMM dd, yyyy';
  const pageNumberArray = new Set(disclosureContent.disclosureList.map(item => item.pageNumber));
  const subsequentPageNumbers = Array.from(pageNumberArray)
    .sort((a, b) => a - b)
    .slice(1);
  const boldFontFamily = pdfStyles.disclosure.fontFamilyBold;

  const titleTextStyle = {
    color: pdfStyles.primaryMainColor.color,
    fontSize: 12,
    marginBottom: '12px',
    marginTop: '40px',
  };

  const sectionTextStyle: ReactStyle = {
    textAlign: 'left',
    margin: '40px',
    position: disclosureAtBottom ? 'absolute' : 'relative',
    bottom: '40px',
  };

  const generateDisclosureSection = (item: DisclosureList) => {
    return (
      <>
        {item.heading && (
          <Text
            style={{
              fontSize: FontSizes.Small,
              margin: '2px 0',
              fontWeight: 700,
              fontFamily: boldFontFamily,
            }}
          >
            {item.heading}
          </Text>
        )}
        <Text
          style={{
            fontSize: FontSizes.Small,
            margin: '2px 0',
            fontWeight: 400,
            ...pdfStyles.disclosure,
          }}
        >
          <RteContent
            config={{
              quarterStart: format(parseISO(startDate), formatDate),
              quarterEnd: format(parseISO(endDate), formatDate),
              yearStart: format(startOfYear(parseISO(endDate)), formatDate),
              inceptionDate: format(parseISO(inceptionDate), formatDate),
            }}
            data={item.text}
          />
        </Text>
        {item.subPointList.length > 0 &&
          item.subPointList.map(point => (
            <>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  marginRight: '40px',
                }}
              >
                <Text
                  style={{
                    fontSize: FontSizes.Small,
                    margin: '2px 0 2px 32px',
                    fontWeight: 700,
                    fontFamily: boldFontFamily,
                  }}
                >
                  {point.subPointHeading}
                  <Text
                    style={{
                      fontSize: FontSizes.Small,
                      margin: '2px 0 2px 12px',
                      fontWeight: 400,
                      ...pdfStyles.disclosure,
                    }}
                  >
                    {point.subPointText}
                  </Text>
                </Text>
              </View>
            </>
          ))}
      </>
    );
  };
  return (
    <>
      <Page size="LETTER" wrap={false}>
        <Header accountNumber={accountNumber} logoUrl={logo} pageName={pageName} pdfStyles={pdfStyles} />
        <View style={sectionTextStyle}>
          <View>
            <Text style={titleTextStyle}>{disclosureContent.title}</Text>
            {disclosureContent.disclosureList.length > 0 &&
              disclosureContent.disclosureList
                .filter(dis => dis.pageNumber === 1)
                .map(item => generateDisclosureSection(item))}
          </View>
        </View>
        <Footer pdfStyles={pdfStyles} text={footerText} />
      </Page>
      {subsequentPageNumbers.length > 0 &&
        subsequentPageNumbers.map((page: number) => {
          const pageContent = disclosureContent.disclosureList.filter(dis => dis.pageNumber === page);
          return (
            <Page key={page} size="LETTER" wrap={false}>
              <Header accountNumber={accountNumber} logoUrl={logo} pageName={pageName} pdfStyles={pdfStyles} />
              <View style={sectionTextStyle}>
                <View>
                  <Text style={titleTextStyle}>{disclosureContent.continuedTitle}</Text>
                  {pageContent.map(item => generateDisclosureSection(item))}
                </View>
              </View>
              <Footer pdfStyles={pdfStyles} text={footerText} />
            </Page>
          );
        })}
    </>
  );
};
