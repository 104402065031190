import React, { ComponentProps } from 'react';

import { BasicTable } from '~/components/ui/BasicTable';
import { Modal } from '~/components/ui/Modal';
import { Button } from '~/components/ui/mui';

export interface RelevantDocumentsContent {
  accountTypeTableLabel: string;
  close: string;
  modalTitle: string;
  requiredDocumentsLabel: string;
  tableContent: {
    accountType: string;
    requiredDocuments: string;
  }[];
}

export type Props = ComponentProps<typeof Modal> & {
  content: RelevantDocumentsContent;
  dataQa?: string;
  onClose?: () => void;
};

enum headerData {
  ACCOUNT_TYPE = 'ACCOUNT_TYPE',
  REQUIRED_DOCUMENTS = 'REQUIRED_DOCUMENTS',
}

export const RelevantDocuments: React.FC<Props> = ({
  content,
  dataQa = 'relevant-documents-details-modal',
  open,
  ...modalProps
}) => {
  const getColumnData = () => {
    const { accountTypeTableLabel: accountType, requiredDocumentsLabel: requiredDocuments } = content;
    const columnData = [
      { key: headerData.ACCOUNT_TYPE, title: accountType },
      { key: headerData.REQUIRED_DOCUMENTS, title: requiredDocuments },
    ];
    return columnData;
  };

  const getRowData = (rows: Record<string, React.ReactNode>[]) => {
    return (
      rows.map((row, idx) => ({
        rowKey: `${idx}`,
        [headerData.ACCOUNT_TYPE]: row.accountType,
        [headerData.REQUIRED_DOCUMENTS]: row.requiredDocuments,
      })) ?? []
    );
  };

  return (
    <Modal
      actions={
        <Button data-qa={`${dataQa}-close`} onClick={modalProps.onClose as () => void} variant="outlined">
          {content.close}
        </Button>
      }
      content={
        <BasicTable
          alignItems="left"
          columns={getColumnData()}
          data={getRowData(content.tableContent)}
          dataQa={`${dataQa}-table`}
        />
      }
      data-qa={dataQa}
      maxWidth="md"
      open={open}
      title={content.modalTitle}
      {...modalProps}
    />
  );
};
