import { Canvas } from '@react-pdf/renderer';
import React from 'react';

export const Dot: React.FC<{ color: string; opacity?: string; size?: number; style?: any }> = ({
  style,
  opacity,
  color,
  size = 4,
}) => {
  return (
    <Canvas
      paint={(painter: any) =>
        painter
          .circle(size / 2, size / 2, size / 2)
          .fillColor(color)
          .fillOpacity(parseInt(opacity ?? '100', 10) / 100)
          .fill()
      }
      style={{ width: size, height: size, margin: '0 8px', ...style }}
    />
  );
};
