import React from 'react';

import { BillingRateTargetType } from '~/__generated__/symphonyTypes.v2';
import { Link } from '~/components/ui/Link';
import { Skeleton, TableCell, TableRow, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { formatPercentage, FormatPercentageOptions } from '~/utils/format';
import { BlendedRates, Discount } from '~/utils/pricing';
import { SfTheme } from '~/utils/theme';

export interface FeeBreakdownProps {
  discount?: Discount;
  discountLabel?: string;
  feeTiersLabel?: string;
  fees?: BlendedRates[];
  formatPercentageOptions: FormatPercentageOptions;
  hideFeesWhenZero: boolean;
  loading: boolean;
  showDiscount: boolean;
  showFeeTiers?: () => void;
}

export const FeeBreakdown = ({
  discount,
  discountLabel,
  feeTiersLabel,
  fees,
  formatPercentageOptions,
  loading,
  hideFeesWhenZero,
  showDiscount,
  showFeeTiers,
}: FeeBreakdownProps) => {
  const {
    sfPricingSummary: { styles, typographyVariants },
  } = useTheme<SfTheme>();

  return (
    <>
      {fees?.map(fee => {
        if (parseFloat(fee.rate) === 0 && hideFeesWhenZero) {
          return;
        }

        return (
          <TableRow key={`${fee.label}_${fee.rate}`}>
            <TableCell sx={styles.feeLabelCell}>
              <Typography variant={typographyVariants?.feeItem}>{fee.label}</Typography>
              {fee.configName === BillingRateTargetType.ADVISOR && showDiscount && discount && (
                <RteContent
                  config={{
                    discount: formatPercentage(discount.discountValue, {
                      ...formatPercentageOptions,
                      removeTrailingZeroes: true,
                    }),
                  }}
                  data={discountLabel ?? ''}
                  sx={styles.discountCell}
                />
              )}
              {fee.configName === BillingRateTargetType.ADVISOR && !!showFeeTiers && feeTiersLabel && (
                <Link onClick={showFeeTiers} sx={{ paddingLeft: 1 }}>
                  <Typography variant={typographyVariants?.tiersLink}>{feeTiersLabel}</Typography>
                </Link>
              )}
            </TableCell>
            <TableCell sx={styles.feeValueCell}>
              <Typography variant={typographyVariants?.feeItem}>
                {loading ? (
                  <Skeleton height={15} sx={{ display: 'inline-block' }} width={45} />
                ) : (
                  formatPercentage(parseFloat(fee.rate), formatPercentageOptions)
                )}
              </Typography>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
