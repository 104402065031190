import { Link, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { Divider } from './Divider';
import { Donut } from './Donut';
import { Dot } from './Dot';

import { formatDisplayDate } from '~/components/AccountPerformance/utils';
import { Allocation } from '~/components/AssetAllocationTable';
import { FontSizes, Style } from '~/components/pdfs/styles';
import { allocationToDonutSlice } from '~/utils/asset-allocation';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { AllocationDetailsColumns, AssetAllocationTableColumns } from '~/utils/config/types';
import { ContentOptions } from '~/utils/contentstack';
import { formatCurrency, formatPercentage } from '~/utils/format';

export interface AllocationDetailsContent {
  assetAllocationColumns: {
    allocation: string;
    asset: string;
    assetClass: string;
    targetAllocation?: string;
    value?: string;
  };
  assetAllocationTitle: string;
  donutActualAllocationLabel?: string;
  donutTargetAllocationLabel: string;
  subtitle?: string[];
  title: string;
}

export interface AllocationDetailsData {
  assetAllocation: Allocation[];
  centerSubText: string;
  centerText: string;
  modelPortfolioName: string;
}

interface Props {
  allocation: Allocation[];
  assetClassTier?: AssetClassTier;
  centerSubText: string;
  centerText: string;
  columns?: AllocationDetailsColumns[] | AssetAllocationTableColumns[];
  content: AllocationDetailsContent;
  contentOptions: ContentOptions;
  displayAllocationBar?: boolean;
  donutFlexDirection?: 'row-reverse' | 'column';
  donutSize?: number;
  endDate?: string;
  factSheetLinkText?: string;
  factSheetUrl?: string;
  modelPortfolioName?: string;
  pdfStyles: Style;
  showActualAllocationInDonut?: boolean;
  showFactSheetLink?: boolean;
  showPortfolioName?: boolean;
}

export const AllocationTable: React.FC<Props> = ({
  columns = [
    AllocationDetailsColumns.ASSET_CLASS_LABEL,
    AllocationDetailsColumns.SECURITY_NAME,
    AllocationDetailsColumns.ALLOCATION_PERCENTAGE,
  ],
  content,
  contentOptions,
  centerText,
  allocation,
  assetClassTier,
  centerSubText,
  displayAllocationBar,
  donutFlexDirection = 'column',
  donutSize = 100,
  endDate,
  factSheetLinkText,
  factSheetUrl,
  modelPortfolioName,
  pdfStyles,
  showActualAllocationInDonut,
  showFactSheetLink,
  showPortfolioName,
}) => {
  const commonTextStyle = {
    color: pdfStyles.textPrimary.color,
    fontSize: 10,
    marginTop: '8px',
    marginBottom: '8px',
  };

  return (
    <View style={{ ...pdfStyles.border, padding: '20px' }}>
      <Text
        style={{ ...commonTextStyle, fontSize: FontSizes.Large, marginBottom: '4px', ...pdfStyles.accountAllocation }}
      >
        {content.assetAllocationTitle}
      </Text>
      {endDate && (
        <Text
          style={{
            color: pdfStyles.textSecondary.color,
            fontSize: 8,
          }}
        >
          {formatDisplayDate(endDate)}
        </Text>
      )}

      {(showPortfolioName || (factSheetUrl && showFactSheetLink)) && (
        <View
          style={{
            display: 'flex',
            marginBottom: '12px',
            marginTop: '15px',
            flexDirection: 'row',
            fontSize: 11,
          }}
        >
          {showPortfolioName ? <Text>{modelPortfolioName}</Text> : <Text />}
          {factSheetUrl && showFactSheetLink && (
            <Link src={factSheetUrl} style={{ ...pdfStyles?.factSheetLink }}>
              {factSheetLinkText}
            </Link>
          )}
        </View>
      )}
      <View style={{ display: 'flex', flexDirection: donutFlexDirection, justifyContent: 'space-between' }}>
        <Donut
          centerSubText={centerSubText}
          centerText={centerText}
          data={allocation.map(allocationToDonutSlice(assetClassTier, showActualAllocationInDonut))}
          fontSize={8}
          label={showActualAllocationInDonut ? content.donutActualAllocationLabel : content.donutTargetAllocationLabel}
          pdfStyles={pdfStyles}
          size={donutSize}
        />
        <View style={{ marginRight: '24px' }}>
          <View
            style={{
              ...commonTextStyle,
              display: 'flex',
              flexDirection: 'row',
              fontSize: 8,
              justifyContent: 'space-between',
              marginTop: '6px',
              marginBottom: '6px',
            }}
          >
            {columns.map(column => {
              switch (column) {
                case AllocationDetailsColumns.ALLOCATION_PERCENTAGE:
                case AssetAllocationTableColumns.ActualAllocation:
                  return (
                    <Text
                      key={AllocationDetailsColumns.ALLOCATION_PERCENTAGE}
                      style={{ textAlign: 'left', width: pdfStyles.allocationPercentageColumn.width }}
                    >
                      {content.assetAllocationColumns.allocation}
                    </Text>
                  );
                case AllocationDetailsColumns.ASSET_CLASS_LABEL:
                case AssetAllocationTableColumns.AssetClass:
                  return (
                    <Text
                      key={AllocationDetailsColumns.ASSET_CLASS_LABEL}
                      style={{ width: pdfStyles.assetClassLabelColumn.width }}
                    >
                      {content.assetAllocationColumns.assetClass}
                    </Text>
                  );
                case AllocationDetailsColumns.SECURITY_NAME:
                case AssetAllocationTableColumns.Asset:
                  return (
                    <Text
                      key={AllocationDetailsColumns.SECURITY_NAME}
                      style={{ width: pdfStyles.securityNameColumn.width, paddingRight: '8px' }}
                    >
                      {content.assetAllocationColumns.asset}
                    </Text>
                  );
                case AssetAllocationTableColumns.TargetAllocation:
                  return (
                    <Text
                      key={AssetAllocationTableColumns.TargetAllocation}
                      style={{ width: '20%', textAlign: 'left' }}
                    >
                      {content.assetAllocationColumns.targetAllocation}
                    </Text>
                  );
                case AssetAllocationTableColumns.Value:
                  return (
                    <Text key={AssetAllocationTableColumns.Value} style={{ width: '15%', textAlign: 'left' }}>
                      {content.assetAllocationColumns.value}
                    </Text>
                  );
                default:
                  return null;
              }
            })}
          </View>
          <Divider pdfStyles={pdfStyles} style={donutFlexDirection === 'row-reverse' ? { padding: '0 4px' } : {}} />
          {allocation
            .slice()
            .sort((a, b) => {
              return (
                (a.order === null ? 1 : 0) - (b.order === null ? 1 : 0) ||
                +((a.order ?? 0) > (b.order ?? 0)) ||
                -((a.order ?? 0) < (b.order ?? 0))
              );
            })
            .map(item => (
              <View key={item.assetClass.label}>
                <View
                  style={{
                    ...commonTextStyle,
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 8,
                    justifyContent: 'space-between',
                    marginTop: '6px',
                    marginBottom: '6px',
                  }}
                >
                  {columns.map(column => {
                    switch (column) {
                      case AllocationDetailsColumns.ASSET_CLASS_LABEL:
                      case AssetAllocationTableColumns.AssetClass:
                        return (
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: pdfStyles.assetClassLabelColumn.width,
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <Dot color={item.color} opacity={item.opacity} size={8} />
                            <Text>{item.assetClass.label}</Text>
                          </View>
                        );
                      case AssetAllocationTableColumns.TargetAllocation:
                        return (
                          <Text style={{ width: '20%', alignItems: 'center' }}>
                            {formatPercentage((item.targetPercent ?? 0) / 100, {
                              decimals: 1,
                              removeTrailingZeroes: false,
                              locale: contentOptions.locale,
                            })}
                          </Text>
                        );
                      case AssetAllocationTableColumns.Value:
                        return (
                          <Text style={{ width: '15%', alignItems: 'center' }}>
                            {formatCurrency(item.value, { locale: contentOptions.locale })}
                          </Text>
                        );
                      case AllocationDetailsColumns.SECURITY_NAME:
                      case AssetAllocationTableColumns.Asset:
                        return (
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: pdfStyles.securityNameColumn.width,
                            }}
                          >
                            {item.tickers.map(ticker => (
                              <Text key={ticker.key} style={{ padding: '1px' }}>
                                {ticker.label}
                              </Text>
                            ))}
                          </View>
                        );
                      case AllocationDetailsColumns.ALLOCATION_PERCENTAGE:
                      case AssetAllocationTableColumns.ActualAllocation:
                        const value =
                          column === AssetAllocationTableColumns.ActualAllocation
                            ? item.actualPercent ?? 0
                            : item.percent;
                        return (
                          <View
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-start',
                              width: pdfStyles.allocationPercentageColumn.width,
                            }}
                          >
                            <Text>
                              {formatPercentage(value / 100, {
                                decimals: 1,
                                removeTrailingZeroes: false,
                                locale: contentOptions.locale,
                              })}
                            </Text>
                            {displayAllocationBar && (
                              <View
                                style={{
                                  width: 60,
                                  marginLeft: 5,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <View
                                  style={{
                                    height: 7,
                                    width: (value / 100) * 60,
                                    backgroundColor: item.color,
                                  }}
                                />
                              </View>
                            )}
                          </View>
                        );
                      default:
                        return null;
                    }
                  })}
                </View>
                <Divider
                  pdfStyles={pdfStyles}
                  style={donutFlexDirection === 'row-reverse' ? { padding: '0 8px' } : {}}
                />
              </View>
            ))}
        </View>
      </View>
    </View>
  );
};
