import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { format, parseISO, startOfYear, subDays, subYears } from 'date-fns';
import React from 'react';

import { AllocationTable } from '../components/AllocationTable';
import { Divider } from '../components/Divider';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { AssetBreakdownContent } from '../DownloadablePerformance/pages/AssetBreakdown';
import { CoverContent, CoverPage } from '../DownloadablePerformance/pages/Cover';
import { FontSizes, Style } from '../styles';

import { Disclosure, DisclosureContent } from './pages/Disclosure';
import { AccountActivityView, AccountContent } from './Views/AccountActivity';
import { AccountBalance, AccountBalancePdfContent } from './Views/AccountBalance';
import { AccountOverview, AccountOverviewPdfContent } from './Views/AccountOverview';
import { Returns, ReturnsPdfContent, ReturnsSecurityColors } from './Views/Returns';

import { SecurityReturns } from '~/components/AccountPerformance/types';
import { formatDisplayDate } from '~/components/AccountPerformance/utils';
import { Allocation } from '~/components/AssetAllocationTable';
import { CompositeModelPortfolioAllocationContent as SleeveContent } from '~/components/CompositeModelPortfolioAllocation/hooks';
import {
  CompositeModelPortfolioAllocationData as Sleeve,
  MultiSleeveAllocation,
} from '~/components/pdfs/components/MultiSleeveAllocation';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts } from '~/hooks/account-details/symphony/__generated__/query.v2';
import { OpeningClosingBalance } from '~/hooks/qpr/useGetDataForQPR';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { AllocationDetailsColumns, AssetAllocationTableColumns } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';

export interface ActivityData {
  field: string;
  label: string;
  thisQuarter: string;
  ytd: string;
}

export interface DownloadablePerformanceContent {
  account: string;
  accountContent: AccountContent;
  accountOverviewBalance: {
    balance: AccountBalancePdfContent;
    footerText: string;
    logo: string;
    overview: AccountOverviewPdfContent;
    returns: ReturnsPdfContent;
  };
  assetBreakdown: AssetBreakdownContent;
  cover: CoverContent;
  disclosureContent: DisclosureContent;
  pageName: string;
  title: string;
}

export interface Props {
  account?: AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts;
  accountActivityFormattedData: ActivityData[];
  accountBalanceChartUrl: string;
  accountNumberToDisplay: string;
  allocation: Allocation[];
  assetAllocationColumns?: AllocationDetailsColumns[] | AssetAllocationTableColumns[];
  assetClassTier?: AssetClassTier;
  content: DownloadablePerformanceContent;
  contentOptions: ContentOptions;
  dataQa?: string;
  disclosureAtBottom: boolean;
  displayAllocationBar: boolean;
  endDate: string;
  factSheetLinkText?: string;
  factSheetUrl?: string;
  formatPhoneNumbers: boolean;
  height?: number | string;
  inceptionDate: string;
  maskedAccountNumberDisplay: string;
  modelPortfolioName?: string;
  openingClosingBalance: OpeningClosingBalance;
  partnerStyles: Style;
  returnPercentage?: string;
  returnsChartUrl: string;
  returnsSecurityColors: ReturnsSecurityColors;
  securitiesReturns: SecurityReturns[];
  showActualAllocationDonut: boolean;
  showAdditionalClientsInfo: boolean;
  showFactSheetLinkInAssetAllocation?: boolean;
  showPortfolioNameInAssetAllocation?: boolean;
  showSecurities: boolean;
  sleeveContent: SleeveContent;
  sleeves: Sleeve[];
  startDate: string;
  stockBondLabel: string;
  stockBondRatio: string;
  width?: number | string;
}

export const DownloadablePerformance: React.FC<Props> = ({
  account,
  accountActivityFormattedData,
  accountBalanceChartUrl,
  accountNumberToDisplay,
  allocation,
  assetAllocationColumns,
  assetClassTier,
  content,
  contentOptions,
  dataQa = 'downloadable-qpr',
  disclosureAtBottom,
  displayAllocationBar,
  endDate,
  factSheetUrl,
  factSheetLinkText,
  formatPhoneNumbers,
  inceptionDate,
  partnerStyles,
  openingClosingBalance,
  returnPercentage,
  showActualAllocationDonut,
  maskedAccountNumberDisplay,
  modelPortfolioName,
  returnsChartUrl,
  returnsSecurityColors,
  securitiesReturns,
  stockBondRatio,
  stockBondLabel,
  showAdditionalClientsInfo,
  showFactSheetLinkInAssetAllocation,
  showPortfolioNameInAssetAllocation,
  showSecurities,
  sleeveContent,
  sleeves,
  startDate,
}) => {
  const { cover } = content;
  const pdfStyles = StyleSheet.create({ ...partnerStyles });
  const hasMultipleSleeves = sleeves ? sleeves.length > 1 : false;
  const formatDate = 'MMM dd, yyyy';
  return (
    <Document data-qa={dataQa}>
      <CoverPage
        content={cover}
        formatPhoneNumbers={formatPhoneNumbers}
        pdfStyles={pdfStyles}
        showAdditionalClientsInfo={showAdditionalClientsInfo}
      />
      <Page size="LETTER" style={{ ...pdfStyles.accountReport }} wrap={false}>
        <Header
          accountNumber={accountNumberToDisplay}
          logoUrl={content.accountOverviewBalance.logo}
          pageName={content.pageName}
          pdfStyles={pdfStyles}
        />
        <View style={{ margin: '20px 40px 0' }}>
          <Text
            style={{
              color: pdfStyles.textSecondary.color,
              fontSize: 7,
            }}
          >
            {`${content.account} ${accountNumberToDisplay}`}
          </Text>
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text
              style={{
                color: pdfStyles.primaryMainColor.color,
                fontSize: 20,
                marginBottom: '6px',
                marginRight: '6px',
              }}
            >
              {content.title}
            </Text>
            <Text
              style={{
                fontSize: 10,
              }}
            >
              {`${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`}
            </Text>
          </View>

          <Divider color={pdfStyles.primaryMainColor.color} pdfStyles={pdfStyles} />
        </View>

        <AccountOverview
          accountBalance={openingClosingBalance.closingBalance ?? undefined}
          content={content.accountOverviewBalance.overview}
          contentOptions={contentOptions}
          endDate={endDate}
          pdfStyles={pdfStyles}
          returnPercentage={returnPercentage}
          startDate={startDate}
        />
        <AccountBalance
          content={content.accountOverviewBalance.balance}
          contentOptions={contentOptions}
          endDate={endDate}
          imageUrl={accountBalanceChartUrl}
          openingClosingBalance={openingClosingBalance}
          pdfStyles={pdfStyles}
          startDate={startDate}
        />
        <Returns
          accountNumberDisplay={maskedAccountNumberDisplay}
          accountReturnsPercentage={returnPercentage}
          content={content.accountOverviewBalance.returns}
          endDate={endDate}
          imageUrl={returnsChartUrl}
          pdfStyles={pdfStyles}
          returnsSecurityColors={returnsSecurityColors}
          securitiesReturns={securitiesReturns}
          showSecurities={showSecurities}
          startDate={startDate}
        />
        <Footer pdfStyles={pdfStyles} text={content.accountOverviewBalance.footerText} />
      </Page>
      {content.accountContent.disclosureContent.disclosure.length > 0 && (
        <Page size="LETTER" wrap={false}>
          <Header
            accountNumber={accountNumberToDisplay}
            logoUrl={content.accountOverviewBalance.logo}
            pageName={content.pageName}
            pdfStyles={pdfStyles}
          />
          <View
            style={{
              textAlign: 'left',
              margin: '40px',
              position: disclosureAtBottom ? 'absolute' : 'relative',
              bottom: '20px',
            }}
          >
            <Text
              style={{
                color: pdfStyles.primaryMainColor.color,
                fontSize: '12px',
                marginBottom: '8px',
              }}
            >
              {content.accountContent.disclosureContent.title}
            </Text>
            <Text
              style={{
                margin: '2px 0',
                fontWeight: 400,
                fontSize: FontSizes.Small,
                fontFamily: pdfStyles.disclosure.fontFamily,
              }}
            >
              <RteContent
                config={{
                  accountOpeningDate: format(startOfYear(parseISO(inceptionDate)), formatDate),
                  endPeriod: format(subYears(subDays(parseISO(endDate), 1), 1), formatDate),
                  quarterStart: format(parseISO(startDate), formatDate),
                  quarterEnd: format(parseISO(endDate), formatDate),
                  yearStart: format(startOfYear(parseISO(startDate)), formatDate),
                }}
                data={content.accountContent.disclosureContent.disclosure}
              />
            </Text>
          </View>
          <Footer pdfStyles={pdfStyles} text={content.accountOverviewBalance.footerText} />
        </Page>
      )}
      <Page size="LETTER" wrap={false}>
        <Header
          accountNumber={account?.maskedAccountNumber}
          logoUrl={content.assetBreakdown.logo}
          pageName={content.pageName}
          pdfStyles={pdfStyles}
        />

        <View
          style={{
            textAlign: 'left',
            margin: '20px 40px',
          }}
        >
          <AccountActivityView
            accountActivityFormattedData={accountActivityFormattedData}
            accountContent={content.accountContent}
            displayQuarter={false}
            endDate={endDate}
            openingClosingBalance={openingClosingBalance}
            pdfStyles={pdfStyles}
            startDate={startDate}
          />
        </View>

        {hasMultipleSleeves ? (
          <MultiSleeveAllocation
            assetClassTier={assetClassTier}
            contentOptions={contentOptions}
            pdfStyles={pdfStyles}
            sleeveContent={sleeveContent}
            sleeves={sleeves}
          />
        ) : (
          <View />
        )}

        <Footer pdfStyles={pdfStyles} text={content.assetBreakdown.footerText} />
      </Page>

      <Page size="LETTER" wrap={false}>
        <Header
          accountNumber={account?.maskedAccountNumber}
          logoUrl={content.assetBreakdown.logo}
          pageName={content.pageName}
          pdfStyles={pdfStyles}
        />
        <View
          style={{
            textAlign: 'left',
            margin: '20px 40px',
          }}
        >
          <AllocationTable
            allocation={allocation}
            assetClassTier={assetClassTier}
            centerSubText={stockBondLabel}
            centerText={stockBondRatio}
            columns={assetAllocationColumns}
            content={content.assetBreakdown.allocationDetails}
            contentOptions={contentOptions}
            displayAllocationBar={displayAllocationBar}
            donutFlexDirection="row-reverse"
            endDate={endDate}
            factSheetLinkText={factSheetLinkText}
            factSheetUrl={factSheetUrl}
            modelPortfolioName={modelPortfolioName}
            pdfStyles={pdfStyles}
            showActualAllocationInDonut={showActualAllocationDonut}
            showFactSheetLink={showFactSheetLinkInAssetAllocation}
            showPortfolioName={hasMultipleSleeves ? undefined : showPortfolioNameInAssetAllocation}
          />
        </View>
        <Footer pdfStyles={pdfStyles} text={content.assetBreakdown.footerText} />
      </Page>
      <Disclosure
        accountNumber={account?.maskedAccountNumber ?? undefined}
        disclosureAtBottom={disclosureAtBottom}
        disclosureContent={content.disclosureContent}
        endDate={endDate}
        inceptionDate={startDate}
        pdfStyles={pdfStyles}
        startDate={startDate}
      />
    </Document>
  );
};
