import React, { FC, useEffect, useMemo, useState } from 'react';

import { useDocusignDAProContent } from './hooks/useDocusignDAProContent';
import { RelevantDocuments } from './RelevantDocuments';
import { useGetDocusignStatus } from './symphony';

import { LegalDocumentStatus } from '~/__generated__';
import { DocumentList, Packet } from '~/components/DocumentList';
import { DocusignIframe } from '~/components/DocusignIframe';
import { OnboardingPageCtas } from '~/components/Onboarding/OnboardingPageCtas';
import { OnboardingPageHeader } from '~/components/Onboarding/OnboardingPageHeader';
import { Alert } from '~/components/ui/Alert';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { Link } from '~/components/ui/Link';
import { Button, CheckIcon, Grid, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { SigningDocument, useCreateSigningDocument } from '~/hooks/docusign/symphony';
import { ContentOptions } from '~/utils/contentstack';
import { useInterval } from '~/utils/interval';
import { SfTheme } from '~/utils/theme';

enum SigningDocumentStatus {
  CLIENT_DECLINED = 'CLIENT_DECLINED',
  CLIENT_SIGNED = 'CLIENT_SIGNED',
  FA_DECLINED = 'FA_DECLINED',
  FA_SIGNED = 'FA_SIGNED',
  PENDING_CLIENT_SIGNATURE = 'PENDING_CLIENT_SIGNATURE',
  PENDING_CREATION = 'PENDING_CREATION',
}

export interface Props {
  baseUrl: string;
  clientSignaturePollFrequency?: number;
  contentOptions: ContentOptions;
  dataQa?: string;
  deeplinkUrl?: string;
  managedProductId: string;
  onClientDecline?: () => void;
  onFADecline?: () => void;
  onFASign?: () => void;
  onIframeReady?: () => void;
  onSaveExit: () => void;
  partyId: string;
  partyIdFA: string;
  returnToUrl: string;
  showRelevantDocuments?: boolean;
}

export const DocusignDAPro: FC<Props> = ({
  dataQa = 'docusign-da-pro',
  clientSignaturePollFrequency = 20 * 1000, // 20 seconds
  contentOptions,
  partyId,
  partyIdFA,
  managedProductId,
  baseUrl,
  returnToUrl,
  onIframeReady,
  onFADecline,
  onFASign,
  onClientDecline,
  onSaveExit,
  deeplinkUrl,
  showRelevantDocuments = false,
}) => {
  const [signingDocumentCreated, setSigningDocumentCreated] = useState(false);
  const [showPendingClientSignatureStep, setShowPendingClientSignatureStep] = useState(false);
  const [faSigningDocument, setFASigningDocument] = useState<SigningDocument>();
  const [signingDocumentError, setSigningDocumentError] = useState<Error | undefined>();
  const [signingDocumentLoading, setSigningDocumentLoading] = useState(false);
  const [signingDocumentState, setSigningDocumentState] = useState<SigningDocumentStatus | undefined>();
  const [signingDocumentPackets, setSigningDocumentPackets] = useState<Packet[]>([]);
  const [createSigningDocument] = useCreateSigningDocument();

  const {
    data: docusignProContentData,
    loading: docusignProContentLoading,
    error: docusignProContentError,
  } = useDocusignDAProContent({
    contentOptions,
  });

  const {
    data: faDocusignStatusData,
    error: faDocusignStatusError,
    refetch: refetchFaDocusignStatus,
  } = useGetDocusignStatus({
    variables: { managedProductId, partyId: partyIdFA, baseUrl, returnToUrl },
  });

  const {
    sfOnboardingPageHeader: { styles },
  } = useTheme<SfTheme>();

  const content = docusignProContentData;
  const accountNumber = faDocusignStatusData?.managedProduct?.financialAccountNumber;

  useEffect(() => {
    if (faSigningDocument || !faDocusignStatusData || !content) {
      return;
    }

    const managedProductLegalDocuments = faDocusignStatusData?.managedProduct?.legalDocuments;
    if (managedProductLegalDocuments?.length && content) {
      const legalDocumentPackets: Packet[] = managedProductLegalDocuments.map(doc => {
        const documentsArray = doc.forms.map(form => ({
          name: content.generatedDocusign.documentMapping.find(d => d.key === form.name)?.value ?? form.name,
          status: <CheckIcon />,
        }));
        return {
          documents: documentsArray,
          id: doc.signingDocumentId as string,
        };
      });
      setSigningDocumentPackets(legalDocumentPackets);

      const clientList = managedProductLegalDocuments[0]?.signees?.filter(doc => doc.partyId !== partyIdFA) ?? [];
      const faStatus = managedProductLegalDocuments[0]?.signees.find(doc => doc.partyId === partyIdFA)?.status;
      if (clientList.length) {
        if (
          clientList.some(
            client => ![LegalDocumentStatus.SUCCEEDED, LegalDocumentStatus.DECLINED].includes(client.status),
          )
        ) {
          setSigningDocumentState(SigningDocumentStatus.PENDING_CLIENT_SIGNATURE);
        } else if (clientList.every(client => client.status === LegalDocumentStatus.SUCCEEDED)) {
          setSigningDocumentState(SigningDocumentStatus.CLIENT_SIGNED);
        } else if (clientList.some(client => client.status === LegalDocumentStatus.DECLINED)) {
          setSigningDocumentState(SigningDocumentStatus.CLIENT_DECLINED);
          onClientDecline?.();
        } else if (faStatus === LegalDocumentStatus.DECLINED) {
          setSigningDocumentState(SigningDocumentStatus.FA_DECLINED);
          onFADecline?.();
        } else {
          setSigningDocumentState(SigningDocumentStatus.FA_SIGNED);
          onFASign?.();
        }
      }

      setSigningDocumentCreated(true);
    } else {
      setSigningDocumentState(SigningDocumentStatus.PENDING_CREATION);
    }

    if (
      signingDocumentState === SigningDocumentStatus.CLIENT_SIGNED &&
      faDocusignStatusData?.managedProduct?.legalDocuments?.[0]?.linkToDocument
    ) {
      setFASigningDocument(faDocusignStatusData.managedProduct.legalDocuments[0]);
      onIframeReady?.();
    }
  }, [
    content,
    docusignProContentData,
    faSigningDocument,
    faDocusignStatusData,
    onIframeReady,
    onClientDecline,
    onFASign,
    onFADecline,
    signingDocumentState,
    partyIdFA,
  ]);

  useEffect(() => {
    // clean up controller
    let isMounted = true;
    if (!signingDocumentLoading && signingDocumentState === SigningDocumentStatus.PENDING_CREATION) {
      setSigningDocumentLoading(true);
      createSigningDocument({
        variables: {
          baseUrl,
          faPartyId: partyIdFA,
          managedProductId,
          partyId,
          returnToUrl,
          deeplinkUrl,
        },
      })
        .then(document => {
          if (isMounted) {
            if (document.data?.createSigningDocument) {
              setSigningDocumentCreated(true);
              refetchFaDocusignStatus();
            } else {
              setSigningDocumentError(
                Error(
                  `Unable to get/create signing document for partyId: ${partyId} and managedProductId: ${managedProductId}. Please refresh the page or contact support.`,
                ),
              );
            }
          }
        })
        .catch(err => {
          // TODO DA2-512: Log error to sentry
          console.error(err);
          setSigningDocumentError(err);
        })
        .finally(() => {
          setSigningDocumentLoading(false);
        });
    }
    // cancel subscription to useEffect
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signingDocumentState]);

  // keep refreshing docusign status until we have the FA signing document, after the signing document is created
  useInterval(
    async () => {
      await refetchFaDocusignStatus();
    },
    signingDocumentCreated && !faSigningDocument ? clientSignaturePollFrequency : null,
  );

  const queryLoading = useMemo(
    () =>
      docusignProContentLoading ||
      signingDocumentLoading ||
      !signingDocumentState ||
      signingDocumentState === SigningDocumentStatus.PENDING_CREATION,
    [docusignProContentLoading, signingDocumentLoading, signingDocumentState],
  );
  const queryError = useMemo(() => signingDocumentError || faDocusignStatusError || docusignProContentError, [
    docusignProContentError,
    signingDocumentError,
    faDocusignStatusError,
  ]);

  const generatedDocusignContent = content?.generatedDocusign;
  const pendingClientDocusignContent = content?.pendingClientDocusign;
  const faDocusignContent = content?.faDocusign;

  const headerContent = useMemo(() => {
    const headingContent =
      signingDocumentState === SigningDocumentStatus.PENDING_CLIENT_SIGNATURE
        ? showPendingClientSignatureStep
          ? pendingClientDocusignContent
          : generatedDocusignContent
        : signingDocumentState === SigningDocumentStatus.CLIENT_SIGNED
        ? faDocusignContent
        : undefined;

    return (
      headingContent && {
        imageConnection: headingContent.imageConnection,
        heading:
          'documentMapping' in headingContent ? (
            <RteContent
              config={{ accountNumber: accountNumber ? ` (${accountNumber})` : '' }}
              data={headingContent.heading}
              sx={styles.title}
            />
          ) : (
            headingContent.heading
          ),
      }
    );
  }, [
    accountNumber,
    faDocusignContent,
    generatedDocusignContent,
    pendingClientDocusignContent,
    showPendingClientSignatureStep,
    signingDocumentState,
    styles.title,
  ]);

  const renderSigningDocumentStates = (docusignState: SigningDocumentStatus) => {
    switch (docusignState) {
      case SigningDocumentStatus.PENDING_CLIENT_SIGNATURE:
        if (showPendingClientSignatureStep) {
          return (
            <>
              {faDocusignStatusData?.managedProduct?.legalDocuments?.[0]?.signees && (
                <RteContent
                  config={{
                    partyEmail: faDocusignStatusData.managedProduct.legalDocuments[0]?.signees
                      .filter(s => s.partyId !== partyIdFA)
                      .map((signee, idx) => (
                        <>
                          {idx > 0 && (
                            <Typography component="span" key={`${signee.partyId}-conjunction`}>
                              {` ${pendingClientDocusignContent?.clientTextConjunction ?? 'and'} `}
                            </Typography>
                          )}
                          <Typography color="primary.main" component="span" key={`${signee.partyId}-email`}>
                            {signee.email}
                          </Typography>
                        </>
                      )),
                  }}
                  data={pendingClientDocusignContent?.clientText ?? ''}
                  data-qa={`${dataQa}-sub-heading`}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                />
              )}
              <Typography data-qa={`${dataQa}-info-text`}>{pendingClientDocusignContent?.infoText}</Typography>
              <Typography data-qa={`${dataQa}-disclaimer-text`} sx={{ my: 2, pb: 3 }}>
                {pendingClientDocusignContent?.disclaimerText}
              </Typography>
              <Alert
                action={
                  <Button onClick={onSaveExit} sx={{ whiteSpace: { md: 'nowrap' } }}>
                    {pendingClientDocusignContent?.pendingAlertCta}
                  </Button>
                }
                contentOptions={contentOptions}
                data-qa={`${dataQa}-pending-alert`}
                severity="info"
                sx={{
                  '& .MuiAlert-icon': {
                    mt: 0,
                  },
                }}
              >
                <Typography>{pendingClientDocusignContent?.pendingAlertMessage}</Typography>
              </Alert>
            </>
          );
        }

        return (
          <>
            <DocumentList packets={signingDocumentPackets} />
            <OnboardingPageCtas
              content={{ backCta: '', nextCta: generatedDocusignContent?.primaryButtonCta ?? '' }}
              nextButtonProps={{ onClick: () => setShowPendingClientSignatureStep(true) }}
              sx={{ my: 3 }}
            />
            {generatedDocusignContent?.disclosure && (
              <RteContent
                anchorComponent={props => <Link {...props} rel="noopener" target="_blank" underline="hover" />}
                data={generatedDocusignContent.disclosure}
              />
            )}
          </>
        );
      case SigningDocumentStatus.CLIENT_SIGNED:
        return (
          <>
            {showRelevantDocuments ? (
              <RelevantDocuments
                contentOptions={contentOptions}
                data={faDocusignContent?.subHeading}
                data-qa={`${dataQa}-sub-heading`}
                linkText={faDocusignContent?.requiredDocumentsCta ?? ''}
              />
            ) : (
              <Typography data-qa={`${dataQa}-sub-heading`} sx={{ pb: 5 }} variant="body2">
                {faDocusignContent?.subHeading}
              </Typography>
            )}

            {!!faSigningDocument?.linkToDocument && (
              <DocusignIframe
                dataQa={dataQa}
                helperHeading={faDocusignContent?.helperHeading}
                helperSubHeading={faDocusignContent?.helperSubHeading}
                linkToDocument={faSigningDocument.linkToDocument}
              />
            )}
          </>
        );
      default:
        return null;
    }
  };
  return (
    <Grid data-qa={dataQa} sx={{ py: 2 }}>
      {(queryLoading || queryError) && (
        <AlertAndLoading
          ariaLabel="Loading docusign data"
          contentOptions={contentOptions}
          error={queryError}
          loading={queryLoading}
        />
      )}
      <Grid sx={{ textAlign: 'center' }}>
        {headerContent && (
          <OnboardingPageHeader
            dataQa={`${dataQa}-header`}
            headerIcon={headerContent.imageConnection ?? ''}
            title={headerContent.heading ?? ''}
          />
        )}
        {signingDocumentState && renderSigningDocumentStates(signingDocumentState)}
      </Grid>
    </Grid>
  );
};
