import axios from 'axios';

import { StagedModelPortfolioData } from '~/containers/Plan/types';

export const getFactSheetImages = async (
  getFactSheetURL: (series: string, internalId: string, page: number, providerName?: string) => string,
  modelPortfolioData: StagedModelPortfolioData,
) => {
  const factSheetImages = [];

  // Sanitize model names to match S3 paths
  const sanitizeTextForFactSheet = (text: string): string => text.replace(/\+/g, '%2B').replace(/ /g, '+');

  const portfolioIdentifiers = modelPortfolioData.modelPortfolios.map(mp => ({
    internalId: mp.internalId,
    series: sanitizeTextForFactSheet(mp.seriesBaseName),
    providerName: sanitizeTextForFactSheet(mp.providerName ?? ''),
  }));

  for (const portfolio of portfolioIdentifiers) {
    let perModel = true;
    let page = 1;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        const { series, providerName, internalId } = portfolio;
        const perModelInternalId = perModel ? `${internalId}/` : '';
        const path = getFactSheetURL(series, perModelInternalId, page, providerName);
        if (path) {
          // This is a temporary fix
          // @todo Have to create a context and provider for the Axios Request required in different use cases
          const instance = axios.create();
          delete instance.defaults.headers.common.Authorization;
          const res = await instance.get(path, {
            responseType: 'arraybuffer',
          });

          if (res.data) {
            factSheetImages.push(Buffer.from(res.data, 'binary'));
          }

          page += 1;
        } else {
          break;
        }
      } catch (e) {
        if (!perModel || page > 1) {
          // If we've already looked per-model and per-series and still fail to load, assume fact sheets are unavailable
          // or we've reached the end of the pages
          break;
        }
        perModel = false;
      }
    }
  }

  return factSheetImages;
};
