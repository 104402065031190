import { useCallback, useEffect, useMemo } from 'react';

import { useGetDigitalWealthAccounts } from '../AccountSummary/symphony';
import { Account, getAccounts, getPartialAccount } from '../AccountSummary/utils';

import { useGetWidgetsContent, WidgetsContentNullState } from './contentstack';
import { GetWidgetsContent_all_widgets_items_labels } from './contentstack/__generated__/getWidgetsContent.v2';

import { FinancialAccountStatus, OnboardingStates } from '~/__generated__';
import { useModelPortfoliosContent } from '~/hooks/model-portfolios/useModelPortfoliosContent';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface WidgetsComponentHookOptions {
  contentOptions: ContentOptions;
  financialAccountStatusesToInclude: FinancialAccountStatus[];
  nullStateKey?: string;
  onboardingStatesToInclude: OnboardingStates[];
  partyId?: string;
  showModelPortfolioInfo?: boolean;
}

export interface WidgetsComponentData {
  accounts?: Account[];
  contentLabels?: GetWidgetsContent_all_widgets_items_labels;
  nullStateWidget?: WidgetsContentNullState;
}

/**
 * The Widgets component renders a list of managed accounts that satisfy the financialAccountStatusesToInclude and
 * onboardingStatesToInclude.
 * If there isn't any, null-state widget is rendered.
 *
 */
export const useWidgetsComponent = ({
  contentOptions,
  financialAccountStatusesToInclude,
  nullStateKey,
  onboardingStatesToInclude,
  partyId,
  showModelPortfolioInfo,
}: WidgetsComponentHookOptions): AsyncResult<WidgetsComponentData> => {
  const { data: widgetsContent, loading: widgetsContentLoading, error: widgetsContentError } = useGetWidgetsContent({
    variables: contentOptions,
  });

  const { data: modelPortfoliosContent, loading: modelPortfoliosContentLoading } = useModelPortfoliosContent({
    contentOptions,
  });

  const { data: accountsData, loading: accountsLoading, error: accountsError } = useGetDigitalWealthAccounts({
    variables: { partyId: partyId || '', withModelPortfolio: !!showModelPortfolioInfo, withLegalDocuments: false },
    errorPolicy: 'all', // partial results possible
    skip: !partyId, // if no partyId (anonymous user), skip the query to render null state widget
  });

  /**
   * Returns true if the account is a managed account that isn't suspended and
   * satisfies the financialAccountStatusesToInclude, and onboardingStatesToInclude for partials.
   */
  const shouldShowManagedProduct = useCallback(
    (status: FinancialAccountStatus, onboardingState?: OnboardingStates) => {
      return (
        financialAccountStatusesToInclude.includes(status) &&
        (status !== FinancialAccountStatus.PARTIAL ||
          onboardingStatesToInclude.includes(onboardingState ?? OnboardingStates.UNKNOWN))
      );
    },
    [financialAccountStatusesToInclude, onboardingStatesToInclude],
  );
  useEffect(() => {
    if (accountsError) {
      console.error(
        `[Widgets] - Failed to retrieve account data info for partyId: [${partyId}], error: ${accountsError}`,
      );
    }
    if (widgetsContentError) {
      console.error(`[Widgets] - Failed to retrieve widget content from contentstack, error: ${widgetsContentError}`);
    }
  }, [accountsError, partyId, widgetsContentError]);

  const data = useMemo(() => {
    if (partyId && !accountsData) {
      return undefined;
    }
    // No partId means anonymous user, and they shall be served with null state widget.
    const contentLabels = widgetsContent?.all_widgets?.items?.[0]?.labels || undefined;
    const accounts =
      accountsData && partyId && modelPortfoliosContent
        ? getAccounts(contentOptions.locale, accountsData, partyId, modelPortfoliosContent.nameParser).filter(account =>
            shouldShowManagedProduct(account.status, account.onboardingState),
          )
        : [];
    const accountsForAccountListWidget = accounts.filter(a => a.status !== FinancialAccountStatus.PARTIAL);

    if (accountsForAccountListWidget.length) {
      return {
        accounts: accountsForAccountListWidget,
        contentLabels,
      };
    }

    // Null state refers to a user that has not been enrolled in Digital Advice yet.
    // If the user has a partial from onboarding, find the widgets for certain onboardingState milestones.
    // Otherwise, find the nullStateKey.
    const partialAccountsOnboardingState = getPartialAccount(accounts)?.onboardingState ?? null;
    const nullWidgetContent = widgetsContent?.all_widgets?.items?.[0]?.widgets;
    const targetNullStateWidget =
      nullWidgetContent?.find(w =>
        (w?.widget_null_state?.onboarding_state ?? []).includes(partialAccountsOnboardingState),
      ) ?? nullWidgetContent?.find(w => w?.widget_null_state?.key === nullStateKey);

    return { nullStateWidget: targetNullStateWidget?.widget_null_state ?? undefined };
  }, [
    accountsData,
    contentOptions.locale,
    modelPortfoliosContent,
    nullStateKey,
    partyId,
    shouldShowManagedProduct,
    widgetsContent?.all_widgets?.items,
  ]);

  return {
    loading: accountsLoading || modelPortfoliosContentLoading || widgetsContentLoading,
    error: accountsError ?? widgetsContentError,
    data,
  };
};
