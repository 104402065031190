import React, { FC, useEffect, useMemo, useState } from 'react';
import { Controller, UseFormMethods, useWatch } from 'react-hook-form';

import { DataPointKeys, QuestionKeys } from '../../../configs/questions/types';
import {
  ManagedProductPaperworkWithFreeFormId,
  PaperworkInputWithFreeFormId,
} from '../../../hooks/useGetPaperworkData';
import { FormData } from '../../../types';
import { getFieldName } from '../../utils';
import { getQuestionsContentForCustomComponent } from '../utils';

import { defaultAssetsHeldAwayConfig } from './config';
import { getUpdatedTotalAndPercentageArray } from './utils';

import { FinancialAccountType } from '~/__generated__/symphonyTypes.v2';
import { Alert } from '~/components/ui/Alert';
import { Box, Grid, InputAdornment } from '~/components/ui/mui';
import { CurrencyTextField } from '~/components/ui/TextField/CurrencyTextField';
import { Typography } from '~/components/ui/Typography';
import { CMSQuestions } from '~/containers/Paperwork/contentstack';
import { isTrustAccountType } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';

export interface AssetsHeldAwayQuestionConfig {
  dataPointKey: DataPointKeys;
  paperworkInputSetter: (paperworkInput: PaperworkInputWithFreeFormId, value: string) => void;
  prefillValueGetter: (paperworkData?: ManagedProductPaperworkWithFreeFormId) => string;
  questionKey: QuestionKeys;
}

export interface AssetsHeldAwayConfig {
  questions: AssetsHeldAwayQuestionConfig[];
}

export interface Props {
  accountType: FinancialAccountType;
  assetsHeldAwayConfig?: AssetsHeldAwayConfig;
  contentOptions: ContentOptions;
  dataQa?: string;
  disablePageEditing?: boolean;
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  isAccountProfileEdit?: boolean;
  isFormSubmitted: boolean;
  paperworkFreeFormId?: string;
  questionsContent: CMSQuestions;
  savedPaperworkData?: ManagedProductPaperworkWithFreeFormId;
}

export const AssetsHeldAwayV2: FC<Props> = ({
  dataQa = 'assets-held-away-v2',
  accountType,
  assetsHeldAwayConfig = defaultAssetsHeldAwayConfig,
  savedPaperworkData,
  questionsContent,
  contentOptions,
  paperworkFreeFormId,
  formHooks: { control, setValue, errors: fieldErrors, trigger },
  isAccountProfileEdit,
  isFormSubmitted,
  disablePageEditing,
}) => {
  const [percentageArray, setPercentageArray] = useState<number[]>(
    new Array(assetsHeldAwayConfig.questions.length).fill(0),
  );
  const [totalFunds, setTotalFunds] = useState<string>(
    savedPaperworkData?.investment?.experienceDetail?.total.qualified ?? '0.00',
  );

  const questions = useMemo(() => {
    return getQuestionsContentForCustomComponent(questionsContent, QuestionKeys.ASSETS_HELD_AWAY);
  }, [questionsContent]);

  const watchAllFields = useWatch({
    control,
    name: assetsHeldAwayConfig.questions.map(assetConfig =>
      getFieldName(assetConfig.dataPointKey, paperworkFreeFormId),
    ),
  });

  const errorMessage = questions.find(question => question.key === QuestionKeys.ASSETS_HELD_AWAY_ERROR_MESSAGE)
    ?.question;

  const totalAssetLabel = questions.find(question => question.key === QuestionKeys.ASSETS_HELD_AWAY_TOTAL_ASSET_VALUE)
    ?.question;

  const assetHeldAwayInfo = questions.find(
    question =>
      question.key ===
      (isTrustAccountType(accountType) ? QuestionKeys.ASSETS_HELD_AWAY_TRUST_INFO : QuestionKeys.ASSETS_HELD_AWAY_INFO),
  )?.question;

  useEffect(() => {
    const allFieldsValues = Object.values(watchAllFields);
    const transformedFieldValues = allFieldsValues.map(value => (isNaN(Number(value)) ? 0 : Number(value)));
    const { totalValue: newValue, percentageArray: newPercentageArray } = getUpdatedTotalAndPercentageArray(
      transformedFieldValues,
    );

    const newTotalValue = newValue > 0 ? newValue.toString() : '0.00';
    setTotalFunds(prevTotalValue => {
      if (prevTotalValue === newTotalValue) {
        return prevTotalValue;
      } else {
        return newTotalValue;
      }
    });
    setPercentageArray(prevPercentageArray => {
      if (JSON.stringify(newPercentageArray) !== JSON.stringify(prevPercentageArray)) {
        return [...newPercentageArray];
      } else {
        return prevPercentageArray;
      }
    });
  }, [isFormSubmitted, watchAllFields, assetsHeldAwayConfig.questions]);

  useEffect(() => {
    trigger(assetsHeldAwayConfig.questions.map(ac => getFieldName(ac.dataPointKey, paperworkFreeFormId)));
  }, [totalFunds, trigger, assetsHeldAwayConfig.questions, paperworkFreeFormId]);

  const getController = (componentName: string, percentageValue?: number, label?: string, defaultValue?: string) => {
    const fieldError = fieldErrors[componentName];
    return (
      <Grid item key={componentName} xs={isAccountProfileEdit ? 4 : 12}>
        <Box
          sx={{
            my: isAccountProfileEdit ? 2 : 1,
            display: 'flex',
            flexDirection: 'row',
            width: 1,
          }}
        >
          <Box sx={{ minWidth: 0.83 }}>
            <Controller
              control={control}
              defaultValue={defaultValue ?? ''}
              name={componentName}
              render={({ ref, value }) => (
                <CurrencyTextField
                  InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  contentOptions={contentOptions}
                  currencySymbol=""
                  data-qa={`${dataQa}-${componentName}`}
                  decimalScale={0}
                  disabled={disablePageEditing}
                  error={isFormSubmitted ? !!fieldErrors[componentName] : false}
                  fullWidth
                  id={componentName}
                  inputRef={ref}
                  label={label}
                  name={componentName}
                  onValueChange={({ value: newValue }) =>
                    setValue(componentName, newValue, { shouldDirty: true, shouldValidate: true })
                  }
                  placeholder="0"
                  value={parseFloat(value)}
                />
              )}
              rules={{
                validate: {
                  isValidTotal: _value => {
                    if (errorMessage && !(totalFunds && parseFloat(totalFunds) > 0)) {
                      return errorMessage;
                    }
                    return undefined;
                  },
                },
              }}
            />
            <Box sx={{ width: 1 }}>
              {isFormSubmitted && fieldError && (
                <Typography key={`error-${componentName}`} role="alert" sx={{ color: 'error.main' }} variant="caption">
                  {fieldError.message}
                </Typography>
              )}
            </Box>
          </Box>
          <Typography data-testid={`${dataQa}-percentage`} sx={{ mt: 4, ml: 1.5 }} variant="body1">
            {`${percentageValue ?? 0}%`}
          </Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <Box data-qa={dataQa}>
      <Alert contentOptions={contentOptions} data-qa="component-info-" severity="info" sx={{ mt: 2 }}>
        {assetHeldAwayInfo}
      </Alert>
      <Grid container>
        {assetsHeldAwayConfig.questions.map((assetConfig, index) => {
          const questionLabel = questions.find(question => question.key === assetConfig.questionKey)?.question;
          const defaultValue = assetConfig.prefillValueGetter(savedPaperworkData);
          return getController(
            getFieldName(assetConfig.dataPointKey, paperworkFreeFormId),
            percentageArray[index],
            questionLabel ?? '',
            defaultValue,
          );
        })}
      </Grid>
      <Box sx={{ mt: 3, mb: 1.5, display: 'flex', flexDirection: 'row', width: 1 }}>
        <Box sx={{ minWidth: 0.83 }}>
          <Typography variant="caption">{totalAssetLabel}</Typography>
          <Typography
            component="p"
            data-testid={`${dataQa}-total-asset`}
            sx={{ mt: 2 }}
            variant="subtitle1"
          >{`$${totalFunds}`}</Typography>
        </Box>
        <Typography component="p" sx={{ mt: 4, ml: 1.5 }} variant="subtitle1">{`${
          Number(totalFunds) > 0 ? '100%' : '0%'
        }`}</Typography>
      </Box>
    </Box>
  );
};
