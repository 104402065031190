import { range } from 'fp-ts/Array';

import { DataPointKeys } from '../../../configs/questions/types';
import {
  getPaperworkEnumFieldValue,
  getPaperworkNumberFieldValue,
  getPaperworkStringFieldValue,
} from '../../../configs/questions/utils';
import {
  ManagedProductPaperworkWithFreeFormId,
  PaperworkInputWithFreeFormId,
} from '../../../hooks/useGetPaperworkData';
import { FormData } from '../../../types';
import { getFieldName } from '../../utils';

import { AddressType, ContactType, RelationshipInput, RelationshipName, RelationshipSubtype } from '~/__generated__';

export const populateTrustedContacts = (
  paperworkInput: PaperworkInputWithFreeFormId,
  data: {
    allSavedPaperworkData: ManagedProductPaperworkWithFreeFormId[];
    dependentFormFieldsInvisibility: Record<string, boolean>;
    formValues: FormData;
    savedPaperworkData?: ManagedProductPaperworkWithFreeFormId;
    unmaskedValues: Record<string, string>;
  },
  paperworkFreeFormId: string | undefined,
) => {
  if (paperworkInput.relationships) {
    paperworkInput.relationships = paperworkInput.relationships.filter(
      relationship => relationship.name !== RelationshipName.TRUSTED_CONTACT,
    );
  }

  const numOfTrustedContacts =
    getPaperworkNumberFieldValue(
      data.formValues[getFieldName(DataPointKeys.TRUSTED_CONTACT_COUNT, paperworkFreeFormId)],
    ) ?? 0;
  if (numOfTrustedContacts > 0) {
    range(0, numOfTrustedContacts - 1).forEach(i => {
      const contactFirstName = getPaperworkStringFieldValue(
        data.formValues[`${getFieldName(DataPointKeys.TRUSTED_CONTACT_FIRST_NAME, paperworkFreeFormId)}-${i}`],
      );
      const contactMiddleName = getPaperworkStringFieldValue(
        data.formValues[`${getFieldName(DataPointKeys.TRUSTED_CONTACT_MIDDLE_NAME, paperworkFreeFormId)}-${i}`],
      );
      const contactLastName = getPaperworkStringFieldValue(
        data.formValues[`${getFieldName(DataPointKeys.TRUSTED_CONTACT_LAST_NAME, paperworkFreeFormId)}-${i}`],
      );
      if (contactFirstName) {
        const trustedContact: RelationshipInput = {
          name: RelationshipName.TRUSTED_CONTACT,
          party: {
            partyPerson: {
              givenName: contactFirstName,
              middleName: contactMiddleName ?? '',
              familyName: contactLastName,
            },
            partyContacts: [
              {
                contact:
                  getPaperworkStringFieldValue(
                    data.formValues[`${getFieldName(DataPointKeys.TRUSTED_CONTACT_EMAIL, paperworkFreeFormId)}-${i}`],
                  ) ?? '',
                type: ContactType.EMAIL,
              },
              {
                contact:
                  getPaperworkStringFieldValue(
                    data.formValues[
                      `${getFieldName(DataPointKeys.TRUSTED_CONTACT_PHONE_NUMBER, paperworkFreeFormId)}-${i}`
                    ],
                  ) ?? '',
                type: ContactType.MOBILE,
              },
            ].filter(contact => contact.contact.length),
            addresses: [
              {
                countryPrimarySubdivision: getPaperworkStringFieldValue(
                  data.formValues[`${getFieldName(DataPointKeys.TRUSTED_CONTACT_STATE, paperworkFreeFormId)}-${i}`],
                ),
                countrySecondarySubdivision: getPaperworkStringFieldValue(
                  data.formValues[`${getFieldName(DataPointKeys.TRUSTED_CONTACT_CITY, paperworkFreeFormId)}-${i}`],
                ),
                postalCode: getPaperworkStringFieldValue(
                  data.formValues[`${getFieldName(DataPointKeys.TRUSTED_CONTACT_ZIP_CODE, paperworkFreeFormId)}-${i}`],
                ),
                addressLine1: getPaperworkStringFieldValue(
                  data.formValues[
                    `${getFieldName(DataPointKeys.TRUSTED_CONTACT_STREET_ADDRESS, paperworkFreeFormId)}-${i}`
                  ],
                ),
                addressLine2:
                  getPaperworkStringFieldValue(
                    data.formValues[
                      `${getFieldName(DataPointKeys.TRUSTED_CONTACT_STREET_LINE, paperworkFreeFormId)}-${i}`
                    ],
                  ) ?? '',
                type: AddressType.HOME,
              },
            ],
            identifiers: [],
          },
          relationshipType: getPaperworkEnumFieldValue(
            RelationshipSubtype,
            data.formValues[`${getFieldName(DataPointKeys.TRUSTED_CONTACT_RELATIONSHIP, paperworkFreeFormId)}-${i}`],
            RelationshipSubtype.OTHER,
          ),
        };
        paperworkInput.relationships = [...(paperworkInput.relationships ?? []), trustedContact];
      }
    });
  }
};
