import {
  GetRiskPreferenceVolatilityMappings,
  GetRiskPreferenceVolatilityMappings_riskPreferenceVolatilityMappings,
  GetRiskPreferenceVolatilityMappingsVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type RiskPreferenceVolatilityMappings = GetRiskPreferenceVolatilityMappings_riskPreferenceVolatilityMappings;

export const useGetRiskPreferenceVolatilityMappings = (
  options?: QueryHookOptions<GetRiskPreferenceVolatilityMappings, GetRiskPreferenceVolatilityMappingsVariables>,
): QueryResult<GetRiskPreferenceVolatilityMappings, GetRiskPreferenceVolatilityMappingsVariables> => {
  return useSymphonyQuery(queries.GetRiskPreferenceVolatilityMappings, options);
};
