import { Link, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { Dot } from '../../../components/Dot';
import { Style } from '../../../styles';

export interface ImportantLinks {
  link: string;
  text: string;
}

export interface DisclosureContent {
  disclosureList: string[];
  importantLinkText: string;
  importantLinks: ImportantLinks[];
  servicesList: string[];
  servicesText: string;
  title: string;
}

interface Props {
  content: DisclosureContent;
  pdfStyles: Style;
}

export const Disclosure: React.FC<Props> = ({ content, pdfStyles }) => {
  const commonTextStyle = {
    fontSize: 10,
    marginTop: '8px',
    marginBottom: '8px',
  };

  const servicesTextStyle = {
    fontSize: 8,
  };

  return (
    <>
      <Text
        style={{
          color: pdfStyles.primaryMainColor.color,
          fontSize: 16,
          margin: '12px 0',
        }}
      >
        {content.title}
      </Text>
      {content.disclosureList.map(item => {
        return (
          <Text key={item} style={commonTextStyle}>
            {item}
          </Text>
        );
      })}
      <View style={{ ...pdfStyles.border, width: '100%', padding: '8px 16px', marginTop: '64px', textAlign: 'center' }}>
        <Text style={servicesTextStyle}>{content.servicesText}</Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: '4px',
            justifyContent: 'center',
          }}
        >
          {content.servicesList.map((item, index, arr) => {
            return (
              <View
                key={item}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '4px 0',
                }}
              >
                <Dot
                  color={pdfStyles.textPrimary.color}
                  style={{ margin: '0 4px', position: 'relative', top: '2px' }}
                />
                {index !== arr.length - 1 && <Text style={servicesTextStyle}>{item}</Text>}
              </View>
            );
          })}
          <Text style={servicesTextStyle}>{content.servicesList[content.servicesList.length - 1]}</Text>
        </View>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginTop: '8px',
          justifyContent: 'center',
        }}
      >
        <Text style={servicesTextStyle}>{content.importantLinkText}</Text>
        {content.importantLinks.map((item, index, arr) => {
          const linkStyle = { ...servicesTextStyle, marginLeft: '8px', paddingRight: '8px' };
          return (
            <View
              key={item.text}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Link
                src={item.link}
                style={
                  index !== arr.length - 1
                    ? { ...linkStyle, ...pdfStyles.verticalDivider, color: pdfStyles.disclosureImportantLink.color }
                    : { ...linkStyle, color: pdfStyles.disclosureImportantLink.color }
                }
              >
                {item.text}
              </Link>
            </View>
          );
        })}
      </View>
    </>
  );
};
