import { QueryHookOptions, QueryResult } from '@apollo/client';

import * as queries from './query.gql';

import {
  GetManagedProductInfoForRetake,
  GetManagedProductInfoForRetakeVariables,
} from '~/components/RetakeRTQWrapper/symphony/__generated__/query.v2';
import { useSymphonyQuery } from '~/utils/symphony';

export const useGetManagedProductInfoForRetake = (
  options?: QueryHookOptions<GetManagedProductInfoForRetake, GetManagedProductInfoForRetakeVariables>,
): QueryResult<GetManagedProductInfoForRetake, GetManagedProductInfoForRetakeVariables> => {
  return useSymphonyQuery(queries.GetManagedProductInfoForRetake, options);
};
