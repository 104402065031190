import { GetRetakeRTQCompletedContent, GetRetakeRTQCompletedContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetRetakeRTQCompletedContent = (
  options?: QueryHookOptions<GetRetakeRTQCompletedContent, GetRetakeRTQCompletedContentVariables>,
): QueryResult<GetRetakeRTQCompletedContent, GetRetakeRTQCompletedContentVariables> => {
  return useContentstackQuery(queries.GetRetakeRTQCompletedContent, options);
};
