import React, { FC } from 'react';

import { WidgetAction } from '../contentstack';

import { Button, Card, Grid, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface NullStateActions {
  action: string;
  route: string;
}
export interface Props {
  ctaPrimaryAction: string;
  ctaPrimaryLabel: string;
  ctaSecondaryAction?: string;
  ctaSecondaryLabel?: string;
  dataQa?: string;
  description?: string;
  heading?: string;
  imageSource?: string;
  nullStateActions?: NullStateActions[];
}

export const NullStateWidget: FC<Props> = ({
  ctaPrimaryLabel,
  ctaPrimaryAction,
  ctaSecondaryLabel,
  ctaSecondaryAction,
  dataQa = 'account-widgets-null-state',
  description,
  heading,
  imageSource,
  nullStateActions,
}) => {
  const isMediumScreen = useIsMediumScreen();
  const {
    sfWidgets: { styles: sfWidgetsStyles },
  } = useTheme();

  const getRoute = (action: WidgetAction) => {
    if (!action) {
      return undefined;
    }
    const foundAction = nullStateActions?.find(a => a.action === action);
    if (!foundAction) {
      console.error(`[Widgets/NullStateWidget] - Unable to find matching action=[${action}]`);

      return undefined;
    }

    return foundAction.route;
  };
  const ctaPrimaryRoute = getRoute(ctaPrimaryAction);
  const ctaSecondaryRoute = getRoute(ctaSecondaryAction || '');

  /**
   * Widgets are expected to be rendered in an iframe on the partner's page. Therefore, open the CTA in a new tab.
   * @param route The URL path or full URL to open in a tab.
   */
  const handleCtaClick = (route: string) => {
    if (route) {
      window.open(route, '_blank');
    } else {
      console.error(`[Widgets/NullStateWidget] - Unable to redirect to invalid route=[${route}]`);
    }
  };

  return (
    <Card
      data-qa={dataQa}
      sx={isMediumScreen ? { width: 'initial', p: 2 } : { ...sfWidgetsStyles.card }}
      variant="outlined"
    >
      <Grid
        alignItems="center"
        container
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'center', md: 'flex-start' }}
        m={0}
        spacing={{ md: 1, xs: 2 }}
        xs={12}
      >
        <Grid data-qa={`${dataQa}-image`} item md={2} xs={11}>
          {imageSource && <img alt="" src={imageSource} width={isMediumScreen ? 80 : 'initial'} />}
        </Grid>
        <Grid item md={10} xs={11}>
          <Grid container data-qa={`${dataQa}-content`} direction="column" spacing={{ md: 0, xs: 2 }}>
            <Grid item>
              <RteContent
                className="null-state-widget-heading"
                data={heading || ''}
                data-qa={`${dataQa}-heading`}
                sx={{ mb: 1 }}
              />
            </Grid>
            <Grid item>
              <RteContent
                className="null-state-widget-description"
                data={description || ''}
                data-qa={`${dataQa}-description`}
              />
            </Grid>
            <Grid item>
              <Grid container data-qa={`${dataQa}-ctas`} mt={{ md: 3, xs: 0 }} spacing={{ md: 0, xs: 1 }}>
                <Grid item md xs={12}>
                  {ctaPrimaryLabel && ctaPrimaryRoute && (
                    <Button
                      data-qa={`${dataQa}-primary-cta`}
                      fullWidth={isMediumScreen}
                      onClick={() => {
                        handleCtaClick(ctaPrimaryRoute);
                      }}
                      sx={sfWidgetsStyles.button}
                      variant="contained"
                    >
                      {ctaPrimaryLabel}
                    </Button>
                  )}
                  {ctaSecondaryLabel && ctaSecondaryRoute && (
                    <Button
                      data-qa={`${dataQa}-secondary-cta`}
                      fullWidth={isMediumScreen}
                      onClick={() => {
                        handleCtaClick(ctaSecondaryRoute);
                      }}
                      sx={{
                        ml: { md: 1, xs: 0 },
                        mt: { md: 0, xs: 1 },
                      }}
                    >
                      {ctaSecondaryLabel}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
