export enum ActiveClientColumns {
  AGGREGATED_ACCOUNT_VALUE = 'aggregatedAccountValue',
  CLIENT_NAME = 'clientName',
  CLIENT_REVIEW_STATUS = 'clientReviewStatus',
  CONTACT_DETAILS = 'contactDetails',
  PENDING_RMD_STATUS = 'pendingRmdStatus',
}

export enum ActiveClientFilters {
  CLIENT_REVIEW_STATUS = 'CLIENT_REVIEW_STATUS',
  PENDING_RMD_STATUS = 'PENDING_RMD_STATUS',
}
