import { useEffect, useState } from 'react';

import { BillingRate, calculateDiscount, Discount, DiscountType } from '~/utils/pricing';

export const usePricingSummaryDiscount = ({
  defaultFees,
  discountFees,
}: {
  defaultFees?: BillingRate[];
  discountFees?: BillingRate[];
}) => {
  const [discount, setDiscount] = useState<Discount>({
    discountType: undefined,
    discountValue: 0,
  });

  // if there's an overriddenRate with more than 1 tier then it's a basis point discount applied uniformly across all tiers, else flat percentage fee override
  useEffect(() => {
    if (defaultFees && discountFees) {
      const appliedDiscount = calculateDiscount(defaultFees, discountFees);

      if (!appliedDiscount) {
        return;
      }

      if (appliedDiscount.discountType === DiscountType.BasisPoint) {
        setDiscount(appliedDiscount);
      } else {
        setDiscount({ discountType: DiscountType.Percentage, discountValue: 0 });
      }
    }
  }, [defaultFees, discountFees]);

  return discount;
};
