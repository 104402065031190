import { CmsKeys, ComponentTypes, QuestionOrderSteps } from '../../Section/types';
import { getFieldName } from '../../Section/utils';
import { isValidNonNegativeInteger, isValidZipCode } from '../../Section/validators';
import { PaperworkDataQuestionConfig } from '../types';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';
import {
  filterValidationRules,
  getPaperworkBooleanFieldValue,
  getPaperworkDataForPrefill,
  getPaperworkStringFieldValue,
  requiredValidation,
} from './utils';

import { AddressInput, AddressType, PaperworkType } from '~/__generated__';

export const companyAddressQuestionSet: PaperworkDataQuestionConfig[] = [
  {
    properties: { rules: [{ next: QuestionKeys.COMPANY_STREET_ADDRESS_LINE_2 }] },
    questionOrSetKey: QuestionKeys.COMPANY_STREET_ADDRESS,
  },
  {
    properties: {
      optional: true,
      rules: [{ next: QuestionKeys.COMPANY_CITY }],
    },
    questionOrSetKey: QuestionKeys.COMPANY_STREET_ADDRESS_LINE_2,
  },
  {
    properties: { rules: [{ next: QuestionKeys.COMPANY_STATE }] },
    questionOrSetKey: QuestionKeys.COMPANY_CITY,
  },
  {
    properties: {
      adjacent: QuestionKeys.COMPANY_ZIP_CODE,
      rules: [{ next: QuestionKeys.COMPANY_ZIP_CODE }],
    },
    questionOrSetKey: QuestionKeys.COMPANY_STATE,
  },
  {
    properties: { rules: [{ next: QuestionKeys.COMPANY_COUNTRY }] },
    questionOrSetKey: QuestionKeys.COMPANY_ZIP_CODE,
  },
  {
    properties: {
      defaultValue: '999', // USA
      disabled: true,
      rules: [{ next: null }],
    },
    questionOrSetKey: QuestionKeys.COMPANY_COUNTRY,
  },
];

export const homeAddressQuestionSet: PaperworkDataQuestionConfig[] = [
  {
    properties: { rules: [{ next: QuestionKeys.HOME_STREET_ADDRESS_LINE_2 }] },
    questionOrSetKey: QuestionKeys.HOME_STREET_ADDRESS,
  },
  {
    properties: {
      optional: true,
      rules: [{ next: QuestionKeys.HOME_CITY }],
    },
    questionOrSetKey: QuestionKeys.HOME_STREET_ADDRESS_LINE_2,
  },
  {
    properties: { rules: [{ next: QuestionKeys.HOME_STATE }] },
    questionOrSetKey: QuestionKeys.HOME_CITY,
  },
  {
    properties: {
      adjacent: QuestionKeys.HOME_ZIP_CODE,
      rules: [{ next: QuestionKeys.HOME_ZIP_CODE }],
    },
    questionOrSetKey: QuestionKeys.HOME_STATE,
  },
  {
    properties: { rules: [{ next: QuestionKeys.HOME_COUNTRY }] },
    questionOrSetKey: QuestionKeys.HOME_ZIP_CODE,
  },
  {
    properties: {
      defaultValue: '999', // USA
      disabled: true,
    },
    questionOrSetKey: QuestionKeys.HOME_COUNTRY,
  },
];

export const mailingAddressQuestionSet: PaperworkDataQuestionConfig[] = [
  {
    properties: { rules: [{ next: QuestionKeys.MAILING_STREET_ADDRESS_LINE_2 }] },
    questionOrSetKey: QuestionKeys.MAILING_STREET_ADDRESS,
  },
  {
    properties: {
      optional: true,
      rules: [{ next: QuestionKeys.MAILING_CITY }],
    },
    questionOrSetKey: QuestionKeys.MAILING_STREET_ADDRESS_LINE_2,
  },
  {
    properties: { rules: [{ next: QuestionKeys.MAILING_STATE }] },
    questionOrSetKey: QuestionKeys.MAILING_CITY,
  },
  {
    properties: {
      adjacent: QuestionKeys.MAILING_ZIP_CODE,
      rules: [{ next: QuestionKeys.MAILING_ZIP_CODE }],
    },
    questionOrSetKey: QuestionKeys.MAILING_STATE,
  },
  {
    properties: { rules: [{ next: QuestionKeys.MAILING_COUNTRY }] },
    questionOrSetKey: QuestionKeys.MAILING_ZIP_CODE,
  },
  {
    properties: {
      defaultValue: '999', // USA
      disabled: true,
      rules: [{ next: null }],
    },
    questionOrSetKey: QuestionKeys.MAILING_COUNTRY,
  },
];

const companyAddressType = AddressType.OFFICE;
const homeAddressType = AddressType.HOME;
const mailingAddressType = AddressType.MAILING;

export const companyCity = (properties: QuestionProperties): QuestionOrderSteps => {
  return city(properties, QuestionKeys.COMPANY_CITY, companyAddressType);
};

export const companyCountry = (properties: QuestionProperties): QuestionOrderSteps => {
  return country(properties, QuestionKeys.COMPANY_COUNTRY, companyAddressType);
};

export const companyState = (properties: QuestionProperties): QuestionOrderSteps => {
  return state(properties, QuestionKeys.COMPANY_STATE, companyAddressType);
};

export const companyStreetAddress = (properties: QuestionProperties): QuestionOrderSteps => {
  return streetAddress(properties, QuestionKeys.COMPANY_STREET_ADDRESS, companyAddressType);
};

export const companyStreetAddressLine2 = (properties: QuestionProperties): QuestionOrderSteps => {
  return streetAddressLine2(properties, QuestionKeys.COMPANY_STREET_ADDRESS_LINE_2, companyAddressType);
};

export const companyZipCode = (properties: QuestionProperties): QuestionOrderSteps => {
  return zipCode(properties, QuestionKeys.COMPANY_ZIP_CODE, companyAddressType);
};

export const homeCity = (properties: QuestionProperties): QuestionOrderSteps => {
  return city(properties, QuestionKeys.HOME_CITY, homeAddressType);
};

export const homeCountry = (properties: QuestionProperties): QuestionOrderSteps => {
  return country(properties, QuestionKeys.HOME_COUNTRY, homeAddressType);
};

export const homeState = (properties: QuestionProperties): QuestionOrderSteps => {
  return state(properties, QuestionKeys.HOME_STATE, homeAddressType);
};

export const homeStreetAddress = (properties: QuestionProperties): QuestionOrderSteps => {
  return streetAddress(properties, QuestionKeys.HOME_STREET_ADDRESS, homeAddressType);
};

export const homeStreetAddressLine2 = (properties: QuestionProperties): QuestionOrderSteps => {
  return streetAddressLine2(properties, QuestionKeys.HOME_STREET_ADDRESS_LINE_2, homeAddressType);
};

export const homeZipCode = (properties: QuestionProperties): QuestionOrderSteps => {
  return zipCode(properties, QuestionKeys.HOME_ZIP_CODE, homeAddressType);
};

export const mailingCity = (properties: QuestionProperties): QuestionOrderSteps => {
  return city(properties, QuestionKeys.MAILING_CITY, mailingAddressType);
};

export const mailingCountry = (properties: QuestionProperties): QuestionOrderSteps => {
  return country(properties, QuestionKeys.MAILING_COUNTRY, mailingAddressType);
};

export const mailingState = (properties: QuestionProperties): QuestionOrderSteps => {
  return state(properties, QuestionKeys.MAILING_STATE, mailingAddressType);
};

export const mailingStreetAddress = (properties: QuestionProperties): QuestionOrderSteps => {
  return streetAddress(properties, QuestionKeys.MAILING_STREET_ADDRESS, mailingAddressType);
};

export const mailingStreetAddressLine2 = (properties: QuestionProperties): QuestionOrderSteps => {
  return streetAddressLine2(properties, QuestionKeys.MAILING_STREET_ADDRESS_LINE_2, mailingAddressType);
};

export const mailingZipCode = (properties: QuestionProperties): QuestionOrderSteps => {
  return zipCode(properties, QuestionKeys.MAILING_ZIP_CODE, mailingAddressType);
};

export const differentMailingAddress = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.DIFFERENT_MAILING_ADDRESS,
    dataPointKey: DataPointKeys.DIFFERENT_MAILING_ADDRESS,
    prefillValueGetter: data => {
      return !(getPaperworkDataForPrefill(data, paperworkFreeFormId)?.isMailingAddressSameAsHomeAddress ?? true);
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const isMailingAddressDifferentValue = getPaperworkBooleanFieldValue(
        data.formValues[getFieldName(DataPointKeys.DIFFERENT_MAILING_ADDRESS, paperworkFreeFormId)],
      );
      paperworkInput.isMailingAddressSameAsHomeAddress = !isMailingAddressDifferentValue;
      if (!isMailingAddressDifferentValue) {
        // Copy over home address fields into mailing address
        // This is based on the assumption that the home address is collected first
        const homeAddress = paperworkInput.party.addresses?.find(address => address.type === AddressType.HOME);
        if (paperworkInput.party.addresses && homeAddress) {
          // Remove the older Mailing Address if it exists
          paperworkInput.party.addresses = paperworkInput.party.addresses.filter(ad => ad.type !== AddressType.MAILING);
          paperworkInput.party.addresses.push({ ...homeAddress, type: AddressType.MAILING });
        }
      }
    },
    componentType: ComponentTypes.Checkbox,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const sameHomeMailingAddressAsPrimary = ({
  paperworkFreeFormId,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.SAME_HOME_MAILING_ADDRESS,
    dataPointKey: DataPointKeys.SAME_HOME_MAILING_ADDRESS,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.isHomeAddressDerivedFromPrimary;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const isHomeAddressDerivedFromPrimaryValue = getPaperworkBooleanFieldValue(
        data.formValues[getFieldName(DataPointKeys.SAME_HOME_MAILING_ADDRESS, paperworkFreeFormId)],
      );
      if (isHomeAddressDerivedFromPrimaryValue) {
        // Copy over home address fields into mailing address
        // This is based on the assumption that the primary's info is already saved
        const primaryPaperwork = data.allSavedPaperworkData.find(
          paperwork => paperwork.paperworkType === PaperworkType.PRIMARY,
        );
        if (primaryPaperwork) {
          const primaryHomeAndMailingAddresses =
            primaryPaperwork.party?.addresses?.filter((address: AddressInput) =>
              [AddressType.HOME, AddressType.MAILING].includes(address.type),
            ) ?? [];
          paperworkInput.isMailingAddressSameAsHomeAddress = primaryPaperwork.isMailingAddressSameAsHomeAddress;
          paperworkInput.party.addresses = paperworkInput.party.addresses?.map(
            address => primaryHomeAndMailingAddresses.find(({ type }) => type === address.type) || address,
          );
        }
      }
    },
    componentType: ComponentTypes.Checkbox,
    defaultValue: true,
    rules,
  };
};

const city = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  questionKey: QuestionKeys,
  addressType: AddressType,
): QuestionOrderSteps => {
  return {
    questionKey,
    dataPointKey: getCityDataPointKey(addressType),
    prefillValueGetter: data => {
      const address = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.addresses?.find(
        a => a.type === addressType,
      );
      return address?.countrySecondarySubdivision;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const address = paperworkInput.party.addresses?.find(a => a.type === addressType);
      const cityValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(getCityDataPointKey(addressType), paperworkFreeFormId)],
      );
      if (address) {
        address.countrySecondarySubdivision = cityValue;
      } else {
        paperworkInput.party.addresses = [
          ...(paperworkInput.party.addresses ?? []),
          {
            type: addressType,
            countrySecondarySubdivision: cityValue,
          },
        ];
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

const country = (
  { additionalValidations, adjacent, paperworkFreeFormId, defaultValue, disabled, rules, required }: QuestionProperties,
  questionKey: QuestionKeys,
  addressType: AddressType,
): QuestionOrderSteps => {
  return {
    questionKey,
    cmsKey: CmsKeys.Countries,
    dataPointKey: getCountryDataPointKey(addressType),
    prefillValueGetter: (data, content) => {
      const address = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.addresses?.find(
        a => a.type === addressType,
      );
      const countryCode = address?.countryCode;
      let sanitisedCountryCode = countryCode;
      if (countryCode && content?.countriesList) {
        sanitisedCountryCode = content.countriesList.find(
          c => c.value === countryCode || c.otherValues?.includes(countryCode),
        )?.value;
      }
      return sanitisedCountryCode;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const address = paperworkInput.party.addresses?.find(a => a.type === addressType);
      const countryValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(getCountryDataPointKey(addressType), paperworkFreeFormId)],
      );
      if (address) {
        address.countryCode = countryValue;
      } else {
        paperworkInput.party.addresses = [
          ...(paperworkInput.party.addresses ?? []),
          {
            type: addressType,
            countryCode: countryValue,
          },
        ];
      }
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    defaultValue,
    disabled,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

const state = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  questionKey: QuestionKeys,
  addressType: AddressType,
): QuestionOrderSteps => {
  return {
    questionKey,
    cmsKey: CmsKeys.States,
    dataPointKey: getStateDataPointKey(addressType),
    prefillValueGetter: (data, content) => {
      const address = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.addresses?.find(
        a => a.type === addressType,
      );
      const stateValue = address?.countryPrimarySubdivision;
      let sanitisedState = stateValue;
      if (stateValue && content?.statesList) {
        sanitisedState = content.statesList.find(
          c =>
            c.value === stateValue ||
            c.otherValues?.includes(stateValue) ||
            (typeof c.label === 'string' && c.label.toLowerCase() === stateValue.toLowerCase()),
        )?.value;
      }
      return sanitisedState;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const address = paperworkInput.party.addresses?.find(a => a.type === addressType);
      const stateValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(getStateDataPointKey(addressType), paperworkFreeFormId)],
      );
      if (address) {
        address.countryPrimarySubdivision = stateValue;
      } else {
        paperworkInput.party.addresses = [
          ...(paperworkInput.party.addresses ?? []),
          {
            type: addressType,
            countryPrimarySubdivision: stateValue,
          },
        ];
      }
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

const streetAddress = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  questionKey: QuestionKeys,
  addressType: AddressType,
): QuestionOrderSteps => {
  return {
    questionKey,
    dataPointKey: getStreetAddressDataPointKey(addressType),
    prefillValueGetter: data => {
      const address = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.addresses?.find(
        a => a.type === addressType,
      );
      return address?.addressLine1;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const address = paperworkInput.party.addresses?.find(a => a.type === addressType);
      const line1Value = getPaperworkStringFieldValue(
        data.formValues[getFieldName(getStreetAddressDataPointKey(addressType), paperworkFreeFormId)],
      );
      if (address) {
        address.addressLine1 = line1Value;
      } else {
        paperworkInput.party.addresses = [
          ...(paperworkInput.party.addresses ?? []),
          {
            type: addressType,
            addressLine1: line1Value,
          },
        ];
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

const streetAddressLine2 = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  questionKey: QuestionKeys,
  addressType: AddressType,
): QuestionOrderSteps => {
  return {
    questionKey,
    dataPointKey: getStreetLine2DataPointKey(addressType),
    prefillValueGetter: data => {
      const address = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.addresses?.find(
        a => a.type === addressType,
      );
      return address?.addressLine2;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const address = paperworkInput.party.addresses?.find(a => a.type === addressType);
      const line2Value = getPaperworkStringFieldValue(
        data.formValues[getFieldName(getStreetLine2DataPointKey(addressType), paperworkFreeFormId)],
      );
      if (address) {
        address.addressLine2 = line2Value;
      } else {
        paperworkInput.party.addresses = [
          ...(paperworkInput.party.addresses ?? []),
          {
            type: addressType,
            addressLine2: line2Value,
          },
        ];
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

const zipCode = (
  { additionalValidations, adjacent, required, rules, paperworkFreeFormId }: QuestionProperties,
  questionKey: QuestionKeys,
  addressType: AddressType,
): QuestionOrderSteps => {
  return {
    questionKey,
    dataPointKey: getZipCodeDataPointKey(addressType),
    prefillValueGetter: data => {
      const address = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.addresses?.find(
        a => a.type === addressType,
      );
      return address?.postalCode;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const address = paperworkInput.party.addresses?.find(a => a.type === addressType);
      const zipCodeValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(getZipCodeDataPointKey(addressType), paperworkFreeFormId)],
      );
      if (address) {
        address.postalCode = zipCodeValue;
      } else {
        paperworkInput.party.addresses = [
          ...(paperworkInput.party.addresses ?? []),
          {
            type: addressType,
            postalCode: zipCodeValue,
          },
        ];
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsWatchedFormFields: [getFieldName(getStateDataPointKey(addressType), paperworkFreeFormId)],
    validationsGetter: (data, content) =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            isNumber: value => (value ? isValidNonNegativeInteger(value) : true),
            validZipCode: value => {
              return isValidZipCode({
                zipcode: value,
                linkedState: getPaperworkStringFieldValue(
                  data.getFormValue(getFieldName(getStateDataPointKey(addressType), paperworkFreeFormId)),
                ),
                stateZipCodeMap: content?.stateZipCodeMap,
              });
            },
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

const getCityDataPointKey = (addressType: AddressType): DataPointKeys => {
  switch (addressType) {
    case AddressType.HOME:
      return DataPointKeys.HOME_ADDRESS_CITY;
    case AddressType.MAILING:
      return DataPointKeys.MAILING_ADDRESS_CITY;
    case AddressType.OFFICE:
      return DataPointKeys.COMPANY_ADDRESS_CITY;
    default:
      throw new Error(`Data point key for addressType '${addressType}' not found`);
  }
};

const getCountryDataPointKey = (addressType: AddressType): DataPointKeys => {
  switch (addressType) {
    case AddressType.HOME:
      return DataPointKeys.HOME_ADDRESS_COUNTRY;
    case AddressType.MAILING:
      return DataPointKeys.MAILING_ADDRESS_COUNTRY;
    case AddressType.OFFICE:
      return DataPointKeys.COMPANY_ADDRESS_COUNTRY;
    default:
      throw new Error(`Data point key for addressType '${addressType}' not found`);
  }
};

const getStateDataPointKey = (addressType: AddressType): DataPointKeys => {
  switch (addressType) {
    case AddressType.HOME:
      return DataPointKeys.HOME_ADDRESS_STATE;
    case AddressType.MAILING:
      return DataPointKeys.MAILING_ADDRESS_STATE;
    case AddressType.OFFICE:
      return DataPointKeys.COMPANY_ADDRESS_STATE;
    default:
      throw new Error(`Data point key for addressType '${addressType}' not found`);
  }
};

const getStreetAddressDataPointKey = (addressType: AddressType): DataPointKeys => {
  switch (addressType) {
    case AddressType.HOME:
      return DataPointKeys.HOME_ADDRESS_STREET_ADDRESS;
    case AddressType.MAILING:
      return DataPointKeys.MAILING_ADDRESS_STREET_ADDRESS;
    case AddressType.OFFICE:
      return DataPointKeys.COMPANY_ADDRESS_STREET_ADDRESS;
    default:
      throw new Error(`Data point key for addressType '${addressType}' not found`);
  }
};

const getStreetLine2DataPointKey = (addressType: AddressType): DataPointKeys => {
  switch (addressType) {
    case AddressType.HOME:
      return DataPointKeys.HOME_ADDRESS_STREET_LINE_2;
    case AddressType.MAILING:
      return DataPointKeys.MAILING_ADDRESS_STREET_LINE_2;
    case AddressType.OFFICE:
      return DataPointKeys.COMPANY_ADDRESS_STREET_LINE_2;
    default:
      throw new Error(`Data point key for addressType '${addressType}' not found`);
  }
};

const getZipCodeDataPointKey = (addressType: AddressType): DataPointKeys => {
  switch (addressType) {
    case AddressType.HOME:
      return DataPointKeys.HOME_ADDRESS_ZIP_CODE;
    case AddressType.MAILING:
      return DataPointKeys.MAILING_ADDRESS_ZIP_CODE;
    case AddressType.OFFICE:
      return DataPointKeys.COMPANY_ADDRESS_ZIP_CODE;
    default:
      throw new Error(`Data point key for addressType '${addressType}' not found`);
  }
};
