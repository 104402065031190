import React, { useMemo, useState } from 'react';

import { PortfolioSelectionNullState } from '../NullState';
import { PortfolioSelectionCard, PortfolioSelectionCardProps } from '../PortfolioSelectionCard';
import { getFilteredPortfolios, getGroupedPortfolios, initializeSelectedFilters } from '../utils';

import { FilterContainer, FilterContainerProps } from '~/components/PortfolioSelection/FilterContainer';
import { Grid, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { PortfolioSelectionFilters } from '~/containers/PortfolioSelection/types';
import { SfTheme } from '~/utils/theme';

export interface FilteredPortfolioSelectionProps {
  filterProps: Omit<FilterContainerProps, 'selectedFilters' | 'setSelectedFilters'>;
  higherMinimumWarningMessage?: string;
  investmentAmount?: number;
  isTaxShelteredAccountFromQuestionnaire?: boolean;
  nullStateMessage: string;
  portfolioCardProps: PortfolioSelectionCardProps;
}

export const FilteredPortfolioSelection: React.FC<FilteredPortfolioSelectionProps> = ({
  filterProps,
  higherMinimumWarningMessage,
  investmentAmount,
  isTaxShelteredAccountFromQuestionnaire,
  nullStateMessage,
  portfolioCardProps,
}) => {
  const {
    sfPortfolioSelection: { styles },
  } = useTheme<SfTheme>();

  const [selectedFilters, setSelectedFilters] = useState<PortfolioSelectionFilters>(
    initializeSelectedFilters(filterProps.portfolioFilters, isTaxShelteredAccountFromQuestionnaire),
  );
  const filteredPortfolios = useMemo(
    () =>
      getFilteredPortfolios({
        activeFilters: selectedFilters,
        portfolios: portfolioCardProps.portfolios,
      }),

    [portfolioCardProps.portfolios, selectedFilters],
  );
  const noAvailablePortfolios = filteredPortfolios.length === 0;

  const groupedPortfolios = useMemo(() => getGroupedPortfolios(filteredPortfolios), [filteredPortfolios]);

  // This flag will be used to show warning message when there is atleast one model
  // where opening minimum is higher than the investment amount
  const isModelBelowMinimumBalanceAvailable = useMemo(
    () =>
      investmentAmount !== undefined &&
      filteredPortfolios.find(
        model => parseFloat(model.accountMinimumBalanceForAccountOpening ?? '') > investmentAmount,
      ),
    [filteredPortfolios, investmentAmount],
  );

  return (
    <Grid container spacing={2}>
      <Grid item sx={{ ...styles.filterContainer }} xs={3}>
        <FilterContainer
          portfolioFilters={filterProps.portfolioFilters}
          portfolioFiltersHeader={filterProps.portfolioFiltersHeader}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </Grid>
      <Grid item xs={9}>
        {noAvailablePortfolios ? (
          <PortfolioSelectionNullState message={nullStateMessage} />
        ) : (
          <Grid container spacing={4}>
            {Array.from(groupedPortfolios.keys()).map(key => {
              const portfolioGroup = groupedPortfolios.get(key);
              if (!portfolioGroup) {
                return <></>;
              }

              return (
                <Grid item key={key} xs={12}>
                  <PortfolioSelectionCard
                    {...portfolioCardProps}
                    content={{
                      header: {
                        ...portfolioCardProps.content.header,
                        // TODO: https://sigfig.atlassian.net/browse/INV-4787
                        description: portfolioGroup[0].description,
                        title: key,
                      },
                      labels: portfolioCardProps.content.labels,
                    }}
                    investmentAmount={investmentAmount}
                    portfolios={portfolioGroup}
                  />
                </Grid>
              );
            })}
            {isModelBelowMinimumBalanceAvailable && (
              <Typography sx={{ ml: 4, mt: 5 }} variant="caption">
                {higherMinimumWarningMessage}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
