import React, { ComponentProps, FC } from 'react';

import { AccountListWidget } from './AccountListWidget';
import { NullStateActions, NullStateWidget } from './NullStateWidget';

import { FinancialAccountStatus, OnboardingStates } from '~/__generated__';
import { Alert } from '~/components/ui/Alert';
import { Box, Skeleton, useTheme } from '~/components/ui/mui';
import { useWidgetsComponent } from '~/containers/Widgets/hooks';
import { ContentOptions } from '~/utils/contentstack/src/types';

export const FINANCIAL_ACCOUNT_STATUSES_TO_INCLUDE = [
  FinancialAccountStatus.PARTIAL,
  FinancialAccountStatus.NEW,
  FinancialAccountStatus.LEGAL_DOCUMENTS_PREPARED,
  FinancialAccountStatus.LEGAL_DOCUMENTS_SIGNED,
  FinancialAccountStatus.LEGAL_DOCUMENTS_SUBMITTED,
  FinancialAccountStatus.LEGAL_DOCUMENTS_SIGNATURE_FAILED,
  FinancialAccountStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED,
  FinancialAccountStatus.ACTIVE,
];
export const ONBOARDING_STATES_FOR_PARTIALS_TO_INCLUDE = [
  OnboardingStates.RTQ,
  OnboardingStates.RTQ_RESULT,
  OnboardingStates.PORTFOLIO_SELECTION,
  OnboardingStates.PLAN,
  OnboardingStates.FUNDING,
  OnboardingStates.PAPERWORK,
  OnboardingStates.PLAYBACK,
];

export interface Props {
  accountListProps: ComponentProps<typeof AccountListWidget>['config'];
  contentOptions: ContentOptions;
  dataQa?: string;
  financialAccountStatusesToInclude?: FinancialAccountStatus[];
  nullStateActions?: NullStateActions[];
  nullStateKey?: string;
  onboardingStatesToInclude?: OnboardingStates[];
  partyId?: string;
}

const LOADING_SECTION_HEIGHT = 300;

export const Widgets: FC<Props> = ({
  accountListProps,
  contentOptions,
  dataQa = 'widgets',
  financialAccountStatusesToInclude = FINANCIAL_ACCOUNT_STATUSES_TO_INCLUDE,
  nullStateActions,
  nullStateKey,
  onboardingStatesToInclude = ONBOARDING_STATES_FOR_PARTIALS_TO_INCLUDE,
  partyId,
}) => {
  const {
    sfWidgets: { root: sfWidgetsRoot },
  } = useTheme();
  const {
    data: widgetsComponentData,
    loading: widgetsComponentLoading,
    error: widgetsComponentError,
  } = useWidgetsComponent({
    contentOptions,
    financialAccountStatusesToInclude,
    nullStateKey,
    onboardingStatesToInclude,
    partyId,
    showModelPortfolioInfo: !!accountListProps?.accountDetailsToShow?.showModelPortfolioInfo,
  });

  if (widgetsComponentLoading) {
    return (
      <Box data-qa={`${dataQa}-loading`}>
        <Skeleton height={LOADING_SECTION_HEIGHT} variant="rectangular" />
      </Box>
    );
  }

  const nullStateWidget = widgetsComponentData?.nullStateWidget;
  if (nullStateWidget) {
    return (
      <Box data-qa={dataQa} sx={{ m: 1, ...sfWidgetsRoot }}>
        <NullStateWidget
          ctaPrimaryAction={nullStateWidget.cta_primary?.action || ''}
          ctaPrimaryLabel={nullStateWidget.cta_primary?.label || ''}
          ctaSecondaryAction={nullStateWidget.cta_secondary?.action || ''}
          ctaSecondaryLabel={nullStateWidget.cta_secondary?.label || ''}
          description={nullStateWidget.description || ''}
          heading={nullStateWidget.heading || ''}
          imageSource={nullStateWidget.imageConnection?.edges?.[0]?.node?.url || ''}
          nullStateActions={nullStateActions}
        />
      </Box>
    );
  }

  if (widgetsComponentData?.accounts) {
    return (
      <Box data-qa={dataQa} sx={sfWidgetsRoot}>
        <AccountListWidget
          accounts={widgetsComponentData?.accounts}
          config={accountListProps}
          contentOptions={contentOptions}
          labels={widgetsComponentData?.contentLabels}
        />
      </Box>
    );
  }

  return <Alert contentOptions={contentOptions} error={widgetsComponentError} severity="error" />;
};
