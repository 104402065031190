import React, { useMemo, useState } from 'react';

import { SleeveTemplateGrid } from './SleeveTemplateGrid';
import { GetUserSleeveTemplates, GetUserSleeveTemplatesVariables } from './symphony/__generated__/query.v2';
import * as queries from './symphony/query.gql';
import { getSleeveTemplateData, SleeveTemplateData, useGetSleeveTemplates } from './utils';

import { ManagedProductType } from '~/__generated__';
import { useDeleteUserSleeveTemplate } from '~/components/SleeveTemplates/symphony';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { Box, Grid, Tab, TabContext, TabPanel } from '~/components/ui/mui';
import { Tabs } from '~/components/ui/Tabs';
import { Typography } from '~/components/ui/Typography';
import { AssetAllocationModalState, Portfolio } from '~/containers/PortfolioSelection/types';
import { useGetRiskPreferenceVolatilityMappings } from '~/hooks/volatility/symphony';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { ContentOptions } from '~/utils/contentstack';
import produce from '~/utils/immer';

export enum SleeveTemplateFilterState {
  All = 'All',
  Suitable = 'Suitable',
}

export enum SleeveTemplatesTabs {
  System = 'System',
  User = 'User',
}

export interface SleeveTemplateProps {
  assetClassTier?: AssetClassTier;
  contentOptions: ContentOptions;
  currentTab: SleeveTemplatesTabs;
  dataQa?: string;
  initialRiskPreference: number;
  modelPortfolios: Portfolio[];
  onQuickApplySleeveTemplate: (sleeveTemplate: SleeveTemplateData) => void;
  openAssetAllocationModal: (assetAllocationProps: AssetAllocationModalState) => void;
  partyId: string;
  productType: ManagedProductType;
  setCurrentTab: (newTab: SleeveTemplatesTabs) => void;
}

export const SleeveTemplates: React.FC<SleeveTemplateProps> = ({
  contentOptions,
  currentTab,
  dataQa = 'sleeve-templates',
  initialRiskPreference,
  modelPortfolios,
  onQuickApplySleeveTemplate,
  openAssetAllocationModal,
  partyId,
  productType,
  assetClassTier,
  setCurrentTab,
}) => {
  const [currentSleeveTemplateFilter, setCurrentSleeveTemplateFilter] = useState(SleeveTemplateFilterState.Suitable);
  const { data, loading, error } = useGetSleeveTemplates({ contentOptions, initialRiskPreference, partyId });
  const {
    modelPortfoliosContent,
    assetClassContent,
    sleeveTemplateCardContent,
    systemTemplatesArray,
    userTemplatesArray,
  } = {
    ...data,
  };
  const { data: riskPreferenceVolatilityMappingsData } = useGetRiskPreferenceVolatilityMappings({
    variables: { productType },
  });
  const riskPreferenceVolatilityList = riskPreferenceVolatilityMappingsData?.riskPreferenceVolatilityMappings || [];

  const systemSleevesData = useMemo(
    () =>
      getSleeveTemplateData(
        systemTemplatesArray ?? [],
        modelPortfolios,
        modelPortfoliosContent,
        assetClassContent,
        assetClassTier,
      ),
    [systemTemplatesArray, modelPortfoliosContent, assetClassTier],
  );
  const userSleevesData = useMemo(
    () =>
      getSleeveTemplateData(
        userTemplatesArray ?? [],
        modelPortfolios,
        modelPortfoliosContent,
        assetClassContent,
        assetClassTier,
      ),
    [userTemplatesArray, modelPortfoliosContent, assetClassTier],
  );
  const [deleteUserSleeveTemplate] = useDeleteUserSleeveTemplate();
  const deleteUserSleeveTemplateHandler = (sleeveTemplateId: number) => {
    return deleteUserSleeveTemplate({
      variables: { partyId, userSleeveTemplateId: sleeveTemplateId },
      update: (cache, deleteResult) => {
        const queryOptions = { query: queries.GetUserSleeveTemplates, variables: { partyId } };
        const userSleeveTemplateCache = cache.readQuery<GetUserSleeveTemplates, GetUserSleeveTemplatesVariables>(
          queryOptions,
        );
        if (userSleeveTemplateCache) {
          cache.writeQuery<GetUserSleeveTemplates>({
            ...queryOptions,
            data: produce(userSleeveTemplateCache, draft => {
              const userSleeveTemplates = draft.userSleeveTemplates;
              if (userSleeveTemplates && deleteResult.data?.deleteUserSleeveTemplate?.success === true) {
                const userSleeveTemplateIndex = userSleeveTemplates.findIndex(
                  userSleeveTemplate => userSleeveTemplate?.id === sleeveTemplateId,
                );
                if (userSleeveTemplateIndex !== -1) {
                  userSleeveTemplates.splice(userSleeveTemplateIndex, 1);
                }
              }
            }),
          });
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    }).then(() => {});
  };

  return (
    <Box data-qa={dataQa}>
      <TabContext value={currentTab}>
        {(loading || error) && (
          <Grid item>
            <AlertAndLoading
              ariaLabel="Loading closure items"
              contentOptions={contentOptions}
              error={error}
              loading={loading}
            />
          </Grid>
        )}
        {sleeveTemplateCardContent && (
          <>
            <Grid container sx={{ px: 2 }}>
              <Grid item xs={12}>
                <Box sx={{ mb: 4, mt: 3 }}>
                  <Typography variant="h5">{sleeveTemplateCardContent.sleeveTemplateHeader}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Tabs
                  data-qa={`${dataQa}-tabs`}
                  onChange={(newValue: SleeveTemplatesTabs) => {
                    setCurrentTab(newValue);
                  }}
                  value={currentTab}
                  variant="fullWidth"
                >
                  <Tab
                    data-qa={`${dataQa}-tab-system`}
                    label={sleeveTemplateCardContent.tabs.system_tab_label}
                    sx={{ textTransform: 'none' }}
                    value={SleeveTemplatesTabs.System}
                  />
                  <Tab
                    data-qa={`${dataQa}-tab-user`}
                    label={sleeveTemplateCardContent.tabs.user_tab_label}
                    sx={{ textTransform: 'none' }}
                    value={SleeveTemplatesTabs.User}
                  />
                </Tabs>
              </Grid>
            </Grid>
            <TabPanel value={SleeveTemplatesTabs.System}>
              <SleeveTemplateGrid
                content={sleeveTemplateCardContent}
                initialRiskPreference={initialRiskPreference}
                onQuickApplySleeveTemplate={onQuickApplySleeveTemplate}
                openAssetAllocationModal={openAssetAllocationModal}
                riskPreferenceVolatilityList={riskPreferenceVolatilityList}
                sleeveTemplates={systemSleevesData}
              />
            </TabPanel>
            <TabPanel value={SleeveTemplatesTabs.User}>
              <SleeveTemplateGrid
                content={sleeveTemplateCardContent}
                deleteSleeveTemplate={deleteUserSleeveTemplateHandler}
                filterState={currentSleeveTemplateFilter}
                initialRiskPreference={initialRiskPreference}
                isDeleteAllowed
                onQuickApplySleeveTemplate={onQuickApplySleeveTemplate}
                openAssetAllocationModal={openAssetAllocationModal}
                riskPreferenceVolatilityList={riskPreferenceVolatilityList}
                setCurrentSleeveTemplateFilter={setCurrentSleeveTemplateFilter}
                sleeveTemplates={userSleevesData}
              />
            </TabPanel>
          </>
        )}
      </TabContext>
    </Box>
  );
};
