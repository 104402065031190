import React from 'react';

import { Box, Card, CardContent, Skeleton, useTheme } from '~/components/ui/mui';

export interface Props {
  dataQa?: string;
  loading: boolean;
  showBorderEffect?: boolean;
}

export const QuestionnaireCard: React.FC<Props> = ({
  children,
  dataQa = 'questionnaire-card',
  loading,
  showBorderEffect,
}) => {
  const {
    sfQuestionnaire: { styles: sfQuestionnaireStyles },
  } = useTheme();

  return (
    <Card
      data-qa={dataQa}
      sx={{
        borderColor: { xs: 'transparent', md: sfQuestionnaireStyles.card?.borderColor },
        maxWidth: showBorderEffect ? sfQuestionnaireStyles.maxWidth : undefined,
        mb: { xs: 0, md: 3 },
        mt: { xs: 0, md: 6 },
        mx: 'auto',
        pb: { xs: 0, md: 'auto' },
        overflow: 'visible',
        position: 'relative',
      }}
      variant="outlined"
    >
      <CardContent
        sx={{
          minHeight: sfQuestionnaireStyles.card?.minHeight,
          overflow: 'hidden',
          p: { xs: 0, md: 5 },
          '.MuiFormLabel-root': {
            lineHeight: 1.5,
            mb: 2,
            width: '100%',
          },
          pb: { xs: 0, md: 5 },
        }}
      >
        {loading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          children
        )}
      </CardContent>
      {showBorderEffect && (
        <Box
          sx={{
            background: sfQuestionnaireStyles.card?.background,
            borderRadius: 1,
            height: '100%',
            ml: 2,
            position: 'absolute',
            transform: 'matrix(1, 0.06, -0.04, 1, 0, 0)',
            top: 0,
            width: '100%',
            zIndex: -1,
          }}
        />
      )}
    </Card>
  );
};
