import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Portfolio } from '~/containers/PortfolioSelection/types';

export interface PortfolioSeries {
  portfolios: Portfolio[];
  seriesBaseName: string;
  seriesId: number;
}

export const getSeriesPortfolios = (portfolios: Portfolio[]): PortfolioSeries[] => {
  const seriesList: PortfolioSeries[] = [];
  if (portfolios) {
    portfolios.forEach(portfolio => {
      const portfolioSeries = seriesList.find((series: PortfolioSeries) => series.seriesId === portfolio.seriesId);
      if (portfolioSeries) {
        portfolioSeries.portfolios.push(portfolio);
      } else {
        seriesList.push({
          portfolios: [portfolio],
          seriesBaseName: portfolio.seriesBaseName ?? '',
          seriesId: portfolio.seriesId ?? 0,
        });
      }
    });
    seriesList?.sort((a, b) => (a.seriesBaseName > b.seriesBaseName ? 1 : -1));
  }
  return seriesList;
};

export const getPortfoliosOptions = (portfolios?: (Portfolio | null)[]): DropdownItem[] => {
  const list = [{ value: '', label: 'Please Select' }];
  (portfolios ?? []).forEach(portfolio => {
    list.push({
      label: portfolio?.productName ?? '',
      value: portfolio?.internalId?.toString() ?? '',
    });
  });
  return list;
};

export const isInvalidAllocationPercentage = (
  percentage: number,
  maximumPercentageAllowed: number,
  minimumPercentageAllowed: number,
) => {
  return percentage < minimumPercentageAllowed || percentage > maximumPercentageAllowed;
};
