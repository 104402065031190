import { UpdateAdvisorFees, UpdateAdvisorFeesVariables } from './__generated__/mutation.v2';
import { GetManagedProductPricing, GetManagedProductPricingVariables } from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { BillingRateType } from '~/__generated__';
import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export const useGetManagedProductPricing = (
  options?: QueryHookOptions<GetManagedProductPricing, GetManagedProductPricingVariables>,
): QueryResult<GetManagedProductPricing, GetManagedProductPricingVariables> => {
  return useSymphonyQuery(queries.GetManagedProductPricing, options);
};

export const useUpdateAdvisorFee = (
  options?: MutationHookOptions<UpdateAdvisorFees, UpdateAdvisorFeesVariables>,
): MutationTuple<UpdateAdvisorFees, UpdateAdvisorFeesVariables> => {
  return useSymphonyMutation(mutations.UpdateAdvisorFees, options);
};

export const useApplyDiscount = (
  managedProductId: string,
  rateType: BillingRateType = BillingRateType.ANNUALIZED_PERCENTAGE,
) => {
  const [updateAdvisorFee] = useUpdateAdvisorFee();

  return async (discount: number): Promise<void> => {
    try {
      await updateAdvisorFee({
        variables: {
          managedProductId,
          rateType,
          basisPoint: Number.isInteger(discount) ? discount : undefined,
          advisorFee: Number.isInteger(discount) ? undefined : String(discount),
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Uncaught error');
    }
  };
};
