import { useEffect, useState } from 'react';

import { useGetPendingRiskScore, useGetRecommendedModelPortfolio } from './symphony';

import { ManagedProductType } from '~/__generated__/symphonyTypes.v2';
import { AsyncResult } from '~/utils/types';

export interface AutoPortfolioSelectionComponentDataHookOptions {
  brokerageAlias: string;
  managedProductId: string;
  managedProductType: ManagedProductType;
  skip?: boolean;
}

interface ModelPortfolioDetails {
  internalId: number;
  seriesId: number;
}

export interface AutoPortfolioSelectionComponentData {
  planUpdateWorkflowId: string;
  recommendedModelPortfolio: ModelPortfolioDetails;
}

export const useAutoPortfolioSelectionComponentData = ({
  brokerageAlias,
  managedProductId,
  managedProductType,
}: AutoPortfolioSelectionComponentDataHookOptions): AsyncResult<AutoPortfolioSelectionComponentData> => {
  const [state, setState] = useState<AsyncResult<AutoPortfolioSelectionComponentData>>({
    loading: true,
    error: undefined,
    data: undefined,
  });
  const {
    data: pendingRiskScoreData,
    loading: pendingRiskScoreLoading,
    error: pendingRiskScoreError,
  } = useGetPendingRiskScore({
    variables: { managedProductId },
  });
  const isTaxSheltered = pendingRiskScoreData?.managedProduct?.isTaxSheltered ?? undefined;
  const planUpdateWorkflow = pendingRiskScoreData?.managedProduct?.planUpdateWorkflows[0];
  const pendingRiskScore = planUpdateWorkflow?.riskPreference;

  const {
    data: recommendedModelPortfolioData,
    loading: recommendedModelPortfolioLoading,
    error: recommendedModelPortfolioError,
  } = useGetRecommendedModelPortfolio({
    variables: { isTaxSheltered, riskScore: pendingRiskScore, managedProductType, brokerageAlias },
    skip: isTaxSheltered === undefined || pendingRiskScore === null || pendingRiskScore === undefined,
  });
  useEffect(() => {
    if (pendingRiskScoreError || recommendedModelPortfolioError) {
      setState({
        error: pendingRiskScoreError ?? recommendedModelPortfolioError,
        loading: false,
      });
    }
  }, [pendingRiskScoreError, recommendedModelPortfolioError]);

  useEffect(() => {
    if (!pendingRiskScoreLoading && !recommendedModelPortfolioLoading) {
      const recommendedPortfolioToUse = recommendedModelPortfolioData?.modelPortfoliosV2?.modelPortfolios[0];
      const recommendedModelPortfolio = {
        internalId: recommendedPortfolioToUse?.internalId ?? 0,
        seriesId: recommendedPortfolioToUse?.seriesId ?? 0,
      };
      setState({
        loading: false,
        data: {
          recommendedModelPortfolio,
          planUpdateWorkflowId: planUpdateWorkflow?.id ?? '',
        },
      });
    }
  }, [
    recommendedModelPortfolioData,
    pendingRiskScoreLoading,
    recommendedModelPortfolioLoading,
    planUpdateWorkflow?.id,
  ]);

  return state;
};
