import React, { FC } from 'react';

import { useGetRetakeRTQCompletedContent } from './contentstack';
import { getRetakeRtqCompletedContent } from './utils';

import { LegalDocumentStatus } from '~/__generated__';
import { CompletionMessage, CompletionMessageContent } from '~/components/CompletionMessage';
import { DocusignUserType } from '~/containers/DocusignDAProCompleted';
import { ContentOptions } from '~/utils/contentstack/src/types';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  declinedBy?: DocusignUserType;
  isAdvisorNotSignee?: boolean;
  isClientDocusign?: boolean;
  isDocusignFlow?: boolean;
  isPortfolioUpdated?: boolean;
  isUserLoggedIn?: boolean;
  onPrimaryCtaCallback?: () => void;
  signingStatus?: LegalDocumentStatus;
}

export const RetakeRTQCompleted: FC<Props> = ({
  contentOptions,
  dataQa = 'retake-rtq-completed',
  isClientDocusign = false,
  signingStatus,
  isPortfolioUpdated = false,
  isDocusignFlow = false,
  isAdvisorNotSignee = false,
  isUserLoggedIn = false,
  declinedBy = DocusignUserType.FA,
  onPrimaryCtaCallback,
}) => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetRetakeRTQCompletedContent({
    variables: contentOptions,
  });

  const completionMessageContent: CompletionMessageContent = getRetakeRtqCompletedContent({
    isClientDocusign,
    signingStatus,
    isPortfolioUpdated,
    isDocusignFlow,
    isAdvisorNotSignee,
    declinedBy,
    contentData,
  });

  return (
    <CompletionMessage
      content={completionMessageContent}
      contentError={contentError}
      contentLoading={contentLoading}
      contentOptions={contentOptions}
      dataQa={dataQa}
      isUserLoggedIn={isUserLoggedIn}
      onPrimaryCtaCallback={onPrimaryCtaCallback}
    />
  );
};
