import React, { ComponentProps, Dispatch, SetStateAction } from 'react';
import { RegisterOptions, UseFormMethods } from 'react-hook-form';

import { ManagedProductPaperworkWithFreeFormId, PaperworkInputWithFreeFormId } from '../hooks/useGetPaperworkData';
import { FormData } from '../types';

import { AssetsHeldAwayV2 } from './Question/AssetsHeldAwayV2';
import { BeneficiariesV2 } from './Question/BeneficiariesV2';
import { ContactsV2 } from './Question/ContactsV2';
import { DateQuestion } from './Question/DateQuestion';
import { InputQuestion } from './Question/InputQuestion';
import { RegulatoryV2 } from './Question/RegulatoryV2';
import { Props as QuestionProps } from './Question/types';

import { FinancialAccountType } from '~/__generated__';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { FileUploader as FileUploaderComponent } from '~/components/ui/FileUploader';
import { Item } from '~/components/ui/RadioGroup';
import { CTAs } from '~/containers/AccountProfile/contentstack';
import { CMSQuestions } from '~/containers/Paperwork/contentstack';
import { ZipCodeMapItem } from '~/containers/Paperwork/hooks/useGetPaperworkData';
import { ManagedProductPaperwork, QuestionnaireData } from '~/containers/Paperwork/symphony';
import { PartyAttribute } from '~/hooks/client/symphony';
import { ContentOptions } from '~/utils/contentstack/src/types';

export type QuestionConditionType = 'EQUAL' | 'NOT_EQUAL';

export enum ComponentTypes {
  AssetsHeldAway = 'AssetsHeldAway',
  Beneficiary = 'Beneficiary',
  Checkbox = 'Checkbox',
  Contacts = 'Contacts',
  Currency = 'Currency',
  Date = 'Date',
  Dropdown = 'Dropdown',
  FileUploader = 'FileUploader',
  /*
   * Hidden ComponentType is a page modifier that is not rendered on DOM,
   * but its value maybe used to conditionally render items
   * they are NOT meant to get information on the page. Their values will
   * not be used to save information.
   */
  Hidden = 'Hidden',
  Info = 'Info',
  Input = 'Input',
  InvestmentRestrictions = 'InvestmentRestrictions',
  Label = 'Label',
  Radio = 'Radio',
  Regulatory = 'Regulatory',
  Subtext = 'Subtext',
  Text = 'Text',
  Tlh = 'Tlh',
}

export enum ValidationNames {
  MaxFileSize = 'maxFileSize',
  MaxNumberOfFiles = 'maxNumberOfFiles',
}

export enum CmsKeys {
  Countries = 'countries',
  States = 'states',
}

// only primitive data types are allowed for conditional checks
export type QuestionConditionValue = string | number | boolean;

export interface QuestionRule {
  conditions?: {
    customValueComparator?: (formValue: any) => boolean;
    dataPointKey: string;
    type?: QuestionConditionType;
    value?: QuestionConditionValue;
  }[];
  newLine?: boolean;
  next: string | null;
}

export interface Mask {
  charsToShow: number;
  maskChar: string;
}

export interface ValidationMessage {
  key: string;
  label: string;
}

export interface QuestionOrder {
  orderSteps: QuestionOrderSteps[];
  start: string | null;
}

export interface QuestionOrderSteps {
  adjacent?: string | null;
  assetsHeldAwayComponentProps?: Partial<ComponentProps<typeof AssetsHeldAwayV2>>;
  beneficiariesComponentProps?: Partial<ComponentProps<typeof BeneficiariesV2>>;
  cmsKey?: string;
  componentType: ComponentTypes;
  contactsComponentProps?: Partial<ComponentProps<typeof ContactsV2>>;
  ctas?: CTAs[];
  customComponent?: React.FC<QuestionProps>;
  dataPointKey: string;
  dateComponentProps?: Partial<ComponentProps<typeof DateQuestion>>;
  defaultValue?: string | boolean;
  disabled?: boolean;
  disabledIfPrefilled?: boolean;
  dropdownItemsGetter?: (
    data: {
      allPaperworkDataAvailableForPrefill?: ManagedProductPaperwork[];
      allSavedPaperworkData: ManagedProductPaperworkWithFreeFormId[];
      faRepCodes: DropdownItem<string>[];
      getFormValue: (fieldName?: string) => string;
      partyRelationships?: Record<string, string[]>; // partyId -> partyId[]
    },
    content: {
      countriesList?: DropdownItem<string>[];
      questionContent: QuestionContent;
      statesList?: DropdownItem<string>[];
    },
  ) => DropdownItem[];
  dropdownItemsWatchedFormFields?: string[];
  fileUploaderComponentProps?: Partial<ComponentProps<typeof FileUploaderComponent>>;
  inputComponentProps?: Partial<ComponentProps<typeof InputQuestion>>;
  paperworkFreeFormId?: string;
  paperworkInputSetter?: (
    paperworkInput: PaperworkInputWithFreeFormId,
    data: {
      allSavedPaperworkData: ManagedProductPaperworkWithFreeFormId[];
      dependentFormFieldsInvisibility: Record<string, boolean>;
      formValues: FormData;
      savedPaperworkData?: ManagedProductPaperworkWithFreeFormId;
      unmaskedValues: Record<string, string>;
    },
  ) => void;
  prefillValueGetter?: (
    data: {
      accountType: FinancialAccountType;
      allPaperworkDataAvailableForPrefill?: ManagedProductPaperwork[];
      allSavedPaperworkData: ManagedProductPaperworkWithFreeFormId[];
      getFormValue: (fieldName?: string) => string;
      partyId: string;
      questionnaireInvestWealthData: QuestionnaireData[];
      savedPaperworkData?: ManagedProductPaperworkWithFreeFormId;
    },
    content?: {
      countriesList?: DropdownItem<string>[];
      statesList?: DropdownItem<string>[];
    },
  ) => any;
  prefillWatchedFormFields?: string[];
  questionKey: string;
  questionnaireDataPointKey?: string;
  regulatoryComponentProps?: Partial<ComponentProps<typeof RegulatoryV2>>;
  rules: QuestionRule[];
  validationsGetter?: (
    data: {
      accountType: FinancialAccountType;
      allPaperworkData: ManagedProductPaperworkWithFreeFormId[];
      faPartyAttributes?: PartyAttribute[];
      getFormValue: (fieldName?: string) => any;
      hidden: boolean;
      isFieldDisabled: boolean;
      isPaperworkSaving: boolean;
    },
    content?: {
      minorAgesByState?: { minorAge: number; state?: string }[];
      stateZipCodeMap?: ZipCodeMapItem[];
    },
  ) => RegisterOptions;
  validationsWatchedFormFields?: string[];
  value?: string;
}

export interface QuestionContent {
  character_limit?: number;
  error?: boolean;
  key: string;
  options?: Item[];
  prefix?: string;
  question: string;
  suffix?: string;
}

export interface Props {
  accountProfileContent?: {
    ctas?: CTAs[];
  };
  accountType: FinancialAccountType;
  allPaperworkDataAvailableForPrefill?: ManagedProductPaperwork[];
  allSavedPaperworkData?: ManagedProductPaperworkWithFreeFormId[];
  content: {
    countriesList?: DropdownItem<string>[];
    minorAgesByState?: { minorAge: number; state?: string }[];
    questions: CMSQuestions;
    stateZipCodeMap?: ZipCodeMapItem[];
    statesList?: DropdownItem<string>[];
    validationMessages?: ValidationMessage[];
  };
  contentOptions: ContentOptions;
  dataQa?: string;
  dependentFormFieldsInvisibility: Record<string, boolean>;
  disablePageEditing: boolean;
  faPartyAttributes?: PartyAttribute[];
  faRepCodes: DropdownItem<string>[];
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  harvestLosses?: boolean;
  isAccountProfileEdit?: boolean;
  isFormSubmitted: boolean;
  isPaperworkSaving: boolean;
  isPaperworkSubmitting: boolean;
  managedProductId: string;
  onFormFieldsVisibilityChange: (newInvisibility: Record<string, boolean>) => void;
  order: QuestionOrder;
  partyId: string;
  partyRelationships?: Record<string, string[]>; // partyId -> partyId[]
  questionnaireInvestWealthData?: QuestionnaireData[];
  registerCustomDeferredPromise: (fieldName: string, deferredPromise: Promise<void>) => void;
  sectionHeading?: string;
  sectionKey: string;
  sectionNumber?: number;
  setUnmaskedValues: Dispatch<SetStateAction<Record<string, string>>>;
  showTlhButtonIcon?: boolean;
  unmaskedValues: Record<string, string>;
  unregisterCustomDeferredPromise: (fieldName: string) => void;
}
