import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError, RegisterOptions, UseFormMethods } from 'react-hook-form';

import { ManagedProductPaperworkWithFreeFormId, ZipCodeMapItem } from '../../../hooks/useGetPaperworkData';
import { FormData } from '../../../types';
import { ComponentTypes, QuestionContent, QuestionOrderSteps, ValidationMessage } from '../../types';
import { getComponentFields, getDropdownItemsFromCms, getPrefillValue } from '../utils';
import { WatchForFormFields } from '../WatchForFormFields';

import { FinancialAccountType } from '~/__generated__';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { CMSQuestions } from '~/containers/Paperwork/contentstack';
import { ManagedProductPaperwork, QuestionnaireData } from '~/containers/Paperwork/symphony';
import { PartyAttribute } from '~/hooks/client/symphony';

interface Variables {
  accountType: FinancialAccountType;
  allPaperworkDataAvailableForPrefill?: ManagedProductPaperwork[];
  allSavedPaperworkData?: ManagedProductPaperworkWithFreeFormId[];
  countriesList: DropdownItem<string>[];
  disablePageEditing: boolean;
  faPartyAttributes?: PartyAttribute[];
  faRepCodes: DropdownItem<string>[];
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  hidden: boolean;
  isFormSubmitted: boolean;
  isPaperworkSaving: boolean;
  managedProductId: string;
  minorAgesByState?: { custodialMinorAge?: number; minorAge: number; state?: string }[];
  onQuestionShow: (fieldName: string, prefillValue: any) => void;
  partyId: string;
  partyRelationships?: Record<string, string[]>; // partyId -> partyId[]
  questionInRow: QuestionOrderSteps;
  questionnaireInvestWealthData?: QuestionnaireData[];
  questionsContent: CMSQuestions;
  savedPaperworkDataForCurrentQuestion: ManagedProductPaperworkWithFreeFormId | null | undefined;
  stateZipCodeMap: ZipCodeMapItem[] | undefined;
  statesList: DropdownItem<string>[];
  validationMessages: ValidationMessage[] | undefined;
}

interface Data {
  DropdownItemsWatcher: JSX.Element | null;
  PrefillValuesWatcher: JSX.Element | null;
  ValidationWatcher: JSX.Element | null;
  componentType: ComponentTypes;
  dropdownItems: DropdownItem[];
  errorMessage: string | null | undefined;
  fieldName: string;
  inputErrors: FieldError | undefined;
  isFieldDisabled: boolean;
  prefillValue: any;
  questionContent: QuestionContent;
  validationRules: RegisterOptions;
}

export const usePaperworkQuestionManager = ({
  accountType,
  allPaperworkDataAvailableForPrefill,
  allSavedPaperworkData,
  countriesList,
  disablePageEditing,
  faPartyAttributes,
  faRepCodes,
  formHooks,
  hidden,
  isFormSubmitted,
  isPaperworkSaving,
  minorAgesByState,
  onQuestionShow,
  partyId,
  partyRelationships,
  questionInRow,
  questionnaireInvestWealthData,
  questionsContent,
  savedPaperworkDataForCurrentQuestion,
  stateZipCodeMap,
  statesList,
  validationMessages,
}: Variables): Data => {
  const { control, trigger, getValues, errors: fieldsErrors } = formHooks;

  const { fieldName, componentType, inputErrors, questionContent, errorMessage } = useMemo(
    () =>
      getComponentFields({
        fieldsErrors,
        question: questionInRow,
        singleOptionInputContents: questionsContent.single_option_input_questions,
        textInputContents: questionsContent.text_input_questions,
        validationMessages,
      }),
    [
      fieldsErrors,
      questionInRow,
      questionsContent.single_option_input_questions,
      questionsContent.text_input_questions,
      validationMessages,
    ],
  );

  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>(
    componentType === ComponentTypes.Dropdown
      ? questionInRow.dropdownItemsGetter?.(
          {
            allPaperworkDataAvailableForPrefill,
            allSavedPaperworkData: allSavedPaperworkData ?? [],
            faRepCodes,
            getFormValue: getValues,
            partyRelationships,
          },
          {
            countriesList,
            questionContent,
            statesList,
          },
        ) ?? getDropdownItemsFromCms(questionContent, questionInRow.cmsKey, countriesList, statesList)
      : [],
  );

  const [prefillValue, setPrefillValue] = useState<any>(
    getPrefillValue({
      accountType,
      allPaperworkDataAvailableForPrefill: allPaperworkDataAvailableForPrefill ?? [],
      allSavedPaperworkData: allSavedPaperworkData ?? [],
      componentType,
      countriesList,
      dropdownItems,
      getValues,
      question: questionInRow,
      questionContent,
      questionnaireInvestWealthData: questionnaireInvestWealthData ?? [],
      partyId,
      savedPaperworkData: savedPaperworkDataForCurrentQuestion,
      statesList,
    }),
  );

  const isFieldDisabled =
    (disablePageEditing || !!questionInRow.disabled || (!!prefillValue && questionInRow.disabledIfPrefilled)) ?? false;

  const updatePrefillValue = useCallback(() => {
    setPrefillValue(
      getPrefillValue({
        accountType,
        allPaperworkDataAvailableForPrefill: allPaperworkDataAvailableForPrefill ?? [],
        allSavedPaperworkData: allSavedPaperworkData ?? [],
        componentType,
        countriesList,
        dropdownItems,
        getValues,
        question: questionInRow,
        questionContent,
        questionnaireInvestWealthData: questionnaireInvestWealthData ?? [],
        partyId,
        savedPaperworkData: savedPaperworkDataForCurrentQuestion,
        statesList,
      }),
    );
  }, [
    accountType,
    allPaperworkDataAvailableForPrefill,
    allSavedPaperworkData,
    componentType,
    countriesList,
    dropdownItems,
    getValues,
    partyId,
    questionContent,
    questionInRow,
    questionnaireInvestWealthData,
    savedPaperworkDataForCurrentQuestion,
    statesList,
  ]);

  const triggerValidation = useCallback(() => {
    if (isFormSubmitted) {
      trigger(fieldName);
    }
  }, [fieldName, isFormSubmitted, trigger]);

  const updateDropdownItems = useCallback(() => {
    setDropdownItems(
      questionInRow.dropdownItemsGetter?.(
        {
          allPaperworkDataAvailableForPrefill,
          allSavedPaperworkData: allSavedPaperworkData ?? [],
          faRepCodes,
          getFormValue: getValues,
          partyRelationships,
        },
        {
          countriesList,
          questionContent,
          statesList,
        },
      ) ?? [],
    );
  }, [
    allPaperworkDataAvailableForPrefill,
    allSavedPaperworkData,
    countriesList,
    faRepCodes,
    getValues,
    partyRelationships,
    questionContent,
    questionInRow,
    statesList,
  ]);

  const PrefillValuesWatcher = useMemo(() => {
    if (questionInRow.prefillValueGetter && questionInRow.prefillWatchedFormFields?.length) {
      return (
        <WatchForFormFields
          control={control}
          fieldNames={questionInRow.prefillWatchedFormFields}
          onUpdate={updatePrefillValue}
        />
      );
    } else {
      return null;
    }
  }, [control, questionInRow, updatePrefillValue]);

  const ValidationWatcher = useMemo(() => {
    if (questionInRow.validationsGetter && questionInRow.validationsWatchedFormFields?.length && isFormSubmitted) {
      return (
        <WatchForFormFields
          control={control}
          fieldNames={questionInRow.validationsWatchedFormFields}
          onUpdate={triggerValidation}
        />
      );
    } else {
      return null;
    }
  }, [control, isFormSubmitted, questionInRow, triggerValidation]);

  const DropdownItemsWatcher = useMemo(() => {
    if (questionInRow.dropdownItemsGetter && questionInRow.dropdownItemsWatchedFormFields?.length) {
      return (
        <WatchForFormFields
          control={control}
          fieldNames={questionInRow.dropdownItemsWatchedFormFields}
          onUpdate={updateDropdownItems}
        />
      );
    } else {
      return null;
    }
  }, [control, questionInRow, updateDropdownItems]);

  const validationRules = useMemo(() => {
    return (
      questionInRow.validationsGetter?.(
        {
          accountType,
          allPaperworkData: allSavedPaperworkData ?? [],
          faPartyAttributes,
          getFormValue: getValues,
          hidden,
          isPaperworkSaving,
          isFieldDisabled,
        },
        {
          minorAgesByState,
          stateZipCodeMap,
        },
      ) ?? {}
    );
  }, [
    accountType,
    allSavedPaperworkData,
    faPartyAttributes,
    getValues,
    hidden,
    isFieldDisabled,
    isPaperworkSaving,
    minorAgesByState,
    questionInRow,
    stateZipCodeMap,
  ]);

  useEffect(() => {
    if (!hidden) {
      onQuestionShow(fieldName, prefillValue);
    }
  }, [fieldName, hidden, onQuestionShow, prefillValue]);

  const data = useMemo(() => {
    return {
      DropdownItemsWatcher,
      PrefillValuesWatcher,
      ValidationWatcher,
      componentType,
      dropdownItems,
      errorMessage,
      fieldName,
      inputErrors,
      isFieldDisabled,
      prefillValue,
      questionContent,
      validationRules,
    };
  }, [
    DropdownItemsWatcher,
    PrefillValuesWatcher,
    ValidationWatcher,
    componentType,
    dropdownItems,
    errorMessage,
    fieldName,
    inputErrors,
    isFieldDisabled,
    prefillValue,
    questionContent,
    validationRules,
  ]);

  return data;
};
