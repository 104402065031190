import { endOfYesterday } from 'date-fns';
import React, { useMemo, useState } from 'react';

import { HomeOfficeTimeFrameContent, HomeOfficeTimeFrameOptions, HomeOfficeTimeFrameValues } from '../types';

import {
  DropDownOptions,
  getAllDropdownOptions,
  getDefaultDropDownOptionsFromValue,
  getHomeOfficeTimeFrameStateFromValue,
  getHomeOfficeTimeFrameValueFromState,
  getTimeFrameOptions,
  HomeOfficeTimeFrameState,
} from './utils';

import { DatePicker } from '~/components/ui/DatePicker';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownChangeEvent } from '~/components/ui/Dropdown/types';
import { Grid } from '~/components/ui/mui';
import { useCoreConfig } from '~/utils/config';

export interface Props {
  content: HomeOfficeTimeFrameContent;
  dataQa?: string;
  handleChange: (newTimeFilterValues: HomeOfficeTimeFrameValues) => void;
  timeFrameValues: HomeOfficeTimeFrameValues;
}

export const TimeFrameFilters: React.FC<Props> = ({
  dataQa = 'time-frame-filters',
  content,
  timeFrameValues,
  handleChange,
}) => {
  const {
    components: {
      sfHomeOffice: { initiationDate },
    },
  } = useCoreConfig();
  const allPossibleTimeFrameDropDownOptions = useMemo(() => getAllDropdownOptions(initiationDate), [initiationDate]);

  const [dropDownOptions, setDropDownOptions] = useState(
    getDefaultDropDownOptionsFromValue(timeFrameValues, allPossibleTimeFrameDropDownOptions),
  );
  const [currentState, setCurrentState] = useState(getHomeOfficeTimeFrameStateFromValue(timeFrameValues));

  const timeFrameItems = useMemo(
    () => getTimeFrameOptions(content.timePeriodOptionLabels, allPossibleTimeFrameDropDownOptions),
    [content, allPossibleTimeFrameDropDownOptions],
  );

  const onTimePeriodChange = (event: DropdownChangeEvent) => {
    const selectedTimeFrame = event.target.value as HomeOfficeTimeFrameOptions;

    const filterState: HomeOfficeTimeFrameState = { selectedTimeFrame };

    const newDropDownOptions: DropDownOptions = {
      year: [],
      month: [],
      quarter: [],
    };

    if (selectedTimeFrame === HomeOfficeTimeFrameOptions.date) {
      onDateChange(endOfYesterday());
      return;
    } else if (selectedTimeFrame === HomeOfficeTimeFrameOptions.year) {
      newDropDownOptions.year = allPossibleTimeFrameDropDownOptions[selectedTimeFrame];
    } else {
      newDropDownOptions.year = allPossibleTimeFrameDropDownOptions[selectedTimeFrame].years;
    }

    setDropDownOptions(newDropDownOptions);
    setCurrentState(filterState);
  };

  const onYearChange = (event: DropdownChangeEvent) => {
    const selectedYear = event.target.value as number;
    const updateFilterState = {
      ...currentState,
      selectedYear,
      selectedDate: undefined,
      selectedQuarter: undefined,
      selectedMonth: undefined,
    };
    setCurrentState(updateFilterState);

    if (updateFilterState.selectedTimeFrame === HomeOfficeTimeFrameOptions.year) {
      handleChange(getHomeOfficeTimeFrameValueFromState(updateFilterState));
    } else {
      const updatedDropDownOptions = dropDownOptions;

      if (updateFilterState.selectedTimeFrame === HomeOfficeTimeFrameOptions.month) {
        updatedDropDownOptions.month =
          allPossibleTimeFrameDropDownOptions[HomeOfficeTimeFrameOptions.month].optionsForYear[selectedYear];
      } else {
        updatedDropDownOptions.quarter =
          allPossibleTimeFrameDropDownOptions[HomeOfficeTimeFrameOptions.quarter].optionsForYear[selectedYear];
      }

      setDropDownOptions(updatedDropDownOptions);
    }
  };

  const onMonthChange = (event: DropdownChangeEvent) => {
    const updateFilterState = {
      ...currentState,
      selectedMonth: event.target.value as number,
      selectedDate: undefined,
      selectedQuarter: undefined,
    };

    setCurrentState(updateFilterState);
    handleChange(getHomeOfficeTimeFrameValueFromState(updateFilterState));
  };

  const onQuarterChange = (event: DropdownChangeEvent) => {
    const updateFilterState = {
      ...currentState,
      selectedQuarter: event.target.value as number,
      selectedDate: undefined,
      selectedMonth: undefined,
    };

    setCurrentState(updateFilterState);
    handleChange(getHomeOfficeTimeFrameValueFromState(updateFilterState));
  };

  const onDateChange = (newDate: Date) => {
    const updateFilterState = { selectedTimeFrame: HomeOfficeTimeFrameOptions.date, selectedDate: newDate };

    setCurrentState(updateFilterState);
    handleChange(getHomeOfficeTimeFrameValueFromState(updateFilterState));
  };

  return (
    <Grid alignItems="center" columnGap={2} container data-qa={`${dataQa}-filters`}>
      <Grid item>
        <Dropdown
          items={timeFrameItems}
          label={content.timeFrameLabel}
          onChange={onTimePeriodChange}
          sx={{ minWidth: '200px' }}
          value={currentState.selectedTimeFrame}
        />
      </Grid>

      {currentState.selectedTimeFrame === HomeOfficeTimeFrameOptions.date ? (
        <Grid item>
          <DatePicker
            dataQa={`datepicker-${dataQa}`}
            disableFuture
            fullWidth
            inputLabelProps={{ sx: { ml: { xs: 0.5 }, mb: 0.5 } }}
            isControlled
            label={content.labels.date}
            minDate={initiationDate}
            onChange={onDateChange}
            value={currentState.selectedDate}
          />
        </Grid>
      ) : (
        <Grid item>
          <Dropdown
            items={dropDownOptions.year}
            label={content.labels.year}
            onChange={onYearChange}
            placeholder={content.placeHolders.year}
            sx={{ minWidth: '200px' }}
            value={currentState.selectedYear ?? ''}
          />
        </Grid>
      )}
      {currentState.selectedTimeFrame === HomeOfficeTimeFrameOptions.month && (
        <Grid item>
          <Dropdown
            MenuProps={{ PaperProps: { sx: { maxHeight: '150px' } } }}
            disabled={!currentState.selectedYear}
            items={dropDownOptions.month}
            label={content.labels.month}
            onChange={onMonthChange}
            placeholder={content.placeHolders.month}
            sx={{ minWidth: '200px' }}
            value={currentState.selectedMonth ?? ''}
          />
        </Grid>
      )}
      {currentState.selectedTimeFrame === HomeOfficeTimeFrameOptions.quarter && (
        <Grid item>
          <Dropdown
            disabled={!currentState.selectedYear}
            items={dropDownOptions.quarter}
            label={content.labels.quarter}
            onChange={onQuarterChange}
            placeholder={content.placeHolders.quarter}
            sx={{ minWidth: '200px' }}
            value={currentState.selectedQuarter ?? ''}
          />
        </Grid>
      )}
    </Grid>
  );
};
