import { QuestionOrderSteps as QuestionOrderStep } from '../../../Section/types';
import { firstName, lastName, middleName } from '../../questions/personal';
import { QuestionProperties } from '../../questions/types';

export const firstNameFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return firstName({
    ...properties,
    additionalValidations: {
      maxLength: 25,
      ...properties.additionalValidations,
    },
  });
};

export const lastNameFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return lastName({
    ...properties,
    additionalValidations: {
      maxLength: 30,
      ...properties.additionalValidations,
    },
  });
};

export const middleNameFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return middleName(
    {
      ...properties,
      additionalValidations: {
        maxLength: 10,
        pattern: new RegExp(/^[a-zA-Z]*$/),
        ...properties.additionalValidations,
      },
    },
    {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z]/g),
    },
  );
};
