import { Text, View } from '@react-pdf/renderer';
import React from 'react';

import { AllocationTable } from './AllocationTable';

import { Allocation } from '~/components/AssetAllocationTable';
import { Style } from '~/components/pdfs/styles';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { AllocationDetailsColumns, AssetAllocationTableColumns } from '~/utils/config/types';
import { ContentOptions } from '~/utils/contentstack';

export interface AllocationDetailsContent {
  assetAllocationColumns: {
    allocation: string;
    asset: string;
    assetClass: string;
    targetAllocation?: string;
    value?: string;
  };
  assetAllocationTitle: string;
  donutActualAllocationLabel?: string;
  donutTargetAllocationLabel: string;
  subtitle?: string[];
  title: string;
}

export interface AllocationDetailsData {
  assetAllocation: Allocation[];
  centerSubText: string;
  centerText: string;
  modelPortfolioName: string;
}

interface Props {
  allocation: Allocation[];
  assetClassTier?: AssetClassTier;
  centerSubText: string;
  centerText: string;
  columns?: AllocationDetailsColumns[] | AssetAllocationTableColumns[];
  content: AllocationDetailsContent;
  contentOptions: ContentOptions;
  displayAllocationBar?: boolean;
  donutFlexDirection?: 'row-reverse' | 'column';
  donutSize?: number;
  modelPortfolioDescription?: string;
  modelPortfolioName?: string;
  paddingTop: string;
  pdfStyles: Style;
  showActualAllocationInDonut?: boolean;
  showModelPortfolioDescription?: boolean;
}

export const AllocationDetails: React.FC<Props> = ({
  content,
  pdfStyles,
  paddingTop,
  showActualAllocationInDonut,
  showModelPortfolioDescription,
  ...restProps
}) => {
  const commonTextStyle = {
    color: pdfStyles.textPrimary.color,
    fontSize: 10,
    marginTop: '8px',
    marginBottom: '8px',
  };
  return (
    <>
      <View
        style={{
          width: '100%',
          textAlign: 'left',
          paddingTop,
          paddingLeft: '80px',
          paddingRight: '80px',
        }}
      >
        <Text
          style={{
            color: pdfStyles.primaryMainColor.color,
            fontSize: 16,
            marginBottom: '12px',
          }}
        >
          {content.title}
        </Text>

        {showModelPortfolioDescription ? (
          <Text style={commonTextStyle}>{restProps.modelPortfolioDescription}</Text>
        ) : (
          content.subtitle && (
            <Text style={commonTextStyle}>
              {content.subtitle.map((item, index, arr) => (
                <Text
                  key={item}
                  style={
                    index !== arr.length - 1 || arr.length === 1 ? {} : { color: pdfStyles.primaryMainColor.color }
                  }
                >{`${item} `}</Text>
              ))}
            </Text>
          )
        )}
        <AllocationTable
          {...restProps}
          content={content}
          pdfStyles={pdfStyles}
          showActualAllocationInDonut={showActualAllocationInDonut}
        />
      </View>
    </>
  );
};
