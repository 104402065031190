import { QuestionOrderSteps as QuestionOrderStep } from '../../Section/types';
import {
  companyCountry,
  companyState,
  differentMailingAddress,
  homeCountry,
  mailingCountry,
  mailingState,
  sameHomeMailingAddressAsPrimary,
} from '../questions/address';
import { designatePrimaryBeneficiary, designateTrustedContact } from '../questions/beneficiary';
import { eDelivery } from '../questions/eDelivery';
import { employmentStatus, incomeSource, otherIncomeSource } from '../questions/employment';
import {
  drivingLicenseExpiryDate,
  drivingLicenseIssueDate,
  drivingLicenseState,
  identificationType,
  otherIdentificationExpiryDate,
  otherIdentificationIssueDate,
  passportCountry,
  passportExpiryDate,
  passportIssueDate,
  taxReportingType,
} from '../questions/identifiers';
import { sectionJustification } from '../questions/info';
import {
  designatePrimaryBeneficiary as designatePrimaryBeneficiaryLabel,
  employment,
  identification,
  revocablePrimaryTrustee,
} from '../questions/labels';
import {
  birthdate,
  citizenship,
  maritalStatus,
  numberOfDependents,
  residentAlienTaxStatus,
  residentType,
  usTaxStatus,
} from '../questions/personal';
import { regulatory } from '../questions/regulatory';
import { QuestionKeys, QuestionProperties } from '../questions/types';
import { taxBracket, wealthExpensesText } from '../questions/wealthExpenses';

import {
  companyCityFidelityMapper,
  companyStreetAddressFidelityMapper,
  companyStreetAddressLine2FidelityMapper,
  companyZipCodeFidelityMapper,
  homeCityFidelityMapper,
  homeStateFidelityMapper,
  homeStreetAddressFidelityMapper,
  homeStreetAddressLine2FidelityMapper,
  homeZipCodeFidelityMapper,
  mailingAddressCityFidelityMapper,
  mailingAddressStreetAddressFidelityMapper,
  mailingAddressStreetAddressLine2FidelityMapper,
  mailingAddressZipCodeFidelityMapper,
} from './mappers/address';
import {
  contingentBeneficiaryFidelityMapper,
  primaryBeneficiaryFidelityMapper,
  trustedContactFidelityMapper,
} from './mappers/beneficiary';
import { confirmEmailFidelityMapper, emailFidelityMapper, phoneNumberFidelityMapper } from './mappers/contact';
import {
  companyNameFidelityMapper,
  jobTitleFidelityMapper,
  jobTitleSelfEmployedFidelityMapper,
} from './mappers/employment';
import {
  drivingLicenseIdentificationNumberFidelityMapper,
  otherIdentificationNumberFidelityMapper,
  passportIdentificationNumberFidelityMapper,
  ssnFidelityMapper,
  tinFidelityMapper,
} from './mappers/identifiers';
import { firstNameFidelityMapper, lastNameFidelityMapper, middleNameFidelityMapper } from './mappers/personal';
import {
  annualHouseholdIncomeFidelityMapper,
  annualRecurringExpensesFidelityMapper,
  estimatedNetWorthFidelityMapper,
  liquidNetWorthFidelityMapper,
} from './mappers/wealthAndExpenses';

export const mapper = (questionKey: string, properties: QuestionProperties): QuestionOrderStep => {
  switch (questionKey) {
    case QuestionKeys.ANNUAL_HOUSEHOLD_INCOME:
      return annualHouseholdIncomeFidelityMapper(properties);
    case QuestionKeys.ANNUAL_RECURRING_EXPENSES:
      return annualRecurringExpensesFidelityMapper(properties);
    case QuestionKeys.BIRTH_DATE:
      return birthdate(properties);
    case QuestionKeys.CITIZENSHIP:
      return citizenship(properties);
    case QuestionKeys.COMPANY_CITY:
      return companyCityFidelityMapper(properties);
    case QuestionKeys.COMPANY_COUNTRY:
      return companyCountry(properties);
    case QuestionKeys.COMPANY_NAME:
      return companyNameFidelityMapper(properties);
    case QuestionKeys.COMPANY_STATE:
      return companyState(properties);
    case QuestionKeys.COMPANY_STREET_ADDRESS:
      return companyStreetAddressFidelityMapper(properties);
    case QuestionKeys.COMPANY_STREET_ADDRESS_LINE_2:
      return companyStreetAddressLine2FidelityMapper(properties);
    case QuestionKeys.COMPANY_ZIP_CODE:
      return companyZipCodeFidelityMapper(properties);
    case QuestionKeys.CONFIRM_EMAIL:
      return confirmEmailFidelityMapper(properties);
    case QuestionKeys.CONTINGENT_BENEFICIARY:
      return contingentBeneficiaryFidelityMapper(properties);
    case QuestionKeys.DESIGNATE_PRIMARY_BENEFICIARY:
      return designatePrimaryBeneficiary(properties);
    case QuestionKeys.DESIGNATE_PRIMARY_BENEFICIARY_LABEL:
      return designatePrimaryBeneficiaryLabel(properties);
    case QuestionKeys.DESIGNATE_TRUSTED_CONTACT:
      return designateTrustedContact(properties);
    case QuestionKeys.DIFFERENT_MAILING_ADDRESS:
      return differentMailingAddress(properties);
    case QuestionKeys.DRIVING_LICENSE_EXPIRY_DATE:
      return drivingLicenseExpiryDate(properties);
    case QuestionKeys.DRIVING_LICENSE_IDENTIFICATION_NUMBER:
      return drivingLicenseIdentificationNumberFidelityMapper(properties);
    case QuestionKeys.DRIVING_LICENSE_ISSUE_DATE:
      return drivingLicenseIssueDate(properties);
    case QuestionKeys.DRIVING_LICENSE_STATE:
      return drivingLicenseState(properties);
    case QuestionKeys.EDELIVERY:
      return eDelivery(properties);
    case QuestionKeys.EMAIL:
      return emailFidelityMapper(properties);
    case QuestionKeys.EMPLOYMENT:
      return employment(properties);
    case QuestionKeys.EMPLOYMENT_STATUS:
      return employmentStatus(properties);
    case QuestionKeys.ESTIMATED_NET_WORTH:
      return estimatedNetWorthFidelityMapper(properties);
    case QuestionKeys.FIRST_NAME:
      return firstNameFidelityMapper(properties);
    case QuestionKeys.HOME_CITY:
      return homeCityFidelityMapper(properties);
    case QuestionKeys.HOME_COUNTRY:
      return homeCountry(properties);
    case QuestionKeys.HOME_STATE:
      return homeStateFidelityMapper(properties);
    case QuestionKeys.HOME_STREET_ADDRESS:
      return homeStreetAddressFidelityMapper(properties);
    case QuestionKeys.HOME_STREET_ADDRESS_LINE_2:
      return homeStreetAddressLine2FidelityMapper(properties);
    case QuestionKeys.HOME_ZIP_CODE:
      return homeZipCodeFidelityMapper(properties);
    case QuestionKeys.IDENTIFICATION:
      return identification(properties);
    case QuestionKeys.IDENTIFICATION_TYPE:
      return identificationType(properties);
    case QuestionKeys.INCOME_SOURCE:
      return incomeSource(properties);
    case QuestionKeys.JOB_TITLE:
      return jobTitleFidelityMapper(properties);
    case QuestionKeys.JOB_TITLE_SELF_EMPLOYED:
      return jobTitleSelfEmployedFidelityMapper(properties);
    case QuestionKeys.LAST_NAME:
      return lastNameFidelityMapper(properties);
    case QuestionKeys.LIQUID_NET_WORTH:
      return liquidNetWorthFidelityMapper(properties);
    case QuestionKeys.MAILING_CITY:
      return mailingAddressCityFidelityMapper(properties);
    case QuestionKeys.MAILING_COUNTRY:
      return mailingCountry(properties);
    case QuestionKeys.MAILING_STATE:
      return mailingState(properties);
    case QuestionKeys.MAILING_STREET_ADDRESS:
      return mailingAddressStreetAddressFidelityMapper(properties);
    case QuestionKeys.MAILING_STREET_ADDRESS_LINE_2:
      return mailingAddressStreetAddressLine2FidelityMapper(properties);
    case QuestionKeys.MAILING_ZIP_CODE:
      return mailingAddressZipCodeFidelityMapper(properties);
    case QuestionKeys.MARITAL_STATUS:
      return maritalStatus(properties);
    case QuestionKeys.MIDDLE_NAME:
      return middleNameFidelityMapper(properties);
    case QuestionKeys.NUMBER_OF_DEPENDENTS:
      return numberOfDependents(properties);
    case QuestionKeys.OTHER_IDENTIFICATION_EXPIRY_DATE:
      return otherIdentificationExpiryDate(properties);
    case QuestionKeys.OTHER_IDENTIFICATION_ISSUE_DATE:
      return otherIdentificationIssueDate(properties);
    case QuestionKeys.OTHER_IDENTIFICATION_NUMBER:
      return otherIdentificationNumberFidelityMapper(properties);
    case QuestionKeys.OTHER_INCOME_SOURCE:
      return otherIncomeSource(properties);
    case QuestionKeys.PASSPORT_COUNTRY:
      return passportCountry(properties);
    case QuestionKeys.PASSPORT_EXPIRY_DATE:
      return passportExpiryDate(properties);
    case QuestionKeys.PASSPORT_IDENTIFICATION_NUMBER:
      return passportIdentificationNumberFidelityMapper(properties);
    case QuestionKeys.PASSPORT_ISSUE_DATE:
      return passportIssueDate(properties);
    case QuestionKeys.PHONE_NUMBER:
      return phoneNumberFidelityMapper(properties);
    case QuestionKeys.PRIMARY_BENEFICIARY:
      return primaryBeneficiaryFidelityMapper(properties);
    case QuestionKeys.REGULATORY:
      return regulatory(properties);
    case QuestionKeys.RESIDENT_ALIEN_TAX_STATUS:
      return residentAlienTaxStatus(properties);
    case QuestionKeys.RESIDENT_TYPE:
      return residentType(properties);
    case QuestionKeys.REVOCABLE_PRIMARY_TRUSTEE:
      return revocablePrimaryTrustee(properties);
    case QuestionKeys.SAME_HOME_MAILING_ADDRESS:
      return sameHomeMailingAddressAsPrimary(properties);
    case QuestionKeys.SECTION_JUSTIFICATION:
      return sectionJustification(properties);
    case QuestionKeys.SSN:
      return ssnFidelityMapper(properties);
    case QuestionKeys.TAX_BRACKET:
      return taxBracket(properties);
    case QuestionKeys.TAX_REPORTING_TYPE:
      return taxReportingType(properties);
    case QuestionKeys.TIN:
      return tinFidelityMapper(properties);
    case QuestionKeys.TRUSTED_CONTACT:
      return trustedContactFidelityMapper(properties);
    case QuestionKeys.US_TAX_STATUS:
      return usTaxStatus(properties);
    case QuestionKeys.WEALTH_EXPENSES_TEXT:
      return wealthExpensesText(properties);
    default:
      throw new Error(`Question key '${questionKey}' not found`);
  }
};
