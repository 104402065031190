import { UncheckedRadioIcon } from './UncheckedIcon';

import { styled, useTheme } from '~/components/ui/mui';

export const CheckedRadioIcon = styled(UncheckedRadioIcon)(() => {
  const {
    sfRadioGroup: {
      styles: { radioIcons: radioIconStyles },
    },
  } = useTheme();

  return {
    ...radioIconStyles?.checkedIcon,
  };
});
