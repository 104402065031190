export const getFieldName = (dataPointKey: string, paperworkFreeFormId?: string) => {
  return `${paperworkFreeFormId ? `PFF${paperworkFreeFormId}:` : ''}${dataPointKey}`;
};

export const splitFieldName = (fieldName: string): { dataPointKey: string; paperworkFreeFormId?: string } => {
  const split = fieldName.split(':');
  if (split.length > 1 && split[0].startsWith('PFF')) {
    return { paperworkFreeFormId: split[0].slice(3), dataPointKey: split[1] };
  } else {
    return { dataPointKey: split[0] };
  }
};
