import { CmsKeys, ComponentTypes, QuestionOrderSteps as QuestionOrderStep } from '../../../Section/types';
import { getFieldName } from '../../../Section/utils';
import { isAddressWithoutPostBox } from '../../../Section/validators';
import {
  companyCity,
  companyStreetAddress,
  companyStreetAddressLine2,
  companyZipCode,
  homeCity,
  homeStreetAddress,
  homeStreetAddressLine2,
  homeZipCode,
  mailingCity,
  mailingStreetAddress,
  mailingStreetAddressLine2,
  mailingZipCode,
} from '../../questions/address';
import { DataPointKeys, QuestionKeys, QuestionProperties } from '../../questions/types';
import {
  filterValidationRules,
  getPaperworkDataForPrefill,
  getPaperworkStringFieldValue,
  requiredValidation,
} from '../../questions/utils';

import { AddressType } from '~/__generated__';
import { isJTWROSAccountType } from '~/utils/account';

export const companyCityFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return companyCity({
    ...properties,
    additionalValidations: {
      maxLength: 30,
      ...properties.additionalValidations,
    },
  });
};

export const companyStreetAddressFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return companyStreetAddress({
    ...properties,
    additionalValidations: {
      maxLength: 30,
      ...properties.additionalValidations,
    },
  });
};

export const companyStreetAddressLine2FidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return companyStreetAddressLine2({
    ...properties,
    additionalValidations: {
      maxLength: 30,
      ...properties.additionalValidations,
    },
  });
};

export const companyZipCodeFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return companyZipCode({
    ...properties,
    additionalValidations: {
      maxLength: 5,
      minLength: 5,
      ...properties.additionalValidations,
    },
  });
};

export const homeCityFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return homeCity({
    ...properties,
    additionalValidations: {
      maxLength: 30,
      ...properties.additionalValidations,
    },
  });
};

export const homeStateFidelityMapper = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.HOME_STATE,
    cmsKey: CmsKeys.States,
    dataPointKey: DataPointKeys.HOME_ADDRESS_STATE,
    prefillValueGetter: data => {
      const address = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.addresses?.find(
        a => a.type === AddressType.HOME,
      );
      return address?.countryPrimarySubdivision;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const address = paperworkInput.party.addresses?.find(a => a.type === AddressType.HOME);
      const stateValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.HOME_ADDRESS_STATE, paperworkFreeFormId)],
      );
      if (address) {
        address.countryPrimarySubdivision = stateValue;
      } else {
        paperworkInput.party.addresses = [
          ...(paperworkInput.party.addresses ?? []),
          {
            type: AddressType.HOME,
            countryPrimarySubdivision: stateValue,
          },
        ];
      }
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            restrictedStatesForJointAccount: value =>
              isJTWROSAccountType(data.accountType) && value ? !['LA'].includes(value) : true,
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const homeStreetAddressFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return homeStreetAddress({
    ...properties,
    additionalValidations: {
      maxLength: 50,
      ...properties.additionalValidations,
      validate: {
        postBoxNotAllowed: value => (value ? isAddressWithoutPostBox(value) : true),
        ...properties.additionalValidations.validate,
      },
    },
  });
};

export const homeStreetAddressLine2FidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return homeStreetAddressLine2({
    ...properties,
    additionalValidations: {
      maxLength: 50,
      ...properties.additionalValidations,
    },
  });
};

export const homeZipCodeFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return homeZipCode({
    ...properties,
    additionalValidations: {
      maxLength: 5,
      ...properties.additionalValidations,
    },
  });
};

export const mailingAddressCityFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return mailingCity({
    ...properties,
    additionalValidations: {
      maxLength: 30,
      ...properties.additionalValidations,
    },
  });
};

export const mailingAddressStreetAddressFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return mailingStreetAddress({
    ...properties,
    additionalValidations: {
      maxLength: 50,
      ...properties.additionalValidations,
    },
  });
};

export const mailingAddressStreetAddressLine2FidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return mailingStreetAddressLine2({
    ...properties,
    additionalValidations: {
      maxLength: 50,
      ...properties.additionalValidations,
    },
  });
};

export const mailingAddressZipCodeFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return mailingZipCode({
    ...properties,
    additionalValidations: {
      maxLength: 5,
      ...properties.additionalValidations,
    },
  });
};
