import debounce from 'lodash/debounce';
import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { Grid, InputLabel, useTheme } from '~/components/ui/mui';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { Locale } from '~/utils/contentstack';
import { getCurrencyInputProps, localeToCurrencySymbol } from '~/utils/format';
import { format as formatCurrency } from '~/utils/format/currency';
import { SfTheme } from '~/utils/theme';

export interface AccountValueProps extends React.ComponentProps<typeof Grid> {
  accountValue?: number;
  accountValueLabel?: string;
  displayAccountValueRowInline?: boolean;
  handleAccountValueChange?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const AccountValue = ({
  accountValue = 0,
  accountValueLabel,
  displayAccountValueRowInline = false,
  handleAccountValueChange,
  ...rest
}: AccountValueProps) => {
  const {
    sfPricingSummary: { styles, typographyVariants },
  } = useTheme<SfTheme>();

  const handleNumberInput = (value: NumberFormatValues) => {
    if (value.floatValue) {
      handleAccountValueChange?.(value.floatValue);
    }
  };

  return (
    <Grid item sx={styles.accountValue} {...rest}>
      {handleAccountValueChange ? (
        <>
          <InputLabel>{accountValueLabel}</InputLabel>
          <NumberFormat
            customInput={TextField}
            decimalScale={0}
            defaultValue={accountValue}
            fullWidth
            id="fieldName"
            inputProps={{ sx: styles.accountValueInput }}
            isAllowed={({ value }: NumberFormatValues): boolean =>
              value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 999999999)
            }
            onValueChange={debounce(handleNumberInput, 300)}
            variant="outlined"
            {...getCurrencyInputProps(Locale.en_us, localeToCurrencySymbol[Locale.en_us])}
          />
        </>
      ) : (
        <Grid sx={{ ...styles.accountValue, display: displayAccountValueRowInline ? 'flex' : 'block' }}>
          <Typography variant={typographyVariants?.accountValueLabel}>{accountValueLabel}</Typography>
          <Typography variant={typographyVariants?.accountValue}>{formatCurrency(accountValue)}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
