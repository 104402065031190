import React, { ComponentProps } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '~/components/ui/mui';
import { formatCurrencyToSI, formatPercentage } from '~/utils/format';
import { BillingRate } from '~/utils/pricing';

export interface Props extends ComponentProps<typeof TableContainer> {
  billingRate?: BillingRate;
  bodyColumnAlign?: [ComponentProps<typeof TableCell>['align'], ComponentProps<typeof TableCell>['align']];
  content?: {
    assetLabel?: string;
    currencySymbol?: string;
    feeLabel?: string;
    finalTierPrefix?: string;
    finalTierSuffix?: string;
  };
  dataQa?: string;
}

export const PricingSummaryTable: React.FC<Props> = ({
  billingRate,
  content,
  dataQa = 'pricing-summary-table',
  bodyColumnAlign = ['left', 'right'],
  ...rest
}) => {
  return (
    <TableContainer data-qa={`${dataQa}-container`} {...rest}>
      <Table>
        <TableHead data-qa={`${dataQa}-head`}>
          <TableRow>
            <TableCell>{content?.assetLabel}</TableCell>
            <TableCell>{content?.feeLabel}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-qa={`${dataQa}-body`}>
          {billingRate?.tiers.map(tier => {
            const assetTier = tier.upperBound
              ? `${formatCurrencyToSI(tier.lowerBound ?? 0, 0, content?.currencySymbol)}-${formatCurrencyToSI(
                  tier.upperBound,
                  0,
                  content?.currencySymbol,
                )}`
              : `${content?.finalTierPrefix}${formatCurrencyToSI(tier.lowerBound ?? 0, 0, content?.currencySymbol)}${
                  content?.finalTierSuffix
                }`;

            return (
              <TableRow key={`${tier.lowerBound}-${tier.upperBound}-${tier.rate}`}>
                <TableCell align={bodyColumnAlign[0]}>{assetTier}</TableCell>
                <TableCell align={bodyColumnAlign[1]}>
                  {formatPercentage(tier.rate, { removeTrailingZeroes: false })}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
