import axios from 'axios';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { useGetAnnualReviewClientEmailActionContent } from './contentstack';

import { CompletionMessage } from '~/components/CompletionMessage';
import { Alert } from '~/components/ui/Alert';
import { Box, Skeleton } from '~/components/ui/mui';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  clientReviewInvitationURI: string;
  contentOptions: ContentOptions;
  dataQa: string;
  emailReviewStatus: 'requested' | 'completed';
  hmac: string;
  partner: string;
}
export const ClientEmailReviewAction: FC<Props> = ({
  clientReviewInvitationURI,
  contentOptions,
  dataQa = 'clientEmailReviewAction',
  emailReviewStatus,
  hmac,
  partner,
}) => {
  const { data, loading, error } = useGetAnnualReviewClientEmailActionContent({
    variables: contentOptions,
  });
  const [success, setSuccess] = useState(false);
  const callReviewAction = async () => {
    try {
      await axios({
        method: 'put',
        url: clientReviewInvitationURI,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          hmac,
          isPartyReviewRequired: emailReviewStatus === 'requested',
          partnerId: partner,
        },
      });
      setSuccess(true);
    } catch (err) {
      console.error(err);
      setSuccess(false);
    }
  };
  useEffect(() => {
    callReviewAction();
  }, []);

  const contentData = useMemo(() => {
    // When API throws error, show fallback error message instead of an empty UI.
    const reviewAction = data?.all_annual_review?.items?.[0]?.client_email_review_action?.find(
      action => action?.action_name === (success ? emailReviewStatus : 'fallbackErrorMessage'),
    );
    return {
      heading: reviewAction?.heading ?? '',
      imageUrl: reviewAction?.imageConnection?.edges?.[0]?.node?.url ?? '',
      subHeading: {
        data: reviewAction?.sub_heading ?? '',
      },
    };
  }, [data, emailReviewStatus, success]);

  return (
    <Box data-qa={dataQa} sx={{ textAlign: 'center', my: 4 }}>
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : error ? (
        <Alert contentOptions={contentOptions} error={error} severity="error" />
      ) : (
        <CompletionMessage
          content={contentData}
          contentError={error}
          contentLoading={loading}
          contentOptions={contentOptions}
        />
      )}
    </Box>
  );
};
