import React, { FC, useMemo } from 'react';

import { useGetDocusignCompletedContent } from './contentstack';
import { getDocusignDAProCompletedContent } from './utils';

import { LegalDocumentStatus } from '~/__generated__';
import { OnboardingPageCtas } from '~/components/Onboarding/OnboardingPageCtas';
import { OnboardingPageHeader } from '~/components/Onboarding/OnboardingPageHeader';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { Grid } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export enum DocusignUserType {
  Assistant = 'Assistant',
  Client = 'Client',
  FA = 'FA',
}
export interface DocusignDaProCompletedContent {
  assistant: {
    bankAccountAssociationMessage: string;
    bankAccountAssociationPrimaryCta: string;
    bankAccountAssociationSubHeading: string;
    clientDeclinedImage: string;
    clientDeclinedMessage: string;
    declinedImage: string;
    declinedMessage: string;
    signedImage: string;
    signedMessage: string;
    signedPrimaryCtaLabel: string;
    signedPrincipalSubHeading: string;
    signedSecondaryCtaLabel: string;
    signedSubHeading: string;
  };
  clientPage: {
    bankAccountAssociationFailedMessage: string;
    clientDeclinedImage: string;
    clientDeclinedMessage: string;
    clientSignedImage: string;
    clientSignedMessage: string;
    clientSignedSubHeading: string;
  };
  common: {
    docusignStatusOtherClientSubHeading: string;
    docusignStatusOtherFASubHeading: string;
    docusignStatusOtherImage: string;
    docusignStatusOtherMessage: string;
    docusignStatusOtherSubHeading: string;
  };
  faPage: {
    bankAccountAssociationMessage: string;
    bankAccountAssociationPrimaryCta: string;
    bankAccountAssociationSubHeading: string;
    clientDeclinedImage: string;
    clientDeclinedMessage: string;
    faDeclinedImage: string;
    faDeclinedMessage: string;
    faSignedImage: string;
    faSignedMessage: string;
    faSignedPrimaryCtaLabel: string;
    faSignedPrincipalSubHeading: string;
    faSignedSecondaryCtaLabel: string;
    faSignedSubHeading: string;
  };
}

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  declinedBy?: DocusignUserType;
  isForBankAccountAssociation?: boolean;
  needsPrincipalApprovalPostFASigning?: boolean;
  onFASignedPrimaryCtaCallback?: () => void;
  onFASignedSecondaryCtaCallback?: () => void;
  partyId: string;
  showCtas: boolean;
  signingStatus: LegalDocumentStatus;
  userType: DocusignUserType;
}

export const DocusignDAProCompleted: FC<Props> = ({
  contentOptions,
  dataQa = 'docusign-da-pro-completed',
  showCtas,
  isForBankAccountAssociation,
  needsPrincipalApprovalPostFASigning,
  onFASignedPrimaryCtaCallback,
  onFASignedSecondaryCtaCallback,
  userType,
  declinedBy,
  signingStatus,
}) => {
  const {
    data: docusignCompletedContent,
    loading: docusignCompletedLoading,
    error: docusignCompletedError,
  } = useGetDocusignCompletedContent({
    variables: contentOptions,
  });

  const { faPage, clientPage, common, assistant } = getDocusignDAProCompletedContent(docusignCompletedContent);

  const content = useMemo(() => {
    if (signingStatus !== LegalDocumentStatus.SUCCEEDED && signingStatus !== LegalDocumentStatus.DECLINED) {
      return {
        message: common.docusignStatusOtherMessage,
        image: common.docusignStatusOtherImage,
        subHeading:
          userType?.toString().toUpperCase() === DocusignUserType.FA.toString().toUpperCase()
            ? common.docusignStatusOtherFASubHeading
            : userType?.toString().toUpperCase() === DocusignUserType.Client.toString().toUpperCase()
            ? common.docusignStatusOtherClientSubHeading
            : common.docusignStatusOtherSubHeading,
      };
    }
    switch (userType?.toString().toUpperCase()) {
      case DocusignUserType.Assistant.toString().toUpperCase(): {
        if (signingStatus === LegalDocumentStatus.SUCCEEDED) {
          if (isForBankAccountAssociation) {
            return {
              message: assistant.bankAccountAssociationMessage,
              subHeading: assistant.bankAccountAssociationSubHeading,
              image: assistant.signedImage,
            };
          }
          return {
            message: assistant.signedMessage,
            image: assistant.signedImage,
            subHeading: needsPrincipalApprovalPostFASigning
              ? assistant.signedPrincipalSubHeading
              : assistant.signedSubHeading,
          };
        }
        if (signingStatus === LegalDocumentStatus.DECLINED) {
          return declinedBy === DocusignUserType.Client
            ? {
                message: assistant.clientDeclinedMessage,
                image: assistant.clientDeclinedImage,
              }
            : {
                message: assistant.declinedMessage,
                image: assistant.declinedImage,
              };
        }

        return undefined;
      }
      case DocusignUserType.FA.toString().toUpperCase(): {
        if (signingStatus === LegalDocumentStatus.SUCCEEDED) {
          if (isForBankAccountAssociation) {
            return {
              message: faPage.bankAccountAssociationMessage,
              subHeading: faPage.bankAccountAssociationSubHeading,
              image: faPage.faSignedImage,
            };
          }
          return {
            message: faPage.faSignedMessage,
            image: faPage.faSignedImage,
            subHeading: needsPrincipalApprovalPostFASigning
              ? faPage.faSignedPrincipalSubHeading
              : faPage.faSignedSubHeading,
          };
        }
        if (signingStatus === LegalDocumentStatus.DECLINED) {
          return declinedBy === DocusignUserType.Client
            ? {
                message: faPage.clientDeclinedMessage,
                image: faPage.clientDeclinedImage,
              }
            : {
                message: faPage.faDeclinedMessage,
                image: faPage.faDeclinedImage,
              };
        }

        return undefined;
      }
      case DocusignUserType.Client.toString().toUpperCase():
        {
          if (signingStatus === LegalDocumentStatus.SUCCEEDED) {
            if (isForBankAccountAssociation) {
              return {
                subHeading: clientPage.bankAccountAssociationFailedMessage,
                image: clientPage.clientSignedImage,
              };
            }
            return {
              message: clientPage.clientSignedMessage,
              image: clientPage.clientSignedImage,
              subHeading: clientPage.clientSignedSubHeading,
            };
          }
          if (signingStatus === LegalDocumentStatus.DECLINED) {
            return {
              message: clientPage.clientDeclinedMessage,
              image: clientPage.clientDeclinedImage,
            };
          }
        }
        return undefined;
      default:
        return undefined;
    }
  }, [docusignCompletedContent?.all_docusign?.items, signingStatus, userType]);

  if (docusignCompletedLoading || docusignCompletedError) {
    return (
      <AlertAndLoading
        ariaLabel="Loading docusign data"
        contentOptions={contentOptions}
        error={docusignCompletedError}
        loading={docusignCompletedLoading}
      />
    );
  }

  if (!content) {
    return <></>;
  }

  return (
    <Grid container data-qa={dataQa} direction="column">
      <OnboardingPageHeader dataQa={`${dataQa}-header`} headerIcon={content.image} title={content.message} />
      {content.subHeading && (
        <Typography data-qa={`${dataQa}-sub-header`} sx={{ pb: 5, textAlign: 'center' }} variant="h3">
          {content.subHeading}
        </Typography>
      )}
      {showCtas && (
        <OnboardingPageCtas
          backButtonProps={{ onClick: onFASignedSecondaryCtaCallback }}
          content={{
            backCta: faPage.faSignedSecondaryCtaLabel,
            nextCta: isForBankAccountAssociation
              ? faPage.bankAccountAssociationPrimaryCta
              : faPage.faSignedPrimaryCtaLabel,
          }}
          dataQa={`${dataQa}-faCtas`}
          nextButtonProps={{ onClick: onFASignedPrimaryCtaCallback }}
          sx={{ m: 0 }}
        />
      )}
    </Grid>
  );
};
