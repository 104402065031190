import { populateRegulatory } from '../../Section/Question/RegulatoryV2/utils';
import { ComponentTypes, QuestionOrderSteps as QuestionOrderStep } from '../../Section/types';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';

export const regulatory = ({ paperworkFreeFormId, rules }: QuestionProperties): QuestionOrderStep => {
  return {
    regulatoryComponentProps: {
      regulatoryConfig: {
        autoFillCompanyNameFromTicker: true,
        isAffiliationLetterFieldEnabled: true,
      },
    },
    questionKey: QuestionKeys.REGULATORY,
    dataPointKey: DataPointKeys.REGULATORY,
    prefillValueGetter: _data => undefined,
    paperworkInputSetter: (paperworkInput, data) => {
      populateRegulatory(paperworkInput, data, paperworkFreeFormId);
    },
    componentType: ComponentTypes.Regulatory,
    rules,
  };
};
