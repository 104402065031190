import { range } from 'fp-ts/lib/Array';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FieldError, UseFormMethods, useWatch } from 'react-hook-form';

import { DataPointKeys, QuestionKeys } from '../../../configs/questions/types';
import { ManagedProductPaperworkWithFreeFormId, ZipCodeMapItem } from '../../../hooks/useGetPaperworkData';
import { FormData } from '../../../types';
import { CmsKeys, ValidationMessage } from '../../types';
import { getFieldName } from '../../utils';
import { isValidLength, isValidNonNegativeInteger, isValidZipCode } from '../../validators';
import {
  filterRelationshipSymphonyData,
  getDropdownItemsFromCms,
  getErrorMessage,
  getQuestionsContentForCustomComponent,
} from '../utils';

import { AddressType, ContactType, MaritalStatus, RelationshipSubtype } from '~/__generated__';
import { Alert } from '~/components/ui/Alert';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Box, Button, useTheme } from '~/components/ui/mui';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { CTAs } from '~/containers/AccountProfile/contentstack';
import { CMSQuestions } from '~/containers/Paperwork/contentstack';
import { SfTheme } from '~/utils/theme';
import { isValidEmail } from '~/utils/validations';

export interface TrustedContactConfig {
  allowSpecialCharacters: boolean;
  checkMaritalStatus: boolean;
  eitherEmailOrPhoneRequired: boolean;
  optionalCity: boolean;
  optionalEmailAddress: boolean;
  optionalPhoneNumber: boolean;
  optionalState: boolean;
  optionalStreetAddress: boolean;
  optionalZipCode: boolean;
}

export interface Props {
  ctas?: CTAs[];
  dataQa?: string;
  disablePageEditing: boolean;
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  hidden: boolean;
  isAccountProfileEdit?: boolean;
  maximumContacts: number;
  minimumContacts: number;
  paperworkFreeFormId?: string;
  questionKey: string;
  questionsContent: CMSQuestions;
  savedPaperworkData?: ManagedProductPaperworkWithFreeFormId;
  stateZipCodeMap?: ZipCodeMapItem[];
  statesList: DropdownItem<string>[];
  trustedContactConfig?: TrustedContactConfig;
  validationMessages?: ValidationMessage[];
}

export const defaultTrustedContactConfig = {
  allowSpecialCharacters: false,
  checkMaritalStatus: false,
  eitherEmailOrPhoneRequired: false,
  optionalCity: false,
  optionalEmailAddress: false,
  optionalPhoneNumber: false,
  optionalState: false,
  optionalStreetAddress: false,
  optionalZipCode: false,
};

export const ContactsV2: FC<Props> = ({
  dataQa = 'contactsV2',
  ctas,
  disablePageEditing,
  formHooks,
  hidden,
  paperworkFreeFormId,
  isAccountProfileEdit,
  minimumContacts,
  maximumContacts,
  questionKey,
  savedPaperworkData,
  questionsContent,
  stateZipCodeMap,
  statesList,
  trustedContactConfig = defaultTrustedContactConfig,
  validationMessages,
}) => {
  const {
    sfPaperwork: { styles: style },
    sfAccountProfile: { styles: accountProfileStyles },
  } = useTheme<SfTheme>();

  const [isInitialised, setIsInitialised] = useState(false);
  const [numberOfContacts, setNumberOfContacts] = useState(0);
  const [contactRelationTypeError, setContactRelationTypeError] = useState<boolean>(false);

  const { getValues, setValue, control, trigger, register, errors: fieldErrors } = formHooks;
  const contactRelationTypeIndexValueObjRef = useRef({});

  const contactFirstNameFieldName = getFieldName(DataPointKeys.TRUSTED_CONTACT_FIRST_NAME, paperworkFreeFormId);
  const contactMiddleNameFieldName = getFieldName(DataPointKeys.TRUSTED_CONTACT_MIDDLE_NAME, paperworkFreeFormId);
  const contactLastNameFieldName = getFieldName(DataPointKeys.TRUSTED_CONTACT_LAST_NAME, paperworkFreeFormId);
  const contactRelationshipFieldName = getFieldName(DataPointKeys.TRUSTED_CONTACT_RELATIONSHIP, paperworkFreeFormId);
  const contactEmailFieldName = getFieldName(DataPointKeys.TRUSTED_CONTACT_EMAIL, paperworkFreeFormId);
  const contactPhoneNumberFieldName = getFieldName(DataPointKeys.TRUSTED_CONTACT_PHONE_NUMBER, paperworkFreeFormId);
  const contactStreetAddressFieldName = getFieldName(DataPointKeys.TRUSTED_CONTACT_STREET_ADDRESS, paperworkFreeFormId);
  const contactStreetLineFieldName = getFieldName(DataPointKeys.TRUSTED_CONTACT_STREET_LINE, paperworkFreeFormId);
  const contactCityFieldName = getFieldName(DataPointKeys.TRUSTED_CONTACT_CITY, paperworkFreeFormId);
  const contactStateFieldName = getFieldName(DataPointKeys.TRUSTED_CONTACT_STATE, paperworkFreeFormId);
  const contactZipCodeFieldName = getFieldName(DataPointKeys.TRUSTED_CONTACT_ZIP_CODE, paperworkFreeFormId);
  const contactCountFieldName = getFieldName(DataPointKeys.TRUSTED_CONTACT_COUNT, paperworkFreeFormId);
  const maritalStatusFieldName = getFieldName(DataPointKeys.MARITAL_STATUS, paperworkFreeFormId);

  const contactData = useMemo(() => {
    return filterRelationshipSymphonyData(savedPaperworkData ?? null, questionKey);
  }, [savedPaperworkData, questionKey]);

  const questions = useMemo(() => {
    return getQuestionsContentForCustomComponent(questionsContent, questionKey);
  }, [questionsContent, questionKey]);

  const contactFirstNameContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_FIRST_NAME);
  const contactMiddleNameContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_MIDDLE_NAME);
  const contactLastNameContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_LAST_NAME);
  const alternateContactFirstNameContent = questions.find(
    q => q.key === QuestionKeys.TRUSTED_CONTACT_ALTERNATE_FIRST_NAME,
  );
  const alternateContactMiddleNameContent = questions.find(
    q => q.key === QuestionKeys.TRUSTED_CONTACT_ALTERNATE_MIDDLE_NAME,
  );
  const alternateContactLastNameContent = questions.find(
    q => q.key === QuestionKeys.TRUSTED_CONTACT_ALTERNATE_LAST_NAME,
  );
  const contactRelationshipContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_RELATIONSHIP);
  const contactEmailContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_EMAIL);
  const contactPhoneNumberContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_PHONE_NUMBER);
  const contactStreetAddressContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_STREET_ADDRESS);
  const contactStreetLineContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_STREET_LINE_2);
  const contactCityContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_CITY);
  const contactStateContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_STATE);
  const contactZipCodeContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_ZIP_CODE);
  const titleContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_TITLE);
  const subtitleContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_SUBTITLE);
  const addCtaContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_ADD_CTA);
  const removeCtaContent = questions.find(q => q.key === QuestionKeys.TRUSTED_CONTACT_REMOVE_CTA);

  const contactElementStyles = isAccountProfileEdit ? accountProfileStyles.contactElements : style.contactElements;

  // register a field for keeping track of the count
  register(contactCountFieldName);

  const maritalStatus = useWatch({ control, name: maritalStatusFieldName });
  useEffect(() => {
    range(0, numberOfContacts - 1).forEach(number => {
      trigger(`${contactRelationshipFieldName}-${number}`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maritalStatus]);

  const checkContactRelationTypeError = useCallback(() => {
    const spouseCount = Object.values(contactRelationTypeIndexValueObjRef.current).filter(
      contactType => contactType === RelationshipSubtype.SPOUSE,
    ).length;
    setContactRelationTypeError(spouseCount > 1);
  }, []);

  // Initialisation
  useEffect(() => {
    if (!isInitialised) {
      const newCount = contactData.length ? contactData.length : isAccountProfileEdit ? 0 : 1;
      setNumberOfContacts(newCount);
      setValue(contactCountFieldName, newCount);

      if (contactData.length) {
        const prefillValues = contactData.map((value, index) => {
          return {
            [index]: value.relationshipType,
          };
        });
        contactRelationTypeIndexValueObjRef.current = {
          ...contactRelationTypeIndexValueObjRef.current,
          ...prefillValues,
        };
        checkContactRelationTypeError();
      }

      setIsInitialised(true);
    }
  }, [
    isInitialised,
    contactCountFieldName,
    contactData,
    isAccountProfileEdit,
    setValue,
    checkContactRelationTypeError,
  ]);

  const onAdd = () => {
    const newCount = numberOfContacts + 1;
    setNumberOfContacts(newCount);
    setValue(contactCountFieldName, newCount);
  };

  const onRemove = () => {
    const newCount = numberOfContacts - 1;
    setNumberOfContacts(newCount);
    setValue(contactCountFieldName, newCount);
  };

  const getInputError = (fieldNameForError: string, questionKeyForError: string, error?: FieldError) => {
    return (
      !!error && (
        <Box data-qa={`error-${error.ref?.name}`} sx={{ width: 1 }}>
          <Typography role="alert" sx={{ color: 'error.main' }} variant="caption">
            {getErrorMessage(fieldNameForError, questionKeyForError, error.type, validationMessages)}
          </Typography>
        </Box>
      )
    );
  };

  const onContactRelationTypeChange = (index: number, value: string) => {
    contactRelationTypeIndexValueObjRef.current = { ...contactRelationTypeIndexValueObjRef.current, [index]: value };
    checkContactRelationTypeError();
  };

  if (
    [
      contactFirstNameContent,
      contactMiddleNameContent,
      contactLastNameContent,
      alternateContactFirstNameContent,
      alternateContactMiddleNameContent,
      alternateContactLastNameContent,
      contactRelationshipContent,
      contactEmailContent,
      contactPhoneNumberContent,
      contactStreetAddressContent,
      contactStreetLineContent,
      contactCityContent,
      contactStateContent,
      contactZipCodeContent,
    ].includes(undefined)
  ) {
    return <Alert severity="error">Content not found for some of the question(s) in: "{questionKey}" in CMS.</Alert>;
  }

  const addCtaText = isAccountProfileEdit
    ? numberOfContacts
      ? ctas?.find(cta => cta?.key === 'addAlternateTrustedContact')?.label
      : ctas?.find(cta => cta?.key === 'addTrustedContact')?.label
    : addCtaContent?.question;
  const ctaStyle = style.sectionCTA ? { ...style.sectionCTA } : {};

  const invalidCharactersInNameRegex = (allowSpecialCharacters?: boolean) =>
    allowSpecialCharacters ? /[^A-Za-z -]/g : /[^A-Za-z ]/g;

  return (
    <Box data-qa={dataQa}>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ mr: 1 }} variant="subtitle1">
          {titleContent?.question}
        </Typography>
        <Typography sx={{ alignSelf: 'center' }} variant="caption">
          {subtitleContent?.question}
        </Typography>
      </Box>
      {!!numberOfContacts &&
        range(0, numberOfContacts - 1).map(number => {
          return (
            <Box
              data-qa={dataQa}
              key={number}
              sx={
                isAccountProfileEdit
                  ? { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', mb: 2, mt: number ? 2 : 0 }
                  : { mb: 2 }
              }
            >
              <Box sx={{ ...contactElementStyles, mt: isAccountProfileEdit ? 1 : undefined }}>
                <Controller
                  control={control}
                  defaultValue={contactData[number]?.party.partyPerson?.givenName ?? ''}
                  name={`${contactFirstNameFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactFirstNameFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldErrors[`${contactFirstNameFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={
                        number === 0 ? contactFirstNameContent?.question : alternateContactFirstNameContent?.question
                      }
                      name={name}
                      onBlur={e => {
                        const trimmedValue = e.target.value.trim();
                        e.target.value = trimmedValue.replaceAll(/\s+/g, ' ');
                        onChange(e);
                      }}
                      onChange={e => {
                        const { value: inputValue } = e.target;
                        if (
                          contactFirstNameContent?.character_limit &&
                          inputValue.length > contactFirstNameContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        if (inputValue) {
                          e.target.value = inputValue.replaceAll(
                            invalidCharactersInNameRegex(trustedContactConfig.allowSpecialCharacters),
                            '',
                          );
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                  rules={hidden ? {} : { required: true }}
                />
                {getInputError(
                  contactFirstNameFieldName,
                  QuestionKeys.TRUSTED_CONTACT_FIRST_NAME,
                  fieldErrors[`${contactFirstNameFieldName}-${number}`],
                )}
              </Box>
              <Box sx={{ ...contactElementStyles, mt: isAccountProfileEdit ? 1 : undefined }}>
                <Controller
                  control={control}
                  defaultValue={contactData[number]?.party.partyPerson?.middleName ?? ''}
                  name={`${contactMiddleNameFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactMiddleNameFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldErrors[contactMiddleNameFieldName]}
                      fullWidth
                      inputRef={ref}
                      label={
                        number === 0 ? contactMiddleNameContent?.question : alternateContactMiddleNameContent?.question
                      }
                      name={name}
                      onBlur={e => {
                        const trimmedValue = e.target.value.trim();
                        e.target.value = trimmedValue.replaceAll(/\s+/g, ' ');
                        onChange(e);
                      }}
                      onChange={e => {
                        const { value: inputValue } = e.target;
                        if (
                          contactMiddleNameContent?.character_limit &&
                          inputValue.length > contactMiddleNameContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        if (inputValue) {
                          e.target.value = inputValue.replaceAll(invalidCharactersInNameRegex(false), '');
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                />
                {getInputError(
                  contactMiddleNameFieldName,
                  QuestionKeys.TRUSTED_CONTACT_MIDDLE_NAME,
                  fieldErrors[`${contactMiddleNameFieldName}-${number}`],
                )}
              </Box>
              <Box
                sx={{
                  ...contactElementStyles,
                  mt: isAccountProfileEdit ? 1 : undefined,
                  mr: isAccountProfileEdit ? 0 : undefined,
                }}
              >
                <Controller
                  control={control}
                  defaultValue={contactData[number]?.party.partyPerson?.familyName ?? ''}
                  disabled={disablePageEditing}
                  name={`${contactLastNameFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactLastNameFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldErrors[`${contactLastNameFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={
                        number === 0 ? contactLastNameContent?.question : alternateContactLastNameContent?.question
                      }
                      name={name}
                      onBlur={e => {
                        const trimmedValue = e.target.value.trim();
                        e.target.value = trimmedValue.replaceAll(/\s+/g, ' ');
                        onChange(e);
                      }}
                      onChange={e => {
                        const { value: inputValue } = e.target;
                        if (
                          contactLastNameContent?.character_limit &&
                          inputValue.length > contactLastNameContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        if (inputValue) {
                          e.target.value = inputValue.replaceAll(
                            invalidCharactersInNameRegex(trustedContactConfig.allowSpecialCharacters),
                            '',
                          );
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                  rules={hidden ? {} : { required: true }}
                />
                {getInputError(
                  contactLastNameFieldName,
                  QuestionKeys.TRUSTED_CONTACT_LAST_NAME,
                  fieldErrors[`${contactLastNameFieldName}-${number}`],
                )}
              </Box>
              <Box sx={{ ...contactElementStyles, mt: 1.75 }}>
                <Controller
                  control={control}
                  defaultValue={contactData[number]?.relationshipType ?? ''}
                  name={`${contactRelationshipFieldName}-${number}`}
                  render={({ onChange, value, ref }) => (
                    <Dropdown
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: style.maxDropdownHeight,
                          },
                        },
                      }}
                      dataQa={`dropdown-${contactRelationshipFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldErrors[`${contactRelationshipFieldName}-${number}`]}
                      inputRef={ref}
                      items={contactRelationshipContent?.options ?? []}
                      label={contactRelationshipContent?.question}
                      onChange={e => {
                        onContactRelationTypeChange(number, e.target.value as string);
                        onChange(e.target.value);
                      }}
                      value={value}
                      width="100%"
                    />
                  )}
                  rules={
                    hidden
                      ? {}
                      : {
                          required: true,
                          validate: {
                            onlyOneSpouseSelectionAllowed: () => !contactRelationTypeError,
                            spouseNotAllowed: value =>
                              isAccountProfileEdit ||
                              !(
                                trustedContactConfig.checkMaritalStatus &&
                                maritalStatus &&
                                maritalStatus !== MaritalStatus.MARRIED &&
                                value === RelationshipSubtype.SPOUSE
                              ),
                          },
                        }
                  }
                />
                {getInputError(
                  contactRelationshipFieldName,
                  QuestionKeys.TRUSTED_CONTACT_RELATIONSHIP,
                  fieldErrors[`${contactRelationshipFieldName}-${number}`],
                )}
              </Box>
              <Box sx={contactElementStyles}>
                <Controller
                  control={control}
                  defaultValue={
                    contactData[number]?.party.partyContacts?.find(contact => contact.type === ContactType.EMAIL)
                      ?.contact ?? ''
                  }
                  name={`${contactEmailFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactEmailFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldErrors[`${contactEmailFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={contactEmailContent?.question}
                      name={name}
                      onChange={e => {
                        const { value: inputValue } = e.target;
                        if (
                          contactEmailContent?.character_limit &&
                          inputValue.length > contactEmailContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        onChange(e);
                      }}
                      type="email"
                      value={value}
                    />
                  )}
                  rules={
                    hidden
                      ? {}
                      : {
                          required: !trustedContactConfig.optionalEmailAddress,
                          validate: {
                            isEmail: value => !value || isValidEmail(value),
                            eitherEmailOrPhoneRequired: value => {
                              const phoneNumberField = `${contactPhoneNumberFieldName}-${number}`;
                              const phoneNumberFieldValue = getValues(phoneNumberField);
                              if (trustedContactConfig.eitherEmailOrPhoneRequired && !phoneNumberFieldValue) {
                                if (!value) {
                                  return false;
                                } else {
                                  trigger(phoneNumberField);
                                }
                              }
                              return true;
                            },
                          },
                        }
                  }
                />
                {getInputError(
                  contactEmailFieldName,
                  QuestionKeys.TRUSTED_CONTACT_EMAIL,
                  fieldErrors[`${contactEmailFieldName}-${number}`],
                )}
              </Box>
              <Box sx={{ ...contactElementStyles, mr: isAccountProfileEdit ? 0 : undefined }}>
                <Controller
                  control={control}
                  defaultValue={
                    contactData[number]?.party.partyContacts?.find(contact => contact.type === ContactType.MOBILE)
                      ?.contact ?? ''
                  }
                  name={`${contactPhoneNumberFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactPhoneNumberFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldErrors[`${contactPhoneNumberFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={contactPhoneNumberContent?.question}
                      name={name}
                      onChange={e => {
                        const { value: inputValue } = e.target;
                        if (
                          contactPhoneNumberContent?.character_limit &&
                          inputValue.length > contactPhoneNumberContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        if (inputValue) {
                          e.target.value = inputValue.replaceAll(/\D/g, '');
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                  rules={
                    hidden
                      ? {}
                      : {
                          required: !trustedContactConfig.optionalPhoneNumber,
                          validate: {
                            isNumber: value => (value ? isValidNonNegativeInteger(value) : true),
                            minLength: value => (value ? value.length >= 10 : true),
                            eitherEmailOrPhoneRequired: value => {
                              const emailField = `${contactEmailFieldName}-${number}`;
                              const emailFieldValue = getValues(emailField);
                              if (trustedContactConfig.eitherEmailOrPhoneRequired && !emailFieldValue) {
                                if (!value) {
                                  return false;
                                } else {
                                  trigger(emailField);
                                }
                              }
                              return true;
                            },
                          },
                        }
                  }
                />
                {getInputError(
                  contactPhoneNumberFieldName,
                  QuestionKeys.TRUSTED_CONTACT_PHONE_NUMBER,
                  fieldErrors[`${contactPhoneNumberFieldName}-${number}`],
                )}
              </Box>
              <Box sx={contactElementStyles}>
                <Controller
                  control={control}
                  defaultValue={
                    contactData[number]?.party.addresses?.find(address => address.type === AddressType.HOME)
                      ?.addressLine1 ?? ''
                  }
                  name={`${contactStreetAddressFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactStreetAddressFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldErrors[`${contactStreetAddressFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={contactStreetAddressContent?.question}
                      name={name}
                      onBlur={e => {
                        const trimmedValue = e.target.value.trim();
                        e.target.value = trimmedValue.replaceAll(/\s+/g, ' ');
                        onChange(e);
                      }}
                      onChange={e => {
                        if (
                          contactStreetAddressContent?.character_limit &&
                          e.target.value.length > contactStreetAddressContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                  rules={hidden ? {} : { required: !trustedContactConfig.optionalStreetAddress }}
                />
                {getInputError(
                  contactStreetAddressFieldName,
                  QuestionKeys.TRUSTED_CONTACT_STREET_ADDRESS,
                  fieldErrors[`${contactStreetAddressFieldName}-${number}`],
                )}
              </Box>
              <Box sx={contactElementStyles}>
                <Controller
                  control={control}
                  defaultValue={
                    contactData[number]?.party.addresses?.find(address => address.type === AddressType.HOME)
                      ?.addressLine2 ?? ''
                  }
                  name={`${contactStreetLineFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactStreetLineFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldErrors[`${contactStreetLineFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={contactStreetLineContent?.question}
                      name={name}
                      onBlur={e => {
                        const trimmedValue = e.target.value.trim();
                        e.target.value = trimmedValue.replaceAll(/\s+/g, ' ');
                        onChange(e);
                      }}
                      onChange={e => {
                        if (
                          contactStreetLineContent?.character_limit &&
                          e.target.value.length > contactStreetLineContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                />
                {getInputError(
                  contactStreetLineFieldName,
                  QuestionKeys.TRUSTED_CONTACT_STREET_LINE_2,
                  fieldErrors[`${contactStreetLineFieldName}-${number}`],
                )}
              </Box>
              <Box sx={{ ...contactElementStyles, mr: isAccountProfileEdit ? 0 : undefined }}>
                <Controller
                  control={control}
                  defaultValue={
                    contactData[number]?.party.addresses?.find(address => address.type === AddressType.HOME)
                      ?.countrySecondarySubdivision ?? ''
                  }
                  name={`${contactCityFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactCityFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldErrors[`${contactCityFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={contactCityContent?.question}
                      name={name}
                      onBlur={e => {
                        const trimmedValue = e.target.value.trim();
                        e.target.value = trimmedValue.replaceAll(/\s+/g, ' ');
                        onChange(e);
                      }}
                      onChange={e => {
                        if (
                          contactCityContent?.character_limit &&
                          e.target.value.length > contactCityContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                  rules={hidden ? {} : { required: !trustedContactConfig.optionalCity }}
                />
                {getInputError(
                  contactCityFieldName,
                  QuestionKeys.TRUSTED_CONTACT_CITY,
                  fieldErrors[`${contactCityFieldName}-${number}`],
                )}
              </Box>
              <Box sx={{ display: 'flex', my: 2, width: isAccountProfileEdit ? 0.32 : undefined }}>
                <Box sx={{ display: 'flex', flex: '1 0', flexWrap: 'wrap', mr: 1 }}>
                  <Controller
                    control={control}
                    defaultValue={
                      contactData[number]?.party.addresses?.find(address => address.type === AddressType.HOME)
                        ?.countryPrimarySubdivision ?? ''
                    }
                    name={`${contactStateFieldName}-${number}`}
                    render={({ onChange, value, ref }) => (
                      <Dropdown
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: style.maxDropdownHeight,
                            },
                          },
                        }}
                        dataQa={`dropdown-${contactStateFieldName}-${number}`}
                        disabled={disablePageEditing}
                        error={!!fieldErrors[`${contactStateFieldName}-${number}`]}
                        inputRef={ref}
                        items={
                          contactStateContent
                            ? getDropdownItemsFromCms(contactStateContent, CmsKeys.States, [], statesList)
                            : []
                        }
                        label={contactStateContent?.question}
                        onChange={e => onChange(e.target.value)}
                        value={value}
                        width="100%"
                      />
                    )}
                    rules={hidden ? {} : { required: !trustedContactConfig.optionalState }}
                  />
                  {getInputError(
                    contactStateFieldName,
                    QuestionKeys.TRUSTED_CONTACT_STATE,
                    fieldErrors[`${contactStateFieldName}-${number}`],
                  )}
                </Box>
                <Box sx={{ display: 'flex', flex: '1 0', flexWrap: 'wrap' }}>
                  <Controller
                    control={control}
                    defaultValue={
                      contactData[number]?.party.addresses?.find(address => address.type === AddressType.HOME)
                        ?.postalCode ?? ''
                    }
                    name={`${contactZipCodeFieldName}-${number}`}
                    render={({ onChange, value, name, ref }) => (
                      <TextField
                        dataQa={`textField-${contactZipCodeFieldName}-${number}`}
                        disabled={disablePageEditing}
                        error={!!fieldErrors[`${contactZipCodeFieldName}-${number}`]}
                        fullWidth
                        inputRef={ref}
                        label={contactZipCodeContent?.question}
                        name={name}
                        onBlur={e => {
                          const trimmedValue = e.target.value.trim();
                          e.target.value = trimmedValue.replaceAll(/\s+/g, ' ');
                          onChange(e);
                        }}
                        onChange={e => {
                          const { value: inputValue } = e.target;
                          if (
                            contactZipCodeContent?.character_limit &&
                            inputValue.length > contactZipCodeContent.character_limit
                          ) {
                            e.preventDefault();
                            return;
                          }
                          if (inputValue) {
                            e.target.value = inputValue.replaceAll(/\D/g, '');
                          }
                          onChange(e);
                        }}
                        type="text"
                        value={value}
                      />
                    )}
                    rules={
                      hidden
                        ? {}
                        : {
                            required: !trustedContactConfig.optionalZipCode,
                            validate: {
                              isLength: value => (value ? isValidLength(value, 5) : true),
                              isValidZipCode: value =>
                                isValidZipCode({
                                  linkedState: getValues(`${contactStateFieldName}-${number}`),
                                  zipcode: value,
                                  stateZipCodeMap: stateZipCodeMap ?? [],
                                  isZipCodeOptional: trustedContactConfig.optionalZipCode,
                                }),
                            },
                          }
                    }
                  />
                  {getInputError(
                    contactZipCodeFieldName,
                    QuestionKeys.TRUSTED_CONTACT_ZIP_CODE,
                    fieldErrors[`${contactZipCodeFieldName}-${number}`],
                  )}
                </Box>
              </Box>
            </Box>
          );
        })}
      {isAccountProfileEdit && !!numberOfContacts && (
        <Button disabled={disablePageEditing} onClick={onRemove} sx={{ ...ctaStyle, mt: -3 }}>
          {removeCtaContent?.question ?? 'Remove'}
        </Button>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: isAccountProfileEdit ? 3 : 0 }}>
        {numberOfContacts < maximumContacts && (
          <Button
            disabled={disablePageEditing}
            onClick={onAdd}
            sx={ctaStyle}
            variant={isAccountProfileEdit ? 'outlined' : 'text'}
          >
            {addCtaText ?? `+ Add  ${numberOfContacts ? 'an Alternate' : 'a'} Trusted Contact`}
          </Button>
        )}
        {!isAccountProfileEdit && numberOfContacts > minimumContacts && (
          <Button disabled={disablePageEditing} onClick={onRemove} sx={ctaStyle}>
            {removeCtaContent?.question ?? 'Remove'}
          </Button>
        )}
      </Box>
    </Box>
  );
};
