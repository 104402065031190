import React, { FC, Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import { isAccountActivitySection } from './contentstack';
import { useGetAccountPerformanceData } from './hooks/useGetAccountPerformanceData';
import { useGetDataForPerformancePdfData } from './hooks/useGetPerformancePdfData';
import { getAccountActivityContent, getAssetAllocationContent } from './utils';

import { ManagedProductType, PerformanceMethodTypes, SleeveType } from '~/__generated__';
import { AccountAction } from '~/components/AccountActions';
import { useVisibleManagedAccounts } from '~/components/AccountHeader/useVisibleManagedAccounts';
import { AccountBalanceChart } from '~/components/AccountPerformance/AccountBalanceChart';
import { AccountOverview } from '~/components/AccountPerformance/AccountOverview';
import { AccountPerformanceActivity } from '~/components/AccountPerformance/AccountPerformanceActivity';
import { AccountPerformanceFilters } from '~/components/AccountPerformance/AccountPerformanceFilters';
import { AccountPerformanceSidebar } from '~/components/AccountPerformance/AccountPerformanceSidebar';
import DownloadablePerformanceComponent from '~/components/AccountPerformance/DownloadablePerformanceComponent';
import { MarketReview } from '~/components/AccountPerformance/MarketReview';
import { ReturnsChart } from '~/components/AccountPerformance/ReturnsChart';
import { AssetAllocation, Content as AssetAllocationContent } from '~/components/AssetAllocation';
import { Alert } from '~/components/ui/Alert';
import { DropdownChangeEvent, DropdownItem } from '~/components/ui/Dropdown/types';
import { Grid, Skeleton, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { FinancialAccountManagedProductTradingSuspensions } from '~/hooks/account-details/symphony';
import { DownloadableQprProps } from '~/hooks/account-details/types';
import { AccountDetailsFeatureFlags, getAllocations, Quarter } from '~/hooks/account-details/utils';
import { MeasurementName, ReturnsByPeriod } from '~/hooks/performance/types';
import { useReturns } from '~/hooks/performance/useReturns';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { formatPercentage } from '~/utils/format';

export interface Props {
  assetClassTier?: (program: ManagedProductType) => AssetClassTier;
  contentOptions: ContentOptions;
  dataQa?: string;
  downloadableQPRProps: DownloadableQprProps;
  featureFlags?: AccountDetailsFeatureFlags;
  initialTimePeriod?: MeasurementName;
  managedProductId?: string;
  partyId: string;
  performanceMethod?: PerformanceMethodTypes;
  returnsDataOverride?: ReturnsByPeriod;
  tradingSuspensionsFilter?: (suspension: FinancialAccountManagedProductTradingSuspensions) => boolean;
}

export const AccountPerformance: FC<Props> = ({
  assetClassTier: getAssetClassTier,
  contentOptions,
  dataQa = 'account-performance',
  downloadableQPRProps,
  featureFlags = {
    ignoreInsufficientFunds: false,
    showJointAccountClientInfo: false,
    showVerifiedBankAccounts: false,
    syncExternalBankAccounts: false,
  },
  initialTimePeriod = MeasurementName.SinceInception,
  managedProductId,
  partyId,
  performanceMethod,
  returnsDataOverride,
  tradingSuspensionsFilter = undefined,
}) => {
  const {
    sfAccountPerformance: { styles, typographyVariants },
  } = useTheme();

  const {
    components: {
      sfAccountPerformance: {
        partnerMarketReviewUrl,
        securityIds,
        showActualAllocationInDonut,
        showAdditionalClientsInfoForAccountType,
        showFactSheetLinkInAssetAllocation,
        showFullAccountNumberInPdf,
        showPartnerMarketReviewUrl,
        showPortfolioNameInAssetAllocation,
      },
      sfDownloadQPR: { allocationDetailsColumns, displayAllocationBar, disclosureAtBottom, formatPhoneNumbers },
    },
  } = useCoreConfig();
  const [selectedManagedProductId, setSelectedManagedProductId] = useState<string | undefined>(managedProductId);
  const { showVerifiedBankAccounts, syncExternalBankAccounts } = featureFlags;
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<MeasurementName>(initialTimePeriod);
  const [selectedQuarter, setSelectedQuarter] = useState<string>('');
  const [showSecurities, setShowSecurities] = useState(false);

  const seeDetailsAction: AccountAction = useMemo(
    () => ({
      type: 'custom',
      callback: (args?: { managedProductId?: string; partyId?: string }) => {
        if (args?.managedProductId) {
          setSelectedManagedProductId(args.managedProductId);
        }
      },
    }),
    [],
  );

  const onToggleShowSecuritiesInReturns = useCallback(() => {
    setShowSecurities(!showSecurities);
  }, [showSecurities, setShowSecurities]);

  const handlePerformanceFilterChange = useCallback((event: DropdownChangeEvent) => {
    setSelectedTimePeriod(event.target.value as MeasurementName);
  }, []);

  const handleQuarterChange = useCallback((event: DropdownChangeEvent) => {
    setSelectedQuarter(event.target.value as Quarter);
  }, []);

  const {
    data: accountPerformanceData,
    loading: accountPerformanceDataLoading,
    error: accountPerformanceDataError,
  } = useGetAccountPerformanceData({
    variables: {
      contentOptions,
      managedProductId: selectedManagedProductId ?? '',
      partyId,
      performanceMethod,
      securityIds,
      selectedQuarter,
      selectedTimePeriod,
      showFactSheetLinkInAssetAllocation,
      showPortfolioNameInAssetAllocation,
      showVerifiedBankAccounts,
      syncExternalBankAccounts,
    },
    skip: !selectedManagedProductId,
  });

  const startDate = accountPerformanceData?.startDate || '';
  const endDate = accountPerformanceData?.endDate || '';
  const account = accountPerformanceData?.account;
  const managedProduct = accountPerformanceData?.managedProduct;
  const assets = accountPerformanceData?.assets;
  const openingClosingBalance = accountPerformanceData?.openingClosingBalance;
  const modelPortfolioSeriesBaseName = accountPerformanceData?.modelPortfolioSeriesBaseName;
  const modelPortfolioName = accountPerformanceData?.modelPortfolioName || undefined;
  const monthlyReviewURL = showPartnerMarketReviewUrl
    ? partnerMarketReviewUrl
    : accountPerformanceData?.monthlyReviewURL;
  const accountNumberToDisplay = showFullAccountNumberInPdf ? account?.accountNumber : account?.maskedAccountNumber;
  const maskedAccountNumberDisplay = `${accountPerformanceData?.content.performanceFilters.labels.account} ${account?.maskedAccountNumber}`;

  const performancePdfResult = useGetDataForPerformancePdfData({
    variables: {
      accountType: managedProduct?.accountType ?? undefined,
      contentOptions,
      managedProductId: selectedManagedProductId ?? '',
      partyId,
      partyIdFA: downloadableQPRProps.partyIdFA,
      shouldFetchAdditionalClientsInfo:
        !!managedProduct?.accountType && showAdditionalClientsInfoForAccountType.includes(managedProduct.accountType),
    },
  });

  const { data: returnsData, loading: returnsDataLoading, error: returnsDataError } = useReturns({
    accounts: [account?.id as string],
    measurements: accountPerformanceData?.measurements || [],
    skip: !account?.id || !managedProduct?.firstRebalancedOn,
  });

  const visibleManagedAccountsArgs = useMemo(
    () => ({
      accountNumberFormat: '*${accountNumber}',
      contentOptions,
      partyId,
      seeDetailsAccountAction: seeDetailsAction,
      tradingSuspensionsFilter,
      showClosedAccounts: false,
    }),
    [contentOptions, partyId, seeDetailsAction, tradingSuspensionsFilter],
  );

  const {
    data: visibleManagedAccounts,
    error: visibleManagedAccountsError,
    loading: visibleManagedAccountsLoading,
  } = useVisibleManagedAccounts(visibleManagedAccountsArgs);

  useEffect(() => {
    if (managedProductId) {
      setSelectedManagedProductId(managedProductId);
    } else if (visibleManagedAccounts?.visibleAccounts && visibleManagedAccounts.visibleAccounts.length > 0) {
      const firstAccount = visibleManagedAccounts.visibleAccounts.find(
        (item): item is DropdownItem => (item as DropdownItem).value !== undefined,
      );
      if (firstAccount) {
        setSelectedManagedProductId(firstAccount.value.toString());
      }
    }
  }, [managedProductId, visibleManagedAccounts]);

  const returns = returnsDataOverride ?? returnsData;
  const returnsValue = returns?.[selectedTimePeriod]?.value;
  const formattedPercentageReturn = useMemo(() => {
    return returnsValue
      ? formatPercentage(parseFloat(returnsValue), {
          decimals: 2,
          removeTrailingZeroes: false,
          locale: contentOptions.locale,
        })
      : '--';
  }, [contentOptions, returnsValue]);

  const assetClassTier =
    managedProduct && getAssetClassTier ? getAssetClassTier(managedProduct.program) : AssetClassTier.MODEL;

  const formattedAllocationContent = useMemo((): AssetAllocationContent | undefined => {
    const allocationContent = accountPerformanceData?.content.assetAllocationSectionContent;
    if (allocationContent) {
      return getAssetAllocationContent(accountPerformanceData.content, allocationContent, assets);
    }
    return;
  }, [accountPerformanceData?.content, assets]);

  const accountActivityContent = useMemo(() => {
    const accountActivitySection = accountPerformanceData?.content.sections.find(isAccountActivitySection)
      ?.account_activity;
    return accountActivitySection?.account_activityConnection?.edges?.[0]?.node;
  }, [accountPerformanceData?.content.sections]);

  const cashSleeve = assets?.sleeveAllocation?.find(sleeve => sleeve.name === SleeveType.CASH);

  const handleAccountChange = useCallback(
    (event: DropdownChangeEvent) => {
      const value = event.target.value as string;
      setSelectedManagedProductId(value);
      seeDetailsAction.callback({ managedProductId: value });
    },
    [seeDetailsAction],
  );

  if (!selectedManagedProductId && !accountPerformanceDataLoading && !visibleManagedAccountsLoading) {
    return <Typography>No accounts available.</Typography>;
  }

  return (
    <>
      {accountPerformanceDataError || returnsDataError || visibleManagedAccountsError ? (
        <Alert
          contentOptions={contentOptions}
          error={accountPerformanceDataError || returnsDataError || visibleManagedAccountsError}
          severity="error"
        />
      ) : (
        <Grid container data-qa={dataQa} sx={styles.content}>
          {accountPerformanceData?.content.performanceFilters && selectedManagedProductId && (
            <Grid sx={{ ...styles.container, pt: 1, pb: 0, my: 0, borderTop: 'none', borderRadius: 0 }} width="100%">
              <Typography color="text.primary" component="h1" variant={typographyVariants?.accountNumber}>
                {maskedAccountNumberDisplay}
              </Typography>
              <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                <Grid item>
                  <AccountPerformanceFilters
                    accountOptions={visibleManagedAccounts?.visibleAccounts ?? []}
                    content={accountPerformanceData.content.performanceFilters}
                    dataQa={`${dataQa}-filters`}
                    handleAccountChange={handleAccountChange}
                    handlePerformanceFilterChange={handlePerformanceFilterChange}
                    handleQuarterChange={handleQuarterChange}
                    performanceFilterOptions={accountPerformanceData.performanceFilterDropdowns}
                    selectedAccountId={selectedManagedProductId}
                    selectedQuarter={selectedQuarter}
                    selectedTimePeriod={selectedTimePeriod}
                  />
                </Grid>
                <Grid item>
                  {accountPerformanceData.accountActivityData && openingClosingBalance && modelPortfolioSeriesBaseName && (
                    <Suspense fallback={null}>
                      <DownloadablePerformanceComponent
                        account={account}
                        accountActivityData={accountPerformanceData.accountActivityData}
                        accountNumberToDisplay={accountNumberToDisplay ?? ''}
                        assetAllocationColumns={allocationDetailsColumns}
                        assetAllocationContent={formattedAllocationContent}
                        assetClassContent={accountPerformanceData.assetClassContent}
                        assetClassTier={assetClassTier}
                        assets={assets}
                        buttonText={accountPerformanceData.content.performanceFilters.ctas.downloadPdf}
                        contentOptions={contentOptions}
                        disclosureAtBottom={disclosureAtBottom}
                        displayAllocationBar={displayAllocationBar}
                        endDate={endDate}
                        factSheetUrl={accountPerformanceData.strategyBrochureUrl ?? ''}
                        formatPhoneNumbers={formatPhoneNumbers}
                        fullWidth={false}
                        inceptionDate={managedProduct?.firstRebalancedOn ?? ''}
                        maskedAccountNumberDisplay={maskedAccountNumberDisplay}
                        modelPortfolioName={modelPortfolioName}
                        openingClosingBalance={openingClosingBalance}
                        partnerStyles={downloadableQPRProps.partnerStyles}
                        performancePdfResult={performancePdfResult}
                        returnPercentage={formattedPercentageReturn}
                        securitiesReturns={accountPerformanceData.returns.securitiesReturnsData}
                        showActualAllocationDonut={showActualAllocationInDonut}
                        showAdditionalClientsInfo={
                          !!managedProduct?.accountType &&
                          showAdditionalClientsInfoForAccountType.includes(managedProduct.accountType)
                        }
                        showFactSheetLinkInAssetAllocation={showFactSheetLinkInAssetAllocation}
                        showPortfolioNameInAssetAllocation={showPortfolioNameInAssetAllocation}
                        showSecurities={showSecurities}
                        sleeves={accountPerformanceData.sleeves}
                        startDate={startDate}
                      />
                    </Suspense>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container sx={{ p: 6 }} width="100%">
            {accountPerformanceDataLoading || returnsDataLoading || visibleManagedAccountsLoading ? (
              <Grid container spacing={3}>
                {/* Header */}
                <Grid item xs={12}>
                  <Skeleton height={40} variant="text" width="60%" />
                  <Skeleton height={56} sx={{ mt: 2 }} variant="rectangular" width="100%" />
                </Grid>

                {/* Sidebar */}
                <Grid item md={2} xs={12}>
                  <Skeleton height={200} variant="rectangular" />
                </Grid>

                {/* Main content */}
                <Grid item md={10} xs={12}>
                  <Skeleton height={100} sx={{ mb: 3 }} variant="rectangular" width="100%" />
                  <Skeleton height={300} sx={{ mb: 3 }} variant="rectangular" width="100%" />
                  <Skeleton height={250} sx={{ mb: 3 }} variant="rectangular" width="100%" />
                  <Skeleton height={200} sx={{ mb: 3 }} variant="rectangular" width="100%" />
                  <Skeleton height={300} sx={{ mb: 3 }} variant="rectangular" width="100%" />
                  <Skeleton height={150} variant="rectangular" width="100%" />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid md={2}>
                  {accountPerformanceData?.content.sidebarItems && (
                    <AccountPerformanceSidebar
                      content={accountPerformanceData.content.sidebarItems}
                      monthlyReviewURL={monthlyReviewURL}
                    />
                  )}
                </Grid>
                <Grid item md={10}>
                  {accountPerformanceData?.content.accountOverview && (
                    <AccountOverview
                      accountBalance={openingClosingBalance?.closingBalance ?? undefined}
                      content={accountPerformanceData.content.accountOverview}
                      contentOptions={contentOptions}
                      dataQa={dataQa}
                      endDate={endDate}
                      returnPercentage={formattedPercentageReturn}
                      startDate={startDate}
                    />
                  )}
                  {accountPerformanceData?.performanceChartData && (
                    <AccountBalanceChart
                      content={accountPerformanceData.content}
                      contentOptions={contentOptions}
                      endDate={endDate}
                      openingClosingBalance={openingClosingBalance}
                      performanceChartData={accountPerformanceData.performanceChartData}
                      startDate={startDate}
                    />
                  )}
                  {accountPerformanceData?.returns && (
                    <ReturnsChart
                      accountNumberDisplay={maskedAccountNumberDisplay}
                      accountReturns={accountPerformanceData.returns.accountReturnsData}
                      accountReturnsPercentage={formattedPercentageReturn}
                      content={accountPerformanceData.content.returnsChart}
                      contentOptions={contentOptions}
                      endDate={endDate}
                      onToggleShowSecurities={onToggleShowSecuritiesInReturns}
                      securitiesReturns={accountPerformanceData.returns.securitiesReturnsData}
                      showSecurities={showSecurities}
                      startDate={startDate}
                    />
                  )}
                  {accountActivityContent && (
                    <AccountPerformanceActivity
                      accountActivityData={accountPerformanceData?.accountActivityData}
                      content={getAccountActivityContent(accountActivityContent)}
                      endDate={endDate}
                      startDate={startDate}
                    />
                  )}
                  {formattedAllocationContent && (
                    <Grid id="assetAllocation" sx={styles.container}>
                      <AssetAllocation
                        allocations={getAllocations(assetClassTier, assets, accountPerformanceData?.assetClassContent)}
                        assetClassTier={assetClassTier}
                        cashSleeve={cashSleeve}
                        content={formattedAllocationContent}
                        contentOptions={contentOptions}
                        endDate={endDate}
                        factSheetUrl={accountPerformanceData?.strategyBrochureUrl ?? ''}
                        isSavingsOptimizerEnabled={false}
                        modelPortfolioName={modelPortfolioName}
                        showActualAllocationInDonut={showActualAllocationInDonut}
                        showDate
                        showFactSheetLinkInAssetAllocation={showFactSheetLinkInAssetAllocation}
                        showPortfolioNameInAssetAllocation={showPortfolioNameInAssetAllocation}
                      />
                    </Grid>
                  )}
                  {monthlyReviewURL && accountPerformanceData && (
                    <MarketReview
                      content={accountPerformanceData.content.marketReview}
                      monthlyReviewURL={monthlyReviewURL}
                    />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
