import { populateBeneficiaries } from '../../Section/Question/BeneficiariesV2/utils';
import { populateTrustedContacts } from '../../Section/Question/ContactsV2/utils';
import { ComponentTypes, QuestionOrderSteps } from '../../Section/types';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';
import {
  designatePrimaryBeneficiaryNoValue,
  designatePrimaryBeneficiaryYesValue,
  designateTrustedContactNoValue,
  designateTrustedContactYesValue,
  filterValidationRules,
  getPaperworkDataForPrefill,
} from './utils';

import { RelationshipName } from '~/__generated__';

export const contingentBeneficiary = (
  { additionalValidations, paperworkFreeFormId, rules }: QuestionProperties,
  beneficiariesComponentProps?: QuestionOrderSteps['beneficiariesComponentProps'],
): QuestionOrderSteps => {
  return {
    beneficiariesComponentProps,
    questionKey: QuestionKeys.CONTINGENT_BENEFICIARY,
    dataPointKey: DataPointKeys.CONTINGENT_BENEFICIARY,
    prefillValueGetter: _data => undefined,
    paperworkInputSetter: (paperworkInput, data) => {
      populateBeneficiaries(false, paperworkInput, data, paperworkFreeFormId);
    },
    componentType: ComponentTypes.Beneficiary,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const designatePrimaryBeneficiary = ({ paperworkFreeFormId, rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.DESIGNATE_PRIMARY_BENEFICIARY,
    dataPointKey: DataPointKeys.DESIGNATE_PRIMARY_BENEFICIARY,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.relationships?.find(
        r => r?.name === RelationshipName.BENEFICIARY_PRIMARY,
      )
        ? designatePrimaryBeneficiaryYesValue
        : designatePrimaryBeneficiaryNoValue;
    },
    paperworkInputSetter: (_paperworkInput, _data) => undefined,
    componentType: ComponentTypes.Radio,
    rules,
  };
};

export const designateTrustedContact = ({ paperworkFreeFormId, rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.DESIGNATE_TRUSTED_CONTACT,
    dataPointKey: DataPointKeys.DESIGNATE_TRUSTED_CONTACT,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.relationships?.find(
        r => r?.name === RelationshipName.TRUSTED_CONTACT,
      )
        ? designateTrustedContactYesValue
        : designateTrustedContactNoValue;
    },
    paperworkInputSetter: (_paperworkInput, _data) => undefined,
    defaultValue: designateTrustedContactNoValue,
    componentType: ComponentTypes.Radio,
    rules,
  };
};

export const primaryBeneficiary = (
  { additionalValidations, paperworkFreeFormId, rules }: QuestionProperties,
  beneficiariesComponentProps?: QuestionOrderSteps['beneficiariesComponentProps'],
): QuestionOrderSteps => {
  return {
    beneficiariesComponentProps,
    questionKey: QuestionKeys.PRIMARY_BENEFICIARY,
    dataPointKey: DataPointKeys.PRIMARY_BENEFICIARY,
    prefillValueGetter: _data => undefined,
    paperworkInputSetter: (paperworkInput, data) => {
      populateBeneficiaries(true, paperworkInput, data, paperworkFreeFormId);
    },
    componentType: ComponentTypes.Beneficiary,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const trustedContact = (
  { additionalValidations, paperworkFreeFormId, rules }: QuestionProperties,
  contactsComponentProps?: QuestionOrderSteps['contactsComponentProps'],
): QuestionOrderSteps => {
  return {
    contactsComponentProps,
    questionKey: QuestionKeys.TRUSTED_CONTACT,
    dataPointKey: DataPointKeys.TRUSTED_CONTACT,
    prefillValueGetter: _data => undefined,
    paperworkInputSetter: (paperworkInput, data) => {
      populateTrustedContacts(paperworkInput, data, paperworkFreeFormId);
    },
    componentType: ComponentTypes.Contacts,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};
