import { GetAccountBalances, GetAccountBalancesVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import {
  GetPortfolioSelectionModelPortfolioDetails,
  GetPortfolioSelectionModelPortfolioDetails_managedProduct,
  GetPortfolioSelectionModelPortfolioDetailsVariables,
} from '~/containers/PortfolioSelection/symphony/__generated__/query.v2';
import {
  GetPortfolioSelectionData_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedCurrency,
  GetPortfolioSelectionData_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedSingleOption,
} from '~/hooks/portfolio/symphony/__generated__/query.v2';
import { QueryHookOptions, QueryResult, QueryTuple } from '~/utils/apollo-client';
import { useLazySymphonyQuery, useSymphonyQuery } from '~/utils/symphony';

export type ManagedProduct = GetPortfolioSelectionModelPortfolioDetails_managedProduct;
export type QuestionnaireCollectedDataSingleOption = GetPortfolioSelectionData_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedSingleOption;
export type QuestionnaireCollectedCurrency = GetPortfolioSelectionData_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedCurrency;

export const useGetPortfolioSelectionModelPortfolioDetails = (
  options?: QueryHookOptions<
    GetPortfolioSelectionModelPortfolioDetails,
    GetPortfolioSelectionModelPortfolioDetailsVariables
  >,
): QueryResult<GetPortfolioSelectionModelPortfolioDetails, GetPortfolioSelectionModelPortfolioDetailsVariables> => {
  return useSymphonyQuery(queries.GetPortfolioSelectionModelPortfolioDetails, options);
};

export const useGetAccountBalances = (
  options?: QueryHookOptions<GetAccountBalances, GetAccountBalancesVariables>,
): QueryTuple<GetAccountBalances, GetAccountBalancesVariables> => {
  return useLazySymphonyQuery(queries.GetAccountBalances, options);
};
