import { formatCurrency, formatCurrencyPrecise } from '~/utils/format';

interface AccountActivityHeaderContent {
  balanceDecreasedBy: string;
  balanceIncreasedBy: string;
  subText: string;
  thisQuarter: string;
  title: string;
}

export interface AccountActivityTableHeadingContent {
  activityColumnLabel: string;
  valueColumnLabel: string;
}

export interface AccountActivityTableBodyContent {
  tableRow: TableRowContent[];
}

export interface TableRowContent {
  isBold: boolean;
  key: string;
  label: string;
  showTotal: boolean;
}

export interface AccountActivityContent {
  header: AccountActivityHeaderContent;
  tableBody: AccountActivityTableBodyContent;
  tableHeading: AccountActivityTableHeadingContent;
}

export interface AccountActivityData {
  closingBalance: string;
  contributions: string;
  dividends: string;
  fees: string;
  interest: string;
  marketMovement: string;
  openingBalance: string;
  withdrawals: string;
}

export type RowKey = 'contributions' | 'dividends' | 'fees' | 'interest' | 'marketMovement' | 'withdrawals';

export const getTableCellValue = (row: TableRowContent, accountActivityData: AccountActivityData): string => {
  const cellValue = accountActivityData[row.key as RowKey];
  if (!cellValue) {
    return '';
  }
  return formatCurrencyPrecise(parseFloat(cellValue));
};

export interface OpeningClosingBalance {
  balanceDifference?: number;
  closingBalance?: string | null;
  increased?: boolean;
  openingBalance?: string | null;
}

export const getOpeningClosingBalanceDifference = (
  openingClosingBal?: OpeningClosingBalance,
): { balanceDifference: string; increased: boolean | null } => {
  if (!openingClosingBal?.openingBalance || !openingClosingBal.closingBalance) {
    return {
      balanceDifference: '',
      increased: null,
    };
  }
  const balanceDiff = parseFloat(openingClosingBal.closingBalance) - parseFloat(openingClosingBal.openingBalance);
  return {
    balanceDifference: formatCurrency(Math.abs(balanceDiff)),
    increased: balanceDiff > 0,
  };
};
