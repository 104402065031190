import React, { FC, useState } from 'react';

import { AccountPerformanceSideBar } from '../types';

import { Box, MuiTypography } from '~/components/ui/mui';

export interface Props {
  content: AccountPerformanceSideBar[];
  dataQa?: string;
  monthlyReviewURL?: string;
}

export const AccountPerformanceSidebar: FC<Props> = ({
  content,
  dataQa = 'account-performance-sidebar',
  monthlyReviewURL,
}) => {
  const [activeKey, setActiveKey] = useState(content[0]?.key);

  const handleItemClick = (key: string | null) => {
    const element = key ? document.getElementById(key) : undefined;
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
    setActiveKey(key);
  };

  const filteredContent = monthlyReviewURL ? content : content.filter(item => item?.key !== 'marketReview');

  return (
    <Box data-qa={dataQa} sx={{ mt: 4, position: 'sticky', top: '40px' }}>
      {filteredContent.map(item => (
        <MuiTypography
          color={item?.key === activeKey ? 'primary.main' : 'text.secondary'}
          key={item?.key}
          sx={{ cursor: 'pointer', my: 2 }}
          variant={item?.key === activeKey ? 'subtitle1' : 'body1'}
        >
          {item?.key && <MuiTypography onClick={() => handleItemClick(item.key)}>{item.value}</MuiTypography>}
        </MuiTypography>
      ))}
    </Box>
  );
};
