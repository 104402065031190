import { ManagedProductPaperworkWithFreeFormId } from '../../hooks/useGetPaperworkData';
import { PaperworkDataPageConfig } from '../types';

import { aboutTheGrantorQuestions, aboutTheGrantorSectionKey } from './sections/aboutTheGrantor';
import { aboutTheTrustQuestions, aboutTheTrustSectionKey } from './sections/aboutTheTrust';
import { assetsHeldAwayQuestions, assetsHeldAwaySectionKey } from './sections/assetsHeldAway';
import { eDeliveryQuestions, eDeliverySectionKey } from './sections/eDelivery';
import { idAndEmploymentQuestions, idAndEmploymentSectionKey } from './sections/identificationAndEmployment';
import { regulatoryQuestions, regulatorySectionKey } from './sections/regulatory';
import { aboutTheTrusteeQuestions, aboutTheTrusteeSectionKey } from './sections/trustee';
import { trustWealthAndExpensesQuestions, wealthAndExpensesSectionKey } from './sections/wealthAndExpenses';

import { FinancialAccountType, PaperworkType, TrustProfileType } from '~/__generated__';

export const trustPaperworkPagesForAdvisorLedOnboarding = (
  _accountType: FinancialAccountType,
  allSavedPaperworkData: ManagedProductPaperworkWithFreeFormId[],
): PaperworkDataPageConfig[] => {
  const trustInformationFromPaperwork = allSavedPaperworkData.find(pd => pd.paperworkFreeFormId === 'trust')
    ?.trustInformation;
  const totalTrustees = trustInformationFromPaperwork?.numberOfTrustees ?? 1;
  const isTrusteeGrantor = trustInformationFromPaperwork?.isTrusteeGrantor ?? false;
  return [
    {
      paperworkFreeFormIdForPage: 'trust',
      sections: [
        {
          sectionKey: aboutTheTrustSectionKey,
          questions: aboutTheTrustQuestions,
        },
        {
          sectionKey: wealthAndExpensesSectionKey,
          questions: trustWealthAndExpensesQuestions,
        },
        {
          sectionKey: assetsHeldAwaySectionKey,
          questions: assetsHeldAwayQuestions,
        },
      ],
    },
    {
      heading: totalTrustees > 1 ? 'heading_trustee_1_account' : 'heading_trustee_account',
      paperworkFreeFormIdForPage: 'trustee1',
      sections: [
        {
          sectionKey: aboutTheTrusteeSectionKey,
          questions: aboutTheTrusteeQuestions,
        },
        {
          sectionKey: idAndEmploymentSectionKey,
          questions: idAndEmploymentQuestions,
        },
        {
          sectionKey: regulatorySectionKey,
          questions: regulatoryQuestions,
        },
      ],
    },
    ...(totalTrustees ? [...getAdditionalTrusteePages(totalTrustees)] : []),
    {
      paperworkFreeFormIdForPage: 'trustee1',
      sections: [
        {
          sectionKey: eDeliverySectionKey,
          questions: eDeliveryQuestions,
        },
      ],
    },
    ...(isTrusteeGrantor
      ? []
      : [
          {
            paperworkFreeFormIdForPage: 'grantor',
            heading: 'heading_grantor_account',
            sections: [
              {
                sectionKey: aboutTheGrantorSectionKey,
                questions: aboutTheGrantorQuestions,
              },
              {
                sectionKey: idAndEmploymentSectionKey,
                questions: idAndEmploymentQuestions,
              },
              {
                sectionKey: regulatorySectionKey,
                questions: regulatoryQuestions,
              },
            ],
          },
        ]),
  ];
};

const getAdditionalTrusteePages = (totalTrustees: number): PaperworkDataPageConfig[] => {
  const pages: PaperworkDataPageConfig[] = [];
  for (let i = 1; i < totalTrustees; i++) {
    pages.push({
      heading: `heading_trustee_${i + 1}_account`,
      paperworkFreeFormIdForPage: `trustee${i + 1}`,
      sections: [
        {
          sectionKey: aboutTheTrusteeSectionKey,
          questions: aboutTheTrusteeQuestions,
        },
        {
          sectionKey: idAndEmploymentSectionKey,
          questions: idAndEmploymentQuestions,
        },
        {
          sectionKey: regulatorySectionKey,
          questions: regulatoryQuestions,
        },
      ],
    });
  }
  return pages;
};

export const trustPaperworkFreeFormIdToProfileType = (
  pages: PaperworkDataPageConfig[],
): Record<string, PaperworkType> => {
  const allPaperworkFreeFormIds = new Set<string>();
  pages.forEach(p => allPaperworkFreeFormIds.add(p.paperworkFreeFormIdForPage));

  return Array.from(allPaperworkFreeFormIds).reduce<Record<string, PaperworkType>>((curr, freeFormId) => {
    if (freeFormId === 'trustee1') {
      curr[freeFormId] = PaperworkType.PRIMARY;
    } else {
      curr[freeFormId] = PaperworkType.SECONDARY;
    }
    return curr;
  }, {});
};

export const trustPaperworkFreeFormIdToTrustProfileType = (
  pages: PaperworkDataPageConfig[],
): Record<string, TrustProfileType> => {
  const allPaperworkFreeFormIds = new Set<string>();
  pages.forEach(p => allPaperworkFreeFormIds.add(p.paperworkFreeFormIdForPage));

  return Array.from(allPaperworkFreeFormIds).reduce<Record<string, TrustProfileType>>((curr, freeFormId) => {
    if (freeFormId.startsWith('trustee')) {
      curr[freeFormId] = TrustProfileType.TRUSTEE;
    } else if (freeFormId === 'trust') {
      curr[freeFormId] = TrustProfileType.TRUST;
    } else {
      curr[freeFormId] = TrustProfileType.NON_TRUSTEE_GRANTOR;
    }
    return curr;
  }, {});
};
