import { ComponentTypes, QuestionOrderSteps } from '../../Section/types';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';

export const tlhLabel = ({ adjacent, rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.TLH_LABEL,
    dataPointKey: DataPointKeys.TLH_LABEL,
    componentType: ComponentTypes.Label,
    adjacent,
    rules,
  };
};

export const tlhText = ({ adjacent, rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.TLH_TEXT,
    dataPointKey: DataPointKeys.TLH_TEXT,
    componentType: ComponentTypes.Text,
    adjacent,
    rules,
  };
};

export const tlh = ({ adjacent, rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.TLH,
    dataPointKey: DataPointKeys.TLH,
    componentType: ComponentTypes.Tlh,
    adjacent,
    rules,
  };
};
