import React from 'react';

import { Content as InvestmentAmountModalContent } from '../InvestmentAmountModal';
import { InvestmentAmountContent, PortfolioSelectionOptions } from '../PortfolioSelectionOptions';

import { KeepCurrentPortfolio, KeepCurrentPortfolioContent } from './KeepCurrentPortfolio';

import { OnboardingPageHeader } from '~/components/Onboarding/OnboardingPageHeader';
import { CompactVariant, RiskSpectrum } from '~/components/RiskSpectrum';
import { Alert } from '~/components/ui/Alert';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Link } from '~/components/ui/Link';
import { Box, EditIcon, Grid, Paper, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { AssetAllocationModalState, StrategyMode } from '~/containers/PortfolioSelection/types';
import { ComparisonData } from '~/hooks/comparison/usePortfolioComparison';
import { RecommendedProductData } from '~/hooks/questionnaire/useRecommendedProduct';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { ContentOptions } from '~/utils/contentstack';
import { SfTheme } from '~/utils/theme';

export interface Content {
  headerIcon: string;
  investmentAmount?: InvestmentAmountContent;
  investmentAmountModal?: InvestmentAmountModalContent;
  investmentStrategyLabel: string;
  keepCurrentPortfolio: KeepCurrentPortfolioContent;
  minimumInvestmentAmountInfoText: string;
  riskLabel: string;
  riskToleranceEditLinkText: string;
  riskToleranceLabel: string;
  strategyModeOptions?: DropdownItem[];
  title: string;
}

export interface PortfolioSelectionHeaderProps {
  accountBalance?: number;
  accountMinimumBalanceForAccountOpening?: number;
  assetClassTier?: AssetClassTier;
  content: Content;
  contentOptions: ContentOptions;
  currentPortfolioData?: ComparisonData;
  dataQa?: string;
  initialInvestmentAmountValue?: number;
  investmentAmountValue?: number;
  isRce?: boolean;
  onBack: () => void;
  onInvestmentAmountChange: (value?: number) => void;
  onStrategyModeChange?: (strategyMode: StrategyMode) => void;
  openAssetAllocationModal: (assetAllocationProps: AssetAllocationModalState) => void;
  openConfirmationModal: () => void;
  recommendedProduct?: RecommendedProductData;
  selectedStrategyMode?: StrategyMode;
}

export const PortfolioSelectionHeader: React.FC<PortfolioSelectionHeaderProps> = ({
  accountBalance,
  accountMinimumBalanceForAccountOpening,
  assetClassTier,
  content,
  contentOptions,
  currentPortfolioData,
  dataQa = 'portfolio-selection-header',
  initialInvestmentAmountValue,
  investmentAmountValue,
  isRce,
  onBack,
  onInvestmentAmountChange,
  openAssetAllocationModal,
  openConfirmationModal,
  onStrategyModeChange,
  recommendedProduct,
  selectedStrategyMode,
}) => {
  const {
    sfPortfolioSelection: { styles, typographyVariants },
  } = useTheme<SfTheme>();

  return (
    <>
      <OnboardingPageHeader headerIcon={content.headerIcon} title={content.title} />
      {isRce && currentPortfolioData && (
        <KeepCurrentPortfolio
          assetClassTier={assetClassTier}
          content={content.keepCurrentPortfolio}
          currentPortfolioData={currentPortfolioData}
          openAssetAllocationModal={openAssetAllocationModal}
          openConfirmationModal={openConfirmationModal}
          viewAssetAllocationLinkStyle={styles.portfolioSelectionCard?.table.link}
        />
      )}
      {isRce && (
        <Alert severity="info" sx={{ mt: 5 }}>
          <Typography variant="body2">{content.minimumInvestmentAmountInfoText}</Typography>
        </Alert>
      )}
      <Paper elevation={3} sx={styles.headerStrategyContainer}>
        {recommendedProduct && (
          <Grid container direction={{ xs: 'column', md: 'row' }}>
            <Grid data-qa={`${dataQa}-risk-spectrum`} item md={5.5} sx={{ alignSelf: 'center' }} xs={12}>
              <Box
                data-qa={`${dataQa}-edit-risk-tolerance`}
                sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', mb: 1.5, mt: 1 }}
              >
                <Typography component="h2" sx={{ mr: 1, color: 'text.secondary' }} variant="subtitle2">
                  {content.riskToleranceLabel}
                </Typography>
                <Link
                  aria-label="edit risk tolerance icon"
                  data-qa={`${dataQa}-edit-risk-tolerance-link`}
                  onClick={onBack}
                  sx={{ color: 'primary.dark', textDecoration: 'none' }}
                  variant={typographyVariants?.editRiskToleranceLink}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ lineHeight: 1.75 }} variant="body2">
                      {content.riskToleranceEditLinkText}
                    </Typography>
                    <EditIcon fontSize="small" sx={{ ml: 0.5 }} />
                  </Box>
                </Link>
              </Box>
              <Grid container>
                <Grid item xs={3}>
                  <Typography
                    align="left"
                    sx={{ mb: 1, color: 'text.primary' }}
                    variant={typographyVariants?.riskSpectrumLabel}
                  >
                    {content.riskLabel}
                  </Typography>
                </Grid>
                <Grid item sx={{ pt: 1.5, pr: 0.5 }} xs={6}>
                  <RiskSpectrum
                    {...recommendedProduct}
                    compact={CompactVariant.Arrow}
                    contentOptions={contentOptions}
                  />
                </Grid>
              </Grid>
            </Grid>
            <PortfolioSelectionOptions
              accountBalance={accountBalance}
              accountMinimumBalanceForAccountOpening={accountMinimumBalanceForAccountOpening}
              content={{
                investmentAmount: content.investmentAmount ?? {},
                investmentAmountModal: content.investmentAmountModal ?? {},
                investmentStrategy: {
                  investmentStrategyLabel: content.investmentStrategyLabel,
                  strategyModeOptions: content.strategyModeOptions,
                },
              }}
              contentOptions={contentOptions}
              dataQa={dataQa}
              initialInvestmentAmountValue={initialInvestmentAmountValue}
              investmentAmountValue={investmentAmountValue}
              isRce={isRce}
              onInvestmentAmountChange={onInvestmentAmountChange}
              onStrategyModeChange={onStrategyModeChange}
              selectedStrategyMode={selectedStrategyMode}
            />
          </Grid>
        )}
      </Paper>
    </>
  );
};
