import { PaperworkDataPageConfig } from '../types';

import { aboutYouQuestions, aboutYouSectionKey } from './sections/aboutYou';
import { assetsHeldAwayQuestions, assetsHeldAwaySectionKey } from './sections/assetsHeldAway';
import {
  beneficiariesAndContactsIraQuestions,
  beneficiariesAndContactsSectionKey,
  beneficiariesAndContactsTaxableQuestions,
} from './sections/beneficiariesAndTrustedContacts';
import { eDeliveryQuestions, eDeliverySectionKey } from './sections/eDelivery';
import { idAndEmploymentQuestions, idAndEmploymentSectionKey } from './sections/identificationAndEmployment';
import { regulatoryQuestions, regulatorySectionKey } from './sections/regulatory';
import {
  individualJointWealthAndExpensesQuestions,
  wealthAndExpensesQuestions,
  wealthAndExpensesSectionKey,
} from './sections/wealthAndExpenses';

import { FinancialAccountType, PaperworkType } from '~/__generated__';
import { isIRAAccountType } from '~/utils/account';

export const individualOwnerPaperworkPagesForClientLedOnboarding = (
  accountType: FinancialAccountType,
): PaperworkDataPageConfig[] => {
  return [
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: aboutYouSectionKey,
          questions: aboutYouQuestions,
        },
        {
          sectionKey: idAndEmploymentSectionKey,
          questions: idAndEmploymentQuestions,
        },
        {
          sectionKey: beneficiariesAndContactsSectionKey,
          questions: isIRAAccountType(accountType)
            ? beneficiariesAndContactsIraQuestions
            : beneficiariesAndContactsTaxableQuestions,
        },
      ],
    },
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      consent: {
        consentRequired: true,
        defaultChecked: false,
      },
      sections: [
        {
          sectionKey: wealthAndExpensesSectionKey,
          questions: isIRAAccountType(accountType)
            ? wealthAndExpensesQuestions
            : individualJointWealthAndExpensesQuestions,
        },
        {
          sectionKey: regulatorySectionKey,
          questions: regulatoryQuestions,
        },
      ],
    },
  ];
};

export const individualOwnerPaperworkPagesForAdvisorLedOnboarding = (
  accountType: FinancialAccountType,
): PaperworkDataPageConfig[] => {
  return [
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: aboutYouSectionKey,
          questions: aboutYouQuestions,
        },
        {
          sectionKey: idAndEmploymentSectionKey,
          questions: idAndEmploymentQuestions,
        },
        {
          sectionKey: beneficiariesAndContactsSectionKey,
          questions: isIRAAccountType(accountType)
            ? beneficiariesAndContactsIraQuestions
            : beneficiariesAndContactsTaxableQuestions,
        },
      ],
    },
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: wealthAndExpensesSectionKey,
          questions: isIRAAccountType(accountType)
            ? wealthAndExpensesQuestions
            : individualJointWealthAndExpensesQuestions,
        },
        {
          sectionKey: regulatorySectionKey,
          questions: regulatoryQuestions,
        },
        {
          sectionKey: assetsHeldAwaySectionKey,
          questions: assetsHeldAwayQuestions,
        },
      ],
    },
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: eDeliverySectionKey,
          questions: eDeliveryQuestions,
        },
      ],
    },
  ];
};

export const individualPaperworkFreeFormIdToProfileType: Record<string, PaperworkType> = {
  individual: PaperworkType.PRIMARY,
};
