import React, { FC } from 'react';

import { Box, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

export interface Props {
  dataQa?: string;
  heading: string;
  number?: number;
}

export const SectionHeading: FC<Props> = ({ dataQa = 'section-heading', number, heading }) => {
  const {
    sfPaperwork: { styles: sfPaperworkStyles },
  } = useTheme();
  return (
    <Box data-qa={dataQa}>
      <Box sx={{ ...sfPaperworkStyles.sectionHeader, display: 'flex', py: 3 }}>
        {number !== undefined && (
          <Typography
            component="h2"
            data-qa={`${dataQa}-number`}
            sx={{
              ...sfPaperworkStyles.sectionHeaderNumber,
            }}
            variant="body2"
          >
            {number}
          </Typography>
        )}
        <Typography component="h2" data-qa={`${dataQa}-heading`} sx={{ pl: 1 }} variant="subtitle1">
          {heading}
        </Typography>
      </Box>
    </Box>
  );
};
