import {
  GetCountries,
  GetCountries_all_countries_items_countrieslist,
  GetCountriesVariables,
  GetPaperworkContent,
  GetPaperworkContentVariables,
  GetPaperworkQuestionContent,
  GetPaperworkQuestionContent_all_paperwork_questions_items,
  GetPaperworkQuestionContent_all_paperwork_questions_items_single_option_input_questions,
  GetPaperworkQuestionContent_all_paperwork_questions_items_single_option_input_questions_input_options,
  GetPaperworkQuestionContent_all_paperwork_questions_items_text_input_questions,
  GetPaperworkQuestionContentVariables,
  GetStates,
  GetStates_all_states_items_stateslist,
  GetStates_all_states_items_stateslist_valid_zip_code_ranges,
  GetStatesVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type CountriesList = GetCountries_all_countries_items_countrieslist;
export type CMSQuestions = GetPaperworkQuestionContent_all_paperwork_questions_items;
export type TextInputQuestion = GetPaperworkQuestionContent_all_paperwork_questions_items_text_input_questions;
export type ZipCodeRanges = GetStates_all_states_items_stateslist_valid_zip_code_ranges;
export type SingleOptionInputQuestion = GetPaperworkQuestionContent_all_paperwork_questions_items_single_option_input_questions;
export type StatesList = GetStates_all_states_items_stateslist;
export type PaperworkInputOptions =
  | (GetPaperworkQuestionContent_all_paperwork_questions_items_single_option_input_questions_input_options | null)[]
  | null;

export const useGetPaperworkContent = (
  options?: QueryHookOptions<GetPaperworkContent, GetPaperworkContentVariables>,
): QueryResult<GetPaperworkContent, GetPaperworkContentVariables> => {
  return useContentstackQuery(queries.GetPaperworkContent, options);
};

export const useGetPaperworkQuestionsContent = (
  options?: QueryHookOptions<GetPaperworkQuestionContent, GetPaperworkQuestionContentVariables>,
): QueryResult<GetPaperworkQuestionContent, GetPaperworkQuestionContentVariables> => {
  return useContentstackQuery(queries.GetPaperworkQuestionContent, options);
};

export const useGetStates = (
  options?: QueryHookOptions<GetStates, GetStatesVariables>,
): QueryResult<GetStates, GetStatesVariables> => {
  return useContentstackQuery(queries.GetStates, options);
};

export const useGetCountries = (
  options?: QueryHookOptions<GetCountries, GetCountriesVariables>,
): QueryResult<GetCountries, GetCountriesVariables> => {
  return useContentstackQuery(queries.GetCountries, options);
};
