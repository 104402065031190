import { useMemo } from 'react';

import { BillingRate, calculateDiscount, DiscountType } from '~/utils/pricing';

export interface UseInitialDiscountValueProps {
  defaultDiscountValue?: number;
  defaultRates?: BillingRate[];
  discountLowerBound?: number;
  discountTiers: [number, ...number[]]; // needs an array with at least 1 element, should be supplied when hook is invoked
  isAdvancedPricingEnabled?: boolean;
  overriddenRates?: BillingRate[];
}

export const useInitialDiscountValue = ({
  defaultDiscountValue,
  defaultRates,
  discountLowerBound,
  discountTiers,
  isAdvancedPricingEnabled,
  overriddenRates,
}: UseInitialDiscountValueProps) => {
  const defaultDiscountState =
    defaultDiscountValue ?? (isAdvancedPricingEnabled ? discountLowerBound ?? discountTiers[0] : discountTiers[0]);

  const currentDiscount = useMemo(() => calculateDiscount(defaultRates, overriddenRates), [
    defaultRates,
    overriddenRates,
  ]);

  return useMemo(
    () =>
      currentDiscount
        ? currentDiscount.discountType === DiscountType.Percentage
          ? currentDiscount.discountValue * 100
          : Math.round(currentDiscount.discountValue * 10000)
        : defaultDiscountState,
    [currentDiscount, defaultDiscountState],
  );
};
