import React, { FC } from 'react';
import { Controller, FieldError, RegisterOptions, UseFormMethods } from 'react-hook-form';

import { FormData } from '../../types';
import { QuestionContent } from '../types';

import { FormHelperTextProps } from '~/components/ui/mui';
import { CurrencyTextField } from '~/components/ui/TextField/CurrencyTextField';
import { ContentOptions } from '~/utils/contentstack';

interface Props {
  contentOptions: ContentOptions;
  defaultValue: any;
  fieldName: string;
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  inputErrors?: FieldError;
  questionContent: QuestionContent;
  validationRules: RegisterOptions;
}

export const CurrencyQuestion: FC<Props> = ({
  contentOptions,
  defaultValue,
  validationRules,
  fieldName,
  formHooks,
  questionContent,
  inputErrors,
}) => {
  const { control } = formHooks;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      key={`question-${fieldName}`}
      name={fieldName}
      render={({ ref, value, name, onChange }) => {
        return (
          <CurrencyTextField
            FormHelperTextProps={{ component: 'div' } as FormHelperTextProps}
            contentOptions={contentOptions}
            // We do not want to allow any decimal places for now as symphony doesn't support d.p.
            decimalScale={0}
            error={!!inputErrors}
            fullWidth
            id={fieldName}
            inputRef={ref}
            isAllowed={values => !!validationRules.maxLength && values.value.length <= +validationRules.maxLength}
            label={questionContent.question}
            name={name}
            onBlur={e => {
              e.target.value = e.target.value.trim();
              onChange(e.target.value.replace(/[$,]/g, ''));
            }}
            onValueChange={values => {
              const { value: inputValue } = values;
              if (validationRules.maxLength && inputValue.length > +validationRules.maxLength) {
                return;
              } else {
                onChange(inputValue);
              }
            }}
            value={parseFloat(value)}
          />
        );
      }}
      rules={validationRules}
    />
  );
};
