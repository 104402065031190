import { GetSleevesShoppingCartContent, GetSleevesShoppingCartContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetShoppingCartContent = (
  options?: QueryHookOptions<GetSleevesShoppingCartContent, GetSleevesShoppingCartContentVariables>,
): QueryResult<GetSleevesShoppingCartContent, GetSleevesShoppingCartContentVariables> => {
  return useContentstackQuery(queries.GetSleevesShoppingCartContent, options);
};
