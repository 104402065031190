import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { ArrayField, Control, DeepMap, FieldError, useFieldArray, useForm } from 'react-hook-form';

import { CartAllocation } from '..';
import { useComputeCompositeModelPortfolio } from '../symphony';
import { getSeriesPortfolios, isInvalidAllocationPercentage, PortfolioSeries } from '../utils';

import { ShoppingCartContentData } from '.';

import { ComputeRecommendedPortfolioAllocationInput, Scalars as SymphonyScalars } from '~/__generated__';
import { SleeveAllocationData } from '~/components/SleeveTemplates/SleeveTemplateCard';
import { SleeveTemplateData } from '~/components/SleeveTemplates/utils';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Portfolio } from '~/containers/PortfolioSelection/types';
import { RiskPreferenceVolatilityMappings } from '~/hooks/volatility/symphony';
import { checkVolatilityMatch, VolatilityMatchStatus } from '~/utils/volatility';

export interface ShoppingCartFormVariables {
  allocationValue?: SleeveAllocationData[] | null;
  maximumPercentageAllowed: number;
  maximumSleeveCount?: number;
  minimumPercentageAllowed: number;
  modelPortfolios: Portfolio[];
  quickApplySleeveTemplate?: SleeveTemplateData;
  riskPreferenceVolatilityList?: RiskPreferenceVolatilityMappings[];
  riskScore: number;
  shoppingCartContent?: ShoppingCartContentData;
}

export enum InputNames {
  allocation = 'allocation',
  modelPortfolioIdentifier = 'modelPortfolioIdentifier',
  modelPortfolioSeriesId = 'modelPortfolioSeriesId',
  percentage = 'percentage',
  templateName = 'templateName',
}

type FormData = {
  allocation: CartAllocation[];
  templateName: string;
};

interface ShoppingCartSeries {
  index: number;
  portfolios: Portfolio[];
}

export interface ShoppingCartFormData {
  addSleeve: () => void;
  compositeModelPortfolioOpeningMinimum: SymphonyScalars['Decimal'];
  compositeModelPortfolioRebalanceMinimum: SymphonyScalars['Decimal'];
  compositeModelPortfolioVolatility: SymphonyScalars['Decimal'];
  compositeModelPortfolioVolatilityMatchStatus: VolatilityMatchStatus;
  control: Control<FormData>;
  fields: Partial<ArrayField<Record<string, any>, 'id'>>[];
  fieldsErrors: DeepMap<FormData, FieldError>;
  getSeriesOptions: (sleeveIndex: number) => DropdownItem[];
  getValues: () => { allocation: CartAllocation[]; templateName: string };
  isCompositeModelPortfolioLoaded: boolean;
  isContinueButtonClicked: [boolean, Dispatch<SetStateAction<boolean>>];
  isSubmitButtonClicked: [boolean, Dispatch<SetStateAction<boolean>>];
  numberOfSleeves: number;
  onSeriesChange: (value: string, index: number, retainModelPortfolioIdentifier?: boolean) => void;
  removeSleeve: () => void;
  saveTemplateFormValidity: boolean;
  sleevesList: ShoppingCartSeries[];
  symphonyError: [Error | undefined | null, Dispatch<SetStateAction<Error | undefined | null>>];
  totalPercentageValue: number;
}

export const useShoppingCartForm = ({
  allocationValue,
  maximumPercentageAllowed,
  maximumSleeveCount = 3,
  minimumPercentageAllowed,
  modelPortfolios,
  quickApplySleeveTemplate,
  riskPreferenceVolatilityList,
  riskScore,
  shoppingCartContent,
}: ShoppingCartFormVariables): ShoppingCartFormData => {
  const [sleevesList, setSleevesList] = useState<ShoppingCartSeries[]>([]);
  const [numberOfSleeves, setNumberOfSleeves] = useState(2);
  const [isCompositeModelPortfolioLoaded, setIsCompositeModelPortfolioLoaded] = useState<boolean>(false);
  const [
    compositeModelPortfolioVolatilityMatchStatus,
    setIsCompositeModelPortfolioVolatilityMatchStatus,
  ] = useState<VolatilityMatchStatus>(VolatilityMatchStatus.TOO_CONSERVATIVE);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isContinueButtonClicked, setIsContinueButtonClicked] = useState(false);
  const [totalPercentageValue, setTotalPercentageValue] = useState(0);
  const [compositeModelPortfolioOpeningMinimum, setCompositeModelPortfolioOpeningMinimum] = useState<
    SymphonyScalars['Decimal']
  >();
  const [compositeModelPortfolioRebalanceMinimum, setCompositeModelPortfolioRebalanceMinimum] = useState<
    SymphonyScalars['Decimal']
  >();
  const [compositeModelPortfolioVolatility, setCompositeModelPortfolioVolatility] = useState<
    SymphonyScalars['Decimal']
  >();
  const [symphonyError, setSymphonyError] = useState<Error | null>();
  const [computeCompositeModelPortfolio] = useComputeCompositeModelPortfolio();

  const { watch, errors: fieldsErrors, control, getValues, setValue } = useForm<FormData>({ mode: 'onTouched' });
  const { fields, append, remove } = useFieldArray({
    control,
    name: InputNames.allocation,
  });
  const watchAllFields = watch();

  const modelPortfolioSeries = getSeriesPortfolios(modelPortfolios);

  useEffect(() => {
    if (modelPortfolioSeries) {
      const sleevesLength = allocationValue ? allocationValue.length : 2;
      setNumberOfSleeves(sleevesLength);
      if (sleevesLength > sleevesList.length && sleevesList.length === 0) {
        const sleeves = sleevesList;
        for (let i = 0; i < sleevesLength; i++) {
          const newSleeve = {} as ShoppingCartSeries;
          newSleeve.portfolios = [];
          sleeves.push(newSleeve);
          if (allocationValue && allocationValue[i]) {
            const portfolio = allocationValue[i];
            const modelPortfolioSeriesId = portfolio.modelPortfolioSeriesId?.toString() || '';
            const modelPortfolioInternalId = portfolio.modelPortfolioInternalId.toString();
            const percentage = Number(portfolio.percentage);
            appendValues(modelPortfolioSeriesId, modelPortfolioInternalId, Math.trunc(percentage).toString());
            setTimeout(() => {
              onSeriesChange(modelPortfolioSeriesId, i, true);
              setFieldValues(i, modelPortfolioSeriesId, modelPortfolioInternalId, Math.trunc(percentage).toString());
            }, 1000);
          } else {
            appendValues('', '', null);
          }
        }
        setSleevesList(sleeves);
      }
    }
  }, []);

  useEffect(() => {
    if (quickApplySleeveTemplate && quickApplySleeveTemplate.allocations) {
      setValue(InputNames.templateName, quickApplySleeveTemplate.name);
      quickApplySleeveTemplate.allocations.forEach((portfolio, index) => {
        const modelPortfolioSeriesId = portfolio.modelPortfolioSeriesId?.toString() || '';
        const modelPortfolioInternalId = portfolio.modelPortfolioInternalId.toString();
        const percentage = Number(portfolio.percentage);
        if (index > numberOfSleeves - 1) {
          appendValues('', '', null);
        } else {
          setFieldValues(index, '', '', '');
        }
        setTimeout(() => {
          onSeriesChange(modelPortfolioSeriesId, index);
          setFieldValues(index, modelPortfolioSeriesId, modelPortfolioInternalId, Math.trunc(percentage).toString());
        });
      });
      if (quickApplySleeveTemplate.allocations.length <= maximumSleeveCount - 1) {
        remove(quickApplySleeveTemplate.allocations.length);
      }
      setNumberOfSleeves(quickApplySleeveTemplate.allocations.length);
    }
  }, [quickApplySleeveTemplate]);

  const appendValues = (
    modelPortfolioSeriesId: string,
    modelPortfolioIdentifier: string,
    percentage: string | null,
  ) => {
    append({
      modelPortfolioSeriesId,
      modelPortfolioIdentifier,
      percentage,
    });
  };

  const setFieldValues = (
    index: number,
    modelPortfolioSeriesId: string,
    modelPortfolioInternalId: string,
    percentage: string,
  ) => {
    setValue(`${InputNames.allocation}[${index}].${InputNames.modelPortfolioSeriesId}`, modelPortfolioSeriesId);
    setValue(`${InputNames.allocation}[${index}].${InputNames.modelPortfolioIdentifier}`, modelPortfolioInternalId);
    setValue(`${InputNames.allocation}[${index}].${InputNames.percentage}`, percentage);
  };

  const getSeriesOptions = (sleeveIndex: number) => {
    const list = [{ value: '', label: shoppingCartContent?.seriesPlaceholder ?? '' }];
    if (modelPortfolioSeries) {
      const allFields = getValues();
      const fieldValues = allFields.allocation ?? [];
      modelPortfolioSeries.forEach((series: PortfolioSeries) => {
        const valueIndex = fieldValues.findIndex(
          (allocation: any) => allocation.modelPortfolioSeriesId?.toString() === series.seriesId.toString(),
        );
        if (valueIndex < 0 || valueIndex === sleeveIndex) {
          list.push({ value: series.seriesId.toString(), label: series.seriesBaseName });
        }
      });
    }
    return list;
  };

  const onSeriesChange = (value: string, index: number, retainModelPortfolioIdentifier = false) => {
    const portfolios = sleevesList;
    if (value) {
      portfolios[index] = portfolios[index] ? portfolios[index] : ({} as ShoppingCartSeries);
      const portfoliosList = modelPortfolioSeries.filter(portfolio => portfolio.seriesId.toString() === value);
      if (portfoliosList && portfoliosList[0]) {
        portfolios[index].portfolios = portfoliosList[0].portfolios
          ? portfoliosList[0].portfolios.sort((a, b) => (a.internalId < b.internalId ? -1 : 1))
          : [];
      }
      setSleevesList(portfolios);
    } else {
      portfolios[index].portfolios = [];
      setSleevesList(portfolios);
    }
    const allFields = getValues();
    const allocationFieldValues = allFields.allocation;
    if (allocationFieldValues && !retainModelPortfolioIdentifier) {
      setValue(`${InputNames.allocation}[${index}].${InputNames.modelPortfolioIdentifier}`, '');
    }
  };

  const addSleeve = () => {
    if (numberOfSleeves <= maximumSleeveCount) {
      const sleeves = sleevesList;
      const newSleeve = {} as ShoppingCartSeries;
      sleeves.push(newSleeve);
      appendValues('', '', null);
      setSleevesList(sleeves);
      setNumberOfSleeves(numberOfSleeves + 1);
    }
  };

  const removeSleeve = () => {
    const sleeves = sleevesList;
    remove(numberOfSleeves - 1);
    sleeves.pop();
    setSleevesList(sleeves);
    setNumberOfSleeves(numberOfSleeves - 1);
  };

  const checkOpeningMinimum = async (allocationValuesList: CartAllocation[]) => {
    if (allocationValuesList) {
      const sleeveDataList: ComputeRecommendedPortfolioAllocationInput[] = [];
      let isValid = true;
      allocationValuesList.forEach(allocation => {
        const sleeveData = {} as ComputeRecommendedPortfolioAllocationInput;
        if (allocation.modelPortfolioSeriesId && allocation.modelPortfolioIdentifier && allocation.percentage) {
          sleeveData.modelPortfolioSeriesId = Number(allocation.modelPortfolioSeriesId);
          sleeveData.modelPortfolioInternalId = Number(allocation.modelPortfolioIdentifier);
          sleeveData.percentage = allocation.percentage.toString();
          sleeveDataList.push(sleeveData);
        } else {
          isValid = false;
        }
      });
      if (isValid) {
        try {
          const response = await computeCompositeModelPortfolio({
            variables: {
              allocation: sleeveDataList,
            },
          });
          const computeCompositeModelPortfolioData = response.data?.computeCompositeModelPortfolio;
          setCompositeModelPortfolioOpeningMinimum(
            computeCompositeModelPortfolioData?.minimumBalanceForAccountOpening ?? '',
          );
          setCompositeModelPortfolioRebalanceMinimum(
            computeCompositeModelPortfolioData?.minimumBalanceForRebalance ?? '',
          );
          setCompositeModelPortfolioVolatility(computeCompositeModelPortfolioData?.guidance?.volatility?.percentage);
          const volatilityCheck = checkVolatilityMatch(
            riskPreferenceVolatilityList ?? [],
            computeCompositeModelPortfolioData?.guidance?.volatility?.percentage ?? '',
            riskScore,
          );
          setIsCompositeModelPortfolioVolatilityMatchStatus(volatilityCheck);
        } catch (err: any) {
          setSymphonyError(err);
          console.error(err);
        } finally {
          setIsCompositeModelPortfolioLoaded(true);
        }
      }
    }
  };

  const onFieldValuesChanged = () => {
    setIsCompositeModelPortfolioVolatilityMatchStatus(VolatilityMatchStatus.TOO_CONSERVATIVE);
    if (isCompositeModelPortfolioLoaded) {
      setIsCompositeModelPortfolioLoaded(false);
    }
  };

  useEffect(() => {
    const allFields = getValues();
    setSymphonyError(null);
    const fieldValues = allFields.allocation;
    onFieldValuesChanged();
    let totalPercentage = 0;
    let isValidPercentage = true;
    if (fieldValues) {
      fieldValues.forEach((allocation: any) => {
        if (Number(allocation[InputNames.percentage]) > 0) {
          totalPercentage = totalPercentage + Number(allocation[InputNames.percentage]);
          if (
            isInvalidAllocationPercentage(
              Number(allocation[InputNames.percentage]),
              maximumPercentageAllowed,
              minimumPercentageAllowed,
            )
          ) {
            isValidPercentage = false;
          }
        }
      });
    }
    if (totalPercentage === 100 && isValidPercentage) {
      checkOpeningMinimum(fieldValues);
    }
    setTotalPercentageValue(totalPercentage);
  }, [JSON.stringify(watchAllFields.allocation)]);

  const saveTemplateFormValidity = useMemo(() => {
    let isFormValid = true;
    const allFields = getValues();
    const allocationFieldValues = allFields.allocation;
    const templateName = allFields.templateName;
    if (allocationFieldValues) {
      let totalPercentage = 0;
      allocationFieldValues.forEach((allocation: CartAllocation) => {
        if (
          !allocation[InputNames.modelPortfolioSeriesId] ||
          !allocation[InputNames.modelPortfolioIdentifier] ||
          !allocation[InputNames.percentage] ||
          isInvalidAllocationPercentage(
            Number(allocation[InputNames.percentage]),
            maximumPercentageAllowed,
            minimumPercentageAllowed,
          )
        ) {
          isFormValid = false;
        } else {
          totalPercentage = totalPercentage + Number(allocation.percentage);
        }
      });
      if (totalPercentage !== 100) {
        isFormValid = false;
      }
    }
    if (!templateName || !templateName.trim().length) {
      isFormValid = false;
    }
    return isFormValid;
  }, [JSON.stringify(watchAllFields)]);

  return {
    addSleeve,
    compositeModelPortfolioOpeningMinimum: compositeModelPortfolioOpeningMinimum ?? '',
    compositeModelPortfolioRebalanceMinimum: compositeModelPortfolioRebalanceMinimum ?? '',
    compositeModelPortfolioVolatility: compositeModelPortfolioVolatility ?? '0',
    compositeModelPortfolioVolatilityMatchStatus,
    control,
    fields,
    fieldsErrors,
    getSeriesOptions,
    getValues,
    isCompositeModelPortfolioLoaded,
    isContinueButtonClicked: [isContinueButtonClicked, setIsContinueButtonClicked],
    isSubmitButtonClicked: [isSubmitButtonClicked, setIsSubmitButtonClicked],
    numberOfSleeves,
    onSeriesChange,
    removeSleeve,
    saveTemplateFormValidity,
    sleevesList,
    symphonyError: [symphonyError, setSymphonyError],
    totalPercentageValue,
  };
};
