import { QuestionOrderSteps as QuestionOrderStep } from '../../../Section/types';
import { eDeliveryEmail } from '../../questions/eDelivery';
import { QuestionProperties } from '../../questions/types';

export const eDeliveryEmailNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return eDeliveryEmail({
    ...properties,
    additionalValidations: {
      maxLength: 80,
      ...properties.additionalValidations,
    },
  });
};
