import { DeepMap, FieldError, RegisterOptions, UseFormMethods } from 'react-hook-form';

import { QuestionKeys } from '../../configs/questions/types';
import { ManagedProductPaperworkWithFreeFormId } from '../../hooks/useGetPaperworkData';
import { FormData } from '../../types';
import { CmsKeys, ComponentTypes, Mask, QuestionContent, QuestionOrderSteps, ValidationMessage } from '../types';
import { getFieldName } from '../utils';

import { FinancialAccountType, RelationshipName } from '~/__generated__';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { CMSQuestions, SingleOptionInputQuestion, TextInputQuestion } from '~/containers/Paperwork/contentstack';
import { ManagedProductPaperwork, PaperworkRelationships, QuestionnaireData } from '~/containers/Paperwork/symphony';

interface GetComponentFieldsVariables {
  fieldsErrors: DeepMap<FormData, FieldError>;
  question: QuestionOrderSteps;
  singleOptionInputContents: (SingleOptionInputQuestion | null)[] | null;
  textInputContents: (TextInputQuestion | null)[] | null;
  validationMessages?: ValidationMessage[];
}

interface GetComponentFieldsData {
  componentType: ComponentTypes;
  errorMessage?: string | null;
  fieldName: string;
  inputErrors?: FieldError;
  questionContent: QuestionContent;
}

export const getComponentFields = (params: GetComponentFieldsVariables): GetComponentFieldsData => {
  const { fieldsErrors, question, singleOptionInputContents, validationMessages, textInputContents } = params;
  const paperworkFreeFormId = question.paperworkFreeFormId;
  const fieldName = getFieldName(question.dataPointKey, paperworkFreeFormId);
  const inputErrors = fieldsErrors[fieldName];
  const errorMessage = getErrorMessage(fieldName, question.questionKey, inputErrors?.type, validationMessages);
  const componentType = question.componentType;
  const questionContent = getQuestionContent({
    componentType,
    questionKey: question.questionKey,
    singleOptionInputContents,
    textInputContents,
  });

  return {
    errorMessage,
    fieldName,
    inputErrors,
    componentType,
    questionContent,
  };
};

export const getErrorMessage = (
  fieldName: string,
  questionKey: string,
  errorType?: string,
  validationMessages?: ValidationMessage[],
): string | undefined => {
  if (!errorType) {
    return undefined;
  }

  return (
    validationMessages?.find(m => m.key === `validation:${fieldName}:${errorType}`)?.label ??
    validationMessages?.find(m => m.key === `${fieldName}:${errorType}`)?.label ??
    validationMessages?.find(m => m.key === `validation:${questionKey}:${errorType}`)?.label ??
    validationMessages?.find(m => m.key === `${questionKey}:${errorType}`)?.label ??
    validationMessages?.find(m => m.key === `validation:${errorType}`)?.label ??
    validationMessages?.find(m => m.key === errorType)?.label ??
    errorType
  );
};

interface PrefillValueVariables {
  accountType: FinancialAccountType;
  allPaperworkDataAvailableForPrefill: ManagedProductPaperwork[];
  allSavedPaperworkData: ManagedProductPaperworkWithFreeFormId[];
  componentType: ComponentTypes;
  countriesList: DropdownItem<string>[];
  dropdownItems: DropdownItem[];
  getValues: UseFormMethods<FormData>['getValues'];
  partyId: string;
  question: QuestionOrderSteps;
  questionContent: QuestionContent;
  questionnaireInvestWealthData: QuestionnaireData[];
  savedPaperworkData?: ManagedProductPaperworkWithFreeFormId | null;
  statesList: DropdownItem<string>[];
}

export const getPrefillValue = ({
  accountType,
  allPaperworkDataAvailableForPrefill,
  allSavedPaperworkData,
  componentType,
  countriesList,
  dropdownItems,
  getValues,
  question,
  questionContent,
  questionnaireInvestWealthData,
  partyId,
  savedPaperworkData,
  statesList,
}: PrefillValueVariables): any => {
  let questionPrefillValue: any = question.defaultValue ?? '';
  if (question.prefillValueGetter) {
    const prefillValueFromPaperwork = question.prefillValueGetter(
      {
        accountType,
        allPaperworkDataAvailableForPrefill,
        allSavedPaperworkData,
        getFormValue: getValues,
        partyId,
        questionnaireInvestWealthData,
        savedPaperworkData: savedPaperworkData ?? undefined,
      },
      { countriesList, statesList },
    );
    // for some fields, we don't really have a single persisted value, but a range of options to chose from
    if (prefillValueFromPaperwork && typeof prefillValueFromPaperwork === 'object') {
      if (componentType === ComponentTypes.Dropdown) {
        const matchingOption = dropdownItems.find(option => (prefillValueFromPaperwork as any[]).includes(option.value))
          ?.value;
        if (matchingOption) {
          questionPrefillValue = matchingOption;
        }
      } else if (componentType === ComponentTypes.Radio) {
        const matchingOption = questionContent.options?.find(option =>
          (prefillValueFromPaperwork as string[]).includes(option.value),
        )?.value;
        if (matchingOption) {
          questionPrefillValue = matchingOption;
        }
      }
    } else if (prefillValueFromPaperwork) {
      questionPrefillValue = prefillValueFromPaperwork;
    }
  }

  // finally, sanitise prefill value for dropdown
  if (
    componentType === ComponentTypes.Dropdown &&
    questionPrefillValue &&
    !dropdownItems.find(item => item.value === questionPrefillValue)
  ) {
    questionPrefillValue = '';
  }

  // finally, sanitise prefill value for checkbox
  if (componentType === ComponentTypes.Checkbox && !questionPrefillValue) {
    questionPrefillValue = false;
  }

  return questionPrefillValue;
};

export const getDropdownItemsFromCms = (
  questionContent: QuestionContent,
  cmsKey?: string,
  countriesList?: DropdownItem<string>[],
  statesList?: DropdownItem<string>[],
  allowedOptions?: string[],
  hiddenOptions?: string[],
): DropdownItem[] => {
  const sortFunc = (a: DropdownItem, b: DropdownItem) =>
    a.label && b.label ? (a.label > b.label ? 1 : a.label < b.label ? -1 : 0) : 0;
  const dropdownItems =
    cmsKey === CmsKeys.States
      ? statesList?.sort(sortFunc) ?? []
      : cmsKey === CmsKeys.Countries
      ? [
          countriesList?.find(country => country.value === '999') as DropdownItem,
          ...(countriesList?.filter(country => country.value !== '999')?.sort(sortFunc) ?? []),
        ]
      : questionContent.options ?? [];
  return (dropdownItems as DropdownItem[])
    .filter(item => !allowedOptions || allowedOptions.includes(item.value.toString()))
    .filter(item => !hiddenOptions?.includes(item.value.toString()));
};

export const getQuestionsContentForCustomComponent = (questionsContent: CMSQuestions, questionKey: string) => {
  const singleOptionInputContents = questionsContent.single_option_input_questions;
  const textInputContents = questionsContent.text_input_questions;

  const singleInputQuestions =
    singleOptionInputContents
      ?.filter(c => c?.key?.startsWith(questionKey))
      .map(c =>
        getQuestionContent({
          componentType: ComponentTypes.Dropdown, // Hard-coded to extract all relevant content
          questionKey: c?.key ?? '',
          singleOptionInputContents,
          textInputContents,
        }),
      ) ?? [];
  const textQuestions =
    textInputContents
      ?.filter(c => c?.key?.startsWith(questionKey))
      .map(c =>
        getQuestionContent({
          componentType: ComponentTypes.Input, // Hard-coded to extract all relevant content
          questionKey: c?.key ?? '',
          singleOptionInputContents,
          textInputContents,
        }),
      ) ?? [];

  return singleInputQuestions.concat(textQuestions);
};

export const getQuestionContent = (params: {
  componentType: ComponentTypes;
  questionKey: string;
  singleOptionInputContents: (SingleOptionInputQuestion | null)[] | null;
  textInputContents: (TextInputQuestion | null)[] | null;
}): QuestionContent => {
  const { componentType, questionKey, singleOptionInputContents, textInputContents } = params;
  if (componentType === ComponentTypes.Radio || componentType === ComponentTypes.Dropdown) {
    const singleInputQuestionContent = singleOptionInputContents?.find(c => c?.key === questionKey);
    if (singleInputQuestionContent) {
      return {
        key: singleInputQuestionContent.key ?? '',
        question: singleInputQuestionContent.question ?? '',
        options:
          singleInputQuestionContent.input_options?.map(o => ({
            value: o?.value ?? '',
            label: o?.label ?? '',
          })) ?? [],
      };
    }
  } else {
    const textInputQuestionContent = textInputContents?.find(c => c?.key === questionKey);
    if (textInputQuestionContent) {
      return {
        key: textInputQuestionContent.key ?? '',
        question: textInputQuestionContent.question ?? '',
        character_limit: textInputQuestionContent.character_limit ?? 50,
        prefix: textInputQuestionContent.prefix ?? undefined,
        suffix: textInputQuestionContent.suffix ?? undefined,
      };
    }
  }

  return { key: '', question: '', error: true };
};

export const filterRelationshipSymphonyData = (
  symphonyData: ManagedProductPaperwork | null,
  questionKey: string,
): PaperworkRelationships[] => {
  if (!symphonyData?.relationships?.length) {
    return [];
  }

  switch (questionKey) {
    case QuestionKeys.PRIMARY_BENEFICIARY:
      return symphonyData.relationships.filter(
        (datum): datum is PaperworkRelationships => datum?.name === RelationshipName.BENEFICIARY_PRIMARY,
      );
    case QuestionKeys.CONTINGENT_BENEFICIARY:
      return symphonyData.relationships.filter(
        (datum): datum is PaperworkRelationships => datum?.name === RelationshipName.BENEFICIARY,
      );
    case QuestionKeys.TRUSTED_CONTACT:
      return symphonyData.relationships.filter(
        (datum): datum is PaperworkRelationships => datum?.name === RelationshipName.TRUSTED_CONTACT,
      );
    default:
      return [];
  }
};

export const maskData = (value: string, mask: Mask): string => {
  return value
    .split('')
    .map((char, index) => {
      if (index < value.length - mask.charsToShow) {
        return mask.maskChar;
      }
      return char;
    })
    .join('');
};

export const formatInputQuestionDefaultValue = ({
  capitalize,
  defaultValue,
  invalidCharacterRegex,
  masked,
  validationRules,
}: {
  capitalize?: boolean;
  defaultValue?: string | number;
  invalidCharacterRegex?: RegExp;
  masked?: Mask;
  validationRules: RegisterOptions;
}) => {
  if (typeof defaultValue === 'number') {
    return defaultValue;
  }

  let finalValue = (defaultValue ?? '').trim();
  if (invalidCharacterRegex) {
    finalValue = finalValue.replace(invalidCharacterRegex, '');
  }
  if (validationRules.maxLength) {
    finalValue = finalValue.slice(0, validationRules.maxLength as number);
  }
  if (capitalize) {
    finalValue = finalValue.toUpperCase();
  }
  if (masked) {
    finalValue = maskData(finalValue, masked);
  }
  return finalValue;
};
