import { ComponentTypes, QuestionOrderSteps } from '../../Section/types';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';

export const aboutAdditionalStakeholder = ({ rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.ADDITIONAL_STAKEHOLDER,
    dataPointKey: DataPointKeys.ADDITIONAL_STAKEHOLDER,
    componentType: ComponentTypes.Label,
    rules,
  };
};

export const designatePrimaryBeneficiary = ({ rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.DESIGNATE_PRIMARY_BENEFICIARY_LABEL,
    dataPointKey: DataPointKeys.DESIGNATE_PRIMARY_BENEFICIARY_LABEL,
    componentType: ComponentTypes.Label,
    rules,
  };
};

export const employment = ({ rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.EMPLOYMENT,
    dataPointKey: DataPointKeys.EMPLOYMENT,
    componentType: ComponentTypes.Label,
    rules,
  };
};

export const identification = ({ rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.IDENTIFICATION,
    dataPointKey: DataPointKeys.IDENTIFICATION,
    componentType: ComponentTypes.Label,
    rules,
  };
};

export const revocablePrimaryTrustee = ({ rules }: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.REVOCABLE_PRIMARY_TRUSTEE,
    dataPointKey: DataPointKeys.REVOCABLE_PRIMARY_TRUSTEE,
    componentType: ComponentTypes.Label,
    rules,
  };
};
