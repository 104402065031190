import { useEffect, useMemo, useState } from 'react';

import { useGetRelatedParties } from '../symphony';

import { ContactType, FinancialAccountType } from '~/__generated__';
import { useGetCompositeModelPortfolioAllocationContent } from '~/components/CompositeModelPortfolioAllocation/contentstack';
import {
  CompositeModelPortfolioAllocationContent,
  getCompositeModelPortfolioAllocationContent,
} from '~/components/CompositeModelPortfolioAllocation/hooks';
import { useGetClientInfo, useGetPartyPrimaryFAInfo } from '~/hooks/client/symphony';
import { GetClientInfo } from '~/hooks/client/symphony/__generated__/query.v2';
import { useClientInfo } from '~/hooks/client/useClientInfo';
import { useGetAllModelPortfoliosContentAcrossPages } from '~/hooks/model-portfolios/contentstack';
import { useGetDownloadableQPRContent } from '~/hooks/qpr/contentstack';
import { GetDownloadableQPRContent_all_downloadable_qpr_items } from '~/hooks/qpr/contentstack/__generated__/query.v2';
import { getEmail, getFullName, getPhone } from '~/utils/client';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface AccountActivityVariables {
  accountType?: FinancialAccountType;
  contentOptions: ContentOptions;
  managedProductId: string;
  partyId: string;
  partyIdFA: string | null;
  shouldFetchAdditionalClientsInfo: boolean;
}

export interface ModelPortfolioContent {
  modelPortfolioIdentifier: string;
  modelPortfolioName: string;
  modelPortfolioSeriesBaseName: string;
}

export interface PartyPerson {
  displayName: string;
  familyName: string;
  givenName: string;
  middleName: string;
  title: string;
}

export interface PartyContact {
  applicationId: string;
  contact: string;
  isPrimary: boolean;
  type: ContactType;
}

export interface Party {
  partyContacts: PartyContact[];
  partyPerson: PartyPerson;
}

export interface PartyInfo {
  email: string;
  name: string;
  phoneNumber: string;
  title?: string;
}

export interface OpeningClosingBalance {
  closingBalance?: string | null;
  openingBalance?: string | null;
}

export interface PerformancePdfData {
  additionalClientsInfo?: PartyInfo[];
  advisorInfo?: PartyInfo;
  clientInfo?: GetClientInfo;
  modelPortfoliosContentData?: ModelPortfolioContent[];
  pdfContentData?: GetDownloadableQPRContent_all_downloadable_qpr_items | null;
  sleevesContent: CompositeModelPortfolioAllocationContent;
}

export const useGetDataForPerformancePdfData = ({
  variables: { accountType, contentOptions, managedProductId, partyId, partyIdFA, shouldFetchAdditionalClientsInfo },
}: {
  variables: AccountActivityVariables;
}): AsyncResult<PerformancePdfData> => {
  const [state, setState] = useState<AsyncResult<PerformancePdfData>>({
    loading: true,
  });

  const { data: pdfContentData, loading: pdfContentLoading, error: pdfContentError } = useGetDownloadableQPRContent({
    variables: contentOptions,
  });

  const { data: cmpData, loading: cmpLoading, error: cmpError } = useGetCompositeModelPortfolioAllocationContent({
    variables: contentOptions,
  });

  const { data: clientInfo, loading: clientInfoLoading, error: clientInfoError } = useGetClientInfo({
    variables: { partyId },
  });

  const {
    data: additionalClientsInfo,
    loading: additionalClientsInfoLoading,
    error: additionalClientsInfoError,
  } = useGetRelatedParties({
    variables: { managedProductId },
    skip: !shouldFetchAdditionalClientsInfo,
  });

  const { data: faInfo, loading: faInfoLoading, error: faInfoError } = useClientInfo({
    variables: { partyId: partyIdFA ?? '' },
    skip: !partyIdFA,
  });

  // API call to get primary FA info for DW as partyIdFA will not be available
  const { data: primaryFAInfo, loading: primaryFAInfoLoading, error: primaryFAInfoError } = useGetPartyPrimaryFAInfo({
    variables: { partyId },
    skip: !!partyIdFA,
  });

  const {
    data: modelPortfoliosContentData,
    loading: modelPortfoliosContentLoading,
    error: modelPortfoliosContentError,
  } = useGetAllModelPortfoliosContentAcrossPages({
    contentOptions,
  });

  const additionalClientsData: PartyInfo[] | undefined = useMemo(
    () =>
      (additionalClientsInfo?.managedProduct?.relatedParties
        ?.filter(
          party =>
            party.partyId !== partyId &&
            accountType === FinancialAccountType.JOINT &&
            (party.relationshipName?.includes('Joint') || party.relationshipName === 'Owner'),
        )
        ?.map(party => ({
          partyPerson: {
            displayName: party.party?.partyPerson?.displayName,
            familyName: party.party?.partyPerson?.familyName,
            givenName: party.party?.partyPerson?.givenName,
            title: party.party?.partyPerson?.title,
          },
          partyContacts: party.party?.partyContacts?.map(contact => ({
            applicationId: contact.applicationId,
            contact: contact.contact,
            isPrimary: !!contact.isPrimary,
            type: contact.type,
          })),
        })) as Party[] | undefined)?.map(party => ({
        email: getEmail(party),
        name: getFullName(party.partyPerson) ?? '',
        phoneNumber: getPhone(party),
      })),
    [accountType, additionalClientsInfo, partyId],
  );

  const advisorInfo: PartyInfo | undefined = useMemo(() => {
    if (faInfo) {
      return {
        email: faInfo.email,
        name: faInfo.fullName ?? '',
        phoneNumber: faInfo.phone,
      };
    }
    if (primaryFAInfo) {
      return {
        email: primaryFAInfo.client?.party?.primaryAdvisor?.email ?? '',
        name: `${primaryFAInfo.client?.party?.primaryAdvisor?.givenName} ${primaryFAInfo.client?.party?.primaryAdvisor?.familyName}`.trim(),
        phoneNumber: primaryFAInfo.client?.party?.primaryAdvisor?.phoneNumber ?? '',
      };
    }
    return undefined;
  }, [faInfo, primaryFAInfo]);

  useEffect(() => {
    const loading = [
      pdfContentLoading,
      clientInfoLoading,
      faInfoLoading,
      primaryFAInfoLoading,
      additionalClientsInfoLoading,
      cmpLoading,
      modelPortfoliosContentLoading,
    ];
    const error = [
      pdfContentError,
      clientInfoError,
      faInfoError,
      primaryFAInfoError,
      additionalClientsInfoError,
      cmpError,
      modelPortfoliosContentError,
    ];

    if (loading.includes(true)) {
      setState({ loading: true });
      return;
    }

    const currentError = error.find(el => !!el);
    if (currentError) {
      console.error(currentError);
      setState({
        loading: false,
        error: currentError,
      });
      return;
    }

    setState({
      loading: false,
      data: {
        pdfContentData: pdfContentData?.all_downloadable_qpr?.items?.[0],
        clientInfo,
        advisorInfo,
        additionalClientsInfo: additionalClientsData,
        modelPortfoliosContentData:
          modelPortfoliosContentData?.all_model_portfolio_data?.items?.map(item => ({
            modelPortfolioIdentifier: item?.internal_id ?? '',
            modelPortfolioName: item?.model_portfolio_name_shorthand || 'Unknown',
            modelPortfolioSeriesBaseName: item?.series_name ?? '',
          })) ?? [],
        sleevesContent: getCompositeModelPortfolioAllocationContent(cmpData),
      },
    });
  }, [
    additionalClientsInfoError,
    advisorInfo,
    clientInfoError,
    faInfoError,
    primaryFAInfoError,
    additionalClientsInfoLoading,
    clientInfoLoading,
    faInfoLoading,
    primaryFAInfoLoading,
    additionalClientsData,
    clientInfo,
    faInfo,
    primaryFAInfo,
    cmpData,
    cmpLoading,
    modelPortfoliosContentData,
    modelPortfoliosContentLoading,
    cmpError,
    modelPortfoliosContentError,
    pdfContentLoading,
    pdfContentError,
    pdfContentData,
  ]);

  return state;
};
