import { format } from 'date-fns';

import { Content } from '.';
import {
  GetDownloadableProposal_all_downloadable_portfolio_compare_items,
  GetDownloadableProposal_all_downloadableproposal_items,
} from './contentstack/__generated__/query.v2';

import { variableString } from '~/components/ui/redactor/utils/helpers';
import { GoalOnboardingContent } from '~/hooks/goals/useGoalOnboardingContent';
import { formatPercentage } from '~/utils/format';

export const returnRegex = (str: string): RegExp => {
  return new RegExp(str, 'g');
};

export const replaceVariable = (str: string, variableList: { value: string; variable: string }[]): string => {
  let result = str.replace(returnRegex('&nbsp;'), ' ');
  if (str.includes('<p>')) {
    result = result.replace(returnRegex('</p>'), '');
    result = result.replace(returnRegex('<p>'), '');
  }
  variableList.forEach(item => {
    result = result.replace(returnRegex(variableString(item.variable)), item.value);
  });
  return result;
};

export const formatPercentageToTwoDecimals = (value: string) =>
  formatPercentage(parseFloat(value), {
    decimals: 2,
    removeTrailingZeroes: false,
    convertRatioToPercentage: false,
  });

export const getDownloadableProposalContent = (
  clientName: string,
  financialAdvisor: { advisorName: string; email: string; phoneNumber: string },
  data?: GetDownloadableProposal_all_downloadableproposal_items,
  portfolioCompareDownloadProposalContent?: GetDownloadableProposal_all_downloadable_portfolio_compare_items,
  goalOnboardingContent?: Pick<GoalOnboardingContent, 'getDownloadableProposalLabel' | 'getGoalObjective'>,
): Content => {
  const { advisorName, phoneNumber, email } = financialAdvisor;
  const commonText = {
    logo: data?.logoConnection?.edges?.[0]?.node?.url ?? '',
    footerText: replaceVariable(data?.footer ?? '', [
      {
        value: clientName,
        variable: 'clientName',
      },
      {
        value: advisorName,
        variable: 'advisorName',
      },
      {
        value: `${format(new Date(), 'MMM dd, yyyy')} at ${format(new Date(), 'HH:mm')}`,
        variable: 'dateTime',
      },
    ]),
  };
  const findReturnContentTextOrImage = (text: string, type: string): string => {
    if (type === 'text') {
      return portfolioCompareDownloadProposalContent?.text_sections?.find(x => x?.text_id === text)?.value ?? '';
    } else {
      return (
        portfolioCompareDownloadProposalContent?.icon_sections?.find(x => x?.icon_id === text)?.icon_urlConnection
          ?.edges?.[0]?.node?.url ?? ''
      );
    }
  };

  return {
    appendix: {
      pageName: data?.appendix?.pagename ?? '',
      title: data?.appendix?.title ?? '',
      showFooter: !!data?.appendix?.show_footer,
      showHeader: !!data?.appendix?.show_header,
      ...commonText,
    },
    cover: {
      title: data?.cover?.title ?? '',
      titleSuperscript: data?.cover?.title_superscript ?? '',
      subtitle: data?.cover?.subtitle ?? '',
      email: replaceVariable(data?.cover?.email ?? '', [{ value: email, variable: 'email' }]),
      emailImage: data?.cover?.email_imageConnection?.edges?.[0]?.node?.url ?? '',
      phone: replaceVariable(data?.cover?.phone ?? '', [{ value: phoneNumber, variable: 'phoneNumber' }]),
      phoneImage: data?.cover?.phone_imageConnection?.edges?.[0]?.node?.url ?? '',
      ...commonText,
    },
    detailedInvestmentBreakdown: {
      allocationDetails: {
        assetAllocationColumns: {
          allocation: data?.detailed_investment_breakdown?.asset_allocation_columns?.allocation ?? '',
          asset: data?.detailed_investment_breakdown?.asset_allocation_columns?.asset ?? '',
          assetClass: data?.detailed_investment_breakdown?.asset_allocation_columns?.asset_class ?? '',
        },
        assetAllocationTitle: data?.detailed_investment_breakdown?.asset_allocation_title ?? '',
        donutTargetAllocationLabel: data?.detailed_investment_breakdown?.donut_label ?? '',
        subtitle: data?.detailed_investment_breakdown?.subtitle?.map(item => item ?? '') ?? [],
        title: data?.detailed_investment_breakdown?.title ?? '',
      },
      infoText: data?.detailed_investment_breakdown?.info_text ?? '',
      compositeModelPortfolioName: data?.detailed_investment_breakdown?.composite_model_portfolio_name ?? '',
      pageName: data?.detailed_investment_breakdown?.pagename ?? '',
      ...commonText,
    },
    feesAndDisclosures: {
      pageName: data?.fees_and_disclosures?.pagename ?? '',
      feeContent: {
        title: data?.fees_and_disclosures?.fees?.title ?? '',
        subtitle: data?.fees_and_disclosures?.fees?.subtitle ?? '',
        pricingDetails: {
          accountValue: data?.fees_and_disclosures?.fees?.pricing_details?.account_value ?? 0,
          accountValueText: data?.fees_and_disclosures?.fees?.pricing_details?.account_value_text ?? '',
          discount: data?.fees_and_disclosures?.fees?.pricing_details?.discount
            ? {
                label: data.fees_and_disclosures.fees.pricing_details.discount.label ?? '',
                value: data.fees_and_disclosures.fees.pricing_details.discount.value ?? 0,
              }
            : undefined,
          title: data?.fees_and_disclosures?.fees?.pricing_details?.title ?? '',
          estimateAnnualFeeText: data?.fees_and_disclosures?.fees?.pricing_details?.estimated_annual_fee ?? '',
          fees:
            data?.fees_and_disclosures?.fees?.pricing_details?.fees?.map(item => ({
              label: item?.label ?? '',
              value: item?.value ?? 0,
            })) ?? [],
        },
      },
      disclosure: {
        disclosureList: data?.fees_and_disclosures?.disclosure?.disclosure_list?.map(item => item ?? '') ?? [],
        title: data?.fees_and_disclosures?.disclosure?.title ?? '',
        importantLinkText: data?.fees_and_disclosures?.disclosure?.important_link_text ?? '',
        servicesText: data?.fees_and_disclosures?.disclosure?.services_text ?? '',
        importantLinks:
          data?.fees_and_disclosures?.disclosure?.important_links?.map(item => ({
            link: item?.link ?? '',
            text: item?.text ?? '',
          })) ?? [],
        servicesList: data?.fees_and_disclosures?.disclosure?.services_list?.map(item => item ?? '') ?? [],
      },
      ...commonText,
    },
    riskPlaybackAndInvestmentRecommendation: {
      pageName: data?.riskplayback_and_investmentrecommendation?.pagename ?? '',
      riskPlayback: {
        title: data?.riskplayback_and_investmentrecommendation?.risk_playback?.title ?? '',
        subtitle: data?.riskplayback_and_investmentrecommendation?.risk_playback?.subtitle ?? '',
        descriptionLabel: data?.riskplayback_and_investmentrecommendation?.risk_playback?.description_label ?? '',
        riskToleranceTitle: data?.riskplayback_and_investmentrecommendation?.risk_playback?.risk_tolerance_title ?? '',
      },
      investmentRecommendation: {
        title: data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.title ?? '',
        subtitle: data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.subtitle ?? '',
        descriptionLabel:
          data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.description_label ?? '',
        compositePortfolioName:
          data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.composite_portfolio_name ?? '',
        blendLabel: data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.blend_label ?? '',
        percentageLabel:
          data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.percentage_label ?? '',
        minimumInvestmentLabel:
          data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.minimum_investment_label ?? '',
      },
      goalPlayback: goalOnboardingContent,
      ...commonText,
    },
    tableOfContents: {
      pageName: data?.table_of_contents?.pagename ?? '',
      title: data?.table_of_contents?.title ?? '',
      pages: data?.table_of_contents?.pages?.map(item => ({ page: item?.page ?? '', index: item?.index ?? '' })) ?? [],
      ...commonText,
    },
    whyDigitalAdvicePro: {
      pageName: data?.why_digital_advice_pro?.pagename ?? '',
      title: data?.why_digital_advice_pro?.title ?? '',
      benefitTitle: data?.why_digital_advice_pro?.benefit_title ?? '',
      benefits:
        data?.why_digital_advice_pro?.benefits?.map(item => ({
          title: item?.title ?? '',
          text: item?.text ?? '',
          logo: item?.logoConnection?.edges?.[0]?.node?.url ?? '',
        })) ?? [],
      info: data?.why_digital_advice_pro?.info?.map(item => item ?? '') ?? [],
      ...commonText,
    },
    portfolioCompareDetails: {
      category: findReturnContentTextOrImage('category', 'icon'),
      currentPortfolio: findReturnContentTextOrImage('current_portfolio', 'icon'),
      proposedPortfolio: findReturnContentTextOrImage('proposed_portfolio', 'icon'),
      pageName: findReturnContentTextOrImage('page_name', 'text'),
      title: findReturnContentTextOrImage('page_title', 'text'),
      tickerText: findReturnContentTextOrImage('ticker_text', 'text'),
      fundNameText: findReturnContentTextOrImage('fund_name_text', 'text'),
      singleStockExposureFundValue: findReturnContentTextOrImage('single_stock_exposure_fund_value', 'text'),
      expenseRatioFundValue: findReturnContentTextOrImage('expense_ratio_fund_value', 'text'),
      companyText: findReturnContentTextOrImage('company_text', 'text'),
      info: findReturnContentTextOrImage('info', 'text'),
      gradeError: findReturnContentTextOrImage('grade_error', 'icon'),
      gradeGood: findReturnContentTextOrImage('grade_good', 'icon'),
      gradeLow: findReturnContentTextOrImage('grade_low', 'icon'),
      gradeLowText: findReturnContentTextOrImage('grade_text_low', 'text'),
      gradeModerateText: findReturnContentTextOrImage('grade_text_moderate', 'text'),
      gradeHighText: findReturnContentTextOrImage('grade_text_high', 'text'),
      gradeTextDiversifiedLow: findReturnContentTextOrImage('grade_text_diversified_low', 'text'),
      gradeTextDiversifiedMedium: findReturnContentTextOrImage('grade_text_diversified_medium', 'text'),
      gradeTextDiversifiedHigh: findReturnContentTextOrImage('grade_text_diversified_high', 'text'),
      categoryText: findReturnContentTextOrImage('category', 'text'),
      currentPortfolioText: findReturnContentTextOrImage('current_portfolio', 'text'),
      proposedPortfolioText: findReturnContentTextOrImage('proposed_portfolio', 'text'),
      allocation: {
        heading: findReturnContentTextOrImage('allocation_heading', 'text'),
        description: findReturnContentTextOrImage('allocation_description', 'text'),
        icon: findReturnContentTextOrImage('allocation', 'icon'),
        modelPortfolio: {
          subContent: findReturnContentTextOrImage('allocation_model_portfolio_subcontent', 'text'),
        },
        userPortfolio: {
          subContent: findReturnContentTextOrImage('allocation_user_portfolio_subcontent', 'text'),
        },
      },
      stockBondSplit: {
        heading: findReturnContentTextOrImage('stock_bond_split_heading', 'text'),
        description: findReturnContentTextOrImage('stock_bond_split_description', 'text'),
        icon: findReturnContentTextOrImage('stock_bond_split', 'icon'),
        modelPortfolio: {
          subText: findReturnContentTextOrImage('stock_bond_split_subtext', 'text'),
          subContent: findReturnContentTextOrImage('stock_bond_model_portfolio_subcontent', 'text'),
        },
        userPortfolio: {
          subText: findReturnContentTextOrImage('stock_bond_split_subtext', 'text'),
          subContent: findReturnContentTextOrImage('stock_bond_user_portfolio_subcontent', 'text'),
        },
      },
      cashDrag: {
        heading: findReturnContentTextOrImage('cash_drag_heading', 'text'),
        description: findReturnContentTextOrImage('cash_drag_description', 'text'),
        icon: findReturnContentTextOrImage('cash_drag', 'icon'),
        modelPortfolio: {
          subContent: findReturnContentTextOrImage('cash_drag_model_portfolio_subcontent', 'text'),
        },
        userPortfolio: {
          subContent: findReturnContentTextOrImage('cash_drag_user_portfolio_subcontent', 'text'),
        },
      },
      expenseRatio: {
        heading: findReturnContentTextOrImage('expense_ratio_heading', 'text'),
        description: findReturnContentTextOrImage('expense_ratio_description', 'text'),
        icon: findReturnContentTextOrImage('expense_ratio', 'icon'),
        modelPortfolio: {
          subContent: findReturnContentTextOrImage('expense_ratio_model_portfolio_subcontent', 'text'),
          tableHeading: findReturnContentTextOrImage('expense_ratio_model_portfolio_table_heading', 'text'),
        },
        userPortfolio: {
          subContent: findReturnContentTextOrImage('expense_ratio_user_portfolio_subcontent', 'text'),
          tableHeading: findReturnContentTextOrImage('expense_ratio_user_portfolio_table_heading', 'text'),
        },
      },
      singleStockExposure: {
        heading: findReturnContentTextOrImage('single_stock_exposure_heading', 'text'),
        description: findReturnContentTextOrImage('single_stock_exposure_description', 'text'),
        icon: findReturnContentTextOrImage('single_stock_exposure', 'icon'),
        modelPortfolio: {
          subContent: findReturnContentTextOrImage('single_stock_exposure_model_subcontent', 'text'),
        },
        userPortfolio: {
          subContent: findReturnContentTextOrImage('single_stock_exposure_user_subcontent', 'text'),
        },
      },
      geographicDiversification: {
        heading: findReturnContentTextOrImage('geographic_diversification_heading', 'text'),
        description: findReturnContentTextOrImage('geographic_diversification_description', 'text'),
        icon: findReturnContentTextOrImage('geographic_diversification', 'icon'),
        modelPortfolio: {
          subContent: findReturnContentTextOrImage('geographic_diversification_model_portfolio_subcontent', 'text'),
        },
        userPortfolio: {
          subContent: findReturnContentTextOrImage('geographic_diversification_user_portfolio_subcontent', 'text'),
        },
      },
      volatility: {
        heading: findReturnContentTextOrImage('volatility_heading', 'text'),
        description: findReturnContentTextOrImage('volatility_description', 'text'),
        icon: findReturnContentTextOrImage('volatility', 'icon'),
        modelPortfolio: {
          subContent: findReturnContentTextOrImage('volatility_model_portfolio_subcontent', 'text'),
        },
        userPortfolio: {
          subContent: findReturnContentTextOrImage('volatility_user_portfolio_subcontent', 'text'),
        },
      },
      calculationsAndDisclosures: {
        cashDragHeading: findReturnContentTextOrImage('discloser_cash_drag_heading', 'text'),
        cashDragText: findReturnContentTextOrImage('discloser_cash_drag_text', 'text'),
        discloserFooter: findReturnContentTextOrImage('discloser_footer', 'text'),
        discloserHeading: findReturnContentTextOrImage('discloser_heading', 'text'),
        equityHeading: findReturnContentTextOrImage('discloser_equity_heading', 'text'),
        equityText: findReturnContentTextOrImage('discloser_equity_text', 'text'),
        expensiveFundsHeading: findReturnContentTextOrImage('discloser_expensive_funding_heading', 'text'),
        expensiveFundsText: findReturnContentTextOrImage('discloser_expensive_funding_text', 'text'),
        expensiveFundsTextFive: findReturnContentTextOrImage('discloser_expensive_funding_five', 'text'),
        expensiveFundsTextFour: findReturnContentTextOrImage('discloser_expensive_funding_four', 'text'),
        expensiveFundsTextThree: findReturnContentTextOrImage('discloser_expensive_funding_text_three', 'text'),
        expensiveFundsTextTwo: findReturnContentTextOrImage('discloser_expensive_funding_text_two', 'text'),
        formula: findReturnContentTextOrImage('disclouser_formula_one', 'icon'),
        formulaTwo: findReturnContentTextOrImage('disclouser_formula_two', 'icon'),
        geographicDiversificationHeading: findReturnContentTextOrImage('discloser_geographic_heading', 'text'),
        geographicDiversificationText: findReturnContentTextOrImage('discloser_geographic_text', 'text'),
        singleStockHeading: findReturnContentTextOrImage('discloser_single_stock_heading', 'text'),
        singleStockText: findReturnContentTextOrImage('discloser_single_stock_text', 'text'),
        singleStockTextTwo: findReturnContentTextOrImage('discloser_single_stock_text_two', 'text'),
        volatilityHeading: findReturnContentTextOrImage('discloser_volatility_heading', 'text'),
        volatilityText: findReturnContentTextOrImage('discloser_volatility_text', 'text'),
        volatilityTextTwo: findReturnContentTextOrImage('discloser_volatility_text_two', 'text'),
      },
      singleStockExposureZeroValue: findReturnContentTextOrImage(
        'single_stock_exposure_user_subcontent_zero_value',
        'text',
      ),
      singleStockExposureOneValue: findReturnContentTextOrImage(
        'single_stock_exposure_user_subcontent_one_value',
        'text',
      ),
      ...commonText,
    },
  };
};
