import {
  GetAccountsSummaryContent,
  GetAccountsSummaryContent_all_account_summary_items_sections,
  GetAccountsSummaryContent_all_account_summary_items_sections_AccountSummarySectionsAccountList,
  GetAccountsSummaryContentVariables,
} from './__generated__/getAccountsSummaryContent.v2';
import * as accountsSummaryQuery from './getAccountsSummaryContent.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type AccountListSections = GetAccountsSummaryContent_all_account_summary_items_sections;
export type AccountListSection = GetAccountsSummaryContent_all_account_summary_items_sections_AccountSummarySectionsAccountList;

export const isAccountListSection = (section: AccountListSections | null): section is AccountListSection =>
  section?.__typename === 'AccountSummarySectionsAccountList';

export const useGetAccountsSummaryContent = (
  options?: QueryHookOptions<GetAccountsSummaryContent, GetAccountsSummaryContentVariables>,
): QueryResult<GetAccountsSummaryContent, GetAccountsSummaryContentVariables> => {
  return useContentstackQuery(accountsSummaryQuery.GetAccountsSummaryContent, options);
};
