import { GetRelevantDocuments, GetRelevantDocumentsVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetRelevantDocuments = (
  options?: QueryHookOptions<GetRelevantDocuments, GetRelevantDocumentsVariables>,
): QueryResult<GetRelevantDocuments, GetRelevantDocumentsVariables> => {
  return useContentstackQuery(queries.GetRelevantDocuments, options);
};
