import React, { FC, useMemo } from 'react';

import { ReturnsChartContent, SecurityReturns } from '../types';
import { formatDisplayDate, formatSecurityName } from '../utils';

import { Chart } from './Chart';

import { PerformanceDetails, PerformanceDetailsType } from '~/components/modals/PerformanceDetails';
import { fillMissingData } from '~/components/PerformanceChart/utils';
import { useModalState } from '~/components/ui/Modal/hooks';
import {
  Box,
  Button,
  FiberManualRecordIcon,
  Grid,
  IconButton,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  OpenInNewIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { formatPercentage } from '~/utils/format';
import { DailyChartValue } from '~/utils/types';

export interface Props {
  accountNumberDisplay: string;
  accountReturns: DailyChartValue[];
  accountReturnsPercentage: string;
  content: ReturnsChartContent;
  contentOptions: ContentOptions;
  dataQa?: string;
  endDate: string;
  onToggleShowSecurities: () => void;
  securitiesReturns: SecurityReturns[];
  showSecurities: boolean;
  startDate: string;
}

export const ReturnsChart: FC<Props> = ({
  accountNumberDisplay,
  accountReturns,
  accountReturnsPercentage,
  content,
  contentOptions,
  dataQa = 'returns',
  endDate,
  onToggleShowSecurities,
  securitiesReturns,
  showSecurities,
  startDate,
}) => {
  const {
    sfAccountPerformance: { styles: accountPerformanceStyles, typographyVariants, root: sfTableStyle },
    sfReturnsChart: { styles: returnsChartStyles },
  } = useTheme();
  const { open, openModal, onClose } = useModalState();

  const noSecuritiesToShow =
    !showSecurities || securitiesReturns.every(securityReturn => securityReturn.dailyChartData.length === 0);

  const performanceTableData = useMemo(() => {
    return fillMissingData(accountReturns).map((chartData, index) => {
      const formattedPercentage = formatPercentage(chartData.amount, {
        convertRatioToPercentage: false,
        decimals: 2,
        removeTrailingZeroes: false,
        locale: contentOptions.locale,
      });
      // NOTE: Using index below is safe because both Account & Security returns
      //  are filled with the same parameters in the hook to retrieve the data.
      //  See useGetAccountPerformanceData().
      return {
        date: chartData.date,
        dataPoints: noSecuritiesToShow
          ? [formattedPercentage]
          : [
              `${accountNumberDisplay}: ${formattedPercentage}`,
              ...securitiesReturns.map(securityReturn => {
                const formattedReturn = formatPercentage(securityReturn.dailyChartData[index].amount, {
                  convertRatioToPercentage: false,
                  decimals: 2,
                  removeTrailingZeroes: false,
                  locale: contentOptions.locale,
                });
                return `${securityReturn.securityName}: ${formattedReturn}`;
              }),
            ],
      };
    });
  }, [accountNumberDisplay, accountReturns, contentOptions.locale, noSecuritiesToShow, securitiesReturns]);

  return (
    <Grid
      columnGap={5}
      container
      data-qa={dataQa}
      id="returns"
      sx={{ ...accountPerformanceStyles.container, justifyContent: 'space-between' }}
    >
      <Grid item sm={3}>
        <Typography component="h2" variant={typographyVariants?.returnsHeading}>
          {content.labels.returns}
        </Typography>
        <Typography color="text.secondary" sx={{ mt: 1 }} variant={typographyVariants?.date}>
          {`${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`}
        </Typography>
        <TableContainer sx={{ mt: 2 }}>
          <Table sx={sfTableStyle}>
            <TableBody>
              <TableRow>
                <TableCell
                  align="left"
                  key="account-number"
                  sx={{ ...(noSecuritiesToShow && { borderBottom: 'none' }) }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FiberManualRecordIcon
                      fontSize="inherit"
                      sx={{ color: returnsChartStyles.colors?.default || 'primary.main', mx: 2 }}
                    />
                    <Typography color="text.secondary" variant="body1">
                      {accountNumberDisplay}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell
                  align="right"
                  key="account-returns"
                  sx={{ ...(noSecuritiesToShow && { borderBottom: 'none' }) }}
                >
                  <Typography color="text.primary" fontWeight={700} variant="subtitle1">
                    {accountReturnsPercentage}
                  </Typography>
                </TableCell>
              </TableRow>
              {showSecurities &&
                securitiesReturns
                  .filter(securityReturn => securityReturn.dailyChartData.length > 0)
                  .map((securityReturn, index, array) => {
                    const isLastItem = index === array.length - 1;
                    return (
                      <TableRow key={securityReturn.securityId}>
                        <TableCell
                          align="left"
                          key={`security-id-${index}`}
                          sx={{ ...(isLastItem && { borderBottom: 'none' }) }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FiberManualRecordIcon
                              fontSize="inherit"
                              sx={{
                                color: returnsChartStyles.colors?.[securityReturn.securityId] || 'primary.main',
                                mx: 2,
                              }}
                            />
                            <Typography color="text.secondary" variant="body1">
                              {formatSecurityName(securityReturn.securityName)}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          align="right"
                          key={`security-returns-${index}`}
                          sx={{ ...(isLastItem && { borderBottom: 'none' }) }}
                        >
                          <Typography color="text.primary" fontWeight={700} variant="subtitle1">
                            {formatPercentage(parseFloat(securityReturn.returnValue ?? ''), {
                              convertRatioToPercentage: false,
                              decimals: 2,
                              removeTrailingZeroes: false,
                              locale: contentOptions.locale,
                            })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              <TableRow>
                <TableCell align="left" colSpan={2} sx={{ borderBottom: 'none' }}>
                  <IconButton
                    aria-label="show-hide-indices"
                    onClick={() => onToggleShowSecurities()}
                    sx={{ ...returnsChartStyles.indices?.button }}
                  >
                    <Typography color={returnsChartStyles.indices?.text || 'text.secondary'} variant="body1">
                      {showSecurities ? content.labels.hideIndicesButton : content.labels.showIndicesButton}
                    </Typography>
                    {showSecurities ? (
                      <KeyboardArrowUpIcon
                        fontSize="inherit"
                        sx={{ color: returnsChartStyles.indices?.arrow || 'text.secondary' }}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        fontSize="inherit"
                        sx={{ color: returnsChartStyles.indices?.arrow || 'text.secondary' }}
                      />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {accountReturns.length > 0 && (
        <Grid item sm={8}>
          <Grid container sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={openModal} sx={{ mb: 3 }} variant="outlined">
              {content.labels.detailedTable} <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
            </Button>
          </Grid>
          <Chart
            accountReturns={accountReturns}
            contentOptions={contentOptions}
            height={200}
            securitiesReturns={securitiesReturns}
            showSecurities={showSecurities}
          />
          <PerformanceDetails
            contentOptions={contentOptions}
            onClose={onClose}
            open={open}
            performanceData={performanceTableData}
            type={PerformanceDetailsType.Returns}
          />
        </Grid>
      )}
    </Grid>
  );
};
