import React, { useState } from 'react';

import { Tab } from '~/components/ui/mui';
import { Tabs } from '~/components/ui/Tabs';
import { Typography } from '~/components/ui/Typography';

const a11yProps = (index: number) => ({
  id: `full-width-tab-${index}`,
  'aria-controls': `full-width-tabpanel-${index}`,
});

export const AssetAllocationTabs: React.FC<{
  content?: {
    tax_sheltered_allocation: string | null;
    taxable_allocation: string | null;
  } | null;
  dataQa: string | undefined;
  onChange: (newTabIndex: number) => void;
  value: number;
}> = props => {
  const [showIndicator, setShowIndicator] = useState(false);
  return (
    <Tabs
      TabIndicatorProps={{
        style: showIndicator
          ? {}
          : {
              display: 'none',
            },
      }}
      action={ref =>
        setTimeout(() => {
          /**
           * This is done for ticket DA2-2668, MUI calculates indicator length on initial font load
           * since we use custom font this creates a mismatch, indicator length varies, it has to be updated
           * after a certain amount of time to get the actual indicator.
           */
          setShowIndicator(true);
          ref?.updateIndicator();
        }, 100)
      }
      data-qa={`${props.dataQa}-tabs`}
      indicatorColor="primary"
      onChange={props.onChange}
      textColor="primary"
      value={props.value}
      variant="fullWidth"
    >
      <Tab
        data-qa={`${props.dataQa}-tab-taxable`}
        label={<Typography variant="subtitle2">{props.content?.taxable_allocation}</Typography>}
        {...a11yProps(0)}
      />
      <Tab
        data-qa={`${props.dataQa}-tab-tax-sheltered`}
        label={<Typography variant="subtitle2">{props.content?.tax_sheltered_allocation}</Typography>}
        {...a11yProps(1)}
      />
    </Tabs>
  );
};
