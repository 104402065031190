import { useEffect, useState } from 'react';

import { useGetPricingModalContent } from './contentstack';
import { useGetManagedProductPricing } from './symphony';
import { GetManagedProductPricing } from './symphony/__generated__/query.v2';

import { BillingRateTargetType, BillingRateType } from '~/__generated__';
import { ApolloQueryResult } from '~/utils/apollo-client';
import { ContentOptions } from '~/utils/contentstack';
import { localeToCurrencySymbol } from '~/utils/format';
import { BillingRate, BlendedRates, getBillingRates, getBlendedRates, getClientFee } from '~/utils/pricing';
import { AsyncResult } from '~/utils/types';

export type PricingSummaryModalDataContent = {
  advancedPricingContent: {
    discountUnit: string;
    header: string;
    helperTexts: {
      discountLowerBound: string;
      discountStep: string;
      discountUpperBound: string;
    };
    primaryCta: string;
    secondaryCta: string;
  };
  feeTiersContent: {
    assetLabel: string;
    currencySymbol: string;
    feeLabel: string;
    finalTierPrefix: string;
    finalTierSuffix: string;
    header: string;
    primaryCta: string;
  };
  iconUrl: string;
  modalContent: {
    header: string;
    primaryCta: string;
    secondaryCta: string;
  };
  pricingSummaryContent: {
    accountValueLabel: string;
    discountAppliedLabel: string;
    discountLabel: string;
    editDiscountLabel: string;
    feeLabel: string;
    feeTiersLabel: string;
  };
};
export interface PricingSummaryModalVariables {
  billableValue?: number;
  contentOptions: ContentOptions;
  fetchFinalizedBillingRate?: boolean;
  managedProductId: string;
  skip?: boolean;
}

export interface PricingSummaryModalDataResponse {
  billingRates: {
    defaultRates: BillingRate[];
    overriddenRates?: BillingRate[];
  };
  blendedRates: BlendedRates[];
  clientFee: number;
  content: PricingSummaryModalDataContent;
  rateType?: BillingRateType;
  refetchData?: () => Promise<ApolloQueryResult<GetManagedProductPricing>>;
  refetchingData: boolean;
}

export const usePricingSummaryModalData = ({
  billableValue,
  contentOptions,
  fetchFinalizedBillingRate,
  managedProductId,
  skip,
}: PricingSummaryModalVariables): AsyncResult<PricingSummaryModalDataResponse> => {
  const [state, setState] = useState<AsyncResult<PricingSummaryModalDataResponse>>({
    loading: !skip,
    error: undefined,
    data: undefined,
  });

  const { data: contentstackData, error: contentstackError, loading: contentstackLoading } = useGetPricingModalContent({
    variables: contentOptions,
    skip,
  });

  const {
    data: managedProductPricingData,
    error: managedProductPricingError,
    loading: managedProductPricingLoading,
    refetch: managedProductPricingRefetch,
  } = useGetManagedProductPricing({
    variables: {
      managedProductId,
      billableValue: billableValue?.toString(),
      fetchFinalizedBillingRate: !!fetchFinalizedBillingRate,
    },
    // network-only policy to allow for changes in previous steps to be reflected in the pricing data while preserving applyDiscount functionality
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip,
  });

  useEffect(() => {
    if (contentstackLoading || managedProductPricingLoading) {
      setState(prevState => {
        if (prevState.data) {
          return { ...prevState, data: { ...prevState.data, refetchingData: true } };
        } else {
          return prevState;
        }
      });
      return;
    }

    const error = contentstackError || managedProductPricingError;
    if (error) {
      setState({ error, loading: false });
      return;
    }

    const contentstackContent = contentstackData?.all_pricing_modal?.items?.[0];
    const billingData = managedProductPricingData?.managedProduct?.billingDataV2;
    const billingRateData = fetchFinalizedBillingRate
      ? billingData?.finalizedBillingRateData
      : billingData?.stagedBillingRateData;

    const billingRates = {
      defaultRates: getBillingRates(billingRateData?.defaultRates, contentstackContent?.fees) ?? [],
      overriddenRates: billingRateData?.overriddenRates?.length
        ? getBillingRates(billingRateData.overriddenRates, contentstackContent?.fees)
        : undefined,
    };

    const content: PricingSummaryModalDataContent = {
      advancedPricingContent: {
        discountUnit: contentstackContent?.advanced_settings?.discount_unit ?? '',
        header: contentstackContent?.advanced_settings?.header ?? '',
        helperTexts: {
          discountLowerBound:
            contentstackContent?.advanced_settings?.helper_texts?.find(x => x?.key === 'lowerBound')?.value ?? '',
          discountStep: contentstackContent?.advanced_settings?.helper_texts?.find(x => x?.key === 'step')?.value ?? '',
          discountUpperBound:
            contentstackContent?.advanced_settings?.helper_texts?.find(x => x?.key === 'upperBound')?.value ?? '',
        },
        primaryCta: contentstackContent?.advanced_settings?.cta?.primary ?? '',
        secondaryCta: contentstackContent?.advanced_settings?.cta?.secondary ?? '',
      },
      feeTiersContent: {
        assetLabel: contentstackContent?.fee_tiers?.asset_column_label ?? '',
        currencySymbol: localeToCurrencySymbol[contentOptions.locale],
        feeLabel: contentstackContent?.fee_tiers?.fee_column_label ?? '',
        finalTierPrefix: contentstackContent?.fee_tiers?.final_tier_prefix
          ? `${contentstackContent.fee_tiers.final_tier_prefix} `
          : '',
        finalTierSuffix: contentstackContent?.fee_tiers?.final_tier_suffix
          ? ` ${contentstackContent.fee_tiers.final_tier_suffix}`
          : '',
        header: contentstackContent?.fee_tiers?.header ?? '',
        primaryCta: contentstackContent?.fee_tiers?.primary_cta ?? '',
      },
      iconUrl: contentstackContent?.iconConnection?.edges?.[0]?.node?.url ?? '',
      modalContent: {
        header: contentstackContent?.modal_title ?? '',
        primaryCta: contentstackContent?.cta?.primary ?? '',
        secondaryCta: contentstackContent?.cta?.secondary ?? '',
      },
      pricingSummaryContent: {
        discountAppliedLabel: contentstackContent?.labels?.discount_applied ?? '',
        accountValueLabel: contentstackContent?.labels?.account_value ?? '',
        discountLabel: contentstackContent?.advanced_settings?.apply_discount ?? '',
        editDiscountLabel: contentstackContent?.advanced_settings?.edit_discount_label ?? '',
        feeLabel: contentstackContent?.labels?.estimated_annual_fee ?? '',
        feeTiersLabel: contentstackContent?.fee_tiers?.link ?? '',
      },
    };

    const blendedRates: BlendedRates[] =
      getBlendedRates(billingRateData?.blendedBillingRateComponent ?? [], contentstackContent?.fees) ?? [];

    const data: PricingSummaryModalDataResponse = {
      clientFee: getClientFee(billingRateData?.blendedBillingRateComponent),
      blendedRates,
      billingRates,
      content,
      rateType: blendedRates.find(item => item.configName === BillingRateTargetType.ADVISOR)?.rateType,
      refetchData: managedProductPricingRefetch,
      refetchingData: false,
    };

    setState({ data, loading: false });
  }, [
    contentOptions.locale,
    contentstackData,
    contentstackLoading,
    managedProductPricingLoading,
    contentstackError,
    managedProductPricingData?.managedProduct?.billingDataV2,
    managedProductPricingError,
    managedProductPricingRefetch,
    fetchFinalizedBillingRate,
  ]);

  return state;
};
