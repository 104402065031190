import React, { FC } from 'react';

import { usePaperworkManager } from './hooks/usePaperworkManager';
import { Section } from './Section';
import { PaperworkSection, Props } from './types';

import { PricingSummaryModalButton } from '~/components/modals/PricingSummary';
import { OnboardingPageCtas } from '~/components/Onboarding/OnboardingPageCtas';
import { Alert } from '~/components/ui/Alert';
import { Divider } from '~/components/ui/Divider';
import { Box, Checkbox, FormControl, FormControlLabel, Grid, Skeleton, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Snackbar } from '~/components/ui/Snackbar';
import { Typography } from '~/components/ui/Typography';
import { useCoreConfig } from '~/utils/config';
import { useIsMediumScreen } from '~/utils/responsiveness';
import { SfTheme } from '~/utils/theme';

const PaperworkV2: FC<Props> = ({
  contentOptions,
  dataQa = 'paperworkV2',
  paperworkConfigGetter,
  managedProductId,
  partyId,
  partyIdFA,
  onBack,
  onNext,
  onSaveExit,
  pricingSummaryModalProps,
}) => {
  const {
    sfPaperwork: { root, styles: style },
  } = useTheme<SfTheme>();

  const {
    prefillWealthDataFromQuestionnaire,
    prefillInvestDataFromQuestionnaire,
    showSaveButton,
    showTlhButtonIcon,
  } = useCoreConfig().components.sfPaperworkV2;

  const isMediumScreen = useIsMediumScreen();
  const sectionsRendered = new Set();

  const { data, loading, error } = usePaperworkManager({
    contentOptions,
    managedProductId,
    onBack,
    onNext,
    onSaveExit,
    partyId,
    partyIdFA,
    paperworkConfigGetter,
    prefillInvestDataFromQuestionnaire,
    prefillWealthDataFromQuestionnaire,
  });

  const CustomPage = data?.page?.customComponent;

  const renderSectionComponent = (section: PaperworkSection, index: number) => {
    if (!data || sectionsRendered.has(section.sectionKey)) {
      return null;
    }

    const page = data.page;
    const paperworkData = data.paperworkData;
    const formHooks = data.formHooks;
    const isFormSubmitted = data.isFormSubmitted;
    const isPaperworkSaving = data.isPaperworkSaving;
    const isPaperworkSubmitting = data.isPaperworkSubmitting;

    if (!page || !paperworkData) {
      return null;
    }

    const sectionsInColumn = [section];
    sectionsRendered.add(section.sectionKey);
    const numberOfSections = page.sections?.length ?? 1;
    if (section.adjacent) {
      const adjacentSection = page.sections?.find(innerSection => {
        return innerSection.sectionKey === section.adjacent;
      });
      if (adjacentSection) {
        sectionsRendered.add(adjacentSection.sectionKey);
        sectionsInColumn.push(adjacentSection);
      }
    }
    return (
      <Grid item key={index} xs={numberOfSections > 2 || numberOfSections === 1 ? 4 : 12 / numberOfSections}>
        <Box
          sx={{
            py: 1,
            px: isMediumScreen ? 1 : 4,
            height: '100%',
            borderRight:
              page.sections && index < page.sections.length - sectionsInColumn.length && !isMediumScreen
                ? '1px solid'
                : '',
            borderColor: style.borderColor,
          }}
        >
          {sectionsInColumn.map(sectionInColumn => {
            const sectionHeading =
              paperworkData.content.section?.find(
                s => s?.key === (sectionInColumn.headingKey ?? sectionInColumn.sectionKey),
              )?.title ?? undefined;
            const questionContent = paperworkData.content.questions.find(
              c => c.section_key === sectionInColumn.sectionKey,
            );

            const SectionComponent = section.customComponent ?? Section;
            if (!questionContent) {
              return (
                <Box data-qa={`cms-error-${sectionInColumn.sectionKey}`} key={index} sx={{ py: 2, px: 4 }}>
                  <Alert severity="error">
                    Question content not found in CMS for section: {sectionInColumn.sectionKey}
                  </Alert>
                </Box>
              );
            }

            return (
              <SectionComponent
                accountType={paperworkData.accountType}
                allPaperworkDataAvailableForPrefill={paperworkData.allPaperworkDataAvailableForPrefill}
                allSavedPaperworkData={paperworkData.allSavedPaperworkData ?? []}
                content={{
                  questions: questionContent,
                  validationMessages: paperworkData.content.validationMessages,
                  statesList: paperworkData.content.statesList,
                  stateZipCodeMap: paperworkData.content.stateZipCodeMap,
                  countriesList: paperworkData.content.countriesList,
                  minorAgesByState: paperworkData.content.minorAgesByState ?? [],
                }}
                contentOptions={contentOptions}
                dataQa={`${dataQa}-section`}
                dependentFormFieldsInvisibility={data.dependentFormFieldsInvisibility}
                disablePageEditing={false}
                faPartyAttributes={paperworkData.faPartyAttributes}
                faRepCodes={paperworkData.faRepCodes}
                formHooks={formHooks}
                harvestLosses={paperworkData.partyHarvestLosses}
                isFormSubmitted={isFormSubmitted}
                isPaperworkSaving={isPaperworkSaving}
                isPaperworkSubmitting={isPaperworkSubmitting}
                key={sectionInColumn.sectionKey}
                managedProductId={managedProductId}
                onFormFieldsVisibilityChange={data.onFormFieldsVisibilityChange}
                order={sectionInColumn.questions.order}
                partyId={partyId}
                partyRelationships={paperworkData.partyRelationships}
                questionnaireInvestWealthData={paperworkData.questionnaireInvestWealthData}
                registerCustomDeferredPromise={data.registerCustomDeferredPromise}
                sectionHeading={sectionHeading}
                sectionKey={sectionInColumn.sectionKey}
                sectionNumber={sectionInColumn.number}
                setUnmaskedValues={data.setUnmaskedValues}
                showTlhButtonIcon={showTlhButtonIcon}
                unmaskedValues={data.unmaskedValues}
                unregisterCustomDeferredPromise={data.unregisterCustomDeferredPromise}
              />
            );
          })}
        </Box>
      </Grid>
    );
  };

  return (
    <Box data-qa={dataQa} sx={{ py: 2, ...root }}>
      {loading && !data?.isPaperworkSaving ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : error ? (
        <Alert contentOptions={contentOptions} error={error} severity="error" />
      ) : CustomPage ? (
        <CustomPage
          managedProductId={managedProductId}
          onBack={data.handleBack}
          onNext={data.handleCustomPageComponentOnNext}
          partyId={partyId}
        />
      ) : (
        <>
          {data?.paperworkData?.content.imageUrl && (
            <Box sx={{ height: '100px', pb: 2, textAlign: 'center' }}>
              <img alt="" src={data.paperworkData.content.imageUrl} />
            </Box>
          )}
          <RteContent
            aria-live="polite"
            data={
              data?.paperworkData?.content.headings?.find(heading => heading?.key === data.page?.heading)?.title ?? ''
            }
            data-qa={`${dataQa}-title`}
            sx={{ color: `${style.headingColor}`, textAlign: 'center' }}
            tabIndex={-1}
          />
          <Box sx={{ pt: 3 }}>
            <Grid
              container
              direction={isMediumScreen ? 'column' : 'row'}
              flexWrap="nowrap"
              key={`page_${data?.page?.index}`}
            >
              {data?.page && data.page.sections
                ? data.page.sections
                    .sort((a, b) => (a.index > b.index ? 1 : -1))
                    .map((section, index) => renderSectionComponent(section, index))
                : null}
            </Grid>
          </Box>
          <Divider sx={{ pt: isMediumScreen ? 3 : 4, mx: isMediumScreen ? 0 : 4 }} />
          {data?.showPaperworkConsent && data.paperworkData?.content.consent?.consentText && (
            <Grid
              alignContent={isMediumScreen ? 'flex-start' : 'center'}
              container
              direction="column"
              minHeight="65px"
              pt={3}
            >
              <Grid item>
                {/* TODO - move checkbox with FormControl and label into a component */}
                <FormControl data-qa="consent-checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={data.defaultChecked}
                        id="consent-checkbox"
                        onChange={e => {
                          data.handleSetValidation(e.target.checked);
                        }}
                      />
                    }
                    label={<RteContent data={data.paperworkData.content.consent.consentText} />}
                  />
                </FormControl>
              </Grid>
              {!data.isValidationChecked && data.consentRequired && (
                <Grid item>
                  <Typography component="div" data-qa="consent-error-text" sx={{ color: 'error.main' }} variant="body2">
                    {data.paperworkData.content.consent.consentErrorText}
                  </Typography>
                </Grid>
              )}
              {data.showPaperworkCrs && data.paperworkData.content.crs?.crsText && (
                <>
                  <Grid item>
                    <FormControl data-qa="crs-checkbox">
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={data.crsDefaultChecked}
                            id="crs-checkbox"
                            onChange={e => {
                              data.handleSetCrsValidation(e.target.checked);
                            }}
                          />
                        }
                        label={<RteContent data={data.paperworkData.content.crs.crsText} />}
                      />
                    </FormControl>
                  </Grid>
                  {!data.isCrsValidationChecked && data.crsRequired && (
                    <Grid item>
                      <Typography
                        component="div"
                        data-qa="consent-error-text"
                        sx={{ color: 'error.main' }}
                        variant="body2"
                      >
                        {data.paperworkData.content.crs.crsError}
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )}
          <OnboardingPageCtas
            backButtonProps={{ disabled: data?.isRefetching, onClick: data?.handleBack }}
            content={{
              backCta: data?.paperworkData?.content.backLabel ?? '',
              nextCta: data?.paperworkData?.content.nextLabel ?? '',
              saveCta: data?.paperworkData?.content.saveLabel ?? '',
              saveExitData: data?.paperworkData?.content.saveAndExit?.description ?? '',
              saveExitLink: data?.paperworkData?.content.saveAndExit?.link ?? '',
            }}
            justifyContent="center"
            nextButtonProps={{
              disabled: data?.isPaperworkSaving || data?.isRefetching,
              loading: data?.isPaperworkSubmitting,
              onClick: data?.handleNext,
            }}
            pb={1}
            pt={2}
            saveButtonProps={{
              disabled: data?.isPaperworkSubmitting || data?.isRefetching,
              loading: data?.isPaperworkSaving,
              onClick: data?.handleSave,
            }}
            saveLinkProps={{
              onClick: e => {
                data?.handleSave(e, true);
              },
            }}
            showSaveButton={showSaveButton}
            showSaveLink={!!onSaveExit}
          />

          {data?.errorSaving && (
            <Box flexDirection="column" sx={{ pt: 2 }}>
              <Alert contentOptions={contentOptions} error={data.errorSaving} severity="error">
                There was an error saving the paperwork form.
              </Alert>
            </Box>
          )}
          {data?.paperworkData?.content.disclaimer && (
            <Typography
              align="center"
              component="div"
              data-qa={`${dataQa}-disclosure-footnote`}
              sx={{ py: 3 }}
              variant="caption"
            >
              {data.paperworkData.content.disclaimer}
            </Typography>
          )}
          {pricingSummaryModalProps && (
            <PricingSummaryModalButton
              contentOptions={contentOptions}
              managedProductId={managedProductId}
              pricingSummaryModalProps={pricingSummaryModalProps}
            />
          )}
        </>
      )}
      {data?.showSnackbar && (
        <Snackbar
          feedbackMessage={data.paperworkData?.content.saveSnackbarMessage ?? ''}
          onClose={() => {
            data.onHideSnackbar();
          }}
        />
      )}
    </Box>
  );
};
export default PaperworkV2;
