import { QuestionOrderSteps as QuestionOrderStep } from '../../../Section/types';
import {
  originalDepositorFirstName,
  originalDepositorLastName,
  originalDepositorMiddleName,
  originalDepositorSsn,
} from '../../questions/originalDepositor';
import { QuestionProperties } from '../../questions/types';

export const originalDepositorFirstNameNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return originalDepositorFirstName(
    {
      ...properties,
      additionalValidations: {
        pattern: new RegExp(/^[A-Za-z -]+$/),
        maxLength: 25,
        ...properties.additionalValidations,
      },
    },
    {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z -]/g),
    },
  );
};

export const originalDepositorLastNameNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return originalDepositorLastName(
    {
      ...properties,
      additionalValidations: {
        pattern: new RegExp(/^[a-zA-Z -]*$/),
        maxLength: 25,
        ...properties.additionalValidations,
      },
    },
    {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z -]/g),
    },
  );
};

export const originalDepositorMiddleNameNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return originalDepositorMiddleName(
    {
      ...properties,
      additionalValidations: {
        pattern: new RegExp(/^[a-zA-Z ]*$/),
        maxLength: 10,
        ...properties.additionalValidations,
      },
    },
    {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z ]/g),
    },
  );
};

export const originalDepositorSsnNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return originalDepositorSsn({
    ...properties,
  });
};
