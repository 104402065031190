import omit from 'lodash.omit';

import { isMasked } from '../configs/questions/utils';
import { getFieldName, splitFieldName } from '../Section/utils';
import { FormData, PaperworkPage, PaperworkPageConfig, Props } from '../types';

import { ManagedProductPaperworkWithFreeFormId, PaperworkInputWithFreeFormId } from './useGetPaperworkData';

import {
  AddressInput,
  BusinessEntityInput,
  BusinessEntityType,
  FinancialAccountType,
  IdentifierType,
  InvestmentInput,
  PaperworkType,
  PartyContactInput,
  PartyIdentifierInput,
  PartyInput,
  PartyPersonInput,
  PartyType,
  RegulatoryInformationInput,
  RelationshipInput,
  TrustIdentificationType,
  TrustProfileType,
} from '~/__generated__';
import {
  ManagedProductPaperwork,
  PaperworkInvestment,
  PaperworkParty,
  PaperworkPartyAddress,
  PaperworkPartyBusinessEntity,
  PaperworkPartyContact,
  PaperworkPartyIdentifier,
  PaperworkPartyPerson,
  PaperworkRegulatoryInformation,
  PaperworkRelationships,
  RelatedContactParty,
  TrustInformation,
} from '~/containers/Paperwork/symphony';
import { isCustodialAccountType, isIRAAccountType, isJointAccountType, isTrustAccountType } from '~/utils/account';

export const fetchPages = async (
  accountType: FinancialAccountType,
  allPaperworkData: ManagedProductPaperworkWithFreeFormId[] | null | undefined,
  paperworkConfigGetter: Props['paperworkConfigGetter'],
): Promise<PaperworkPageConfig> => {
  return await paperworkConfigGetter(accountType, allPaperworkData ?? []);
};

export const getPaperworkWithFreeFormId = (
  accountType: FinancialAccountType,
  allSavedPaperworkData: ManagedProductPaperwork[],
): ManagedProductPaperworkWithFreeFormId[] => {
  return allSavedPaperworkData.map(paperwork => {
    // TODO - remove this fallback once we shift permanently to paperworkV2
    let paperworkFreeFormIdFallback = 'unknown';
    if (isIRAAccountType(accountType) || accountType === FinancialAccountType.INDIVIDUAL) {
      paperworkFreeFormIdFallback = 'individual';
    } else if (isJointAccountType(accountType)) {
      paperworkFreeFormIdFallback = paperwork.paperworkType === PaperworkType.PRIMARY ? 'individual' : 'joint';
    } else if (isTrustAccountType(accountType) && paperwork.trustInformation?.trustProfileType) {
      paperworkFreeFormIdFallback =
        paperwork.trustInformation.trustProfileType === TrustProfileType.TRUST
          ? 'trust'
          : paperwork.trustInformation.trustProfileType === TrustProfileType.TRUSTEE
          ? 'trustee'
          : 'grantor';
    } else if (isCustodialAccountType(accountType)) {
      paperworkFreeFormIdFallback = paperwork.paperworkType === PaperworkType.PRIMARY ? 'minor' : 'custodian';
    }

    return {
      ...paperwork,
      paperworkFreeFormId: paperwork.paperworkFreeFormId ?? paperworkFreeFormIdFallback,
    };
  });
};

interface MapResultToSymphonyPaperworkInputsVariables {
  accountType: FinancialAccountType;
  allSavedPaperworkData: ManagedProductPaperworkWithFreeFormId[];
  crsRequired?: boolean;
  dependentFormFieldsInvisibility: Record<string, boolean>;
  formValues: FormData;
  isCrsValidationChecked?: boolean;
  managedProductId: string;
  page: PaperworkPage;
  paperworkFreeFormIdToProfileType: Record<string, PaperworkType>;
  paperworkFreeFormIdToTrustProfileType: Record<string, TrustProfileType>;
  partyId: string;
  unmaskedValues: Record<string, string>;
}
export const mapResultToSymphonyPaperworkInputs = ({
  partyId,
  managedProductId,
  accountType,
  allSavedPaperworkData,
  page,
  paperworkFreeFormIdToProfileType,
  paperworkFreeFormIdToTrustProfileType,
  formValues,
  dependentFormFieldsInvisibility,
  unmaskedValues,
  crsRequired,
  isCrsValidationChecked,
}: MapResultToSymphonyPaperworkInputsVariables): PaperworkInputWithFreeFormId[] => {
  const isTrustAccount = isTrustAccountType(accountType);
  const formFieldsByPaperworkFreeFormId = Object.entries(formValues).reduce<Record<string, FormData>>((acc, curr) => {
    const { dataPointKey, paperworkFreeFormId = '' } = splitFieldName(curr[0]);
    if (acc[paperworkFreeFormId]) {
      acc[paperworkFreeFormId][dataPointKey] = curr[1];
    } else {
      acc[paperworkFreeFormId] = { [dataPointKey]: curr[1] };
    }
    return acc;
  }, {});

  const allPaperworkFormIdsBeingSaved = Object.keys(formFieldsByPaperworkFreeFormId);
  return allPaperworkFormIdsBeingSaved.map(paperworkFreeFormId => {
    const currentlySavedPaperworkData = allSavedPaperworkData.find(p => p.paperworkFreeFormId === paperworkFreeFormId);
    const profileType =
      currentlySavedPaperworkData?.paperworkType ??
      paperworkFreeFormIdToProfileType[paperworkFreeFormId] ??
      PaperworkType.UNKNOWN;
    const trustProfileType = paperworkFreeFormIdToTrustProfileType[paperworkFreeFormId];
    const paperworkInput: PaperworkInputWithFreeFormId = {
      paperworkFreeFormId,
      id: currentlySavedPaperworkData?.id ?? null,
      managedId: managedProductId,
      paperworkType: profileType,
      party: initialisePartyObject(partyId, currentlySavedPaperworkData?.party),
      investment: initialiseInvestmentObject(currentlySavedPaperworkData?.investment),
      relationships:
        currentlySavedPaperworkData?.relationships
          ?.filter((value): value is PaperworkRelationships => !!value)
          .map(initialiseRelationshipObject) ?? [],
      regulatoryInformation: initialiseRegulatoryObject(currentlySavedPaperworkData?.regulatoryInformation),
      trustInformation: isTrustAccount
        ? currentlySavedPaperworkData?.trustInformation
          ? omit(currentlySavedPaperworkData.trustInformation, '__typename')
          : { trustProfileType }
        : undefined,
      wealthInformation: currentlySavedPaperworkData?.wealthInformation
        ? omit(currentlySavedPaperworkData.wealthInformation, '__typename')
        : {},
      isHomeAddressDerivedFromPrimary: !!currentlySavedPaperworkData?.isHomeAddressDerivedFromPrimary,
      isMailingAddressDerivedFromPrimary: !!currentlySavedPaperworkData?.isMailingAddressDerivedFromPrimary,
      isMailingAddressSameAsHomeAddress: currentlySavedPaperworkData?.isMailingAddressSameAsHomeAddress,
      additionalAttributes: currentlySavedPaperworkData?.additionalAttributes
        ? {
            ...omit(currentlySavedPaperworkData.additionalAttributes, '__typename'),
            isFormCrsReviewed: crsRequired ? isCrsValidationChecked : null,
          }
        : {},
      isProxyVotingEnabled: currentlySavedPaperworkData?.isProxyVotingEnabled,
      tradeConfirmationFrequency: currentlySavedPaperworkData?.tradeConfirmationFrequency,
    };

    page.sections?.forEach(section => {
      section.questions.order.orderSteps
        .filter(question => {
          return !dependentFormFieldsInvisibility[getFieldName(question.dataPointKey, question.paperworkFreeFormId)];
        })
        .forEach(question => {
          question.paperworkInputSetter?.(paperworkInput, {
            allSavedPaperworkData,
            dependentFormFieldsInvisibility,
            formValues,
            unmaskedValues,
            savedPaperworkData: currentlySavedPaperworkData,
          });
        });
    });

    return paperworkInput;
  });
};

const initialiseRelationshipObject = (relationship: PaperworkRelationships): RelationshipInput => {
  return {
    ...omit(relationship, ['__typename']),
    party: initialisePartyObject(null, {
      partyCompany: null,
      partyType: null,
      ...relationship.party,
      partyPerson: relationship.party.partyPerson
        ? {
            employmentStatus: null,
            maritalStatus: null,
            occupation: null,
            language: null,
            ...relationship.party.partyPerson,
          }
        : null,
      partyBusinessEntity: relationship.party.partyBusinessEntity
        ? {
            entityType: null,
            state: null,
            ...relationship.party.partyBusinessEntity,
            attributes: relationship.party.partyBusinessEntity.attributes
              ? {
                  accountHolderType: null,
                  countryOfTaxation: null,
                  organizationDocumentName: null,
                  resolutionDateAdopted: null,
                  taxation: null,
                  ...relationship.party.partyBusinessEntity.attributes,
                }
              : null,
          }
        : null,
      identifiers:
        relationship.party.identifiers?.map(id => {
          return {
            ...id,
            identifierCountry: null,
            identifierJurisdiction: null,
            identifierIssuance: null,
            identifierExpiry: null,
            // if the value is already masked, send it as null
            identifierValue: isMasked(id.identifierValue) ? null : id.identifierValue,
          };
        }) ?? null,
    }),
  };
};

const initialiseInvestmentObject = (investment?: PaperworkInvestment | null): InvestmentInput => {
  if (!investment) {
    return {};
  }

  return {
    ...omit(investment, ['__typename', 'experienceDetail']),
    experienceDetail: investment.experienceDetail
      ? {
          alternativeInvestments: omit(investment.experienceDetail.alternativeInvestments, '__typename'),
          annuitiesAndLifeInsurance: omit(investment.experienceDetail.annuitiesAndLifeInsurance, '__typename'),
          bonds: omit(investment.experienceDetail.bonds, '__typename'),
          foreignCurrency: omit(investment.experienceDetail.foreignCurrency, '__typename'),
          foreignSecurity: omit(investment.experienceDetail.foreignSecurity, '__typename'),
          lifeInsurance: omit(investment.experienceDetail.lifeInsurance, '__typename'),
          limitedPartnerships: omit(investment.experienceDetail.limitedPartnerships, '__typename'),
          mutualFunds: omit(investment.experienceDetail.mutualFunds, '__typename'),
          options: omit(investment.experienceDetail.options, '__typename'),
          other: omit(investment.experienceDetail.other, '__typename'),
          otherAssetClass: investment.experienceDetail.otherAssetClass,
          shortTerms: omit(investment.experienceDetail.shortTerms, '__typename'),
          securityFutures: omit(investment.experienceDetail.securityFutures, '__typename'),
          stocks: omit(investment.experienceDetail.stocks, '__typename'),
          total: omit(investment.experienceDetail.total, '__typename'),
          variableContracts: omit(investment.experienceDetail.variableContracts, '__typename'),
        }
      : null,
  };
};

const initialisePartyObject = (partyId: string | null, party?: PaperworkParty | null): PartyInput => {
  return {
    id: partyId,
    partyPerson: party?.partyPerson ? initialisePartyPersonObject(party.partyPerson) : {},
    partyBusinessEntity: party?.partyBusinessEntity
      ? initialisePartyBusinessEntityObject(party.partyBusinessEntity)
      : {},
    partyCompany: party?.partyCompany ? omit(party.partyCompany, '__typename') : {},
    partyContacts: party?.partyContacts?.map(initialisePartyContactObject) ?? [],
    addresses: party?.addresses?.map(initialisePartyAddressObject) ?? [],
    identifiers: party?.identifiers?.map(initialisePartyIdentifierObject) ?? [],
    relationships: [],
  };
};

const initialisePartyPersonObject = (partyPerson: PaperworkPartyPerson): PartyPersonInput => {
  return {
    ...omit(partyPerson, '__typename'),
    language: partyPerson.language ? omit(partyPerson.language, '__typename') : null,
  };
};

const initialisePartyBusinessEntityObject = (
  partyBusinessEntity: PaperworkPartyBusinessEntity,
): BusinessEntityInput => {
  return {
    ...omit(partyBusinessEntity, '__typename', 'entityType'),
    attributes: partyBusinessEntity.attributes ? omit(partyBusinessEntity.attributes, '__typename') : null,
  };
};

const initialisePartyContactObject = (partyContact: PaperworkPartyContact): PartyContactInput => {
  return omit(partyContact, '__typename', 'isPrimary');
};

const initialisePartyIdentifierObject = (identifier: PaperworkPartyIdentifier): PartyIdentifierInput => {
  // if the value is already masked, send it as null
  return {
    ...omit(identifier, '__typename'),
    identifierValue: isMasked(identifier.identifierValue) ? null : identifier.identifierValue,
  };
};

const initialiseRegulatoryObject = (
  regulatoryInformation?: PaperworkRegulatoryInformation | null,
): RegulatoryInformationInput => {
  return regulatoryInformation
    ? {
        ...omit(regulatoryInformation, '__typename', 'exchangeAddress'),
        exchangeAddress: regulatoryInformation.exchangeAddress
          ? initialisePartyAddressObject(regulatoryInformation.exchangeAddress)
          : null,
      }
    : {};
};

const initialisePartyAddressObject = (address: PaperworkPartyAddress): AddressInput => {
  return omit(address, '__typename');
};

export const intialisePaperworkDataFromRelatedContact = (party: RelatedContactParty): ManagedProductPaperwork => {
  const partyPerson = omit(party.partyPerson, 'investmentInformation');
  const partyBusinessEntity = omit(party.partyBusinessEntity, 'investmentInformation');
  const trustInformation =
    partyBusinessEntity.entityType === BusinessEntityType.TRUST ? getTrustInformationFromRelatedContact(party) : null;
  const { investment, wealthInformation } = getInvestmentInformationFromRelatedContact(party);
  return {
    __typename: 'Paperwork',
    id: null,
    paperworkFreeFormId: null,
    paperworkType: PaperworkType.SECONDARY, // Dummy this is not used in paperwork while saving
    isMailingAddressSameAsHomeAddress: true,
    isHomeAddressDerivedFromPrimary: false,
    isMailingAddressDerivedFromPrimary: false,
    isUpdatingBeneficiariesAllowed: null,
    party: { ...party, partyPerson, partyBusinessEntity },
    trustInformation,
    wealthInformation,
    relationships: null,
    regulatoryInformation: null,
    investment,
    tradeConfirmationFrequency: null,
    isProxyVotingEnabled: null,
    additionalAttributes: null,
  };
};

const getInvestmentInformationFromRelatedContact = (party: RelatedContactParty) => ({
  investment:
    party.partyPerson?.investmentInformation?.investment ??
    party.partyBusinessEntity?.investmentInformation?.investment ??
    null,
  wealthInformation:
    party.partyPerson?.investmentInformation?.wealthInformation ??
    party.partyBusinessEntity?.investmentInformation?.wealthInformation ??
    null,
});

const getTrustInformationFromRelatedContact = (party: RelatedContactParty): TrustInformation => {
  const identifier =
    party.identifiers?.find(id => IdentifierType.SSN === id.type) ??
    party.identifiers?.find(id => IdentifierType.TIN === id.type);
  return {
    __typename: 'TrustInformation',
    isTrusteeGrantor: null,
    canTrusteesModifyTrust: null,
    eachTrusteeAction: null,
    numberOfTrustees: null,
    otherSettlorTrustorOrGrantor: null,
    otherTrusteeAction: null,
    revokerFirstName: null,
    revokerLastName: null,
    revokerMiddleName: null,
    settlorTrustorOrGrantor: null,
    trustAgreementDate: party.partyBusinessEntity?.attributes?.trustDate ?? '',
    trustGoverningState: party.partyBusinessEntity?.state ?? '',
    trustIdentificationNumber: identifier?.identifierValue ?? '',
    trustIdentificationType: identifier
      ? identifier.type === IdentifierType.SSN
        ? TrustIdentificationType.SSN
        : TrustIdentificationType.TIN
      : null,
    trustName: party.partyBusinessEntity?.name ?? '',
    trustProfileType: party.partyType === PartyType.BUSINESS_ENTITY ? TrustProfileType.TRUST : TrustProfileType.TRUSTEE,
    trustType: null,
    whoCanModifyTrust: null,
  };
};
