import React from 'react';

import { HomeOfficeColumnKey, HomeOfficeTableData } from '../types';

import { DEFAULT_DISPLAY_VALUE } from './utils';

import { TableData } from '~/components/ui/BasicTable';
import { NorthEastIcon, SouthWestIcon, Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { LabeledValueField } from '~/containers/OpsDashboard/common/LabeledValueField';
import { precise } from '~/utils/format/currency';

const advisorNameColumnValue = (item: HomeOfficeTableData, onAdvisorClick: (partyId: string) => void) => (
  <LabeledValueField
    label={item.advisor.repCode}
    onClick={() => onAdvisorClick(item.advisor.id)}
    value={item.advisor.name}
  />
);

const contactInformationColumnValue = (item: HomeOfficeTableData) => {
  if (item.contactData.phoneNumber || item.contactData.email) {
    return <LabeledValueField label={item.contactData.email} value={item.contactData.phoneNumber} />;
  }
  return <>{DEFAULT_DISPLAY_VALUE}</>;
};

/**
 * Returns a JSX element displaying a metric value and its change over a period.
 *
 * The metric value is displayed as a Typography component.
 * If `showChange` is true, the change in the metric value over the period is displayed next to the metric value.
 * The change is displayed with an icon indicating whether the
 * change is positive (NorthEastIcon) or negative (SouthWestIcon), and the color of the change text is set accordingly.
 *
 * @param {object} params - The parameters for the function.
 * @param {string} params.changeInMetricValue - The change in the metric value over the period.
 * @param {boolean} params.isChangePositive - Whether the change in the metric value is positive.
 * @param {boolean} params.isPartnerMetric - Whether the metric is a partner metric.
 * This affects the size of the Typography components.
 * @param {string} params.metricValue - The metric value.
 * @param {boolean} params.showChange - Whether to show the change in the metric value.
 */
export const getMetricValueWithChangeInPeriod = ({
  isPartnerMetric,
  isChangePositive,
  metricValue,
  changeInMetricValue,
  showChange,
}: {
  changeInMetricValue?: string;
  isChangePositive?: boolean;
  isPartnerMetric: boolean;
  metricValue: string;
  showChange?: boolean;
}) => {
  // The change icon is shown iff changeInMetricValue is not $0.00 (value returned by precise(0)) and is defined.
  const showChangeIcon = !!changeInMetricValue && changeInMetricValue !== precise(0);

  // The color of change in metric value text is set based on whether the change is positive or negative or ZERO.
  let changeInMetricValueColor = 'inherit';
  if (showChangeIcon) {
    changeInMetricValueColor = isChangePositive ? 'success.main' : 'error.main';
  }

  return (
    <>
      <Typography variant={isPartnerMetric ? 'h2' : 'body1'}>{metricValue}</Typography>
      {showChange && metricValue !== DEFAULT_DISPLAY_VALUE && (
        <Stack direction="row">
          {showChangeIcon &&
            (isChangePositive ? (
              <NorthEastIcon
                aria-hidden="false"
                aria-label={`Positive ${changeInMetricValue}`}
                color="success"
                fontSize={isPartnerMetric ? 'medium' : 'small'}
                height="1"
                role="img"
                width="1"
              />
            ) : (
              <SouthWestIcon
                aria-hidden="false"
                aria-label={`Negative ${changeInMetricValue}`}
                color="error"
                height="1"
                role="img"
                width="1"
              />
            ))}
          <Typography aria-hidden="true" color={changeInMetricValueColor} variant={isPartnerMetric ? 'body1' : 'body2'}>
            {changeInMetricValue ?? DEFAULT_DISPLAY_VALUE}
          </Typography>
        </Stack>
      )}
    </>
  );
};

export const getFormattedItems = ({
  items,
  keys,
  onAdvisorClick,
}: {
  items?: HomeOfficeTableData[];
  keys: HomeOfficeColumnKey[];
  onAdvisorClick: (partyId: string) => void;
}): TableData[] => {
  return (items ?? []).map(item => {
    const rowData = keys.reduce(
      (result: Partial<Record<HomeOfficeColumnKey, string | number | React.ReactNode>>, key) => {
        let value;
        switch (key) {
          case 'advisorName':
            value = advisorNameColumnValue(item, onAdvisorClick);
            break;
          case 'contactInformation':
            value = contactInformationColumnValue(item);
            break;
          case 'accountsClosedAsOfDate':
            value = item.accountsData.closedAsOfDate ?? DEFAULT_DISPLAY_VALUE;
            break;
          case 'accountsActiveAsOfDate':
            value = item.accountsData.activeAsOfDate ?? DEFAULT_DISPLAY_VALUE;
            break;
          case 'accountsInProgressAsOfDate':
            value = item.accountsData.inProgressAsOfDate ?? DEFAULT_DISPLAY_VALUE;
            break;
          case 'aumAsOfDate':
            value = item.aum.value;
            break;
          case 'accountsClosedInPeriod':
            value = item.accountsData.closedInPeriod ?? DEFAULT_DISPLAY_VALUE;
            break;
          case 'accountsOpenedInPeriod':
            value = item.accountsData.openedInPeriod ?? DEFAULT_DISPLAY_VALUE;
            break;
          case 'aumForPeriod':
            value = getMetricValueWithChangeInPeriod({
              isPartnerMetric: false,
              isChangePositive: item.aum.isPositive,
              metricValue: item.aum.value,
              changeInMetricValue: item.aum.change,
              showChange: true,
            });
            break;
          default:
            value = '';
            break;
        }
        result[key] = value;
        return result;
      },
      {},
    );

    return {
      ...item,
      rowKey: item.id,
      ...rowData,
    };
  });
};
