import { useEffect, useState } from 'react';

import { useGetDocusignDAProContent } from '../contentstack';

import { ContentOptions, getImageAssetUrl } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface DocusignContentVariables {
  contentOptions: ContentOptions;
}

export interface DocumentMapping {
  key: string;
  value: string;
}

export interface DocusignContentData {
  faDocusign: {
    heading: string;
    helperHeading: string;
    helperSubHeading: string;
    imageConnection: string;
    requiredDocumentsCta: string;
    subHeading: string;
  };
  generatedDocusign: {
    disclosure: string;
    documentMapping: DocumentMapping[];
    heading: string;
    imageConnection: string;
    primaryButtonCta: string;
  };
  pendingClientDocusign: {
    clientText: string;
    clientTextConjunction: string;
    disclaimerText: string;
    heading: string;
    imageConnection: string;
    infoText: string;
    pendingAlertCta: string;
    pendingAlertMessage: string;
  };
}

export const useDocusignDAProContent = ({
  contentOptions,
}: DocusignContentVariables): AsyncResult<DocusignContentData> => {
  const [state, setState] = useState<AsyncResult<DocusignContentData>>({
    loading: true,
  });

  const {
    data: docusignProContentData,
    loading: docusignProContentLoading,
    error: docusignProContentError,
  } = useGetDocusignDAProContent({
    variables: contentOptions,
  });

  useEffect(() => {
    if (docusignProContentLoading) {
      return;
    }
    if (docusignProContentError) {
      setState({ loading: false, error: docusignProContentError });
      return;
    }

    const content = docusignProContentData?.all_docusign?.items?.[0];

    setState({
      loading: false,
      data: {
        faDocusign: {
          heading: content?.fa_docusign?.heading ?? '',
          helperHeading: content?.fa_docusign?.helper_heading ?? '',
          helperSubHeading: content?.fa_docusign?.helper_sub_heading ?? '',
          requiredDocumentsCta: content?.fa_docusign?.required_documents_cta ?? '',
          imageConnection: getImageAssetUrl(content?.fa_docusign?.imageConnection),
          subHeading: content?.fa_docusign?.sub_heading ?? '',
        },
        generatedDocusign: {
          disclosure: content?.generated_docusign?.disclosure ?? '',
          documentMapping: content?.generated_docusign?.document_mapping.value ?? [],
          heading: content?.generated_docusign?.heading_redactor ?? '',
          imageConnection: getImageAssetUrl(content?.generated_docusign?.imageConnection),
          primaryButtonCta: content?.generated_docusign?.primary_button_cta ?? '',
        },
        pendingClientDocusign: {
          clientText: content?.pending_client_docusign?.client_text ?? '',
          clientTextConjunction: content?.pending_client_docusign?.client_text_conjunction ?? '',
          disclaimerText: content?.pending_client_docusign?.disclaimer_text ?? '',
          heading: content?.pending_client_docusign?.heading ?? '',
          imageConnection: getImageAssetUrl(content?.pending_client_docusign?.imageConnection),
          infoText: content?.pending_client_docusign?.info_text ?? '',
          pendingAlertCta: content?.pending_client_docusign?.pending_alert_cta ?? '',
          pendingAlertMessage: content?.pending_client_docusign?.pending_alert_message ?? '',
        },
      },
    });
  }, [docusignProContentData, docusignProContentLoading, docusignProContentError]);

  return state;
};
