import { QuestionKeys } from '../../questions/types';
import { PaperworkDataQuestionConfig } from '../../types';

export const sectionKey = 'wealth_expenses';

export const questions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.TAX_BRACKET },
  { questionOrSetKey: QuestionKeys.ANNUAL_HOUSEHOLD_INCOME },
  { questionOrSetKey: QuestionKeys.ANNUAL_RECURRING_EXPENSES },
  { questionOrSetKey: QuestionKeys.LIQUID_NET_WORTH },
  { questionOrSetKey: QuestionKeys.ESTIMATED_NET_WORTH },
];
