import { QuestionKeys } from '../../questions/types';
import { PaperworkDataQuestionConfig } from '../../types';

export const originalDepositorSectionKey = 'original_depositor';
export const originalDepositorQuestions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.ORIGINAL_DEPOSITOR_TEXT },
  { questionOrSetKey: QuestionKeys.ORIGINAL_DEPOSITOR_FIRST_NAME },
  { properties: { optional: true }, questionOrSetKey: QuestionKeys.ORIGINAL_DEPOSITOR_MIDDLE_NAME },
  { questionOrSetKey: QuestionKeys.ORIGINAL_DEPOSITOR_LAST_NAME },
  {
    properties: { adjacent: QuestionKeys.ORIGINAL_DEPOSITOR_SSN },
    questionOrSetKey: QuestionKeys.ORIGINAL_DEPOSITOR_RELATIONSHIP_TYPE,
  },
  { questionOrSetKey: QuestionKeys.ORIGINAL_DEPOSITOR_SSN },
  {
    properties: { adjacent: QuestionKeys.ORIGINAL_DEPOSITOR_DEATH_DATE },
    questionOrSetKey: QuestionKeys.ORIGINAL_DEPOSITOR_BIRTH_DATE,
  },
  { questionOrSetKey: QuestionKeys.ORIGINAL_DEPOSITOR_DEATH_DATE },
];
