import { QuestionKeys } from '~/containers/Paperwork/configs/questions/types';

export const completeAssetsHeldAwayMapping = [
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_STOCKS,
    dataPointKey: 'data_point:stocks:number',
    symphonyMapping: 'investment.experienceDetail.stocks.qualified',
  },
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_BONDS,
    dataPointKey: 'data_point:bonds:number',
    symphonyMapping: 'investment.experienceDetail.bonds.qualified',
  },
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_SHORT_TERMS,
    dataPointKey: 'data_point:shortTerms:number',
    symphonyMapping: 'investment.experienceDetail.shortTerms.qualified',
  },
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_MUTUAL_FUNDS,
    dataPointKey: 'data_point:mutualFunds:number',
    symphonyMapping: 'investment.experienceDetail.mutualFunds.qualified',
  },
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_OPTIONS,
    dataPointKey: 'data_point:options:number',
    symphonyMapping: 'investment.experienceDetail.options.qualified',
  },
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_LIMITED_PARTNERSHIPS,
    dataPointKey: 'data_point:limitedPartnerships:number',
    symphonyMapping: 'investment.experienceDetail.limitedPartnerships.qualified',
  },
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_LIFE_INSURANCE,
    dataPointKey: 'data_point:lifeInsurance:number',
    symphonyMapping: 'investment.experienceDetail.lifeInsurance.qualified',
  },
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_VARIABLE_CONTRACTS,
    dataPointKey: 'data_point:variableContracts:number',
    symphonyMapping: 'investment.experienceDetail.variableContracts.qualified',
  },
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_SECURITY_FUTURES,
    dataPointKey: 'data_point:securityFutures:number',
    symphonyMapping: 'investment.experienceDetail.securityFutures.qualified',
  },
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_ANNUITIES_AND_LIFE_INSURANCE,
    dataPointKey: 'data_point:annuitiesAndLifeInsurance:number',
    symphonyMapping: 'investment.experienceDetail.annuitiesAndLifeInsurance.qualified',
  },
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_ALTERNATIVE_INVESTMENTS,
    dataPointKey: 'data_point:alternativeInvestments:number',
    symphonyMapping: 'investment.experienceDetail.alternativeInvestments.qualified',
  },
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_FOREIGN_CURRENCY,
    dataPointKey: 'data_point:foreignCurrency:number',
    symphonyMapping: 'investment.experienceDetail.foreignCurrency.qualified',
  },
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_FOREIGN_SECURITY,
    dataPointKey: 'data_point:foreignSecurity:number',
    symphonyMapping: 'investment.experienceDetail.foreignSecurity.qualified',
  },
  {
    questionKey: QuestionKeys.ASSETS_HELD_AWAY_OTHER,
    dataPointKey: 'data_point:other:string',
    symphonyMapping: 'investment.experienceDetail.other.qualified',
  },
];
