import {
  GetAdvisorMetricValues,
  GetAdvisorMetricValuesVariables,
  GetFinancialAdvisorPartyByRepCode,
  GetFinancialAdvisorPartyByRepCodeVariables,
  GetPartnerMetricValues,
  GetPartnerMetricValuesVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { LazyQueryHookOptions, QueryHookOptions, QueryResult, QueryTuple } from '~/utils/apollo-client';
import { useLazySymphonyQuery, useSymphonyQuery } from '~/utils/symphony';

export const useGetFinancialAdvisorPartyByRepCode = (
  options?: QueryHookOptions<GetFinancialAdvisorPartyByRepCode, GetFinancialAdvisorPartyByRepCodeVariables>,
): QueryResult<GetFinancialAdvisorPartyByRepCode, GetFinancialAdvisorPartyByRepCodeVariables> =>
  useSymphonyQuery(queries.GetFinancialAdvisorPartyByRepCode, options);

export const useGetPartnerMetricValues = (
  options?: QueryHookOptions<GetPartnerMetricValues, GetPartnerMetricValuesVariables>,
): QueryResult<GetPartnerMetricValues, GetPartnerMetricValuesVariables> => {
  return useSymphonyQuery(queries.GetPartnerMetricValues, options);
};

export const useGetAdvisorMetricValues = (
  options?: QueryHookOptions<GetAdvisorMetricValues, GetAdvisorMetricValuesVariables>,
): QueryResult<GetAdvisorMetricValues, GetAdvisorMetricValuesVariables> => {
  return useSymphonyQuery(queries.GetAdvisorMetricValues, options);
};

export const useLazyGetAdvisorMetricValues = (
  options?: LazyQueryHookOptions<GetAdvisorMetricValues, GetAdvisorMetricValuesVariables>,
): QueryTuple<GetAdvisorMetricValues, GetAdvisorMetricValuesVariables> => {
  return useLazySymphonyQuery(queries.GetAdvisorMetricValues, options);
};
