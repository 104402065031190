import { QuestionOrderSteps as QuestionOrderStep } from '../../../Section/types';
import { confirmEmail, email, phoneNumber } from '../../questions/contact';
import { QuestionProperties } from '../../questions/types';

export const confirmEmailFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return confirmEmail({
    ...properties,
    additionalValidations: {
      maxLength: 80,
      ...properties.additionalValidations,
    },
  });
};

export const emailFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return email({
    ...properties,
    additionalValidations: {
      maxLength: 80,
      ...properties.additionalValidations,
    },
  });
};

export const phoneNumberFidelityMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return phoneNumber({
    ...properties,
    additionalValidations: {
      maxLength: 10,
      minLength: 10,
      ...properties.additionalValidations,
    },
  });
};
