import { useCallback, useMemo, useState } from 'react';

import { useGetHomeOfficeDashboardContent } from '../contentstack';
import { defaultTimeFrameValues } from '../TimeFrameFilters/utils';
import { HomeOfficeData, HomeOfficeTimeFrameValues, SearchOption } from '../types';

import { getFormattedItems } from './getFormattedItems';
import { useGetHomeOfficeMetricsData } from './useGetHomeOfficeMetricsData';
import {
  DEFAULT_DISPLAY_VALUE,
  DEFAULT_SORT_CONFIG,
  getFilterOptionsConfig,
  getHomeOfficeContent,
  getPartnerMetricRteAttributes,
  isDateView,
} from './utils';

import { OrderType } from '~/__generated__';
import { FilterConfig, SelectedFilters } from '~/components/Filters/types';
import { getSelectedRange } from '~/components/Filters/utils';
import { SortConfig } from '~/components/ui/BasicTable';
import { NumberRange } from '~/components/ui/NumberFormatRange';
import { useDownloadFinancialAdvisorData } from '~/containers/HomeOfficeDashboard/hooks/useDownloadFinancialAdvisorData';
import { useSearchFinancialAdvisor } from '~/containers/HomeOfficeDashboard/hooks/useSearchFinancialAdvisor';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface Props {
  contentOptions: ContentOptions;
  onAdvisorClick: (faPartyId: string) => void;
  viewerPartyId: string;
}

export const useGetHomeOfficeData = ({
  contentOptions,
  onAdvisorClick,
  viewerPartyId,
}: Props): AsyncResult<HomeOfficeData> => {
  const {
    components: {
      sfHomeOffice: { dateView, periodView },
    },
  } = useCoreConfig();

  const [view, setView] = useState(dateView);
  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState<SortConfig>(DEFAULT_SORT_CONFIG);

  const [appliedFilters, setAppliedFilters] = useState<SelectedFilters>({});
  const [filtersPopupOpen, setFiltersPopupOpen] = useState(false);
  const [timeFrameValues, setTimeFrameValues] = useState<HomeOfficeTimeFrameValues>(defaultTimeFrameValues);

  const onSort = useCallback(
    (field: string) => () => {
      const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
      setSortConfig({ order: toggleOrder, field });
      setPage(1);
    },
    [sortConfig],
  );

  const { data: searchFinancialAdvisorData, loading: searchFinancialAdvisorLoading } = useSearchFinancialAdvisor({
    viewerPartyId,
  });

  const { data: contentData, loading: contentLoading, error: contentError } = useGetHomeOfficeDashboardContent({
    variables: contentOptions,
  });

  const content = useMemo(
    () => getHomeOfficeContent(contentData ?? { all_home_office_dashboard: null }, view, onSort),
    [contentData, view, onSort],
  );

  const { data: homeOfficeMetricsData, error: homeOfficeMetricsError } = useGetHomeOfficeMetricsData({
    timeFrameValues,
    filterValues: Object.keys(appliedFilters).reduce<{ [key: string]: NumberRange | undefined }>((acc, key) => {
      acc[key] = getSelectedRange(appliedFilters[key]);
      return acc;
    }, {}),
    contentOptions,
    view,
    selectedParty: searchFinancialAdvisorData?.selectedSearchOption?.advisorPartyId,
    currentPage: page,
    recordsPerPage,
    sortConfig,
  });

  const onDownloadData = useDownloadFinancialAdvisorData();

  const updateTimeFrameValues = useCallback(
    (newTimeFrameValues: HomeOfficeTimeFrameValues) => {
      const newView = isDateView(newTimeFrameValues) ? dateView : periodView;
      // Reset filter values when changing selectedTimeFrame
      if (newTimeFrameValues.selectedTimeFrame !== timeFrameValues.selectedTimeFrame) {
        setAppliedFilters({});
      }
      setView(newView);
      setTimeFrameValues(newTimeFrameValues);
      setPage(1);
      if (isDateView(newTimeFrameValues)) {
        setSortConfig(DEFAULT_SORT_CONFIG);
      } else {
        setSortConfig({
          order: DEFAULT_SORT_CONFIG.order,
          field: 'aumForPeriod',
        });
      }
    },
    [dateView, periodView, timeFrameValues.selectedTimeFrame],
  );

  const updateRecordsPerPage = useCallback((newRecordsPerPage: number) => {
    setPage(1);
    setRecordsPerPage(newRecordsPerPage);
  }, []);

  const filterConfig: FilterConfig[] = useMemo(() => {
    return getFilterOptionsConfig(content.homeOfficeTableFilterOptionsContent, view);
  }, [content.homeOfficeTableFilterOptionsContent, view]);

  const homeOfficeTableItems = useMemo(
    () =>
      getFormattedItems({
        items: homeOfficeMetricsData?.homeOfficeTableData,
        onAdvisorClick,
        keys: view.columns.map(col => col.key),
      }),
    [homeOfficeMetricsData?.homeOfficeTableData, onAdvisorClick, view.columns],
  );

  const onClickDownloadData = useCallback(() => {
    if (onDownloadData.isDownloading || !homeOfficeMetricsData) {
      return;
    }
    onDownloadData.onDownloadCSV({
      timeFrameValues,
      contentOptions,
      content,
      sortConfig,
      selectedParty: searchFinancialAdvisorData?.selectedSearchOption?.advisorPartyId,
      filterValues: Object.keys(appliedFilters).reduce<{ [key: string]: NumberRange | undefined }>((acc, key) => {
        acc[key] = getSelectedRange(appliedFilters[key]);
        return acc;
      }, {}),
      view,
      totalData: homeOfficeMetricsData.paginationContext.totalRecords,
    });
  }, [
    appliedFilters,
    content,
    contentOptions,
    homeOfficeMetricsData,
    onDownloadData,
    searchFinancialAdvisorData?.selectedSearchOption?.advisorPartyId,
    sortConfig,
    timeFrameValues,
    view,
  ]);

  const onFilterChange = useCallback(
    (selectedFilters: SelectedFilters) => {
      setAppliedFilters(selectedFilters);
      setPage(1);
      if (isDateView(timeFrameValues)) {
        setSortConfig(DEFAULT_SORT_CONFIG);
      } else {
        setSortConfig({
          order: DEFAULT_SORT_CONFIG.order,
          field: 'aumForPeriod',
        });
      }
    },
    [timeFrameValues],
  );

  const updateOpenFiltersPopup = useCallback((value: boolean) => {
    setFiltersPopupOpen(value);
  }, []);

  const data = useMemo(() => {
    const paginationContext = {
      currentPage: page,
      totalItems: homeOfficeMetricsData?.paginationContext.totalRecords ?? 0,
      recordsPerPage,
      recordsPerPageLabel: content.recordsPerPageLabel,
      totalItemsLabel: content.totalItemsLabel,
      updateRecordsPerPage,
    };
    return {
      appliedFilters,
      content,
      downloadLoading: onDownloadData.isDownloading,
      filterConfig,
      filtersPopupOpen,
      homeOfficeTableItems,
      onDownloadCSV: onClickDownloadData,
      onFilterChange,
      onSearchOptionSelect: (selectedSearchOption: SearchOption) =>
        searchFinancialAdvisorData?.onSearchOptionSelect(selectedSearchOption),
      onSearchTermChange: (searchTerm: string) => searchFinancialAdvisorData?.onSearchTermChange(searchTerm),
      page,
      paginationContext,
      partnerDataLoading: homeOfficeMetricsData?.partnerMetricsLoading ?? true,
      searchError: !!searchFinancialAdvisorData?.searchError,
      searchOptions: searchFinancialAdvisorData?.searchOptions ?? [],
      searchOptionsLoading: searchFinancialAdvisorLoading,
      selectedSearchOption: searchFinancialAdvisorData?.selectedSearchOption,
      sortConfig,
      tableDataError: homeOfficeMetricsError,
      tableDataLoading: homeOfficeMetricsData?.homeOfficeTableDataLoading ?? true,
      timeFrameValues,
      totalAccountsOpened: homeOfficeMetricsData?.partnerData.totalAccountsOpened ?? DEFAULT_DISPLAY_VALUE,
      totalAum: homeOfficeMetricsData?.partnerData.totalAum,
      totalPages: homeOfficeMetricsData?.paginationContext.totalPages ?? 0,
      updateOpenFiltersPopup,
      updatePage: setPage,
      updateTimeFrameValues,
      view,
      ...getPartnerMetricRteAttributes(content, timeFrameValues),
    };
  }, [
    appliedFilters,
    content,
    filterConfig,
    filtersPopupOpen,
    homeOfficeMetricsData,
    homeOfficeMetricsError,
    homeOfficeTableItems,
    onClickDownloadData,
    onDownloadData.isDownloading,
    onFilterChange,
    page,
    recordsPerPage,
    sortConfig,
    searchFinancialAdvisorData,
    searchFinancialAdvisorLoading,
    timeFrameValues,
    updateOpenFiltersPopup,
    updateRecordsPerPage,
    updateTimeFrameValues,
    view,
  ]);

  return {
    data,
    error: contentError,
    loading: contentLoading,
  };
};
