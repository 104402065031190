import { QuestionOrderSteps as QuestionOrderStep, QuestionRule } from '../Section/types';
import { PaperworkPageConfig, PaperworkSection } from '../types';

import { companyAddressQuestionSet, homeAddressQuestionSet, mailingAddressQuestionSet } from './questions/address';
import { citizenshipQuestionSet } from './questions/personal';
import { QuestionKeys, QuestionProperties, QuestionSetKeys } from './questions/types';
import { PaperworkDataPageConfig, PaperworkDataQuestionConfig, PaperworkDataSectionConfig } from './types';

import { PaperworkType, TrustProfileType } from '~/__generated__';

export const getPaperworkData = (
  pages: PaperworkDataPageConfig[],
  paperworkFreeFormIdToProfileType: Record<string, PaperworkType>,
  paperworkFreeFormIdToTrustProfileType: Record<string, TrustProfileType>,
  mapper: (questionKey: string, properties: QuestionProperties) => QuestionOrderStep,
): PaperworkPageConfig => {
  let sectionCount = 0;
  return {
    pages: pages.map((page, index) => {
      const initialSectionNumberForPage = sectionCount + 1;
      sectionCount += page.sections.length;
      return {
        paperworkFreeFormIdForPage: page.paperworkFreeFormIdForPage,
        consent: page.consent,
        heading: page.heading,
        index,
        sections: constructSections(
          page.paperworkFreeFormIdForPage,
          page.sections,
          mapper,
          initialSectionNumberForPage,
        ),
      };
    }),
    paperworkFreeFormIdToProfileType,
    paperworkFreeFormIdToTrustProfileType,
  };
};

const constructSections = (
  paperworkFreeFormId: string,
  sections: PaperworkDataSectionConfig[],
  mapper: (questionKey: string, properties: QuestionProperties) => QuestionOrderStep,
  initialNumber: number,
): PaperworkSection[] => {
  return sections.map((section, index) => {
    return {
      index,
      number: initialNumber + index,
      sectionKey: section.sectionKey,
      adjacent: section.adjacent,
      questions: {
        order: {
          start: section.questions && section.questions.length > 0 ? section.questions[0].questionOrSetKey : null,
          orderSteps: constructQuestions(paperworkFreeFormId, section.questions ?? [], mapper),
        },
      },
    };
  });
};

export const constructQuestions = (
  paperworkFreeFormId: string,
  questions: PaperworkDataQuestionConfig[],
  mapper: (questionKey: string, properties: QuestionProperties) => QuestionOrderStep,
): QuestionOrderStep[] => {
  return questions
    .map((question, index, array) => {
      const nextIndex = index + 1;
      const rules = question.properties?.rules ?? getNext(array, nextIndex);
      return mapQuestion(paperworkFreeFormId, question, rules, mapper);
    })
    .flat();
};

const mapQuestion = (
  paperworkFreeFormId: string,
  question: PaperworkDataQuestionConfig,
  rules: QuestionRule[],
  mapper: (questionKey: string, properties: QuestionProperties) => QuestionOrderStep,
): QuestionOrderStep[] => {
  switch (question.questionOrSetKey) {
    case QuestionSetKeys.CITIZENSHIP_FIELDS:
      return citizenshipQuestionSet.map((defaultCitizenshipQuestion, index) => {
        let questionRules: QuestionRule[];
        if (index === 0 && rules.length > 0) {
          questionRules = [...(defaultCitizenshipQuestion.properties?.rules ?? []), ...rules];
        } else {
          questionRules = defaultCitizenshipQuestion.properties?.rules ?? [];
        }
        return callMapper(paperworkFreeFormId, defaultCitizenshipQuestion, questionRules, mapper);
      });
    case QuestionSetKeys.COMPANY_ADDRESS_FIELDS:
      return companyAddressQuestionSet.map(defaultAddressQuestion => {
        return callMapper(
          paperworkFreeFormId,
          defaultAddressQuestion,
          defaultAddressQuestion.properties?.rules ?? rules,
          mapper,
        );
      });
    case QuestionSetKeys.HOME_ADDRESS_FIELDS:
      return homeAddressQuestionSet.map(defaultAddressQuestion => {
        return callMapper(
          paperworkFreeFormId,
          defaultAddressQuestion,
          defaultAddressQuestion.properties?.rules ?? rules,
          mapper,
        );
      });
    case QuestionSetKeys.MAILING_ADDRESS_FIELDS:
      return mailingAddressQuestionSet.map(defaultAddressQuestion => {
        return callMapper(
          paperworkFreeFormId,
          defaultAddressQuestion,
          defaultAddressQuestion.properties?.rules ?? rules,
          mapper,
        );
      });
    default:
      return [callMapper(paperworkFreeFormId, question, rules, mapper)];
  }
};

const callMapper = (
  paperworkFreeFormId: string,
  question: PaperworkDataQuestionConfig,
  rules: QuestionRule[],
  mapper: (questionKey: string, properties: QuestionProperties) => QuestionOrderStep,
): QuestionOrderStep => {
  return {
    paperworkFreeFormId,
    ...mapper(question.questionOrSetKey, {
      paperworkFreeFormId,
      additionalValidations: {},
      adjacent: question.properties?.adjacent,
      defaultValue: question.properties?.defaultValue,
      disabled: question.properties?.disabled,
      required: question.properties?.optional ? !question.properties.optional : true,
      rules,
    }),
  };
};

const getNext = (questions: PaperworkDataQuestionConfig[], nextIndex: number): QuestionRule[] => {
  if (nextIndex < questions.length) {
    const nextKey = questions[nextIndex].questionOrSetKey;
    switch (nextKey) {
      case QuestionSetKeys.CITIZENSHIP_FIELDS:
        return [{ next: QuestionKeys.RESIDENT_TYPE }];
      case QuestionSetKeys.COMPANY_ADDRESS_FIELDS:
        return [{ next: QuestionKeys.COMPANY_STREET_ADDRESS }];
      case QuestionSetKeys.HOME_ADDRESS_FIELDS:
        return [{ next: QuestionKeys.HOME_STREET_ADDRESS }];
      case QuestionSetKeys.MAILING_ADDRESS_FIELDS:
        return [{ next: QuestionKeys.MAILING_STREET_ADDRESS }];
      default:
        return [{ next: nextKey }];
    }
  } else {
    return [];
  }
};
