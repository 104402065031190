import { ComponentTypes, QuestionOrderSteps } from '../../Section/types';
import { getFieldName } from '../../Section/utils';
import { isConfirmEmailMatching, isValidEmail, isValidNonNegativeInteger } from '../../Section/validators';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';
import {
  filterValidationRules,
  getPaperworkDataForPrefill,
  getPaperworkStringFieldValue,
  requiredValidation,
} from './utils';

import { ContactType } from '~/__generated__';

export const confirmEmail = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.CONFIRM_EMAIL,
    dataPointKey: DataPointKeys.CONFIRM_EMAIL,
    prefillValueGetter: data => {
      const emailPartyContacts = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyContacts?.filter(
        c => c.type === ContactType.EMAIL,
      );
      let emailPartyContact = emailPartyContacts?.find(c => c.isPrimary);
      if (!emailPartyContact) {
        emailPartyContact = emailPartyContacts?.[0];
      }
      return emailPartyContact?.contact;
    },
    paperworkInputSetter: (_paperworkInput, _data) => undefined,
    componentType: ComponentTypes.Input,
    inputComponentProps: {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z0-9@._+-]/g),
      textFieldProps: {
        onPaste: e => e.preventDefault(),
        type: 'email',
      },
    },
    adjacent,
    rules,
    validationsWatchedFormFields: [getFieldName(DataPointKeys.EMAIL, paperworkFreeFormId)],
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            isEmail: value => (value ? isValidEmail(value) : true),
            isEmailConfirmed: value =>
              value
                ? isConfirmEmailMatching(value, getFieldName(DataPointKeys.EMAIL, paperworkFreeFormId), data)
                : true,
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const email = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.EMAIL,
    dataPointKey: DataPointKeys.EMAIL,
    prefillValueGetter: data => {
      const emailPartyContacts = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyContacts?.filter(
        c => c.type === ContactType.EMAIL,
      );
      let emailPartyContact = emailPartyContacts?.find(c => c.isPrimary);
      if (!emailPartyContact) {
        emailPartyContact = emailPartyContacts?.[0];
      }
      return emailPartyContact?.contact;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const emailValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.EMAIL, paperworkFreeFormId)],
      );
      const partyContact = paperworkInput.party.partyContacts?.find(i => i.type === ContactType.EMAIL);
      if (!partyContact) {
        if (emailValue) {
          paperworkInput.party.partyContacts?.push({
            type: ContactType.EMAIL,
            contact: emailValue,
          });
        }
      } else if (emailValue) {
        partyContact.contact = emailValue;
      }
    },
    componentType: ComponentTypes.Input,
    inputComponentProps: {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z0-9@._+-]/g),
      textFieldProps: {
        type: 'email',
      },
    },
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            isEmail: value => (value ? isValidEmail(value) : true),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const phoneNumber = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.PHONE_NUMBER,
    dataPointKey: DataPointKeys.PHONE_NUMBER,
    prefillValueGetter: data => {
      const phonePartyContacts = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyContacts?.filter(
        c => c.type === ContactType.MOBILE,
      );
      let phonePartyContact = phonePartyContacts?.find(c => c.isPrimary);
      if (!phonePartyContact) {
        phonePartyContact = phonePartyContacts?.[0];
      }
      return phonePartyContact?.contact;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const phoneValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.PHONE_NUMBER, paperworkFreeFormId)],
      );
      const partyContact = paperworkInput.party.partyContacts?.find(i => i.type === ContactType.MOBILE);
      if (!partyContact) {
        if (phoneValue) {
          paperworkInput.party.partyContacts?.push({
            type: ContactType.MOBILE,
            contact: phoneValue,
          });
        }
      } else if (phoneValue) {
        partyContact.contact = phoneValue;
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps: {
      invalidCharacterRegex: new RegExp(/\D/g),
    },
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            isNumber: value => (value ? isValidNonNegativeInteger(value) : true),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};
