import React, { useMemo } from 'react';

import { useGetPortfolioSelectionCardAssetData } from './hooks/useGetPortfolioSelectionCardAssetData';

import { Chip } from '~/components/ui/Chip';
import { Divider } from '~/components/ui/Divider';
import { Link } from '~/components/ui/Link';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { getStockBondRatio } from '~/containers/Plan/utils';
import { AssetAllocationModalState, FilterGroup, Portfolio } from '~/containers/PortfolioSelection/types';
import { allocationToDonutSlice, getAssetAllocations } from '~/utils/asset-allocation';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { formatCurrency } from '~/utils/format';

export interface PortfolioSelectionCardHeader {
  action: string;
  assetAllocation: string;
  description?: string;
  factSheet: string;
  minimumInvestmentAmount: string;
  productName: string;
  title?: string;
}

export interface PortfolioSelectionCardLabels {
  assetAllocationLabel: string;
  factSheetLabel: string;
  selectLabel: string;
}

export interface PortfolioSelectionCardProps {
  assetClassTier?: AssetClassTier;
  content: {
    header: Partial<PortfolioSelectionCardHeader>;
    labels: Partial<PortfolioSelectionCardLabels>;
  };
  contentOptions: ContentOptions;
  filters?: FilterGroup[];
  investmentAmount?: number;
  openAssetAllocationModal: (assetAllocationProps: AssetAllocationModalState) => void;
  portfolios: Portfolio[];
  selectSinglePortfolio: (portfolio: Portfolio) => void;
}

export const PortfolioSelectionCard: React.FC<PortfolioSelectionCardProps> = ({
  assetClassTier,
  content: { header, labels },
  contentOptions,
  filters,
  investmentAmount,
  openAssetAllocationModal,
  portfolios,
  selectSinglePortfolio,
}) => {
  const {
    sfPortfolioSelection: { styles, typographyVariants },
  } = useTheme();
  const tableCellStyle = styles.portfolioSelectionCard?.table.cell;

  const {
    hideFactSheet,
    showMinimumInvestmentAmountColumn,
    supportedTags,
  } = useCoreConfig().components.sfPortfolioSelection;

  const {
    data: portfolioSelectionCardAssetData,
    loading: portfolioSelectionCardAssetLoading,
    error: portfolioSelectionCardAssetError,
  } = useGetPortfolioSelectionCardAssetData({
    contentOptions,
  });

  const assetClassContent = portfolioSelectionCardAssetData?.assetClass;

  const content = portfolioSelectionCardAssetData?.content;

  const handleOpenAssetAllocationModal = (portfolio: Portfolio): void => {
    const assetAllocationData = getAssetAllocations(portfolio.assetAllocations, assetClassContent, assetClassTier);

    const stockBondLabel = content?.stockLabel && content.bondLabel ? `${content.stockLabel}/${content.bondLabel}` : '';

    const stockBondRatio = getStockBondRatio(portfolio.stockBondDiversification);

    const portfolioAssetsBreakdown = assetAllocationData.map(allocationToDonutSlice(assetClassTier));

    const donutPropsData = {
      centerSubtext: stockBondLabel,
      centerText: stockBondRatio,
      centerTextVariant: typographyVariants?.stockBondSplitLabel,
      data: portfolioAssetsBreakdown,
      innerRadius: 75,
      justifyContentGraph: 'center',
      showTooltip: true,
      size: 275,
    };

    openAssetAllocationModal({
      content,
      error: portfolioSelectionCardAssetError,
      loading: portfolioSelectionCardAssetLoading,
      portfolio: {
        assetAllocationData,
        description: portfolio.description,
        name: portfolio.productName,
        portfolioAssetsBreakdown,
        stockBondRatio,
      },
      donutProps: donutPropsData,
    });
  };

  const tags = useMemo(() => {
    const cardTags = Array.from(
      new Set(
        portfolios.flatMap(portfolio => portfolio.tags?.filter(tag => tag.value)).map(tag => tag?.name && tag.name),
      ),
    ).filter(contentTag => contentTag && supportedTags.includes(contentTag));

    const allTags = filters?.find(filterGroup => filterGroup.attribute === 'tags')?.items;

    if (!allTags) {
      return cardTags;
    }

    return cardTags.map(
      tag => allTags.find(contentTag => contentTag.value === tag)?.label ?? `Tag label not found for ${tag}`,
    );
  }, [filters, portfolios, supportedTags]);

  // This flag will be used to show asterisk besides model name
  // where opening minimum is higher than the investment amount
  const isModelPartiallyAllowed = (portfolio: Portfolio) =>
    investmentAmount !== undefined &&
    portfolio.accountMinimumBalanceForAccountOpening &&
    parseFloat(portfolio.accountMinimumBalanceForAccountOpening) > investmentAmount;

  return (
    <Grid container spacing={2.5}>
      <Grid display="flex" item xs={12}>
        <Typography component="h2" sx={{ color: styles.highlightColor }} variant="body1">
          {header.title}
        </Typography>
        {tags.map(tag => tag && <Chip key={tag} label={tag} size="small" sx={{ ml: 0.5 }} variant="filled" />)}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">{header.description}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ borderColor: styles.highlightColor, borderWidth: 0.25 }} />
        <TableContainer>
          <Table sx={styles.portfolioSelectionCard?.table.root}>
            <TableHead>
              <TableRow>
                <TableCell width="100%">{header.productName}</TableCell>
                {showMinimumInvestmentAmountColumn && (
                  <TableCell sx={tableCellStyle}>{header.minimumInvestmentAmount}</TableCell>
                )}
                {!hideFactSheet && <TableCell sx={tableCellStyle}>{header.factSheet}</TableCell>}
                <TableCell sx={tableCellStyle}>{header.assetAllocation}</TableCell>
                <TableCell sx={tableCellStyle}>{header.action}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {portfolios.map(portfolio => (
                <TableRow key={`${portfolio.seriesBaseName}-${portfolio.productName}-${portfolio.seriesId}`}>
                  <TableCell scope="row">
                    {`${portfolio.productName}${isModelPartiallyAllowed(portfolio) ? '*' : ''}`}
                  </TableCell>
                  {showMinimumInvestmentAmountColumn && (
                    <TableCell sx={tableCellStyle}>
                      {portfolio.accountMinimumBalanceForAccountOpening &&
                        formatCurrency(parseFloat(portfolio.accountMinimumBalanceForAccountOpening))}
                    </TableCell>
                  )}
                  {!hideFactSheet && (
                    <TableCell sx={tableCellStyle}>
                      <Link
                        href={portfolio.factSheetLink}
                        rel="noopener noreferrer"
                        sx={styles.portfolioSelectionCard?.table.link}
                        target="_blank"
                      >
                        {labels.factSheetLabel}
                      </Link>
                    </TableCell>
                  )}
                  <TableCell sx={tableCellStyle}>
                    <Link
                      aria-haspopup="true"
                      onClick={() => handleOpenAssetAllocationModal(portfolio)}
                      sx={styles.portfolioSelectionCard?.table.link}
                    >
                      {labels.assetAllocationLabel}
                    </Link>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <Button
                      onClick={() => selectSinglePortfolio(portfolio)}
                      sx={styles.portfolioSelectionCard?.table.cta}
                      variant="contained"
                    >
                      {labels.selectLabel}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
