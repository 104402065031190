import { DataPointKeys, QuestionKeys, QuestionSetKeys } from '../../questions/types';
import { PaperworkDataQuestionConfig } from '../../types';

export const aboutYouSectionKey = 'about_you';
export const aboutCoApplicantSectionKey = 'about_co_applicant';

export const aboutYouQuestions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.FIRST_NAME },
  { properties: { optional: true }, questionOrSetKey: QuestionKeys.MIDDLE_NAME },
  { questionOrSetKey: QuestionKeys.LAST_NAME },
  { questionOrSetKey: QuestionKeys.EMAIL },
  { questionOrSetKey: QuestionKeys.CONFIRM_EMAIL },
  { questionOrSetKey: QuestionKeys.PHONE_NUMBER },
  { properties: { adjacent: QuestionKeys.SSN }, questionOrSetKey: QuestionKeys.BIRTH_DATE },
  { questionOrSetKey: QuestionKeys.SSN },
  { questionOrSetKey: QuestionSetKeys.HOME_ADDRESS_FIELDS },
  {
    properties: {
      optional: true,
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.DIFFERENT_MAILING_ADDRESS,
              value: true,
            },
          ],
          next: QuestionKeys.MAILING_STREET_ADDRESS,
        },
        {
          next: QuestionKeys.RESIDENT_TYPE,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.DIFFERENT_MAILING_ADDRESS,
  },
  { questionOrSetKey: QuestionSetKeys.MAILING_ADDRESS_FIELDS },
  { questionOrSetKey: QuestionSetKeys.CITIZENSHIP_FIELDS },
  { questionOrSetKey: QuestionKeys.MARITAL_STATUS },
  { questionOrSetKey: QuestionKeys.NUMBER_OF_DEPENDENTS },
];

export const aboutCoApplicantQuestions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.FIRST_NAME },
  { properties: { optional: true }, questionOrSetKey: QuestionKeys.MIDDLE_NAME },
  { questionOrSetKey: QuestionKeys.LAST_NAME },
  { questionOrSetKey: QuestionKeys.EMAIL },
  { questionOrSetKey: QuestionKeys.CONFIRM_EMAIL },
  { questionOrSetKey: QuestionKeys.PHONE_NUMBER },
  { properties: { adjacent: QuestionKeys.SSN }, questionOrSetKey: QuestionKeys.BIRTH_DATE },
  { questionOrSetKey: QuestionKeys.SSN },
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.SAME_HOME_MAILING_ADDRESS,
              value: false,
            },
          ],
          next: QuestionKeys.HOME_STREET_ADDRESS,
        },
        {
          next: QuestionKeys.RESIDENT_TYPE,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.SAME_HOME_MAILING_ADDRESS,
  },
  { questionOrSetKey: QuestionSetKeys.HOME_ADDRESS_FIELDS },
  {
    properties: {
      optional: true,
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.DIFFERENT_MAILING_ADDRESS,
              value: true,
            },
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.SAME_HOME_MAILING_ADDRESS,
              value: false,
            },
          ],
          next: QuestionKeys.MAILING_STREET_ADDRESS,
        },
        {
          next: null,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.DIFFERENT_MAILING_ADDRESS,
  },
  { questionOrSetKey: QuestionSetKeys.MAILING_ADDRESS_FIELDS },
  { questionOrSetKey: QuestionSetKeys.CITIZENSHIP_FIELDS },
  { questionOrSetKey: QuestionKeys.MARITAL_STATUS },
  { questionOrSetKey: QuestionKeys.NUMBER_OF_DEPENDENTS },
];

export const aboutJointTenantsInCommonQuestions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.FIRST_NAME },
  { properties: { optional: true }, questionOrSetKey: QuestionKeys.MIDDLE_NAME },
  { questionOrSetKey: QuestionKeys.LAST_NAME },
  { questionOrSetKey: QuestionKeys.TENANTS_IN_COMMON_PERCENTAGE },
  { questionOrSetKey: QuestionKeys.EMAIL },
  { questionOrSetKey: QuestionKeys.CONFIRM_EMAIL },
  { questionOrSetKey: QuestionKeys.PHONE_NUMBER },
  { properties: { adjacent: QuestionKeys.SSN }, questionOrSetKey: QuestionKeys.BIRTH_DATE },
  { questionOrSetKey: QuestionKeys.SSN },
  { questionOrSetKey: QuestionSetKeys.HOME_ADDRESS_FIELDS },
  {
    properties: {
      optional: true,
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.DIFFERENT_MAILING_ADDRESS,
              value: true,
            },
          ],
          next: QuestionKeys.MAILING_STREET_ADDRESS,
        },
        {
          next: QuestionKeys.RESIDENT_TYPE,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.DIFFERENT_MAILING_ADDRESS,
  },
  { questionOrSetKey: QuestionSetKeys.MAILING_ADDRESS_FIELDS },
  { questionOrSetKey: QuestionSetKeys.CITIZENSHIP_FIELDS },
  { questionOrSetKey: QuestionKeys.MARITAL_STATUS },
  { questionOrSetKey: QuestionKeys.NUMBER_OF_DEPENDENTS },
];

export const aboutJointTenantsInCommonCoApplicantQuestions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.FIRST_NAME },
  { properties: { optional: true }, questionOrSetKey: QuestionKeys.MIDDLE_NAME },
  { questionOrSetKey: QuestionKeys.LAST_NAME },
  { questionOrSetKey: QuestionKeys.TENANTS_IN_COMMON_PERCENTAGE_CO_APPLICANT },
  { questionOrSetKey: QuestionKeys.EMAIL },
  { questionOrSetKey: QuestionKeys.CONFIRM_EMAIL },
  { questionOrSetKey: QuestionKeys.PHONE_NUMBER },
  { properties: { adjacent: QuestionKeys.SSN }, questionOrSetKey: QuestionKeys.BIRTH_DATE },
  { questionOrSetKey: QuestionKeys.SSN },
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.SAME_HOME_MAILING_ADDRESS,
              value: false,
            },
          ],
          next: QuestionKeys.HOME_STREET_ADDRESS,
        },
        {
          next: QuestionKeys.RESIDENT_TYPE,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.SAME_HOME_MAILING_ADDRESS,
  },
  { questionOrSetKey: QuestionSetKeys.HOME_ADDRESS_FIELDS },
  {
    properties: {
      optional: true,
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.DIFFERENT_MAILING_ADDRESS,
              value: true,
            },
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.SAME_HOME_MAILING_ADDRESS,
              value: false,
            },
          ],
          next: QuestionKeys.MAILING_STREET_ADDRESS,
        },
        {
          next: null,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.DIFFERENT_MAILING_ADDRESS,
  },
  { questionOrSetKey: QuestionSetKeys.MAILING_ADDRESS_FIELDS },
  { questionOrSetKey: QuestionSetKeys.CITIZENSHIP_FIELDS },
  { questionOrSetKey: QuestionKeys.MARITAL_STATUS },
  { questionOrSetKey: QuestionKeys.NUMBER_OF_DEPENDENTS },
];
