import { QuestionOrderSteps as QuestionOrderStep } from '../../../Section/types';
import {
  grantorSsn,
  revokerFirstName,
  revokerLastName,
  revokerMiddleName,
  taxReportingNumber,
  trustName,
  trustSsn,
} from '../../questions/trust';
import { QuestionProperties } from '../../questions/types';

export const grantorSsnNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return grantorSsn({
    ...properties,
  });
};

export const revokerFirstNameNfsSMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return revokerFirstName(
    {
      ...properties,
      additionalValidations: {
        pattern: new RegExp(/^[a-zA-Z -]*$/),
        maxLength: 25,
        ...properties.additionalValidations,
      },
    },
    {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z -]/g),
    },
  );
};

export const revokerLastNameNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return revokerLastName(
    {
      ...properties,
      additionalValidations: {
        pattern: new RegExp(/^[a-zA-Z -]*$/),
        maxLength: 25,
        ...properties.additionalValidations,
      },
    },
    {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z -]/g),
    },
  );
};

export const revokerMiddleNameNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return revokerMiddleName(
    {
      ...properties,
      additionalValidations: {
        pattern: new RegExp(/^[a-zA-Z ]*$/),
        maxLength: 10,
        ...properties.additionalValidations,
      },
    },
    {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z ]/g),
    },
  );
};

export const taxReportingNumberNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return taxReportingNumber({
    ...properties,
    additionalValidations: {
      maxLength: 9,
      ...properties.additionalValidations,
    },
  });
};

export const trustNameNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return trustName({
    ...properties,
    additionalValidations: {
      maxLength: 25,
      ...properties.additionalValidations,
    },
  });
};

export const trustSsnNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return trustSsn({
    ...properties,
  });
};
