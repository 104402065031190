import { Text, View } from '@react-pdf/renderer';
import React from 'react';

import { formatBalance, formatDisplayDate } from '~/components/AccountPerformance/utils';
import { Style } from '~/components/pdfs/styles';
import { ContentOptions } from '~/utils/contentstack';

export interface PageList {
  index: string;
  page: string;
}

export interface AccountOverviewPdfContent {
  accountBalance: string;
  overview: string;
  returns: string;
}

export interface Props {
  accountBalance?: string;
  content: AccountOverviewPdfContent;
  contentOptions: ContentOptions;
  endDate: string;
  pdfStyles: Style;
  returnPercentage?: string;
  startDate?: string | null;
}

export const AccountOverview: React.FC<Props> = ({
  accountBalance,
  content,
  contentOptions,
  endDate,
  pdfStyles,
  returnPercentage,
  startDate,
}) => {
  return (
    <View
      style={{
        width: '60%',
        padding: '24px 40px 0',
        marginBottom: '8px',
      }}
    >
      <Text
        style={{
          color: pdfStyles.textPrimary.color,
          fontSize: 16,
          marginBottom: '4px',
        }}
      >
        {content.overview}
      </Text>
      <Text
        style={{
          color: pdfStyles.textSecondary.color,
          fontSize: 8,
        }}
      >
        {`${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`}
      </Text>
      <View
        style={{
          display: 'flex',
          padding: '2px 0',
          justifyContent: 'space-between',
          flexDirection: 'row',
          marginTop: '24px',
        }}
      >
        <View
          style={{
            marginRight: '24px',
          }}
        >
          <Text
            style={{
              color: pdfStyles.textSecondary.color,
              fontSize: 10,
              marginBottom: '4px',
            }}
          >
            {content.accountBalance}
          </Text>
          <Text
            style={{
              color: pdfStyles.textPrimary.color,
              fontSize: 22,
            }}
          >
            {formatBalance(accountBalance ?? '', contentOptions)}
          </Text>
        </View>
        <View>
          <Text
            style={{
              color: pdfStyles.textSecondary.color,
              fontSize: 10,
              marginBottom: '4px',
            }}
          >
            {content.returns}
          </Text>
          <Text
            style={{
              color: pdfStyles.textPrimary.color,
              fontSize: 22,
            }}
          >
            {returnPercentage}
          </Text>
        </View>
      </View>
    </View>
  );
};
