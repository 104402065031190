import { QuestionOrderSteps as QuestionOrderStep } from '../../Section/types';
import {
  companyCountry,
  companyState,
  differentMailingAddress,
  homeCountry,
  mailingCountry,
  mailingState,
  sameHomeMailingAddressAsPrimary,
} from '../questions/address';
import { assetsHeldAway } from '../questions/assetsHeldAway';
import { designatePrimaryBeneficiary, designateTrustedContact } from '../questions/beneficiary';
import { eDelivery } from '../questions/eDelivery';
import { employmentStatus, incomeSource } from '../questions/employment';
import {
  drivingLicenseExpiryDate,
  drivingLicenseIssueDate,
  drivingLicenseState,
  passportCountry,
  passportExpiryDate,
  passportIssueDate,
} from '../questions/identifiers';
import { grantorPageSoftWarning, sectionJustification } from '../questions/info';
import {
  aboutAdditionalStakeholder,
  designatePrimaryBeneficiary as designatePrimaryBeneficiaryLabel,
  employment,
  identification,
  revocablePrimaryTrustee,
} from '../questions/labels';
import {
  originalDepositorBirthdate,
  originalDepositorDeathDate,
  originalDepositorRelationshipType,
  originalDepositorText,
} from '../questions/originalDepositor';
import {
  birthdate,
  citizenship,
  maritalStatus,
  residentAlienTaxStatus,
  residentType,
  tenantInCommonPercentage,
  tenantInCommonPercentageCoApplicant,
  usTaxStatus,
} from '../questions/personal';
import { regulatory } from '../questions/regulatory';
import { tlh, tlhLabel, tlhText } from '../questions/taxLossHarvesting';
import {
  isSsnSameAsTrustee,
  numberOfTrustees,
  stateLawGoverningTrust,
  taxReportingNumberType,
  trustDate,
  trustType,
} from '../questions/trust';
import { QuestionKeys, QuestionProperties } from '../questions/types';
import { taxBracket, wealthExpensesText } from '../questions/wealthExpenses';

import {
  companyCityNfsMapper,
  companyStreetAddressLine2NfsMapper,
  companyStreetAddressNfsMapper,
  companyZipCodeNfsMapper,
  homeCityNfsMapper,
  homeStateNfsMapper,
  homeStreetAddressLine2NfsMapper,
  homeStreetAddressNfsMapper,
  homeZipCodeNfsMapper,
  mailingAddressCityNfsMapper,
  mailingAddressStreetAddressLine2NfsMapper,
  mailingAddressStreetAddressNfsMapper,
  mailingAddressZipCodeNfsMapper,
} from './mappers/address';
import {
  contingentBeneficiaryNfsMapper,
  primaryBeneficiaryNfsMapper,
  trustedContactNfsMapper,
} from './mappers/beneficiary';
import { confirmEmailNfsMapper, emailNfsMapper, phoneNumberNfsMapper } from './mappers/contact';
import { eDeliveryEmailNfsMapper } from './mappers/eDelivery';
import {
  companyNameNfsMapper,
  jobTitleNfsMapper,
  jobTitleSelfEmployedNfsMapper,
  otherIncomeSourceNfsMapper,
} from './mappers/employment';
import {
  drivingLicenseIdentificationNumberNfsMapper,
  identificationTypeNfsMapper,
  passportIdentificationNumberNfsMapper,
  ssnNfsMapper,
} from './mappers/identifiers';
import {
  originalDepositorFirstNameNfsMapper,
  originalDepositorLastNameNfsMapper,
  originalDepositorMiddleNameNfsMapper,
  originalDepositorSsnNfsMapper,
} from './mappers/orignalDepositor';
import {
  firstNameNfsMapper,
  lastNameNfsMapper,
  middleNameNfsMapper,
  numberOfDependentsNfsMapper,
} from './mappers/personal';
import {
  grantorSsnNfsMapper,
  revokerFirstNameNfsSMapper,
  revokerLastNameNfsMapper,
  revokerMiddleNameNfsMapper,
  taxReportingNumberNfsMapper,
  trustNameNfsMapper,
  trustSsnNfsMapper,
} from './mappers/trust';
import {
  annualHouseholdIncomeMapper,
  annualRecurringExpensesMapper,
  estimatedNetWorthMapper,
  liquidNetWorthMapper,
} from './mappers/wealthAndExpenses';

export const mapper = (questionKey: string, properties: QuestionProperties): QuestionOrderStep => {
  switch (questionKey) {
    case QuestionKeys.ADDITIONAL_STAKEHOLDER:
      return aboutAdditionalStakeholder(properties);
    case QuestionKeys.ANNUAL_HOUSEHOLD_INCOME:
      return annualHouseholdIncomeMapper(properties);
    case QuestionKeys.ANNUAL_RECURRING_EXPENSES:
      return annualRecurringExpensesMapper(properties);
    case QuestionKeys.ASSETS_HELD_AWAY:
      return assetsHeldAway(properties);
    case QuestionKeys.BIRTH_DATE:
      return birthdate(properties);
    case QuestionKeys.CITIZENSHIP:
      return citizenship(properties);
    case QuestionKeys.COMPANY_CITY:
      return companyCityNfsMapper(properties);
    case QuestionKeys.COMPANY_COUNTRY:
      return companyCountry(properties);
    case QuestionKeys.COMPANY_NAME:
      return companyNameNfsMapper(properties);
    case QuestionKeys.COMPANY_STATE:
      return companyState(properties);
    case QuestionKeys.COMPANY_STREET_ADDRESS:
      return companyStreetAddressNfsMapper(properties);
    case QuestionKeys.COMPANY_STREET_ADDRESS_LINE_2:
      return companyStreetAddressLine2NfsMapper(properties);
    case QuestionKeys.COMPANY_ZIP_CODE:
      return companyZipCodeNfsMapper(properties);
    case QuestionKeys.CONFIRM_EMAIL:
      return confirmEmailNfsMapper(properties);
    case QuestionKeys.CONTINGENT_BENEFICIARY:
      return contingentBeneficiaryNfsMapper(properties);
    case QuestionKeys.DESIGNATE_PRIMARY_BENEFICIARY:
      return designatePrimaryBeneficiary(properties);
    case QuestionKeys.DESIGNATE_PRIMARY_BENEFICIARY_LABEL:
      return designatePrimaryBeneficiaryLabel(properties);
    case QuestionKeys.DESIGNATE_TRUSTED_CONTACT:
      return designateTrustedContact(properties);
    case QuestionKeys.DIFFERENT_MAILING_ADDRESS:
      return differentMailingAddress(properties);
    case QuestionKeys.DRIVING_LICENSE_EXPIRY_DATE:
      return drivingLicenseExpiryDate(properties);
    case QuestionKeys.DRIVING_LICENSE_IDENTIFICATION_NUMBER:
      return drivingLicenseIdentificationNumberNfsMapper(properties);
    case QuestionKeys.DRIVING_LICENSE_ISSUE_DATE:
      return drivingLicenseIssueDate(properties);
    case QuestionKeys.DRIVING_LICENSE_STATE:
      return drivingLicenseState(properties);
    case QuestionKeys.EDELIVERY:
      return eDelivery(properties);
    case QuestionKeys.EDELIVERY_EMAIL:
      return eDeliveryEmailNfsMapper(properties);
    case QuestionKeys.EMAIL:
      return emailNfsMapper(properties);
    case QuestionKeys.EMPLOYMENT:
      return employment(properties);
    case QuestionKeys.EMPLOYMENT_STATUS:
      return employmentStatus(properties);
    case QuestionKeys.ESTIMATED_NET_WORTH:
      return estimatedNetWorthMapper(properties);
    case QuestionKeys.FIRST_NAME:
      return firstNameNfsMapper(properties);
    case QuestionKeys.GRANTOR_PAGE_SOFT_WARNING:
      return grantorPageSoftWarning(properties);
    case QuestionKeys.GRANTOR_SSN:
      return grantorSsnNfsMapper(properties);
    case QuestionKeys.HOME_CITY:
      return homeCityNfsMapper(properties);
    case QuestionKeys.HOME_COUNTRY:
      return homeCountry(properties);
    case QuestionKeys.HOME_STATE:
      return homeStateNfsMapper(properties);
    case QuestionKeys.HOME_STREET_ADDRESS:
      return homeStreetAddressNfsMapper(properties);
    case QuestionKeys.HOME_STREET_ADDRESS_LINE_2:
      return homeStreetAddressLine2NfsMapper(properties);
    case QuestionKeys.HOME_ZIP_CODE:
      return homeZipCodeNfsMapper(properties);
    case QuestionKeys.IDENTIFICATION:
      return identification(properties);
    case QuestionKeys.IDENTIFICATION_TYPE:
      return identificationTypeNfsMapper(properties);
    case QuestionKeys.INCOME_SOURCE:
      return incomeSource(properties);
    case QuestionKeys.IS_SSN_SAME_AS_TRUSTEE:
      return isSsnSameAsTrustee(properties);
    case QuestionKeys.JOB_TITLE:
      return jobTitleNfsMapper(properties);
    case QuestionKeys.JOB_TITLE_SELF_EMPLOYED:
      return jobTitleSelfEmployedNfsMapper(properties);
    case QuestionKeys.LAST_NAME:
      return lastNameNfsMapper(properties);
    case QuestionKeys.LIQUID_NET_WORTH:
      return liquidNetWorthMapper(properties);
    case QuestionKeys.MAILING_CITY:
      return mailingAddressCityNfsMapper(properties);
    case QuestionKeys.MAILING_COUNTRY:
      return mailingCountry(properties);
    case QuestionKeys.MAILING_STATE:
      return mailingState(properties);
    case QuestionKeys.MAILING_STREET_ADDRESS:
      return mailingAddressStreetAddressNfsMapper(properties);
    case QuestionKeys.MAILING_STREET_ADDRESS_LINE_2:
      return mailingAddressStreetAddressLine2NfsMapper(properties);
    case QuestionKeys.MAILING_ZIP_CODE:
      return mailingAddressZipCodeNfsMapper(properties);
    case QuestionKeys.MARITAL_STATUS:
      return maritalStatus(properties);
    case QuestionKeys.MIDDLE_NAME:
      return middleNameNfsMapper(properties);
    case QuestionKeys.NUMBER_OF_DEPENDENTS:
      return numberOfDependentsNfsMapper(properties);
    case QuestionKeys.NUMBER_OF_TRUSTEES:
      return numberOfTrustees(properties);
    case QuestionKeys.ORIGINAL_DEPOSITOR_BIRTH_DATE:
      return originalDepositorBirthdate(properties);
    case QuestionKeys.ORIGINAL_DEPOSITOR_DEATH_DATE:
      return originalDepositorDeathDate(properties);
    case QuestionKeys.ORIGINAL_DEPOSITOR_FIRST_NAME:
      return originalDepositorFirstNameNfsMapper(properties);
    case QuestionKeys.ORIGINAL_DEPOSITOR_LAST_NAME:
      return originalDepositorLastNameNfsMapper(properties);
    case QuestionKeys.ORIGINAL_DEPOSITOR_MIDDLE_NAME:
      return originalDepositorMiddleNameNfsMapper(properties);
    case QuestionKeys.ORIGINAL_DEPOSITOR_RELATIONSHIP_TYPE:
      return originalDepositorRelationshipType(properties);
    case QuestionKeys.ORIGINAL_DEPOSITOR_SSN:
      return originalDepositorSsnNfsMapper(properties);
    case QuestionKeys.ORIGINAL_DEPOSITOR_TEXT:
      return originalDepositorText(properties);
    case QuestionKeys.OTHER_INCOME_SOURCE:
      return otherIncomeSourceNfsMapper(properties);
    case QuestionKeys.PASSPORT_COUNTRY:
      return passportCountry(properties);
    case QuestionKeys.PASSPORT_EXPIRY_DATE:
      return passportExpiryDate(properties);
    case QuestionKeys.PASSPORT_IDENTIFICATION_NUMBER:
      return passportIdentificationNumberNfsMapper(properties);
    case QuestionKeys.PASSPORT_ISSUE_DATE:
      return passportIssueDate(properties);
    case QuestionKeys.PHONE_NUMBER:
      return phoneNumberNfsMapper(properties);
    case QuestionKeys.PRIMARY_BENEFICIARY:
      return primaryBeneficiaryNfsMapper(properties);
    case QuestionKeys.REGULATORY:
      return regulatory(properties);
    case QuestionKeys.RESIDENT_ALIEN_TAX_STATUS:
      return residentAlienTaxStatus(properties);
    case QuestionKeys.RESIDENT_TYPE:
      return residentType(properties);
    case QuestionKeys.REVOCABLE_PRIMARY_TRUSTEE:
      return revocablePrimaryTrustee(properties);
    case QuestionKeys.REVOKER_FIRST_NAME:
      return revokerFirstNameNfsSMapper(properties);
    case QuestionKeys.REVOKER_LAST_NAME:
      return revokerLastNameNfsMapper(properties);
    case QuestionKeys.REVOKER_MIDDLE_NAME:
      return revokerMiddleNameNfsMapper(properties);
    case QuestionKeys.SAME_HOME_MAILING_ADDRESS:
      return sameHomeMailingAddressAsPrimary(properties);
    case QuestionKeys.SECTION_JUSTIFICATION:
      return sectionJustification(properties);
    case QuestionKeys.SSN:
      return ssnNfsMapper(properties);
    case QuestionKeys.STATE_LAW_GOVERNING_TRUST:
      return stateLawGoverningTrust(properties);
    case QuestionKeys.TAX_BRACKET:
      return taxBracket(properties);
    case QuestionKeys.TAX_REPORTING_NUMBER:
      return taxReportingNumberNfsMapper(properties);
    case QuestionKeys.TAX_REPORTING_TYPE:
      return taxReportingNumberType(properties);
    case QuestionKeys.TENANTS_IN_COMMON_PERCENTAGE:
      return tenantInCommonPercentage(properties);
    case QuestionKeys.TENANTS_IN_COMMON_PERCENTAGE_CO_APPLICANT:
      return tenantInCommonPercentageCoApplicant(properties);
    case QuestionKeys.TLH:
      return tlh(properties);
    case QuestionKeys.TLH_LABEL:
      return tlhLabel(properties);
    case QuestionKeys.TLH_TEXT:
      return tlhText(properties);
    case QuestionKeys.TRUSTED_CONTACT:
      return trustedContactNfsMapper(properties);
    case QuestionKeys.TRUST_DATE:
      return trustDate(properties);
    case QuestionKeys.TRUST_NAME:
      return trustNameNfsMapper(properties);
    case QuestionKeys.TRUST_SSN:
      return trustSsnNfsMapper(properties);
    case QuestionKeys.TRUST_TYPE:
      return trustType(properties);
    case QuestionKeys.US_TAX_STATUS:
      return usTaxStatus(properties);
    case QuestionKeys.WEALTH_EXPENSES_TEXT:
      return wealthExpensesText(properties);
    default:
      throw new Error(`Question key '${questionKey}' not found`);
  }
};
