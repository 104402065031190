import { parseISO } from 'date-fns';
import React, { ComponentProps, FC } from 'react';
import { Controller, FieldError, RegisterOptions, UseFormMethods } from 'react-hook-form';

import { FormData } from '../../types';
import { QuestionContent, QuestionOrderSteps } from '../types';

import { DatePicker } from '~/components/ui/DatePicker';

interface Props {
  datePickerProps?: Partial<ComponentProps<typeof DatePicker>>;
  defaultValue: any;
  fieldName: string;
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  inputErrors?: FieldError;
  isFieldDisabled: boolean;
  onDateAnswerChange: (dataPointKey: string, answer: string) => void;
  questionContent: QuestionContent;
  questionInRow: QuestionOrderSteps;
  validationRules: RegisterOptions;
}

export const DateQuestion: FC<Props> = ({
  datePickerProps,
  defaultValue,
  validationRules,
  fieldName,
  formHooks,
  onDateAnswerChange,
  questionContent,
  questionInRow,
  isFieldDisabled,
  inputErrors,
}) => {
  const { control } = formHooks;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue ? parseISO(defaultValue) : null}
      key={`question-${fieldName}`}
      name={fieldName}
      render={({ onChange, value, ref }) => (
        <DatePicker
          dataQa={`question-datePicker-${questionInRow.questionKey}`}
          disabled={isFieldDisabled}
          error={!!inputErrors}
          fullWidth
          inputRef={ref}
          isControlled
          label={questionContent.question}
          onChange={e => {
            onDateAnswerChange(fieldName, e);
            onChange(e);
          }}
          value={value}
          {...datePickerProps}
        />
      )}
      rules={validationRules}
    />
  );
};
