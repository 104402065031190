import { GetHomeOfficeDashboardContent, GetHomeOfficeDashboardContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetHomeOfficeDashboardContent = (
  options?: QueryHookOptions<GetHomeOfficeDashboardContent, GetHomeOfficeDashboardContentVariables>,
): QueryResult<GetHomeOfficeDashboardContent, GetHomeOfficeDashboardContentVariables> => {
  return useContentstackQuery(queries.GetHomeOfficeDashboardContent, options);
};
