import { GetHomeOfficeDashboardContent } from '../contentstack/__generated__/query.v2';
import {
  HomeOfficeColumnKey,
  HomeOfficeContent,
  HomeOfficeContentKeys,
  HomeOfficeFilterOptions,
  HomeOfficeFilterValues,
  HomeOfficeTableColumnConfig,
  HomeOfficeTableData,
  HomeOfficeTimeFrameOptions,
  HomeOfficeTimeFrameValues,
  MetricsIds,
} from '../types';

import { MetricValueFilterInput, OrderType } from '~/__generated__';
import { FilterTypes, RangeFilterConfig } from '~/components/Filters/types';
import { SortConfig, TableColumn } from '~/components/ui/BasicTable';
import { HomeOfficeView } from '~/containers/HomeOfficeDashboard/config';
import {
  GetAdvisorMetricValues_advisorMetricValues_advisorMetricValues as AdvisorMetricValues,
  GetAdvisorMetricValuesVariables,
} from '~/containers/HomeOfficeDashboard/symphony/__generated__/query.v2';
import { findFieldValue } from '~/utils/contentstack';
import { precise } from '~/utils/format/currency';
import { formatDate } from '~/utils/format/date';

export const DEFAULT_DISPLAY_VALUE = '-';

export const DEFAULT_SORT_CONFIG = {
  order: OrderType.DESCENDING,
  field: 'aumAsOfDate',
};

export const getFilterOptionsConfig = (
  filterOptionsContent: Record<HomeOfficeFilterOptions, string>,
  view: HomeOfficeView,
): RangeFilterConfig[] => {
  return [
    {
      key: HomeOfficeFilterOptions.AUM_AS_OF_DATE,
      label: filterOptionsContent[HomeOfficeFilterOptions.AUM_AS_OF_DATE],
      maxRangeValue: Number.MAX_SAFE_INTEGER,
      type: FilterTypes.CURRENCY_RANGE,
    } as RangeFilterConfig,
    {
      key: HomeOfficeFilterOptions.AUM_FOR_PERIOD,
      label: filterOptionsContent[HomeOfficeFilterOptions.AUM_FOR_PERIOD],
      maxRangeValue: Number.MAX_SAFE_INTEGER,
      type: FilterTypes.CURRENCY_RANGE,
    } as RangeFilterConfig,
    {
      key: HomeOfficeFilterOptions.ACCOUNTS_ACTIVE_AS_OF_DATE,
      label: filterOptionsContent[HomeOfficeFilterOptions.ACCOUNTS_ACTIVE_AS_OF_DATE],
      maxRangeValue: 99999,
      type: FilterTypes.NUMBER_RANGE,
    } as RangeFilterConfig,
    {
      key: HomeOfficeFilterOptions.ACCOUNTS_CLOSED_AS_OF_DATE,
      label: filterOptionsContent[HomeOfficeFilterOptions.ACCOUNTS_CLOSED_AS_OF_DATE],
      maxRangeValue: 99999,
      type: FilterTypes.NUMBER_RANGE,
    } as RangeFilterConfig,
    {
      key: HomeOfficeFilterOptions.ACCOUNTS_OPENED_IN_PERIOD,
      label: filterOptionsContent[HomeOfficeFilterOptions.ACCOUNTS_OPENED_IN_PERIOD],
      maxRangeValue: 99999,
      type: FilterTypes.NUMBER_RANGE,
    } as RangeFilterConfig,
    {
      key: HomeOfficeFilterOptions.ACCOUNTS_CLOSED_IN_PERIOD,
      label: filterOptionsContent[HomeOfficeFilterOptions.ACCOUNTS_CLOSED_IN_PERIOD],
      maxRangeValue: 99999,
      type: FilterTypes.NUMBER_RANGE,
    } as RangeFilterConfig,
    {
      key: HomeOfficeFilterOptions.ACCOUNTS_IN_PROGRESS_AS_OF_DATE,
      label: filterOptionsContent[HomeOfficeFilterOptions.ACCOUNTS_IN_PROGRESS_AS_OF_DATE],
      maxRangeValue: 99999,
      type: FilterTypes.NUMBER_RANGE,
    } as RangeFilterConfig,
  ].filter(filter => view.filters.find(vf => vf.toString() === filter.key));
};

export const getHomeOfficeContent = (
  content: GetHomeOfficeDashboardContent,
  homeOfficeView: {
    columns: HomeOfficeTableColumnConfig[];
  },
  onSort?: (field: string) => () => void,
): HomeOfficeContent => {
  const textContent = content.all_home_office_dashboard?.items?.[0]?.fields?.text ?? [];
  const rteContent = content.all_home_office_dashboard?.items?.[0]?.fields?.rte ?? [];

  const columnLabels: Record<HomeOfficeColumnKey, string> = {
    accountsActiveAsOfDate: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_COLUMN_ACCOUNTS_ACTIVE_AS_OF_DATE),
    accountsClosedAsOfDate: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_COLUMN_ACCOUNTS_CLOSED_AS_OF_DATE),
    accountsInProgressAsOfDate: findFieldValue(
      textContent,
      HomeOfficeContentKeys.TABLE_COLUMN_ACCOUNTS_IN_PROGRESS_AS_OF_DATE,
    ),
    advisorName: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_COLUMN_ADVISOR_NAME),
    aumAsOfDate: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_COLUMN_AUM_AS_OF_DATE),
    contactInformation: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_COLUMN_CONTACT_INFORMATION),
    aumForPeriod: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_COLUMN_AUM_FOR_PERIOD),
    accountsOpenedInPeriod: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_COLUMN_ACCOUNTS_OPENED_IN_PERIOD),
    accountsClosedInPeriod: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_COLUMN_ACCOUNTS_CLOSED_IN_PERIOD),
  };

  const optionLabels = {
    [HomeOfficeFilterOptions.ACCOUNTS_ACTIVE_AS_OF_DATE]: columnLabels.accountsActiveAsOfDate,
    [HomeOfficeFilterOptions.ACCOUNTS_CLOSED_AS_OF_DATE]: columnLabels.accountsClosedAsOfDate,
    [HomeOfficeFilterOptions.ACCOUNTS_IN_PROGRESS_AS_OF_DATE]: columnLabels.accountsInProgressAsOfDate,
    [HomeOfficeFilterOptions.AUM_AS_OF_DATE]: columnLabels.aumAsOfDate,
    [HomeOfficeFilterOptions.ACCOUNTS_CLOSED_IN_PERIOD]: columnLabels.accountsClosedInPeriod,
    [HomeOfficeFilterOptions.ACCOUNTS_OPENED_IN_PERIOD]: columnLabels.accountsOpenedInPeriod,
    [HomeOfficeFilterOptions.AUM_FOR_PERIOD]: columnLabels.aumForPeriod,
  };

  return {
    csvHeader: {
      advisorName: columnLabels.advisorName,
      aumAsOfDate: columnLabels.aumAsOfDate,
      accountsInProgressAsOfDate: columnLabels.accountsInProgressAsOfDate,
      accountsActiveAsOfDate: columnLabels.accountsActiveAsOfDate,
      accountsClosedAsOfDate: columnLabels.accountsClosedAsOfDate,
      aumForPeriod: columnLabels.aumForPeriod,
      accountsOpenedInPeriod: columnLabels.accountsOpenedInPeriod,
      accountsClosedInPeriod: columnLabels.accountsClosedInPeriod,
      changeInAumForPeriod: findFieldValue(textContent, HomeOfficeContentKeys.CSV_CHANGE_IN_AUM),
      email: findFieldValue(textContent, HomeOfficeContentKeys.CSV_EMAIL),
      phone: findFieldValue(textContent, HomeOfficeContentKeys.CSV_PHONE),
      repCode: findFieldValue(textContent, HomeOfficeContentKeys.CSV_REP_CODE),
    },
    timeFrameContent: {
      labels: {
        date: findFieldValue(textContent, HomeOfficeContentKeys.TIME_FRAME_LABEL_DATE),
        month: findFieldValue(textContent, HomeOfficeContentKeys.TIME_FRAME_LABEL_MONTH),
        quarter: findFieldValue(textContent, HomeOfficeContentKeys.TIME_FRAME_LABEL_QUARTER),
        year: findFieldValue(textContent, HomeOfficeContentKeys.TIME_FRAME_LABEL_YEAR),
      },
      placeHolders: {
        month: findFieldValue(textContent, HomeOfficeContentKeys.TIME_FRAME_DROPDOWN_PLACEHOLDER_MONTH),
        quarter: findFieldValue(textContent, HomeOfficeContentKeys.TIME_FRAME_DROPDOWN_PLACEHOLDER_QUARTER),
        year: findFieldValue(textContent, HomeOfficeContentKeys.TIME_FRAME_DROPDOWN_PLACEHOLDER_YEAR),
      },
      timeFrameLabel: findFieldValue(textContent, HomeOfficeContentKeys.TIME_FRAME_LABEL),
      timePeriodOptionLabels: {
        date: findFieldValue(textContent, HomeOfficeContentKeys.TIME_FRAME_OPTION_DATE),
        month: findFieldValue(textContent, HomeOfficeContentKeys.TIME_FRAME_OPTION_MONTH),
        quarter: findFieldValue(textContent, HomeOfficeContentKeys.TIME_FRAME_OPTION_QUARTER),
        year: findFieldValue(textContent, HomeOfficeContentKeys.TIME_FRAME_OPTION_YEAR),
      },
    },
    downloadCsv: findFieldValue(textContent, HomeOfficeContentKeys.DOWNLOAD_CSV_LABEL),
    filterContent: {
      allFilter: '',
      apply: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_FILTER_APPLY_TEXT),
      filters: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_FILTER_BUTTON_TEXT),
      maxValueLabel: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_FILTER_MAX_VALUE_TEXT),
      minValueLabel: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_FILTER_MIN_VALUE_TEXT),
      rangeFilterErrorMessage: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_FILTER_ERROR_MESSAGE),
      resetAll: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_FILTER_RESET_ALL_TEXT),
    },
    homeOfficeTableFilterOptionsContent: optionLabels,
    search: {
      emptyResults: findFieldValue(textContent, HomeOfficeContentKeys.SEARCH_NO_RESULTS),
      placeholder: findFieldValue(textContent, HomeOfficeContentKeys.SEARCH_PLACEHOLDER),
      repPrefix: findFieldValue(textContent, HomeOfficeContentKeys.SEARCH_REP_PREFIX),
    },
    table: {
      tableColumns: getColumnHeaders(homeOfficeView.columns, columnLabels, onSort),
      repCodeLabel: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_REP_CODE_LABEL),
      nullStateMessage: findFieldValue(textContent, HomeOfficeContentKeys.TABLE_NULL_STATE_MESSAGE),
    },
    title: findFieldValue(textContent, HomeOfficeContentKeys.TITLE),
    totalAccountsOpenedForDate: findFieldValue(rteContent, HomeOfficeContentKeys.TOTAL_ACCOUNTS_OPENED_FOR_DATE),
    totalAumForDate: findFieldValue(rteContent, HomeOfficeContentKeys.TOTAL_AUM_FOR_DATE),
    recordsPerPageLabel: findFieldValue(textContent, HomeOfficeContentKeys.RECORDS_PER_PAGE_LABEL),
    totalItemsLabel: findFieldValue(rteContent, HomeOfficeContentKeys.TOTAL_ITEMS_LABEL),
  };
};

const getColumnHeaders = (
  columnsConfig: HomeOfficeTableColumnConfig[],
  columnLabels: Record<HomeOfficeColumnKey, string>,
  onSort?: (field: string) => () => void,
): TableColumn[] =>
  columnsConfig.map(columnConfig => ({
    key: columnConfig.key,
    title: columnLabels[columnConfig.key],
    onSort: onSort && columnConfig.sortable ? onSort : undefined,
    wrappable: columnConfig.wrappable,
  }));

export const getTotalPages = ({ limit, total }: { limit: number | null; total: number | null }): number =>
  limit && total ? Math.ceil(total / limit) : 1;

export const mapDataToCsv = (
  data: HomeOfficeTableData[],
  keys: HomeOfficeColumnKey[],
  content: HomeOfficeContent,
): string => {
  const header = keys
    .map(key => {
      switch (key) {
        case 'advisorName':
          return `${content.csvHeader.advisorName},${content.csvHeader.repCode}`;
        case 'contactInformation':
          return `${content.csvHeader.phone},${content.csvHeader.email}`;
        case 'aumAsOfDate':
          return content.csvHeader.aumAsOfDate;
        case 'accountsActiveAsOfDate':
          return content.csvHeader.accountsActiveAsOfDate;
        case 'accountsInProgressAsOfDate':
          return content.csvHeader.accountsInProgressAsOfDate;
        case 'accountsClosedAsOfDate':
          return `${content.csvHeader.accountsClosedAsOfDate}`;
        case 'accountsClosedInPeriod':
          return `${content.csvHeader.accountsClosedInPeriod}`;
        case 'accountsOpenedInPeriod':
          return `${content.csvHeader.accountsOpenedInPeriod}`;
        case 'aumForPeriod':
          return `${content.csvHeader.aumForPeriod},${content.csvHeader.changeInAumForPeriod}`;
        default:
          return '';
      }
    })
    .join(',');

  const body = data.map(row =>
    keys
      .map(key => {
        switch (key) {
          case 'advisorName':
            return `${row.advisor.name},${row.advisor.repCode}`;
          case 'contactInformation':
            return `${row.contactData.phoneNumber},${row.contactData.email}`;
          case 'aumAsOfDate':
            return row.aum.value;
          case 'accountsActiveAsOfDate':
            return row.accountsData.activeAsOfDate;
          case 'accountsInProgressAsOfDate':
            return row.accountsData.inProgressAsOfDate;
          case 'accountsClosedAsOfDate':
            return row.accountsData.closedAsOfDate;
          case 'aumForPeriod':
            return `${row.aum.value},${row.aum.change}`;
          case 'accountsClosedInPeriod':
            return row.accountsData.closedInPeriod;
          case 'accountsOpenedInPeriod':
            return row.accountsData.openedInPeriod;
          default:
            return '';
        }
      })
      .join(','),
  );
  return [header, ...body].join('\n');
};

const getAumDataForCSV = (
  metricValues: { metricId: string; value: string }[] | undefined,
  totalAumMetricId: MetricsIds,
  totalAumForChangeMetricId: MetricsIds,
) => {
  const value = getMetricValueByMetricId(metricValues, totalAumMetricId) ?? '';

  const totalAumMetricsChangeValue = getMetricValueByMetricId(metricValues, totalAumForChangeMetricId);
  const change = totalAumMetricsChangeValue && value ? totalAumMetricsChangeValue : DEFAULT_DISPLAY_VALUE;

  return {
    change,
    value: parseFloat(value).toFixed(2),
  };
};

export const getRepCodeForParty = (
  partyExternalSystemIdentifiers: { name: string; value: string }[] | null | undefined,
  repCodeIdentifierName: string | undefined,
) => {
  return (
    repCodeIdentifierName &&
    partyExternalSystemIdentifiers?.find(
      identifier => identifier.name.toLowerCase() === repCodeIdentifierName.toLowerCase(),
    )?.value
  );
};

export const getMetricIdsByView = (view: HomeOfficeView): string[] => {
  const columnKeys = view.columns.map(column => column.key);
  return columnKeys.map(homeOfficeColumnKeyToMetricsTypeMapper).flat();
};

const homeOfficeColumnKeyToMetricsTypeMapper = (key: HomeOfficeColumnKey): string[] => {
  switch (key) {
    case 'accountsActiveAsOfDate':
      return [MetricsIds.AdvisorActiveAccountsCount];
    case 'accountsClosedAsOfDate':
      return [MetricsIds.AdvisorClosedAccountsCount];
    case 'accountsInProgressAsOfDate':
      return [MetricsIds.AdvisorAccountsInProgressCount];
    case 'accountsClosedInPeriod':
      return [MetricsIds.AdvisorAccountsClosedInPeriodCount];
    case 'accountsOpenedInPeriod':
      return [MetricsIds.AdvisorAccountsOpenedInPeriodCount];
    case 'aumAsOfDate':
      return [MetricsIds.AdvisorTotalAUM];
    case 'aumForPeriod':
      return [MetricsIds.AdvisorTotalAUM, MetricsIds.ChangeInAdvisorAUM];
    default:
      return [];
  }
};

export const homeOfficeFilterOptionToMetricsTypeMapper = (filterOption: HomeOfficeFilterOptions): string => {
  switch (filterOption) {
    case HomeOfficeFilterOptions.AUM_FOR_PERIOD:
      return MetricsIds.AdvisorTotalAUM;
    case HomeOfficeFilterOptions.ACCOUNTS_CLOSED_IN_PERIOD:
      return MetricsIds.AdvisorAccountsClosedInPeriodCount;
    case HomeOfficeFilterOptions.ACCOUNTS_ACTIVE_AS_OF_DATE:
      return MetricsIds.AdvisorActiveAccountsCount;
    case HomeOfficeFilterOptions.ACCOUNTS_CLOSED_AS_OF_DATE:
      return MetricsIds.AdvisorClosedAccountsCount;
    case HomeOfficeFilterOptions.ACCOUNTS_IN_PROGRESS_AS_OF_DATE:
      return MetricsIds.AdvisorAccountsInProgressCount;
    case HomeOfficeFilterOptions.ACCOUNTS_OPENED_IN_PERIOD:
      return MetricsIds.AdvisorAccountsOpenedInPeriodCount;
    case HomeOfficeFilterOptions.AUM_AS_OF_DATE:
      return MetricsIds.AdvisorTotalAUM;
    default:
      return '';
  }
};

export const getHomeOfficeTableData = (
  data: AdvisorMetricValues[] | undefined,
  forCSV = false,
): HomeOfficeTableData[] => {
  if (data) {
    return data.map(item => {
      const advisorData = item.party;
      const aum = forCSV
        ? getAumDataForCSV(item.metricValues, MetricsIds.AdvisorTotalAUM, MetricsIds.ChangeInAdvisorAUM)
        : getAumData(item.metricValues, MetricsIds.AdvisorTotalAUM, MetricsIds.ChangeInAdvisorAUM);

      return {
        id: item.partyId,
        aum,
        advisor: {
          id: item.partyId,
          repCode: advisorData.repCode ?? '',
          name: advisorData.name,
        },
        accountsData: {
          closedAsOfDate: formatMetricValueAsInteger(
            getMetricValueByMetricId(item.metricValues, MetricsIds.AdvisorClosedAccountsCount),
          ),
          inProgressAsOfDate: formatMetricValueAsInteger(
            getMetricValueByMetricId(item.metricValues, MetricsIds.AdvisorAccountsInProgressCount),
          ),
          activeAsOfDate: formatMetricValueAsInteger(
            getMetricValueByMetricId(item.metricValues, MetricsIds.AdvisorActiveAccountsCount),
          ),
          openedInPeriod: formatMetricValueAsInteger(
            getMetricValueByMetricId(item.metricValues, MetricsIds.AdvisorAccountsOpenedInPeriodCount),
          ),
          closedInPeriod: formatMetricValueAsInteger(
            getMetricValueByMetricId(item.metricValues, MetricsIds.AdvisorAccountsClosedInPeriodCount),
          ),
        },
        contactData: {
          email: advisorData.email ?? '',
          phoneNumber: advisorData.phoneNumber ?? '',
        },
      };
    });
  }
  return [];
};

export const getMetricValueByMetricId = (
  metricValues: { metricId: string; value: string }[] | undefined,
  metricId: MetricsIds,
): string | undefined => {
  return metricValues?.find(metric => metric.metricId === metricId)?.value;
};

const getFilterVariableInputs = (
  filterValues: HomeOfficeFilterValues,
  view: HomeOfficeView,
): MetricValueFilterInput[] =>
  view.filters
    .map(key => {
      const filterValue = filterValues[key];
      const metricId = homeOfficeFilterOptionToMetricsTypeMapper(key);
      return {
        metricId,
        max: filterValue?.maxValue.toString() ?? '',
        min: filterValue?.minValue.toString() ?? '',
      };
    })
    .filter(obj => !!(obj.max && obj.min));

export const getAdvisorMetricValuesVariables = ({
  selectedParty,
  filterValues,
  view,
  currentPage,
  recordsPerPage,
  sortConfig,
}: {
  currentPage?: number;
  filterValues: HomeOfficeFilterValues;
  recordsPerPage?: number;
  selectedParty?: string | null;
  sortConfig: SortConfig;
  view: HomeOfficeView;
}): GetAdvisorMetricValuesVariables => {
  const metricIds = getMetricIdsByView(view);
  const valueFilters = getFilterVariableInputs(filterValues, view);

  const variables: GetAdvisorMetricValuesVariables = {
    metricIds,
    valueFilters,
    partyIds: selectedParty ? [selectedParty] : [],
    metricSort: {
      order: sortConfig.order,
      metricId: MetricsIds.AdvisorTotalAUM,
    },
  };

  if (currentPage && recordsPerPage) {
    variables.pagination = {
      limit: recordsPerPage,
      offset: (currentPage - 1) * recordsPerPage,
    };
  }

  return variables;
};

export const getAumData = (
  metricValues: { metricId: string; value: string }[] | undefined,
  totalAumMetricId: MetricsIds,
  totalAumForChangeMetricId: MetricsIds,
) => {
  const totalAumMetricsValue = getMetricValueByMetricId(metricValues, totalAumMetricId);
  const value = totalAumMetricsValue ? precise(parseFloat(totalAumMetricsValue)) : DEFAULT_DISPLAY_VALUE;

  const totalAumMetricsChangeValue = getMetricValueByMetricId(metricValues, totalAumForChangeMetricId);
  const totalAumMetricsChange =
    totalAumMetricsChangeValue && totalAumMetricsValue ? precise(parseFloat(totalAumMetricsChangeValue)) : undefined;

  return {
    change: totalAumMetricsChange,
    isPositive: totalAumMetricsChangeValue ? parseInt(totalAumMetricsChangeValue, 10) > 0 : undefined,
    value,
  };
};

export const isDateView = (timeFrameValues?: HomeOfficeTimeFrameValues) =>
  timeFrameValues && timeFrameValues.selectedTimeFrame === HomeOfficeTimeFrameOptions.date;

export const getPartnerMetricRteAttributes = (
  content: HomeOfficeContent,
  timeFrameValues: HomeOfficeTimeFrameValues,
) => {
  const config = { timeFrame: formatDate(timeFrameValues.endDate, 'MM/dd/yyyy') };

  return {
    totalAccountsOpenedRteParams: {
      config,
      label: content.totalAccountsOpenedForDate,
    },
    totalAumRteParams: {
      config,
      label: content.totalAumForDate,
    },
  };
};

/**
 * Formats the given metric value.
 *
 * If the value is defined, it parses the value to an integer and converts it back to a string.
 * If the value is undefined, it returns a default value indicating undefined metrics.
 *
 * @param metricValue - The value to format.
 * @returns The formatted value.
 */
export const formatMetricValueAsInteger = (metricValue: string | undefined) =>
  metricValue ? parseInt(metricValue, 10).toString() : DEFAULT_DISPLAY_VALUE;
