import React, { useMemo } from 'react';

import { Divider } from '~/components/ui/Divider';
import { Donut } from '~/components/ui/Donut';
import { Link } from '~/components/ui/Link';
import { Button, Card, Grid, SxProps, Theme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { getStockBondRatio } from '~/containers/Plan/utils';
import { AssetAllocationModalState } from '~/containers/PortfolioSelection/types';
import { ComparisonData } from '~/hooks/comparison/usePortfolioComparison';
import { allocationToDonutSlice } from '~/utils/asset-allocation';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { formatPercentage } from '~/utils/format';

export interface KeepCurrentPortfolioContent {
  assetAllocationLabel: string;
  currentPortfolioLabel: string;
  keepCurrentPortfolioCta: string;
  message: string;
  viewAssetAllocation: string;
}

interface Props {
  assetClassTier?: AssetClassTier;
  content: KeepCurrentPortfolioContent;
  currentPortfolioData: ComparisonData;
  dataQa?: string;
  openAssetAllocationModal: (assetAllocationProps: AssetAllocationModalState) => void;
  openConfirmationModal: () => void;
  viewAssetAllocationLinkStyle?: SxProps<Theme>;
}

export const KeepCurrentPortfolio: React.FC<Props> = ({
  assetClassTier,
  currentPortfolioData,
  dataQa = 'keep-current-portfolio',
  content,
  viewAssetAllocationLinkStyle,
  openConfirmationModal,
  openAssetAllocationModal,
}) => {
  // TODO: Convert this to pure component https://sigfig.atlassian.net/browse/DA2-5895
  const { comparisonContent, currentAssetAllocationData, currentModelPortfolioData } = currentPortfolioData;

  const allocation = currentAssetAllocationData.map(allocationToDonutSlice(assetClassTier));
  const assetAllocationContent = useMemo(() => {
    return {
      assetAllocation: comparisonContent?.modal_title ?? '',
      close: comparisonContent?.close_modal_text ?? '',
    };
  }, [comparisonContent]);

  const handleOpenAssetAllocationModal = (index: number): AssetAllocationModalState => {
    const sleeveAssetAllocations = currentModelPortfolioData.modelPortfolios?.[index]?.sleeveAssetAllocations;
    const sleeveStockBondDiversification =
      currentModelPortfolioData.modelPortfolios?.[index]?.sleeveStockBondDiversification;
    const stockBondRatio = sleeveStockBondDiversification ? getStockBondRatio(sleeveStockBondDiversification) : '';
    const portfolioAssetsBreakdown = sleeveAssetAllocations?.map(allocationToDonutSlice(assetClassTier)) ?? [];

    return {
      content: assetAllocationContent,
      portfolio: {
        assetAllocationData: sleeveAssetAllocations ?? [],
        description: currentModelPortfolioData.modelPortfolios?.[index]?.description ?? '',
        name: currentModelPortfolioData.modelPortfolios?.[index]?.modelPortfolioName ?? '',
        portfolioAssetsBreakdown: currentModelPortfolioData.assetAllocation.map(allocationToDonutSlice(assetClassTier)),
        stockBondRatio: sleeveStockBondDiversification ? getStockBondRatio(sleeveStockBondDiversification) : '',
      },
      donutProps: {
        centerSubtext: comparisonContent?.stock_bond_label ?? '',
        centerText: stockBondRatio,
        data: portfolioAssetsBreakdown,
        innerRadius: 75,
        justifyContentGraph: 'center',
        showTooltip: true,
        size: 275,
      },
    };
  };

  return (
    <>
      <Card
        data-qa={dataQa}
        sx={{
          boxSizing: 'border-box',
          width: '100%',
          py: 2,
          px: 4,
          my: 4,
        }}
        variant="outlined"
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography sx={{ mt: 2 }}>{content.message}</Typography>
          </Grid>
          <Grid item textAlign="end" xs={4}>
            <Button onClick={openConfirmationModal} variant="contained">
              {content.keepCurrentPortfolioCta}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider flexItem />
          </Grid>
          <Grid item ml={-2} mr={3} px={0} xs={1}>
            <Donut data={allocation} innerRadius={20} size={100} />
          </Grid>
          {currentModelPortfolioData.modelPortfolios && (
            <Grid item xs={10}>
              <Grid container mt={currentModelPortfolioData.modelPortfolios.length > 1 ? 1 : 3}>
                <Grid item xs={10}>
                  <Typography variant="subtitle2">{content.currentPortfolioLabel}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">{content.assetAllocationLabel}</Typography>
                </Grid>
                {currentModelPortfolioData.modelPortfolios.map((el, index) => {
                  return (
                    <React.Fragment key={`${el.seriesId}.${el.internalId}`}>
                      <Grid item xs={10}>
                        <Typography>
                          {currentModelPortfolioData.modelPortfolios &&
                          currentModelPortfolioData.modelPortfolios.length > 1
                            ? `${el.modelPortfolioName} - ${formatPercentage(parseFloat(el.percentage ?? '0') / 100, {
                                decimals: 0,
                                removeTrailingZeroes: true,
                              })}`
                            : el.modelPortfolioName}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Link
                          aria-haspopup="true"
                          onClick={() => openAssetAllocationModal(handleOpenAssetAllocationModal(index))}
                          sx={viewAssetAllocationLinkStyle}
                        >
                          {content.viewAssetAllocation}
                        </Link>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  );
};
