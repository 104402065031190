import React, { FC } from 'react';

import { MarketReviewContent } from '../types';

import { Button, Grid, OpenInNewIcon, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

export interface Props {
  content: MarketReviewContent;
  dataQa?: string;
  monthlyReviewURL: string;
}

export const MarketReview: FC<Props> = ({ content, dataQa = 'market-review', monthlyReviewURL }) => {
  const {
    sfAccountPerformance: { styles, typographyVariants },
  } = useTheme();

  return (
    <Grid container data-qa={dataQa} id="marketReview" sx={{ ...styles.container, justifyContent: 'space-between' }}>
      <Grid item>
        <Typography color="text.primary" variant={typographyVariants?.marketReviewTitle}>
          {content.labels.title}
        </Typography>
        <Typography color="text.secondary" variant={typographyVariants?.marketReviewSubtitle}>
          {content.labels.subTitle}
        </Typography>
      </Grid>
      <Grid item>
        <Button href={monthlyReviewURL} target="_blank" variant="outlined">
          {content.ctas.viewDetails} <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
        </Button>
      </Grid>
    </Grid>
  );
};
