import React, { ComponentProps } from 'react';

import { Grid, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { SfTheme } from '~/utils/theme';

export interface OnboardingPageHeaderProps extends Omit<ComponentProps<typeof Grid>, 'title'> {
  dataQa?: string;
  headerIcon?: string;
  title?: React.ReactNode;
}

export const OnboardingPageHeader: React.FC<OnboardingPageHeaderProps> = ({
  dataQa = 'onboarding-header',
  headerIcon,
  title,
  ...rest
}) => {
  const {
    sfOnboardingPageHeader: { styles, typographyVariants },
  } = useTheme<SfTheme>();

  return (
    <Grid data-qa={dataQa} sx={styles.container} {...rest}>
      {headerIcon && (
        <Grid
          alt="header-icon"
          aria-hidden
          component="img"
          data-qa={`${dataQa}-icon`}
          src={headerIcon}
          sx={styles.icon}
        />
      )}
      {React.isValidElement(title) ? (
        title
      ) : (
        <Typography component="h1" data-qa={`${dataQa}-title`} sx={styles.title} variant={typographyVariants?.title}>
          {title}
        </Typography>
      )}
    </Grid>
  );
};
