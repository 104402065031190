import { isFuture, isPast, isToday } from 'date-fns';

import { getDropdownItemsFromCms } from '../../Section/Question/utils';
import { CmsKeys, ComponentTypes, QuestionOrderSteps } from '../../Section/types';
import { getFieldName } from '../../Section/utils';
import { isValidIdentificationNumber } from '../../Section/validators';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';
import {
  commonDefaultDateQuestionProps,
  commonDefaultDateValidations,
  filterValidationRules,
  getPaperworkDataForPrefill,
  getPaperworkDateFieldValueAsIsoString,
  getPaperworkStringFieldValue,
  isMasked,
  requiredValidation,
} from './utils';

import { IdentifierType } from '~/__generated__';

export const drivingLicenseExpiryDate = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.DRIVING_LICENSE_EXPIRY_DATE,
    dataPointKey: DataPointKeys.DRIVING_LICENSE_EXPIRY_DATE,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.DRIVING_LICENSE,
      );
      return identifier?.identifierExpiry;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const identifier = paperworkInput.party.identifiers?.find(i => i.type === IdentifierType.DRIVING_LICENSE);
      const expiryValue = getPaperworkDateFieldValueAsIsoString(
        data.formValues[getFieldName(DataPointKeys.DRIVING_LICENSE_EXPIRY_DATE, paperworkFreeFormId)],
      );
      if (identifier) {
        identifier.identifierExpiry = expiryValue;
      } else {
        paperworkInput.party.identifiers?.push({
          type: IdentifierType.DRIVING_LICENSE,
          identifierExpiry: expiryValue,
        });
      }
    },
    componentType: ComponentTypes.Date,
    adjacent,
    rules,
    dateComponentProps: {
      ...commonDefaultDateQuestionProps,
      datePickerProps: {
        ...commonDefaultDateQuestionProps.datePickerProps,
        disablePast: true,
      },
    },
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            ...commonDefaultDateValidations,
            isPastDateDisabled: value => isFuture(value) || isToday(value),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const drivingLicenseIdentificationNumber = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.DRIVING_LICENSE_IDENTIFICATION_NUMBER,
    dataPointKey: DataPointKeys.DRIVING_LICENSE_IDENTIFICATION_NUMBER,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.DRIVING_LICENSE,
      );
      return identifier?.identifierValue;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const identifier = paperworkInput.party.identifiers?.find(i => i.type === IdentifierType.DRIVING_LICENSE);
      const idNumberValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.DRIVING_LICENSE_IDENTIFICATION_NUMBER, paperworkFreeFormId)],
      )?.toUpperCase();
      if (identifier) {
        identifier.identifierValue = idNumberValue;
      } else {
        paperworkInput.party.identifiers?.push({
          type: IdentifierType.DRIVING_LICENSE,
          identifierValue: idNumberValue,
        });
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps: {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z0-9]/g),
      formattingRules: { capitalize: true },
    },
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          pattern: new RegExp(/^[a-zA-Z0-9]*$/),
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const drivingLicenseIssueDate = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.DRIVING_LICENSE_ISSUE_DATE,
    dataPointKey: DataPointKeys.DRIVING_LICENSE_ISSUE_DATE,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.DRIVING_LICENSE,
      );
      return identifier?.identifierIssuance;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const identifier = paperworkInput.party.identifiers?.find(i => i.type === IdentifierType.DRIVING_LICENSE);
      const issuanceValue = getPaperworkDateFieldValueAsIsoString(
        data.formValues[getFieldName(DataPointKeys.DRIVING_LICENSE_ISSUE_DATE, paperworkFreeFormId)],
      );
      if (identifier) {
        identifier.identifierIssuance = issuanceValue;
      } else {
        paperworkInput.party.identifiers?.push({
          type: IdentifierType.DRIVING_LICENSE,
          identifierIssuance: issuanceValue,
        });
      }
    },
    componentType: ComponentTypes.Date,
    adjacent,
    rules,
    dateComponentProps: {
      ...commonDefaultDateQuestionProps,
      datePickerProps: {
        ...commonDefaultDateQuestionProps.datePickerProps,
        disableFuture: true,
      },
    },
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            ...commonDefaultDateValidations,
            isFutureDateDisabled: value => isPast(value) || isToday(value),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const drivingLicenseState = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.DRIVING_LICENSE_STATE,
    cmsKey: CmsKeys.States,
    dataPointKey: DataPointKeys.DRIVING_LICENSE_STATE,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.DRIVING_LICENSE,
      );
      return identifier?.identifierJurisdiction;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const identifier = paperworkInput.party.identifiers?.find(i => i.type === IdentifierType.DRIVING_LICENSE);
      const stateValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.DRIVING_LICENSE_STATE, paperworkFreeFormId)],
      );
      if (identifier) {
        identifier.identifierJurisdiction = stateValue;
      } else {
        paperworkInput.party.identifiers?.push({
          type: IdentifierType.DRIVING_LICENSE,
          identifierJurisdiction: stateValue,
        });
      }
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const identificationType = ({
  additionalValidations,
  adjacent,
  allowedOptions,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.IDENTIFICATION_TYPE,
    dataPointKey: DataPointKeys.IDENTIFICATION_TYPE,
    prefillValueGetter: data => {
      return (
        getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.map(id => id.type.toString()) ?? []
      );
    },
    paperworkInputSetter: (_paperworkInput, _data) => undefined,
    componentType: ComponentTypes.Dropdown,
    defaultValue: 'PASSPORT',
    dropdownItemsGetter: (_data, content) => {
      return getDropdownItemsFromCms(content.questionContent, undefined, undefined, undefined, allowedOptions);
    },
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const otherIdentificationExpiryDate = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.OTHER_IDENTIFICATION_EXPIRY_DATE,
    dataPointKey: DataPointKeys.OTHER_IDENTIFICATION_EXPIRY_DATE,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.OTHER,
      );
      return identifier?.identifierExpiry;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const identifier = paperworkInput.party.identifiers?.find(i => i.type === IdentifierType.OTHER);
      const expiryValue = getPaperworkDateFieldValueAsIsoString(
        data.formValues[getFieldName(DataPointKeys.OTHER_IDENTIFICATION_EXPIRY_DATE, paperworkFreeFormId)],
      );
      if (identifier) {
        identifier.identifierExpiry = expiryValue;
      } else {
        paperworkInput.party.identifiers?.push({
          type: IdentifierType.OTHER,
          identifierExpiry: expiryValue,
        });
      }
    },
    componentType: ComponentTypes.Date,
    adjacent,
    rules,
    dateComponentProps: {
      ...commonDefaultDateQuestionProps,
      datePickerProps: {
        ...commonDefaultDateQuestionProps.datePickerProps,
        disablePast: true,
      },
    },
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            ...commonDefaultDateValidations,
            isPastDateDisabled: value => isFuture(value) || isToday(value),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const otherIdentificationIssueDate = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.OTHER_IDENTIFICATION_ISSUE_DATE,
    dataPointKey: DataPointKeys.OTHER_IDENTIFICATION_ISSUE_DATE,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.OTHER,
      );
      return identifier?.identifierIssuance;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const identifier = paperworkInput.party.identifiers?.find(i => i.type === IdentifierType.OTHER);
      const issuanceValue = getPaperworkDateFieldValueAsIsoString(
        data.formValues[getFieldName(DataPointKeys.OTHER_IDENTIFICATION_ISSUE_DATE, paperworkFreeFormId)],
      );
      if (identifier) {
        identifier.identifierIssuance = issuanceValue;
      } else {
        paperworkInput.party.identifiers?.push({
          type: IdentifierType.OTHER,
          identifierIssuance: issuanceValue,
        });
      }
    },
    componentType: ComponentTypes.Date,
    adjacent,
    rules,
    dateComponentProps: {
      ...commonDefaultDateQuestionProps,
      datePickerProps: {
        ...commonDefaultDateQuestionProps.datePickerProps,
        disableFuture: true,
      },
    },
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            ...commonDefaultDateValidations,
            isFutureDateDisabled: value => isPast(value) || isToday(value),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const otherIdentificationNumber = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.OTHER_IDENTIFICATION_NUMBER,
    dataPointKey: DataPointKeys.OTHER_IDENTIFICATION_NUMBER,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.OTHER,
      );
      return identifier?.identifierValue;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const identifier = paperworkInput.party.identifiers?.find(i => i.type === IdentifierType.OTHER);
      const idNumberValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.OTHER_IDENTIFICATION_NUMBER, paperworkFreeFormId)],
      )?.toUpperCase();
      if (identifier) {
        identifier.identifierValue = idNumberValue;
      } else {
        paperworkInput.party.identifiers?.push({
          type: IdentifierType.OTHER,
          identifierValue: idNumberValue,
        });
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps: {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z0-9]/g),
      formattingRules: { capitalize: true },
    },
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          pattern: new RegExp(/^[a-zA-Z0-9]*$/),
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const passportCountry = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.PASSPORT_COUNTRY,
    cmsKey: CmsKeys.Countries,
    dataPointKey: DataPointKeys.PASSPORT_COUNTRY,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.PASSPORT,
      );
      return identifier?.identifierCountry;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const identifier = paperworkInput.party.identifiers?.find(i => i.type === IdentifierType.PASSPORT);
      const countryValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.PASSPORT_COUNTRY, paperworkFreeFormId)],
      );
      if (identifier) {
        identifier.identifierCountry = countryValue;
      } else {
        paperworkInput.party.identifiers?.push({
          type: IdentifierType.PASSPORT,
          identifierCountry: countryValue,
        });
      }
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const passportExpiryDate = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.PASSPORT_EXPIRY_DATE,
    dataPointKey: DataPointKeys.PASSPORT_EXPIRY_DATE,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.PASSPORT,
      );
      return identifier?.identifierExpiry;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const identifier = paperworkInput.party.identifiers?.find(i => i.type === IdentifierType.PASSPORT);
      const expiryValue = getPaperworkDateFieldValueAsIsoString(
        data.formValues[getFieldName(DataPointKeys.PASSPORT_EXPIRY_DATE, paperworkFreeFormId)],
      );
      if (identifier) {
        identifier.identifierExpiry = expiryValue;
      } else {
        paperworkInput.party.identifiers?.push({
          type: IdentifierType.PASSPORT,
          identifierExpiry: expiryValue,
        });
      }
    },
    componentType: ComponentTypes.Date,
    adjacent,
    rules,
    dateComponentProps: {
      ...commonDefaultDateQuestionProps,
      datePickerProps: {
        ...commonDefaultDateQuestionProps.datePickerProps,
        disablePast: true,
      },
    },
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            ...commonDefaultDateValidations,
            isPastDateDisabled: value => isFuture(value) || isToday(value),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const passportIdentificationNumber = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.PASSPORT_IDENTIFICATION_NUMBER,
    dataPointKey: DataPointKeys.PASSPORT_IDENTIFICATION_NUMBER,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.PASSPORT,
      );
      return identifier?.identifierValue;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const identifier = paperworkInput.party.identifiers?.find(i => i.type === IdentifierType.PASSPORT);
      const idNumberValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.PASSPORT_IDENTIFICATION_NUMBER, paperworkFreeFormId)],
      )?.toUpperCase();
      if (identifier) {
        identifier.identifierValue = idNumberValue;
      } else {
        paperworkInput.party.identifiers?.push({
          type: IdentifierType.PASSPORT,
          identifierValue: idNumberValue,
        });
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps: {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z0-9]/g),
      formattingRules: { capitalize: true },
    },
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          pattern: new RegExp(/^[a-zA-Z0-9]*$/),
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const passportIssueDate = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.PASSPORT_ISSUE_DATE,
    dataPointKey: DataPointKeys.PASSPORT_ISSUE_DATE,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.PASSPORT,
      );
      return identifier?.identifierIssuance;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const identifier = paperworkInput.party.identifiers?.find(i => i.type === IdentifierType.PASSPORT);
      const issuanceValue = getPaperworkDateFieldValueAsIsoString(
        data.formValues[getFieldName(DataPointKeys.PASSPORT_ISSUE_DATE, paperworkFreeFormId)],
      );
      if (identifier) {
        identifier.identifierIssuance = issuanceValue;
      } else {
        paperworkInput.party.identifiers?.push({
          type: IdentifierType.PASSPORT,
          identifierIssuance: issuanceValue,
        });
      }
    },
    componentType: ComponentTypes.Date,
    adjacent,
    rules,
    dateComponentProps: {
      ...commonDefaultDateQuestionProps,
      datePickerProps: {
        ...commonDefaultDateQuestionProps.datePickerProps,
        disableFuture: true,
      },
    },
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            ...commonDefaultDateValidations,
            isFutureDateDisabled: value => isPast(value) || isToday(value),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const taxReportingType = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.TAX_REPORTING_TYPE,
    dataPointKey: DataPointKeys.TAX_REPORTING_TYPE,
    prefillValueGetter: data => {
      return (
        getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.map(id => id.type.toString()) ?? []
      );
    },
    paperworkInputSetter: (_paperworkInput, _data) => undefined,
    componentType: ComponentTypes.Radio,
    defaultValue: 'SSN',
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const ssn = ({
  additionalValidations,
  adjacent,
  disabledIfPrefilled,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.SSN,
    dataPointKey: DataPointKeys.SSN,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.SSN,
      );
      return identifier?.identifierValue;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const identifier = paperworkInput.party.identifiers?.find(i => i.type === IdentifierType.SSN);
      let idNumberValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.SSN, paperworkFreeFormId)],
      );
      if (isMasked(idNumberValue)) {
        idNumberValue = data.unmaskedValues[getFieldName(DataPointKeys.SSN, paperworkFreeFormId)];
      }
      if (identifier) {
        identifier.identifierValue = idNumberValue;
      } else {
        paperworkInput.party.identifiers?.push({
          type: IdentifierType.SSN,
          identifierValue: idNumberValue,
        });
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    disabledIfPrefilled,
    inputComponentProps: {
      invalidCharacterRegex: new RegExp(/[^*\d]/g),
      formattingRules: {
        masked: {
          charsToShow: 4,
          maskChar: '*',
        },
      },
      textFieldProps: {
        onFocus: e => {
          if (e.target.value.includes('*')) {
            e.preventDefault();
            e.target.select();
          }
        },
      },
    },
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          maxLength: 9,
          minLength: 9,
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            isSsn: value => (value ? isValidIdentificationNumber(value) : true),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const tin = ({
  additionalValidations,
  adjacent,
  disabledIfPrefilled,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.TIN,
    dataPointKey: DataPointKeys.TIN,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.TIN,
      );
      return identifier?.identifierValue;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const identifier = paperworkInput.party.identifiers?.find(i => i.type === IdentifierType.TIN);
      let idNumberValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.TIN, paperworkFreeFormId)],
      );
      if (isMasked(idNumberValue)) {
        idNumberValue = data.unmaskedValues[getFieldName(DataPointKeys.TIN, paperworkFreeFormId)];
      }
      if (identifier) {
        identifier.identifierValue = idNumberValue;
      } else {
        paperworkInput.party.identifiers?.push({
          type: IdentifierType.TIN,
          identifierValue: idNumberValue,
        });
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    disabledIfPrefilled,
    inputComponentProps: {
      invalidCharacterRegex: new RegExp(/[^*\d]/g),
      formattingRules: {
        masked: {
          charsToShow: 4,
          maskChar: '*',
        },
      },
      textFieldProps: {
        onFocus: e => {
          if (e.target.value.includes('*')) {
            e.preventDefault();
            e.target.select();
          }
        },
      },
    },
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          maxLength: 9,
          minLength: 9,
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            isTin: value => (value ? isValidIdentificationNumber(value) : true),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};
