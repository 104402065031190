/**
 * Abstracted nested ternary
 * @param isPercentageAdvancedPricing if true no normalization
 * @param discountUnit suffix to append to input box if present
 * @returns {string}
 */
export const getNormalizedDiscountUnit = (isPercentageAdvancedPricing?: boolean, discountUnit?: string) => {
  if (isPercentageAdvancedPricing) {
    return '%';
  }
  return discountUnit ? ` ${discountUnit}` : '';
};
