import {
  differenceInDays,
  endOfDay,
  endOfMonth,
  endOfYesterday,
  startOfDay,
  startOfMonth,
  startOfYear,
  startOfYesterday,
} from 'date-fns';
import { range } from 'fp-ts/lib/Array';

import { HomeOfficeTimeFrameLabels, HomeOfficeTimeFrameOptions, HomeOfficeTimeFrameValues } from '../types';

import { DropdownItem } from '~/components/ui/Dropdown/types';
import { getCompletedMonthOptionsForYear as getCompletedMonthsForYear } from '~/utils/dropdown';

type YearDropdownOptionsRecord = {
  optionsForYear: Record<number, DropdownItem<number>[]>;
  years: DropdownItem<number>[];
};

export interface HomeOfficeDropdownOptions {
  [HomeOfficeTimeFrameOptions.year]: DropdownItem<number>[];
  [HomeOfficeTimeFrameOptions.month]: YearDropdownOptionsRecord;
  [HomeOfficeTimeFrameOptions.quarter]: YearDropdownOptionsRecord;
}

export interface DropDownOptions {
  month: DropdownItem<number>[];
  quarter: DropdownItem<number>[];
  year: DropdownItem<number>[];
}

export interface HomeOfficeTimeFrameState {
  selectedDate?: Date;
  selectedMonth?: number;
  selectedQuarter?: number;
  selectedTimeFrame: HomeOfficeTimeFrameOptions;
  selectedYear?: number;
}

const MAX_NUMBER_OF_YEARS = 3;

export const defaultTimeFrameValues: HomeOfficeTimeFrameValues = {
  selectedTimeFrame: HomeOfficeTimeFrameOptions.date,
  startDate: startOfYesterday(),
  endDate: endOfYesterday(),
};

/**
 * Generates options for home office time period dropdown.
 * @note - A timeFrame option for which data is not available is not included in the dropdown.
 * @param {HomeOfficeTimeFrameLabels} labels - Labels for different time frame options.
 * @param {HomeOfficeDropdownOptions} dropdownOptions - Pre-computed list of dropdown options.
 * @returns {DropdownItem[]} - Dropdown options.
 */
export const getTimeFrameOptions = (
  labels: HomeOfficeTimeFrameLabels,
  dropdownOptions: HomeOfficeDropdownOptions,
): DropdownItem[] => {
  const timeFrameOptions = [
    {
      label: labels[HomeOfficeTimeFrameOptions.date],
      value: HomeOfficeTimeFrameOptions.date,
    },
  ];

  if (dropdownOptions[HomeOfficeTimeFrameOptions.month].years.length) {
    timeFrameOptions.push({
      label: labels[HomeOfficeTimeFrameOptions.month],
      value: HomeOfficeTimeFrameOptions.month,
    });
  }

  if (dropdownOptions[HomeOfficeTimeFrameOptions.quarter].years.length) {
    timeFrameOptions.push({
      label: labels[HomeOfficeTimeFrameOptions.quarter],
      value: HomeOfficeTimeFrameOptions.quarter,
    });
  }

  if (dropdownOptions[HomeOfficeTimeFrameOptions.year].length > 0) {
    timeFrameOptions.push({
      label: labels[HomeOfficeTimeFrameOptions.year],
      value: HomeOfficeTimeFrameOptions.year,
    });
  }
  return timeFrameOptions;
};

/**
 * Generates a list of DropdownItems for the last N years.<br>
 * Depending on the second parameter, the current year can be excluded
 *
 * @param {number} maxNumberOfYears - Maximum Number of years to generate options for.
 * @param {Date | null} initiationDate - The first date that can be accessed using the filters
 * @param {boolean} [includeInitiationYear=false] - Whether to include the current year.
 * @param {boolean} [includeCurrentYear=false] - Whether to include the current year.
 * @returns {DropdownItem<number>[]} - Dropdown options for years.
 */
const getLastNYears = (
  maxNumberOfYears: number,
  initiationDate: Date | null,
  includeInitiationYear: boolean,
  includeCurrentYear?: boolean,
): DropdownItem<number>[] => {
  const currentYear = new Date().getFullYear();
  const initiationYear = initiationDate?.getFullYear();

  // Calculate the range of years
  const latestYear = currentYear - 1;
  let earliestYear = latestYear - maxNumberOfYears + 1;

  if (initiationDate && initiationYear && earliestYear < initiationYear) {
    const isInitiationYearIncluded =
      includeInitiationYear || differenceInDays(initiationDate, startOfYear(initiationDate)) === 0;
    earliestYear = Math.max(earliestYear, initiationYear + (isInitiationYearIncluded ? 0 : 1));
  }
  // Generate the years
  const years = range(earliestYear, latestYear);

  if (includeCurrentYear) {
    years.push(currentYear);
  }
  // Create the dropdown items
  return years.reverse().map(year => ({ label: year.toString(), value: year }));
};

/**
 * Generates a list of completed months for a given year.
 *
 * @param {number} selectedYear - The selected year.
 * @param {Date} initiationDate - The first date that can be accessed using the filters
 * @returns {DropdownItem<number>[]} - Dropdown options for completed months.
 */
const getCompletedMonthDropdownOptionsForYear = (
  selectedYear: number,
  initiationDate: Date | null,
): DropdownItem<number>[] => {
  const initiationYear = initiationDate?.getFullYear();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const months = getCompletedMonthsForYear(selectedYear) as DropdownItem<number>[];
  if ((initiationYear && selectedYear < initiationYear) || selectedYear > currentYear) {
    // Future year is given Or Year before initiation Year is given. None of the months are completed.
    return [];
  }
  if (initiationDate && selectedYear === initiationYear) {
    const initiationMonth = initiationDate.getMonth();
    const startMonth =
      differenceInDays(initiationDate, startOfMonth(initiationDate)) === 0 ? initiationMonth : initiationMonth + 1;

    return months.filter(month => month.value >= startMonth);
  }

  return months;
};

/**
 * Converts a time frame value to a state object for managing the home office date filter.
 * @note startDate is used to get the selectedYear / selectedMonth / selectedQuarter.
 * @param {HomeOfficeTimeFrameValues} timeFrameValue - The time frame value Object to convert.
 * @returns {HomeOfficeTimeFrameState}
 *
 * @example
 * // Example usage:
 * const timeFrameValue = {
 *   selectedTimeFrame: HomeOfficeTimeFrameOptions.month,
 *   startDate: new Date(2022, 4, 15), // May 15, 2022
 * };
 * const timeFrameState = getHomeOfficeTimeFrameStateFromValue(timeFrameValue);
 * timeFrameState: { selectedTimeFrame: HomeOfficeTimeFrameOptions.month, selectedYear: 2022, selectedMonth: 4 }
 */
export const getHomeOfficeTimeFrameStateFromValue = (
  timeFrameValue: HomeOfficeTimeFrameValues,
): HomeOfficeTimeFrameState => {
  const selectedTimeFrame = timeFrameValue.selectedTimeFrame;
  const timeFrameState: HomeOfficeTimeFrameState = { selectedTimeFrame };

  switch (selectedTimeFrame) {
    case HomeOfficeTimeFrameOptions.date:
      timeFrameState.selectedDate = timeFrameValue.startDate;
      break;
    case HomeOfficeTimeFrameOptions.month:
      timeFrameState.selectedMonth = timeFrameValue.startDate.getMonth();
      timeFrameState.selectedYear = timeFrameValue.startDate.getFullYear();
      break;
    case HomeOfficeTimeFrameOptions.quarter:
      const quarterStartMonth = timeFrameValue.startDate.getMonth() + 1;
      timeFrameState.selectedQuarter = Math.ceil(quarterStartMonth / 3);
      timeFrameState.selectedYear = timeFrameValue.startDate.getFullYear();
      break;
    // Default case is when selectedTimeFrame is `year`
    default:
      timeFrameState.selectedYear = timeFrameValue.startDate.getFullYear();
  }

  return timeFrameState;
};

/**
 * Converts a state object to a time frame value for managing the home office date filter.<br>
 * It will return start and end dates if the timePeriod is not HomeOfficeTimeFrameOptions.date
 *
 * @param {HomeOfficeTimeFrameState} timeFrameState - The state object to convert.
 * @returns {HomeOfficeTimeFrameValues} - The corresponding time frame value with selected time period, start date and end date.
 *
 * @example
 * // Example usage:
 * const timeFrameState = {
 *   selectedTimeFrame: HomeOfficeTimeFrameOptions.month,
 *   selectedYear: 2022,
 *   selectedMonth: 4,
 * };
 * const timeFrameValue = getHomeOfficeTimeFrameValueFromState(timeFrameState);
 * timeFrameValue: {
 *  selectedTimeFrame: HomeOfficeTimeFrameOptions.month,
 *  startDate: new Date(2022, 4, 1),
 *  endDate: new Date(2022, 4, 30)
 * }
 */
export const getHomeOfficeTimeFrameValueFromState = (
  timeFrameState: HomeOfficeTimeFrameState,
): HomeOfficeTimeFrameValues => {
  const selectedTimeFrame = timeFrameState.selectedTimeFrame;
  const timeFrameValue: HomeOfficeTimeFrameValues = { ...defaultTimeFrameValues, selectedTimeFrame };
  const selectedYear = timeFrameState.selectedYear ?? new Date().getFullYear() - 1;

  switch (selectedTimeFrame) {
    case HomeOfficeTimeFrameOptions.date:
      timeFrameValue.startDate = startOfDay(timeFrameState.selectedDate ?? startOfYesterday());
      timeFrameValue.endDate = endOfDay(timeFrameValue.startDate);
      break;
    case HomeOfficeTimeFrameOptions.month:
      timeFrameValue.startDate = startOfMonth(new Date(selectedYear, timeFrameState.selectedMonth ?? 0));
      timeFrameValue.endDate = endOfMonth(new Date(selectedYear, timeFrameState.selectedMonth ?? 0));
      break;
    case HomeOfficeTimeFrameOptions.quarter:
      const selectedQuarter = timeFrameState.selectedQuarter ?? 1;
      const startMonthOfQuarter = 3 * (selectedQuarter - 1);
      const endMonthOfQuarter = startMonthOfQuarter + 2;

      timeFrameValue.startDate = startOfMonth(new Date(selectedYear, startMonthOfQuarter));
      timeFrameValue.endDate = endOfMonth(new Date(selectedYear, endMonthOfQuarter));
      break;
    // Default case is when selectedTimeFrame is `year`
    default:
      timeFrameValue.startDate = startOfDay(new Date(selectedYear, 0, 1));
      timeFrameValue.endDate = endOfDay(new Date(selectedYear, 11, 31));
  }

  return timeFrameValue;
};

/**
 * Returns dropdown options based on the selected time period and year and pre-computed dropdown options.
 *
 * @param {HomeOfficeTimeFrameValues} timeFrameValues - The time frame values.
 * @param {HomeOfficeDropdownOptions} dropdownOptions - Pre-computed list of dropdown options.
 * @returns {DropDownOptions} - Dropdown options for the selected time period and year.
 */
export const getDefaultDropDownOptionsFromValue = (
  timeFrameValues: HomeOfficeTimeFrameValues,
  dropdownOptions: HomeOfficeDropdownOptions,
): DropDownOptions => {
  const newDropDownOptions: DropDownOptions = {
    year: [],
    month: [],
    quarter: [],
  };

  const newState = getHomeOfficeTimeFrameStateFromValue(timeFrameValues);

  switch (newState.selectedTimeFrame) {
    case HomeOfficeTimeFrameOptions.year:
      newDropDownOptions.year = dropdownOptions[HomeOfficeTimeFrameOptions.year];
      break;

    case HomeOfficeTimeFrameOptions.month:
      newDropDownOptions.year = dropdownOptions[newState.selectedTimeFrame].years;
      if (newState.selectedYear) {
        newDropDownOptions.month = dropdownOptions[newState.selectedTimeFrame].optionsForYear[newState.selectedYear];
      }
      break;

    case HomeOfficeTimeFrameOptions.quarter:
      newDropDownOptions.year = dropdownOptions[newState.selectedTimeFrame].years;
      if (newState.selectedYear) {
        newDropDownOptions.quarter = dropdownOptions[newState.selectedTimeFrame].optionsForYear[newState.selectedYear];
      }
      break;

    default:
      break;
  }

  return newDropDownOptions;
};

/**
 * Generates dropdown options for year, month, and quarter based on the initiation date.
 * @note The month data is 0-indexed, and labels will have full names.
 * The quarter data is 1-indexed.
 * @param {Date | null} initiationDate - The first date that can be accessed using the filters.
 * @returns {HomeOfficeDropdownOptions} - An object containing arrays of DropdownItems for year, month, and quarter.
 *
 * @example
 * // Example usage:
 * // Note: Current date assumed as April 26, 2024.
 * const initiationDate = new Date('2022-12-01'); // Dec 01, 2022
 * const dropdownOptions = getAllDropdownOptions(initiationDate);
 * dropdownOptions: {
 *   year: [
 *     { label: '2024', value: 2024 },
 *     { label: '2023', value: 2023 },
 *   ],
 *   month: {
 *     2024: [{ label: 'Jan', value: 0 }, { label: 'Feb', value: 1 }, { label: 'Mar', value: 2 }],
 *     2023: [{ label: 'Jan', value: 0 }, { label: 'Feb', value: 1 }, ...],
 *     2022: [{ label: 'Dec', value: 11 }],
 *   },
 *   quarter: {
 *     2024: [{ label: 'Q1', value: 1 }, { label: 'Q2', value: 2 }],
 *     2023: [{ label: 'Q1', value: 1 }, { label: 'Q2', value: 2 }, ...],
 *     2022: [{ label: 'Q4', value: 4 }],
 *   },
 * }
 */
export const getAllDropdownOptions = (initiationDate: Date | null): HomeOfficeDropdownOptions => {
  const yearOptionsForMonthAndQuarters = getLastNYears(MAX_NUMBER_OF_YEARS, initiationDate, true, true);

  // Generate the dropdown options for months based on the year options.This should be a map of year to DropdownOptions
  const monthOptions = yearOptionsForMonthAndQuarters.reduce(
    (acc: YearDropdownOptionsRecord, year) => {
      const months = getCompletedMonthDropdownOptionsForYear(year.value, initiationDate);
      if (months.length > 0) {
        acc.optionsForYear[year.value] = months;
        acc.years.push(year);
      }
      return acc;
    },
    {
      years: [],
      optionsForYear: {},
    },
  );

  // Generate the dropdown options for quarters based on the monthOptions options.
  // The reduce method iterates over the years which have months and generates quarters.
  const quarterOptions = monthOptions.years.reduce(
    (acc: YearDropdownOptionsRecord, monthYear) => {
      const year = monthYear.value;
      const quarters = getQuartersByMonths(monthOptions.optionsForYear[monthYear.value].map(month => month.value));
      if (quarters.length > 0) {
        acc.optionsForYear[year] = quarters;
        acc.years.push(monthYear);
      }
      return acc;
    },
    {
      years: [],
      optionsForYear: {},
    },
  );

  const yearOptions = getLastNYears(MAX_NUMBER_OF_YEARS, initiationDate, false, false);

  return {
    [HomeOfficeTimeFrameOptions.year]: yearOptions,
    [HomeOfficeTimeFrameOptions.month]: monthOptions,
    [HomeOfficeTimeFrameOptions.quarter]: quarterOptions,
  };
};

/**
 * Generates a list of quarters based on the provided months.
 *
 * @param {number[]} months - An array of months. Months are 0-indexed in this case.
 * @returns {DropdownItem<number>[]} - An array of DropdownItems representing quarters that have full coverage of months.
 *
 * @example
 * // Example usage:
 * const months = [ 0, 1, 2, 3, 4, 5, 6 ];
 * const quarters = getQuartersByMonths(months);
 * quarters: [
 *   { label: 'Q1', value: 1 },
 *   { label: 'Q2', value: 2 },
 * ]
 */
const getQuartersByMonths = (months: number[]): DropdownItem<number>[] => {
  const quarters = [
    { label: 'Q1', value: 1 },
    { label: 'Q2', value: 2 },
    { label: 'Q3', value: 3 },
    { label: 'Q4', value: 4 },
  ];

  if (months.length < 3) {
    return [];
  }

  // Filter quarters based on full coverage of months
  return quarters.filter(quarter => {
    const quarterStart = (quarter.value - 1) * 3;
    const quarterEnd = quarterStart + 2;
    return months.includes(quarterStart) && months.includes(quarterEnd);
  });
};
