import React from 'react';

import { getMetricValueWithChangeInPeriod } from './hooks/getFormattedItems';
import { useGetHomeOfficeData } from './hooks/useGetHomeOfficeData';
import { DEFAULT_DISPLAY_VALUE, isDateView } from './hooks/utils';
import { Search } from './Search';
import { TimeFrameFilters } from './TimeFrameFilters';

import { ErrorComponent } from '~/components/ErrorComponent';
import { Filters } from '~/components/Filters';
import { FilterCountMap } from '~/components/Filters/types';
import { LoadingPage } from '~/components/LoadingPage';
import { BasicTable } from '~/components/ui/BasicTable';
import { ItemsPerPage } from '~/components/ui/BasicTable/ItemsPerPage';
import { TotalItems } from '~/components/ui/BasicTable/TotalItems';
import { Divider } from '~/components/ui/Divider';
import { Box, Button, DownloadIcon, Skeleton, Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  contentOptions: ContentOptions;
  onAdvisorClick: (faPartyId: string) => void;
  viewerPartyId: string;
}

const defaultAppliedFilters = {};
const filterCountMap: FilterCountMap[] = [];
const layoutStyle = { mx: 'auto', maxWidth: { xl: 'xl', lg: 'lg', md: 'md' } };

export const HomeOfficeDashboard: React.FC<Props> = ({ contentOptions, onAdvisorClick, viewerPartyId }) => {
  const {
    components: {
      sfHomeOffice: { showFilters },
    },
  } = useCoreConfig();

  const { data, loading, error } = useGetHomeOfficeData({
    contentOptions,
    onAdvisorClick,
    viewerPartyId,
  });
  const content = data?.content;

  const getTotalSection = (
    isLoading: boolean,
    metricValue: string,
    rteLabel: string,
    showChange?: boolean,
    rteConfig?: Record<string, React.ReactNode>,
    changeInMetricValue?: string,
    isChangePositive?: boolean,
  ) => {
    if (isLoading) {
      return <Skeleton height="35px" width="250px" />;
    }
    return (
      <Stack>
        <RteContent config={rteConfig} data={rteLabel} defaultBodyVariant="subtitle1" />
        {getMetricValueWithChangeInPeriod({
          changeInMetricValue,
          isChangePositive,
          isPartnerMetric: true,
          metricValue,
          showChange,
        })}
      </Stack>
    );
  };

  return loading ? (
    <LoadingPage />
  ) : error ? (
    <ErrorComponent contentOptions={contentOptions} error={error} />
  ) : (
    <>
      <Box maxWidth="100%" sx={{ pt: 8, pb: 4, px: 6 }}>
        <Stack spacing={6.25} sx={layoutStyle}>
          <Typography component="h1" variant="h3">
            {content?.title}
          </Typography>
          {data && content && (
            <TimeFrameFilters
              content={content.timeFrameContent}
              handleChange={data.updateTimeFrameValues}
              timeFrameValues={data.timeFrameValues}
            />
          )}
          <Stack direction="row" divider={<Divider flexItem orientation="vertical" />} spacing={3}>
            {getTotalSection(
              !data || data.partnerDataLoading,
              data?.totalAum?.value ?? DEFAULT_DISPLAY_VALUE,
              data?.totalAumRteParams.label ?? '',
              !isDateView(data?.timeFrameValues),
              data?.totalAumRteParams.config,
              data?.totalAum?.change,
              data?.totalAum?.isPositive,
            )}
            {getTotalSection(
              !data || data.partnerDataLoading,
              data?.totalAccountsOpened ?? DEFAULT_DISPLAY_VALUE,
              data?.totalAccountsOpenedRteParams.label ?? '',
              !isDateView(data?.timeFrameValues),
              data?.totalAccountsOpenedRteParams.config,
            )}
          </Stack>
        </Stack>
      </Box>

      {data && content && (
        <Box maxWidth="100%" sx={{ backgroundColor: 'other.backgroundGrey', py: 7, px: 6 }}>
          <Stack sx={{ ...layoutStyle, backgroundColor: 'white', py: 3 }}>
            <Stack direction="row" justifyContent="space-between" sx={{ pt: 1, px: 4 }}>
              <Search
                content={data.content.search}
                disabled={loading}
                onSearchTermChange={data.onSearchTermChange}
                onSelect={data.onSearchOptionSelect}
                searchError={data.searchError}
                searchOptions={data.searchOptions}
                searchOptionsLoading={data.searchOptionsLoading}
              />
              <Stack direction="row" justifyContent="end" spacing={1}>
                <Button
                  disabled={data.homeOfficeTableItems.length === 0 || data.downloadLoading}
                  endIcon={<DownloadIcon />}
                  onClick={data.onDownloadCSV}
                >
                  {data.content.downloadCsv}
                </Button>
                {showFilters && (
                  <Filters
                    appliedFilters={data.appliedFilters}
                    content={data.content.filterContent}
                    contentOptions={contentOptions}
                    defaultAppliedFilters={defaultAppliedFilters}
                    filterCountMap={filterCountMap}
                    filters={data.filterConfig}
                    onSubmit={data.onFilterChange}
                    openFiltersPopup={data.filtersPopupOpen}
                    updateOpenFiltersPopup={data.updateOpenFiltersPopup}
                  />
                )}
              </Stack>
            </Stack>
            {data.tableDataLoading ? (
              <>
                <Skeleton height="35px" sx={{ m: 4, mb: 0 }} />
                <Skeleton height="35px" sx={{ m: 4, mt: 0 }} />
              </>
            ) : (
              <BasicTable
                BottomLeftSection={<TotalItems paginationContext={data.paginationContext} />}
                BottomRightSection={<ItemsPerPage paginationContext={data.paginationContext} />}
                alignItems="left"
                columns={content.table.tableColumns}
                currentPage={data.page}
                data={data.homeOfficeTableItems}
                nullStateConfig={{
                  message: content.table.nullStateMessage,
                }}
                onPageChange={data.updatePage}
                showPagination
                sortConfig={data.sortConfig}
                totalPages={data.totalPages}
              />
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};
