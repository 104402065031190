import { endOfYesterday, isAfter, isBefore, isValid, startOfYear, toDate } from 'date-fns';
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Controller, RegisterOptions, UseFormMethods, useWatch } from 'react-hook-form';

import { DataPointKeys, QuestionKeys } from '../../../../configs/questions/types';
import { FormData } from '../../../../types';
import { CmsKeys, ComponentTypes, QuestionContent, ValidationMessage } from '../../../types';
import { getFieldName } from '../../../utils';
import { isValidIdentificationNumber, isValidLength } from '../../../validators';
import { getDropdownItemsFromCms } from '../../utils';
import { BeneficiaryConfig, BeneficiaryTypeError } from '..';
import {
  getDobFieldName,
  getFirstNameFieldName,
  getLastNameFieldName,
  getNameFieldName,
  getNameOfTrusteeFieldName,
  getOrganizationCountryFieldName,
  getPercentageFieldName,
  getPerStirpesFieldName,
  getSsnFieldName,
  getTaxReportingNumberFieldName,
  getTaxReportingTypeFieldName,
  getTypeFieldName,
  getUlidFieldName,
} from '../utils';

import { getDefaultValueForDOBField, getDefaultValueForNameField, getInputError } from './utils';

import { IdentifierType, MaritalStatus, RelationshipSubtype } from '~/__generated__';
import { Alert } from '~/components/ui/Alert';
import { DatePicker } from '~/components/ui/DatePicker';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInputProps,
} from '~/components/ui/mui';
import { RadioGroup } from '~/components/ui/RadioGroup';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { TextField } from '~/components/ui/TextField';
import { PaperworkRelationships } from '~/containers/Paperwork/symphony';

interface ControllerParams {
  componentName: string;
  componentType?: ComponentTypes;
  defaultValue?: string;
  disabled?: boolean;
  dropdownOptions?: DropdownItem[];
  error?: boolean;
  inputProps?: Partial<OutlinedInputProps>;
  onInputChange?: (e: any, onChange: (...event: any[]) => void) => void;
  questionContent?: QuestionContent;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  type?: string;
}

export interface Props {
  beneficiaryConfig: BeneficiaryConfig;
  beneficiaryTypeError: BeneficiaryTypeError;
  beneficiaryUniqueId: string;
  countriesList: DropdownItem<string>[];
  dataQa?: string;
  disablePageEditing: boolean;
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  hidden: boolean;
  index: number;
  isAccountProfileEdit?: boolean;
  isFormSubmitted: boolean;
  isPrimaryBeneficiary: boolean;
  onBeneficiaryTypeChange: (index: number, value: string) => void;
  onPercentageChange: (name: string, value: number) => void;
  paperworkFreeFormId?: string;
  percentageSumError: boolean;
  prefillData?: PaperworkRelationships;
  questionKey: string;
  questions: QuestionContent[];
  validationMessages?: ValidationMessage[];
}

export const Beneficiary: FC<Props> = ({
  beneficiaryConfig,
  dataQa = 'beneficiary',
  disablePageEditing,
  index,
  formHooks: { errors: fieldErrors, control, trigger },
  beneficiaryUniqueId,
  countriesList,
  questions,
  validationMessages,
  paperworkFreeFormId,
  hidden,
  isAccountProfileEdit,
  isFormSubmitted,
  isPrimaryBeneficiary,
  onPercentageChange,
  onBeneficiaryTypeChange,
  percentageSumError,
  beneficiaryTypeError,
  questionKey,
  prefillData,
}) => {
  const { hideSsn: hideSSN, includeLastName, isTrustTypeFieldsEnabled, isPerStirpesEnabled } = beneficiaryConfig;

  const [isInitialised, setIsInitialised] = useState(false);
  const [DOBFieldHasValue, setDOBFieldHasValue] = useState<boolean | null>();
  const [SSNFieldHasValue, setSSNFieldHasValue] = useState<boolean | null>();
  const [benTypeFieldValue, setBenTypeFieldValue] = useState<string | null>();
  const [taxReportingTypeFieldValue, setTaxReportingTypeFieldValue] = useState<string | null>();

  const beneficiaryULIDFieldName = getUlidFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId);
  const beneficiaryNameFieldName = getNameFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId);
  const beneficiaryFirstNameFieldName = getFirstNameFieldName(
    isPrimaryBeneficiary,
    beneficiaryUniqueId,
    paperworkFreeFormId,
  );
  const beneficiaryLastNameFieldName = getLastNameFieldName(
    isPrimaryBeneficiary,
    beneficiaryUniqueId,
    paperworkFreeFormId,
  );
  const beneficiaryTypeFieldName = getTypeFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId);
  const beneficiaryPercentageFieldName = getPercentageFieldName(
    isPrimaryBeneficiary,
    beneficiaryUniqueId,
    paperworkFreeFormId,
  );
  const beneficiaryDOBFieldName = getDobFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId);
  const beneficiarySSNFieldName = getSsnFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId);
  const beneficiaryPerStirpesFieldName = getPerStirpesFieldName(
    isPrimaryBeneficiary,
    beneficiaryUniqueId,
    paperworkFreeFormId,
  );
  const beneficiaryNameOfTrusteeFieldName = getNameOfTrusteeFieldName(
    isPrimaryBeneficiary,
    beneficiaryUniqueId,
    paperworkFreeFormId,
  );
  const beneficiaryOrganizationCountryFieldName = getOrganizationCountryFieldName(
    isPrimaryBeneficiary,
    beneficiaryUniqueId,
    paperworkFreeFormId,
  );
  const beneficiaryTINFieldName = getTaxReportingNumberFieldName(
    isPrimaryBeneficiary,
    beneficiaryUniqueId,
    paperworkFreeFormId,
  );
  const beneficiaryTaxReportingTypeFieldName = getTaxReportingTypeFieldName(
    isPrimaryBeneficiary,
    beneficiaryUniqueId,
    paperworkFreeFormId,
  );
  const maritalStatusFieldName = getFieldName(DataPointKeys.MARITAL_STATUS, paperworkFreeFormId);

  const nameQuestionKey = isPrimaryBeneficiary
    ? QuestionKeys.PRIMARY_BENEFICIARY_NAME
    : QuestionKeys.CONTINGENT_BENEFICIARY_NAME;
  const firstNameQuestionKey = isPrimaryBeneficiary
    ? QuestionKeys.PRIMARY_BENEFICIARY_FIRST_NAME
    : QuestionKeys.CONTINGENT_BENEFICIARY_FIRST_NAME;
  const lastNameQuestionKey = isPrimaryBeneficiary
    ? QuestionKeys.PRIMARY_BENEFICIARY_LAST_NAME
    : QuestionKeys.CONTINGENT_BENEFICIARY_LAST_NAME;
  const beneficiaryTypeQuestionKey = isPrimaryBeneficiary
    ? QuestionKeys.PRIMARY_BENEFICIARY_TYPE
    : QuestionKeys.CONTINGENT_BENEFICIARY_TYPE;
  const percentageQuestionKey = isPrimaryBeneficiary
    ? QuestionKeys.PRIMARY_BENEFICIARY_PERCENTAGE
    : QuestionKeys.CONTINGENT_BENEFICIARY_PERCENTAGE;
  const dobQuestionKey = isPrimaryBeneficiary
    ? QuestionKeys.PRIMARY_BENEFICIARY_DOB
    : QuestionKeys.CONTINGENT_BENEFICIARY_DOB;
  const ssnQuestionKey = isPrimaryBeneficiary
    ? QuestionKeys.PRIMARY_BENEFICIARY_SSN
    : QuestionKeys.CONTINGENT_BENEFICIARY_SSN;
  const perStirpesQuestionKey = isPrimaryBeneficiary
    ? QuestionKeys.PRIMARY_BENEFICIARY_PER_STIRPES
    : QuestionKeys.CONTINGENT_BENEFICIARY_PER_STIRPES;
  const trusteeNameQuestionKey = isPrimaryBeneficiary
    ? QuestionKeys.PRIMARY_BENEFICIARY_NAME_OF_TRUSTEE
    : QuestionKeys.CONTINGENT_BENEFICIARY_NAME_OF_TRUSTEE;
  const organizationCountryQuestionKey = isPrimaryBeneficiary
    ? QuestionKeys.PRIMARY_BENEFICIARY_ORGANIZATION_COUNTRY
    : QuestionKeys.CONTINGENT_BENEFICIARY_ORGANIZATION_COUNTRY;
  const trustDateQuestionKey = isPrimaryBeneficiary
    ? QuestionKeys.PRIMARY_BENEFICIARY_TRUST_DATE
    : QuestionKeys.CONTINGENT_BENEFICIARY_TRUST_DATE;
  const taxReportingNumberQuestionKey = isPrimaryBeneficiary
    ? QuestionKeys.PRIMARY_BENEFICIARY_TAX_REPORTING_NUMBER
    : QuestionKeys.CONTINGENT_BENEFICIARY_TAX_REPORTING_NUMBER;
  const taxReportingNumberTypeQuestionKey = isPrimaryBeneficiary
    ? QuestionKeys.PRIMARY_BENEFICIARY_TAX_REPORTING_NUMBER_TYPE
    : QuestionKeys.CONTINGENT_BENEFICIARY_TAX_REPORTING_NUMBER_TYPE;

  const beneficiaryNameContent = questions.find(q => q.key === nameQuestionKey);
  const beneficiaryFirstNameContent = questions.find(q => q.key === firstNameQuestionKey);
  const beneficiaryLastNameContent = questions.find(q => q.key === lastNameQuestionKey);
  const beneficiaryTypeContent = questions.find(q => q.key === beneficiaryTypeQuestionKey);
  const beneficiaryPercentageContent = questions.find(q => q.key === percentageQuestionKey);
  const beneficiaryDOBContent = questions.find(q => q.key === dobQuestionKey);
  const beneficiarySSNContent = questions.find(q => q.key === ssnQuestionKey);
  const beneficiaryPerStirpesContent = questions.find(q => q.key === perStirpesQuestionKey);
  const beneficiaryNameOfTrusteeContent = questions.find(q => q.key === trusteeNameQuestionKey);
  const beneficiaryOrganizationCountryContent = questions.find(q => q.key === organizationCountryQuestionKey);
  const beneficiaryTrustDateContent = questions.find(q => q.key === trustDateQuestionKey);
  const beneficiaryTINContent = questions.find(q => q.key === taxReportingNumberQuestionKey);
  const beneficiaryTaxReportingNumberTypeContent = questions.find(q => q.key === taxReportingNumberTypeQuestionKey);

  const watchedFields = useWatch({
    control,
    name: [beneficiaryDOBFieldName, beneficiarySSNFieldName, beneficiaryTINFieldName, maritalStatusFieldName],
  });

  const watchedDOBField = watchedFields[beneficiaryDOBFieldName];
  const watchedSSNField = watchedFields[beneficiarySSNFieldName];
  const watchedTINField = watchedFields[beneficiaryTINFieldName];
  const watchedMaritalStatus = watchedFields[maritalStatusFieldName];

  useEffect(() => {
    if (!isInitialised) {
      if (prefillData?.relationshipType) {
        onBeneficiaryTypeChange(index, prefillData.relationshipType);
        setBenTypeFieldValue(prefillData.relationshipType);

        if (prefillData.party.identifiers?.length) {
          const isBeneficiaryTrustOrCharity = [RelationshipSubtype.TRUST, RelationshipSubtype.CHARITY].includes(
            prefillData.relationshipType,
          );
          if (isBeneficiaryTrustOrCharity) {
            const identifierType = prefillData.party.identifiers.find(
              (id: { identifierValue?: string | null; type: IdentifierType }) => !!id.identifierValue,
            )?.type;
            if (identifierType) {
              setTaxReportingTypeFieldValue(identifierType);
            }
          }
        }
      }
      if (prefillData?.percentage) {
        onPercentageChange(
          beneficiaryPercentageFieldName,
          prefillData.percentage ? parseFloat(prefillData.percentage) : 0,
        );
      }
      setIsInitialised(true);
    }
  }, [
    beneficiaryPercentageFieldName,
    index,
    isInitialised,
    onBeneficiaryTypeChange,
    onPercentageChange,
    prefillData?.party.identifiers,
    prefillData?.percentage,
    prefillData?.relationshipType,
  ]);

  useEffect(() => {
    // Setting DOBField and SSNField in state, these values are used for the validation rules for SSN and DOB field.
    // If DOB Field is true SSN field is not required and vice versa.
    setDOBFieldHasValue(!!watchedDOBField);
    setSSNFieldHasValue(!!watchedSSNField || !!watchedTINField);
  }, [watchedDOBField, watchedSSNField, watchedTINField]);

  useEffect(() => {
    if (isFormSubmitted) {
      trigger(beneficiaryPercentageFieldName);
    }
  }, [isFormSubmitted, beneficiaryPercentageFieldName, percentageSumError, trigger]);

  useEffect(() => {
    if (isFormSubmitted) {
      trigger(beneficiaryTypeFieldName);
    }
  }, [isFormSubmitted, beneficiaryTypeError, beneficiaryTypeFieldName, trigger, watchedMaritalStatus]);

  useEffect(() => {
    if (!hideSSN && isFormSubmitted) {
      trigger(beneficiarySSNFieldName);
    }
  }, [isFormSubmitted, DOBFieldHasValue, beneficiarySSNFieldName, hideSSN, trigger]);

  useEffect(() => {
    if (isFormSubmitted) {
      trigger(beneficiaryDOBFieldName);
    }
  }, [isFormSubmitted, SSNFieldHasValue, beneficiaryDOBFieldName, trigger]);

  const isTrustOrCharity = useMemo(() => {
    const beneficiaryType = benTypeFieldValue as RelationshipSubtype;
    return [RelationshipSubtype.TRUST, RelationshipSubtype.CHARITY].includes(beneficiaryType);
  }, [benTypeFieldValue]);

  if (
    [
      ...(includeLastName ? [beneficiaryFirstNameContent, beneficiaryLastNameContent] : [beneficiaryNameContent]),
      beneficiaryTypeContent,
      beneficiaryPercentageContent,
      beneficiaryDOBContent,
      beneficiarySSNContent,
    ].includes(undefined)
  ) {
    return <Alert severity="error">Content not found for some of the question(s) in: "{questionKey}" in CMS.</Alert>;
  }

  const onBeneficiaryNameChange = (e: any, onChange: (...event: any[]) => void) => {
    const { value: inputValue } = e.target;
    if (beneficiaryNameContent?.character_limit && inputValue?.length > beneficiaryNameContent.character_limit) {
      e.preventDefault();
      return;
    }
    if (inputValue) {
      e.target.value = inputValue.replaceAll(/[^A-Za-z\d -]/g, '');
    }
    onChange(e);
  };

  const onBeneficiaryFirstNameChange = (e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
    const { value: inputValue } = e.target;
    if (
      beneficiaryFirstNameContent?.character_limit &&
      inputValue.length > beneficiaryFirstNameContent.character_limit
    ) {
      e.preventDefault();
      return;
    }
    if (inputValue) {
      e.target.value = inputValue.replaceAll(/[^A-Za-z -]/g, '');
    }
    onChange(e);
  };

  const onBeneficiaryLastNameChange = (e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
    const { value: inputValue } = e.target;
    if (beneficiaryLastNameContent?.character_limit && inputValue.length > beneficiaryLastNameContent.character_limit) {
      e.preventDefault();
      return;
    }
    if (inputValue) {
      e.target.value = inputValue.replaceAll(/[^A-Za-z -]/g, '');
    }
    onChange(e);
  };

  const IDENTIFICATION_LENGTH = 9;
  const numbersNegatedRegExp = new RegExp(/\D/g);

  // Default minDate as MUI Date picker default to 1900-01-01 as the earliest date.
  const defaultMinDate = startOfYear(new Date('1900-01-01'));
  const defaultMinDateForTrust = startOfYear(new Date('1500-01-01'));

  const onBeneficiarySSNChange = (e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
    const { value: inputValue } = e.target;
    if (inputValue.length > IDENTIFICATION_LENGTH) {
      e.preventDefault();
      return;
    }

    e.target.value = inputValue.replaceAll(numbersNegatedRegExp, '');
    onChange(e);
  };

  const onBeneficiaryTINChange = (e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
    const { value: inputValue } = e.target;
    if (inputValue.length > IDENTIFICATION_LENGTH) {
      e.preventDefault();
      return;
    }

    e.target.value = inputValue.replaceAll(numbersNegatedRegExp, '');
    onChange(e);
  };

  const onBeneficiaryTypeFieldChange = (e: any, onChange: (...event: any[]) => void) => {
    const { value: selectedDropdownValue } = e.target;
    setBenTypeFieldValue(selectedDropdownValue as string);
    onBeneficiaryTypeChange(index, selectedDropdownValue as string);
    onChange(e);
  };

  const onPercentageFieldChange = (e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
    const { value: inputValue } = e.target;
    if (isNaN((inputValue as unknown) as number)) {
      e.preventDefault(); // Prevent Non-numeric entries
      return;
    }
    const parts = inputValue.split('.');
    if (parts.length === 2 && parts[1].length > 2) {
      e.preventDefault();
      return;
    }

    onPercentageChange(e.target.name, inputValue ? parseFloat(inputValue) : 0);
    onChange(e);
  };

  const getControllerByComponentName = (componentName: string) => {
    switch (componentName) {
      case 'dob':
        const isTrustBeneficiaryType = benTypeFieldValue === RelationshipSubtype.TRUST;
        return (
          <Controller
            control={control}
            defaultValue={getDefaultValueForDOBField(prefillData)}
            name={beneficiaryDOBFieldName}
            render={({ onChange, value, ref }) => (
              <DatePicker
                dataQa={`datepicker-${beneficiaryDOBFieldName}`}
                disableFuture
                disableHighlightToday
                disabled={disablePageEditing}
                error={isFormSubmitted ? !!fieldErrors[beneficiaryDOBFieldName] : false}
                fullWidth
                inputRef={ref}
                isControlled
                label={isTrustBeneficiaryType ? beneficiaryTrustDateContent?.question : beneficiaryDOBContent?.question}
                maxDate={endOfYesterday()}
                minDate={isTrustBeneficiaryType ? defaultMinDateForTrust : defaultMinDate}
                onChange={onChange}
                value={value}
              />
            )}
            rules={
              hidden
                ? {}
                : {
                    required: !SSNFieldHasValue,
                    validate: {
                      isDate: value => (value ? isValid(toDate(value)) : true),
                      minDate: value => {
                        if (!value) {
                          return true;
                        }
                        return !isBefore(value, isTrustBeneficiaryType ? defaultMinDateForTrust : defaultMinDate);
                      },
                      isFutureDateDisabled: value => (value ? isAfter(endOfYesterday(), value) : true),
                    },
                  }
            }
          />
        );
      case 'perStirpes':
        return (
          <Controller
            control={control}
            defaultValue={prefillData?.isPerStirpes ?? false}
            name={beneficiaryPerStirpesFieldName}
            render={({ onChange, value }) => (
              <FormControl
                data-qa={`question-checkbox-${beneficiaryPerStirpesFieldName}`}
                disabled={disablePageEditing}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      id={beneficiaryPerStirpesFieldName}
                      onChange={e => {
                        onChange(e.target.checked);
                      }}
                    />
                  }
                  label={<RteContent data={beneficiaryPerStirpesContent?.question ?? ''} />}
                  value={value}
                />
              </FormControl>
            )}
          />
        );
      case 'taxType':
        return (
          <Controller
            control={control}
            defaultValue={
              prefillData?.party.identifiers?.find((id: { identifierValue: string | null }) => !!id.identifierValue)
                ?.type ?? ''
            }
            key={beneficiaryTaxReportingTypeFieldName}
            name={beneficiaryTaxReportingTypeFieldName}
            render={({ onChange, value }) => (
              <RadioGroup
                dataQa={`dropdown-${beneficiaryTaxReportingTypeFieldName}`}
                disabled={disablePageEditing}
                error={isFormSubmitted ? !!fieldErrors[beneficiaryTaxReportingTypeFieldName] : false}
                items={beneficiaryTaxReportingNumberTypeContent?.options ?? []}
                legend={beneficiaryTaxReportingNumberTypeContent?.question}
                name={beneficiaryTaxReportingTypeFieldName}
                onChange={e => {
                  setTaxReportingTypeFieldValue(e);
                  onChange(e);
                }}
                value={value}
              />
            )}
            rules={{ required: !DOBFieldHasValue }}
          />
        );
      default:
        return null;
    }
  };

  const getControllerByComponentType = ({
    componentName,
    questionContent,
    componentType,
    defaultValue,
    disabled,
    dropdownOptions,
    error,
    onInputChange,
    type,
    rules,
    inputProps,
  }: ControllerParams) => {
    const ariaLabel = `${questionContent?.question} ${Number(beneficiaryUniqueId) + 1}`;
    switch (componentType) {
      case ComponentTypes.Input:
        return (
          <Controller
            control={control}
            defaultValue={defaultValue ?? ''}
            name={componentName}
            render={({ onChange, value, name, ref }) => (
              <TextField
                InputLabelProps={{ 'aria-label': ariaLabel }}
                InputProps={inputProps}
                dataQa={`textField-${componentName}`}
                disabled={disablePageEditing ? disablePageEditing : disabled}
                error={isFormSubmitted ? !!fieldErrors[componentName] || error : false}
                fullWidth
                id={componentName}
                inputRef={ref}
                label={questionContent?.question}
                name={name}
                onBlur={e => {
                  const trimmedValue = e.target.value.trim();
                  e.target.value = trimmedValue.replaceAll(/\s+/g, ' ');
                  onChange(e);
                }}
                onChange={e => {
                  if (onInputChange) {
                    onInputChange(e, onChange);
                  } else {
                    onChange(e);
                  }
                }}
                type={type ?? 'text'}
                value={value}
              />
            )}
            rules={!hidden && rules ? rules : {}}
          />
        );
      case ComponentTypes.Dropdown:
        return (
          <Controller
            control={control}
            defaultValue={defaultValue ?? ''}
            name={componentName}
            render={({ onChange, value, ref }) => (
              <Dropdown
                ariaLabel={ariaLabel}
                dataQa={`dropdown-${componentName}`}
                disabled={disablePageEditing}
                error={isFormSubmitted ? !!fieldErrors[componentName] || error : false}
                inputRef={ref}
                items={dropdownOptions ?? questionContent?.options ?? []}
                label={questionContent?.question}
                onChange={e => {
                  if (onInputChange) {
                    onInputChange(e, onChange);
                  } else {
                    onChange(e);
                  }
                }}
                value={value}
                width="100%"
              />
            )}
            rules={!hidden && rules ? rules : {}}
          />
        );
      default:
        return null;
    }
  };

  const UIDFieldControllerObj: ControllerParams = {
    componentName: beneficiaryULIDFieldName,
    componentType: ComponentTypes.Input,
    defaultValue: prefillData?.id ?? '',
    type: 'hidden',
  };

  const NameFieldControllerObj: ControllerParams = {
    componentName: beneficiaryNameFieldName,
    componentType: ComponentTypes.Input,
    defaultValue: getDefaultValueForNameField(prefillData),
    onInputChange: onBeneficiaryNameChange,
    rules: {
      required: true,
    },
    questionContent: beneficiaryNameContent,
  };

  const FirstNameFieldControllerObj: ControllerParams = {
    componentName: beneficiaryFirstNameFieldName,
    componentType: ComponentTypes.Input,
    defaultValue: prefillData?.party.partyPerson?.givenName ?? '',
    onInputChange: onBeneficiaryFirstNameChange,
    rules: {
      required: true,
    },
    questionContent: beneficiaryFirstNameContent,
  };

  const LastNameFieldControllerObj: ControllerParams = {
    componentName: beneficiaryLastNameFieldName,
    componentType: ComponentTypes.Input,
    defaultValue: prefillData?.party.partyPerson?.familyName ?? '',
    onInputChange: onBeneficiaryLastNameChange,
    rules: {
      required: true,
    },
    questionContent: beneficiaryLastNameContent,
  };

  const SSNFieldControllerObj: ControllerParams = {
    componentName: beneficiarySSNFieldName,
    componentType: ComponentTypes.Input,
    defaultValue:
      prefillData?.party.identifiers?.find((id: { type: IdentifierType }) => id.type === IdentifierType.SSN)
        ?.identifierValue ?? '',
    disabled:
      isAccountProfileEdit &&
      !!prefillData?.party.identifiers?.find((id: { type: IdentifierType }) => id.type === IdentifierType.SSN)
        ?.identifierValue?.length,
    onInputChange: onBeneficiarySSNChange,
    questionContent: beneficiarySSNContent,
    rules: {
      required: !DOBFieldHasValue,
      validate: {
        isSsn: value => (value ? isValidIdentificationNumber(value) : true),
        isLength: value => (value ? isValidLength(value, 9) : true),
      },
    },
  };

  const NameOfTrusteeFieldControllerObj: ControllerParams = {
    componentName: beneficiaryNameOfTrusteeFieldName,
    componentType: ComponentTypes.Input,
    defaultValue: prefillData?.nameOfTrustees ?? '',
    onInputChange: onBeneficiaryNameChange,
    questionContent: beneficiaryNameOfTrusteeContent,
  };

  const TypeFieldControllerObj: ControllerParams = {
    componentName: beneficiaryTypeFieldName,
    componentType: ComponentTypes.Dropdown,
    defaultValue: prefillData?.relationshipType ?? undefined,
    error: beneficiaryTypeError.spouseCount,
    questionContent: beneficiaryTypeContent,
    onInputChange: onBeneficiaryTypeFieldChange,
    rules: {
      required: true,
      validate: {
        onlyOneSpouseSelectionAllowed: () => !beneficiaryTypeError.spouseCount,
        spouseNotAllowed: value =>
          isAccountProfileEdit ||
          !(
            beneficiaryConfig.checkMaritalStatus &&
            watchedMaritalStatus &&
            watchedMaritalStatus !== MaritalStatus.MARRIED &&
            value === RelationshipSubtype.SPOUSE
          ),
        maritalStatusNotSelected: () =>
          (isAccountProfileEdit && !watchedMaritalStatus) ||
          !(!watchedMaritalStatus && beneficiaryConfig.checkMaritalStatus),
      },
    },
  };

  const OrganizationCountryControllerObj: ControllerParams = {
    componentName: beneficiaryOrganizationCountryFieldName,
    componentType: ComponentTypes.Dropdown,
    defaultValue: prefillData?.party.partyBusinessEntity?.country ?? '999',
    dropdownOptions: beneficiaryOrganizationCountryContent
      ? getDropdownItemsFromCms(beneficiaryOrganizationCountryContent, CmsKeys.Countries, countriesList, [])
      : [],
    questionContent: beneficiaryOrganizationCountryContent,
  };

  const PercentageFieldControllerObj: ControllerParams = {
    inputProps: {
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    },
    componentName: beneficiaryPercentageFieldName,
    componentType: ComponentTypes.Input,
    defaultValue: prefillData?.percentage ?? '',
    questionContent: beneficiaryPercentageContent,
    onInputChange: onPercentageFieldChange,
    rules: {
      required: true,
      min: 0,
      max: 100,
      validate: {
        sumNotEqualToHundred: () => !percentageSumError,
        zeroNotAllowed: inputValue => !!parseFloat(inputValue),
      },
    },
    error: percentageSumError,
  };

  const TINFieldControllerObj: ControllerParams = {
    componentName: beneficiaryTINFieldName,
    componentType: ComponentTypes.Input,
    defaultValue:
      prefillData?.party.identifiers?.find((id: { type: IdentifierType }) => id.type === IdentifierType.TIN)
        ?.identifierValue ?? '',
    onInputChange: onBeneficiaryTINChange,
    questionContent: beneficiaryTINContent,
    rules: {
      // TIN will have a required value only when the Relationship if DOB field does not have a value.
      required:
        !!benTypeFieldValue &&
        (benTypeFieldValue === RelationshipSubtype.CHARITY ||
          (benTypeFieldValue === RelationshipSubtype.TRUST && !DOBFieldHasValue)) &&
        !!taxReportingTypeFieldValue &&
        taxReportingTypeFieldValue === IdentifierType.TIN,
      validate: {
        isTin: value => (value ? isValidIdentificationNumber(value) : true),
        isLength: value => (value ? isValidLength(value, 9) : true),
      },
    },
  };

  return isAccountProfileEdit ? (
    <Grid columnSpacing={2} container data-qa={dataQa} item sx={{ mt: index ? 3 : 0 }} xs={12}>
      {/* The hidden input field is put here to map the ULID to the Beneficiary object*/}
      <Box sx={{ display: 'none' }}>{getControllerByComponentType(UIDFieldControllerObj)}</Box>
      {includeLastName ? (
        <>
          <Grid item xs={4}>
            {getControllerByComponentType(FirstNameFieldControllerObj)}
            {isFormSubmitted &&
              getInputError(
                beneficiaryFirstNameFieldName,
                firstNameQuestionKey,
                fieldErrors[beneficiaryFirstNameFieldName],
                validationMessages,
              )}
          </Grid>
          <Grid item xs={4}>
            {getControllerByComponentType(LastNameFieldControllerObj)}
            {isFormSubmitted &&
              getInputError(
                beneficiaryLastNameFieldName,
                lastNameQuestionKey,
                fieldErrors[beneficiaryLastNameFieldName],
                validationMessages,
              )}
          </Grid>
        </>
      ) : (
        <Grid item xs={4}>
          {getControllerByComponentType(NameFieldControllerObj)}
          {isFormSubmitted &&
            getInputError(
              beneficiaryNameFieldName,
              nameQuestionKey,
              fieldErrors[beneficiaryNameFieldName],
              validationMessages,
            )}
        </Grid>
      )}
      <Grid item sx={{ mt: '-6.5px' }} xs={hideSSN ? 4 : 2}>
        {getControllerByComponentType(TypeFieldControllerObj)}
        {isFormSubmitted &&
          getInputError(
            beneficiaryTypeFieldName,
            beneficiaryTypeQuestionKey,
            fieldErrors[beneficiaryTypeFieldName],
            validationMessages,
          )}
      </Grid>
      {hideSSN ? null : (
        <Grid item xs={2}>
          {getControllerByComponentType(SSNFieldControllerObj)}
          {isFormSubmitted &&
            getInputError(
              beneficiarySSNFieldName,
              ssnQuestionKey,
              fieldErrors[beneficiarySSNFieldName],
              validationMessages,
            )}
        </Grid>
      )}
      <Grid item xs={2}>
        {getControllerByComponentName('dob')}
        {isFormSubmitted &&
          getInputError(
            beneficiaryDOBFieldName,
            dobQuestionKey,
            fieldErrors[beneficiaryDOBFieldName],
            validationMessages,
          )}
      </Grid>
      <Grid item xs={2}>
        {getControllerByComponentType(PercentageFieldControllerObj)}
        {isFormSubmitted &&
          getInputError(
            beneficiaryPercentageFieldName,
            percentageQuestionKey,
            fieldErrors[beneficiaryPercentageFieldName],
            validationMessages,
          )}
      </Grid>
      {!!beneficiaryPerStirpesContent && isPerStirpesEnabled && (
        <Grid item xs={12}>
          {getControllerByComponentName('perStirpes')}
        </Grid>
      )}
    </Grid>
  ) : (
    <Grid columnSpacing={1} container data-qa={dataQa} item rowSpacing={2} sx={{ pt: 2 }} xs={12}>
      {/* The hidden input field is put here to map the ULID to the Beneficiary object*/}
      <Grid item sx={{ display: 'none' }}>
        <Box sx={{ display: 'none' }}>{getControllerByComponentType(UIDFieldControllerObj)}</Box>
      </Grid>
      {includeLastName ? (
        <>
          <Grid item xs={12}>
            {getControllerByComponentType(FirstNameFieldControllerObj)}
            {isFormSubmitted &&
              getInputError(
                beneficiaryFirstNameFieldName,
                firstNameQuestionKey,
                fieldErrors[beneficiaryFirstNameFieldName],
                validationMessages,
              )}
          </Grid>
          <Grid item xs={12}>
            {getControllerByComponentType(LastNameFieldControllerObj)}
            {isFormSubmitted &&
              getInputError(
                beneficiaryLastNameFieldName,
                lastNameQuestionKey,
                fieldErrors[beneficiaryLastNameFieldName],
                validationMessages,
              )}
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          {getControllerByComponentType(NameFieldControllerObj)}
          {isFormSubmitted &&
            getInputError(
              beneficiaryNameFieldName,
              nameQuestionKey,
              fieldErrors[beneficiaryNameFieldName],
              validationMessages,
            )}
        </Grid>
      )}
      <Grid item sx={{ mt: '-1.5px' }} xs={6}>
        {getControllerByComponentType(TypeFieldControllerObj)}
        {isFormSubmitted &&
          getInputError(
            beneficiaryTypeFieldName,
            beneficiaryTypeQuestionKey,
            fieldErrors[beneficiaryTypeFieldName],
            validationMessages,
          )}
      </Grid>
      <Grid item xs={6}>
        {getControllerByComponentType(PercentageFieldControllerObj)}
        {isFormSubmitted &&
          getInputError(
            beneficiaryPercentageFieldName,
            percentageQuestionKey,
            fieldErrors[beneficiaryPercentageFieldName],
            validationMessages,
          )}
      </Grid>
      {!!beneficiaryPerStirpesContent && isPerStirpesEnabled && (
        <Grid item xs={12}>
          {getControllerByComponentName('perStirpes')}
        </Grid>
      )}
      {isTrustOrCharity ? (
        <>
          {isTrustTypeFieldsEnabled && benTypeFieldValue === RelationshipSubtype.TRUST && (
            <Grid item xs={12}>
              {getControllerByComponentName('dob')}
              {isFormSubmitted &&
                getInputError(
                  beneficiaryDOBFieldName,
                  dobQuestionKey,
                  fieldErrors[beneficiaryDOBFieldName],
                  validationMessages,
                )}
            </Grid>
          )}
          <Grid item xs={12}>
            {getControllerByComponentName('taxType')}
            {isFormSubmitted &&
              getInputError(
                beneficiaryTaxReportingTypeFieldName,
                taxReportingNumberTypeQuestionKey,
                fieldErrors[beneficiaryTaxReportingTypeFieldName],
                validationMessages,
              )}
          </Grid>
          {taxReportingTypeFieldValue === IdentifierType.SSN && (
            <Grid item xs={12}>
              {getControllerByComponentType(SSNFieldControllerObj)}
              {isFormSubmitted &&
                getInputError(
                  beneficiarySSNFieldName,
                  ssnQuestionKey,
                  fieldErrors[beneficiarySSNFieldName],
                  validationMessages,
                )}
            </Grid>
          )}
          {taxReportingTypeFieldValue === IdentifierType.TIN && (
            <Grid item xs={12}>
              {getControllerByComponentType(TINFieldControllerObj)}
              {isFormSubmitted &&
                getInputError(
                  beneficiaryTINFieldName,
                  taxReportingNumberQuestionKey,
                  fieldErrors[beneficiaryTINFieldName],
                  validationMessages,
                )}
            </Grid>
          )}
          {isTrustTypeFieldsEnabled && benTypeFieldValue === RelationshipSubtype.TRUST && (
            <>
              <Grid item xs={12}>
                {getControllerByComponentType(OrganizationCountryControllerObj)}
                {isFormSubmitted &&
                  getInputError(
                    beneficiaryOrganizationCountryFieldName,
                    organizationCountryQuestionKey,
                    fieldErrors[beneficiaryOrganizationCountryFieldName],
                    validationMessages,
                  )}
              </Grid>
              <Grid item xs={12}>
                {getControllerByComponentType(NameOfTrusteeFieldControllerObj)}
                {isFormSubmitted &&
                  getInputError(
                    beneficiaryNameOfTrusteeFieldName,
                    trusteeNameQuestionKey,
                    fieldErrors[beneficiaryNameOfTrusteeFieldName],
                    validationMessages,
                  )}
              </Grid>
            </>
          )}
        </>
      ) : (
        <>
          <Grid item xs={6}>
            {getControllerByComponentName('dob')}
            {isFormSubmitted &&
              getInputError(
                beneficiaryDOBFieldName,
                dobQuestionKey,
                fieldErrors[beneficiaryDOBFieldName],
                validationMessages,
              )}
          </Grid>
          <Grid item xs={6}>
            {hideSSN ? null : (
              <>
                {getControllerByComponentType(SSNFieldControllerObj)}
                {isFormSubmitted &&
                  getInputError(
                    beneficiarySSNFieldName,
                    ssnQuestionKey,
                    fieldErrors[beneficiarySSNFieldName],
                    validationMessages,
                  )}
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
