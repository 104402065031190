import React, { FC } from 'react';
import { Controller, FieldError, RegisterOptions, UseFormMethods } from 'react-hook-form';

import { FormData } from '../../types';
import { QuestionContent, QuestionOrderSteps } from '../types';

import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { useTheme } from '~/components/ui/mui';

interface Props {
  defaultValue: any;
  dropdownItems: DropdownItem[];
  fieldName: string;
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  inputErrors?: FieldError;
  isFieldDisabled: boolean;
  onDropdownAnswerChange: (dataPointKey: string, answer: any) => void;
  questionContent: QuestionContent;
  questionInRow: QuestionOrderSteps;
  validationRules: RegisterOptions;
}

export const DropdownQuestion: FC<Props> = ({
  defaultValue,
  dropdownItems,
  validationRules,
  fieldName,
  formHooks,
  onDropdownAnswerChange,
  questionContent,
  questionInRow,
  isFieldDisabled,
  inputErrors,
}) => {
  const {
    sfPaperwork: { styles: style },
  } = useTheme();
  const { control } = formHooks;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      key={`question-${fieldName}`}
      name={fieldName}
      render={({ onChange, value, ref }) => (
        <Dropdown
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: style.maxDropdownHeight as string | number | undefined,
              },
            },
          }}
          dataQa={`question-dropdown-${questionInRow.questionKey}`}
          disabled={isFieldDisabled}
          displayEmpty
          error={!!inputErrors}
          inputRef={ref}
          items={dropdownItems}
          label={questionContent.question}
          onChange={e => {
            const targetValue = e.target.value;
            onDropdownAnswerChange(fieldName, targetValue);
            onChange(targetValue);
          }}
          value={value}
          width="100%"
        />
      )}
      rules={validationRules}
    />
  );
};
