import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { QuestionKeys } from '../../../configs/questions/types';
import { ManagedProductPaperworkWithFreeFormId } from '../../../hooks/useGetPaperworkData';
import { FormData } from '../../../types';
import { ValidationMessage } from '../../types';
import { filterRelationshipSymphonyData, getQuestionsContentForCustomComponent } from '../utils';

import { Beneficiary } from './Beneficiary';
import { getPercentageFieldName, getTempBeneficiaryId, getUniqueIdsFieldName } from './utils';

import { RelationshipSubtype } from '~/__generated__';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Box, Button, useTheme, visuallyHidden } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { CTAs } from '~/containers/AccountProfile/contentstack';
import { CMSQuestions } from '~/containers/Paperwork/contentstack';
import { useGetPaperworkAccessibilityContent } from '~/containers/Paperwork/hooks/useGetPaperworkAccessibilityContent';
import { ContentOptions } from '~/utils/contentstack';
import { SfTheme } from '~/utils/theme';

export interface BeneficiaryTypeError {
  spouseCount: boolean;
}

export interface BeneficiaryConfig {
  checkMaritalStatus: boolean;
  hideSsn: boolean;
  includeLastName: boolean;
  isPerStirpesEnabled: boolean;
  isTrustTypeFieldsEnabled: boolean;
}

export interface Props {
  beneficiaryConfig?: BeneficiaryConfig;
  contentOptions: ContentOptions;
  countriesList: DropdownItem<string>[];
  ctas?: CTAs[];
  dataQa?: string;
  disablePageEditing: boolean;
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  hidden: boolean;
  isAccountProfileEdit?: boolean;
  isFormSubmitted: boolean;
  maximumBeneficiaries: number;
  minimumBeneficiaries: number;
  paperworkFreeFormId?: string;
  questionKey: string;
  questionsContent: CMSQuestions;
  savedPaperworkData?: ManagedProductPaperworkWithFreeFormId;
  validationMessages?: ValidationMessage[];
}

export const defaultBeneficiariesConfig: BeneficiaryConfig = {
  checkMaritalStatus: false,
  hideSsn: false,
  includeLastName: false,
  isPerStirpesEnabled: false,
  isTrustTypeFieldsEnabled: false,
};

export const BeneficiariesV2: FC<Props> = ({
  ctas,
  dataQa = 'beneficiariesV2',
  formHooks,
  beneficiaryConfig = defaultBeneficiariesConfig,
  countriesList,
  questionsContent,
  validationMessages,
  contentOptions,
  disablePageEditing,
  hidden,
  isAccountProfileEdit,
  isFormSubmitted,
  minimumBeneficiaries,
  maximumBeneficiaries,
  questionKey,
  paperworkFreeFormId,
  savedPaperworkData,
}) => {
  const {
    sfPaperwork: { styles: style },
  } = useTheme<SfTheme>();

  const [isInitialised, setIsInitialised] = useState(false);
  const [percentages, setPercentages] = useState<Record<string, number>>({});
  const [numberOfBeneficiaries, setNumberOfBeneficiaries] = useState(0);
  const [percentageSumError, setPercentageSumError] = useState(false);
  const [beneficiaryTypeError, setBeneficiaryTypeError] = useState<BeneficiaryTypeError>({
    spouseCount: false,
  });

  const [beneficiaryIdList, setBeneficiaryIdList] = useState<string[]>([]);
  const [addOrRemoveClickedLabel, setAddOrRemoveClickedLabel] = useState('');

  const { setValue, register } = formHooks;
  const beneficiaryTypeIndexValueObjRef = useRef<string[]>([]);

  const isPrimaryBeneficiary = questionKey === QuestionKeys.PRIMARY_BENEFICIARY;

  const beneficiaryData = useMemo(() => {
    return filterRelationshipSymphonyData(savedPaperworkData ?? null, questionKey);
  }, [questionKey, savedPaperworkData]);

  const questions = useMemo(() => {
    return getQuestionsContentForCustomComponent(questionsContent, questionKey);
  }, [questionKey, questionsContent]);

  const titleContent = questions.find(
    q =>
      q.key ===
      (isPrimaryBeneficiary ? QuestionKeys.PRIMARY_BENEFICIARY_TITLE : QuestionKeys.CONTINGENT_BENEFICIARY_TITLE),
  );
  const subtitleContent = questions.find(
    q =>
      q.key ===
      (isPrimaryBeneficiary ? QuestionKeys.PRIMARY_BENEFICIARY_SUBTITLE : QuestionKeys.CONTINGENT_BENEFICIARY_SUBTITLE),
  );
  const addCtaContent = questions.find(
    q =>
      q.key ===
      (isPrimaryBeneficiary ? QuestionKeys.PRIMARY_BENEFICIARY_ADD_CTA : QuestionKeys.CONTINGENT_BENEFICIARY_ADD_CTA),
  );
  const removeCtaContent = questions.find(
    q =>
      q.key ===
      (isPrimaryBeneficiary
        ? QuestionKeys.PRIMARY_BENEFICIARY_REMOVE_CTA
        : QuestionKeys.CONTINGENT_BENEFICIARY_REMOVE_CTA),
  );
  const accountProfileRemoveCtaContent = questions.find(
    q =>
      q.key ===
      (isPrimaryBeneficiary
        ? QuestionKeys.PRIMARY_BENEFICIARY_ACCOUNT_PROFILE_REMOVE_CTA
        : QuestionKeys.CONTINGENT_BENEFICIARY_ACCOUNT_PROFILE_REMOVE_CTA),
  );

  const ctaStyle = style.sectionCTA ? { ...style.sectionCTA } : {};
  const { data: accessibilityContent } = useGetPaperworkAccessibilityContent(contentOptions);

  const onAdd = useCallback(() => {
    let newBeneficiaryId = getTempBeneficiaryId();
    // add back the saved beneficiary id if
    // the current last displayed beneficiary is from the saved beneficiary list && there are more saved beneficiaries
    const lastDisplayedBeneficiaryId = beneficiaryIdList[beneficiaryIdList.length - 1];
    const lastDisplayedBeneficiaryIndex = beneficiaryData.findIndex(bd => bd.id === lastDisplayedBeneficiaryId);
    if (lastDisplayedBeneficiaryIndex > 0 && lastDisplayedBeneficiaryIndex + 1 < beneficiaryData.length) {
      newBeneficiaryId = beneficiaryData[lastDisplayedBeneficiaryIndex + 1]?.id ?? newBeneficiaryId;
    }
    const newBeneficiaryIds = [...beneficiaryIdList, newBeneficiaryId];
    setBeneficiaryIdList(newBeneficiaryIds);
    setNumberOfBeneficiaries(newBeneficiaryIds.length);
    setValue(getUniqueIdsFieldName(isPrimaryBeneficiary, paperworkFreeFormId), newBeneficiaryIds.join(','));
    if (accessibilityContent?.beneficiaryAdded) {
      setAddOrRemoveClickedLabel(accessibilityContent.beneficiaryAdded);
    }
  }, [
    accessibilityContent?.beneficiaryAdded,
    beneficiaryData,
    beneficiaryIdList,
    isPrimaryBeneficiary,
    paperworkFreeFormId,
    setValue,
  ]);

  const onRemove = useCallback(
    (removeIndex?: number) => {
      const newBeneficiaryIds = beneficiaryIdList;
      const indexOfRemovedItem = removeIndex ?? numberOfBeneficiaries - 1;
      const beneficiaryUniqueId = newBeneficiaryIds[indexOfRemovedItem];

      setPercentages(prevPercentages => {
        delete prevPercentages[getPercentageFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)];
        return { ...prevPercentages };
      });

      if (removeIndex !== undefined) {
        newBeneficiaryIds.splice(removeIndex, 1);
        beneficiaryTypeIndexValueObjRef.current.splice(removeIndex, 1);
      } else {
        newBeneficiaryIds.pop();
      }
      setBeneficiaryIdList(newBeneficiaryIds);
      setNumberOfBeneficiaries(newBeneficiaryIds.length);
      setValue(getUniqueIdsFieldName(isPrimaryBeneficiary, paperworkFreeFormId), newBeneficiaryIds.join(','));
      if (accessibilityContent?.beneficiaryRemoved) {
        setAddOrRemoveClickedLabel(accessibilityContent.beneficiaryRemoved);
      }
      checkSpouseCount();
    },
    [
      beneficiaryIdList,
      numberOfBeneficiaries,
      setValue,
      isPrimaryBeneficiary,
      paperworkFreeFormId,
      accessibilityContent?.beneficiaryRemoved,
    ],
  );

  useEffect(() => {
    const values = Object.values(percentages);
    if (values.length > 0) {
      const totalPercentage = values.reduce((acc, curr) => acc + curr);

      if (totalPercentage !== 100) {
        setPercentageSumError(true);
      } else if (percentageSumError) {
        setPercentageSumError(false);
      }
    }
  }, [percentageSumError, percentages]);

  // Initialisation
  useEffect(() => {
    if (!isInitialised) {
      // register a field for keeping track of the unique ids
      register(getUniqueIdsFieldName(isPrimaryBeneficiary, paperworkFreeFormId));

      if (beneficiaryData.length) {
        const beneficiaryIds = beneficiaryData.map(beneficiary => beneficiary.id ?? '');
        setBeneficiaryIdList(beneficiaryIds);
        setNumberOfBeneficiaries(beneficiaryData.length);
        setValue(getUniqueIdsFieldName(isPrimaryBeneficiary, paperworkFreeFormId), beneficiaryIds.join(','));
      }

      setIsInitialised(true);
    }
  }, [beneficiaryData, isInitialised, isPrimaryBeneficiary, paperworkFreeFormId, register, setValue]);

  // Add minimum beneficiaries when beneficiaries is shown
  useEffect(() => {
    if (!hidden && isInitialised && minimumBeneficiaries) {
      for (let i = numberOfBeneficiaries; i < minimumBeneficiaries; i++) {
        onAdd();
      }
    }
  }, [hidden, isInitialised, minimumBeneficiaries, numberOfBeneficiaries, onAdd]);

  useEffect(() => {
    if (addOrRemoveClickedLabel) {
      // remove label value once it is announced.
      setTimeout(() => setAddOrRemoveClickedLabel(''), 1000);
    }
  }, [addOrRemoveClickedLabel]);

  const checkSpouseCount = () => {
    let spouseCount = 0;
    beneficiaryTypeIndexValueObjRef.current.forEach(beneficiaryType => {
      if (beneficiaryType === RelationshipSubtype.SPOUSE) {
        spouseCount++;
      }
    });
    setBeneficiaryTypeError({
      spouseCount: spouseCount > 1,
    });
  };

  const onPercentageChange = (name: string, value: number) => {
    setPercentages(prevPercentages => {
      return { ...prevPercentages, [name]: value };
    });
  };

  const onBeneficiaryTypeChange = (index: number, value: string) => {
    beneficiaryTypeIndexValueObjRef.current[index] = value;
    checkSpouseCount();
  };

  const addCtaText =
    isAccountProfileEdit && ctas
      ? isPrimaryBeneficiary
        ? ctas.find(cta => cta?.key === 'addPrimaryBeneficiary')?.label
        : ctas.find(cta => cta?.key === 'addContingentBeneficiary')?.label
      : addCtaContent?.question;

  return (
    <Box data-qa={dataQa}>
      {!isAccountProfileEdit && (
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ mr: 1 }} variant="subtitle1">
            {titleContent?.question}
          </Typography>
          <Typography sx={{ alignSelf: 'center' }} variant="caption">
            {subtitleContent?.question}
          </Typography>
        </Box>
      )}
      {beneficiaryIdList.map((beneficiaryId, number) => {
        const beneficiaryPrefillData = beneficiaryData.find(bd => bd.id === beneficiaryId);
        return (
          <Box key={beneficiaryId}>
            <Beneficiary
              beneficiaryConfig={beneficiaryConfig}
              beneficiaryTypeError={beneficiaryTypeError}
              beneficiaryUniqueId={beneficiaryId}
              countriesList={countriesList}
              disablePageEditing={disablePageEditing}
              formHooks={formHooks}
              hidden={hidden}
              index={number}
              isAccountProfileEdit={isAccountProfileEdit}
              isFormSubmitted={isFormSubmitted}
              isPrimaryBeneficiary={isPrimaryBeneficiary}
              key={beneficiaryId}
              onBeneficiaryTypeChange={onBeneficiaryTypeChange}
              onPercentageChange={onPercentageChange}
              paperworkFreeFormId={paperworkFreeFormId}
              percentageSumError={percentageSumError}
              prefillData={beneficiaryPrefillData}
              questionKey={questionKey}
              questions={questions}
              validationMessages={validationMessages}
            />
            {numberOfBeneficiaries > minimumBeneficiaries && isAccountProfileEdit && (
              <Button disabled={disablePageEditing} onClick={() => onRemove(number)} sx={ctaStyle}>
                {accountProfileRemoveCtaContent?.question ?? 'Remove'}
              </Button>
            )}
          </Box>
        );
      })}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: isAccountProfileEdit ? 3 : 2 }}>
        {numberOfBeneficiaries < maximumBeneficiaries && (
          <Button
            aria-label={
              isPrimaryBeneficiary
                ? accessibilityContent?.primaryBeneficiaryAddButton
                : accessibilityContent?.beneficiaryAddButton
            }
            disabled={disablePageEditing}
            onClick={() => onAdd()}
            sx={ctaStyle}
            variant={isAccountProfileEdit ? 'outlined' : 'text'}
          >
            {addCtaText ?? '+ Add'}
          </Button>
        )}
        {numberOfBeneficiaries > minimumBeneficiaries && !isAccountProfileEdit && (
          <Button
            aria-label={
              isPrimaryBeneficiary
                ? accessibilityContent?.primaryBeneficiaryRemoveButton
                : accessibilityContent?.beneficiaryRemoveButton
            }
            onClick={() => onRemove()}
            sx={ctaStyle}
          >
            {removeCtaContent?.question ?? 'Remove'}
          </Button>
        )}
      </Box>
      <Typography aria-live="polite" style={visuallyHidden}>
        {addOrRemoveClickedLabel}
      </Typography>
    </Box>
  );
};
