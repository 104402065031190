import parseISO from 'date-fns/parseISO';
import React from 'react';
import {
  CartesianGrid,
  DotProps,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { SecurityReturns } from '../types';

import { useGetReturnsChartData } from './hooks/useGetReturnsChartData';

import { formatSecurityName } from '~/components/AccountPerformance/utils';
import { getDateFormatType, getXAxisTicks } from '~/components/PerformanceChart/utils';
import { alpha, Box, FiberManualRecordIcon, Paper, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { abbreviatedDateFormat, formatPercentage } from '~/utils/format';
import { getTextProps } from '~/utils/theme';
import { DailyChartValue } from '~/utils/types';

export interface Props {
  accountReturns: DailyChartValue[];
  contentOptions: ContentOptions;
  height?: number | string;
  securitiesReturns: SecurityReturns[];
  showSecurities: boolean;
  width?: number | string;
}

const CustomTooltip = ({ active, label, labelFormatter, payload, formatter }: TooltipProps<number, number>) => {
  const {
    sfReturnsChart: { styles: returnsChartStyles },
  } = useTheme();

  if (active) {
    const data = payload ?? [];
    return (
      <Paper sx={{ py: 2, px: 3 }}>
        <Typography component="div" sx={{ color: 'text.secondary', mb: 0.5 }} variant="caption">
          {label && labelFormatter?.(label, [])}
        </Typography>
        {data.length > 0 &&
          data
            .sort((a, b) => {
              const first = a.value ?? 0;
              const second = b.value ?? 0;
              return first < second ? 1 : -1;
            })
            .map(value => {
              return (
                <Box
                  key={`ToolTip-${value.dataKey}`}
                  sx={{ display: 'flex', alignItems: 'center', justifyItems: 'start' }}
                >
                  <FiberManualRecordIcon
                    fontSize="inherit"
                    sx={{
                      color:
                        returnsChartStyles.colors?.[value.dataKey ?? ''] ||
                        returnsChartStyles.colors?.default ||
                        'primary.main',
                      mx: 2,
                    }}
                  />
                  <Typography variant="subtitle1">
                    {value.name}: {value.value && formatter?.(value.value)}
                  </Typography>
                </Box>
              );
            })}
      </Paper>
    );
  }
  return null;
};

const CustomActiveDot = ({ cx, cy, fill }: DotProps) => {
  return (
    <g>
      <circle cx={cx} cy={cy} fill={alpha(fill ?? '', 0.3)} r="8.5" />
      <circle cx={cx} cy={cy} fill={fill} r="4.5" />
    </g>
  );
};

export const Chart: React.FC<Props> = ({
  contentOptions,
  height = 400,
  width = '100%',
  accountReturns,
  securitiesReturns,
  showSecurities,
}) => {
  const theme = useTheme();
  const { styles } = theme.sfReturnsChart;

  const {
    components: {
      sfAccountPerformance: { showOnlyYearsInChart },
    },
  } = useCoreConfig();

  const data = useGetReturnsChartData(accountReturns, securitiesReturns, showSecurities);

  const yAxisMin = Math.min(...data.map(r => r.valMin));
  const yAxisMax = Math.max(...data.map(r => r.valMax));

  const formatPercentageOptions = {
    convertRatioToPercentage: false,
    decimals: 2,
    removeTrailingZeroes: false,
    locale: contentOptions.locale,
  };

  const dateFormatType = getDateFormatType(data, showOnlyYearsInChart);

  return (
    <Box aria-hidden>
      <ResponsiveContainer height={height} id="returns-chart" width={width}>
        <LineChart data={data} margin={{ top: 20, left: 10, right: 10 }}>
          <CartesianGrid stroke={theme.palette.divider} strokeDasharray="10 10" strokeWidth={2} vertical={false} />
          <XAxis
            dataKey="date"
            interval="preserveStartEnd"
            padding={{ right: 60 }}
            stroke={theme.palette.divider}
            strokeDasharray="10 10"
            strokeDashoffset="10"
            strokeWidth={2}
            textAnchor="middle"
            tick={getTextProps(theme, 'caption')}
            tickFormatter={tick =>
              abbreviatedDateFormat(parseISO(tick), { locale: contentOptions.locale }, dateFormatType)
            }
            ticks={getXAxisTicks(accountReturns)}
          />
          <YAxis
            axisLine={false}
            domain={[yAxisMin, yAxisMax]}
            mirror
            orientation="right"
            tick={{ ...getTextProps(theme, 'caption'), dy: -10 }}
            tickFormatter={tick => formatPercentage(tick, formatPercentageOptions)}
            tickSize={0}
          />
          <Tooltip
            content={CustomTooltip}
            formatter={(value: number) => formatPercentage(value, formatPercentageOptions)}
            labelFormatter={(label: string) =>
              abbreviatedDateFormat(parseISO(label), { locale: contentOptions.locale })
            }
          />
          <Line
            activeDot={CustomActiveDot}
            dataKey="accountReturns"
            dot={false}
            name="Account"
            stroke={styles.colors?.default}
            strokeWidth={2}
            type="monotone"
          />
          {showSecurities &&
            securitiesReturns.map(securityReturn => {
              return (
                <Line
                  activeDot={CustomActiveDot}
                  dataKey={securityReturn.securityId}
                  dot={false}
                  key={securityReturn.securityId}
                  name={formatSecurityName(securityReturn.securityName)}
                  stroke={styles.colors?.[securityReturn.securityId]}
                  strokeWidth={2}
                  type="monotone"
                />
              );
            })}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
