import { FormData } from '../../types';
import { QuestionConditionValue, QuestionOrder, QuestionOrderSteps, ValidationMessage } from '../types';
import { getFieldName } from '../utils';

import { CMSQuestions } from '~/containers/Paperwork/contentstack';

/**
 * This function checks if the concatenated current and target values match. For example:
 * ('one,two', 'two' -> true),  ('one', 'one,two' -> true), ('one,two', 'one,three' -> true)
 * @param {string} targetValue - The string that we want to match against.
 * @param {string} currentValue - The current value that we want to match.
 * @param {string | undefined} splitIdentifier - Defaults to ,
 * @returns {boolean} Whether or not the current and target values match.
 */
export const matchConcatenatedStringValues = (
  targetValue: string,
  currentValue: string,
  splitIdentifier: string | undefined = ',',
): boolean => {
  const currentValueSplit = currentValue.split(splitIdentifier);
  const targetValueSplit = targetValue.split(splitIdentifier);
  return (
    currentValueSplit.includes(targetValue) ||
    targetValueSplit.includes(currentValue) ||
    currentValueSplit.some(v => targetValueSplit.includes(v)) ||
    targetValueSplit.some(v => currentValueSplit.includes(v))
  );
};

// Assumes that both values are of primitive data types
export const isValueEqual = (targetValue: QuestionConditionValue, currentValue: QuestionConditionValue): boolean => {
  return targetValue === currentValue;
};

export const getDependentQuestions = (order: QuestionOrder, question: QuestionOrderSteps): QuestionOrderSteps[] => {
  const conditionalQuestions: QuestionOrderSteps[] = [];
  const paperworkFreeFormId = question.paperworkFreeFormId;

  const recursiveWalkthrough = (questionToSearch: QuestionOrderSteps, beyondConditional: boolean): void => {
    questionToSearch.rules
      .filter(r => beyondConditional || r.conditions !== undefined)
      .forEach(questionConditionalRule => {
        const nextConditionalQuestionKey = questionConditionalRule.next;
        if (nextConditionalQuestionKey) {
          const nextConditionalQuestion = order.orderSteps.find(
            q => q.questionKey === nextConditionalQuestionKey && q.paperworkFreeFormId === paperworkFreeFormId,
          );
          if (nextConditionalQuestion) {
            conditionalQuestions.push(nextConditionalQuestion);
            recursiveWalkthrough(nextConditionalQuestion, true);
          } else {
            throw new Error(`Missing paperwork question for key: ${nextConditionalQuestionKey}`);
          }
        }
      });
  };

  recursiveWalkthrough(question, false);
  return conditionalQuestions;
};

export const getDependentFormFieldsInvisibilityRecursive = (params: {
  currentFieldName: string;
  // can be null/undefined
  currentFormValue: any;
  formValues: FormData;
  isCurrentFieldHidden: boolean;
  questions: QuestionOrderSteps[];
}): Record<string, boolean> => {
  const question = params.questions.find(
    q => getFieldName(q.dataPointKey, q.paperworkFreeFormId) === params.currentFieldName,
  );

  if (!question) {
    return {};
  }

  const dependentFormFieldInvisibility = getDependentFormFieldsInvisibility({
    currentFormValue: params.currentFormValue,
    formValues: params.formValues,
    isCurrentFieldHidden: params.isCurrentFieldHidden,
    currentQuestion: question,
    questions: params.questions,
  });

  return Object.entries(dependentFormFieldInvisibility)
    .map(([newDependentFieldName, isNewDependentFieldHidden]) => {
      return getDependentFormFieldsInvisibilityRecursive({
        currentFormValue: params.formValues[newDependentFieldName] ?? null,
        currentFieldName: newDependentFieldName,
        formValues: params.formValues,
        isCurrentFieldHidden: isNewDependentFieldHidden,
        questions: params.questions,
      });
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), dependentFormFieldInvisibility);
};

export const getDependentFormFieldsInvisibility = (params: {
  // can be null/undefined
  currentFormValue: any;
  currentQuestion: QuestionOrderSteps;
  formValues: FormData;
  isCurrentFieldHidden: boolean;
  questions: QuestionOrderSteps[];
}) => {
  const { currentFormValue, formValues, isCurrentFieldHidden, currentQuestion, questions } = params;
  const newDependentFormFieldsInvisibility: Record<string, boolean> = {};
  const paperworkFreeFormId = currentQuestion.paperworkFreeFormId;
  const fieldName = getFieldName(currentQuestion.dataPointKey, paperworkFreeFormId);
  currentQuestion.rules
    .filter(r => r.next !== null)
    .forEach(rule => {
      const isRuleSatisfied =
        !isCurrentFieldHidden &&
        (!rule.conditions ||
          rule.conditions.every(condition => {
            const conditionFieldName = getFieldName(condition.dataPointKey, paperworkFreeFormId);
            const formValueToCompare =
              conditionFieldName === fieldName ? currentFormValue : formValues[conditionFieldName];
            const formValueToCompareType = typeof formValueToCompare;
            // We can only run conditional checks against primitive data types
            if (condition.customValueComparator) {
              return condition.customValueComparator(formValueToCompare);
            } else if (
              formValueToCompareType !== 'string' &&
              formValueToCompareType !== 'number' &&
              formValueToCompareType !== 'boolean'
            ) {
              return false;
            } else if (!condition.type || condition.value === undefined) {
              return false;
            } else if (condition.type === 'NOT_EQUAL') {
              return !isValueEqual(condition.value, formValueToCompare);
            } else {
              return isValueEqual(condition.value, formValueToCompare);
            }
          }));
      const nextQuestion = questions.find(
        q => q.questionKey === rule.next && q.paperworkFreeFormId === paperworkFreeFormId,
      );
      if (isRuleSatisfied && nextQuestion) {
        newDependentFormFieldsInvisibility[getFieldName(nextQuestion.dataPointKey, paperworkFreeFormId)] = false;
      } else if (nextQuestion) {
        newDependentFormFieldsInvisibility[getFieldName(nextQuestion.dataPointKey, paperworkFreeFormId)] = true;
      }
    });
  return newDependentFormFieldsInvisibility;
};

export const getSectionValidationMessages = (questionContent: CMSQuestions): ValidationMessage[] => {
  const textFields = questionContent.fields?.text;
  return (
    textFields
      ?.filter(field => field?.key?.startsWith('validation:'))
      .map(field => ({ key: field?.key ?? '', label: field?.value ?? '' })) ?? []
  );
};
