import { GetDownloadableQPRContent, GetDownloadableQPRContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetDownloadableQPRContent = (
  options?: QueryHookOptions<GetDownloadableQPRContent, GetDownloadableQPRContentVariables>,
): QueryResult<GetDownloadableQPRContent, GetDownloadableQPRContentVariables> => {
  return useContentstackQuery(queries.GetDownloadableQPRContent, options);
};
