import React from 'react';

import { Grid, useTheme, WarningIcon } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { SfTheme } from '~/utils/theme';

export interface PortfolioSelectionNullStateProps {
  dataQa?: string;
  message: string;
}

export const PortfolioSelectionNullState: React.FC<PortfolioSelectionNullStateProps> = ({
  dataQa = 'portfolio-selection-null-state',
  message,
}) => {
  const {
    sfPortfolioSelection: { typographyVariants },
  } = useTheme<SfTheme>();
  return (
    <Grid alignItems="center" container data-qa={dataQa} direction="column" height="100%" justifyContent="center">
      <WarningIcon sx={{ color: 'warning.main', mb: 2 }} />
      <Typography variant={typographyVariants?.nullState}>{message}</Typography>
    </Grid>
  );
};
