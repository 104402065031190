import React, { useMemo } from 'react';

import {
  AccountValue,
  AdvancedPricingLink,
  FeeBreakdown,
  FeeCost,
  HorizontalLine,
  TableHorizontalLine,
} from './Components';
import { usePricingSummaryDiscount } from './hooks/usePricingSummaryDiscount';

import { Table, TableBody, TableContainer, TableRow } from '~/components/ui/mui';
import { FormatPercentageOptions } from '~/utils/format';
import { BillingRate, BlendedRates, DiscountType, FeePeriod, periodToNumber } from '~/utils/pricing';

export interface Props {
  accountValue?: number;
  billingRates?: {
    defaultRates: BillingRate[];
    overriddenRates?: BillingRate[];
  };
  blendedRates: BlendedRates[];
  content: {
    accountValueLabel: string;
    discountAppliedLabel: string;
    discountLabel?: string;
    editDiscountLabel: string;
    feeLabel: string;
    feeTiersLabel?: string;
  };
  dataQa?: string;
  estimatedTotalFee: number;
  feePeriod: FeePeriod;
  handleAccountValueChange?: React.Dispatch<React.SetStateAction<number | undefined>>;
  hideFeesWhenZero?: boolean;
  loading: boolean;
  ratePrecision?: number;
  showAdvancedPricing?: () => void;
  showFeeTiers?: () => void;
  showPeriodCost?: boolean;
}

export const PricingSummary: React.FC<Props> = ({
  accountValue,
  blendedRates,
  billingRates,
  content,
  dataQa = 'pricing-summary',
  estimatedTotalFee,
  feePeriod,
  loading,
  handleAccountValueChange,
  hideFeesWhenZero = false,
  ratePrecision = 3,
  showAdvancedPricing,
  showFeeTiers,
  showPeriodCost = true,
}) => {
  const defaultFees = useMemo(() => billingRates?.defaultRates, [billingRates?.defaultRates]);
  const discountFees = useMemo(() => billingRates?.overriddenRates, [billingRates?.overriddenRates]);

  const discount = usePricingSummaryDiscount({ defaultFees, discountFees });

  const estimatedPeriodCost = useMemo(() => ((accountValue ?? 0) * estimatedTotalFee) / periodToNumber(feePeriod), [
    accountValue,
    estimatedTotalFee,
    feePeriod,
  ]);
  const formatPercentageOptions: FormatPercentageOptions = {
    decimals: ratePrecision,
    removeTrailingZeroes: false,
  };

  const showDiscount = discount.discountType === DiscountType.BasisPoint && discount.discountValue > 0;

  return (
    <>
      <AccountValue
        accountValue={accountValue}
        accountValueLabel={content.accountValueLabel}
        handleAccountValueChange={handleAccountValueChange}
      />
      {!handleAccountValueChange && <HorizontalLine />}
      <TableContainer data-qa={`${dataQa}-table-container`}>
        <Table>
          <TableBody data-qa={`${dataQa}-table-body`}>
            <FeeBreakdown
              discount={discount}
              discountLabel={content.discountAppliedLabel}
              feeTiersLabel={content.feeTiersLabel}
              fees={blendedRates}
              formatPercentageOptions={formatPercentageOptions}
              hideFeesWhenZero={hideFeesWhenZero}
              loading={loading}
              showDiscount={showDiscount}
              showFeeTiers={showFeeTiers}
            />
            {showAdvancedPricing && (
              <TableRow>
                <AdvancedPricingLink
                  advancedPricingModalLabel={discount.discountValue ? content.editDiscountLabel : content.discountLabel}
                  dataQa={dataQa}
                  showAdvancedPricing={showAdvancedPricing}
                />
              </TableRow>
            )}
            <TableHorizontalLine />
            <TableHorizontalLine />
            <FeeCost
              estimatedPeriodCost={estimatedPeriodCost}
              estimatedTotalFee={estimatedTotalFee}
              feePeriod={feePeriod}
              formatPercentageOptions={formatPercentageOptions}
              loading={loading}
              showPeriodCost={showPeriodCost}
              totalFeeLabel={content.feeLabel}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
