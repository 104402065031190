import {
  ComputeCompositeModelPortfolio,
  ComputeCompositeModelPortfolio_computeCompositeModelPortfolio,
  ComputeCompositeModelPortfolioVariables,
} from './__generated__/mutation.v2';
import { SaveUserSleeveTemplate, SaveUserSleeveTemplateVariables } from './__generated__/mutation.v2';
import * as mutations from './mutation.gql';

import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyMutation } from '~/utils/symphony';

export type CompositeModelPortfolio = ComputeCompositeModelPortfolio_computeCompositeModelPortfolio;

export const useComputeCompositeModelPortfolio = (
  options?: MutationHookOptions<ComputeCompositeModelPortfolio, ComputeCompositeModelPortfolioVariables>,
): MutationTuple<ComputeCompositeModelPortfolio, ComputeCompositeModelPortfolioVariables> => {
  return useSymphonyMutation(mutations.ComputeCompositeModelPortfolio, options);
};

export const useSaveUserSleeveTemplate = (
  options?: MutationHookOptions<SaveUserSleeveTemplate, SaveUserSleeveTemplateVariables>,
): MutationTuple<SaveUserSleeveTemplate, SaveUserSleeveTemplateVariables> => {
  return useSymphonyMutation(mutations.SaveUserSleeveTemplate, options);
};
