import React, {
  cloneElement,
  ComponentType,
  isValidElement,
  lazy,
  ReactElement,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { InvestmentAmountProvider } from '../../hooks/portfolio/investmentAmountContext';

import { AutoPortfolioSelection } from './AutoPortfolioSelection';
import { ClientDocusign } from './ClientDocusign';
import { Docusign as FADocusign } from './FADocusign';
import { useGetManagedProductInfo } from './hooks/useGetManagedProductInfo';
import { RetakeRTQCompleted } from './RetakeRTQCompleted';

import { LegalDocumentStatus, ManagedProductType } from '~/__generated__';
import { Alert } from '~/components/ui/Alert';
import { CircularProgress } from '~/components/ui/CircularProgress';
import { Skeleton } from '~/components/ui/mui';
import { FeedbackMessageType } from '~/components/ui/Snackbar';
import { DocusignParams, Props as PortfolioComparisonProps } from '~/containers/Comparison';
import { DocusignUserType } from '~/containers/DocusignDAProCompleted';
import { PortfolioSelectionProps } from '~/containers/PortfolioSelection/types';
import { Props as QuestionnaireProps } from '~/containers/Questionnaire';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { ContentOptions } from '~/utils/contentstack/src/types';

export interface ReplacementRetakeRtqComponentProps {
  [partnerRepoProps: string]: any;
  onBack?: () => void;
  onNext?: () => void;
}

type OverrideRetakeRtqComponent<T> = ComponentType<T> | ReactElement<ReplacementRetakeRtqComponentProps>;

export interface Props {
  assetClassTier?: AssetClassTier;
  contentOptions: ContentOptions;
  docusignParams?: DocusignParams;
  initialState?: RetakeRTQStates;
  isDocusignRequired?: boolean;
  managedProductId: string;
  onBack: () => void;
  onClientDocusignBackButtonClick?: () => void;
  onDocusignIframeReady?: () => void;
  onLastStage?: (feedbackMessage?: string) => void;
  onRetakeRTQCompletedCTA?: () => void;
  overrideRetakeRTQComponents?: {
    [RetakeRTQStates.COMPARISON]?: OverrideRetakeRtqComponent<PortfolioComparisonProps>;
    [RetakeRTQStates.PORTFOLIO_SELECTION]?: OverrideRetakeRtqComponent<PortfolioSelectionProps>;
    [RetakeRTQStates.RTQ]?: OverrideRetakeRtqComponent<QuestionnaireProps>;
  };
  partyId: string;
  portfolioComparisonProps?: Partial<PortfolioComparisonProps>;
  portfolioSelectionProps?: Partial<PortfolioSelectionProps>;
  questionnaireProps?: Partial<QuestionnaireProps>;
  retakeRtqStatesOrder?: RetakeRTQStates[];
  viewerPartyId: string;
}

export enum RetakeRTQStates {
  AUTO_PORTFOLIO_SELECTION = 'AUTO_PORTFOLIO_SELECTION',
  CLIENT_DOCUSIGN = 'CLIENT_DOCUSIGN',
  COMPARISON = 'COMPARISON',
  FA_DOCUSIGN = 'FA_DOCUSIGN',
  FA_DOCUSIGN_DECLINED = 'FA_DOCUSIGN_DECLINED',
  FA_VIEW_CLIENT_DOCUSIGN_DECLINED = 'FA_VIEW_CLIENT_DOCUSIGN_DECLINED',
  FA_VIEW_NOT_DOCUSIGN_SIGNEE = 'FA_VIEW_NOT_DOCUSIGN_SIGNEE',
  PORTFOLIO_SELECTION = 'PORTFOLIO_SELECTION',
  PORTFOLIO_UNCHANGED = 'PORTFOLIO_UNCHANGED',
  PORTFOLIO_UPDATED = 'PORTFOLIO_UPDATED',
  RTQ = 'RTQ',
  RTQ_RESULT = 'RTQ_RESULT',
}

const ComparisonComponent = lazy(() => import('~/containers/Comparison'));
const PortfolioSelectionComponent = lazy(() => import('~/containers/PortfolioSelection'));
const QuestionnaireComponent = lazy(() => import('~/containers/Questionnaire'));

export const RetakeRTQWrapper: React.FC<Props> = ({
  contentOptions,
  docusignParams,
  initialState = RetakeRTQStates.RTQ,
  isDocusignRequired = false,
  managedProductId,
  onBack,
  onClientDocusignBackButtonClick,
  onDocusignIframeReady,
  onLastStage,
  onRetakeRTQCompletedCTA,
  partyId,
  questionnaireProps,
  portfolioComparisonProps,
  portfolioSelectionProps,
  overrideRetakeRTQComponents,
  retakeRtqStatesOrder = [
    RetakeRTQStates.RTQ,
    RetakeRTQStates.RTQ_RESULT,
    RetakeRTQStates.AUTO_PORTFOLIO_SELECTION,
    RetakeRTQStates.COMPARISON,
  ],
  assetClassTier,
  viewerPartyId,
}) => {
  const [currentRetakeRTQState, setCurrentRetakeRTQState] = useState(initialState);
  const [investmentAmount, setInvestmentAmount] = useState<number | undefined>();
  const { data, error, loading } = useGetManagedProductInfo({ managedProductId });

  const handleNext = useCallback(() => {
    // RTQ and RTQ_RESULT are coupled to Questionnaire component and it invokes onNext when at RTQ_RESULT.
    const current = currentRetakeRTQState === RetakeRTQStates.RTQ ? RetakeRTQStates.RTQ_RESULT : currentRetakeRTQState;

    const newIndex = retakeRtqStatesOrder.indexOf(current) + 1;
    if (newIndex === retakeRtqStatesOrder.length) {
      onLastStage?.();
    } else {
      setCurrentRetakeRTQState(retakeRtqStatesOrder[newIndex]);
    }
  }, [currentRetakeRTQState, onLastStage, retakeRtqStatesOrder]);

  const handleClientDocusignCompleted = () => {
    setCurrentRetakeRTQState(RetakeRTQStates.FA_DOCUSIGN);
  };

  const handleClientDocusignDeclined = () => {
    setCurrentRetakeRTQState(RetakeRTQStates.FA_VIEW_CLIENT_DOCUSIGN_DECLINED);
  };

  const handlePortfolioComparisonOnNext = useCallback(
    (portfolioUpdated?: boolean, isUserNotSignee?: boolean) => {
      if (isDocusignRequired) {
        setCurrentRetakeRTQState(
          portfolioUpdated
            ? isUserNotSignee
              ? RetakeRTQStates.FA_VIEW_NOT_DOCUSIGN_SIGNEE
              : RetakeRTQStates.CLIENT_DOCUSIGN
            : RetakeRTQStates.PORTFOLIO_UNCHANGED,
        );
      } else {
        if (portfolioUpdated) {
          onLastStage?.(FeedbackMessageType.EDIT_PORTFOLIO);
          return;
        }
        onLastStage?.();
      }
    },
    [isDocusignRequired, onLastStage],
  );

  const commonRetakeRTQNavigationProps = useMemo(
    () => ({
      onNext: handleNext,
      managedProductId,
    }),
    [handleNext, managedProductId],
  );

  const handlePortfolioComparisonOnBack = useCallback(() => {
    if (retakeRtqStatesOrder.includes(RetakeRTQStates.PORTFOLIO_SELECTION)) {
      setCurrentRetakeRTQState(RetakeRTQStates.PORTFOLIO_SELECTION);
    } else {
      setCurrentRetakeRTQState(RetakeRTQStates.RTQ_RESULT);
    }
  }, [retakeRtqStatesOrder]);

  const getRetakeRtqComponent = useCallback(() => {
    const brokerageAlias = data?.data.brokerageAlias ?? '';
    const managedProductType = data?.data.managedProductType ?? ManagedProductType.DIGITAL_ADVICE;
    const isCopilot = managedProductType === ManagedProductType.DIGITAL_ADVICE_PRO;

    switch (currentRetakeRTQState) {
      case RetakeRTQStates.RTQ:
      case RetakeRTQStates.RTQ_RESULT: {
        const Questionnaire = overrideRetakeRTQComponents?.[RetakeRTQStates.RTQ] ?? QuestionnaireComponent;

        if (isValidElement(Questionnaire)) {
          return cloneElement(Questionnaire, commonRetakeRTQNavigationProps);
        }

        return (
          <Questionnaire
            contentOptions={contentOptions}
            currentState={currentRetakeRTQState}
            isRCE
            managedProductId={managedProductId}
            onBack={onBack}
            onNext={handleNext}
            partyId={partyId}
            productType={managedProductType}
            {...questionnaireProps}
          />
        );
      }
      case RetakeRTQStates.PORTFOLIO_SELECTION: {
        const PortfolioSelection =
          overrideRetakeRTQComponents?.[RetakeRTQStates.PORTFOLIO_SELECTION] ?? PortfolioSelectionComponent;

        if (isValidElement(PortfolioSelection)) {
          return cloneElement(PortfolioSelection, {
            ...commonRetakeRTQNavigationProps,
            onKeepCurrentPortfolio: handlePortfolioComparisonOnNext,
          });
        }

        return (
          <PortfolioSelection
            actorPartyId={partyId}
            brokerageAlias={brokerageAlias}
            contentOptions={contentOptions}
            isCopilot={isCopilot}
            managedProductId={managedProductId}
            onBack={newState =>
              setCurrentRetakeRTQState(
                newState && newState in RetakeRTQStates ? (newState as RetakeRTQStates) : RetakeRTQStates.RTQ_RESULT,
              )
            }
            onKeepCurrentPortfolio={handlePortfolioComparisonOnNext}
            onNext={handleNext}
            partyId={partyId}
            productType={managedProductType}
            {...portfolioSelectionProps}
          />
        );
      }
      case RetakeRTQStates.COMPARISON:
        const PortfolioComparison = overrideRetakeRTQComponents?.[RetakeRTQStates.COMPARISON] ?? ComparisonComponent;

        if (isValidElement(PortfolioComparison)) {
          return cloneElement(PortfolioComparison, {
            ...commonRetakeRTQNavigationProps,
            viewRiskToleranceResult: () => setCurrentRetakeRTQState(RetakeRTQStates.RTQ_RESULT),
          });
        }

        return (
          <PortfolioComparison
            assetClassTier={assetClassTier}
            contentOptions={contentOptions}
            docusignParams={docusignParams}
            isCopilot={isCopilot}
            managedProductId={managedProductId}
            onBack={() => handlePortfolioComparisonOnBack()}
            onNext={handlePortfolioComparisonOnNext}
            partyId={partyId}
            viewRiskToleranceResult={() => setCurrentRetakeRTQState(RetakeRTQStates.RTQ_RESULT)}
            viewerPartyId={viewerPartyId}
            {...portfolioComparisonProps}
          />
        );
      case RetakeRTQStates.CLIENT_DOCUSIGN:
        return (
          <ClientDocusign
            contentOptions={contentOptions}
            handleButtonClick={onClientDocusignBackButtonClick}
            managedProductId={managedProductId}
            onClientDocusignCompleted={handleClientDocusignCompleted}
            onClientDocusignDeclined={handleClientDocusignDeclined}
            partyId={partyId}
            partyIdFA={docusignParams?.faPartyId}
          />
        );
      case RetakeRTQStates.FA_DOCUSIGN:
        return docusignParams ? (
          <FADocusign
            contentOptions={contentOptions}
            docusignParams={docusignParams}
            managedProductId={managedProductId}
            onIframeReady={onDocusignIframeReady}
          />
        ) : null;
      case RetakeRTQStates.PORTFOLIO_UNCHANGED:
        return (
          <RetakeRTQCompleted
            contentOptions={contentOptions}
            isPortfolioUpdated={false}
            isUserLoggedIn
            onPrimaryCtaCallback={onRetakeRTQCompletedCTA}
          />
        );
      case RetakeRTQStates.PORTFOLIO_UPDATED:
        return (
          <RetakeRTQCompleted
            contentOptions={contentOptions}
            isPortfolioUpdated
            isUserLoggedIn
            onPrimaryCtaCallback={onRetakeRTQCompletedCTA}
          />
        );
      case RetakeRTQStates.FA_VIEW_CLIENT_DOCUSIGN_DECLINED:
        return (
          <RetakeRTQCompleted
            contentOptions={contentOptions}
            declinedBy={DocusignUserType.Client}
            isClientDocusign={false}
            isDocusignFlow
            isPortfolioUpdated={false}
            isUserLoggedIn
            onPrimaryCtaCallback={onRetakeRTQCompletedCTA}
            signingStatus={LegalDocumentStatus.DECLINED}
          />
        );
      case RetakeRTQStates.FA_DOCUSIGN_DECLINED:
        return (
          <RetakeRTQCompleted
            contentOptions={contentOptions}
            declinedBy={DocusignUserType.FA}
            isClientDocusign={false}
            isDocusignFlow
            isPortfolioUpdated={false}
            isUserLoggedIn
            onPrimaryCtaCallback={onRetakeRTQCompletedCTA}
            signingStatus={LegalDocumentStatus.DECLINED}
          />
        );
      case RetakeRTQStates.FA_VIEW_NOT_DOCUSIGN_SIGNEE:
        return (
          <RetakeRTQCompleted
            contentOptions={contentOptions}
            isAdvisorNotSignee
            isUserLoggedIn
            onPrimaryCtaCallback={onRetakeRTQCompletedCTA}
          />
        );
      case RetakeRTQStates.AUTO_PORTFOLIO_SELECTION:
        return (
          <AutoPortfolioSelection
            brokerageAlias={brokerageAlias}
            contentOptions={contentOptions}
            managedProductId={managedProductId}
            managedProductType={managedProductType}
            onNext={handleNext}
          />
        );
      default:
        return <></>;
    }
  }, [
    assetClassTier,
    commonRetakeRTQNavigationProps,
    contentOptions,
    currentRetakeRTQState,
    data,
    docusignParams,
    handleNext,
    handlePortfolioComparisonOnBack,
    handlePortfolioComparisonOnNext,
    managedProductId,
    onBack,
    onClientDocusignBackButtonClick,
    onDocusignIframeReady,
    onRetakeRTQCompletedCTA,
    overrideRetakeRTQComponents,
    partyId,
    portfolioComparisonProps,
    portfolioSelectionProps,
    questionnaireProps,
    viewerPartyId,
  ]);

  return (
    <InvestmentAmountProvider value={{ investmentAmount, setInvestmentAmount }}>
      <Suspense fallback={<CircularProgress />}>
        {loading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : error ? (
          <Alert contentOptions={contentOptions} error={error} severity="error" />
        ) : (
          getRetakeRtqComponent()
        )}
      </Suspense>
    </InvestmentAmountProvider>
  );
};
