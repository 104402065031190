import React, { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import { useGetPortfolioSelectionCardAssetData } from '../PortfolioSelectionCard/hooks/useGetPortfolioSelectionCardAssetData';
import { VolatilityBar } from '../VolatilityBar';

import { useShoppingCartData } from './hooks';
import { InputNames, useShoppingCartForm } from './hooks/useShoppingCartForm';
import { useSaveUserSleeveTemplate } from './symphony';
import { SaveUserSleeveTemplateVariables } from './symphony/__generated__/mutation.v2';
import { getPortfoliosOptions } from './utils';

import { ComputeRecommendedPortfolioAllocationInput, ManagedProductType } from '~/__generated__';
import { SleeveTemplatesTabs } from '~/components/SleeveTemplates';
import { SleeveAllocationData } from '~/components/SleeveTemplates/SleeveTemplateCard';
import {
  GetUserSleeveTemplates,
  GetUserSleeveTemplatesVariables,
} from '~/components/SleeveTemplates/symphony/__generated__/query.v2';
import * as queries from '~/components/SleeveTemplates/symphony/query.gql';
import { SleeveTemplateData } from '~/components/SleeveTemplates/utils';
import { Alert } from '~/components/ui/Alert';
import { Dropdown } from '~/components/ui/Dropdown';
import { Link } from '~/components/ui/Link';
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  LoadingButton,
  MuiAlertColor,
  Skeleton,
  useTheme,
} from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Snackbar, SnackbarPositionType } from '~/components/ui/Snackbar';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { isValidNumberInput } from '~/containers/Paperwork/utils/helper';
import { getStockBondRatio } from '~/containers/Plan/utils';
import { AssetAllocationModalState, ModelPortfolioSleeve, Portfolio } from '~/containers/PortfolioSelection/types';
import { allocationToDonutSlice, getAssetAllocations } from '~/utils/asset-allocation';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { ContentOptions } from '~/utils/contentstack';
import { formatCurrency } from '~/utils/format';
import produce from '~/utils/immer';
import { VolatilityMatchStatus } from '~/utils/volatility';

const labelStyles = { alignItems: 'center', display: 'flex', justifyContent: 'space-between' };

export interface CartAllocation {
  modelPortfolioIdentifier: string;
  modelPortfolioSeriesId: string;
  percentage: number | null;
}

export interface ShoppingCartProps {
  allocationValue?: SleeveAllocationData[] | null;
  assetClassTier?: AssetClassTier;
  contentOptions: ContentOptions;
  dataQa?: string;
  investmentAmountValue: number | undefined;
  isRce?: boolean;
  maximumPercentageAllowed?: number;
  maximumSleeveCount?: number;
  minimumPercentageAllowed?: number;
  modelPortfolios: Portfolio[];
  openAssetAllocationModal: (assetAllocationProps: AssetAllocationModalState) => void;
  partyId: string;
  productType: ManagedProductType;
  quickApplySleeveTemplate?: SleeveTemplateData;
  riskScore: number;
  selectModelPortfolioSleeves: (modelPortfolioSleeves: ModelPortfolioSleeve[]) => void;
  setCurrentTab: (newTab: SleeveTemplatesTabs) => void;
}

export const ShoppingCart: React.FC<ShoppingCartProps> = ({
  assetClassTier,
  dataQa = 'shopping-cart',
  contentOptions,
  modelPortfolios,
  allocationValue,
  isRce,
  investmentAmountValue,
  maximumPercentageAllowed = 85,
  maximumSleeveCount = 3,
  minimumPercentageAllowed = 15,
  partyId,
  productType,
  quickApplySleeveTemplate,
  riskScore,
  selectModelPortfolioSleeves,
  setCurrentTab,
  openAssetAllocationModal,
}) => {
  const {
    sfShoppingCart: { styles, typographyVariants },
  } = useTheme();

  const {
    data: shoppingCartData,
    loading: shoppingCartDataLoading,
    error: shoppingCartDataError,
  } = useShoppingCartData({
    contentOptions,
    productType,
  });

  const {
    data: portfolioSelectionCardAssetData,
    loading: portfolioSelectionCardAssetLoading,
    error: portfolioSelectionCardAssetError,
  } = useGetPortfolioSelectionCardAssetData({
    contentOptions,
  });
  const [saveTemplateButtonLoading, setSaveTemplateButtonLoading] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState('');
  const [snackBarType, setSnackBarType] = useState<MuiAlertColor>('success');

  const shoppingCartContent = shoppingCartData?.contentData;
  const riskPreferenceVolatilityList = shoppingCartData?.riskPreferenceVolatilityList;
  const [saveUserSleeveTemplate] = useSaveUserSleeveTemplate();

  const {
    addSleeve,
    compositeModelPortfolioOpeningMinimum,
    compositeModelPortfolioRebalanceMinimum,
    compositeModelPortfolioVolatility,
    compositeModelPortfolioVolatilityMatchStatus,
    control,
    fields,
    fieldsErrors,
    getSeriesOptions,
    getValues,
    isCompositeModelPortfolioLoaded,
    isContinueButtonClicked: [isContinueButtonClicked, setIsContinueButtonClicked],
    isSubmitButtonClicked: [isSubmitButtonClicked, setIsSubmitButtonClicked],
    numberOfSleeves,
    onSeriesChange,
    removeSleeve,
    saveTemplateFormValidity,
    sleevesList,
    symphonyError: [symphonyError, setSymphonyError],
    totalPercentageValue,
  } = useShoppingCartForm({
    allocationValue,
    maximumPercentageAllowed,
    maximumSleeveCount,
    minimumPercentageAllowed,
    modelPortfolios,
    quickApplySleeveTemplate,
    riskPreferenceVolatilityList,
    riskScore,
    shoppingCartContent,
  });

  const saveTemplate = async () => {
    setIsSubmitButtonClicked(true);
    const allFields = getValues();
    const allocationFieldValues = allFields.allocation;
    if (allocationFieldValues && saveTemplateFormValidity) {
      const sleeveTemplate = {} as SaveUserSleeveTemplateVariables;
      const templateName = allFields.templateName;
      sleeveTemplate.templateName = templateName ? templateName : 'Custom Blend';
      const sleeveDataList: ComputeRecommendedPortfolioAllocationInput[] = [];
      allocationFieldValues.forEach((allocation: any) => {
        const sleeveData = {} as ComputeRecommendedPortfolioAllocationInput;
        sleeveData.modelPortfolioSeriesId = Number(allocation.modelPortfolioSeriesId);
        sleeveData.modelPortfolioInternalId = Number(allocation.modelPortfolioIdentifier);
        sleeveData.percentage = allocation.percentage;
        sleeveDataList.push(sleeveData);
      });
      try {
        setSaveTemplateButtonLoading(true);
        const response = await saveUserSleeveTemplate({
          variables: {
            allocation: sleeveDataList,
            partyId,
            templateName: sleeveTemplate.templateName,
          },
          update: (cache, { data }) => {
            const queryOptions = { query: queries.GetUserSleeveTemplates, variables: { partyId } };
            const userSleeveTemplateCache = cache.readQuery<GetUserSleeveTemplates, GetUserSleeveTemplatesVariables>(
              queryOptions,
            );
            if (userSleeveTemplateCache) {
              cache.writeQuery<GetUserSleeveTemplates>({
                ...queryOptions,
                data: produce(userSleeveTemplateCache, draft => {
                  const userSleeveTemplates = draft.userSleeveTemplates;
                  if (userSleeveTemplates && data?.saveUserSleeveTemplate.id) {
                    const sleeveTemplateData = data?.saveUserSleeveTemplate;
                    let userSleeveTemplate = userSleeveTemplates?.find(sleeve => sleeve.id === sleeveTemplateData.id);
                    if (!userSleeveTemplate) {
                      userSleeveTemplates.push({
                        ...sleeveTemplateData,
                      });
                    } else {
                      userSleeveTemplate = { ...sleeveTemplateData };
                    }
                  }
                }),
              });
            }
          },
        });
        const saveUserSleeveTemplateData = response.data?.saveUserSleeveTemplate.id;
        if (saveUserSleeveTemplateData) {
          setSnackBarType('success');
          setSnackBarContent(shoppingCartContent?.saveSuccessMessage ?? '');
          setShowSnackBar(true);
          setCurrentTab(SleeveTemplatesTabs.User);
        }
      } catch (err: any) {
        setSnackBarType('error');
        setSnackBarContent(shoppingCartContent?.saveErrorMessage ?? '');
        setShowSnackBar(true);
        setSymphonyError(err);
        console.error(err);
      } finally {
        setSaveTemplateButtonLoading(false);
      }
    }
  };

  // (If investment amount is undefined) => No Message and blocker
  // (Investment Amount > ModelOpeningMinimum) => No Message and blocker
  // (Investment Amount < Opening Minimum) => Warning message and blocker in case of Onboarding
  // (Investment Amount < Rebalance Minimum) => Warning message and blocker in case of RCE
  // (Investment Amount > Rebalance Minimum && Investment Amount < ModelOpeningMinimum) => Info Message in case of RCE
  const investmentAmountAlertState = useMemo(() => {
    const isInvestmentAmountLowerThanOpeningMinimum =
      investmentAmountValue !== undefined &&
      investmentAmountValue < parseInt(compositeModelPortfolioOpeningMinimum ?? '0', 10);
    if (isInvestmentAmountLowerThanOpeningMinimum) {
      return investmentAmountValue < parseInt(compositeModelPortfolioRebalanceMinimum ?? '0', 10) || !isRce
        ? 'error'
        : 'info';
    }
    return undefined;
  }, [compositeModelPortfolioOpeningMinimum, compositeModelPortfolioRebalanceMinimum, investmentAmountValue, isRce]);

  const saveSelections = () => {
    setIsContinueButtonClicked(true);
    const sleeveDataList: ComputeRecommendedPortfolioAllocationInput[] = [];
    let isValid = true;
    // Calculating the modelMinimum and modelRebalanceMinimum for selected portfolios
    const allocationFieldValues = getValues()?.allocation;
    allocationFieldValues.forEach((allocation: CartAllocation) => {
      const sleeveData = {} as ComputeRecommendedPortfolioAllocationInput;
      if (allocation.modelPortfolioSeriesId && allocation.modelPortfolioIdentifier && allocation.percentage) {
        sleeveData.modelPortfolioSeriesId = Number(allocation.modelPortfolioSeriesId);
        sleeveData.modelPortfolioInternalId = Number(allocation.modelPortfolioIdentifier);
        sleeveData.percentage = allocation.percentage?.toString();
        sleeveDataList.push(sleeveData);
      } else {
        isValid = false;
      }
    });
    if (isValid) {
      selectModelPortfolioSleeves(sleeveDataList);
    }
  };

  const assetClassContent = portfolioSelectionCardAssetData?.assetClass;

  const content = portfolioSelectionCardAssetData?.content;

  const handleOpenAssetAllocationModal = (sleeveIndex: number): void => {
    const seriesId = getValues()?.allocation[sleeveIndex]?.modelPortfolioSeriesId;
    const internalId = getValues()?.allocation[sleeveIndex]?.modelPortfolioIdentifier;
    const modelPortfolioToUse = modelPortfolios.find(
      modelPortfolio =>
        modelPortfolio.seriesId === Number(seriesId) && modelPortfolio.internalId === Number(internalId),
    );
    const stockBondLabel =
      content?.stockLabel && content?.bondLabel ? `${content.stockLabel}/${content.bondLabel}` : '';
    const assetAllocationData = getAssetAllocations(
      modelPortfolioToUse?.assetAllocations,
      assetClassContent,
      assetClassTier,
    );
    if (modelPortfolioToUse) {
      const stockBondRatio = getStockBondRatio(modelPortfolioToUse?.stockBondDiversification);
      const portfolioAssetsBreakdown = assetAllocationData.map(allocationToDonutSlice(assetClassTier));

      openAssetAllocationModal({
        content,
        error: portfolioSelectionCardAssetError,
        loading: portfolioSelectionCardAssetLoading,
        portfolio: {
          assetAllocationData,
          description: modelPortfolioToUse.description,
          name: modelPortfolioToUse.productName,
          portfolioAssetsBreakdown,
          stockBondRatio,
        },
        donutProps: {
          centerSubtext: stockBondLabel,
          centerText: stockBondRatio,
          centerTextVariant: typographyVariants?.stockBondSplitLabel,
          data: portfolioAssetsBreakdown,
          innerRadius: 75,
          justifyContentGraph: 'center',
          showTooltip: true,
          size: 275,
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      {shoppingCartDataLoading ? (
        <>
          <Skeleton height={300} sx={{ mx: 'auto' }} variant="rectangular" width="80%" />
        </>
      ) : shoppingCartDataError ? (
        <Alert contentOptions={contentOptions} error={shoppingCartDataError} severity="error" />
      ) : (
        <Grid item spacing={6} xs={12}>
          <Typography sx={{ py: 2 }} variant="h3">
            {shoppingCartContent?.title}
          </Typography>
          <FormControl fullWidth sx={{ px: 2, py: 2 }}>
            <Grid container spacing={2} sx={{ pr: 2, py: 2, ...styles.shoppingCartFormContainer }}>
              <Grid item spacing={6} xs={12}>
                <Controller
                  control={control}
                  defaultValue={null}
                  name={InputNames.templateName}
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <TextField
                      data-qa={`${dataQa}-template-name`}
                      error={!!fieldsErrors.templateName}
                      fullWidth
                      helperText={!!fieldsErrors.templateName && (shoppingCartContent?.blendNameErrorText ?? '')}
                      id={InputNames.templateName}
                      inputRef={ref}
                      label={shoppingCartContent?.blendNameLabel ?? ''}
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder={shoppingCartContent?.blendNamePlaceholder ?? ''}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid container direction="column">
                {fields &&
                  fields.map((_sleeve, sleeveIndex) => {
                    return (
                      <Grid
                        container
                        flexDirection="row"
                        key={sleeveIndex}
                        spacing={1}
                        sx={{ display: 'flex', pl: 2, pt: 2 }}
                      >
                        <Grid item spacing={6} sx={{ px: 2, pb: 2 }} xs={4}>
                          <Controller
                            control={control}
                            defaultValue=""
                            name={`${InputNames.allocation}[${sleeveIndex}].${InputNames.modelPortfolioSeriesId}`}
                            render={({ onChange, onBlur, value, name }) => (
                              <Dropdown
                                error={!!fieldsErrors.allocation?.[sleeveIndex]?.modelPortfolioSeriesId}
                                id={`${InputNames.allocation}.${sleeveIndex}.${InputNames.modelPortfolioSeriesId}`}
                                inputProps={{
                                  sx: styles.shoppingCartDropdown,
                                }}
                                items={getSeriesOptions(sleeveIndex) ?? []}
                                label={shoppingCartContent?.seriesLabel}
                                name={name}
                                onBlur={onBlur}
                                onChange={e => {
                                  onSeriesChange(e.target.value.toString(), sleeveIndex);
                                  onChange(e.target.value);
                                }}
                                placeholder={shoppingCartContent?.seriesPlaceholder ?? ''}
                                placeholderValue={shoppingCartContent?.seriesPlaceholder ?? ''}
                                value={value}
                                width="100%"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </Grid>
                        <Grid item spacing={6} sx={{ px: 2, pb: 2 }} xs={5}>
                          <Controller
                            control={control}
                            defaultValue=""
                            name={`${InputNames.allocation}[${sleeveIndex}].${InputNames.modelPortfolioIdentifier}`}
                            render={({ onChange, onBlur, value, name }) => (
                              <Dropdown
                                error={!!fieldsErrors.allocation?.[sleeveIndex]?.modelPortfolioIdentifier}
                                id={`${InputNames.allocation}.${sleeveIndex}.${InputNames.modelPortfolioIdentifier}`}
                                inputProps={{
                                  sx: styles.shoppingCartDropdown,
                                }}
                                items={getPortfoliosOptions(sleevesList[sleeveIndex]?.portfolios) ?? []}
                                label={shoppingCartContent?.portfolioLabel}
                                name={name}
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.value)}
                                placeholder={shoppingCartContent?.portfolioPlaceholder ?? ''}
                                placeholderValue={shoppingCartContent?.portfolioPlaceholder ?? ''}
                                value={value}
                                width="100%"
                              />
                            )}
                            rules={{ required: true }}
                          />
                          {getValues()?.allocation?.[sleeveIndex]?.modelPortfolioIdentifier && (
                            <Link
                              component="button"
                              data-qa={`${dataQa}-asset-allocation-modal-link`}
                              onClick={() => handleOpenAssetAllocationModal(sleeveIndex)}
                              sx={{ mt: 2 }}
                              variant="body2"
                            >
                              {shoppingCartContent?.viewDetailsLinkText}
                            </Link>
                          )}
                        </Grid>
                        <Grid item spacing={6} sx={{ px: 2, pb: 2 }} xs={3}>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name={`${InputNames.allocation}[${sleeveIndex}].${InputNames.percentage}`}
                            render={({ onChange, onBlur, value, name, ref }) => (
                              <TextField
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                error={!!fieldsErrors.allocation?.[sleeveIndex]?.percentage}
                                fullWidth
                                inputRef={ref}
                                label={shoppingCartContent?.percentageLabel}
                                name={name}
                                onBlur={onBlur}
                                onChange={e => {
                                  const { value: inputValue } = e.target;
                                  if (isValidNumberInput(inputValue)) {
                                    e.preventDefault(); // Prevent Non Numeric entries
                                    return;
                                  }
                                  onChange(e);
                                }}
                                placeholder={shoppingCartContent?.percentagePlaceholder}
                                type="text"
                                value={value}
                              />
                            )}
                            rules={{ required: true, min: minimumPercentageAllowed, max: maximumPercentageAllowed }}
                          />
                          <Typography sx={{ mt: 2 }} variant="body2">
                            {shoppingCartContent?.percentageErrorText}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
              <Grid container sx={{ px: 2, pb: 2, display: 'flex' }} xs={12}>
                <Grid
                  item
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                  xs={9}
                >
                  {numberOfSleeves < maximumSleeveCount && (
                    <Link
                      component="button"
                      data-qa={`${dataQa}-add-sleeve`}
                      onClick={addSleeve}
                      sx={{ display: 'inline', my: 3 }}
                      variant="body2"
                    >
                      {shoppingCartContent?.addSleeveButtonText}
                    </Link>
                  )}
                  {numberOfSleeves >= maximumSleeveCount && (
                    <Link
                      component="button"
                      data-qa={`${dataQa}-remove-sleeve`}
                      onClick={removeSleeve}
                      sx={{ display: 'inline', my: 3 }}
                      variant="body2"
                    >
                      {shoppingCartContent?.removeSleeveButtonText}
                    </Link>
                  )}
                </Grid>
                <Grid
                  item
                  sx={{
                    color:
                      (isSubmitButtonClicked || isContinueButtonClicked) && totalPercentageValue !== 100
                        ? styles.totalPercentageErrorLabelColor
                        : '',
                    pl: 2.5,
                    pr: 0.5,
                  }}
                  xs={3}
                >
                  <Box sx={labelStyles}>
                    <Typography variant="subtitle2">{shoppingCartContent?.totalLabel}</Typography>
                    <Typography variant="body2">
                      {totalPercentageValue}
                      {shoppingCartContent?.percentageSign}
                    </Typography>
                  </Box>
                  {totalPercentageValue === 100 && (
                    <Box sx={{ pt: 1, ...labelStyles }}>
                      <Typography variant="subtitle2">{shoppingCartContent?.modelMinimumLabel}</Typography>
                      <Typography variant="body2">
                        {formatCurrency(parseFloat(compositeModelPortfolioOpeningMinimum ?? '0'))}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
              {investmentAmountAlertState && (
                <Box sx={{ mx: 1, padding: 1, paddingBottom: 2, width: '100%' }}>
                  <Alert severity={investmentAmountAlertState}>
                    <Typography variant="body1">{shoppingCartContent?.higherInvestmentAmountAlertHeading}</Typography>
                    <Typography variant="body2">
                      {investmentAmountAlertState === 'info'
                        ? shoppingCartContent?.lowerRebalanceMinimumInfo
                        : shoppingCartContent?.lowerInvestmentAmountWarning}
                    </Typography>
                  </Alert>
                </Box>
              )}
              {isCompositeModelPortfolioLoaded &&
                compositeModelPortfolioVolatilityMatchStatus !== VolatilityMatchStatus.MATCHED && (
                  <Box sx={{ ml: 2, mr: 2, px: 2, pt: 2, pb: 4, ...styles.riskNoteContainer }}>
                    <RteContent
                      config={{
                        riskProfile:
                          compositeModelPortfolioVolatilityMatchStatus === VolatilityMatchStatus.TOO_AGGRESSIVE
                            ? shoppingCartContent?.volatility?.aggressiveText
                            : shoppingCartContent?.volatility?.conservativeText,
                        riskProfileChange:
                          compositeModelPortfolioVolatilityMatchStatus === VolatilityMatchStatus.TOO_AGGRESSIVE
                            ? shoppingCartContent?.volatility?.volatilityDecreasingText
                            : shoppingCartContent?.volatility?.volatilityIncreasingText,
                      }}
                      data={shoppingCartContent?.volatility.riskNote ?? ''}
                    />
                    <VolatilityBar
                      calculatedVolatility={parseFloat(compositeModelPortfolioVolatility)}
                      content={{
                        currentLabel: shoppingCartContent?.volatility?.currentLabel ?? '',
                        targetLabel: shoppingCartContent?.volatility?.targetLabel ?? '',
                      }}
                      riskPreferenceVolatilityList={riskPreferenceVolatilityList ?? []}
                      riskScore={riskScore ?? 0}
                    />
                  </Box>
                )}
              {symphonyError && (
                <Box sx={{ mx: 2, py: 4, width: '100%' }}>
                  <Alert contentOptions={contentOptions} error={symphonyError} severity="error" />
                </Box>
              )}
              <Grid container sx={{ display: 'flex', px: 3, py: 2 }} xs={12}>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={4}>
                  {!isRce && (
                    <>
                      <img alt="" src={shoppingCartContent?.dollarIcon} />
                      {!isCompositeModelPortfolioLoaded ? (
                        <Box>
                          <Typography sx={{ ml: 1 }} variant="body1">
                            {shoppingCartContent?.minimumInvestmentInitialLabel}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography sx={{ ml: 1 }} variant="body1">
                          <RteContent
                            config={{
                              openingMinimum: formatCurrency(parseFloat(compositeModelPortfolioOpeningMinimum ?? '0'), {
                                locale: contentOptions.locale,
                              }),
                            }}
                            data={shoppingCartContent?.minimumInvestmentSuccessLabel ?? ''}
                          />
                        </Typography>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item sx={{ justifyContent: 'flex-end', display: 'flex' }} xs={8}>
                  <LoadingButton
                    disabled={!saveTemplateFormValidity}
                    id="save-btn"
                    loading={saveTemplateButtonLoading}
                    onClick={() => saveTemplate()}
                    sx={{ marginRight: 2 }}
                    variant="outlined"
                  >
                    {shoppingCartContent?.secondaryCTA ?? ''}
                  </LoadingButton>
                  <LoadingButton
                    disabled={
                      !isCompositeModelPortfolioLoaded ||
                      compositeModelPortfolioVolatilityMatchStatus !== VolatilityMatchStatus.MATCHED ||
                      investmentAmountAlertState === 'error'
                    }
                    id="continue-btn"
                    onClick={() => saveSelections()}
                    variant="contained"
                  >
                    {shoppingCartContent?.primaryCTA ?? ''}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      )}
      {showSnackBar && (
        <Snackbar
          action={null}
          autoHideDuration={5000}
          feedbackMessage={snackBarContent}
          message={<Alert severity={snackBarType}>{snackBarContent}</Alert>}
          onClose={() => {
            setShowSnackBar(false);
          }}
          snackbarPosition={SnackbarPositionType.BOTTOM_CENTER}
          sx={{
            px: 0,
            py: 0,
            ...styles.snackBar,
          }}
        />
      )}
    </Grid>
  );
};
