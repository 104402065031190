import {
  GetWidgetsContent,
  GetWidgetsContent_all_widgets_items_widgets_WidgetsWidgetsWidgetNullState_widget_null_state,
  GetWidgetsContent_all_widgets_items_widgets_WidgetsWidgetsWidgetNullState_widget_null_state_cta_primary,
  GetWidgetsContent_all_widgets_items_widgets_WidgetsWidgetsWidgetNullState_widget_null_state_cta_secondary,
  GetWidgetsContentVariables,
} from './__generated__/getWidgetsContent.v2';
import * as widgetsQuery from './getWidgetsContent.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type WidgetAction =
  | GetWidgetsContent_all_widgets_items_widgets_WidgetsWidgetsWidgetNullState_widget_null_state_cta_primary['action']
  | GetWidgetsContent_all_widgets_items_widgets_WidgetsWidgetsWidgetNullState_widget_null_state_cta_secondary['action'];

export type WidgetsContentNullState = GetWidgetsContent_all_widgets_items_widgets_WidgetsWidgetsWidgetNullState_widget_null_state;

export const useGetWidgetsContent = (
  options?: QueryHookOptions<GetWidgetsContent, GetWidgetsContentVariables>,
): QueryResult<GetWidgetsContent, GetWidgetsContentVariables> => {
  return useContentstackQuery(widgetsQuery.GetWidgetsContent, options);
};
