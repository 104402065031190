import { isPast, isToday } from 'date-fns';

import { CmsKeys, ComponentTypes, QuestionOrderSteps as QuestionOrderStep } from '../../Section/types';
import { getFieldName } from '../../Section/utils';

import { ssn } from './identifiers';
import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';
import {
  commonDefaultDateQuestionProps,
  commonDefaultDateValidations,
  filterValidationRules,
  getPaperworkDataForPrefill,
  getPaperworkDateFieldValueAsIsoString,
  getPaperworkEnumFieldValue,
  getPaperworkNumberFieldValue,
  getPaperworkStringFieldValue,
  isMasked,
  requiredValidation,
} from './utils';

import { IdentifierType, TrustIdentificationType, TrustType } from '~/__generated__';

export const grantorSsn = (properties: QuestionProperties): QuestionOrderStep => {
  return {
    ...ssn(properties),
    questionKey: QuestionKeys.GRANTOR_SSN,
    dataPointKey: DataPointKeys.SSN,
    prefillValueGetter: data => {
      const identifier = getPaperworkDataForPrefill(data, properties.paperworkFreeFormId)?.party?.identifiers?.find(
        id => id.type === IdentifierType.SSN,
      );
      return (
        identifier?.identifierValue ??
        getPaperworkDataForPrefill(data, properties.paperworkFreeFormId)?.trustInformation?.trustIdentificationNumber
      );
    },
    paperworkInputSetter: (paperworkInput, data) => {
      if (paperworkInput.trustInformation) {
        const idNumberValue = getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.SSN, properties.paperworkFreeFormId)],
        );
        paperworkInput.trustInformation = {
          ...paperworkInput.trustInformation,
          trustIdentificationNumber: isMasked(idNumberValue) ? null : idNumberValue,
        };
      }
    },
  };
};

export const isSsnSameAsTrustee = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.IS_SSN_SAME_AS_TRUSTEE,
    dataPointKey: DataPointKeys.IS_SSN_SAME_AS_TRUSTEE,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.trustInformation?.isTrusteeGrantor;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      if (paperworkInput.trustInformation) {
        paperworkInput.trustInformation = {
          ...paperworkInput.trustInformation,
          isTrusteeGrantor:
            getPaperworkStringFieldValue(
              data.formValues[getFieldName(DataPointKeys.IS_SSN_SAME_AS_TRUSTEE, paperworkFreeFormId)],
            ) === 'true',
        };
      }
    },
    componentType: ComponentTypes.Radio,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const numberOfTrustees = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.NUMBER_OF_TRUSTEES,
    dataPointKey: DataPointKeys.NUMBER_OF_TRUSTEES,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.trustInformation?.numberOfTrustees?.toString();
    },
    paperworkInputSetter: (paperworkInput, data) => {
      if (paperworkInput.trustInformation) {
        paperworkInput.trustInformation = {
          ...paperworkInput.trustInformation,
          numberOfTrustees: getPaperworkNumberFieldValue(
            data.formValues[getFieldName(DataPointKeys.NUMBER_OF_TRUSTEES, paperworkFreeFormId)],
          ),
        };
      }
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const revokerFirstName = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  inputComponentProps?: QuestionOrderStep['inputComponentProps'],
): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.REVOKER_FIRST_NAME,
    dataPointKey: DataPointKeys.REVOKER_FIRST_NAME,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.trustInformation?.revokerFirstName;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      if (paperworkInput.trustInformation) {
        paperworkInput.trustInformation = {
          ...paperworkInput.trustInformation,
          revokerFirstName: getPaperworkStringFieldValue(
            data.formValues[getFieldName(DataPointKeys.REVOKER_FIRST_NAME, paperworkFreeFormId)],
          ),
        };
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const revokerLastName = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  inputComponentProps?: QuestionOrderStep['inputComponentProps'],
): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.REVOKER_LAST_NAME,
    dataPointKey: DataPointKeys.REVOKER_LAST_NAME,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.trustInformation?.revokerLastName;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      if (paperworkInput.trustInformation) {
        paperworkInput.trustInformation = {
          ...paperworkInput.trustInformation,
          revokerLastName: getPaperworkStringFieldValue(
            data.formValues[getFieldName(DataPointKeys.REVOKER_LAST_NAME, paperworkFreeFormId)],
          ),
        };
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const revokerMiddleName = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  inputComponentProps?: QuestionOrderStep['inputComponentProps'],
): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.REVOKER_MIDDLE_NAME,
    dataPointKey: DataPointKeys.REVOKER_MIDDLE_NAME,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.trustInformation?.revokerMiddleName;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      if (paperworkInput.trustInformation) {
        paperworkInput.trustInformation = {
          ...paperworkInput.trustInformation,
          revokerMiddleName: getPaperworkStringFieldValue(
            data.formValues[getFieldName(DataPointKeys.REVOKER_MIDDLE_NAME, paperworkFreeFormId)],
          ),
        };
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const stateLawGoverningTrust = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.STATE_LAW_GOVERNING_TRUST,
    cmsKey: CmsKeys.States,
    dataPointKey: DataPointKeys.STATE_LAW_GOVERNING_TRUST,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.trustInformation?.trustGoverningState;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      if (paperworkInput.trustInformation) {
        paperworkInput.trustInformation = {
          ...paperworkInput.trustInformation,
          trustGoverningState: getPaperworkStringFieldValue(
            data.formValues[getFieldName(DataPointKeys.STATE_LAW_GOVERNING_TRUST, paperworkFreeFormId)],
          ),
        };
      }
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const taxReportingNumber = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.TAX_REPORTING_NUMBER,
    dataPointKey: DataPointKeys.TAX_REPORTING_NUMBER,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.trustInformation?.trustIdentificationNumber;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      if (paperworkInput.trustInformation) {
        const idNumberValue = getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.TAX_REPORTING_NUMBER, paperworkFreeFormId)],
        );
        paperworkInput.trustInformation = {
          ...paperworkInput.trustInformation,
          trustIdentificationNumber: isMasked(idNumberValue) ? null : idNumberValue,
        };
      }
    },
    componentType: ComponentTypes.Input,
    inputComponentProps: {
      invalidCharacterRegex: new RegExp(/[^*\d]/g),
      formattingRules: {
        masked: {
          charsToShow: 4,
          maskChar: '*',
        },
      },
    },
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const taxReportingNumberType = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.TAX_REPORTING_TYPE,
    dataPointKey: DataPointKeys.TAX_REPORTING_TYPE,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.trustInformation?.trustIdentificationType;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      if (paperworkInput.trustInformation) {
        const trustIdentificationTypeValue = getPaperworkEnumFieldValue(
          TrustIdentificationType,
          data.formValues[getFieldName(DataPointKeys.TAX_REPORTING_TYPE, paperworkFreeFormId)],
        );
        paperworkInput.trustInformation = {
          ...paperworkInput.trustInformation,
          trustIdentificationType: trustIdentificationTypeValue,
        };

        if (trustIdentificationTypeValue === TrustIdentificationType.TIN) {
          paperworkInput.trustInformation.isTrusteeGrantor = null;
        }
      }
    },
    componentType: ComponentTypes.Radio,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};
export const trustDate = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.TRUST_DATE,
    dataPointKey: DataPointKeys.TRUST_DATE,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.trustInformation?.trustAgreementDate;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      if (paperworkInput.trustInformation) {
        paperworkInput.trustInformation = {
          ...paperworkInput.trustInformation,
          trustAgreementDate: getPaperworkDateFieldValueAsIsoString(
            data.formValues[getFieldName(DataPointKeys.TRUST_DATE, paperworkFreeFormId)],
          ),
        };
      }
    },
    componentType: ComponentTypes.Date,
    adjacent,
    rules,
    dateComponentProps: {
      ...commonDefaultDateQuestionProps,
      datePickerProps: {
        ...commonDefaultDateQuestionProps.datePickerProps,
        disableFuture: true,
      },
    },
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            ...commonDefaultDateValidations,
            isFutureDateDisabled: value => isPast(value) || isToday(value),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const trustName = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.TRUST_NAME,
    dataPointKey: DataPointKeys.TRUST_NAME,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.trustInformation?.trustName;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      if (paperworkInput.trustInformation) {
        paperworkInput.trustInformation = {
          ...paperworkInput.trustInformation,
          trustName: getPaperworkStringFieldValue(
            data.formValues[getFieldName(DataPointKeys.TRUST_NAME, paperworkFreeFormId)],
          ),
        };
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const trustSsn = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.TRUST_SSN,
    dataPointKey: DataPointKeys.TRUST_SSN,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.trustInformation?.trustIdentificationNumber;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      if (paperworkInput.trustInformation) {
        const idNumberValue = getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.TRUST_SSN, paperworkFreeFormId)],
        );
        paperworkInput.trustInformation = {
          ...paperworkInput.trustInformation,
          trustIdentificationNumber: isMasked(idNumberValue) ? null : idNumberValue,
        };
      }
    },
    componentType: ComponentTypes.Input,
    inputComponentProps: {
      invalidCharacterRegex: new RegExp(/[^*\d]/g),
      formattingRules: {
        masked: {
          charsToShow: 4,
          maskChar: '*',
        },
      },
    },
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          maxLength: 9,
          minLength: 9,
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const trustType = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.TRUST_TYPE,
    dataPointKey: DataPointKeys.TRUST_TYPE,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.trustInformation?.trustType;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      if (paperworkInput.trustInformation) {
        paperworkInput.trustInformation = {
          ...paperworkInput.trustInformation,
          trustType: getPaperworkEnumFieldValue(
            TrustType,
            data.formValues[getFieldName(DataPointKeys.TRUST_TYPE, paperworkFreeFormId)],
          ),
        };
      }
    },
    componentType: ComponentTypes.Radio,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};
