import uniqueId from 'lodash.uniqueid';
import React, { useEffect } from 'react';

import { Content as InvestmentAmountModalContent, InvestmentAmountModal } from '../InvestmentAmountModal';

import { Divider } from '~/components/ui/Divider';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Link } from '~/components/ui/Link';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, EditIcon, Grid, IconButton, InfoOutlinedIcon, InputLabel, useTheme } from '~/components/ui/mui';
import { Tooltip } from '~/components/ui/Tooltip';
import { Typography } from '~/components/ui/Typography';
import { StrategyMode } from '~/containers/PortfolioSelection/types';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { formatCurrency } from '~/utils/format';
import { SfTheme } from '~/utils/theme';

export interface InvestmentAmountContent {
  disabledText?: string;
  editButtonText?: string;
  label?: string;
  noAmountText?: string;
  tooltipContent?: string;
}

export interface InvestmentStrategyContent {
  investmentStrategyLabel?: string;
  strategyModeOptions?: DropdownItem[];
}

export interface Content {
  investmentAmount?: InvestmentAmountContent;
  investmentAmountModal?: InvestmentAmountModalContent;
  investmentStrategy?: InvestmentStrategyContent;
}

export interface Props {
  accountBalance?: number;
  accountMinimumBalanceForAccountOpening?: number;
  content: Content;
  contentOptions: ContentOptions;
  dataQa?: string;
  initialInvestmentAmountValue?: number;
  investmentAmountValue?: number;
  isRce?: boolean;
  onInvestmentAmountChange: (value?: number) => void;
  onStrategyModeChange?: (strategyMode: StrategyMode) => void;
  selectedStrategyMode?: StrategyMode;
}

export const PortfolioSelectionOptions: React.FC<Props> = ({
  accountBalance,
  accountMinimumBalanceForAccountOpening,
  content,
  contentOptions,
  dataQa = 'portfolio-selection-options',
  initialInvestmentAmountValue,
  investmentAmountValue,
  isRce,
  onInvestmentAmountChange,
  onStrategyModeChange,
  selectedStrategyMode,
}) => {
  const {
    components: {
      sfPortfolioSelection: { showInvestmentAmount, enableEditInitialInvestment },
    },
    featureFlags: { msiEnabled },
  } = useCoreConfig();
  const {
    sfPortfolioSelection: { typographyVariants },
  } = useTheme<SfTheme>();
  const { open, openModal, onClose } = useModalState();

  useEffect(() => {
    if (enableEditInitialInvestment && accountMinimumBalanceForAccountOpening && !initialInvestmentAmountValue) {
      openModal();
    }
  }, [accountMinimumBalanceForAccountOpening, enableEditInitialInvestment, initialInvestmentAmountValue, openModal]);

  return (
    <>
      {(!msiEnabled || !showInvestmentAmount) && <Grid item md={3.5} xs={0} />}
      {msiEnabled && (
        <Grid item md={3} sx={{ pl: 2.5, mt: 0.5 }}>
          <Box>
            <InputLabel htmlFor={uniqueId(`${dataQa}-`)} id={`${uniqueId(`${dataQa}-`)}-label`} sx={{ mb: 1, py: 0.5 }}>
              <Typography sx={{ color: 'text.secondary' }} variant="subtitle2">
                {content.investmentStrategy?.investmentStrategyLabel}
              </Typography>
            </InputLabel>
            <Dropdown
              items={content.investmentStrategy?.strategyModeOptions ?? []}
              onChange={event => {
                onStrategyModeChange?.(event.target.value as StrategyMode);
              }}
              value={selectedStrategyMode}
              variant="standard"
              width="100%"
            />
          </Box>
        </Grid>
      )}
      {msiEnabled && showInvestmentAmount && (
        <Grid item md={0.5} sx={{ pb: 1.5 }}>
          <Divider orientation="vertical" sx={{ mx: 2, width: 0 }} />
        </Grid>
      )}
      {showInvestmentAmount && (
        <>
          <Grid item md={3}>
            <Box>
              <Box
                data-qa={`${dataQa}-edit-risk-tolerance`}
                sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2, mt: 1 }}
              >
                <Typography component="h2" sx={{ alignSelf: 'center', color: 'text.secondary' }} variant="subtitle2">
                  {content.investmentAmount?.label}
                </Typography>
                <Tooltip placement="top" tooltipContent={content.investmentAmount?.tooltipContent ?? ''}>
                  <IconButton aria-label="info icon" size="small" sx={{ py: 0 }}>
                    <InfoOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box
                data-qa={`${dataQa}-investment-amount`}
                sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start' }}
              >
                {initialInvestmentAmountValue !== undefined || investmentAmountValue !== undefined ? (
                  <Typography sx={{ alignSelf: 'center', mr: 1, color: 'text.primary' }} variant="body1">
                    {formatCurrency(investmentAmountValue ?? initialInvestmentAmountValue ?? 0, {
                      locale: contentOptions.locale,
                    })}
                  </Typography>
                ) : (
                  <Typography sx={{ alignSelf: 'center', mr: 1, color: 'text.secondary' }} variant="body1">
                    {content.investmentAmount?.noAmountText}
                  </Typography>
                )}
                {enableEditInitialInvestment && (
                  <Link
                    aria-label="edit investment amount icon"
                    data-qa={`${dataQa}-edit-investment-amount-link`}
                    onClick={openModal}
                    sx={{ color: 'primary.dark', textDecoration: 'none' }}
                    variant={typographyVariants?.editRiskToleranceLink}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ lineHeight: 1.75 }} variant="body2">
                        {content.investmentAmount?.editButtonText}
                      </Typography>
                      <EditIcon fontSize="small" sx={{ ml: 0.5 }} />
                    </Box>
                  </Link>
                )}
              </Box>
            </Box>
          </Grid>
          <InvestmentAmountModal
            accountBalance={accountBalance}
            accountMinimumBalanceForAccountOpening={accountMinimumBalanceForAccountOpening}
            content={content.investmentAmountModal}
            contentOptions={contentOptions}
            dataQa={dataQa}
            initialInvestmentAmountValue={initialInvestmentAmountValue}
            investmentAmountValue={investmentAmountValue}
            isRce={isRce}
            onClose={onClose}
            onInvestmentAmountChange={onInvestmentAmountChange}
            open={open}
          />
        </>
      )}
    </>
  );
};
