import { useEffect, useState } from 'react';

import { useGetShoppingCartContent } from '../contentstack';
import { GetSleevesShoppingCartContent } from '../contentstack/__generated__/query.v2';

import { ManagedProductType } from '~/__generated__';
import { RiskPreferenceVolatilityMappings, useGetRiskPreferenceVolatilityMappings } from '~/hooks/volatility/symphony';
import { getLabel } from '~/utils/content';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface ShoppingCartVariables {
  contentOptions: ContentOptions;
  productType: ManagedProductType;
}

export interface ShoppingCartContentData {
  addSleeveButtonText: string;
  blendNameErrorText: string;
  blendNameLabel: string;
  blendNamePlaceholder: string;
  dollarIcon: string;
  higherInvestmentAmountAlertHeading: string;
  lowerInvestmentAmountWarning: string;
  lowerRebalanceMinimumInfo: string;
  minimumInvestmentInitialLabel: string;
  minimumInvestmentSuccessLabel: string;
  modelMinimumLabel: string;
  percentageErrorText: string;
  percentageLabel: string;
  percentagePlaceholder: string;
  percentageSign: string;
  portfolioLabel: string;
  portfolioPlaceholder: string;
  primaryCTA: string;
  removeSleeveButtonText: string;
  saveErrorMessage: string;
  saveSuccessMessage: string;
  secondaryCTA: string;
  seriesLabel: string;
  seriesPlaceholder: string;
  title: string;
  totalLabel: string;
  viewDetailsLinkText: string;
  volatility: {
    aggressiveText: string;
    conservativeText: string;
    currentLabel: string;
    riskNote: string;
    targetLabel: string;
    volatilityDecreasingText: string;
    volatilityIncreasingText: string;
  };
}

export interface ShoppingCartData {
  contentData: ShoppingCartContentData;
  riskPreferenceVolatilityList: RiskPreferenceVolatilityMappings[];
}

export const useShoppingCartData = ({
  contentOptions,
  productType,
}: ShoppingCartVariables): AsyncResult<ShoppingCartData> => {
  const [state, setState] = useState<AsyncResult<ShoppingCartData>>({ loading: true });
  const {
    data: shoppingCartContentstackData,
    loading: shoppingCartContentstackLoading,
    error: shoppingCartContentstackError,
  } = useGetShoppingCartContent({
    variables: contentOptions,
  });

  const {
    data: riskPreferenceVolatilityMappingsData,
    loading: riskPreferenceVolatilityMappingsLoading,
    error: riskPreferenceVolatilityMappingsError,
  } = useGetRiskPreferenceVolatilityMappings({
    variables: {
      productType,
    },
  });

  useEffect(() => {
    if (shoppingCartContentstackError || riskPreferenceVolatilityMappingsError) {
      setState({
        error: shoppingCartContentstackError || riskPreferenceVolatilityMappingsError,
        loading: false,
      });
    }
  }, [shoppingCartContentstackError, riskPreferenceVolatilityMappingsError]);

  useEffect(() => {
    if (
      shoppingCartContentstackData &&
      !shoppingCartContentstackLoading &&
      riskPreferenceVolatilityMappingsData &&
      !riskPreferenceVolatilityMappingsLoading
    ) {
      const shoppingCartContent = getShoppingCartContentData(shoppingCartContentstackData);
      setState({
        data: {
          contentData: shoppingCartContent,
          riskPreferenceVolatilityList: riskPreferenceVolatilityMappingsData?.riskPreferenceVolatilityMappings,
        },
        loading: false,
      });
    }
  }, [
    shoppingCartContentstackData,
    shoppingCartContentstackLoading,
    riskPreferenceVolatilityMappingsData,
    riskPreferenceVolatilityMappingsLoading,
  ]);

  return state;
};

export const getShoppingCartContentData = (contentData: GetSleevesShoppingCartContent): ShoppingCartContentData => {
  const item = contentData.all_Portfolio_Selection?.items?.[0];
  const shoppingCartContent = item?.multiple_sleeves;
  return {
    addSleeveButtonText: shoppingCartContent?.add_sleeve_label ?? '',
    blendNameErrorText: shoppingCartContent?.blend_name_error_text ?? '',
    blendNameLabel: shoppingCartContent?.blend_name_label ?? '',
    blendNamePlaceholder: shoppingCartContent?.blend_name_placeholder ?? '',
    dollarIcon: shoppingCartContent?.dollar_iconConnection?.edges?.[0]?.node?.url ?? '',
    minimumInvestmentInitialLabel: shoppingCartContent?.minimum_investment_initial_label ?? '',
    minimumInvestmentSuccessLabel: shoppingCartContent?.minimum_investment_success_label ?? '',
    percentageErrorText: shoppingCartContent?.percentage_error_text ?? '',
    percentageLabel: shoppingCartContent?.percentage_label ?? '',
    percentagePlaceholder: shoppingCartContent?.percentage_placeholder ?? '',
    percentageSign: shoppingCartContent?.percentage_sign ?? '',
    portfolioLabel: shoppingCartContent?.portfolio_label ?? '',
    portfolioPlaceholder: shoppingCartContent?.portfolio_placeholder ?? '',
    primaryCTA: shoppingCartContent?.primary_cta ?? '',
    removeSleeveButtonText: shoppingCartContent?.remove_sleeve_label ?? '',
    saveErrorMessage: shoppingCartContent?.save_error_message ?? '',
    saveSuccessMessage: shoppingCartContent?.save_success_message ?? '',
    secondaryCTA: shoppingCartContent?.secondary_cta ?? '',
    seriesLabel: shoppingCartContent?.series_label ?? '',
    seriesPlaceholder: shoppingCartContent?.series_placeholder ?? '',
    title: shoppingCartContent?.title ?? '',
    totalLabel: shoppingCartContent?.total_label ?? '',
    modelMinimumLabel: getLabel(item, 'multi_sleeve_model_minimum_label'),
    higherInvestmentAmountAlertHeading: getLabel(item, 'higher_investment_amount_alert_heading'),
    lowerRebalanceMinimumInfo: getLabel(item, 'lower_rebalance_minimum_info_msg'),
    lowerInvestmentAmountWarning: getLabel(item, 'lower_investment_amount_warning_msg'),
    viewDetailsLinkText: shoppingCartContent?.view_details_link_text ?? '',
    volatility: {
      aggressiveText: shoppingCartContent?.volatility?.aggressive_text ?? '',
      conservativeText: shoppingCartContent?.volatility?.conservative_text ?? '',
      currentLabel: shoppingCartContent?.volatility?.current_label ?? '',
      riskNote: shoppingCartContent?.volatility?.risk_note ?? '',
      targetLabel: shoppingCartContent?.volatility?.target_label ?? '',
      volatilityDecreasingText: shoppingCartContent?.volatility?.volatility_decreasing_text ?? '',
      volatilityIncreasingText: shoppingCartContent?.volatility?.volatility_increasing_text ?? '',
    },
  };
};
