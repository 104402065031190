import React, { ComponentProps, useEffect, useState } from 'react';

import { PricingSummaryEdit } from './PricingSummaryEdit';

import { BillingRateTargetType } from '~/__generated__';
import { PricingSummary as ProductPricingSummary } from '~/components/PricingSummary';
import { PricingSummaryTable } from '~/components/PricingSummaryTable';
import { Alert } from '~/components/ui/Alert';
import { CircularProgress } from '~/components/ui/CircularProgress';
import { Modal } from '~/components/ui/Modal';
import { ModalStateData, useModalState } from '~/components/ui/Modal/hooks';
import { ArrowBackIosNewIcon, Avatar, Button, Fab, Grid, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { useApplyDiscount } from '~/hooks/pricing/symphony';
import { useInitialDiscountValue } from '~/hooks/pricing/useInitialDiscountValue';
import {
  PricingSummaryModalDataResponse,
  usePricingSummaryModalData,
} from '~/hooks/pricing/usePricingSummaryModalData';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { FeePeriod } from '~/utils/pricing';
import { SfTheme } from '~/utils/theme';
import { AsyncResult } from '~/utils/types';

export enum PricingSummaryModalPages {
  AdvancedPricing = 'AdvancedPricing',
  FeeTiers = 'FeeTiers',
  Summary = 'Summary',
}

export type PricingSummaryModalPage = {
  cta: () => void;
  element: JSX.Element;
  primaryCtaLabel: string;
  secondaryCtaLabel?: string;
  title: string;
};
export type PricingSummaryModalFullProps = ComponentProps<typeof Modal> & {
  accountValue?: number;
  applyDiscount?: (discount: number) => Promise<void>;
  defaultDiscountValue?: number;
  discountLowerBound?: number;
  discountTiers?: [number, ...number[]];
  discountUpperBound?: number;
  feePeriod?: FeePeriod;
  hideFeesWhenZero?: boolean;
  isAdvancedPricingEnabled?: boolean;
  isPercentageAdvancedPricing?: boolean;
  isTieredBasisPointAdvancedPricing?: boolean; // Citizens customization that will be deprecated
  onClose: () => void;
  pricingSummaryModalData: PricingSummaryModalDataResponse;
  ratePrecision?: number;
  ratePrecisionAdvancedPricing?: number;
  setAccountValue?: React.Dispatch<React.SetStateAction<number | undefined>>;
  showFeeTiers?: boolean;
  showPeriodCost?: boolean;
};
export type PricingSummaryModalConfigurableProps = Omit<
  PricingSummaryModalFullProps,
  'onClose' | 'open' | 'pricingSummaryModalData'
>;

export const PricingSummaryModal: React.FC<PricingSummaryModalFullProps> = ({
  accountValue,
  applyDiscount,
  dataQa,
  defaultDiscountValue = 0,
  discountLowerBound = 0,
  discountTiers = [0],
  discountUpperBound = 150,
  hideFeesWhenZero = false,
  isAdvancedPricingEnabled = true,
  isPercentageAdvancedPricing = false,
  isTieredBasisPointAdvancedPricing = false,
  onClose,
  open,
  feePeriod = FeePeriod.MONTHLY,
  pricingSummaryModalData,
  ratePrecision,
  ratePrecisionAdvancedPricing,
  setAccountValue,
  showFeeTiers,
  showPeriodCost,
  ...rest
}) => {
  const initialDiscountValue = useInitialDiscountValue({
    defaultDiscountValue,
    defaultRates: pricingSummaryModalData.billingRates.defaultRates,
    discountLowerBound,
    discountTiers,
    isAdvancedPricingEnabled,
    overriddenRates: pricingSummaryModalData.billingRates.overriddenRates,
  });

  const [currentPage, setCurrentPage] = useState(PricingSummaryModalPages.Summary);
  const [discount, setDiscount] = useState(initialDiscountValue);
  const [discountError, setDiscountError] = useState<Error>();
  const [mutationError, setMutationError] = useState<Error | undefined>();

  const {
    components: {
      sfPricingSummary: { step },
    },
  } = useCoreConfig();

  const {
    sfPricingSummaryModal: { styles },
  } = useTheme<SfTheme>();

  const clearErrors = () => {
    setDiscountError(undefined);
    setMutationError(undefined);
  };

  useEffect(() => {
    if (open) {
      setCurrentPage(PricingSummaryModalPages.Summary);
      clearErrors();
    }
  }, [open]);
  const getConfigValue = (key: string) => {
    switch (key) {
      case 'lowerBound':
        return discountLowerBound;
      case 'upperBound':
        return discountUpperBound;
      case 'step':
        return step;
      default:
        return undefined;
    }
  };
  const modalComponent: {
    [key: string]: PricingSummaryModalPage;
  } = {
    [PricingSummaryModalPages.Summary]: {
      cta: () => {
        onClose();
      },
      element: (
        <ProductPricingSummary
          accountValue={accountValue}
          billingRates={pricingSummaryModalData.billingRates}
          blendedRates={pricingSummaryModalData.blendedRates}
          content={pricingSummaryModalData.content.pricingSummaryContent}
          estimatedTotalFee={pricingSummaryModalData.clientFee}
          feePeriod={feePeriod}
          handleAccountValueChange={setAccountValue}
          hideFeesWhenZero={hideFeesWhenZero}
          loading={pricingSummaryModalData.refetchingData}
          ratePrecision={ratePrecision}
          showAdvancedPricing={
            isAdvancedPricingEnabled
              ? () => {
                  setCurrentPage(PricingSummaryModalPages.AdvancedPricing);
                  setDiscount(initialDiscountValue);
                }
              : undefined
          }
          showFeeTiers={showFeeTiers ? () => setCurrentPage(PricingSummaryModalPages.FeeTiers) : undefined}
          showPeriodCost={showPeriodCost}
        />
      ),
      primaryCtaLabel: pricingSummaryModalData.content.modalContent.primaryCta,
      title: pricingSummaryModalData.content.modalContent.header,
    },
    [PricingSummaryModalPages.AdvancedPricing]: {
      cta: () => {
        if (!isTieredBasisPointAdvancedPricing) {
          const error = new Error();
          if (step && discount % step !== 0) {
            error.message = pricingSummaryModalData.content.advancedPricingContent.helperTexts.discountStep;
            error.name = 'step';
          }
          if (discount > discountUpperBound) {
            error.message = pricingSummaryModalData.content.advancedPricingContent.helperTexts.discountUpperBound;
            error.name = 'upperBound';
          }
          if (discount < discountLowerBound) {
            error.message = pricingSummaryModalData.content.advancedPricingContent.helperTexts.discountLowerBound;
            error.name = 'lowerBound';
          }
          if (error.message) {
            setDiscountError(error);
            return;
          }
        }
        applyDiscount?.(isPercentageAdvancedPricing ? discount / 100 : discount)
          .then(() => {
            setDiscountError(undefined);
            setCurrentPage(PricingSummaryModalPages.Summary);
            pricingSummaryModalData.refetchData?.();
          })
          .catch((e: Error) => {
            console.error(`Failed to apply discount: ${e.message}`);
            setMutationError(e);
          });
      },
      element: (
        <Grid sx={{ textAlign: 'center' }}>
          <PricingSummaryEdit
            decimalScale={ratePrecisionAdvancedPricing}
            discount={discount}
            discountTiers={discountTiers}
            discountUnit={pricingSummaryModalData.content.advancedPricingContent.discountUnit}
            helperText={
              <RteContent
                config={{
                  boundValue: getConfigValue(discountError?.name ?? ''),
                }}
                data={discountError?.message ?? ''}
              />
            }
            isInvalidInput={!!discountError}
            isPercentageAdvancedPricing={isPercentageAdvancedPricing}
            isTieredBasisPointAdvancedPricing={isTieredBasisPointAdvancedPricing}
            setDiscount={setDiscount}
          />
          {mutationError && <Typography>{mutationError.message}</Typography>}
        </Grid>
      ),
      primaryCtaLabel: pricingSummaryModalData.content.advancedPricingContent.primaryCta,
      secondaryCtaLabel: pricingSummaryModalData.content.advancedPricingContent.secondaryCta,
      title: pricingSummaryModalData.content.advancedPricingContent.header,
    },
    [PricingSummaryModalPages.FeeTiers]: {
      cta: () => {
        setCurrentPage(PricingSummaryModalPages.Summary);
      },
      element: (
        <PricingSummaryTable
          billingRate={pricingSummaryModalData.billingRates.defaultRates.find(
            rate => rate.rateTarget === BillingRateTargetType.ADVISOR,
          )}
          content={{
            assetLabel: pricingSummaryModalData.content.feeTiersContent.assetLabel,
            currencySymbol: pricingSummaryModalData.content.feeTiersContent.currencySymbol,
            feeLabel: pricingSummaryModalData.content.feeTiersContent.feeLabel,
            finalTierPrefix: pricingSummaryModalData.content.feeTiersContent.finalTierPrefix,
            finalTierSuffix: pricingSummaryModalData.content.feeTiersContent.finalTierSuffix,
          }}
          dataQa="pricing-summary-modal-pricing-table"
        />
      ),
      primaryCtaLabel: pricingSummaryModalData.content.feeTiersContent.primaryCta,
      title: pricingSummaryModalData.content.feeTiersContent.header,
    },
  };

  return (
    <Modal
      actions={
        <Button data-qa={`${dataQa}-primary-button`} onClick={modalComponent[currentPage].cta}>
          {modalComponent[currentPage].primaryCtaLabel}
        </Button>
      }
      content={modalComponent[currentPage].element}
      data-qa={`${dataQa}-modal`}
      maxWidth="xs"
      onClose={onClose}
      open={open}
      title={
        <>
          {currentPage === PricingSummaryModalPages.AdvancedPricing ? (
            <Button
              onClick={() => {
                setCurrentPage(PricingSummaryModalPages.Summary);
                clearErrors();
              }}
              sx={styles.headerCta}
            >
              <ArrowBackIosNewIcon fontSize="small" />
              {modalComponent[currentPage].secondaryCtaLabel}
            </Button>
          ) : (
            <Grid />
          )}
          {modalComponent[currentPage].title}
        </>
      }
      {...rest}
    />
  );
};

export interface BaseButtonProps extends ComponentProps<typeof Fab> {
  contentOptions: ContentOptions;
  managedProductId: string;
  modalState: ModalStateData;
  pricingSummaryModalProps: Omit<PricingSummaryModalFullProps, 'onClose' | 'open' | 'pricingSummaryModalData'>;
  usePricingSummaryModalHook: AsyncResult<PricingSummaryModalDataResponse>;
}
export const PricingSummaryModalButtonBase: React.FC<BaseButtonProps> = ({
  contentOptions,
  managedProductId,
  modalState,
  pricingSummaryModalProps,
  usePricingSummaryModalHook,
  ...rest
}) => {
  const { open, openModal, onClose } = modalState;
  const {
    data: pricingSummaryModalData,
    error: pricingSummaryModalError,
    loading: pricingSummaryModalLoading,
  } = usePricingSummaryModalHook;
  const applyDiscount = useApplyDiscount(managedProductId, pricingSummaryModalData?.rateType);

  if (pricingSummaryModalError) {
    return <Alert contentOptions={contentOptions} error={pricingSummaryModalError} severity="error" />;
  }

  return (
    <>
      <Fab aria-haspopup="true" onClick={openModal} {...rest}>
        {pricingSummaryModalLoading ? (
          <CircularProgress />
        ) : (
          <Avatar
            alt="price-tag-icon"
            imgProps={{ sx: { height: '50%', width: '50%' } }}
            src={pricingSummaryModalData?.content.iconUrl}
          />
        )}
      </Fab>
      {pricingSummaryModalData && (
        <PricingSummaryModal
          {...pricingSummaryModalProps}
          applyDiscount={applyDiscount}
          contentOptions={contentOptions}
          onClose={onClose}
          open={open}
          pricingSummaryModalData={pricingSummaryModalData}
        />
      )}
    </>
  );
};

export interface WrapperButtonProps extends Pick<BaseButtonProps, 'managedProductId' | 'contentOptions'> {
  pricingSummaryModalProps: PricingSummaryModalConfigurableProps;
}
export const PricingSummaryModalButton: React.FC<WrapperButtonProps> = ({
  contentOptions,
  managedProductId,
  pricingSummaryModalProps,
  ...rest
}) => {
  const {
    sfPricingSummaryButton: { styles: pricingSummaryButtonStyles },
  } = useTheme<SfTheme>();

  const [accountValue, setAccountValue] = useState<number | undefined>(pricingSummaryModalProps.accountValue ?? 25000);
  const modalState = useModalState(false);
  const pricingSummaryModalDataHook = usePricingSummaryModalData({
    contentOptions,
    managedProductId,
    billableValue: accountValue ?? 0,
  });

  return (
    <PricingSummaryModalButtonBase
      contentOptions={contentOptions}
      managedProductId={managedProductId}
      modalState={modalState}
      pricingSummaryModalProps={{ ...pricingSummaryModalProps, accountValue, setAccountValue }}
      sx={pricingSummaryButtonStyles}
      usePricingSummaryModalHook={pricingSummaryModalDataHook}
      {...rest}
    />
  );
};
