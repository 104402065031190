import { QuestionOrderSteps as QuestionOrderStep } from '../../../Section/types';
import { companyName, jobTitle, jobTitleSelfEmployed, otherIncomeSource } from '../../questions/employment';
import { QuestionProperties } from '../../questions/types';

export const companyNameNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return companyName({
    ...properties,
    additionalValidations: {
      maxLength: 32,
      ...properties.additionalValidations,
    },
  });
};

export const jobTitleNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return jobTitle({
    ...properties,
    additionalValidations: {
      maxLength: 32,
      ...properties.additionalValidations,
    },
  });
};

export const jobTitleSelfEmployedNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return jobTitleSelfEmployed({
    ...properties,
    additionalValidations: {
      maxLength: 32,
      ...properties.additionalValidations,
    },
  });
};

export const otherIncomeSourceNfsMapper = (properties: QuestionProperties): QuestionOrderStep => {
  return otherIncomeSource({
    ...properties,
    additionalValidations: {
      maxLength: 50,
      ...properties.additionalValidations,
    },
  });
};
