import { useCallback, useMemo, useState } from 'react';

import { SearchOption } from '../types';

import { getRepCodeForParty } from './utils';

import { PartyType, PersonType } from '~/__generated__';
import { useGetFinancialAdvisorPartyByRepCode } from '~/containers/HomeOfficeDashboard/symphony';
import { useGetFinancialAdvisorParties } from '~/hooks/party/symphony';
import { getFullName } from '~/hooks/party/utils';
import { useCoreConfig } from '~/utils/config';
import { AsyncResult } from '~/utils/types';

export interface Props {
  viewerPartyId: string;
}

interface SearchFinancialAdvisorData {
  onSearchOptionSelect: (selectedSearchOption: SearchOption) => void;
  onSearchTermChange: (searchTerm: string) => void;
  searchError: boolean;
  searchOptions: SearchOption[];
  searchOptionsLoading: boolean;
  selectedSearchOption: SearchOption | null;
}

const MINIMUM_SEARCH_TEXT_LENGTH = 2;

export const useSearchFinancialAdvisor = ({ viewerPartyId }: Props): AsyncResult<SearchFinancialAdvisorData> => {
  const {
    featureFlags: { repCodeIdentifierName },
  } = useCoreConfig();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchError, setSearchError] = useState(false);
  const [selectedSearchOption, setSelectedSearchOption] = useState<SearchOption | null>(null);

  const {
    data: financialAdvisorPartiesByNameData,
    loading: financialAdvisorPartiesByNameLoading,
  } = useGetFinancialAdvisorParties({
    variables: {
      expanded: true,
      personType: PersonType.EMPLOYEE,
      partyType: PartyType.PERSON,
      partyName: searchTerm,
      includeExternalSystemIdentifiers: true,
    },
    skip: !searchTerm,
  });

  const {
    data: financialAdvisorPartiesByRepCodeData,
    loading: financialAdvisorPartiesByRepCodeLoading,
  } = useGetFinancialAdvisorPartyByRepCode({
    variables: {
      externalPartyId: searchTerm,
    },
    skip: !searchTerm,
  });

  const searchOptions: SearchOption[] = useMemo(() => {
    if (searchTerm.length > 0 && !searchError) {
      const options = (financialAdvisorPartiesByNameData?.getParties ?? [])
        .filter(party => {
          // Ideally, we should be accounting for FA's who don't have a rep code as well.
          // TODO - Filter for Advisor type parties directly in the query
          return (
            party.id !== viewerPartyId && !!getRepCodeForParty(party.externalSystemIdentifiers, repCodeIdentifierName)
          );
        })
        .map(party => {
          const name = getFullName(party.partyPerson);
          const repCode = getRepCodeForParty(party.externalSystemIdentifiers, repCodeIdentifierName);
          return {
            advisorName: name ?? '',
            advisorPartyId: party.id ?? '',
            advisorRepCode: repCode ?? '',
          };
        });
      const partyForRepCode = financialAdvisorPartiesByRepCodeData?.client?.party;
      if (partyForRepCode && !options.find(option => option.advisorPartyId === partyForRepCode.id)) {
        const name = getFullName(partyForRepCode.partyPerson);
        options.push({
          advisorName: name ?? '',
          advisorPartyId: partyForRepCode.id ?? '',
          advisorRepCode: getRepCodeForParty(partyForRepCode.externalSystemIdentifiers, repCodeIdentifierName) ?? '',
        });
      }
      return options;
    } else {
      return [];
    }
  }, [
    searchTerm.length,
    searchError,
    financialAdvisorPartiesByNameData?.getParties,
    financialAdvisorPartiesByRepCodeData?.client?.party,
    viewerPartyId,
    repCodeIdentifierName,
  ]);

  const onSearchOptionSelect = useCallback((searchOption: SearchOption) => {
    setSelectedSearchOption(searchOption);
    setSearchTerm('');
  }, []);

  const onSearchTermChange = useCallback((search: string) => {
    setSearchError(false);
    if (search.length === 0 || search.length > MINIMUM_SEARCH_TEXT_LENGTH) {
      setSearchTerm(search);
    } else if (search.length > 0) {
      setSearchError(true);
    }

    if (search.length === 0) {
      setSelectedSearchOption(null);
    }
  }, []);

  const data = useMemo(() => {
    return {
      onSearchOptionSelect,
      onSearchTermChange,
      searchError,
      searchOptions,
      searchOptionsLoading: financialAdvisorPartiesByNameLoading || financialAdvisorPartiesByRepCodeLoading,
      selectedSearchOption,
    };
  }, [
    financialAdvisorPartiesByNameLoading,
    financialAdvisorPartiesByRepCodeLoading,
    onSearchOptionSelect,
    onSearchTermChange,
    searchError,
    searchOptions,
    selectedSearchOption,
  ]);

  return {
    data,
    error: undefined,
    loading: false,
  };
};
