import { useEffect, useState } from 'react';

import { useGetPortfolioSelectionModelPortfolioDetails } from '../symphony';

import { SleeveAllocationData } from '~/components/SleeveTemplates/SleeveTemplateCard';
import {
  GetPortfolioSelectionModelPortfolioDetails_managedProduct_stagedModelPortfolio_CompositeRecommendedPortfolio,
  GetPortfolioSelectionModelPortfolioDetails_managedProduct_targetModelPortfolio_RecommendedPortfolio,
} from '~/containers/PortfolioSelection/symphony/__generated__/query.v2';
import { AsyncResult } from '~/utils/types';

export interface ModelPortfolioDetails {
  stagedCompositeModelPortfolioData?: {
    allocationValue?: SleeveAllocationData[];
  };
  targetSingleModelPortfolioData?: {
    internalId: number;
    seriesId: number;
  };
}

export const useModelPortfolioDetails = ({
  managedProductId,
  partyId,
}: {
  managedProductId: string;
  partyId: string;
}): AsyncResult<ModelPortfolioDetails> => {
  const [state, setState] = useState<AsyncResult<ModelPortfolioDetails>>({
    loading: true,
    error: undefined,
    data: undefined,
  });

  const {
    data: modelPortfolioData,
    loading: modelPortfolioDataLoading,
    error: modelPortfolioDataError,
  } = useGetPortfolioSelectionModelPortfolioDetails({
    variables: {
      partyId,
      managedProductId,
    },
    // Model portfolio information was getting cached when moving back and forth the onboarding stages.
    // To prevent this, using no-cache to fetch the latest information each time
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (modelPortfolioDataLoading) {
      return;
    }
    if (modelPortfolioDataError) {
      setState({
        loading: false,
        error: modelPortfolioDataError,
      });
      return;
    }

    const targetModelPortfolio = modelPortfolioData?.managedProduct?.targetModelPortfolio;
    const targetSingleModelPortfolio =
      targetModelPortfolio?.__typename === 'RecommendedPortfolio'
        ? (targetModelPortfolio as GetPortfolioSelectionModelPortfolioDetails_managedProduct_targetModelPortfolio_RecommendedPortfolio)
        : null;
    const stagedModelPortfolio = modelPortfolioData?.managedProduct?.stagedModelPortfolio;
    const stagedCompositeModelPortfolio =
      stagedModelPortfolio?.__typename === 'CompositeRecommendedPortfolio'
        ? (stagedModelPortfolio as GetPortfolioSelectionModelPortfolioDetails_managedProduct_stagedModelPortfolio_CompositeRecommendedPortfolio)
        : null;
    setState({
      loading: false,
      data: {
        stagedCompositeModelPortfolioData: stagedCompositeModelPortfolio
          ? {
              allocationValue: stagedCompositeModelPortfolio.allocation.map(alloc => {
                return {
                  modelPortfolioInternalId: alloc.modelPortfolioInternalId,
                  modelPortfolioSeriesId: alloc.modelPortfolioSeriesId,
                  percentage: alloc.percentage,
                  modelPortfolioName: '',
                };
              }),
            }
          : undefined,
        targetSingleModelPortfolioData: targetSingleModelPortfolio
          ? {
              internalId: targetSingleModelPortfolio.internalId ?? 0,
              seriesId: targetSingleModelPortfolio.seriesId ?? 0,
            }
          : undefined,
      },
    });
  }, [modelPortfolioData, modelPortfolioDataError, modelPortfolioDataLoading]);

  return state;
};
