import { GetRetakeRTQCompletedContent } from './contentstack/__generated__/query.v2';

import { LegalDocumentStatus } from '~/__generated__';
import { CompletionMessageContent } from '~/components/CompletionMessage';
import { DocusignUserType } from '~/containers/DocusignDAProCompleted';
import { getImageAssetUrl } from '~/utils/contentstack';

interface GetRetakeRtqCompletedContentProps {
  contentData?: GetRetakeRTQCompletedContent;
  declinedBy?: DocusignUserType;
  isAdvisorNotSignee?: boolean;
  isClientDocusign?: boolean;
  isDocusignFlow?: boolean;
  isPortfolioUpdated?: boolean;
  signingStatus?: LegalDocumentStatus;
}

export const getRetakeRtqCompletedContent = ({
  isClientDocusign,
  signingStatus,
  isPortfolioUpdated,
  isDocusignFlow,
  isAdvisorNotSignee,
  declinedBy,
  contentData,
}: GetRetakeRtqCompletedContentProps): CompletionMessageContent => {
  const contentItems = contentData?.all_retake_rtq_completed?.items?.[0];
  let content;
  if (isDocusignFlow) {
    if (signingStatus !== LegalDocumentStatus.SUCCEEDED && signingStatus !== LegalDocumentStatus.DECLINED) {
      content = contentItems?.docusign_status_other;
    } else {
      if (isClientDocusign) {
        content =
          signingStatus === LegalDocumentStatus.SUCCEEDED
            ? contentItems?.client_docusign_completed
            : contentItems?.client_docusign_declined;
      } else {
        if (signingStatus === LegalDocumentStatus.DECLINED) {
          content =
            declinedBy?.toString()?.toUpperCase() === DocusignUserType.Client.toString()?.toUpperCase()
              ? contentItems?.fa_view_client_docusign_declined
              : contentItems?.fa_docusign_declined;
        } else {
          content = contentItems?.fa_docusign_completed;
        }
      }
    }
  } else {
    if (isPortfolioUpdated) {
      content = contentItems?.portfolio_changed;
    } else if (isAdvisorNotSignee) {
      content = contentItems?.fa_view_not_signee;
    } else {
      content = contentItems?.portfolio_unchanged;
    }
  }

  return {
    heading: content?.heading ?? '',
    subHeadingText: content?.sub_heading ?? '',
    moreInfo: content?.more_info ?? '',
    imageUrl: getImageAssetUrl(content?.imageConnection),
    primaryCTA: content?.ctas?.primary ?? '',
  };
};
