import React from 'react';

import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { Button, Grid, LoadingButton } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export interface KeepCurrentPortfolioModalProps {
  content: {
    body?: string;
    heading?: string;
    iconUrl?: string;
    modalTitle?: string;
    primaryButtonText?: string;
    secondaryButtonText?: string;
  };
  contentOptions: ContentOptions;
  dataQa?: string;
  errorSaving?: Error;
  handleKeepCurrentPortfolio: () => Promise<void>;
  isBusy: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const KeepCurrentPortfolioModal: React.FC<KeepCurrentPortfolioModalProps> = ({
  onClose,
  content,
  contentOptions,
  dataQa = 'portfolio-selection-keep-current-portfolio-modal',
  errorSaving,
  handleKeepCurrentPortfolio,
  isBusy,
  isOpen,
}) => {
  return (
    <Modal
      actions={
        <>
          <Button onClick={onClose} variant="outlined">
            {content.secondaryButtonText}
          </Button>
          <LoadingButton loading={isBusy} onClick={handleKeepCurrentPortfolio} variant="contained">
            {content.primaryButtonText}
          </LoadingButton>
        </>
      }
      content={
        errorSaving ? (
          <Alert contentOptions={contentOptions} error={errorSaving} severity="error" />
        ) : (
          <Grid flexDirection="column" sx={{ px: 1 }}>
            {content.iconUrl && (
              <Grid sx={{ py: 3 }}>
                <img alt="warning-icon" src={content.iconUrl} />
              </Grid>
            )}
            <Grid sx={{ pb: 4 }}>
              <Typography data-qa={`${dataQa}-heading`} variant="h3">
                {content.heading}
              </Typography>
            </Grid>
            <RteContent
              data={content.body ?? ''}
              data-qa={`${dataQa}-body`}
              sx={{
                pb: 4,
                '& p': {
                  mb: 1,
                },
              }}
            />
          </Grid>
        )
      }
      data-qa={dataQa}
      onClose={onClose}
      open={isOpen}
      title={content.modalTitle}
    />
  );
};
