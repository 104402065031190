import { Image, Page } from '@react-pdf/renderer';
import React from 'react';

interface Props {
  factSheetImages: Buffer[];
}

const ImagePage: React.FC<{ src: Buffer }> = ({ src }) => (
  <Page size="LETTER" style={{ flexDirection: 'column', flexGrow: 1 }}>
    <Image src={{ data: src, format: 'png' }} />
  </Page>
);

export const FactSheets: React.FC<Props> = ({ factSheetImages }) => (
  <>
    {factSheetImages.map((src, index) => (
      <ImagePage key={`page-${index}`} src={src} />
    ))}
  </>
);
