import { DataPointKeys, QuestionKeys, QuestionSetKeys } from '../../questions/types';
import { PaperworkDataQuestionConfig } from '../../types';

export const sectionKey = 'about_you';
export const coApplicantHeading = 'about_co_applicant';
export const questions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.FIRST_NAME },
  { properties: { optional: true }, questionOrSetKey: QuestionKeys.MIDDLE_NAME },
  { questionOrSetKey: QuestionKeys.LAST_NAME },
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.TAX_REPORTING_TYPE,
              value: 'SSN',
            },
          ],
          next: QuestionKeys.SSN,
        },
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.TAX_REPORTING_TYPE,
              value: 'TIN',
            },
          ],
          next: QuestionKeys.TIN,
        },
        {
          next: QuestionKeys.BIRTH_DATE,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.TAX_REPORTING_TYPE,
  },
  {
    properties: { rules: [{ next: null }] },
    questionOrSetKey: QuestionKeys.SSN,
  },
  {
    properties: { rules: [{ next: null }] },
    questionOrSetKey: QuestionKeys.TIN,
  },
  { questionOrSetKey: QuestionKeys.BIRTH_DATE },
  { questionOrSetKey: QuestionKeys.PHONE_NUMBER },
  { questionOrSetKey: QuestionKeys.EMAIL },
  { questionOrSetKey: QuestionKeys.CONFIRM_EMAIL },
  { questionOrSetKey: QuestionSetKeys.HOME_ADDRESS_FIELDS },
  {
    properties: {
      optional: true,
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.DIFFERENT_MAILING_ADDRESS,
              value: true,
            },
          ],
          next: QuestionKeys.MAILING_STREET_ADDRESS,
        },
        {
          next: QuestionKeys.RESIDENT_TYPE,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.DIFFERENT_MAILING_ADDRESS,
  },
  { questionOrSetKey: QuestionSetKeys.MAILING_ADDRESS_FIELDS },
  { questionOrSetKey: QuestionSetKeys.CITIZENSHIP_FIELDS },
];

export const coApplicantQuestions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.FIRST_NAME },
  { properties: { optional: true }, questionOrSetKey: QuestionKeys.MIDDLE_NAME },
  { questionOrSetKey: QuestionKeys.LAST_NAME },
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.TAX_REPORTING_TYPE,
              value: 'SSN',
            },
          ],
          next: QuestionKeys.SSN,
        },
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.TAX_REPORTING_TYPE,
              value: 'TIN',
            },
          ],
          next: QuestionKeys.TIN,
        },
        {
          next: QuestionKeys.BIRTH_DATE,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.TAX_REPORTING_TYPE,
  },
  {
    properties: { rules: [{ next: null }] },
    questionOrSetKey: QuestionKeys.SSN,
  },
  {
    properties: { rules: [{ next: null }] },
    questionOrSetKey: QuestionKeys.TIN,
  },
  { questionOrSetKey: QuestionKeys.BIRTH_DATE },
  { questionOrSetKey: QuestionKeys.PHONE_NUMBER },
  { questionOrSetKey: QuestionKeys.EMAIL },
  { questionOrSetKey: QuestionKeys.CONFIRM_EMAIL },
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.SAME_HOME_MAILING_ADDRESS,
              value: false,
            },
          ],
          next: QuestionKeys.HOME_STREET_ADDRESS,
        },
        {
          next: QuestionKeys.RESIDENT_TYPE,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.SAME_HOME_MAILING_ADDRESS,
  },
  { questionOrSetKey: QuestionSetKeys.HOME_ADDRESS_FIELDS },
  {
    properties: {
      optional: true,
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.DIFFERENT_MAILING_ADDRESS,
              value: true,
            },
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.SAME_HOME_MAILING_ADDRESS,
              value: false,
            },
          ],
          next: QuestionKeys.MAILING_STREET_ADDRESS,
        },
        {
          next: null,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.DIFFERENT_MAILING_ADDRESS,
  },
  { questionOrSetKey: QuestionSetKeys.MAILING_ADDRESS_FIELDS },
  { questionOrSetKey: QuestionSetKeys.CITIZENSHIP_FIELDS },
];
