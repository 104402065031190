import { GetSleeveTemplatesContent, GetSleeveTemplatesContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetSleeveTemplatesContent = (
  options?: QueryHookOptions<GetSleeveTemplatesContent, GetSleeveTemplatesContentVariables>,
): QueryResult<GetSleeveTemplatesContent, GetSleeveTemplatesContentVariables> => {
  return useContentstackQuery(queries.GetSleeveTemplatesContent, options);
};
