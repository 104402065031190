import { isPast, isToday } from 'date-fns';

import { getDropdownItemsFromCms } from '../../Section/Question/utils';
import { CmsKeys, ComponentTypes, QuestionOrderSteps as QuestionOrderStep } from '../../Section/types';
import { getFieldName } from '../../Section/utils';
import { isValidNonNegativeInteger } from '../../Section/validators';
import {
  commonDefaultDateQuestionProps,
  commonDefaultDateValidations,
  filterValidationRules,
  getPaperworkBooleanFieldValue,
  getPaperworkDataForPrefill,
  getPaperworkDateFieldValueAsIsoString,
  getPaperworkEnumFieldValue,
  getPaperworkNumberFieldValue,
  getPaperworkStringFieldValue,
  requiredValidation,
} from '../questions/utils';
import { PaperworkDataQuestionConfig } from '../types';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';

import { AddressType, MaritalStatus, PaperworkType, TaxStatus } from '~/__generated__';
import { calculateAge, formatDate } from '~/utils/format/date';

export const citizenshipQuestionSet: PaperworkDataQuestionConfig[] = [
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.RESIDENT_TYPE,
              value: '1',
            },
          ],
          next: QuestionKeys.US_TAX_STATUS,
        },
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.RESIDENT_TYPE,
              value: '2',
            },
          ],
          next: QuestionKeys.RESIDENT_ALIEN_TAX_STATUS,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.RESIDENT_TYPE,
  },
  {
    properties: { rules: [{ next: QuestionKeys.CITIZENSHIP }] },
    questionOrSetKey: QuestionKeys.RESIDENT_ALIEN_TAX_STATUS,
  },
  { properties: { rules: [{ next: null }] }, questionOrSetKey: QuestionKeys.CITIZENSHIP },
  { properties: { rules: [{ next: null }] }, questionOrSetKey: QuestionKeys.US_TAX_STATUS },
];

export const birthdate = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.BIRTH_DATE,
    dataPointKey: DataPointKeys.BIRTH_DATE,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyPerson?.birthDate;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.party.partyPerson = {
        ...paperworkInput.party.partyPerson,
        birthDate: getPaperworkDateFieldValueAsIsoString(
          data.formValues[getFieldName(DataPointKeys.BIRTH_DATE, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Date,
    adjacent,
    rules,
    dateComponentProps: {
      ...commonDefaultDateQuestionProps,
      datePickerProps: {
        ...commonDefaultDateQuestionProps.datePickerProps,
        disableFuture: true,
      },
    },
    validationsGetter: (data, content) =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            ...commonDefaultDateValidations,
            isFutureDateDisabled: value => isPast(value) || isToday(value),
            isNotMinor: value => {
              const sameAsPrimaryAddressValue = getPaperworkBooleanFieldValue(
                data.getFormValue(getFieldName(DataPointKeys.SAME_HOME_MAILING_ADDRESS, paperworkFreeFormId)),
              );
              const primaryHomeAddressState = data.allPaperworkData
                .find(pd => pd.paperworkType === PaperworkType.PRIMARY)
                ?.party?.addresses?.find(a => a.type === AddressType.HOME)?.countryPrimarySubdivision;
              const homeAddressStateValue = getPaperworkStringFieldValue(
                data.getFormValue(getFieldName(DataPointKeys.HOME_ADDRESS_STATE, paperworkFreeFormId)),
              );
              return value
                ? calculateAge(formatDate(value, 'yyyy-MM-dd')) >=
                    (content?.minorAgesByState?.find(
                      a => a.state === (sameAsPrimaryAddressValue ? primaryHomeAddressState : homeAddressStateValue),
                    )?.minorAge ?? 18)
                : true;
            },
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const citizenship = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.CITIZENSHIP,
    cmsKey: CmsKeys.Countries,
    dataPointKey: DataPointKeys.CITIZENSHIP,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyPerson?.citizenship;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const citizenshipValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.CITIZENSHIP, paperworkFreeFormId)],
      );
      paperworkInput.party.partyPerson = {
        ...paperworkInput.party.partyPerson,
        citizenship: citizenshipValue,
      };
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    dropdownItemsWatchedFormFields: [getFieldName(DataPointKeys.RESIDENT_TYPE, paperworkFreeFormId)],
    dropdownItemsGetter: (data, content) => {
      const residentType = getPaperworkNumberFieldValue(
        data.getFormValue(getFieldName(DataPointKeys.RESIDENT_TYPE, paperworkFreeFormId)),
      );
      const hiddenOptions = residentType === 2 ? ['999'] : [];
      return getDropdownItemsFromCms(
        content.questionContent,
        CmsKeys.Countries,
        content.countriesList,
        content.statesList,
        undefined,
        hiddenOptions,
      );
    },
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const firstName = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  inputComponentProps?: QuestionOrderStep['inputComponentProps'],
): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.FIRST_NAME,
    dataPointKey: DataPointKeys.FIRST_NAME,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyPerson?.givenName;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.party.partyPerson = {
        ...paperworkInput.party.partyPerson,
        givenName: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.FIRST_NAME, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const lastName = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  inputComponentProps?: QuestionOrderStep['inputComponentProps'],
): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.LAST_NAME,
    dataPointKey: DataPointKeys.LAST_NAME,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyPerson?.familyName;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.party.partyPerson = {
        ...paperworkInput.party.partyPerson,
        familyName: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.LAST_NAME, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const maritalStatus = ({
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.MARITAL_STATUS,
    dataPointKey: DataPointKeys.MARITAL_STATUS,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyPerson?.maritalStatus;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.party.partyPerson = {
        ...paperworkInput.party.partyPerson,
        maritalStatus: getPaperworkEnumFieldValue(
          MaritalStatus,
          data.formValues[getFieldName(DataPointKeys.MARITAL_STATUS, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const middleName = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  inputComponentProps?: QuestionOrderStep['inputComponentProps'],
): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.MIDDLE_NAME,
    dataPointKey: DataPointKeys.MIDDLE_NAME,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyPerson?.middleName;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.party.partyPerson = {
        ...paperworkInput.party.partyPerson,
        middleName: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.MIDDLE_NAME, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const numberOfDependents = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  inputComponentProps?: QuestionOrderStep['inputComponentProps'],
): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.NUMBER_OF_DEPENDENTS,
    dataPointKey: DataPointKeys.NUMBER_OF_DEPENDENTS,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.wealthInformation?.numberOfDependents?.toString();
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.wealthInformation = {
        ...paperworkInput.wealthInformation,
        numberOfDependents: getPaperworkNumberFieldValue(
          data.formValues[getFieldName(DataPointKeys.NUMBER_OF_DEPENDENTS, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            isNumber: value => (value ? isValidNonNegativeInteger(value) : true),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const residentAlienTaxStatus = ({
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.RESIDENT_ALIEN_TAX_STATUS,
    dataPointKey: DataPointKeys.RESIDENT_ALIEN_TAX_STATUS,
    prefillValueGetter: data => {
      const taxStatus = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyPerson?.taxStatus;
      return taxStatus === TaxStatus.UNKNOWN ? '' : taxStatus;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.party.partyPerson = {
        ...paperworkInput.party.partyPerson,
        taxStatus: getPaperworkEnumFieldValue(
          TaxStatus,
          data.formValues[getFieldName(DataPointKeys.RESIDENT_ALIEN_TAX_STATUS, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const residentType = ({
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.RESIDENT_TYPE,
    dataPointKey: DataPointKeys.RESIDENT_TYPE,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyPerson?.residentType?.toString();
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const residentTypeValue = getPaperworkNumberFieldValue(
        data.formValues[getFieldName(DataPointKeys.RESIDENT_TYPE, paperworkFreeFormId)],
      );

      paperworkInput.party.partyPerson = {
        ...paperworkInput.party.partyPerson,
        residentType: residentTypeValue,
      };

      // Default to US Citizenship Country code, if residentType is US Citizen
      if (residentTypeValue === 1) {
        paperworkInput.party.partyPerson.citizenship = '999';
      }
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const tenantInCommonPercentage = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.TENANTS_IN_COMMON_PERCENTAGE,
    dataPointKey: DataPointKeys.TENANTS_IN_COMMON_PERCENTAGE,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.additionalAttributes?.tenantsInCommonPercentage;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.additionalAttributes = {
        ...paperworkInput.additionalAttributes,
        tenantsInCommonPercentage: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.TENANTS_IN_COMMON_PERCENTAGE, paperworkFreeFormId)],
        ),
      };
    },
    defaultValue: '',
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          min: 0.01,
          max: 99.99,
          pattern: new RegExp(/^(?:\d*\.\d{0,1,2}|\d+)$/),
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const tenantInCommonPercentageCoApplicant = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.TENANTS_IN_COMMON_PERCENTAGE_CO_APPLICANT,
    dataPointKey: DataPointKeys.TENANTS_IN_COMMON_PERCENTAGE_CO_APPLICANT,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.additionalAttributes?.tenantsInCommonPercentage;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.additionalAttributes = {
        ...paperworkInput.additionalAttributes,
        tenantsInCommonPercentage: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.TENANTS_IN_COMMON_PERCENTAGE_CO_APPLICANT, paperworkFreeFormId)],
        ),
      };
    },
    defaultValue: '',
    componentType: ComponentTypes.Input,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          min: 0.01,
          max: 99.99,
          pattern: new RegExp(/^(?:\d*\.\d{0,1,2}|\d+)$/),
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            tenantsInCommonPercentageSum: value => {
              // Assuming here that the tenants in common percentage for the primary has been stored on a previous page
              const tenantsInCommonPercentagePrimary = data.allPaperworkData.find(
                pd => pd.paperworkType === PaperworkType.PRIMARY,
              )?.additionalAttributes?.tenantsInCommonPercentage;
              return value
                ? !!tenantsInCommonPercentagePrimary &&
                    parseFloat(value) + parseFloat(tenantsInCommonPercentagePrimary) === 100
                : true;
            },
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const usTaxStatus = ({
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.US_TAX_STATUS,
    dataPointKey: DataPointKeys.US_TAX_STATUS,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.party?.partyPerson?.taxStatus;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.party.partyPerson = {
        ...paperworkInput.party.partyPerson,
        taxStatus: getPaperworkEnumFieldValue(
          TaxStatus,
          data.formValues[getFieldName(DataPointKeys.US_TAX_STATUS, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Dropdown,
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};
