import {
  GetFinancialAdvisorParties,
  GetFinancialAdvisorParties_getParties_partyPerson,
  GetFinancialAdvisorPartiesVariables,
} from './__generated__/getFinancialAdvisorParties.v2';
import * as getFinancialAdvisorPartiesQuery from './getFinancialAdvisorParties.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type PartyPerson = GetFinancialAdvisorParties_getParties_partyPerson;

export const useGetFinancialAdvisorParties = (
  options?: QueryHookOptions<GetFinancialAdvisorParties, GetFinancialAdvisorPartiesVariables>,
): QueryResult<GetFinancialAdvisorParties, GetFinancialAdvisorPartiesVariables> =>
  useSymphonyQuery(getFinancialAdvisorPartiesQuery.GetFinancialAdvisorParties, options);
