import isEqual from 'lodash.isequal';
import { FC, useEffect, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';

import { FormData } from '../../types';

interface Props {
  control: Control<FormData>;
  fieldNames: string[];
  onUpdate: () => void;
}

export const WatchForFormFields: FC<Props> = ({ control, fieldNames, onUpdate }) => {
  const [values, setValues] = useState<Record<string, any>>({});
  const watchedFields = useWatch({ control, name: fieldNames });
  useEffect(() => {
    if (Object.values(watchedFields).find(value => value !== undefined) && !isEqual(values, watchedFields)) {
      setValues(watchedFields);
      onUpdate();
    }
  }, [values, onUpdate, watchedFields]);
  return null;
};
