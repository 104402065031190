import { isAfter, isPast, isToday } from 'date-fns';

import { getDropdownItemsFromCms } from '../../Section/Question/utils';
import { ComponentTypes, QuestionOrderSteps as QuestionOrderStep } from '../../Section/types';
import { getFieldName } from '../../Section/utils';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';
import {
  commonDefaultDateQuestionProps,
  commonDefaultDateValidations,
  filterValidationRules,
  getPaperworkDataForPrefill,
  getPaperworkDateFieldValueAsIsoString,
  getPaperworkEnumFieldValue,
  getPaperworkStringFieldValue,
  isMasked,
  requiredValidation,
} from './utils';

import { IdentifierType, MaritalStatus, RelationshipName, RelationshipSubtype } from '~/__generated__';

export const originalDepositorBirthdate = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.ORIGINAL_DEPOSITOR_BIRTH_DATE,
    dataPointKey: DataPointKeys.ORIGINAL_DEPOSITOR_BIRTH_DATE,
    prefillValueGetter: data => {
      const relationship = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.relationships?.find(
        r => r && r.name === RelationshipName.DECEDENT,
      );
      return relationship?.party.partyPerson?.birthDate;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const decedent = paperworkInput.relationships?.find(r => r.name === RelationshipName.DECEDENT);
      const birthDate = getPaperworkDateFieldValueAsIsoString(
        data.formValues[getFieldName(DataPointKeys.ORIGINAL_DEPOSITOR_BIRTH_DATE, paperworkFreeFormId)],
      );
      if (decedent) {
        decedent.party = {
          ...decedent.party,
          partyPerson: {
            ...decedent.party.partyPerson,
            birthDate,
          },
        };
      } else {
        paperworkInput.relationships = [
          ...(paperworkInput.relationships ?? []),
          {
            name: RelationshipName.DECEDENT,
            party: {
              partyPerson: {
                birthDate,
              },
            },
          },
        ];
      }
    },
    componentType: ComponentTypes.Date,
    adjacent,
    rules,
    dateComponentProps: {
      ...commonDefaultDateQuestionProps,
      datePickerProps: {
        ...commonDefaultDateQuestionProps.datePickerProps,
        disableFuture: true,
      },
    },
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            ...commonDefaultDateValidations,
            isFutureDateDisabled: value => isPast(value) || isToday(value),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const originalDepositorDeathDate = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.ORIGINAL_DEPOSITOR_DEATH_DATE,
    dataPointKey: DataPointKeys.ORIGINAL_DEPOSITOR_DEATH_DATE,
    prefillValueGetter: data => {
      const relationship = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.relationships?.find(
        r => r && r.name === RelationshipName.DECEDENT,
      );
      return relationship?.party.partyPerson?.deathDate;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const decedent = paperworkInput.relationships?.find(r => r.name === RelationshipName.DECEDENT);
      const deathDate = getPaperworkDateFieldValueAsIsoString(
        data.formValues[getFieldName(DataPointKeys.ORIGINAL_DEPOSITOR_DEATH_DATE, paperworkFreeFormId)],
      );
      if (decedent) {
        decedent.party = {
          ...decedent.party,
          partyPerson: {
            ...decedent.party.partyPerson,
            deathDate,
          },
        };
      } else {
        paperworkInput.relationships = [
          ...(paperworkInput.relationships ?? []),
          {
            name: RelationshipName.DECEDENT,
            party: {
              partyPerson: {
                deathDate,
              },
            },
          },
        ];
      }
    },
    componentType: ComponentTypes.Date,
    adjacent,
    rules,
    dateComponentProps: {
      ...commonDefaultDateQuestionProps,
      datePickerProps: {
        ...commonDefaultDateQuestionProps.datePickerProps,
        disableFuture: true,
      },
    },
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            ...commonDefaultDateValidations,
            isFutureDateDisabled: value => isPast(value) || isToday(value),
            isAfterBirthDate: value => {
              const birthDateValue = data.getFormValue(
                getFieldName(DataPointKeys.ORIGINAL_DEPOSITOR_BIRTH_DATE, paperworkFreeFormId),
              );
              return value && birthDateValue ? isAfter(value, birthDateValue) : true;
            },
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const originalDepositorFirstName = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  inputComponentProps: QuestionOrderStep['inputComponentProps'],
): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.ORIGINAL_DEPOSITOR_FIRST_NAME,
    dataPointKey: DataPointKeys.ORIGINAL_DEPOSITOR_FIRST_NAME,
    prefillValueGetter: data => {
      const relationship = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.relationships?.find(
        r => r && r.name === RelationshipName.DECEDENT,
      );
      return relationship?.party.partyPerson?.givenName;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const decedent = paperworkInput.relationships?.find(r => r.name === RelationshipName.DECEDENT);
      const givenName = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.ORIGINAL_DEPOSITOR_FIRST_NAME, paperworkFreeFormId)],
      );
      if (decedent) {
        decedent.party = {
          ...decedent.party,
          partyPerson: {
            ...decedent.party.partyPerson,
            givenName,
          },
        };
      } else {
        paperworkInput.relationships = [
          ...(paperworkInput.relationships ?? []),
          {
            name: RelationshipName.DECEDENT,
            party: {
              partyPerson: {
                givenName,
              },
            },
          },
        ];
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const originalDepositorLastName = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  inputComponentProps: QuestionOrderStep['inputComponentProps'],
): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.ORIGINAL_DEPOSITOR_LAST_NAME,
    dataPointKey: DataPointKeys.ORIGINAL_DEPOSITOR_LAST_NAME,
    prefillValueGetter: data => {
      const relationship = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.relationships?.find(
        r => r && r.name === RelationshipName.DECEDENT,
      );
      return relationship?.party.partyPerson?.familyName;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const decedent = paperworkInput.relationships?.find(r => r.name === RelationshipName.DECEDENT);
      const familyName = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.ORIGINAL_DEPOSITOR_LAST_NAME, paperworkFreeFormId)],
      );
      if (decedent) {
        decedent.party = {
          ...decedent.party,
          partyPerson: {
            ...decedent.party.partyPerson,
            familyName,
          },
        };
      } else {
        paperworkInput.relationships = [
          ...(paperworkInput.relationships ?? []),
          {
            name: RelationshipName.DECEDENT,
            party: {
              partyPerson: {
                familyName,
              },
            },
          },
        ];
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const originalDepositorMiddleName = (
  { additionalValidations, adjacent, paperworkFreeFormId, required, rules }: QuestionProperties,
  inputComponentProps: QuestionOrderStep['inputComponentProps'],
): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.ORIGINAL_DEPOSITOR_MIDDLE_NAME,
    dataPointKey: DataPointKeys.ORIGINAL_DEPOSITOR_MIDDLE_NAME,
    prefillValueGetter: data => {
      const relationship = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.relationships?.find(
        r => r && r.name === RelationshipName.DECEDENT,
      );
      return relationship?.party.partyPerson?.middleName;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const decedent = paperworkInput.relationships?.find(r => r.name === RelationshipName.DECEDENT);
      const middleName = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.ORIGINAL_DEPOSITOR_MIDDLE_NAME, paperworkFreeFormId)],
      );
      if (decedent) {
        decedent.party = {
          ...decedent.party,
          partyPerson: {
            ...decedent.party.partyPerson,
            middleName,
          },
        };
      } else {
        paperworkInput.relationships = [
          ...(paperworkInput.relationships ?? []),
          {
            name: RelationshipName.DECEDENT,
            party: {
              partyPerson: {
                middleName,
              },
            },
          },
        ];
      }
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const originalDepositorRelationshipType = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.ORIGINAL_DEPOSITOR_RELATIONSHIP_TYPE,
    dataPointKey: DataPointKeys.ORIGINAL_DEPOSITOR_RELATIONSHIP_TYPE,
    prefillValueGetter: data => {
      const relationship = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.relationships?.find(
        r => r && r.name === RelationshipName.DECEDENT,
      );
      return relationship?.relationshipType;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const decedent = paperworkInput.relationships?.find(r => r.name === RelationshipName.DECEDENT);
      const relationshipType = getPaperworkEnumFieldValue(
        RelationshipSubtype,
        data.formValues[getFieldName(DataPointKeys.ORIGINAL_DEPOSITOR_RELATIONSHIP_TYPE, paperworkFreeFormId)],
      );
      if (decedent) {
        decedent.relationshipType = relationshipType;
      } else {
        paperworkInput.relationships = [
          ...(paperworkInput.relationships ?? []),
          {
            name: RelationshipName.DECEDENT,
            relationshipType,
            party: {},
          },
        ];
      }
    },
    componentType: ComponentTypes.Dropdown,
    dropdownItemsWatchedFormFields: [getFieldName(DataPointKeys.MARITAL_STATUS, paperworkFreeFormId)],
    dropdownItemsGetter: (data, content) => {
      const maritalStatus = getPaperworkEnumFieldValue(
        MaritalStatus,
        data.getFormValue(getFieldName(DataPointKeys.MARITAL_STATUS, paperworkFreeFormId)),
      );
      const hiddenOptions = maritalStatus !== MaritalStatus.MARRIED ? ['SPOUSE'] : [];
      return getDropdownItemsFromCms(
        content.questionContent,
        undefined,
        undefined,
        undefined,
        undefined,
        hiddenOptions,
      );
    },
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const originalDepositorSsn = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.ORIGINAL_DEPOSITOR_SSN,
    dataPointKey: DataPointKeys.ORIGINAL_DEPOSITOR_SSN,
    prefillValueGetter: data => {
      const relationship = getPaperworkDataForPrefill(data, paperworkFreeFormId)?.relationships?.find(
        r => r && r.name === RelationshipName.DECEDENT,
      );
      const identifier = relationship?.party.identifiers?.find(id => id.type === IdentifierType.SSN);
      return identifier?.identifierValue;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const decedent = paperworkInput.relationships?.find(r => r.name === RelationshipName.DECEDENT);
      const idNumberValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.ORIGINAL_DEPOSITOR_SSN, paperworkFreeFormId)],
      );
      if (decedent) {
        const ssnIdentifier = decedent.party.identifiers?.find(id => id.type === IdentifierType.SSN);
        if (ssnIdentifier) {
          ssnIdentifier.identifierValue = isMasked(idNumberValue) ? null : idNumberValue;
        } else {
          decedent.party = {
            ...decedent.party,
            identifiers: [
              ...(decedent.party.identifiers ?? []),
              {
                type: IdentifierType.SSN,
                identifierValue: isMasked(idNumberValue) ? null : idNumberValue,
              },
            ],
          };
        }
      } else {
        paperworkInput.relationships = [
          ...(paperworkInput.relationships ?? []),
          {
            name: RelationshipName.DECEDENT,
            party: {
              identifiers: [
                {
                  type: IdentifierType.SSN,
                  identifierValue: isMasked(idNumberValue) ? null : idNumberValue,
                },
              ],
            },
          },
        ];
      }
    },
    componentType: ComponentTypes.Input,
    inputComponentProps: {
      invalidCharacterRegex: new RegExp(/[^*\d]/g),
      formattingRules: {
        masked: {
          charsToShow: 4,
          maskChar: '*',
        },
      },
    },
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          maxLength: 9,
          minLength: 9,
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const originalDepositorText = ({ rules }: QuestionProperties): QuestionOrderStep => {
  return {
    questionKey: QuestionKeys.ORIGINAL_DEPOSITOR_TEXT,
    dataPointKey: DataPointKeys.ORIGINAL_DEPOSITOR_TEXT,
    componentType: ComponentTypes.Text,
    rules,
  };
};
