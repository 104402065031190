import { Canvas } from '@react-pdf/renderer';
import React from 'react';

export const OutlinedTick: React.FC<{ outlineColor: string; style?: any; tickColor: string }> = ({
  style,
  outlineColor,
  tickColor,
}) => {
  return (
    <Canvas
      paint={(painter: any) =>
        painter
          .lineWidth(2)
          .path(
            'M22.9412 12C22.9412 18.0426 18.0426 22.9412 12 22.9412C5.95735 22.9412 1.05882 18.0426 1.05882 12C1.05882 5.95735 5.95735 1.05882 12 1.05882C18.0426 1.05882 22.9412 5.95735 22.9412 12Z',
          )
          .strokeColor(outlineColor)
          .stroke()
          .path('M7.44278 11.8085L10.9418 15.3075L17.5008 8.74854')
          .strokeColor(tickColor)
          .stroke()
      }
      style={{
        width: 24,
        height: 24,
        transform: 'scale(0.5, 0.5)',
        ...style,
      }}
    />
  );
};
