import React, { FC, Fragment } from 'react';

import { Account } from '../../AccountSummary/AccountList/Account';
import { Account as AccountInterface, getPendingStateDescriptions } from '../../AccountSummary/utils';

import { AccountListWidgetConfigProps } from '.';
import { isAccountListSection, useGetAccountsSummaryContent } from './contentstack';

import { PendingStatusVariants } from '~/components/AccountSummary/AccountPendingStatus';
import { Alert } from '~/components/ui/Alert';
import { Divider } from '~/components/ui/Divider';
import { Box, Skeleton } from '~/components/ui/mui';
import { useAccountPropertyContent } from '~/containers/AccountSummary/hooks';
import { useAccountActionsMenu } from '~/hooks/client/useAccountActionsMenu';
import { ContentOptions } from '~/utils/contentstack/src/types';

export interface Props {
  accountsToDisplay: AccountInterface[];
  config: AccountListWidgetConfigProps;
  contentOptions: ContentOptions;
  dataQa?: string;
  showDivider?: boolean;
}

export const AccountListContainer: FC<Props> = ({
  accountsToDisplay,
  config: { accountDetailsToShow, accountInfoVariant, actions },
  contentOptions,
  dataQa = 'account-list-widget-container',
  showDivider,
}) => {
  const {
    data: accountSummaryContent,
    error: accountSummaryContentError,
    loading: accountSummaryContentLoading,
  } = useGetAccountsSummaryContent({
    variables: contentOptions,
  });
  const {
    data: accountPropertyContent,
    error: accountPropertyContentError,
    loading: accountPropertyContentLoading,
  } = useAccountPropertyContent({
    contentOptions,
  });
  const {
    data: accountActionsItems,
    error: accountActionsItemsError,
    loading: accountActionsItemsLoading,
  } = useAccountActionsMenu({
    contentOptions,
  });

  const accountListContent = accountSummaryContent?.all_account_summary?.items?.[0]?.sections?.find(
    isAccountListSection,
  )?.account_list;
  const pendingDescriptions = getPendingStateDescriptions(accountListContent?.pending_states);

  if (accountSummaryContentLoading || accountPropertyContentLoading || accountActionsItemsLoading) {
    return (
      <Box data-qa={`${dataQa}-loading`}>
        {accountsToDisplay.map(account => (
          <Skeleton height={150} key={account.accountNumber} width="100%" />
        ))}
      </Box>
    );
  }

  if (accountSummaryContentError ?? accountPropertyContentError ?? accountActionsItemsError) {
    return (
      <Alert
        contentOptions={contentOptions}
        error={accountSummaryContentError ?? accountPropertyContentError ?? accountActionsItemsError}
        severity="error"
      />
    );
  }

  return (
    <Box data-qa={`${dataQa}`}>
      {accountPropertyContent &&
        accountsToDisplay.map((account, idx) => (
          <Fragment key={account.accountNumber}>
            <Box className="account-list-widget-item" sx={{ px: 1, py: 3 }}>
              <Account
                accountData={account}
                accountDetailsToShow={accountDetailsToShow}
                accountInfoVariant={accountInfoVariant}
                allAccounts={accountsToDisplay}
                content={{
                  accessibility: { accountMenuAriaLabel: 'more actions' },
                  accountNumberPrefix: accountListContent?.account_number_prefix ?? 'MISSING ACCOUNT NUMBER PREFIX',
                  shouldMaskAccountNumber: accountListContent?.mask_account_number ?? true,
                  accountNumberFormat:
                    accountListContent?.account_number_format_v2Connection?.edges?.[0]?.node?.pattern ??
                    '${accountNumber}',
                  accountType: accountPropertyContent.getAccountTypeText(account.type),
                  ownershipType: accountPropertyContent.getRelationshipNamesText(account.relationshipNames),
                  accountProgram: accountPropertyContent.getAccountProgramText(
                    account.program,
                    account.attributes,
                    true,
                  ),
                  ctas: {
                    pendingModelChangeModalClose: accountListContent?.ctas?.pending_model_change_close ?? '',
                    pendingModelChangeModalCancel: accountListContent?.ctas?.pending_model_change_cancel ?? '',
                    pendingModelChangeModalDiscard: accountListContent?.ctas?.pending_model_change_discard ?? '',
                    addFunds: accountListContent?.ctas?.add_funds ?? 'MISSING ADD FUNDS CTA',
                    approve: accountListContent?.ctas?.approve ?? 'MISSING APPROVE CTA',
                    openNewAccount: accountListContent?.ctas?.open_a_new_account ?? 'MISSING OPEN NEW ACCOUNT CTA',
                    continuePendingAccount:
                      accountListContent?.ctas?.continue_pending_account ?? 'MISSING CONTINUE PENDING ACCOUNT CTA',
                    finishPendingAccount:
                      accountListContent?.ctas?.finish_pending_account ?? 'MISSING FINISH PENDING ACCOUNT CTA',
                    resendDocusign: accountListContent?.ctas?.resend_docusign ?? 'MISSING RESEND DOCUSIGN CTA',
                    signDocuments: accountListContent?.ctas?.sign_documents ?? 'MISSING SIGN DOCUMENTS CTA',
                    generateDocuments: accountListContent?.ctas?.generate_documents ?? 'MISSING GENERATE DOCUMENTS CTA',
                  },
                  ctasMoreAction: accountActionsItems ?? [],
                  modelPortfolio: {
                    donutSize: 64,
                    donutInnerRadius: 10,
                    donutShowTooltip: false,
                    donutAllocations: accountPropertyContent.getDonutAllocations(account.donutAllocations ?? []),
                  },
                  labels: {
                    effectiveDate: '', // This is not used in widget, adding it here since it shares type with account summary
                    resendEmailSentMessage: accountListContent?.labels?.resend_docusign_email_sent_message ?? '',
                    partialAccount: accountListContent?.labels?.partial_account ?? 'MISSING PARTIAL ACCOUNT LABEL',
                    balance: accountListContent?.labels?.balance ?? 'MISSING BALANCE LABEL',
                    ytdReturn: accountListContent?.labels?.ytd_return ?? 'MISSING YTD RETURN LABEL',
                    modelPortfolio: accountListContent?.labels?.model_portfolio ?? 'MISSING MODEL_PORTFOLIO LABEL',
                    pendingModelChange:
                      accountListContent?.labels?.pending_model_change ?? 'MISSING PENDING_MODEL_CHANGE LABEL',
                    pendingModelChangeGenericText:
                      accountListContent?.labels?.pending_model_change_generic_text ??
                      'MISSING PENDING_MODEL_CHANGE_GENERIC_TEXT LABEL',
                    pendingModelChangeModalDiscardMessage:
                      accountListContent?.labels?.pending_model_change_modal_discard_message ?? '',
                    pendingModelChangeModalSuccessfulDiscard:
                      accountListContent?.labels?.pending_model_change_modal_successful_discard ?? '',
                    pendingModelChangeModalTitle: accountListContent?.labels?.pending_model_change_modal_title ?? '',
                    compositeModelPortfolio: accountListContent?.labels?.composite_model_portfolio ?? '',
                  },
                  pendingStatus: {
                    labels: {
                      sign: accountListContent?.labels?.sign ?? 'MISSING SIGN LABEL',
                      activate: accountListContent?.labels?.activate ?? 'MISSING ACTIVATE LABEL',
                      fund: accountListContent?.labels?.fund ?? 'MISSING FUND LABEL',
                      rebalance: accountListContent?.labels?.rebalance ?? 'MISSING REBALANCE LABEL',
                      pending_model_change:
                        accountListContent?.labels?.pending_model_change ?? 'MISSING PENDING_MODEL_CHANGE LABEL',
                    },
                    descriptions: pendingDescriptions,
                  },
                  actions,
                  errorStates: {
                    suspended: accountListContent?.error_states?.suspended ?? 'MISSING SUSPENDED DESCRIPTION',
                    pendingClosed:
                      accountListContent?.error_states?.pending_closed ?? 'MISSING PENDING CLOSED DESCRIPTION',
                    needsAttention:
                      accountListContent?.error_states?.needs_attention ?? 'MISSING NEEDS ATTENTION DESCRIPTION',
                  },
                }}
                contentOptions={contentOptions}
                getAccountTypeLabel={accountPropertyContent.getAccountTypeText}
                pendingBarsVariant={PendingStatusVariants.Stacked}
              />
            </Box>
            {showDivider && idx !== accountsToDisplay.length - 1 && <Divider />}
          </Fragment>
        ))}
    </Box>
  );
};
