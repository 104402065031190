import { RegisterOptions } from 'react-hook-form';

import { QuestionRule } from '../../Section/types';

export enum QuestionKeys {
  ADDITIONAL_STAKEHOLDER = 'question:additional_stakeholder',
  ANNUAL_HOUSEHOLD_INCOME = 'question:annual_household_income',
  ANNUAL_RECURRING_EXPENSES = 'question:annual_recurring_expenses',
  ASSETS_HELD_AWAY = 'question:assets_held_away',
  ASSETS_HELD_AWAY_ALTERNATIVE_INVESTMENTS = 'question:assets_held_away_alternative_investments',
  ASSETS_HELD_AWAY_ANNUITIES_AND_LIFE_INSURANCE = 'question:assets_held_away_annuities_and_life_insurance',
  ASSETS_HELD_AWAY_BONDS = 'question:assets_held_away_bonds',
  ASSETS_HELD_AWAY_ERROR_MESSAGE = 'question:assets_held_away_error_message',
  ASSETS_HELD_AWAY_FOREIGN_CURRENCY = 'question:assets_held_away_foreign_currency',
  ASSETS_HELD_AWAY_FOREIGN_SECURITY = 'question:assets_held_away_foreign_security',
  ASSETS_HELD_AWAY_INFO = 'question:assets_held_away_info',
  ASSETS_HELD_AWAY_LIFE_INSURANCE = 'question:assets_held_away_life_insurance',
  ASSETS_HELD_AWAY_LIMITED_PARTNERSHIPS = 'question:assets_held_away_limited_partnerships',
  ASSETS_HELD_AWAY_MUTUAL_FUNDS = 'question:assets_held_away_mutual_funds',
  ASSETS_HELD_AWAY_OPTIONS = 'question:assets_held_away_options',
  ASSETS_HELD_AWAY_OTHER = 'question:assets_held_away_other',
  ASSETS_HELD_AWAY_SECURITY_FUTURES = 'question:assets_held_away_security_futures',
  ASSETS_HELD_AWAY_SHORT_TERMS = 'question:assets_held_away_short_terms',
  ASSETS_HELD_AWAY_STOCKS = 'question:assets_held_away_stocks',
  ASSETS_HELD_AWAY_TOTAL_ASSET_VALUE = 'question:assets_held_away_total_asset_value',
  ASSETS_HELD_AWAY_TRUST_INFO = 'question:assets_held_away_trust_info',
  ASSETS_HELD_AWAY_VARIABLE_CONTRACTS = 'question:assets_held_away_variable_contracts',
  BIRTH_DATE = 'question:birth_date',
  CITIZENSHIP = 'question:citizenship',
  COMPANY_CITY = 'question:company_city',
  COMPANY_COUNTRY = 'question:company_country',
  COMPANY_NAME = 'question:company_name',
  COMPANY_STATE = 'question:company_state',
  COMPANY_STREET_ADDRESS = 'question:company_street_address',
  COMPANY_STREET_ADDRESS_LINE_2 = 'question:company_street_line_2',
  COMPANY_ZIP_CODE = 'question:company_zip_code',
  CONFIRM_EMAIL = 'question:confirm_email',
  CONTINGENT_BENEFICIARY = 'question:beneficiary',
  CONTINGENT_BENEFICIARY_ACCOUNT_PROFILE_REMOVE_CTA = 'question:beneficiary_account-profile-remove_cta',
  CONTINGENT_BENEFICIARY_ADD_CTA = 'question:beneficiary_add_cta',
  CONTINGENT_BENEFICIARY_DOB = 'question:beneficiary_dob',
  CONTINGENT_BENEFICIARY_FIRST_NAME = 'question:beneficiary_first_name',
  CONTINGENT_BENEFICIARY_LAST_NAME = 'question:beneficiary_last_name',
  CONTINGENT_BENEFICIARY_NAME = 'question:beneficiary_name',
  CONTINGENT_BENEFICIARY_NAME_OF_TRUSTEE = 'question:beneficiary_name_of_trustee',
  CONTINGENT_BENEFICIARY_ORGANIZATION_COUNTRY = 'question:beneficiary_organization_country',
  CONTINGENT_BENEFICIARY_PERCENTAGE = 'question:beneficiary_percentage',
  CONTINGENT_BENEFICIARY_PER_STIRPES = 'question:beneficiary_per_stirpes',
  CONTINGENT_BENEFICIARY_REMOVE_CTA = 'question:beneficiary_remove_cta',
  CONTINGENT_BENEFICIARY_SSN = 'question:beneficiary_ssn',
  CONTINGENT_BENEFICIARY_SUBTITLE = 'question:beneficiary_subtitle',
  CONTINGENT_BENEFICIARY_TAX_REPORTING_NUMBER = 'question:beneficiary_tax_reporting_number',
  CONTINGENT_BENEFICIARY_TAX_REPORTING_NUMBER_TYPE = 'question:beneficiary_tax_reporting_number_type',
  CONTINGENT_BENEFICIARY_TITLE = 'question:beneficiary_title',
  CONTINGENT_BENEFICIARY_TRUST_DATE = 'question:beneficiary_trust_date',
  CONTINGENT_BENEFICIARY_TYPE = 'question:beneficiary_type',
  CONTINGENT_BENEFICIARY_ULID = 'question:beneficiary_ulid',
  DESIGNATE_PRIMARY_BENEFICIARY = 'question:designate_primary_beneficiary',
  DESIGNATE_PRIMARY_BENEFICIARY_LABEL = 'question:designate_primary_label_text',
  DESIGNATE_TRUSTED_CONTACT = 'question:designate_trusted_contact',
  DIFFERENT_MAILING_ADDRESS = 'question:different_mailing_address',
  DRIVING_LICENSE_EXPIRY_DATE = 'question:driving_license_expiry_date',
  DRIVING_LICENSE_IDENTIFICATION_NUMBER = 'question:driving_license_identification_number',
  DRIVING_LICENSE_ISSUE_DATE = 'question:driving_license_issue_date',
  DRIVING_LICENSE_STATE = 'question:driving_license_state',
  EDELIVERY = 'question:enroll_in_edelivery',
  EDELIVERY_EMAIL = 'question:edelivery_email',
  EMAIL = 'question:email',
  EMPLOYMENT = 'question:employment',
  EMPLOYMENT_STATUS = 'question:employment_status',
  ESTIMATED_NET_WORTH = 'question:estimated_net_worth',
  FIRST_NAME = 'question:first_name',
  GRANTOR_PAGE_SOFT_WARNING = 'question:grantor_page_soft_warning',
  GRANTOR_SSN = 'question:grantor_ssn',
  HOME_CITY = 'question:city',
  HOME_COUNTRY = 'question:country',
  HOME_STATE = 'question:state',
  HOME_STREET_ADDRESS = 'question:street_address',
  HOME_STREET_ADDRESS_LINE_2 = 'question:street_line_2',
  HOME_ZIP_CODE = 'question:zip_code',
  IDENTIFICATION = 'question:identification',
  IDENTIFICATION_TYPE = 'question:identification_type',
  INCOME_SOURCE = 'question:income_source',
  IS_SSN_SAME_AS_TRUSTEE = 'question:is_ssn_same_as_trustee',
  JOB_TITLE = 'question:job_title',
  JOB_TITLE_SELF_EMPLOYED = 'question:job_title_self_employed',
  LAST_NAME = 'question:last_name',
  LIQUID_NET_WORTH = 'question:liquid_net_worth',
  MAILING_CITY = 'question:mailing_city',
  MAILING_COUNTRY = 'question:mailing_country',
  MAILING_STATE = 'question:mailing_state',
  MAILING_STREET_ADDRESS = 'question:mailing_street_address',
  MAILING_STREET_ADDRESS_LINE_2 = 'question:mailing_street_line_2',
  MAILING_ZIP_CODE = 'question:mailing_zip_code',
  MARITAL_STATUS = 'question:marital_status',
  MIDDLE_NAME = 'question:middle_name',
  NUMBER_OF_DEPENDENTS = 'question:number_of_dependents',
  NUMBER_OF_TRUSTEES = 'question:number_of_trustees',
  ORIGINAL_DEPOSITOR_BIRTH_DATE = 'question:original_depositor_birth_date',
  ORIGINAL_DEPOSITOR_DEATH_DATE = 'question:original_depositor_death_date',
  ORIGINAL_DEPOSITOR_FIRST_NAME = 'question:original_depositor_first_name',
  ORIGINAL_DEPOSITOR_LAST_NAME = 'question:original_depositor_last_name',
  ORIGINAL_DEPOSITOR_MIDDLE_NAME = 'question:original_depositor_middle_name',
  ORIGINAL_DEPOSITOR_RELATIONSHIP_TYPE = 'question:original_depositor_relationship',
  ORIGINAL_DEPOSITOR_SSN = 'question:original_depositor_ssn',
  ORIGINAL_DEPOSITOR_TEXT = 'question:original_depositor_text',
  OTHER_IDENTIFICATION_EXPIRY_DATE = 'question:other_identification_expiry_date',
  OTHER_IDENTIFICATION_ISSUE_DATE = 'question:other_identification_issue_date',
  OTHER_IDENTIFICATION_NUMBER = 'question:other_identification_number',
  OTHER_INCOME_SOURCE = 'question:other_income_source',
  PASSPORT_COUNTRY = 'question:passport_country',
  PASSPORT_EXPIRY_DATE = 'question:passport_expiry_date',
  PASSPORT_IDENTIFICATION_NUMBER = 'question:passport_identification_number',
  PASSPORT_ISSUE_DATE = 'question:passport_issue_date',
  PHONE_NUMBER = 'question:phone_number',
  PRIMARY_BENEFICIARY = 'question:beneficiary_primary',
  PRIMARY_BENEFICIARY_ACCOUNT_PROFILE_REMOVE_CTA = 'question:beneficiary_primary_account-profile-remove_cta',
  PRIMARY_BENEFICIARY_ADD_CTA = 'question:beneficiary_primary_add_cta',
  PRIMARY_BENEFICIARY_DOB = 'question:beneficiary_primary_dob',
  PRIMARY_BENEFICIARY_FIRST_NAME = 'question:beneficiary_primary_first_name',
  PRIMARY_BENEFICIARY_LAST_NAME = 'question:beneficiary_primary_last_name',
  PRIMARY_BENEFICIARY_NAME = 'question:beneficiary_primary_name',
  PRIMARY_BENEFICIARY_NAME_OF_TRUSTEE = 'question:beneficiary_primary_name_of_trustee',
  PRIMARY_BENEFICIARY_ORGANIZATION_COUNTRY = 'question:beneficiary_primary_organization_country',
  PRIMARY_BENEFICIARY_PERCENTAGE = 'question:beneficiary_primary_percentage',
  PRIMARY_BENEFICIARY_PER_STIRPES = 'question:beneficiary_primary_per_stirpes',
  PRIMARY_BENEFICIARY_REMOVE_CTA = 'question:beneficiary_primary_remove_cta',
  PRIMARY_BENEFICIARY_SSN = 'question:beneficiary_primary_ssn',
  PRIMARY_BENEFICIARY_SUBTITLE = 'question:beneficiary_primary_subtitle',
  PRIMARY_BENEFICIARY_TAX_REPORTING_NUMBER = 'question:beneficiary_primary_tax_reporting_number',
  PRIMARY_BENEFICIARY_TAX_REPORTING_NUMBER_TYPE = 'question:beneficiary_primary_tax_reporting_number_type',
  PRIMARY_BENEFICIARY_TITLE = 'question:beneficiary_primary_title',
  PRIMARY_BENEFICIARY_TRUST_DATE = 'question:beneficiary_primary_trust_date',
  PRIMARY_BENEFICIARY_TYPE = 'question:beneficiary_primary_type',
  PRIMARY_BENEFICIARY_ULID = 'question:beneficiary_primary_ulid',
  REGULATORY = 'question:regulatory',
  REGULATORY_LABEL = `question:regulatory_label`,
  REGULATORY_SPF_CHECKBOX = `question:regulatory_senior_political_figure`,
  REGULATORY_SPF_NAME = `question:regulatory_senior_political_figure_name`,
  REGULATORY_SPF_RELATIONSHIP = `question:regulatory_senior_political_figure_relationship`,
  REGULATORY_SPF_TITLE = `question:regulatory_senior_political_figure_title`,
  REGULATORY_SPF_COUNTRY = `question:regulatory_senior_political_figure_country`,
  REGULATORY_PHC_CHECKBOX = `question:regulatory_policy_making_officer`,
  REGULATORY_PHC_NAME = `question:regulatory_policy_making_officer_company_name`,
  REGULATORY_PHC_TICKER = `question:regulatory_policy_making_officer_company_ticker`,
  REGULATORY_EXCHANGE_EMPLOYED_CHECKBOX = `question:regulatory_employed_by_regulatory_authority`,
  REGULATORY_SAME_AS_EMPLOYER = `question:regulatory_same_as_employer`,
  REGULATORY_EXCHANGE_COMPANY = `question:regulatory_exchange_company_name`,
  REGULATORY_EXCHANGE_AFFILIATION_TYPE = `question:regulatory_exchange_affiliation_type`,
  REGULATORY_EXCHANGE_STREET_ADDRESS = `question:regulatory_exchange_company_street_address`,
  REGULATORY_EXCHANGE_STREET_LINE = `question:regulatory_exchange_company_street_line_2`,
  REGULATORY_EXCHANGE_CITY = `question:regulatory_exchange_company_city`,
  REGULATORY_EXCHANGE_STATE = `question:regulatory_exchange_company_state`,
  REGULATORY_EXCHANGE_ZIP_CODE = `question:regulatory_exchange_company_zip_code`,
  REGULATORY_EXCHANGE_COMPANY_AFFILIATION_LETTER = `question:regulatory_exchange_company_affiliation_letter`,
  REGULATORY_EXCHANGE_COMPANY_AFFILIATION_LETTER_INFO = `question:regulatory_exchange_company_affiliation_letter_info`,
  REGULATORY_PHC_CONTROL_TYPE = `question:regulatory_policy_making_officer_control_type`,
  REGULATORY_PHC_RELATIONSHIP = `question:regulatory_policy_making_officer_relationship`,
  REGULATORY_EXCHANGE_RELATIONSHIP = `question:regulatory_exchange_relationship`,
  REGULATORY_ACCREDITED_INVESTOR_CHECKBOX = `question:regulatory_accredited_investor`,
  REGULATORY_NON_ACCOUNT_HOLDING_BROKER_DEALER_CHECKBOX = `question:regulatory_non_account_holding_broker_dealer`,
  REGULATORY_ACCOUNT_HOLDING_BROKER_DEALER_CHECKBOX = `question:regulatory_account_holding_broker_dealer`,
  REGULATORY_REGISTERED_INVESTMENT_ADVISOR_CHECKBOX = `question:regulatory_registered_investment_advisor`,
  RESIDENT_ALIEN_TAX_STATUS = 'question:resident_alien_tax_status',
  RESIDENT_TYPE = 'question:resident_type',
  REVOCABLE_PRIMARY_TRUSTEE = 'question:revocable_primary_trustee',
  REVOKER_FIRST_NAME = 'question:revoker_first_name',
  REVOKER_LAST_NAME = 'question:revoker_last_name',
  REVOKER_MIDDLE_NAME = 'question:revoker_middle_name',
  SAME_HOME_MAILING_ADDRESS = 'question:same_home_mailing_address',
  SECTION_JUSTIFICATION = 'question:section_justification',
  SSN = 'question:ssn',
  STATE_LAW_GOVERNING_TRUST = 'question:state_law_governing_trust',
  TAX_BRACKET = 'question:tax_bracket',
  TAX_REPORTING_NUMBER = 'question:tax_reporting_number',
  TAX_REPORTING_TYPE = 'question:tax_reporting_type',
  TENANTS_IN_COMMON_PERCENTAGE = 'question:tenants_in_common_percentage',
  TENANTS_IN_COMMON_PERCENTAGE_CO_APPLICANT = 'question:tenants_in_common_percentage_co_applicant',
  TIN = 'question:tin',
  TLH = 'question:tlh',
  TLH_LABEL = 'question:tlh_label',
  TLH_TEXT = 'question:tlh_text',
  TRUSTED_CONTACT = 'question:trusted_contact',
  TRUSTED_CONTACT_ADD_CTA = 'question:trusted_contact_add_cta',
  TRUSTED_CONTACT_ALTERNATE_FIRST_NAME = 'question:trusted_contact_alternate_first_name',
  TRUSTED_CONTACT_ALTERNATE_LAST_NAME = 'question:trusted_contact_alternate_last_name',
  TRUSTED_CONTACT_ALTERNATE_MIDDLE_NAME = 'question:trusted_contact_alternate_middle_name',
  TRUSTED_CONTACT_CITY = 'question:trusted_contact_city',
  TRUSTED_CONTACT_EMAIL = 'question:trusted_contact_email',
  TRUSTED_CONTACT_FIRST_NAME = 'question:trusted_contact_first_name',
  TRUSTED_CONTACT_LAST_NAME = 'question:trusted_contact_last_name',
  TRUSTED_CONTACT_MIDDLE_NAME = 'question:trusted_contact_middle_name',
  TRUSTED_CONTACT_PHONE_NUMBER = 'question:trusted_contact_phone_number',
  TRUSTED_CONTACT_RELATIONSHIP = 'question:trusted_contact_relationship',
  TRUSTED_CONTACT_REMOVE_CTA = 'question:trusted_contact_remove_cta',
  TRUSTED_CONTACT_STATE = 'question:trusted_contact_state',
  TRUSTED_CONTACT_STREET_ADDRESS = 'question:trusted_contact_street_address',
  TRUSTED_CONTACT_STREET_LINE_2 = 'question:trusted_contact_street_line_2',
  TRUSTED_CONTACT_SUBTITLE = 'question:trusted_contact_subtitle',
  TRUSTED_CONTACT_TITLE = 'question:trusted_contact_title',
  TRUSTED_CONTACT_ZIP_CODE = 'question:trusted_contact_zip_code',
  TRUST_DATE = 'question:trust_date',
  TRUST_NAME = 'question:trust_name',
  TRUST_SSN = 'question:trust_ssn',
  TRUST_TYPE = 'question:type_of_trust',
  US_TAX_STATUS = 'question:us_tax_status',
  WEALTH_EXPENSES_TEXT = 'question:wealth_expenses_text',
}

export enum DataPointKeys {
  ADDITIONAL_STAKEHOLDER = 'label:additional_stakeholder:string',
  ANNUAL_HOUSEHOLD_INCOME = 'data_point:annual_household_income:float',
  ANNUAL_RECURRING_EXPENSES = 'data_point:annual_recurring_expenses:float',
  ASSETS_HELD_AWAY = 'custom:assets_held_away:assets_held_away',
  ASSETS_HELD_AWAY_ALTERNATIVE_INVESTMENTS = 'custom:assets_held_away_alternative_investments:number',
  ASSETS_HELD_AWAY_ANNUITIES_AND_LIFE_INSURANCE = 'custom:assets_held_away_annuities_and_life_insurance:number',
  ASSETS_HELD_AWAY_BONDS = 'custom:assets_held_away_bonds:number',
  ASSETS_HELD_AWAY_FOREIGN_CURRENCY = 'custom:assets_held_away_foreign_currency:number',
  ASSETS_HELD_AWAY_FOREIGN_SECURITY = 'custom:assets_held_away_foreign_security:number',
  ASSETS_HELD_AWAY_LIFE_INSURANCE = 'custom:assets_held_away_life_insurance:number',
  ASSETS_HELD_AWAY_LIMITED_PARTNERSHIPS = 'custom:assets_held_away_limited_partnerships:number',
  ASSETS_HELD_AWAY_MUTUAL_FUNDS = 'custom:assets_held_away_mutual_funds:number',
  ASSETS_HELD_AWAY_OPTIONS = 'custom:assets_held_away_options:number',
  ASSETS_HELD_AWAY_OTHER = 'custom:assets_held_away_other:number',
  ASSETS_HELD_AWAY_SECURITY_FUTURES = 'custom:assets_held_away_security_futures:number',
  ASSETS_HELD_AWAY_SHORT_TERMS = 'custom:assets_held_away_short_terms:number',
  ASSETS_HELD_AWAY_STOCKS = 'custom:assets_held_away_stocks:number',
  ASSETS_HELD_AWAY_VARIABLE_CONTRACTS = 'custom:assets_held_away_variable_contracts:number',
  BIRTH_DATE = 'data_point:birth_date:date',
  CITIZENSHIP = 'data_point:citizenship:single_option',
  COMPANY_ADDRESS_CITY = 'data_point:company_city:string',
  COMPANY_ADDRESS_COUNTRY = 'data_point:company_country:single_option',
  COMPANY_ADDRESS_STATE = 'data_point:company_state:single_option',
  COMPANY_ADDRESS_STREET_ADDRESS = 'data_point:company_street_address:string',
  COMPANY_ADDRESS_STREET_LINE_2 = 'data_point:company_street_line_2:string',
  COMPANY_ADDRESS_ZIP_CODE = 'data_point:company_zip_code:number',
  COMPANY_NAME = 'data_point:company_name:string',
  CONFIRM_EMAIL = 'data_point:confirm_email:email',
  CONTINGENT_BENEFICIARY = 'custom:contingent_beneficiaries:beneficiaries',
  CONTINGENT_BENEFICIARY_DOB = 'data_point:beneficiary-dob',
  CONTINGENT_BENEFICIARY_FIRST_NAME = 'data_point:beneficiary-first_name',
  CONTINGENT_BENEFICIARY_LAST_NAME = 'data_point:beneficiary-last_name',
  CONTINGENT_BENEFICIARY_NAME = 'data_point:beneficiary-name',
  CONTINGENT_BENEFICIARY_NAME_OF_TRUSTEE = 'data_point:beneficiary-name-of-trustee',
  CONTINGENT_BENEFICIARY_ORGANIZATION_COUNTRY = 'data_point:beneficiary-organization-country',
  CONTINGENT_BENEFICIARY_PERCENTAGE = 'data_point:beneficiary-percentage',
  CONTINGENT_BENEFICIARY_PER_STIRPES = 'data_point:beneficiary-per-stirpes',
  CONTINGENT_BENEFICIARY_SSN = 'data_point:beneficiary-ssn',
  CONTINGENT_BENEFICIARY_TAX_REPORTING_NUMBER = 'data_point:beneficiary-tax-reporting-number',
  CONTINGENT_BENEFICIARY_TAX_REPORTING_NUMBER_TYPE = 'data_point:beneficiary-tax-reporting-number-type',
  CONTINGENT_BENEFICIARY_TYPE = 'data_point:beneficiary-type',
  CONTINGENT_BENEFICIARY_ULID = 'data_point:beneficiary-ulid',
  CONTINGENT_BENEFICIARY_UNIQUE_IDS = 'data_point:beneficiary-unique-ids',
  DESIGNATE_PRIMARY_BENEFICIARY = 'data_point:designate_primary_beneficiary:single_option',
  DESIGNATE_PRIMARY_BENEFICIARY_LABEL = 'text:designate_primary_label_text:string',
  DESIGNATE_TRUSTED_CONTACT = 'data_point:designate_trusted_contact:single_option',
  DIFFERENT_MAILING_ADDRESS = 'data_point:different_mailing_address:boolean',
  DRIVING_LICENSE_EXPIRY_DATE = 'data_point:driving_license_expiry_date:date',
  DRIVING_LICENSE_IDENTIFICATION_NUMBER = 'data_point:driving_license_identification_number:string',
  DRIVING_LICENSE_ISSUE_DATE = 'data_point:driving_license_issue_date:date',
  DRIVING_LICENSE_STATE = 'data_point:driving_license_state:single_option',
  EDELIVERY = 'data_point:enroll_in_edelivery:single_option',
  EDELIVERY_EMAIL = 'data_point:edelivery_email:email',
  EMAIL = 'data_point:email:email',
  EMPLOYMENT = 'label:employment:string',
  EMPLOYMENT_STATUS = 'data_point:employment_status:single_option',
  ESTIMATED_NET_WORTH = 'data_point:estimated_net_worth:float',
  FIRST_NAME = 'data_point:first_name:string',
  GRANTOR_PAGE_SOFT_WARNING = 'info:grantor_page_soft_warning:string',
  HOME_ADDRESS_CITY = 'data_point:city:string',
  HOME_ADDRESS_COUNTRY = 'data_point:country:single_option',
  HOME_ADDRESS_STATE = 'data_point:state:single_option',
  HOME_ADDRESS_STREET_ADDRESS = 'data_point:street_address:string',
  HOME_ADDRESS_STREET_LINE_2 = 'data_point:street_line_2:string',
  HOME_ADDRESS_ZIP_CODE = 'data_point:zip_code:number',
  IDENTIFICATION = 'label:identification:string',
  IDENTIFICATION_TYPE = 'data_point:identification_type:single_option',
  INCOME_SOURCE = 'data_point:income_source:single_option',
  IS_SSN_SAME_AS_TRUSTEE = 'data_point:is_ssn_same_as_trustee:single_option',
  JOB_TITLE = 'data_point:job_title:string',
  JOB_TITLE_SELF_EMPLOYED = 'data_point:job_title_self_employed:string',
  LAST_NAME = 'data_point:last_name:string',
  LIQUID_NET_WORTH = 'data_point:liquid_net_worth:float',
  MAILING_ADDRESS_CITY = 'data_point:mailing_city:string',
  MAILING_ADDRESS_COUNTRY = 'data_point:mailing_country:single_option',
  MAILING_ADDRESS_STATE = 'data_point:mailing_state:single_option',
  MAILING_ADDRESS_STREET_ADDRESS = 'data_point:mailing_street_address:string',
  MAILING_ADDRESS_STREET_LINE_2 = 'data_point:mailing_street_line_2:string',
  MAILING_ADDRESS_ZIP_CODE = 'data_point:mailing_zip_code:number',
  MARITAL_STATUS = 'data_point:marital_status:single_option',
  MIDDLE_NAME = 'data_point:middle_name:string',
  NUMBER_OF_DEPENDENTS = 'data_point:number_of_dependents:number',
  NUMBER_OF_TRUSTEES = 'data_point:number_of_trustees:single_option',
  ORIGINAL_DEPOSITOR_BIRTH_DATE = 'data_point:original_depositor_birth_date:date',
  ORIGINAL_DEPOSITOR_DEATH_DATE = 'data_point:original_depositor_death_date:date',
  ORIGINAL_DEPOSITOR_FIRST_NAME = 'data_point:original_depositor_first_name:string',
  ORIGINAL_DEPOSITOR_LAST_NAME = 'data_point:original_depositor_last_name:string',
  ORIGINAL_DEPOSITOR_MIDDLE_NAME = 'data_point:original_depositor_middle_name:string',
  ORIGINAL_DEPOSITOR_RELATIONSHIP_TYPE = 'data_point:original_depositor_relationship:single_option',
  ORIGINAL_DEPOSITOR_SSN = 'data_point:original_depositor_ssn:ssn',
  ORIGINAL_DEPOSITOR_TEXT = 'text:original_depositor_text:string',
  OTHER_IDENTIFICATION_EXPIRY_DATE = 'data_point:other_identification_expiry_date:date',
  OTHER_IDENTIFICATION_ISSUE_DATE = 'data_point:other_identification_issue_date:date',
  OTHER_IDENTIFICATION_NUMBER = 'data_point:other_identification_number:string',
  OTHER_INCOME_SOURCE = 'data_point:other_income_source:string',
  PASSPORT_COUNTRY = 'data_point:passport_country:single_option',
  PASSPORT_EXPIRY_DATE = 'data_point:passport_expiry_date:date',
  PASSPORT_IDENTIFICATION_NUMBER = 'data_point:passport_identification_number:string',
  PASSPORT_ISSUE_DATE = 'data_point:passport_issue_date:date',
  PHONE_NUMBER = 'data_point:phone_number:number',
  PRIMARY_BENEFICIARY = 'custom:primary_beneficiaries:beneficiaries',
  PRIMARY_BENEFICIARY_DOB = 'data_point:primary_beneficiary-dob',
  PRIMARY_BENEFICIARY_FIRST_NAME = 'data_point:primary_beneficiary-first_name',
  PRIMARY_BENEFICIARY_LAST_NAME = 'data_point:primary_beneficiary-last_name',
  PRIMARY_BENEFICIARY_NAME = 'data_point:primary_beneficiary-name',
  PRIMARY_BENEFICIARY_NAME_OF_TRUSTEE = 'data_point:primary_beneficiary-name-of-trustee',
  PRIMARY_BENEFICIARY_ORGANIZATION_COUNTRY = 'data_point:primary_beneficiary-organization-country',
  PRIMARY_BENEFICIARY_PERCENTAGE = 'data_point:primary_beneficiary-percentage',
  PRIMARY_BENEFICIARY_PER_STIRPES = 'data_point:primary_beneficiary-per-stirpes',
  PRIMARY_BENEFICIARY_SSN = 'data_point:primary_beneficiary-ssn',
  PRIMARY_BENEFICIARY_TAX_REPORTING_NUMBER = 'data_point:primary_beneficiary-tax-reporting-number',
  PRIMARY_BENEFICIARY_TAX_REPORTING_NUMBER_TYPE = 'data_point:primary_beneficiary-tax-reporting-number-type',
  PRIMARY_BENEFICIARY_TYPE = 'data_point:primary_beneficiary-type',
  PRIMARY_BENEFICIARY_ULID = 'data_point:primary_beneficiary-ulid',
  PRIMARY_BENEFICIARY_UNIQUE_IDS = 'data_point:primary_beneficiary-unique-ids',
  REGULATORY = 'custom:regulatory',
  REGULATORY_SPF_CHECKBOX = `custom:regulatory-senior-political-figure`,
  REGULATORY_SPF_NAME = `custom:regulatory-senior-political-figure-name`,
  REGULATORY_SPF_RELATIONSHIP = `custom:regulatory-senior-political-figure-relationship`,
  REGULATORY_SPF_TITLE = `custom:regulatory-senior-political-figure-title`,
  REGULATORY_SPF_COUNTRY = `custom:regulatory-senior-political-figure-country`,
  REGULATORY_PHC_CHECKBOX = `custom:regulatory-phc`,
  REGULATORY_PHC_NAME = `custom:regulatory-phc-company-name`,
  REGULATORY_PHC_TICKER = `custom:regulatory-phc-ticker`,
  REGULATORY_PHC_CONTROL_TYPE = `custom:regulatory-phc-control-type`,
  REGULATORY_PHC_RELATIONSHIP = `custom:regulatory-phc-relationship`,
  REGULATORY_EXCHANGE_EMPLOYED_CHECKBOX = `custom:regulatory-exchange-company`,
  REGULATORY_SAME_AS_EMPLOYER = `custom:regulatory-exchange-company-same-as-employer`,
  REGULATORY_EXCHANGE_COMPANY_NAME = `custom:regulatory-exchange-company-name`,
  REGULATORY_EXCHANGE_AFFILIATION_TYPE = `custom:regulatory-exchange-company-affiliation-type`,
  REGULATORY_EXCHANGE_RELATIONSHIP = `custom:regulatory-exchange-company-relationship`,
  REGULATORY_EXCHANGE_COMPANY_STREET_ADDRESS = `custom:regulatory-exchange-company-street-address`,
  REGULATORY_EXCHANGE_COMPANY_STREET_LINE = `custom:regulatory-exchange-company-street-line`,
  REGULATORY_EXCHANGE_COMPANY_STATE = `custom:regulatory-exchange-company-state`,
  REGULATORY_EXCHANGE_COMPANY_CITY = `custom:regulatory-exchange-company-city`,
  REGULATORY_EXCHANGE_COMPANY_ZIPCODE = `custom:regulatory-exchange-company-zip-code`,
  REGULATORY_EXCHANGE_COMPANY_AFFILIATION_LETTER = `custom:regulatory-exchange-company-company_affiliation_letter`,
  REGULATORY_ACCREDITED_INVESTOR_CHECKBOX = `custom:regulatory-accredited-investor`,
  REGULATORY_ASSOCIATED_WITH_NON_ACCOUNT_HOLDING_BROKER_DEALER_CHECKBOX = `custom:regulatory-associated-with-non-account-holding-broker-dealer`,
  REGULATORY_ASSOCIATED_WITH_ACCOUNT_HOLDING_BROKER_DEALER_CHECKBOX = `custom:regulatory-associated-with-account-holding-broker-dealer`,
  REGULATORY_ASSOCIATED_WITH_REGISTERED_INVESTMENT_ADVISOR_CHECKBOX = `custom:regulatory-associated-with-registered-investment-advisor`,
  RESIDENT_ALIEN_TAX_STATUS = 'data_point:resident_alien_tax_status:single_option',
  RESIDENT_TYPE = 'data_point:resident_type:single_option',
  REVOCABLE_PRIMARY_TRUSTEE = 'label:revocable_primary_trustee:string',
  REVOKER_FIRST_NAME = 'data_point:revoker_first_name:string',
  REVOKER_LAST_NAME = 'data_point:revoker_last_name:string',
  REVOKER_MIDDLE_NAME = 'data_point:revoker_middle_name:string',
  SAME_HOME_MAILING_ADDRESS = 'data_point:same_home_mailing_address:boolean',
  SECTION_JUSTIFICATION = 'info:section_justification:string',
  SSN = 'data_point:ssn:ssn',
  STATE_LAW_GOVERNING_TRUST = 'data_point:state_of_trust:single_option',
  TAX_BRACKET = 'data_point:tax_bracket:single_option',
  TAX_REPORTING_NUMBER = 'data_point:tax_reporting_number:tin',
  TAX_REPORTING_TYPE = 'data_point:tax_reporting_type:single_option',
  TENANTS_IN_COMMON_PERCENTAGE = 'data_point:tenants_in_common_percentage:percentage',
  TENANTS_IN_COMMON_PERCENTAGE_CO_APPLICANT = 'data_point:tenants_in_common_percentage:percentage',
  TIN = 'data_point:tin:tin',
  TLH = 'custom:tlh:tlh',
  TLH_LABEL = 'label:tlh_label:string',
  TLH_TEXT = 'label:tlh_text:string',
  TRUSTED_CONTACT = 'custom:trusted_contact:contacts',
  TRUSTED_CONTACT_CITY = 'data_point:trusted_contact-city',
  TRUSTED_CONTACT_COUNT = 'data_point:trusted_contact-count',
  TRUSTED_CONTACT_EMAIL = 'data_point:trusted_contact-email',
  TRUSTED_CONTACT_FIRST_NAME = 'data_point:trusted_contact-first-name',
  TRUSTED_CONTACT_LAST_NAME = 'data_point:trusted_contact-last-name',
  TRUSTED_CONTACT_MIDDLE_NAME = 'data_point:trusted_contact-middle-name',
  TRUSTED_CONTACT_PHONE_NUMBER = 'data_point:trusted_contact-phone-number',
  TRUSTED_CONTACT_RELATIONSHIP = 'data_point:trusted_contact-relationship',
  TRUSTED_CONTACT_STATE = 'data_point:trusted_contact-state',
  TRUSTED_CONTACT_STREET_ADDRESS = 'data_point:trusted_contact-street-address',
  TRUSTED_CONTACT_STREET_LINE = 'data_point:trusted_contact-street-line',
  TRUSTED_CONTACT_ZIP_CODE = 'data_point:trusted_contact-zip-code',
  TRUST_DATE = 'data_point:trust_date:date',
  TRUST_NAME = 'data_point:trust_name:string',
  TRUST_SSN = 'data_point:trust_ssn:ssn',
  TRUST_TYPE = 'data_point:type_of_trust:single_option',
  US_TAX_STATUS = 'data_point:us_tax_status:single_option',
  WEALTH_EXPENSES_TEXT = 'label:wealth_expenses_text:string',
}

export enum QuestionSetKeys {
  CITIZENSHIP_FIELDS = 'question_set:citizenship',
  COMPANY_ADDRESS_FIELDS = 'question_set:company_address_fields',
  HOME_ADDRESS_FIELDS = 'question_set:home_address_fields',
  MAILING_ADDRESS_FIELDS = 'question_set:mailing_address_fields',
}

export interface QuestionProperties {
  // NOTE: currently not handled in getPaperworkData()
  // only used in predefined question mappers
  additionalValidations: RegisterOptions;
  adjacent?: string;
  allowedOptions?: string[];
  defaultValue?: string;
  disabled?: boolean;
  disabledIfPrefilled?: boolean;
  paperworkFreeFormId?: string;
  required: boolean;
  rules: QuestionRule[];
}
