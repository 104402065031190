import { useMemo } from 'react';

import { ManagedProductType } from '~/__generated__';
import { useGetManagedProductInfoForRetake } from '~/components/RetakeRTQWrapper/symphony';
import { AsyncResult } from '~/utils/types';

interface Props {
  managedProductId: string;
}

interface Data {
  data: {
    brokerageAlias: string;
    managedProductType: ManagedProductType;
  };
}

export const useGetManagedProductInfo = ({ managedProductId }: Props): AsyncResult<Data> => {
  const { data: managedProductData, error, loading } = useGetManagedProductInfoForRetake({
    variables: { managedProductId },
  });

  const data = useMemo(() => {
    if (managedProductData?.managedProduct) {
      return {
        data: {
          brokerageAlias: managedProductData.managedProduct.brokerageAlias ?? '',
          managedProductType: managedProductData.managedProduct.program,
        },
      };
    }

    return;
  }, [managedProductData]);

  return {
    data,
    error,
    loading,
  };
};
