import { DataPointKeys } from '../../../configs/questions/types';
import {
  getPaperworkBooleanFieldValue,
  getPaperworkDateFieldValueAsIsoString,
  getPaperworkEnumFieldValue,
  getPaperworkStringFieldValue,
  isMasked,
} from '../../../configs/questions/utils';
import {
  ManagedProductPaperworkWithFreeFormId,
  PaperworkInputWithFreeFormId,
} from '../../../hooks/useGetPaperworkData';
import { FormData } from '../../../types';
import { getFieldName } from '../../utils';

import {
  IdentifierType,
  PartyIdentifierInput,
  RelationshipInput,
  RelationshipName,
  RelationshipSubtype,
} from '~/__generated__';
import { randomIntegerWithRange } from '~/utils/math';

export const getNameFieldName = (
  isPrimaryBeneficiary: boolean,
  beneficiaryUniqueId: string,
  paperworkFreeFormId?: string,
): string => {
  return getFieldName(
    (isPrimaryBeneficiary ? DataPointKeys.PRIMARY_BENEFICIARY_NAME : DataPointKeys.CONTINGENT_BENEFICIARY_NAME) +
      `:${beneficiaryUniqueId}`,
    paperworkFreeFormId,
  );
};
export const getFirstNameFieldName = (
  isPrimaryBeneficiary: boolean,
  beneficiaryUniqueId: string,
  paperworkFreeFormId?: string,
): string => {
  return getFieldName(
    (isPrimaryBeneficiary
      ? DataPointKeys.PRIMARY_BENEFICIARY_FIRST_NAME
      : DataPointKeys.CONTINGENT_BENEFICIARY_FIRST_NAME) + `:${beneficiaryUniqueId}`,
    paperworkFreeFormId,
  );
};
export const getLastNameFieldName = (
  isPrimaryBeneficiary: boolean,
  beneficiaryUniqueId: string,
  paperworkFreeFormId?: string,
): string => {
  return getFieldName(
    (isPrimaryBeneficiary
      ? DataPointKeys.PRIMARY_BENEFICIARY_LAST_NAME
      : DataPointKeys.CONTINGENT_BENEFICIARY_LAST_NAME) + `:${beneficiaryUniqueId}`,
    paperworkFreeFormId,
  );
};
export const getUlidFieldName = (
  isPrimaryBeneficiary: boolean,
  beneficiaryUniqueId: string,
  paperworkFreeFormId?: string,
): string => {
  return getFieldName(
    (isPrimaryBeneficiary ? DataPointKeys.PRIMARY_BENEFICIARY_ULID : DataPointKeys.CONTINGENT_BENEFICIARY_ULID) +
      `:${beneficiaryUniqueId}`,
    paperworkFreeFormId,
  );
};
export const getDobFieldName = (
  isPrimaryBeneficiary: boolean,
  beneficiaryUniqueId: string,
  paperworkFreeFormId?: string,
): string => {
  return getFieldName(
    (isPrimaryBeneficiary ? DataPointKeys.PRIMARY_BENEFICIARY_DOB : DataPointKeys.CONTINGENT_BENEFICIARY_DOB) +
      `:${beneficiaryUniqueId}`,
    paperworkFreeFormId,
  );
};
export const getTypeFieldName = (
  isPrimaryBeneficiary: boolean,
  beneficiaryUniqueId: string,
  paperworkFreeFormId?: string,
): string => {
  return getFieldName(
    (isPrimaryBeneficiary ? DataPointKeys.PRIMARY_BENEFICIARY_TYPE : DataPointKeys.CONTINGENT_BENEFICIARY_TYPE) +
      `:${beneficiaryUniqueId}`,
    paperworkFreeFormId,
  );
};
export const getPercentageFieldName = (
  isPrimaryBeneficiary: boolean,
  beneficiaryUniqueId: string,
  paperworkFreeFormId?: string,
): string => {
  return getFieldName(
    (isPrimaryBeneficiary
      ? DataPointKeys.PRIMARY_BENEFICIARY_PERCENTAGE
      : DataPointKeys.CONTINGENT_BENEFICIARY_PERCENTAGE) + `:${beneficiaryUniqueId}`,
    paperworkFreeFormId,
  );
};
export const getSsnFieldName = (
  isPrimaryBeneficiary: boolean,
  beneficiaryUniqueId: string,
  paperworkFreeFormId?: string,
): string => {
  return getFieldName(
    (isPrimaryBeneficiary ? DataPointKeys.PRIMARY_BENEFICIARY_SSN : DataPointKeys.CONTINGENT_BENEFICIARY_SSN) +
      `:${beneficiaryUniqueId}`,
    paperworkFreeFormId,
  );
};
export const getPerStirpesFieldName = (
  isPrimaryBeneficiary: boolean,
  beneficiaryUniqueId: string,
  paperworkFreeFormId?: string,
): string => {
  return getFieldName(
    (isPrimaryBeneficiary
      ? DataPointKeys.PRIMARY_BENEFICIARY_PER_STIRPES
      : DataPointKeys.CONTINGENT_BENEFICIARY_PER_STIRPES) + `:${beneficiaryUniqueId}`,
    paperworkFreeFormId,
  );
};
export const getNameOfTrusteeFieldName = (
  isPrimaryBeneficiary: boolean,
  beneficiaryUniqueId: string,
  paperworkFreeFormId?: string,
): string => {
  return getFieldName(
    (isPrimaryBeneficiary
      ? DataPointKeys.PRIMARY_BENEFICIARY_NAME_OF_TRUSTEE
      : DataPointKeys.CONTINGENT_BENEFICIARY_NAME_OF_TRUSTEE) + `:${beneficiaryUniqueId}`,
    paperworkFreeFormId,
  );
};
export const getOrganizationCountryFieldName = (
  isPrimaryBeneficiary: boolean,
  beneficiaryUniqueId: string,
  paperworkFreeFormId?: string,
): string => {
  return getFieldName(
    (isPrimaryBeneficiary
      ? DataPointKeys.PRIMARY_BENEFICIARY_ORGANIZATION_COUNTRY
      : DataPointKeys.CONTINGENT_BENEFICIARY_ORGANIZATION_COUNTRY) + `:${beneficiaryUniqueId}`,
    paperworkFreeFormId,
  );
};
export const getTaxReportingNumberFieldName = (
  isPrimaryBeneficiary: boolean,
  beneficiaryUniqueId: string,
  paperworkFreeFormId?: string,
): string => {
  return getFieldName(
    (isPrimaryBeneficiary
      ? DataPointKeys.PRIMARY_BENEFICIARY_TAX_REPORTING_NUMBER
      : DataPointKeys.CONTINGENT_BENEFICIARY_TAX_REPORTING_NUMBER) + `:${beneficiaryUniqueId}`,
    paperworkFreeFormId,
  );
};
export const getTaxReportingTypeFieldName = (
  isPrimaryBeneficiary: boolean,
  beneficiaryUniqueId: string,
  paperworkFreeFormId?: string,
): string => {
  return getFieldName(
    (isPrimaryBeneficiary
      ? DataPointKeys.PRIMARY_BENEFICIARY_TAX_REPORTING_NUMBER_TYPE
      : DataPointKeys.CONTINGENT_BENEFICIARY_TAX_REPORTING_NUMBER_TYPE) + `:${beneficiaryUniqueId}`,
    paperworkFreeFormId,
  );
};
export const getUniqueIdsFieldName = (isPrimaryBeneficiary: boolean, paperworkFreeFormId?: string): string => {
  return getFieldName(
    isPrimaryBeneficiary
      ? DataPointKeys.PRIMARY_BENEFICIARY_UNIQUE_IDS
      : DataPointKeys.CONTINGENT_BENEFICIARY_UNIQUE_IDS,
    paperworkFreeFormId,
  );
};

export const isTrustOrCharity = (selectedType: RelationshipSubtype) =>
  selectedType === RelationshipSubtype.TRUST || selectedType === RelationshipSubtype.CHARITY;

export const getTempBeneficiaryId = () => {
  return `temp-beneficiary-id-${randomIntegerWithRange(100, 2000, 2).toString()}`;
};

export const populateBeneficiaries = (
  isPrimaryBeneficiary: boolean,
  paperworkInput: PaperworkInputWithFreeFormId,
  data: {
    allSavedPaperworkData: ManagedProductPaperworkWithFreeFormId[];
    dependentFormFieldsInvisibility: Record<string, boolean>;
    formValues: FormData;
    savedPaperworkData?: ManagedProductPaperworkWithFreeFormId;
    unmaskedValues: Record<string, string>;
  },
  paperworkFreeFormId: string | undefined,
) => {
  const relationshipName = isPrimaryBeneficiary ? RelationshipName.BENEFICIARY_PRIMARY : RelationshipName.BENEFICIARY;
  if (paperworkInput.relationships) {
    paperworkInput.relationships = paperworkInput.relationships.filter(
      relationship => relationship.name !== relationshipName,
    );
  }

  const beneficiaryUniqueIdsFieldName = getUniqueIdsFieldName(isPrimaryBeneficiary, paperworkFreeFormId);
  const allBeneficiaryUniqueIds =
    getPaperworkStringFieldValue(data.formValues[beneficiaryUniqueIdsFieldName])?.split(',') ?? [];
  const beneficiaryCount = allBeneficiaryUniqueIds.length;
  if (beneficiaryCount) {
    allBeneficiaryUniqueIds.forEach(beneficiaryUniqueId => {
      const beneficiaryName = getPaperworkStringFieldValue(
        data.formValues[getNameFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)],
      );
      const beneficiaryFirstName = getPaperworkStringFieldValue(
        data.formValues[getFirstNameFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)],
      );
      const beneficiaryLastName = getPaperworkStringFieldValue(
        data.formValues[getLastNameFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)],
      );
      const beneficiaryPercentage = getPaperworkStringFieldValue(
        data.formValues[getPercentageFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)],
      );
      const beneficiaryTaxReportingType = getPaperworkEnumFieldValue(
        IdentifierType,
        data.formValues[getTaxReportingTypeFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)],
      );
      const beneficiaryTaxReportingValue = getPaperworkStringFieldValue(
        data.formValues[getTaxReportingNumberFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)],
      );
      const beneficiaryType =
        getPaperworkEnumFieldValue(
          RelationshipSubtype,
          data.formValues[getTypeFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)],
        ) ?? RelationshipSubtype.OTHER;
      const beneficiaryCountry = getPaperworkStringFieldValue(
        data.formValues[
          getOrganizationCountryFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)
        ],
      );
      const beneficiaryNameOfTrustee = getPaperworkStringFieldValue(
        data.formValues[getNameOfTrusteeFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)],
      );
      const beneficiarySsnValue = getPaperworkStringFieldValue(
        data.formValues[getSsnFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)],
      );
      const beneficiaryIsPerStirpes = getPaperworkBooleanFieldValue(
        data.formValues[getPerStirpesFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)],
        false,
      );
      const beneficiaryBirthData = getPaperworkDateFieldValueAsIsoString(
        data.formValues[getDobFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)],
      );

      const getPartyIdentifier = (): PartyIdentifierInput[] => {
        return [IdentifierType.SSN, IdentifierType.TIN].map(identifier => {
          const isSelectedTaxReportingType =
            isTrustOrCharity(beneficiaryType) && beneficiaryTaxReportingType
              ? identifier === beneficiaryTaxReportingType
              : identifier === IdentifierType.SSN;
          const selectedTaxReportingTypeValue =
            identifier === IdentifierType.SSN ? beneficiarySsnValue : beneficiaryTaxReportingValue;
          return {
            type: identifier,
            identifierValue:
              isSelectedTaxReportingType &&
              selectedTaxReportingTypeValue?.length &&
              !isMasked(selectedTaxReportingTypeValue)
                ? selectedTaxReportingTypeValue
                : null,
            setValueToNull: isSelectedTaxReportingType && !selectedTaxReportingTypeValue?.length,
          };
        });
      };

      if ((beneficiaryName || beneficiaryFirstName) && beneficiaryPercentage) {
        const beneficiary: RelationshipInput = {
          id:
            !beneficiaryUniqueId.includes('temp-beneficiary-id-') &&
            data.formValues[getUlidFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)]
              ? data.formValues[getUlidFieldName(isPrimaryBeneficiary, beneficiaryUniqueId, paperworkFreeFormId)]
              : null,
          name: relationshipName,
          isPerStirpes: beneficiaryIsPerStirpes,
          nameOfTrustees: beneficiaryNameOfTrustee,
          party: {
            partyPerson: {
              givenName: beneficiaryFirstName ?? beneficiaryName,
              familyName: beneficiaryLastName,
              birthDate: beneficiaryBirthData,
              citizenship: beneficiaryCountry,
            },
            ...(isTrustOrCharity(beneficiaryType) && {
              partyBusinessEntity: {
                ...(beneficiaryType === RelationshipSubtype.TRUST && {
                  attributes: {
                    trustDate: beneficiaryBirthData,
                  },
                }),
                country: beneficiaryCountry,
                name: beneficiaryName,
              },
            }),
            identifiers: getPartyIdentifier(),
          },
          percentage: beneficiaryPercentage,
          relationshipType: beneficiaryType,
        };

        // Remove the keys if null or undefined so that it doesn't get passed to symphony
        if (!beneficiaryBirthData && beneficiary.party.partyPerson?.birthDate) {
          delete beneficiary.party.partyPerson.birthDate;
        }

        // Don't send partyPerson details if Trust / Charity type
        if (isTrustOrCharity(beneficiaryType)) {
          delete beneficiary.party.partyPerson;
        }

        paperworkInput.relationships = [...(paperworkInput.relationships ?? []), beneficiary];
      }
    });
  }
};
