import React, { FC } from 'react';
import { Controller, FieldError, RegisterOptions, UseFormMethods } from 'react-hook-form';

import { FormData } from '../../types';
import { QuestionContent, QuestionOrderSteps } from '../types';

import { Checkbox, FormControl, FormControlLabel } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';

interface Props {
  defaultValue: any;
  fieldName: string;
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  inputErrors?: FieldError;
  isFieldDisabled: boolean;
  onCheckboxAnswerChange: (fieldName: string, answer: boolean) => void;
  questionContent: QuestionContent;
  questionInRow: QuestionOrderSteps;
  validationRules: RegisterOptions;
}

export const CheckboxQuestion: FC<Props> = ({
  defaultValue,
  validationRules,
  fieldName,
  formHooks,
  onCheckboxAnswerChange,
  questionInRow,
  questionContent,
  isFieldDisabled,
  inputErrors,
}) => {
  const { control } = formHooks;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      key={`question-${fieldName}`}
      name={fieldName}
      render={({ onChange, value }) => (
        <FormControl
          data-qa={`question-checkbox-${questionInRow.questionKey}`}
          disabled={isFieldDisabled}
          error={!!inputErrors}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                id={fieldName}
                onChange={e => {
                  onCheckboxAnswerChange(fieldName, e.target.checked);
                  onChange(e.target.checked);
                }}
              />
            }
            label={<RteContent data={questionContent.question} />}
          />
        </FormControl>
      )}
      rules={validationRules}
    />
  );
};
