import {
  GetPendingRiskScore,
  GetPendingRiskScoreVariables,
  GetRecommendedModelPortfolio,
  GetRecommendedModelPortfolioVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export const useGetPendingRiskScore = (
  options?: QueryHookOptions<GetPendingRiskScore, GetPendingRiskScoreVariables>,
): QueryResult<GetPendingRiskScore, GetPendingRiskScoreVariables> => {
  return useSymphonyQuery(queries.GetPendingRiskScore, options);
};

export const useGetRecommendedModelPortfolio = (
  options?: QueryHookOptions<GetRecommendedModelPortfolio, GetRecommendedModelPortfolioVariables>,
): QueryResult<GetRecommendedModelPortfolio, GetRecommendedModelPortfolioVariables> => {
  return useSymphonyQuery(queries.GetRecommendedModelPortfolio, options);
};
