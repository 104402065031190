import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { FormData } from '../../types';
import { QuestionOrder, QuestionOrderSteps, ValidationMessage } from '../types';
import { getFieldName } from '../utils';

import {
  getDependentFormFieldsInvisibilityRecursive,
  getDependentQuestions,
  getSectionValidationMessages,
} from './utils';

import { useModalState } from '~/components/ui/Modal/hooks';
import { CMSQuestions } from '~/containers/Paperwork/contentstack';

interface Variables {
  content: {
    questions: CMSQuestions;
    validationMessages?: ValidationMessage[];
  };
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  harvestLosses?: boolean;
  onFormFieldsVisibilityChange: (newInvisibility: Record<string, boolean>) => void;
  order: QuestionOrder;
}

interface Data {
  closeTlhModal: () => void;
  fetchingQuestions: boolean;
  isTlhModalOpen: boolean;
  onCheckboxAnswerChange: (fieldName: string, answer: boolean) => void;
  onDateAnswerChange: (fieldName: string, answer: string) => void;
  onDropdownAnswerChange: (fieldName: string, answer: any) => void;
  onQuestionShow: (fieldName: string, prefillValue: any) => void;
  onRadioAnswerChange: (fieldName: string, answer: string) => void;
  openTlhModal: () => void;
  questions: QuestionOrderSteps[];
  validationMessages: ValidationMessage[];
}

export const usePaperworkSectionManager = ({
  content,
  formHooks,
  onFormFieldsVisibilityChange,
  order,
}: Variables): Data => {
  const [questions, setQuestions] = useState<QuestionOrder['orderSteps']>([]);
  const [fetchingQuestions, setFetchingQuestions] = useState(true);

  const { open: isTlhModalOpen, openModal: openTlhModal, onClose: closeTlhModal } = useModalState();

  const { getValues } = formHooks;

  useEffect(() => {
    if (questions.length === 0) {
      const firstQuestion = order.orderSteps.find(s => s.questionKey === order.start);
      if (firstQuestion) {
        setQuestions(arr => [...arr, firstQuestion]);
      } else {
        setFetchingQuestions(false);
        throw new Error(`Missing paperwork question for key: ${order.start}`);
      }
    }
  }, [order, order.orderSteps, order.start, questions]);

  const currentLastQuestion = useMemo(() => (questions.length > 0 ? questions[questions.length - 1] : undefined), [
    questions,
  ]);

  useEffect(() => {
    if (currentLastQuestion) {
      const dependentQuestions = getDependentQuestions(order, currentLastQuestion);
      const initialDependentQuestionsInvisibility = dependentQuestions.reduce((acc: Record<string, boolean>, q) => {
        acc[getFieldName(q.dataPointKey, q.paperworkFreeFormId)] = true;
        return acc;
      }, {});

      const nextQuestionKey = currentLastQuestion.rules.find(r => r.conditions === undefined)?.next;
      const nextQuestion = order.orderSteps.find(s => s.questionKey === nextQuestionKey);

      onFormFieldsVisibilityChange(initialDependentQuestionsInvisibility);
      if (nextQuestion) {
        setQuestions(arr => [...arr, ...dependentQuestions, nextQuestion]);
      } else if (dependentQuestions.length > 0) {
        setQuestions(arr => [...arr, ...dependentQuestions]);
      } else {
        setFetchingQuestions(false);
        if (nextQuestionKey !== null && nextQuestionKey !== undefined) {
          throw new Error(`Missing paperwork question for key: ${nextQuestionKey}`);
        }
      }
    }
  }, [currentLastQuestion, onFormFieldsVisibilityChange, order]);

  const validationMessages = useMemo(() => {
    return [...getSectionValidationMessages(content.questions), ...(content.validationMessages ?? [])];
  }, [content.questions, content.validationMessages]);

  const updateFormFieldVisibility = useCallback(
    (currentFieldName: string, currentFormValue: any, isCurrentFieldHidden = false) => {
      const newDependentFormFieldInvisibility = getDependentFormFieldsInvisibilityRecursive({
        currentFieldName,
        currentFormValue,
        formValues: getValues(),
        isCurrentFieldHidden,
        questions,
      });
      // Update only when there are changes in dependent data point visibility to prevent unnecessary re-rendering.
      if (Object.keys(newDependentFormFieldInvisibility).length) {
        onFormFieldsVisibilityChange(newDependentFormFieldInvisibility);
      }
    },
    [getValues, onFormFieldsVisibilityChange, questions],
  );

  const onCheckboxAnswerChange = useCallback(
    (fieldName: string, answer: boolean) => {
      updateFormFieldVisibility(fieldName, answer, false);
    },
    [updateFormFieldVisibility],
  );

  const onDateAnswerChange = useCallback(
    (fieldName: string, answer: string) => {
      updateFormFieldVisibility(fieldName, answer, false);
    },
    [updateFormFieldVisibility],
  );

  const onDropdownAnswerChange = useCallback(
    (fieldName: string, answer: any) => {
      updateFormFieldVisibility(fieldName, answer.toString(), false);
    },
    [updateFormFieldVisibility],
  );

  const onQuestionShow = useCallback(
    (fieldName: string, prefillValue: any) => {
      updateFormFieldVisibility(fieldName, prefillValue, false);
    },
    [updateFormFieldVisibility],
  );

  const onRadioAnswerChange = useCallback(
    (fieldName: string, answer: string) => {
      updateFormFieldVisibility(fieldName, answer, false);
    },
    [updateFormFieldVisibility],
  );

  return {
    fetchingQuestions,
    questions,
    onCheckboxAnswerChange,
    onDateAnswerChange,
    onDropdownAnswerChange,
    onQuestionShow,
    onRadioAnswerChange,
    isTlhModalOpen,
    openTlhModal,
    closeTlhModal,
    validationMessages,
  };
};
