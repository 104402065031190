import { useCallback, useMemo, useRef, useState } from 'react';

import {
  GetAdvisorMetricValues,
  GetAdvisorMetricValues_advisorMetricValues_advisorMetricValues as AdvisorMetricValues,
  GetAdvisorMetricValuesVariables,
} from '../symphony/__generated__/query.v2';
import { HomeOfficeContent, HomeOfficeFilterValues, HomeOfficeTimeFrameValues } from '../types';

import { getAdvisorMetricValuesVariables, getHomeOfficeTableData, getTotalPages, mapDataToCsv } from './utils';

import { SortConfig } from '~/components/ui/BasicTable';
import { HomeOfficeView } from '~/containers/HomeOfficeDashboard/config';
import { useLazyGetAdvisorMetricValues } from '~/containers/HomeOfficeDashboard/symphony';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { downloadFile } from '~/utils/download';
import { toSymphonyDate } from '~/utils/symphony';

interface Props {
  content: HomeOfficeContent;
  contentOptions: ContentOptions;
  filterValues: HomeOfficeFilterValues;
  selectedParty?: string | null;
  sortConfig: SortConfig;
  timeFrameValues: HomeOfficeTimeFrameValues;
  totalData: number;
  view: HomeOfficeView;
}

export interface Data {
  isDownloading: boolean;
  onDownloadCSV: (params: Props) => void;
}
const MAX_DATA_PER_REQUEST = 500;
export const useDownloadFinancialAdvisorData = (): Data => {
  const {
    components: {
      sfHomeOffice: { dateView },
    },
  } = useCoreConfig();

  const totalPages = useRef(0);
  const view = useRef(dateView);
  const content = useRef<HomeOfficeContent>();
  const metricsVariables = useRef<GetAdvisorMetricValuesVariables | undefined>();

  const [currentPage, setCurrentPage] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false);
  const [accumulatedCsvData, setAccumulatedCsvData] = useState<AdvisorMetricValues[]>([]);

  const processCSVData = useCallback((advisorMetricData: AdvisorMetricValues[]) => {
    if (content.current) {
      const keys = view.current.columns.map(col => col.key);
      downloadFile(
        mapDataToCsv(
          getHomeOfficeTableData(
            advisorMetricData.filter(el => !!el.party),
            true,
          ),
          keys,
          content.current,
        ),
        'homeAdvisorOfficeMetrics.csv',
        'text/csv',
      );

      setCurrentPage(1);
      setIsDownloading(false);
      setAccumulatedCsvData([]);
    }
  }, []);

  const [getAdvisorMetricValues] = useLazyGetAdvisorMetricValues({
    onCompleted: (data: GetAdvisorMetricValues) => fetchAllData(data),
    errorPolicy: 'ignore', // todo: Change the error policy to 'all' and handle the errors DWCORE-8739
    // fetchPolicy is added for to retrieve fresh data from the server and to Allow api calls made with same variables
    fetchPolicy: 'no-cache',
  });

  const fetchAllData = useCallback(
    (advisorMetricValuesData?: GetAdvisorMetricValues | null) => {
      let allData = accumulatedCsvData;
      if (advisorMetricValuesData) {
        allData = [...accumulatedCsvData, ...advisorMetricValuesData.advisorMetricValues.advisorMetricValues];
      }

      setAccumulatedCsvData(allData);

      if (totalPages.current > currentPage && metricsVariables.current) {
        getAdvisorMetricValues({
          variables: {
            ...metricsVariables.current,
            pagination: {
              limit: MAX_DATA_PER_REQUEST,
              offset: currentPage * MAX_DATA_PER_REQUEST,
            },
          },
        });

        setCurrentPage(prevState => prevState + 1);
      } else {
        processCSVData(allData);
      }
    },
    [accumulatedCsvData, totalPages, currentPage, metricsVariables, getAdvisorMetricValues, processCSVData],
  );

  const onDownloadCSV = useCallback(
    ({
      content: homeOfficeContent,
      contentOptions,
      selectedParty: homeOfficeSelectedPartyId,
      sortConfig: homeOfficeSortConfig,
      filterValues: homeOfficeFilterValues,
      timeFrameValues: homeOfficeTimeFrameValues,
      view: homeOfficeView,
      totalData: totalDataCount,
    }: Props) => {
      setIsDownloading(true);
      totalPages.current = getTotalPages({ limit: MAX_DATA_PER_REQUEST, total: totalDataCount });
      view.current = homeOfficeView;
      content.current = homeOfficeContent;
      metricsVariables.current = {
        startDate: toSymphonyDate(homeOfficeTimeFrameValues.startDate, contentOptions),
        endDate: toSymphonyDate(homeOfficeTimeFrameValues.endDate, contentOptions),
        ...getAdvisorMetricValuesVariables({
          selectedParty: homeOfficeSelectedPartyId ?? '',
          filterValues: homeOfficeFilterValues,
          view: homeOfficeView,
          sortConfig: homeOfficeSortConfig,
        }),
        pagination: {
          limit: MAX_DATA_PER_REQUEST,
          offset: 0,
        },
      };

      getAdvisorMetricValues({ variables: metricsVariables.current });
    },
    [getAdvisorMetricValues],
  );

  return useMemo(() => {
    return { onDownloadCSV, isDownloading };
  }, [isDownloading, onDownloadCSV]);
};
