import { getPaperworkBooleanFieldValue } from './helper';

import { WealthInput } from '~/__generated__';

export const getUpdatedWealthInformation = (
  objKey: string,
  key: string,
  wealthInformation: WealthInput,
  result: Record<string, any>,
) => {
  switch (objKey) {
    case 'numberOfDependents':
      wealthInformation.numberOfDependents = parseInt(result[key], 10);
      break;
    default:
      wealthInformation[objKey as keyof WealthInput] = key.split(':').includes('boolean')
        ? getPaperworkBooleanFieldValue(result, key)
        : result[key];
      break;
  }
  return wealthInformation;
};

export const getDefaultValueForWealthKey = (wealthInformation: WealthInput, wealthMapping: string) => {
  const wealthInformationKey = wealthMapping as keyof WealthInput;
  return wealthInformation?.[wealthInformationKey] ?? '';
};
