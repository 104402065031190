import { ManagedProductType, OnboardingStates, Scalars as SymphonyScalars } from '~/__generated__';
import { AssetAllocationModalProps } from '~/components/modals/AssetAllocation';
import { Content as InvestmentAmountModalContent } from '~/components/PortfolioSelection/InvestmentAmountModal';
import { Content as InvestmentAmountWarningModalContent } from '~/components/PortfolioSelection/InvestmentAmountWarningModal';
import {
  PortfolioSelectionCardHeader,
  PortfolioSelectionCardLabels,
} from '~/components/PortfolioSelection/PortfolioSelectionCard';
import { KeepCurrentPortfolioContent } from '~/components/PortfolioSelection/PortfolioSelectionHeader/KeepCurrentPortfolio';
import { InvestmentAmountContent } from '~/components/PortfolioSelection/PortfolioSelectionOptions';
import { RetakeRTQStates } from '~/components/RetakeRTQWrapper';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Diversification } from '~/containers/Plan/types';
import { LatestQuestionnaireRecommendationSet } from '~/hooks/questionnaire/symphony';
import { AssetAllocation, AssetClassTier } from '~/utils/asset-allocation/types';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface Tag {
  name: string;
  value: boolean;
}

export interface Provider {
  id: number;
  name: string;
}

export interface Portfolio {
  accountMinimumBalanceForAccountOpening?: string;
  accountMinimumBalanceForRebalance?: string;
  assetAllocations: AssetAllocation[];
  description: string;
  factSheetLink: string;
  internalId: number;
  // See https://sigfig.atlassian.net/browse/DA2-5979?focusedCommentId=383983, null means a Portfolio is suitable for Retirement & Non-retirement uses
  isTaxSheltered?: boolean | null;
  minimumBalanceForAccountOpening?: string;
  minimumBalanceForRebalance?: string;
  percentage?: string;
  productName: string;
  provider?: Provider;
  riskRange?: {
    max: number;
    min: number;
  };
  seriesBaseName?: string;
  seriesId: number;
  stockBondDiversification: Diversification;
  strategyBrochureUrl?: string;
  tags?: Tag[];
}

export interface FilterItem {
  disabled?: boolean;
  label: string;
  value: string;
}

export interface FilterGroup {
  attribute: keyof Portfolio;
  items: FilterItem[];
  label: string;
}

export type PortfolioSelectionFilters = {
  [k in keyof Portfolio]?: string[];
};

export enum StrategyMode {
  MULTI_SLEEVE = 'MULTI_SLEEVE',
  SINGLE = 'SINGLE',
}

export type AssetAllocationModalState = Pick<
  AssetAllocationModalProps,
  'content' | 'error' | 'loading' | 'donutProps' | 'portfolio'
>;

export interface PortfolioSelectionContent {
  back: string;
  cardHeader: PortfolioSelectionCardHeader;
  cardLabels: PortfolioSelectionCardLabels;
  header: {
    headerIcon: string;
    investmentStrategyLabel?: string;
    riskToleranceEditLinkText?: string;
    riskToleranceLabel?: string;
    title: string;
  };
  higherMinimumWarningMessage?: string;
  investmentAmount?: InvestmentAmountContent;
  investmentAmountModal?: InvestmentAmountModalContent;
  investmentAmountWarningModal?: InvestmentAmountWarningModalContent;
  keepCurrentPortfolio: KeepCurrentPortfolioContent;
  minimumInvestmentAmountInfoText: string;
  nullStateMessage: string;
  strategyModeOptions?: DropdownItem[];
  strategyUnavailableText: string;
}
export interface PortfolioSelectionData {
  calculatedRecommendations?: LatestQuestionnaireRecommendationSet;
  content: PortfolioSelectionContent;
  initialFundingAmountFromQuestionnaire?: number;
  isTaxShelteredAccountFromQuestionnaire?: boolean;
  modelPortfolios: Portfolio[];
  portfolioFiltersProps: {
    portfolioFilters: FilterGroup[];
    portfolioFiltersHeader: string;
  };
  riskScoreUser?: number | null;
  selectModelPortfolioSleeves?: (modelPortfolioSleeves: ModelPortfolioSleeve[]) => void;
}
export interface ModelPortfolioSleeve {
  modelPortfolioInternalId: number;
  modelPortfolioSeriesId: number;
  percentage?: SymphonyScalars['Decimal'] | null;
}
export interface PortfolioSelectionProps {
  actorPartyId: string;
  assetClassTier?: AssetClassTier;
  brokerageAlias: string;
  contentOptions: ContentOptions;
  dataQa?: string;
  deliveryChannel?: string;
  initiatingAdvisorPartyId?: string;
  isCopilot?: boolean;
  isRce?: boolean;
  managedProductId: string;
  onBack: (newState?: OnboardingStates | RetakeRTQStates) => void;
  onKeepCurrentPortfolio?: (showFeedbackMessage?: boolean) => void;
  onNext: () => void;
  onRetakeQuestionnaire?: () => Promise<void>;
  partyId: string;
  portfolioSelectionData?: AsyncResult<PortfolioSelectionData>;
  productType: ManagedProductType;
}
