import { Text, View } from '@react-pdf/renderer';
import React from 'react';

import { Divider } from '../../../components/Divider';
import { Donut } from '../../../components/Donut';
import { Style } from '../../../styles';

import { StagedModelPortfolioData } from '~/containers/Plan/types';
import { ContentOptions } from '~/utils/contentstack';
import { formatCurrency, formatPercentageString } from '~/utils/format';

export interface InvestmentRecommendationContent {
  blendLabel: string;
  compositePortfolioName: string;
  descriptionLabel: string;
  minimumInvestmentLabel: string;
  percentageLabel: string;
  subtitle: string;
  title: string;
}

interface Props {
  content: InvestmentRecommendationContent;
  contentOptions: ContentOptions;
  modelPortfolioData: StagedModelPortfolioData;
  pdfStyles: Style;
  stockBondLabel: string;
}

export const InvestmentRecommendation: React.FC<Props> = ({
  content,
  contentOptions,
  modelPortfolioData,
  stockBondLabel,
  pdfStyles,
}) => {
  const commonTextStyle = {
    fontSize: 10,
    marginTop: '8px',
    marginBottom: '8px',
  };

  const commonRowStyle = {
    padding: '6 0',
    fontSize: 10,
    display: 'flex' as const,
    flexDirection: 'row' as const,
    justifyContent: 'space-between' as const,
  };

  const commonHeaderStyle = {
    ...commonTextStyle,
    color: pdfStyles.textSecondary.color,
  };

  const hasMultipleSleeves = modelPortfolioData.modelPortfolios.length > 1;

  return (
    <>
      <Text
        style={{
          color: pdfStyles.primaryMainColor.color,
          fontSize: 16,
          margin: '12px 0',
        }}
      >
        {content.title}
      </Text>
      <Text style={{ ...commonTextStyle, lineHeight: 1.5 }}>{content.subtitle}</Text>
      <View
        style={{
          ...pdfStyles.border,
          padding: '12px',
          margin: '12px 0',
          textAlign: 'justify',
        }}
        wrap={false}
      >
        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px 0' }}>
          <Donut
            centerSubText={stockBondLabel}
            centerText={modelPortfolioData.stockBondRatio}
            data={modelPortfolioData.portfolioAssetsBreakdown}
            fontSize={8}
            pdfStyles={pdfStyles}
            size={100}
          />
          <Text
            style={{
              ...commonTextStyle,
              color: pdfStyles.investmentRecommendationModelPortfolio.color,
              marginTop: '16px',
            }}
          >
            {hasMultipleSleeves ? content.compositePortfolioName : modelPortfolioData.modelPortfolios[0].name}
          </Text>
        </View>
        <Divider pdfStyles={pdfStyles} />
        {hasMultipleSleeves ? (
          <>
            <View>
              <View style={commonRowStyle}>
                <Text style={commonHeaderStyle}>{content.blendLabel}</Text>
                <Text style={commonHeaderStyle}>{content.percentageLabel}</Text>
              </View>
              {modelPortfolioData.modelPortfolios.map((mp, index) => (
                <View key={index} style={{ ...commonRowStyle, fontSize: 10 }}>
                  <Text>{mp.name}</Text>
                  <Text>
                    {mp.percentage ? formatPercentageString(mp.percentage, { locale: contentOptions.locale }) : ''}
                  </Text>
                </View>
              ))}
            </View>
            <View>
              <Text style={commonHeaderStyle}>{content.minimumInvestmentLabel}</Text>
              <Text style={{ fontSize: 10 }}>
                {modelPortfolioData.minimumBalanceForAccountOpening &&
                  formatCurrency(modelPortfolioData.minimumBalanceForAccountOpening, {
                    locale: contentOptions.locale,
                  })}
              </Text>
            </View>
          </>
        ) : (
          <>
            <Text style={{ marginTop: '12px', marginBottom: '8px', fontSize: 9 }}>{content.descriptionLabel}</Text>
            <Text style={{ fontSize: 8, lineHeight: 1.5 }}>{modelPortfolioData.modelPortfolios[0].description}</Text>
          </>
        )}
      </View>
      {hasMultipleSleeves &&
        modelPortfolioData.modelPortfolios.map((mp, index) => (
          <View
            key={index}
            style={{
              ...pdfStyles.border,
              padding: '12px',
              margin: '20px 0 30px 0px',
              textAlign: 'justify',
            }}
            wrap={false}
          >
            <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '16px 0' }}>
              <Donut
                centerSubText={stockBondLabel}
                centerText={mp.stockBondRatio}
                data={mp.portfolioAssetsBreakdown}
                fontSize={8}
                pdfStyles={pdfStyles}
              />
              <Text style={{ ...commonTextStyle, color: pdfStyles.primaryMainColor.color, marginTop: '16px' }}>
                {mp.name}
              </Text>
            </View>
            <Divider pdfStyles={pdfStyles} />
            <Text style={{ marginTop: '12px', marginBottom: '8px', fontSize: 9 }}>{content.descriptionLabel}</Text>
            <Text style={{ fontSize: 8, lineHeight: 1.5 }}>{mp.description}</Text>
          </View>
        ))}
    </>
  );
};
