import { SecurityReturns } from '~/components/AccountPerformance/types';
import { DailyChartValue } from '~/utils/types';

export interface ReturnsChartData {
  accountReturns: number;
  date: string;
  valMax: number;
  valMin: number;
}

export const mapSecuritiesReturnsAndComputeMaxMin = (
  securitiesData: { dailyChartMappedData: { [p: string]: number }; securityId: string }[],
  date: string,
  accountReturnsAmount: number,
  showSecurities: boolean,
): { [key: string]: number; valMax: number; valMin: number } => {
  return securitiesData.reduce<{
    [key: string]: number;
    valMax: number;
    valMin: number;
  }>(
    (acc, securityReturns) => {
      const securityPerformance = securityReturns.dailyChartMappedData[date];
      return {
        ...acc,
        ...{
          [securityReturns.securityId]: securityPerformance,
          valMax: showSecurities ? Math.max(acc.valMax, securityPerformance ?? -Infinity) : accountReturnsAmount,
          valMin: showSecurities ? Math.min(acc.valMin, securityPerformance ?? Infinity) : accountReturnsAmount,
        },
      };
    },
    { valMax: accountReturnsAmount, valMin: accountReturnsAmount },
  );
};

export const useGetReturnsChartData = (
  accountReturns: DailyChartValue[],
  securitiesReturns: SecurityReturns[],
  showSecurities: boolean,
): ReturnsChartData[] => {
  const validDates = accountReturns.map(p => p.date);

  const securitiesReturnsDataMappedByDate = securitiesReturns.map(securityReturns => {
    const mappedChartData = securityReturns.dailyChartData.reduce<{
      [key: string]: number;
    }>((acc, dailyChartData) => {
      if (validDates.includes(dailyChartData.date)) {
        return { ...acc, [dailyChartData.date]: dailyChartData.amount };
      }

      return acc;
    }, {});
    return {
      dailyChartMappedData: mappedChartData,
      securityId: securityReturns.securityId,
    };
  });

  return accountReturns.map(returns => {
    const accountReturnsData = {
      accountReturns: returns.amount,
      date: returns.date,
    };

    const securitiesReturnsData = mapSecuritiesReturnsAndComputeMaxMin(
      securitiesReturnsDataMappedByDate,
      returns.date,
      returns.amount,
      showSecurities,
    );

    return { ...accountReturnsData, ...securitiesReturnsData };
  });
};
