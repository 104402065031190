import React from 'react';

import { updateSelectedFilters } from '../utils';

import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { FilterGroup, Portfolio, PortfolioSelectionFilters } from '~/containers/PortfolioSelection/types';
import { SfTheme } from '~/utils/theme';

export interface FilterContainerProps {
  portfolioFilters: FilterGroup[];
  portfolioFiltersHeader: string;
  selectedFilters: PortfolioSelectionFilters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<PortfolioSelectionFilters>>;
}

export const FilterContainer: React.FC<FilterContainerProps> = ({
  portfolioFilters,
  portfolioFiltersHeader,
  selectedFilters,
  setSelectedFilters,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, attribute: keyof Portfolio, checked: boolean) => {
    setSelectedFilters(prevFilters => updateSelectedFilters(prevFilters, attribute, event.target.value, checked));
  };

  const {
    sfFilterContainer: { styles },
  } = useTheme<SfTheme>();

  return (
    <Grid container spacing={2}>
      <Grid item sx={{ pb: 2 }} xs={12}>
        <Typography color="primary" component="h2" variant="body1">
          {portfolioFiltersHeader}
        </Typography>
      </Grid>
      <Grid container item sx={styles.root} xs={12}>
        <FormControl>
          {portfolioFilters.map(filter => (
            <Grid item key={filter.label} sx={{ m: 1.25 }} xs={12}>
              <FormLabel>{filter.label}</FormLabel>
              <FormGroup aria-label={filter.label} role="group">
                {filter.items
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map(feature => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFilters[filter.attribute]?.includes(feature.value) || false}
                          disabled={feature.disabled}
                          name={feature.label}
                          onChange={(event, checked) => handleChange(event, filter.attribute, checked)}
                          value={feature.value}
                        />
                      }
                      key={feature.label}
                      label={feature.label}
                    />
                  ))}
              </FormGroup>
            </Grid>
          ))}
        </FormControl>
      </Grid>
    </Grid>
  );
};
