import React, { FC } from 'react';
import { Controller, FieldError, RegisterOptions, UseFormMethods } from 'react-hook-form';

import { FormData } from '../../types';
import { QuestionContent, QuestionOrderSteps } from '../types';

import { RadioGroup } from '~/components/ui/RadioGroup';

interface Props {
  defaultValue: any;
  fieldName: string;
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  inputErrors?: FieldError;
  isFieldDisabled: boolean;
  onRadioAnswerChange: (dataPointKey: string, answer: string) => void;
  questionContent: QuestionContent;
  questionInRow: QuestionOrderSteps;
  validationRules: RegisterOptions;
}

export const RadioQuestion: FC<Props> = ({
  defaultValue,
  validationRules,
  fieldName,
  formHooks,
  onRadioAnswerChange,
  questionContent,
  questionInRow,
  isFieldDisabled,
  inputErrors,
}) => {
  const { control } = formHooks;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      key={`question-${fieldName}`}
      name={fieldName}
      render={({ onChange, value }) => (
        <RadioGroup
          dataQa={`question-radio-${questionInRow.questionKey}`}
          disabled={isFieldDisabled}
          error={!!inputErrors}
          items={questionContent.options ?? []}
          legend={questionContent.question}
          name={fieldName}
          onChange={e => {
            onRadioAnswerChange(fieldName, e);
            onChange(e);
          }}
          value={value}
        />
      )}
      rules={validationRules}
    />
  );
};
