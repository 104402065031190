import React, { FC, useEffect, useState } from 'react';

import { useGetRetakeRTQFADocusignContent } from './contentstack';
import { useGetRetakeRTQDocusignDocument } from './symphony';

import { DocusignIframe } from '~/components/DocusignIframe';
import { Alert } from '~/components/ui/Alert';
import { Box, Skeleton, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { DocusignParams } from '~/containers/Comparison';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { SfTheme } from '~/utils/theme';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  docusignParams: DocusignParams;
  managedProductId: string;
  onIframeReady?: () => void;
}

export const Docusign: FC<Props> = ({
  contentOptions,
  dataQa = 'docusign',
  docusignParams: { baseUrl, faPartyId, returnToUrl },
  managedProductId,
  onIframeReady,
}) => {
  const {
    sfDocusign: { styles: style, typographyVariants },
  } = useTheme<SfTheme>();

  const [loading, setLoading] = useState(true);
  const [linkToDocument, setLinkToDocument] = useState<string>();

  const {
    data: docusignContentData,
    loading: docusignContentLoading,
    error: docusignContentError,
  } = useGetRetakeRTQFADocusignContent({
    variables: contentOptions,
  });

  const {
    data: docusignData,
    loading: docusignDataLoading,
    error: docusignDataError,
  } = useGetRetakeRTQDocusignDocument({
    variables: { partyId: faPartyId, managedProductId, baseUrl, returnToUrl },
  });

  useEffect(() => {
    if (docusignData) {
      const legalDocuments = docusignData.managedProduct?.legalDocuments;
      const planUpdateWorkflowId = docusignData.managedProduct?.planUpdateWorkflows?.find(
        puw => !!puw && legalDocuments?.find(ld => ld.associatedEntities?.planUpdateWorkflowIds?.includes(puw.id)),
      )?.id;
      if (planUpdateWorkflowId) {
        const legalDocument = legalDocuments?.find(ld =>
          ld.associatedEntities?.planUpdateWorkflowIds.includes(planUpdateWorkflowId),
        );
        if (legalDocument?.linkToDocument) {
          setLinkToDocument(legalDocument.linkToDocument);
          onIframeReady?.();
        }
      }
    }
  }, [docusignData]);

  const loadingStates = [docusignContentLoading, docusignDataLoading];
  useEffect(() => {
    setLoading(loadingStates.includes(true));
  }, loadingStates);

  const content = docusignContentData?.all_retake_rtq_docusign?.items?.[0]?.fa_docusign;
  return (
    <Box data-qa={dataQa} sx={{ py: 2 }}>
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : docusignDataError || docusignContentError ? (
        <Alert contentOptions={contentOptions} error={docusignDataError || docusignContentError} severity="error" />
      ) : (
        <>
          {content?.imageConnection?.edges?.[0]?.node?.url && (
            <Box data-qa={`${dataQa}-image`} sx={{ height: '100px', py: 2, textAlign: 'center' }}>
              <img alt="" src={content.imageConnection.edges[0]?.node?.url} style={{ height: '100%' }} />
            </Box>
          )}
          <Box px={2}>
            <Typography
              align="center"
              component="div"
              data-qa={`${dataQa}-heading`}
              sx={{ color: `${style.headingColor}`, pb: 2 }}
              variant={typographyVariants?.heading}
            >
              {content?.heading}
            </Typography>
            <Typography align="center" component="div" data-qa={`${dataQa}-sub-heading`} sx={{ pb: 5 }} variant="body2">
              {content?.sub_heading}
            </Typography>
          </Box>
          {linkToDocument && (
            <>
              <DocusignIframe
                dataQa={dataQa}
                helperHeading={content?.helper_heading}
                helperSubHeading={content?.helper_sub_heading}
                linkToDocument={linkToDocument}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};
