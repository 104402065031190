import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';

import { FormData, PaperworkPage, PaperworkPageConfig } from '../types';

import {
  Data as UseGetPaperworkData,
  ManagedProductPaperworkWithFreeFormId,
  useGetPaperworkData,
} from './useGetPaperworkData';
import { fetchPages, mapResultToSymphonyPaperworkInputs } from './utils';

import { FinancialAccountType, PaperworkType, TrustProfileType } from '~/__generated__';
import { OnboardingWrapperOnNextArgs } from '~/components/OnboardingWrapper/types';
import { useDeletePaperworkById, useSaveMultiplePaperwork } from '~/containers/Paperwork/symphony';
import { isCustodialAccountType } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
  managedProductId: string;
  onBack?: () => void;
  onNext?: (onboardingWrapperOnNextArgs?: OnboardingWrapperOnNextArgs) => void;
  onSaveExit?: () => void;
  onboardingWrapperOnNextArgs?: OnboardingWrapperOnNextArgs;
  paperworkConfigGetter: (
    accountType: FinancialAccountType,
    allSavedPaperworkData: ManagedProductPaperworkWithFreeFormId[],
  ) => Promise<PaperworkPageConfig>;
  partyId: string;
  partyIdFA?: string;
  prefillInvestDataFromQuestionnaire: boolean;
  prefillWealthDataFromQuestionnaire: boolean;
}

interface Data {
  activePage: number;
  consentRequired?: boolean;
  crsDefaultChecked?: boolean;
  crsRequired?: boolean;
  defaultChecked?: boolean;
  dependentFormFieldsInvisibility: Record<string, boolean>;
  error?: Error;
  errorSaving?: Error;
  formHooks: Omit<UseFormMethods<FormData>, 'watch' | 'formState'>;
  handleBack: () => void;
  handleChangePage: (newPage: number) => void;
  handleCustomPageComponentOnNext: () => void;
  handleNext: () => void;
  handleSave: (e: React.MouseEvent, exitAfterSave?: boolean) => void;
  handleSetCrsValidation: (value: boolean) => void;
  handleSetValidation: (value: boolean) => void;
  isCrsValidationChecked: boolean;
  isFormSubmitted: boolean;
  isPaperworkSaving: boolean;
  isPaperworkSubmitting: boolean;
  isRefetching: boolean;
  isValidationChecked: boolean;
  loading: boolean;
  onFormFieldsVisibilityChange: (newInvisibility: Record<string, boolean>) => void;
  onHideSnackbar: () => void;
  page: PaperworkPage | null;
  pages: PaperworkPage[];
  paperworkData?: UseGetPaperworkData;
  registerCustomDeferredPromise: (fieldName: string, deferredPromise: Promise<void>) => void;
  setUnmaskedValues: Dispatch<SetStateAction<Record<string, string>>>;
  showPaperworkConsent: boolean;
  showPaperworkCrs: boolean;
  showSnackbar: boolean;
  unmaskedValues: Record<string, string>;
  unregisterCustomDeferredPromise: (fieldName: string) => void;
}

export const usePaperworkManager = ({
  contentOptions,
  managedProductId,
  onboardingWrapperOnNextArgs,
  onBack,
  onNext,
  onSaveExit,
  partyId,
  partyIdFA,
  paperworkConfigGetter,
  prefillInvestDataFromQuestionnaire,
  prefillWealthDataFromQuestionnaire,
}: Variables): AsyncResult<Data> => {
  const [activePage, setActivePage] = useState(0);
  const [isPaperworkSubmitting, setIsPaperworkSubmitting] = useState(false);
  const [isPaperworkSaving, setIsPaperworkSaving] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [pages, setPages] = useState<PaperworkPage[]>([]);
  const [paperworkFreeFormIdToProfileType, setPaperworkFreeFormIdToProfileType] = useState<
    Record<string, PaperworkType>
  >({});
  const [paperworkFreeFormIdToTrustProfileType, setPaperworkFreeFormIdToTrustProfileType] = useState<
    Record<string, TrustProfileType>
  >({});
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [dependentFormFieldsInvisibility, setDependentFormFieldsInvisibility] = useState<Record<string, boolean>>({});
  const [unmaskedValues, setUnmaskedValues] = useState<Record<string, string>>({});
  const [customQuestionDeferredPromises, setCustomQuestionDeferredPromises] = useState<Record<string, Promise<void>>>(
    {},
  );

  const [isValidationChecked, setIsValidationChecked] = useState(false);
  const [isCrsValidationChecked, setIsCrsValidationChecked] = useState(false);

  const {
    register,
    unregister,
    setError,
    clearErrors,
    setValue,
    trigger,
    errors,
    formState,
    reset,
    getValues,
    handleSubmit,
    control,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const isFormSubmitted = formState.isSubmitted;
  const formHooks = useMemo(() => {
    return {
      register,
      unregister,
      setError,
      clearErrors,
      setValue,
      trigger,
      errors,
      reset,
      getValues,
      handleSubmit,
      control,
    };
  }, [clearErrors, control, errors, getValues, handleSubmit, register, reset, setError, setValue, trigger, unregister]);

  const { data: paperworkData, error, loading } = useGetPaperworkData({
    contentOptions,
    managedProductId,
    partyId,
    partyIdFA,
    prefillInvestDataFromQuestionnaire,
    prefillWealthDataFromQuestionnaire,
  });

  const [saveMultiPaperwork] = useSaveMultiplePaperwork();
  const [deletePaperworkById] = useDeletePaperworkById();

  useEffect(() => {
    // Runs on initial load
    if (
      paperworkData &&
      paperworkData.accountType !== FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE &&
      !pages.length &&
      !activePage
    ) {
      fetchPages(paperworkData.accountType, paperworkData.allSavedPaperworkData, paperworkConfigGetter).then(
        async result => {
          setPages(result.pages);
          setPaperworkFreeFormIdToProfileType(result.paperworkFreeFormIdToProfileType);
          setPaperworkFreeFormIdToTrustProfileType(result.paperworkFreeFormIdToTrustProfileType);
        },
      );
    }
  }, [pages.length, activePage, paperworkConfigGetter, paperworkData, deletePaperworkById, managedProductId]);

  const page = useMemo(() => (pages.length > 0 ? pages[activePage] : null), [pages, activePage]);
  const showPaperworkConsent = !!page?.consent;
  const showPaperworkCrs = !!page?.crs;
  const { consentRequired, defaultChecked } = page?.consent || {};
  const { crsRequired = false, crsDefaultChecked = false } = page?.crs || {};
  const accountType = paperworkData?.accountType ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE;

  useEffect(() => {
    if (showPaperworkConsent && defaultChecked !== undefined) {
      setIsValidationChecked(defaultChecked);
    }
  }, [defaultChecked, showPaperworkConsent]);

  useEffect(() => {
    if (showPaperworkCrs && crsDefaultChecked) {
      setIsCrsValidationChecked(crsDefaultChecked);
    }
  }, [crsDefaultChecked, showPaperworkCrs]);

  const handleChangePage = useCallback(
    async (newPage: number) => {
      setIsRefetching(true);
      const refetchedPaperworkInfo = await paperworkData?.refetchPaperwork();
      const result = await fetchPages(accountType, refetchedPaperworkInfo ?? [], paperworkConfigGetter);

      // Making sure that we don't end up clashing against an existing Primary Paperwork
      const allPaperworkFreeFormIdsForNewPage = new Set<string>();
      result.pages[newPage]?.sections?.forEach(s =>
        s.questions.order.orderSteps.forEach(q => {
          if (q.paperworkFreeFormId) {
            allPaperworkFreeFormIdsForNewPage.add(q.paperworkFreeFormId);
          }
        }),
      );
      const primaryPaperworkFreeFormIdInNewPage = Array.from(allPaperworkFreeFormIdsForNewPage).find(ffId => {
        return result.paperworkFreeFormIdToProfileType[ffId] === PaperworkType.PRIMARY;
      });
      const clashingPrimarySavedPaperworkDataId = primaryPaperworkFreeFormIdInNewPage
        ? paperworkData?.allSavedPaperworkData?.find(
            pd =>
              !!pd.id &&
              pd.paperworkType === PaperworkType.PRIMARY &&
              pd.paperworkFreeFormId !== primaryPaperworkFreeFormIdInNewPage,
          )?.id
        : undefined;
      if (clashingPrimarySavedPaperworkDataId) {
        await deletePaperworkById({
          variables: {
            managedId: managedProductId,
            paperworkId: clashingPrimarySavedPaperworkDataId,
          },
        });
      }

      setPages(result.pages);
      setPaperworkFreeFormIdToProfileType(result.paperworkFreeFormIdToProfileType);
      setPaperworkFreeFormIdToTrustProfileType(result.paperworkFreeFormIdToTrustProfileType);
      setActivePage(newPage);
      setDependentFormFieldsInvisibility({});
      setUnmaskedValues({});
      setCustomQuestionDeferredPromises({});
      reset();
      setIsRefetching(false);
      window.scroll(0, 0);
    },
    [paperworkData, accountType, paperworkConfigGetter, reset, deletePaperworkById, managedProductId],
  );

  const submitAndSave = useCallback(async () => {
    try {
      await handleSubmit(
        async () => {
          const allPaperworkInputs = page
            ? mapResultToSymphonyPaperworkInputs({
                partyId,
                managedProductId,
                accountType,
                allSavedPaperworkData: paperworkData?.allSavedPaperworkData ?? [],
                page,
                paperworkFreeFormIdToProfileType,
                paperworkFreeFormIdToTrustProfileType,
                formValues: getValues(),
                dependentFormFieldsInvisibility,
                unmaskedValues,
                crsRequired,
                isCrsValidationChecked,
              })
            : [];

          const saveResult = await saveMultiPaperwork({
            variables: {
              paperworkInputList: allPaperworkInputs,
            },
          });

          if (saveResult.data?.saveMultiplePaperwork.length !== allPaperworkInputs.length) {
            throw new Error('Error saving');
          }

          for (const [fieldName, deferredPromise] of Object.entries(customQuestionDeferredPromises)) {
            try {
              await deferredPromise;
            } catch (err: any) {
              console.error(`${fieldName} was not saved successfully`, err);
              throw new Error(err);
            }
          }
        },
        () => {
          throw new Error('Validations failed');
        },
      )();

      return true;
    } catch (err: any) {
      if (err.message === 'Error saving') {
        setErrorSaving(err);
      }
      return false;
    }
  }, [
    accountType,
    crsRequired,
    customQuestionDeferredPromises,
    dependentFormFieldsInvisibility,
    getValues,
    handleSubmit,
    isCrsValidationChecked,
    managedProductId,
    page,
    paperworkData?.allSavedPaperworkData,
    paperworkFreeFormIdToProfileType,
    paperworkFreeFormIdToTrustProfileType,
    partyId,
    saveMultiPaperwork,
    unmaskedValues,
  ]);

  const handleSave = useCallback(
    async (_e: React.MouseEvent, exitAfterSave?: boolean) => {
      setIsPaperworkSaving(true);
      setTimeout(async () => {
        const isSaved = await submitAndSave();
        if (isSaved) {
          setShowSnackbar(true);
          if (exitAfterSave) {
            onSaveExit?.();
          }
        }
        setIsPaperworkSaving(false);
      }, 500);
    },
    [onSaveExit, submitAndSave],
  );

  const handleNextForLastPage = useCallback(async () => {
    const allPaperworkFreeFormIds = new Set();
    pages.forEach(p =>
      p.sections?.forEach(s =>
        s.questions.order.orderSteps.forEach(q => {
          if (q.paperworkFreeFormId) {
            allPaperworkFreeFormIds.add(q.paperworkFreeFormId);
          }
        }),
      ),
    );

    const unnecessaryPaperworkDataIds =
      paperworkData?.allSavedPaperworkData
        ?.filter(pd => !allPaperworkFreeFormIds.has(pd.paperworkFreeFormId))
        .map(pd => pd.id ?? '') ?? [];
    for (const paperworkDataId of unnecessaryPaperworkDataIds) {
      await deletePaperworkById({
        variables: {
          managedId: managedProductId,
          paperworkId: paperworkDataId,
        },
      });
    }

    onNext?.({
      ...onboardingWrapperOnNextArgs,
      ageOfTermination: undefined, // TODO - populate based on data from paperwork
      accountType, // TODO - update based on data from paperwork (config.statesWithUGMA)
      unsetAgeOfTermination: !isCustodialAccountType(accountType),
    });
  }, [
    accountType,
    deletePaperworkById,
    managedProductId,
    onNext,
    onboardingWrapperOnNextArgs,
    pages,
    paperworkData?.allSavedPaperworkData,
  ]);

  const handleNext = useCallback(async () => {
    setIsPaperworkSubmitting(true);
    const isSaved = await submitAndSave();
    if (isSaved) {
      if (activePage === pages.length - 1) {
        // on last page, delete all the unnecessary paperwork sets
        await handleNextForLastPage();
      } else {
        const nextPageIndex = activePage + 1;
        const nextPage = pages[nextPageIndex];
        await handleChangePage(nextPage.index);
      }
    }
    setIsPaperworkSubmitting(false);
  }, [activePage, handleChangePage, handleNextForLastPage, pages, submitAndSave]);

  const handleCustomPageComponentOnNext = useCallback(async () => {
    if (activePage === pages.length - 1) {
      await handleNextForLastPage();
    } else {
      const nextPageIndex = activePage + 1;
      const nextPage = pages[nextPageIndex];
      await handleChangePage(nextPage.index);
    }
  }, [activePage, pages, handleNextForLastPage, handleChangePage]);

  const handleBack = useCallback(() => {
    if (activePage === 0) {
      onBack?.();
    } else {
      handleChangePage(activePage - 1);
    }
  }, [activePage, handleChangePage, onBack]);

  const handleSetValidation = useCallback((val: boolean) => setIsValidationChecked(val), []);
  const handleSetCrsValidation = useCallback((val: boolean) => setIsCrsValidationChecked(val), []);

  const onFormFieldsVisibilityChange = useCallback(
    (newInvisibility: Record<string, boolean>) =>
      setDependentFormFieldsInvisibility(existing => ({ ...existing, ...newInvisibility })),
    [],
  );

  const registerCustomDeferredPromise = useCallback((fieldName: string, promise: Promise<void>) => {
    setCustomQuestionDeferredPromises(prev => ({ ...prev, [fieldName]: promise }));
  }, []);

  const unregisterCustomDeferredPromise = useCallback((fieldName: string) => {
    setCustomQuestionDeferredPromises(prev => {
      const { [fieldName]: _omitted, ...rest } = prev;
      return rest;
    });
  }, []);

  const data: Data = useMemo(() => {
    return {
      accountType,
      activePage,
      consentRequired,
      crsRequired,
      crsDefaultChecked,
      defaultChecked,
      dependentFormFieldsInvisibility,
      error,
      formHooks,
      handleChangePage,
      handleBack,
      handleCustomPageComponentOnNext,
      handleNext,
      handleSave,
      handleSetValidation,
      handleSetCrsValidation,
      isCrsValidationChecked,
      isFormSubmitted,
      isRefetching,
      isPaperworkSubmitting,
      isPaperworkSaving,
      isValidationChecked,
      errorSaving,
      onFormFieldsVisibilityChange,
      onHideSnackbar: () => setShowSnackbar(false),
      registerCustomDeferredPromise,
      setUnmaskedValues,
      showSnackbar,
      showPaperworkConsent,
      showPaperworkCrs,
      loading,
      page,
      pages,
      paperworkData,
      unmaskedValues,
      unregisterCustomDeferredPromise,
    };
  }, [
    accountType,
    activePage,
    consentRequired,
    crsRequired,
    crsDefaultChecked,
    defaultChecked,
    dependentFormFieldsInvisibility,
    error,
    formHooks,
    handleChangePage,
    handleBack,
    handleCustomPageComponentOnNext,
    handleNext,
    handleSave,
    handleSetValidation,
    handleSetCrsValidation,
    isCrsValidationChecked,
    isFormSubmitted,
    isRefetching,
    isPaperworkSubmitting,
    isPaperworkSaving,
    isValidationChecked,
    errorSaving,
    onFormFieldsVisibilityChange,
    registerCustomDeferredPromise,
    showSnackbar,
    showPaperworkConsent,
    showPaperworkCrs,
    loading,
    page,
    pages,
    paperworkData,
    unmaskedValues,
    unregisterCustomDeferredPromise,
  ]);

  return {
    data,
    error,
    loading,
  };
};
