import { useCallback, useEffect, useMemo, useState } from 'react';

import { getPaperworkWithFreeFormId, intialisePaperworkDataFromRelatedContact } from './utils';

import { FinancialAccountType, PaperworkInput } from '~/__generated__';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import {
  CMSQuestions,
  useGetCountries,
  useGetPaperworkContent,
  useGetPaperworkQuestionsContent,
  useGetStates,
} from '~/containers/Paperwork/contentstack';
import {
  GetPaperworkContent_all_paperwork_items_headings,
  GetPaperworkContent_all_paperwork_items_section,
} from '~/containers/Paperwork/contentstack/__generated__/query.v2';
import {
  isQuestionnaireInvestmentData,
  isQuestionnaireWealthData,
  ManagedProductPaperwork,
  QuestionnaireData,
  useGetPaperwork,
  useGetRelatedContacts,
  useSyncWithCustodian,
} from '~/containers/Paperwork/symphony';
import {
  isStringAttribute,
  PartyAttribute,
  useGetClientExternalIdentifiers,
  useGetPartyAttributes,
} from '~/hooks/client/symphony';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface ZipCodeMapItem {
  ranges: {
    high: string;
    low: string;
  }[];
  state: string;
}

export interface ManagedProductPaperworkWithFreeFormId extends ManagedProductPaperwork {
  paperworkFreeFormId: string;
}

export interface PaperworkInputWithFreeFormId extends PaperworkInput {
  paperworkFreeFormId: string;
}

export interface Data {
  accountType: FinancialAccountType;
  allPaperworkDataAvailableForPrefill?: ManagedProductPaperwork[];
  allSavedPaperworkData?: ManagedProductPaperworkWithFreeFormId[];
  content: {
    backLabel?: string;
    consent?: {
      consentErrorText?: string;
      consentText?: string;
    };
    countriesList?: DropdownItem<string>[];
    crs?: {
      crsError?: string;
      crsText?: string;
    };
    disclaimer?: string;
    headings?: (GetPaperworkContent_all_paperwork_items_headings | null)[];
    imageUrl?: string;
    minorAgesByState?: {
      minorAge: number;
      state: string | undefined;
    }[];
    nextLabel?: string;
    questions: CMSQuestions[];
    saveAndExit?: {
      description?: string;
      link?: string;
    };
    saveLabel?: string;
    saveSnackbarMessage?: string;
    section?: (GetPaperworkContent_all_paperwork_items_section | null)[];
    stateZipCodeMap?: ZipCodeMapItem[];
    statesList?: DropdownItem<string>[];
    validationMessages?: {
      key: string;
      label: string;
    }[];
  };
  faPartyAttributes?: PartyAttribute[];
  faRepCodes: DropdownItem<string>[];
  maskedAccountNumber?: string;
  partyHarvestLosses?: boolean;
  partyRelationships?: Record<string, string[]>; // partyId -> relatedPartyIds
  questionnaireInvestWealthData: QuestionnaireData[];
  refetchPaperwork: () => Promise<ManagedProductPaperworkWithFreeFormId[]>;
}

interface Variables {
  contentOptions: ContentOptions;
  managedProductId: string;
  partyId: string;
  partyIdFA?: string;
  performSyncWithCustodian?: boolean;
  prefillInvestDataFromQuestionnaire: boolean;
  prefillWealthDataFromQuestionnaire: boolean;
}

export const useGetPaperworkData = ({
  contentOptions,
  managedProductId,
  partyId,
  partyIdFA = '',
  performSyncWithCustodian = false,
  prefillInvestDataFromQuestionnaire,
  prefillWealthDataFromQuestionnaire,
}: Variables): AsyncResult<Data> => {
  const [syncError, setSyncError] = useState<Error | undefined>();
  const [isSynced, setIsSynced] = useState(false);
  const [syncWithCustodian] = useSyncWithCustodian();

  const {
    featureFlags: { additionalRepCodeIdentifierNames, repCodeIdentifierName },
    components: {
      sfPaperworkV2: { fetchRelatedPartiesData, relatedPartyRelationshipNames },
    },
  } = useCoreConfig();

  useEffect(() => {
    if (performSyncWithCustodian && !isSynced) {
      const syncWithCustodianFunc = async () => {
        try {
          const result = await syncWithCustodian({
            variables: {
              managedProductId,
              partyId,
            },
          });

          setIsSynced(!!result.data?.syncPaperworkFromCustodian?.success);
          setSyncError(result.errors?.[0].originalError ?? undefined);
        } catch (err) {
          console.error(err);
          if (err instanceof Error) {
            setSyncError(err);
          }
        }
      };

      syncWithCustodianFunc();
    }
  }, [isSynced, managedProductId, partyId, performSyncWithCustodian, syncWithCustodian]);

  const { data: statesData, loading: statesDataLoading, error: statesDataError } = useGetStates({
    variables: contentOptions,
  });

  const { data: countriesData, loading: countriesDataLoading, error: countriesDataError } = useGetCountries({
    variables: contentOptions,
  });

  const {
    data: paperworkContentData,
    loading: paperworkContentLoading,
    error: paperworkContentError,
  } = useGetPaperworkContent({
    variables: contentOptions,
  });

  const {
    data: paperworkQuestionsContentData,
    loading: paperworkQuestionsContentLoading,
    error: paperworkQuestionsContentError,
  } = useGetPaperworkQuestionsContent({
    variables: contentOptions,
  });

  const {
    data: paperworkSymphonyData,
    loading: paperworkSymphonyLoading,
    error: paperworkSymphonyError,
    refetch: refetchPaperworkSymphonyData,
  } = useGetPaperwork({
    variables: {
      partyId,
      managedProductId,
    },
    fetchPolicy: 'no-cache',
    skip: performSyncWithCustodian && !isSynced,
  });

  const {
    data: relatedPartiesData,
    loading: relatedPartiesLoading,
    error: relatedPartiesError,
  } = useGetRelatedContacts({
    variables: {
      partyId,
      relationshipNames: relatedPartyRelationshipNames,
    },
    skip: !fetchRelatedPartiesData,
  });

  const {
    data: faPartyAttributesData,
    loading: faPartyAttributesLoading,
    error: faPartyAttributesError,
  } = useGetPartyAttributes({
    variables: {
      partyId: partyIdFA,
    },
    skip: !partyIdFA,
  });

  const { data: faRepCodeData, loading: faRepCodeLoading, error: faRepCodeError } = useGetClientExternalIdentifiers({
    variables: { partyId: partyIdFA },
    skip: !partyIdFA || !repCodeIdentifierName,
  });

  const loading =
    paperworkContentLoading ||
    paperworkQuestionsContentLoading ||
    paperworkSymphonyLoading ||
    statesDataLoading ||
    countriesDataLoading ||
    relatedPartiesLoading ||
    faPartyAttributesLoading ||
    faRepCodeLoading;
  const error =
    paperworkContentError ||
    paperworkQuestionsContentError ||
    paperworkSymphonyError ||
    statesDataError ||
    countriesDataError ||
    relatedPartiesError ||
    faPartyAttributesError ||
    faRepCodeError ||
    syncError;

  const refetchPaperwork = useCallback(async (): Promise<ManagedProductPaperworkWithFreeFormId[]> => {
    const accountType =
      paperworkSymphonyData?.managedProduct?.accountType ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE;
    const refetchedSymphonyData = await refetchPaperworkSymphonyData();
    return getPaperworkWithFreeFormId(
      accountType,
      refetchedSymphonyData.data.managedProduct?.paperwork?.filter(pd => pd.id) ?? [],
    );
  }, [paperworkSymphonyData?.managedProduct?.accountType, refetchPaperworkSymphonyData]);

  const data: Data = useMemo(() => {
    const content = paperworkContentData?.all_paperwork?.items?.[0];
    const imageUrl = content?.imageConnection?.edges?.[0]?.node?.url ?? undefined;
    const sectionContent = content?.section ?? undefined;
    const headingContent = content?.headings ?? undefined;
    const questionsContent = (paperworkQuestionsContentData?.all_paperwork_questions?.items?.filter(c => c !== null) ??
      []) as CMSQuestions[];
    const statesContent = statesData?.all_states?.items?.[0];
    const statesList = statesContent?.stateslist?.map(s => ({
      label: s?.label,
      value: s?.value,
    })) as DropdownItem<string>[];
    const minorAgesByState =
      statesContent?.stateslist?.map(s => ({
        state: s?.value ?? undefined,
        minorAge: s?.minor_age ?? 18,
      })) ?? [];
    const stateZipCodeMap =
      statesContent?.stateslist?.map(el => ({
        state: el?.value ?? '',
        ranges: el?.valid_zip_code_ranges?.map(range => ({ low: range?.low ?? '', high: range?.high ?? '' })) ?? [],
      })) ?? [];
    const countriesContent = countriesData?.all_countries?.items?.[0];
    const countriesList = countriesContent?.countrieslist?.map(c => ({
      label: c?.label ?? '',
      value: c?.value ?? '',
      otherValues: c?.other_values ?? undefined,
    })) as DropdownItem<string>[];
    const validationMessages = [
      ...(content?.validation
        ?.filter(v => v?.__typename === 'PaperworkValidationMessages')
        ?.map(v => ({ key: v?.messages?.key ?? '', label: v?.messages?.label ?? '' })) ?? []),
      ...(content?.validation_rte
        ?.filter(v => v?.__typename === 'PaperworkValidationRte')
        ?.map(v => ({ key: v?.key ?? '', label: v?.label ?? '' })) ?? []),
      ...(content?.fields?.text
        ?.filter(field => field?.key?.startsWith('validation:'))
        ?.map(field => ({ key: field?.key ?? '', label: field?.value ?? '' })) ?? []),
    ];

    let repCodeDropdownItems: DropdownItem<string>[] = [];
    if (faRepCodeData && faPartyAttributesData) {
      const externalIdentifiersRepCodesDropdownItems =
        faRepCodeData.client?.party?.externalSystemIdentifiers
          ?.filter(
            item =>
              item.name === repCodeIdentifierName ||
              additionalRepCodeIdentifierNames?.externalIdentifiers?.includes(item.name),
          )
          .map(item => ({ label: item.value, value: item.value })) ?? [];

      const partyAttributesRepCodes = faPartyAttributesData.client?.party?.attributes
        ?.filter(isStringAttribute)
        .filter(attribute => additionalRepCodeIdentifierNames?.partyAttributes?.includes(attribute.name))
        .flatMap(attribute => attribute.value.split(','))
        .sort();
      const partyAttributesRepCodeItems = partyAttributesRepCodes?.map(item => ({ label: item, value: item })) ?? [];

      repCodeDropdownItems = externalIdentifiersRepCodesDropdownItems.concat(partyAttributesRepCodeItems);
    }

    const maskedAccountNumber = paperworkSymphonyData?.client?.financialAccounts?.[0].maskedAccountNumber ?? undefined;
    const managedAccount = paperworkSymphonyData?.managedProduct;
    const accountType = managedAccount?.accountType ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE;
    const allPaperworkDataAvailableForPrefill = managedAccount?.paperwork?.filter(pd => !pd.id) ?? [];
    const allSavedPaperworkData = getPaperworkWithFreeFormId(
      accountType,
      managedAccount?.paperwork?.filter(pd => pd.id) ?? [],
    );

    const clientHarvestLosses = paperworkSymphonyData?.client?.kyc?.harvestLosses;
    const questionnaireData = managedAccount?.questionnaires[0]?.collectedDataGroup.collectedData;

    let questionnaireInvestWealthData: QuestionnaireData[] = [];
    if (questionnaireData) {
      if (prefillInvestDataFromQuestionnaire && prefillWealthDataFromQuestionnaire) {
        questionnaireInvestWealthData = questionnaireData.filter(
          x => isQuestionnaireInvestmentData(x) || isQuestionnaireWealthData(x),
        );
      } else if (prefillInvestDataFromQuestionnaire) {
        questionnaireInvestWealthData = questionnaireData.filter(isQuestionnaireInvestmentData);
      } else if (prefillWealthDataFromQuestionnaire) {
        questionnaireInvestWealthData = questionnaireData.filter(isQuestionnaireWealthData);
      }
    }

    const partyRelationshipData: Record<string, string[]> = {};
    if (fetchRelatedPartiesData && relatedPartiesData && paperworkSymphonyData?.managedProduct?.accountType) {
      partyRelationshipData[relatedPartiesData.client?.party?.id ?? ''] = [];
      allPaperworkDataAvailableForPrefill.push(
        ...(relatedPartiesData.client?.party?.relationships
          ?.map(directRelationship => {
            partyRelationshipData[relatedPartiesData.client?.party?.id ?? ''].push(directRelationship.party.id ?? '');
            partyRelationshipData[directRelationship.party.id ?? ''] = [];
            return [
              intialisePaperworkDataFromRelatedContact(directRelationship.party),
              ...(directRelationship.party.relationships?.map(secondLevelRelationship => {
                partyRelationshipData[directRelationship.party.id ?? ''].push(secondLevelRelationship.party.id ?? '');
                return intialisePaperworkDataFromRelatedContact(secondLevelRelationship.party);
              }) ?? []),
            ];
          })
          .flat() ?? []),
      );
    }

    return {
      accountType,
      allPaperworkDataAvailableForPrefill,
      allSavedPaperworkData,
      content: {
        imageUrl,
        section: sectionContent,
        headings: headingContent,
        countriesList,
        minorAgesByState,
        stateZipCodeMap,
        statesList,
        questions: questionsContent,
        validationMessages,
        backLabel: content?.back ?? undefined,
        consent: {
          consentErrorText: content?.consent?.consent_error_text ?? undefined,
          consentText: content?.consent?.consent_text ?? undefined,
        },
        crs: {
          crsError: content?.crs?.crs_error ?? undefined,
          crsText: content?.crs?.crs_text ?? undefined,
        },
        disclaimer: content?.disclaimer ?? undefined,
        nextLabel: content?.next ?? undefined,
        saveAndExit: {
          description: content?.save_and_exit?.description ?? undefined,
          link: content?.save_and_exit?.link ?? undefined,
        },
        saveLabel: content?.save_text ?? undefined,
        saveSnackbarMessage: content?.save_snackbar_message ?? undefined,
      },
      faPartyAttributes: faPartyAttributesData?.client?.party?.attributes ?? [],
      faRepCodes: repCodeDropdownItems,
      maskedAccountNumber,
      partyHarvestLosses: clientHarvestLosses,
      partyRelationships: partyRelationshipData,
      questionnaireInvestWealthData,
      refetchPaperwork,
    };
  }, [
    additionalRepCodeIdentifierNames?.externalIdentifiers,
    additionalRepCodeIdentifierNames?.partyAttributes,
    countriesData?.all_countries?.items,
    faPartyAttributesData,
    faRepCodeData,
    fetchRelatedPartiesData,
    paperworkContentData?.all_paperwork?.items,
    paperworkQuestionsContentData?.all_paperwork_questions?.items,
    paperworkSymphonyData?.client?.financialAccounts,
    paperworkSymphonyData?.client?.kyc?.harvestLosses,
    paperworkSymphonyData?.managedProduct,
    prefillInvestDataFromQuestionnaire,
    prefillWealthDataFromQuestionnaire,
    refetchPaperwork,
    relatedPartiesData,
    repCodeIdentifierName,
    statesData?.all_states?.items,
  ]);

  return {
    loading,
    error,
    data,
  };
};
