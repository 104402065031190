import { Text, View } from '@react-pdf/renderer';
import React from 'react';

import { Donut } from './Donut';

import { Allocation } from '~/components/AssetAllocationTable';
import { CompositeModelPortfolioAllocationContent } from '~/components/CompositeModelPortfolioAllocation/hooks';
import { Style } from '~/components/pdfs/styles';
import { allocationToDonutSlice } from '~/utils/asset-allocation';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { ContentOptions } from '~/utils/contentstack';
import { formatPercentageString } from '~/utils/format';

export interface CompositeModelPortfolioAllocationData {
  allocation: Allocation[];
  modelPortfolioName: string;
  percentage: string;
}

interface Props {
  assetClassTier?: AssetClassTier;
  contentOptions: ContentOptions;
  pdfStyles: Style;
  sleeveContent: CompositeModelPortfolioAllocationContent;
  sleeves: CompositeModelPortfolioAllocationData[];
}

export const MultiSleeveAllocation: React.FC<Props> = ({
  assetClassTier,
  contentOptions,
  sleeveContent,
  sleeves,
  pdfStyles,
}) => {
  const commonTextStyle = {
    color: pdfStyles.textPrimary.color,
    fontSize: 10,
    marginTop: '8px',
    marginBottom: '8px',
  };

  return (
    <View
      style={{
        width: '100%',
        textAlign: 'left',
        paddingTop: '48px',
        paddingLeft: '40px',
        paddingRight: '40px',
      }}
    >
      <Text
        style={{
          color: pdfStyles.primaryMainColor.color,
          fontSize: 16,
          marginBottom: '12px',
        }}
      >
        {sleeveContent.title}
      </Text>
      <View style={{ ...pdfStyles.border, padding: '8px 16px', marginTop: '12px', marginBottom: '48px' }}>
        {sleeves.map(item => (
          <View key={item.modelPortfolioName}>
            <View style={{ ...commonTextStyle, display: 'flex', flexDirection: 'row' }}>
              <Donut
                data={item.allocation.map(allocationToDonutSlice(assetClassTier))}
                fontSize={0}
                lineSize={3}
                pdfStyles={pdfStyles}
                size={20}
              />
              <View style={{ paddingLeft: '8px', width: '70%' }}>
                <Text style={{ color: pdfStyles.textSecondary.color, fontSize: 8 }}>
                  {sleeveContent.portfolioLabel}
                </Text>
                <Text>{item.modelPortfolioName}</Text>
              </View>
              <View style={{ width: '30%' }}>
                <Text style={{ color: pdfStyles.textSecondary.color, fontSize: 8 }}>
                  {sleeveContent.percentageLabel}
                </Text>
                <Text>{formatPercentageString(item.percentage, { locale: contentOptions.locale })}</Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};
