import { QuestionKeys, QuestionSetKeys } from '../../questions/types';
import { PaperworkDataQuestionConfig } from '../../types';

export const aboutTheGrantorSectionKey = 'about_the_grantor';
export const aboutTheGrantorQuestions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.ADDITIONAL_STAKEHOLDER },
  { questionOrSetKey: QuestionKeys.FIRST_NAME },
  { properties: { optional: true }, questionOrSetKey: QuestionKeys.MIDDLE_NAME },
  { questionOrSetKey: QuestionKeys.LAST_NAME },
  { questionOrSetKey: QuestionKeys.EMAIL },
  { questionOrSetKey: QuestionKeys.PHONE_NUMBER },
  { properties: { adjacent: QuestionKeys.GRANTOR_SSN }, questionOrSetKey: QuestionKeys.BIRTH_DATE },
  { properties: { disabled: true }, questionOrSetKey: QuestionKeys.GRANTOR_SSN },
  { questionOrSetKey: QuestionSetKeys.HOME_ADDRESS_FIELDS },
  { questionOrSetKey: QuestionSetKeys.CITIZENSHIP_FIELDS },
];
