import React from 'react';

import { SleeveTemplateFilterState } from '..';
import { SleeveTemplateCardContent } from '../utils';

import { Button, Grid, Paper } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

export interface SleeveTemplateActionCardProps {
  content: SleeveTemplateCardContent;
  dataQa?: string;
  isAllBlendCard?: boolean; // showing all, toggle to suitable
  isEmptyCard?: boolean; // no templates
  isSuitableBlendCard?: boolean; // showing suitable, toggle to all
  isSuitableNotAvailableCard?: boolean; // no suitable
  setCurrentSleeveTemplateFilter: (value: SleeveTemplateFilterState) => void;
}

export const SleeveTemplateActionCard: React.FC<SleeveTemplateActionCardProps> = ({
  content: {
    alertIconUrl,
    allBlendCard,
    chatCircleUrl,
    emptyCardMessage,
    suitableBlendCard,
    suitableNotAvailableCardMessage,
  },
  dataQa = 'sleeve-template-action-card',
  isAllBlendCard = false,
  isEmptyCard = false,
  isSuitableBlendCard = false,
  isSuitableNotAvailableCard = false,
  setCurrentSleeveTemplateFilter,
}) => {
  const handleChangeSleeveTemplateFilter = (value: SleeveTemplateFilterState) => {
    setCurrentSleeveTemplateFilter(value);
  };

  return (
    <Paper
      data-qa={dataQa}
      sx={{
        display: 'flex',
        ...(isAllBlendCard || isSuitableBlendCard ? { bgcolor: 'grey.50' } : {}),
        height: '271px',
      }}
    >
      {isAllBlendCard ? (
        <Grid container sx={{ alignSelf: 'center', px: 6 }}>
          <Grid item sx={{ display: 'flex', justifyContent: 'center', py: 1 }} xs={12}>
            <img src={alertIconUrl} />
          </Grid>
          <Grid item sx={{ textAlign: 'center', p: 1 }} xs={12}>
            <Typography variant="subtitle2">{allBlendCard.header}</Typography>
          </Grid>
          <Grid item sx={{ textAlign: 'center', my: 1 }} xs={12}>
            <Typography variant="caption">{allBlendCard.message}</Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'center', my: 0.5 }} xs={12}>
            <Button
              onClick={() => handleChangeSleeveTemplateFilter(SleeveTemplateFilterState.Suitable)}
              variant="outlined"
            >
              {allBlendCard.button_text}
            </Button>
          </Grid>
        </Grid>
      ) : isSuitableBlendCard ? (
        <Grid container sx={{ alignSelf: 'center', px: 6 }}>
          <Grid item sx={{ display: 'flex', justifyContent: 'center', py: 1 }} xs={12}>
            <img src={alertIconUrl} />
          </Grid>
          <Grid item sx={{ textAlign: 'center', p: 1 }} xs={12}>
            <Typography variant="subtitle2">{suitableBlendCard.header}</Typography>
          </Grid>
          <Grid item sx={{ textAlign: 'center', my: 1 }} xs={12}>
            <Typography variant="caption">{suitableBlendCard.message}</Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'center', my: 0.5 }} xs={12}>
            <Button onClick={() => handleChangeSleeveTemplateFilter(SleeveTemplateFilterState.All)} variant="outlined">
              {suitableBlendCard.button_text}
            </Button>
          </Grid>
        </Grid>
      ) : isSuitableNotAvailableCard ? (
        <Grid container sx={{ alignSelf: 'center', px: 6 }}>
          <Grid item sx={{ display: 'flex', justifyContent: 'center', py: 2 }} xs={12}>
            <img src={chatCircleUrl} />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: 'center' }} variant="body2">
              {suitableNotAvailableCardMessage}
            </Typography>
          </Grid>
        </Grid>
      ) : isEmptyCard ? (
        <Grid container sx={{ alignSelf: 'center', px: 6 }}>
          <Grid item sx={{ display: 'flex', justifyContent: 'center', py: 2 }} xs={12}>
            <img src={chatCircleUrl} />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: 'center' }} variant="body2">
              {emptyCardMessage}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </Paper>
  );
};
