import React, { ComponentProps, FC } from 'react';

import { AccountDetailsToShow } from '../../AccountSummary/types';
import { Account as AccountInterface, getRebalancedAccounts } from '../../AccountSummary/utils';
import { GetWidgetsContent_all_widgets_items_labels } from '../contentstack/__generated__/getWidgetsContent.v2';

import { AccountListContainer } from './AccountListContainer';

import { AccountActions } from '~/components/AccountActions';
import { AccountInfoVariants } from '~/components/AccountSummary/AccountInfo';
import { Box, Grid, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { SfTheme } from '~/utils/theme';

export enum AccountWidgetsVariants {
  Consolidated = 'Consolidated',
  Separated = 'Separated',
}

export interface AccountListWidgetConfigProps {
  accountDetailsToShow?: AccountDetailsToShow;
  accountInfoVariant?: AccountInfoVariants;
  actions: ComponentProps<typeof AccountActions>['config'];
  variant?: AccountWidgetsVariants;
}

export interface Props {
  accounts: AccountInterface[];
  config: AccountListWidgetConfigProps;
  contentOptions: ContentOptions;
  dataQa?: string;
  labels?: GetWidgetsContent_all_widgets_items_labels;
  showLabels?: boolean;
  showModelPortfolioInfo?: boolean;
}

export const AccountListWidget: FC<Props> = ({
  accounts,
  config,
  dataQa = 'account-list-widget',
  labels,
  contentOptions,
}) => {
  const {
    sfWidgets: { styles },
  } = useTheme<SfTheme>();

  const { variant = AccountWidgetsVariants.Consolidated } = config;
  const activeAccounts = getRebalancedAccounts(accounts);
  const pendingAccounts = accounts.filter(account => !activeAccounts.find(a => a.id === account.id));

  return (
    <Box data-qa={dataQa}>
      {variant === AccountWidgetsVariants.Consolidated ? (
        <>
          <Grid
            container
            data-qa={`${dataQa}-consolidated-container`}
            sx={{
              borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
              pb: 3,
            }}
          >
            <Grid data-qa={`${dataQa}-accounts-heading`} item xs={7}>
              <Typography>{labels?.accounts_heading ?? ''}</Typography>
            </Grid>
            <Grid data-qa={`${dataQa}-balance-heading`} item xs={3}>
              <Typography>{labels?.balance_heading ?? ''}</Typography>
            </Grid>
            <Grid data-qa={`${dataQa}-action-heading`} item xs={2}>
              <Typography>{labels?.action_heading ?? ''}</Typography>
            </Grid>
          </Grid>
          <AccountListContainer
            accountsToDisplay={accounts}
            config={config}
            contentOptions={contentOptions}
            showDivider
          />
        </>
      ) : (
        <>
          {pendingAccounts.length > 0 && (
            <Grid container data-qa={`${dataQa}-pending-actions-container`}>
              <Grid
                data-qa={`${dataQa}-pending-partner-heading`}
                item
                sx={{ ...styles.partnerHeading, mb: 0.25, p: 1 }}
                xs={12}
              >
                {labels?.container_heading && <Typography>{labels.container_heading}</Typography>}
              </Grid>
              <Grid data-qa={`${dataQa}-pending-actions-heading`} item sx={{ ...styles.sectionHeading, p: 1 }} xs={12}>
                <Typography>{labels?.pending_actions_heading ?? ''}</Typography>
              </Grid>
            </Grid>
          )}
          {pendingAccounts.length > 0 && (
            <AccountListContainer accountsToDisplay={pendingAccounts} config={config} contentOptions={contentOptions} />
          )}
          {activeAccounts.length > 0 && (
            <Grid container data-qa={`${dataQa}-active-accounts-container`} sx={{ ...styles.sectionHeading, p: 1 }}>
              <Grid data-qa={`${dataQa}-active-accounts-heading`} item xs={12}>
                <Typography>{labels?.active_accounts_heading ?? ''}</Typography>
              </Grid>
            </Grid>
          )}
          {activeAccounts.length > 0 && (
            <AccountListContainer accountsToDisplay={activeAccounts} config={config} contentOptions={contentOptions} />
          )}
        </>
      )}
    </Box>
  );
};
