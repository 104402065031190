import { ComponentTypes, QuestionOrderSteps } from '../../Section/types';
import { getFieldName } from '../../Section/utils';
import { isValidEmail } from '../../Section/validators';

import { DataPointKeys, QuestionKeys, QuestionProperties } from './types';
import {
  filterValidationRules,
  getPaperworkDataForPrefill,
  getPaperworkStringFieldValue,
  requiredValidation,
} from './utils';

export const eDelivery = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.EDELIVERY,
    dataPointKey: DataPointKeys.EDELIVERY,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.additionalAttributes?.enrollInEDelivery;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      const enrollInEDeliveryFormValue = getPaperworkStringFieldValue(
        data.formValues[getFieldName(DataPointKeys.EDELIVERY, paperworkFreeFormId)],
      );
      paperworkInput.additionalAttributes = {
        ...paperworkInput.additionalAttributes,
        enrollInEDelivery: enrollInEDeliveryFormValue,
      };

      const isEnrolled =
        enrollInEDeliveryFormValue?.toLowerCase() === 'yes' || enrollInEDeliveryFormValue?.toLowerCase() === 'true';
      if (!isEnrolled) {
        paperworkInput.additionalAttributes = {
          ...paperworkInput.additionalAttributes,
          receiveShareholderDocumentsViaEmail: false,
          receiveStatementsViaEmail: false,
          receiveConfirmsViaEmail: false,
          receiveCustomerCorrespondenceViaEmail: false,
          receiveTaxDocumentsViaEmail: false,
        };
      }
    },
    componentType: ComponentTypes.Radio,
    defaultValue: 'Yes',
    adjacent,
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};

export const eDeliveryEmail = ({
  additionalValidations,
  adjacent,
  paperworkFreeFormId,
  required,
  rules,
}: QuestionProperties): QuestionOrderSteps => {
  return {
    questionKey: QuestionKeys.EDELIVERY_EMAIL,
    dataPointKey: DataPointKeys.EDELIVERY_EMAIL,
    prefillValueGetter: data => {
      return getPaperworkDataForPrefill(data, paperworkFreeFormId)?.additionalAttributes?.eDeliveryEmailAddress;
    },
    paperworkInputSetter: (paperworkInput, data) => {
      paperworkInput.additionalAttributes = {
        ...paperworkInput.additionalAttributes,
        eDeliveryEmailAddress: getPaperworkStringFieldValue(
          data.formValues[getFieldName(DataPointKeys.EDELIVERY_EMAIL, paperworkFreeFormId)],
        ),
      };
    },
    componentType: ComponentTypes.Input,
    adjacent,
    inputComponentProps: {
      invalidCharacterRegex: new RegExp(/[^a-zA-Z0-9@._+-]/g),
      textFieldProps: {
        type: 'email',
      },
    },
    rules,
    validationsGetter: data =>
      filterValidationRules(
        {
          ...requiredValidation(required),
          ...additionalValidations,
          validate: {
            isEmail: value => (value ? isValidEmail(value) : true),
            ...additionalValidations.validate,
          },
        },
        data.hidden,
        data.isPaperworkSaving,
      ),
  };
};
