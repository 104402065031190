import React from 'react';

import { Skeleton, TableCell, TableRow, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { formatCurrency, formatPercentage, FormatPercentageOptions } from '~/utils/format';
import { FeePeriod, periodToAbbreviation } from '~/utils/pricing';
import { SfTheme } from '~/utils/theme';

export interface FeeCostProps {
  estimatedPeriodCost?: number;
  estimatedTotalFee: number;
  feePeriod?: FeePeriod;
  formatPercentageOptions: FormatPercentageOptions;
  loading?: boolean;
  showPeriodCost?: boolean;
  totalFeeLabel?: string;
}

export const FeeCost = ({
  estimatedPeriodCost,
  estimatedTotalFee,
  feePeriod,
  formatPercentageOptions,
  loading,
  showPeriodCost,
  totalFeeLabel,
}: FeeCostProps) => {
  const {
    sfPricingSummary: { typographyVariants },
  } = useTheme<SfTheme>();

  const showPeriodCostComponent = showPeriodCost && estimatedPeriodCost && feePeriod;

  return (
    <>
      <TableRow>
        <TableCell sx={{ borderBottom: 'none', paddingBottom: 0, paddingTop: 1 }}>
          <Typography variant={typographyVariants?.estimatedTotalCost}>{totalFeeLabel}</Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', paddingBottom: 0, paddingTop: 1, textAlign: 'right' }}>
          <Typography variant={typographyVariants?.estimatedTotalCost}>
            {loading ? (
              <Skeleton height={15} sx={{ display: 'inline-block' }} width={45} />
            ) : (
              formatPercentage(estimatedTotalFee, formatPercentageOptions)
            )}
          </Typography>
        </TableCell>
      </TableRow>
      {showPeriodCostComponent && (
        <TableRow>
          <TableCell sx={{ borderBottom: 'none', paddingTop: 0 }} />
          <TableCell sx={{ borderBottom: 'none', paddingTop: 0, textAlign: 'right' }}>
            {loading ? (
              <Skeleton height={15} sx={{ display: 'inline-block' }} width={45} />
            ) : (
              <Typography variant={typographyVariants?.estimatedPeriodCost}>{`${formatCurrency(
                estimatedPeriodCost,
              )}/${periodToAbbreviation(feePeriod)}`}</Typography>
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
