import { Text, View } from '@react-pdf/renderer';
import React from 'react';

import { Style } from '~/components/pdfs/styles';

interface Props {
  pdfStyles: Style;
  text: string;
}

export const Footer: React.FC<Props> = ({ text, pdfStyles }) => {
  return (
    <View
      fixed
      style={{
        width: '100%',
        height: 20,
        textAlign: 'center',
        marginTop: 'auto',
        bottom: pdfStyles.footer.bottom,
        paddingTop: 2,
      }}
    >
      <Text
        style={{
          textAlign: 'center',
          color: pdfStyles.footer.color ?? pdfStyles.textSecondary.color,
          fontSize: 9,
        }}
      >
        {text}
      </Text>
    </View>
  );
};
