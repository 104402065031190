import React, { FC, useEffect, useState } from 'react';

import { useAutoPortfolioSelectionComponentData } from './hooks';

import { FlowType, ManagedProductType, SleeveType } from '~/__generated__';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { Box } from '~/components/ui/mui';
import { useSavePortfolioSelection } from '~/hooks/portfolio/symphony';
import { ContentOptions } from '~/utils/contentstack/src/types';

export interface Props {
  brokerageAlias: string;
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  managedProductType: ManagedProductType;
  onNext: () => void;
}

export const AutoPortfolioSelection: FC<Props> = ({
  brokerageAlias,
  contentOptions,
  dataQa = 'auto-portfolio-selection',
  managedProductId,
  managedProductType,
  onNext,
}) => {
  const [loading, setLoading] = useState(true);
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [savePortfolioSelection] = useSavePortfolioSelection();
  const {
    data: autoPortfolioSelectionComponentData,
    loading: autoPortfolioSelectionComponentLoading,
    error: autoPortfolioSelectionComponentError,
  } = useAutoPortfolioSelectionComponentData({ managedProductId, managedProductType, brokerageAlias });

  useEffect(() => {
    if (autoPortfolioSelectionComponentData) {
      setLoading(true);
      const recommendedModelPortfolioInput = {
        managedProductId,
        planUpdateWorkFlowId: autoPortfolioSelectionComponentData.planUpdateWorkflowId,
        selectedModelPortfolios: [
          {
            modelPortfolioInternalId: autoPortfolioSelectionComponentData.recommendedModelPortfolio.internalId,
            modelPortfolioSeriesId: autoPortfolioSelectionComponentData.recommendedModelPortfolio.seriesId,
            sleeveType: SleeveType.INVESTMENT,
          },
        ],
        source: FlowType.RETAKE,
      };
      const savePortfolioSelectionStatus = async () => {
        return await savePortfolioSelection({
          variables: recommendedModelPortfolioInput,
        });
      };
      savePortfolioSelectionStatus()
        .then(res => {
          if (res.data?.saveProductSelection.id) {
            onNext();
          }
        })
        .catch((err: Error) => {
          console.error(err);
          setErrorSaving(err);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [autoPortfolioSelectionComponentData]);

  return (
    <>
      {autoPortfolioSelectionComponentLoading || loading || autoPortfolioSelectionComponentError || errorSaving ? (
        <Box data-qa={dataQa} sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
          <AlertAndLoading
            ariaLabel="Auto Saving Portfolio Selection"
            contentOptions={contentOptions}
            error={autoPortfolioSelectionComponentError || errorSaving}
            loading={autoPortfolioSelectionComponentLoading || loading}
          />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
