import { useEffect, useState } from 'react';

import { useGetSleeveTemplatesContent } from './contentstack';
import { GetSleeveTemplatesContent_all_sleeve_templates_items } from './contentstack/__generated__/query.v2';
import { SleeveAllocationData } from './SleeveTemplateCard';
import { SleeveTemplate, useGetSystemSleeveTemplates, useGetUserSleeveTemplates } from './symphony';
import {
  GetSystemSleeveTemplates_systemSleeveTemplates,
  GetSystemSleeveTemplates_systemSleeveTemplates_allocation,
  GetUserSleeveTemplates,
  GetUserSleeveTemplates_userSleeveTemplates,
  GetUserSleeveTemplatesVariables,
} from './symphony/__generated__/query.v2';

import { DonutSlice } from '~/components/ui/Donut';
import { getStockBondRatio } from '~/containers/Plan/utils';
import { Portfolio } from '~/containers/PortfolioSelection/types';
import { useGetAssetClassContent } from '~/hooks/asset-classes/contentstack';
import { GetAssetClassContent } from '~/hooks/asset-classes/contentstack/__generated__/query.v2';
import { useGetAllModelPortfoliosContentAcrossPages } from '~/hooks/model-portfolios/contentstack';
import {
  GetModelPortfoliosContent,
  GetModelPortfoliosContent_all_model_portfolio_data_items,
} from '~/hooks/model-portfolios/contentstack/__generated__/query.v2';
import { ApolloQueryResult } from '~/utils/apollo-client';
import { allocationToDonutSlice, getAssetAllocations } from '~/utils/asset-allocation';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface SleeveTemplateData {
  allocations: SleeveAllocationData[];
  donutAllocations: DonutSlice[];
  id: number;
  minimumBalanceForAccountOpening: string;
  name: string;
  stockBondSplit: string;
  volatility: string;
}

export interface SleeveTemplateVariables {
  contentOptions: ContentOptions;
  initialRiskPreference: number;
  partyId: string;
}

interface SleeveTemplatesData {
  assetClassContent: GetAssetClassContent;
  modelPortfoliosContent: GetModelPortfoliosContent;
  sleeveTemplateCardContent: SleeveTemplateCardContent;
  systemTemplatesArray: GetSystemSleeveTemplates_systemSleeveTemplates[];
  userSleeveTemplatesRefetch?: (
    variables?: Partial<GetUserSleeveTemplatesVariables> | undefined,
  ) => Promise<ApolloQueryResult<GetUserSleeveTemplates>>;
  userTemplatesArray: GetUserSleeveTemplates_userSleeveTemplates[];
}

export interface SleeveTemplateCardContent {
  aggressiveLabel: string;
  alertIconUrl: string;
  allBlendCard: {
    button_text: string;
    header: string;
    message: string;
  };
  cancelIconUrl: string;
  chatCircleUrl: string;
  checkIconUrl: string;
  conservativeLabel: string;
  emptyCardMessage: string;
  minimumInvestmentLabel: string;
  noSuitableBlendCard: {
    header: string;
    message: string;
  };
  notSuitableText: string;
  quickApplyLabel: string;
  removeCard: {
    backCta: string;
    confirmCta: string;
    header: string;
    message: string;
    removeIconUrl: string;
  };
  removeLabel: string;
  sleeveTemplateHeader: string;
  stockBondLabel: string;
  suitableBlendCard: {
    button_text: string;
    header: string;
    message: string;
  };
  suitableNotAvailableCardMessage: string;
  suitableText: string;
  tabs: {
    system_tab_label: string;
    user_tab_label: string;
  };
}

export const getSleeveTemplateData = (
  sleeveTemplatesArray: SleeveTemplate[],
  modelPortfolios: Portfolio[],
  modelPortfoliosContent: GetModelPortfoliosContent | undefined,
  assetClassContent: GetAssetClassContent | undefined,
  assetClassTier: AssetClassTier = AssetClassTier.MODEL,
): SleeveTemplateData[] => {
  return sleeveTemplatesArray?.map(sleeveTemplate => {
    const sleeveAllocations = getCompositeModelPortfolioSleeveAllocationData(
      sleeveTemplate?.allocation,
      modelPortfoliosContent?.all_model_portfolio_data?.items ?? [],
      assetClassContent,
      assetClassTier,
      modelPortfolios,
    );
    const sleeveAssetAllocation = getAssetAllocations(
      sleeveTemplate?.guidance?.diversification?.assets?.allocations,
      assetClassContent?.all_asset_class_data?.items ?? [],
      assetClassTier,
    );
    return {
      allocations: sleeveAllocations,
      donutAllocations: sleeveAssetAllocation.map(allocationToDonutSlice(assetClassTier)),
      minimumBalanceForAccountOpening: sleeveTemplate.minimumBalanceForAccountOpening,
      name: sleeveTemplate.name,
      id: sleeveTemplate.id,
      stockBondSplit: getStockBondRatio({
        bondPercentage:
          sleeveTemplate.guidance?.diversification?.assets?.stockBondDiversification?.bondPercentage ?? '',
        stockPercentage:
          sleeveTemplate.guidance?.diversification?.assets?.stockBondDiversification?.stockPercentage ?? '',
      }),
      volatility: sleeveTemplate.guidance?.volatility?.percentage ?? '',
    };
  });
};

export const getCompositeModelPortfolioSleeveAllocationData = (
  targetModelPortfolioAllocation: GetSystemSleeveTemplates_systemSleeveTemplates_allocation[],
  modelPortfoliosContentData: (GetModelPortfoliosContent_all_model_portfolio_data_items | null)[],
  assetClassContent?: GetAssetClassContent | undefined,
  assetClassTier?: AssetClassTier,
  modelPortfolios?: Portfolio[],
): SleeveAllocationData[] => {
  return targetModelPortfolioAllocation.map(sleeve => {
    const modelPortfolioContent = modelPortfoliosContentData?.find(
      mc =>
        mc?.series_name === sleeve.modelPortfolioSeriesBaseName &&
        mc?.internal_id === sleeve.modelPortfolioInternalId?.toString(),
    );
    const modelPortfolio = modelPortfolios?.find(
      mp =>
        mp.seriesBaseName === sleeve.modelPortfolioSeriesBaseName && mp.internalId === sleeve.modelPortfolioInternalId,
    );
    const assetAllocationData = getAssetAllocations(
      modelPortfolio?.assetAllocations,
      assetClassContent?.all_asset_class_data?.items ?? [],
      assetClassTier,
    );

    return {
      modelPortfolioInternalId: sleeve.modelPortfolioInternalId,
      modelPortfolioName: modelPortfolioContent?.model_portfolio_name_shorthand || 'Unknown',
      modelPortfolioSeriesId: sleeve.modelPortfolioSeriesId,
      percentage: sleeve.percentage,
      modelPortfolioDescription: modelPortfolio?.description,
      stockBondDiversification: modelPortfolio?.stockBondDiversification,
      assetAllocations: assetAllocationData,
      strategyBrochureUrl: modelPortfolio?.strategyBrochureUrl || '',
      portfolioAssetsBreakdown: assetAllocationData.map(allocationToDonutSlice(assetClassTier)),
    };
  });
};

export const getSleeveTemplateCardContent = (
  content?: GetSleeveTemplatesContent_all_sleeve_templates_items,
): SleeveTemplateCardContent => {
  const allBlendCardContent = content?.action_cards?.find(card => card?.key === 'all-blends');
  const suitableCardContent = content?.action_cards?.find(card => card?.key === 'suitable-blends');
  const noSuitableCardContent = content?.action_cards?.find(card => card?.key === 'no-suitable-blends');
  return {
    aggressiveLabel: content?.template_card?.aggressive_label ?? '',
    alertIconUrl: allBlendCardContent?.alert_iconConnection?.edges?.[0]?.node?.url ?? '',
    allBlendCard: {
      button_text: allBlendCardContent?.button_text ?? '',
      header: allBlendCardContent?.header ?? '',
      message: allBlendCardContent?.message ?? '',
    },
    cancelIconUrl: content?.template_card?.cancel_iconConnection?.edges?.[0]?.node?.url ?? '',
    chatCircleUrl: content?.empty_card?.chat_circle_iconConnection?.edges?.[0]?.node?.url ?? '',
    checkIconUrl: content?.template_card?.check_iconConnection?.edges?.[0]?.node?.url ?? '',
    conservativeLabel: content?.template_card?.conservative_label ?? '',
    emptyCardMessage: content?.empty_card?.message ?? '',
    minimumInvestmentLabel: content?.template_card?.minimum_investment_label ?? '',
    noSuitableBlendCard: {
      header: noSuitableCardContent?.header ?? '',
      message: noSuitableCardContent?.message ?? '',
    },
    notSuitableText: content?.template_card?.not_suitable_text ?? '',
    quickApplyLabel: content?.template_card?.quick_apply_label ?? '',
    removeLabel: content?.template_card?.remove_label ?? '',
    removeCard: {
      header: content?.remove_card?.header ?? '',
      message: content?.remove_card?.message ?? '',
      confirmCta: content?.remove_card?.confirm_cta ?? '',
      backCta: content?.remove_card?.back_cta ?? '',
      removeIconUrl: content?.remove_card?.iconConnection?.edges?.[0]?.node?.url ?? '',
    },
    sleeveTemplateHeader: content?.sleeve_template_header ?? '',
    stockBondLabel: content?.template_card?.stock_bond_label ?? '',
    suitableBlendCard: {
      button_text: suitableCardContent?.button_text ?? '',
      header: suitableCardContent?.header ?? '',
      message: suitableCardContent?.message ?? '',
    },
    suitableNotAvailableCardMessage: content?.suitable_not_available_card?.message ?? '',
    suitableText: content?.template_card?.suitable_text ?? '',
    tabs: {
      system_tab_label: content?.tabs?.system_tab_label ?? '',
      user_tab_label: content?.tabs?.user_tab_label ?? '',
    },
  };
};

export const useGetSleeveTemplates = ({
  contentOptions,
  initialRiskPreference,
  partyId,
}: SleeveTemplateVariables): AsyncResult<SleeveTemplatesData> => {
  const [state, setState] = useState<AsyncResult<SleeveTemplatesData>>({
    loading: true,
    error: undefined,
    data: undefined,
  });

  const {
    data: sleeveTemplatesContentData,
    loading: sleeveTemplatesContentLoading,
    error: sleeveTemplatesContentError,
  } = useGetSleeveTemplatesContent({
    variables: contentOptions,
  });
  const {
    data: modelPortfoliosContentData,
    loading: modelPortfoliosContentLoading,
    error: modelPortfoliosContentError,
  } = useGetAllModelPortfoliosContentAcrossPages({
    contentOptions,
  });
  const {
    data: assetClassContentData,
    loading: assetClassContentLoading,
    error: assetClassContentError,
  } = useGetAssetClassContent({
    variables: contentOptions,
  });
  const {
    data: systemSleeveTemplatesData,
    loading: systemSleeveTemplatesLoading,
    error: systemSleeveTemplatesError,
  } = useGetSystemSleeveTemplates({
    variables: { initialRiskPreference },
  });
  const {
    data: userSleeveTemplatesData,
    loading: userSleeveTemplatesLoading,
    error: userSleeveTemplatesError,
    refetch: userSleeveTemplatesRefetch,
  } = useGetUserSleeveTemplates({
    variables: { partyId },
  });
  const systemTemplatesArray = systemSleeveTemplatesData?.systemSleeveTemplates ?? [];
  const userTemplatesArray = userSleeveTemplatesData?.userSleeveTemplates ?? [];

  useEffect(() => {
    if (
      modelPortfoliosContentLoading ||
      assetClassContentLoading ||
      sleeveTemplatesContentLoading ||
      systemSleeveTemplatesLoading ||
      userSleeveTemplatesLoading
    ) {
      return;
    }
    if (
      modelPortfoliosContentError ||
      assetClassContentError ||
      sleeveTemplatesContentError ||
      systemSleeveTemplatesError ||
      userSleeveTemplatesError
    ) {
      setState({
        loading: false,
        error:
          modelPortfoliosContentError ??
          assetClassContentError ??
          sleeveTemplatesContentError ??
          systemSleeveTemplatesError ??
          userSleeveTemplatesError,
      });
      return;
    }

    if (
      !modelPortfoliosContentData ||
      !assetClassContentData ||
      !sleeveTemplatesContentData ||
      !systemTemplatesArray ||
      !userTemplatesArray
    ) {
      return;
    }

    const sleeveTemplateCardContent = getSleeveTemplateCardContent(
      sleeveTemplatesContentData.all_sleeve_templates?.items?.[0] ?? undefined,
    );

    setState({
      loading: false,
      data: {
        modelPortfoliosContent: modelPortfoliosContentData,
        assetClassContent: assetClassContentData,
        sleeveTemplateCardContent,
        systemTemplatesArray,
        userSleeveTemplatesRefetch,
        userTemplatesArray,
      },
    });
  }, [
    modelPortfoliosContentData,
    modelPortfoliosContentLoading,
    modelPortfoliosContentError,
    assetClassContentData,
    assetClassContentLoading,
    assetClassContentError,
    sleeveTemplatesContentData,
    sleeveTemplatesContentLoading,
    sleeveTemplatesContentError,
    systemSleeveTemplatesData,
    systemSleeveTemplatesLoading,
    systemSleeveTemplatesError,
    userSleeveTemplatesData,
    userSleeveTemplatesLoading,
    userSleeveTemplatesError,
    userSleeveTemplatesRefetch,
  ]);

  return state;
};
