import React, { FC, useMemo } from 'react';

import { AccountOverviewContent } from '../types';
import { formatBalance, formatDisplayDate } from '../utils';

import { Box, Grid, Stack, useTheme } from '~/components/ui/mui';
import { Tooltip } from '~/components/ui/Tooltip';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  accountBalance?: string;
  content: AccountOverviewContent;
  contentOptions: ContentOptions;
  dataQa?: string;
  endDate: string;
  returnPercentage: string;
  startDate?: string | null;
}

export const AccountOverview: FC<Props> = ({
  accountBalance,
  content,
  contentOptions,
  dataQa = 'account-overview',
  endDate,
  returnPercentage,
  startDate,
}) => {
  const {
    sfAccountPerformance: { typographyVariants },
  } = useTheme();

  const formattedBalance = useMemo(() => {
    if (accountBalance) {
      return formatBalance(accountBalance, contentOptions);
    }
    return 'Unknown';
  }, [contentOptions, accountBalance]);

  return (
    <Box data-qa={dataQa} id="accountOverview" sx={{ my: 4 }}>
      <Typography component="h2" variant={typographyVariants?.accountOverview}>
        {content.labels.overview}
      </Typography>
      <Typography color="text.secondary" variant={typographyVariants?.date}>
        {`${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`}
      </Typography>
      <Grid columnSpacing={10} container sx={{ my: 3 }}>
        <Grid item>
          <Box sx={{ display: 'flex', alignItems: 'center', minHeight: '30px' }}>
            <Typography color="text.secondary" component="p" variant={typographyVariants?.subHeading}>
              {content.labels.accountBalance}
            </Typography>
          </Box>
          <Typography color="text.primary" component="p" variant={typographyVariants?.subHeadingValue}>
            {formattedBalance}
          </Typography>
        </Grid>
        <Grid item>
          <Stack sx={{ alignItems: 'center', flexDirection: 'row' }}>
            <Typography color="text.secondary" component="p" variant={typographyVariants?.subHeading}>
              {content.labels.returns}
            </Typography>
            <Tooltip tooltipContent={<Typography variant="caption">{content.labels.returnsHelperText}</Typography>} />
          </Stack>
          <Typography color="text.primary" component="p" variant={typographyVariants?.subHeadingValue}>
            {returnPercentage}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
