import { PaperworkDataPageConfig } from '../types';

import { aboutYouQuestions, aboutYouSectionKey } from './sections/aboutYou';
import {
  beneficiariesAndContactsIraQuestions,
  beneficiariesAndContactsSectionKey,
} from './sections/beneficiariesAndTrustedContacts';
import { idAndEmploymentQuestions, idAndEmploymentSectionKey } from './sections/identificationAndEmployment';
import { originalDepositorQuestions, originalDepositorSectionKey } from './sections/originalDepositor';
import { regulatoryQuestions, regulatorySectionKey } from './sections/regulatory';
import { wealthAndExpensesQuestions, wealthAndExpensesSectionKey } from './sections/wealthAndExpenses';

import { PaperworkType } from '~/__generated__';

export const inheritedIRAPaperworkPages = (): PaperworkDataPageConfig[] => {
  return [
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      sections: [
        {
          sectionKey: aboutYouSectionKey,
          questions: aboutYouQuestions,
        },
        {
          sectionKey: idAndEmploymentSectionKey,
          questions: idAndEmploymentQuestions,
        },
        {
          adjacent: originalDepositorSectionKey,
          sectionKey: beneficiariesAndContactsSectionKey,
          questions: beneficiariesAndContactsIraQuestions,
        },
        {
          sectionKey: originalDepositorSectionKey,
          questions: originalDepositorQuestions,
        },
      ],
    },
    {
      heading: 'heading',
      paperworkFreeFormIdForPage: 'individual',
      consent: {
        consentRequired: true,
        defaultChecked: false,
      },
      sections: [
        {
          sectionKey: wealthAndExpensesSectionKey,
          questions: wealthAndExpensesQuestions,
        },
        {
          sectionKey: regulatorySectionKey,
          questions: regulatoryQuestions,
        },
      ],
    },
  ];
};

export const inheritedIraPaperworkFreeFormIdToProfileType: Record<string, PaperworkType> = {
  individual: PaperworkType.PRIMARY,
};
