import { DataPointKeys, QuestionKeys, QuestionSetKeys } from '../../questions/types';
import { PaperworkDataQuestionConfig } from '../../types';

export const idAndEmploymentSectionKey = 'id_employment';
export const idAndEmploymentQuestions: PaperworkDataQuestionConfig[] = [
  { questionOrSetKey: QuestionKeys.IDENTIFICATION },
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.IDENTIFICATION_TYPE,
              value: 'DRIVING_LICENSE',
            },
          ],
          next: QuestionKeys.DRIVING_LICENSE_STATE,
        },
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.IDENTIFICATION_TYPE,
              value: 'PASSPORT',
            },
          ],
          next: QuestionKeys.PASSPORT_COUNTRY,
        },
        {
          next: QuestionKeys.EMPLOYMENT,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.IDENTIFICATION_TYPE,
  },
  { questionOrSetKey: QuestionKeys.DRIVING_LICENSE_STATE },
  { questionOrSetKey: QuestionKeys.DRIVING_LICENSE_IDENTIFICATION_NUMBER },
  {
    properties: { adjacent: QuestionKeys.DRIVING_LICENSE_EXPIRY_DATE },
    questionOrSetKey: QuestionKeys.DRIVING_LICENSE_ISSUE_DATE,
  },
  {
    properties: { rules: [{ next: null }] },
    questionOrSetKey: QuestionKeys.DRIVING_LICENSE_EXPIRY_DATE,
  },
  { questionOrSetKey: QuestionKeys.PASSPORT_COUNTRY },
  { questionOrSetKey: QuestionKeys.PASSPORT_IDENTIFICATION_NUMBER },
  {
    properties: { adjacent: QuestionKeys.PASSPORT_EXPIRY_DATE },
    questionOrSetKey: QuestionKeys.PASSPORT_ISSUE_DATE,
  },
  {
    properties: { rules: [{ next: null }] },
    questionOrSetKey: QuestionKeys.PASSPORT_EXPIRY_DATE,
  },
  { questionOrSetKey: QuestionKeys.EMPLOYMENT },
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.EMPLOYMENT_STATUS,
              value: 'EMPLOYED',
            },
          ],
          next: QuestionKeys.JOB_TITLE,
        },
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.EMPLOYMENT_STATUS,
              value: 'SELF_EMPLOYED',
            },
          ],
          next: QuestionKeys.JOB_TITLE_SELF_EMPLOYED,
        },
        {
          conditions: [
            {
              dataPointKey: DataPointKeys.EMPLOYMENT_STATUS,
              customValueComparator: formValue =>
                ['UNEMPLOYED', 'RETIRED', 'HOME_MAKER', 'STUDENT'].includes(formValue),
            },
          ],
          next: QuestionKeys.INCOME_SOURCE,
        },
      ],
    },
    questionOrSetKey: QuestionKeys.EMPLOYMENT_STATUS,
  },
  { questionOrSetKey: QuestionKeys.JOB_TITLE },
  { questionOrSetKey: QuestionKeys.COMPANY_NAME },
  { questionOrSetKey: QuestionSetKeys.COMPANY_ADDRESS_FIELDS },
  { properties: { rules: [{ next: null }] }, questionOrSetKey: QuestionKeys.JOB_TITLE_SELF_EMPLOYED },
  {
    properties: {
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: DataPointKeys.INCOME_SOURCE,
              value: 'Other',
            },
          ],
          next: QuestionKeys.OTHER_INCOME_SOURCE,
        },
        { next: null },
      ],
    },
    questionOrSetKey: QuestionKeys.INCOME_SOURCE,
  },
  { questionOrSetKey: QuestionKeys.OTHER_INCOME_SOURCE },
];
