import {
  GetRetakeRTQClientDocusignContent,
  GetRetakeRTQClientDocusignContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetRetakeRTQClientDocusignContent = (
  options?: QueryHookOptions<GetRetakeRTQClientDocusignContent, GetRetakeRTQClientDocusignContentVariables>,
): QueryResult<GetRetakeRTQClientDocusignContent, GetRetakeRTQClientDocusignContentVariables> => {
  return useContentstackQuery(queries.GetRetakeRTQClientDocusignContent, options);
};
