import React, { ComponentProps } from 'react';

import { AssetAllocationTabs } from './AssetAllocationTabs';

import { Allocation, AssetAllocationTable } from '~/components/AssetAllocationTable';
import { Donut, DonutSlice, Props as DonutProps } from '~/components/ui/Donut';
import { Modal } from '~/components/ui/Modal';
import { Box, Button, Grid } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { AssetAllocationTableColumns } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface AssetAllocationPortfolio {
  assetAllocationData: Allocation[];
  description: string;
  name: string;
  portfolioAssetsBreakdown: DonutSlice[];
  stockBondRatio?: string;
}

export interface PortfolioDetailsContent {
  assetAllocation: string;
  bondLabel?: string;
  close: string;
  donutAllocationLabel?: string;
  stockLabel?: string;
  taxShelteredAllocation?: string;
  taxableAllocation?: string;
}

export type AssetAllocationModalProps = ComponentProps<typeof Modal> & {
  columns?: AssetAllocationTableColumns[];
  content?: PortfolioDetailsContent;
  contentOptions: ContentOptions;
  currentTab?: number;
  dataQa?: string;
  donutProps?: DonutProps;
  error?: Error;
  isTaxSheltered?: boolean;
  loading?: boolean;
  onChangeTab?: (newTab: number) => void;
  onClose: () => void;
  portfolio: AssetAllocationPortfolio;
};

export const AssetAllocationModal: React.FC<AssetAllocationModalProps> = ({
  columns,
  content,
  contentOptions,
  currentTab,
  dataQa = 'asset-allocation-modal',
  donutProps,
  error,
  loading,
  onClose,
  onChangeTab,
  open,
  portfolio,
}) => {
  const isMediumScreen = useIsMediumScreen();
  return (
    <Modal
      actions={
        <Button fullWidth={isMediumScreen} onClick={onClose} sx={{ m: 1 }} variant="outlined">
          {content?.close}
        </Button>
      }
      content={
        <Grid container direction={{ xs: 'column', md: 'row' }}>
          {isMediumScreen && currentTab !== undefined && onChangeTab && (
            <>
              <Grid item xs={12}>
                <Box sx={{ pb: 2, pl: 0 }}>
                  <AssetAllocationTabs
                    content={{
                      taxable_allocation: content?.taxableAllocation ?? '',
                      tax_sheltered_allocation: content?.taxShelteredAllocation ?? '',
                    }}
                    dataQa={dataQa}
                    onChange={newTabIndex => onChangeTab(newTabIndex)}
                    value={currentTab}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                {portfolio.description && (
                  <Box py={4}>
                    <Typography variant="caption">{portfolio.description}</Typography>
                  </Box>
                )}
              </Grid>
            </>
          )}
          <Grid item md={4}>
            <Donut
              centerSubtext={donutProps?.centerSubtext}
              centerText={donutProps?.centerText}
              centerTextVariant={donutProps?.centerTextVariant}
              data={donutProps?.data ?? []}
              innerRadius={75}
              justifyContentGraph="center"
              label={content?.donutAllocationLabel}
              showTooltip
              size={275}
            />
          </Grid>
          <Grid item md={8}>
            <Grid container>
              {!isMediumScreen && currentTab !== undefined && onChangeTab && (
                <>
                  <Grid item xs={12}>
                    <Box sx={{ pb: 2, pl: 0 }}>
                      <AssetAllocationTabs
                        content={{
                          taxable_allocation: content?.taxableAllocation ?? '',
                          tax_sheltered_allocation: content?.taxShelteredAllocation ?? '',
                        }}
                        dataQa={dataQa}
                        onChange={newTabIndex => onChangeTab(newTabIndex)}
                        value={currentTab}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {portfolio.description && <Typography variant="caption">{portfolio.description}</Typography>}
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Typography
                  component="div"
                  sx={{ pb: 1, pt: { xs: 0, md: 3 } }}
                  textAlign={{ md: 'left', xs: 'center' }}
                  variant="h5"
                >
                  {portfolio.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <AssetAllocationTable
                  columns={columns}
                  contentOptions={contentOptions}
                  data={portfolio.assetAllocationData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      contentOptions={contentOptions}
      error={error}
      fullWidth={isMediumScreen}
      loading={loading}
      maxWidth="md"
      onClose={onClose}
      open={open}
      title={content?.assetAllocation}
    />
  );
};
